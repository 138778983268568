
import { Pie } from '@ant-design/plots';

const MetrictChart = (props) => {
    const { detailInfo, maxLP, isManualListing } = props;

    let totalSupply = detailInfo?.tokenInfo.totalSupply;
    let tokenForPresale = ((detailInfo?.hardCap * detailInfo?.roundIcos[0]?.swapRate / totalSupply) * 100);
    let tokenForLiquidity = isManualListing ? maxLP : 0;

    let unLocked = 100 - (+ ((tokenForLiquidity / totalSupply) * 100));

    const data = [
        {
            type: 'Presale',
            value: tokenForPresale,
        },
        {
            type: 'Liquidity',
            value: (isManualListing ? (tokenForLiquidity / totalSupply) * 100 : 0),
        },
        {
            type: 'Unlocked',
            value: unLocked,
        }
    ];
    const config = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-50%',
            content: '',
            style: {
                textAlign: 'center',
                fontSize: 14,
            },
        },
        theme: {
            colors10: [
                '#FF6B3B',
                '#626681',
                '#FFC100',
                '#9FB40F',
                '#76523B',
                '#DAD5B5',
                '#0E8E89',
                '#E19348',
                '#F383A2',
                '#247FEA',
            ]
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: '#ee7620'
                },
                content: `<span className="color-chart-text">${detailInfo?.tokenInfo?.symbol}</span>`,
            },
        },
    };

    return (
        <>
            {tokenForPresale && (
                <Pie {...config} />
            )}

        </>
    )
}

export default MetrictChart
