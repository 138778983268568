import http from "./http-common";
const getInoList =  (payload) => { 
    return http.post("/ino-launchpads/filter", payload);
};
const getInoDetail =  (handle, account) => { 
    return http.get(`/ino-launchpads/${handle}/details?address=${account}`);
};
const postBuyBox =  (payload) => { 
    return http.post(`/buy-box`, payload);
};
const getBoxesById =  (id) => { 
    return http.get(`/ino-boxes/${id}`);
};

const InoService = {
    getInoList,
    getInoDetail,
    getBoxesById,
    postBuyBox
}
 
export default InoService;