import React, {useState, useEffect} from "react";
import {useActiveWeb3React} from '../../../hooks';
import ConnectWallet from "./connectWallet";
import InfoWallet from "./infoWallet";
import {useSelector, useDispatch} from 'react-redux';
import allActions from '../../../actions';
import { MAPPING_CHAINID, MAPPING_CHAINID_DECIMAL, Tokens } from "../../../constants";
import web3 from "web3";
import { Dropdown, Menu } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import networkChange from "../../../utils/networkChange";

const provider = "https://testnet-rpc.helachain.com";

const Wallet = () => {
    const { account, chainId } = useActiveWeb3React();
    const [showConnectWallet, setShowConnectWallet] = useState(false);
    const [infoWallet, setInfoWallet] = useState(false);
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.currentUser);
    const address = currentUser.address;
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const accountEllipsis = address ? `${address.substring(0, 5)}...${address.substring(address.length - 5)}` : "Connect Wallet";
    const [nativeBalance, setNativeBalance] = useState(0);
    useEffect(() => {
        if(account) {
            dispatch(allActions.userActions.setCurrentUser({ "address": account }));
            dispatch(allActions.authAction.getAuthToken({ "address": account }));
        }
    }, [account]);

    useEffect(() => {
        if(account && currentUser.b) {
            
            localStorage.setItem(Tokens.AUTHUSER, currentUser && currentUser.b);
            dispatch(allActions.authAction.getAuthKey({ "address": account }));
        }
    }, [account, currentUser.b]);

    useEffect(() => {
        if (account) {
            w3.eth.getBalance(account, (err, balance) => {
                if (balance) {
                    let _balance = web3.utils.fromWei(
                        web3.utils.toBN(balance),
                        "ether"
                    );
                    let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
                    setNativeBalance(bnbBl);
                }
            });
        }
    }, [account]);


    // useEffect(() => {
    //     if (chainId === undefined && MAPPING_CHAINID_DECIMAL['helatestnet'] !== chainId) {
    //         networkChange(MAPPING_CHAINID['helatestnet']);
    //     }
    // }, [chainId]);
    

    useEffect(() => {
        const walletconnect = window.localStorage.getItem("walletconnect");
        if(walletconnect || account) {
            if(walletconnect) {
                const arrWallet = JSON.parse(walletconnect);
                const address = arrWallet.accounts && arrWallet.accounts[0];
                const chainId = arrWallet.chainId;
    
                dispatch(allActions.userActions.setChainId(chainId));
            } else {
                // dispatch(allActions.userActions.setCurrentUser(account));
                // dispatch(allActions.userActions.setChainId(chainId));
            }
        } else {
            // dispatch(allActions.userActions.setCurrentUser(null));
            // dispatch(allActions.userActions.setChainId(null));
        }
        
    }, [account, chainId]);

    const handleOpenWallet = () => {
        if(address) {
            setInfoWallet(true);
        } else {
            setShowConnectWallet(true);
        }
    }
    const onCancel = (value) => {
        setShowConnectWallet(value);
        setInfoWallet(value);
    };

    const onchangeNet = () => {
        if (chainId !== 666888) {
            networkChange('0xa2d08');
        }
    };
    
    return(
        <> 
            <button className="c-btnWallet" onClick={() => handleOpenWallet()}>{accountEllipsis}</button>

            {/* {account && chainId !== 666888 ? (
                <button className="c-btnWallet bg-danger" onClick={() => onchangeNet()}>Wrong network: {accountEllipsis}</button>
            ):(
                <button className="c-btnWallet" onClick={() => handleOpenWallet()}>{accountEllipsis}</button>
            )} */}
            
            
            {showConnectWallet ? <ConnectWallet onCancel={onCancel} /> : ''}
            {infoWallet ? <InfoWallet onCancel={onCancel} /> : ''}
        </>
    );
}

export default Wallet;