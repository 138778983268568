import { Empty } from "antd";
import React, { useEffect, useState } from "react";
import { NftApi } from "../../../config/api/apiNFT";

import { useActiveWeb3React } from "../../../hooks";
import OfferItem from "./OfferItem";


const OfferHistory = (props) => {
    const { account, chainId } = useActiveWeb3React();
    const { data, endAuction, currentBidOwner, currentBidPrice, auctionIndex, auctionStatus } = props;
    const [logs, setLogs] = useState([]);

    // console.log('endAuction', endAuction);
    // console.log('currentBidOwner', currentBidOwner);
    // console.log('currentBidPrice', currentBidPrice);

    const getOfferLogs = async (merlionId) => {
        let rs = await NftApi.getOfferLogs(merlionId);
        if(rs.status === 200){
            setLogs(rs.data);
        }
    }

    useEffect(() => {
        if(data){
            getOfferLogs(data?.id)
        }
    }, [data]);


    return (
        <>
            {logs.length > 0 ? (
                <>
                    <div className="main-table">
                        <table className="table-mint">
                            <thead>
                                <tr>
                                    <th>Price</th>
                                    <th>USD Price</th>
                                    <th>Expiration</th>
                                    <th>From </th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {logs.length > 0 && logs.map((item, index) => {
                                    return(
                                        <><OfferItem data={data} item={item} index={index} endAuction={endAuction} currentBidOwner={currentBidOwner} currentBidPrice={currentBidPrice} auctionIndex={auctionIndex} auctionStatus={auctionStatus}/></>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            ):(
                <>
                    {/* <div className="no-data text-center">
                        <img width={200} src="../images/nodata.svg" />
                        <p className="text-black-green text-weight-500">You don't have any offer</p>
                        <button type="button" className="btn-basic w-fitcontent" onClick={()=> window.location.replace('/mint-nft')}>
                            Mint now
                        </button>
                    </div> */}
                    <div className="no-data text-center">
                        <img width={200} src="../images/box-nodata.svg" />
                        <p className="text-black-green text-weight-500">No data.</p>
                    </div>
                </>
            )}
            
        </>
    )
}
export default OfferHistory
