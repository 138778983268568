
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Layout, Row, Col, Modal, Form, Input, Select, Spin, InputNumber, Checkbox, Alert, Space, Radio, Tabs, Pagination } from 'antd';
import { useActiveWeb3React } from '../../hooks';
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons';

import web3 from 'web3';
import { ethers } from 'ethers';
import { getInformationByChain } from '../../config/network/multichainAddresses';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import { Loader } from '../common/component';
import { GeneisNftApi } from '../../config/api/apiGeneisNFT';
import NoneData from '../element/NoneData';
import { _buyNftHLUSD } from '../mint-nft/utilsNFT';
import { useMarketGenesesNFTContract, useMarketNFTContract } from '../../hooks/useContract';
import { GenesesNftApi } from '../../config/api/genesNFTApi';
import { NFTBYTYPE } from '../../constants';

const NftMarketPlace = (props) => {
    const { zealyNftAddress, nftAddress, marketAddress } = props;
    const { account, library, chainId } = useActiveWeb3React();

    const [form] = Form.useForm();
    const w3 = new web3(window.ethereum);
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

    const [showBuyModal, setShowBuyModal] = useState(false);
    const [detailModal, setDetailModal] = useState(false);
    const [nfts, setNfts] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRow, setTotalRow] = useState(0);
    const [hlusdBalance, setHlusdBalance] = useState(0);
    const [buyItem, setBuyItem] = useState(null);
    const [detailItem, setDetailItem] = useState(null);

    let marketContract = useMarketGenesesNFTContract(marketAddress)

    const [sortActive , setSortActive] = useState('PRICE_LOW_2_HIGH');
    const [sltType , setSltType] = useState(null);

    const [param, setParam] = useState(
        {
            pageNumber: 0,
            pageSize: 20,
            sortBy: sortActive,
            nftTypeEnum: sltType
        }
    );

    const getListNft = async ()=>{
        setLoadingData(true);
        
        try {
            let res = await GeneisNftApi.getAllGeneisNftMarket(param);
            setTotalRow(parseInt(res.headers['x-total-count']));
      
            if (res.status === 200) {
                setNfts(res.data);
            } else {
                setNfts([]);
            }
            setLoadingData(false);
        } catch (error) {
            console.log('Error: ', error);
            setLoadingData(false);
        }
    }

    const getNativeBalance = async (address) => {
        try {
           
            let balanMainToken = await w3.eth.getBalance(address);
            
            setHlusdBalance(Number(balanMainToken) / 1e18);

        } catch (error) {
            console.log('error', error);
        }
    }

    useEffect(() => {
        
        if (chainId && account) {
            getNativeBalance(account);
        }
    }, [account, chainId]);

    useEffect(() => {
        getListNft();
    }, [param]);

    const handleShowBuy = (item)=>{
        setBuyItem(item);
        setShowBuyModal(true);
    }
    const handleShowDetail = (item)=>{
        setDetailItem(item);
        setDetailModal(true);
    }

    const confirmBuy = async (data)=>{
        setLoading(true);

        // console.log('data', data);

        let nftSelAddress = nftAddress;

        if(data?.nftGenesis?.nftTypeEnum == "HELA_ZEALY_NFT"){
            nftSelAddress = zealyNftAddress;
        }

        // console.log('nftSelAddress', nftSelAddress);
        // console.log('marketAddress', marketAddress);
        // console.log('data?.nftGenesis?.nftId', data?.nftGenesis?.nftId);
        // console.log('data?.price', data?.price);
        
        if (Number(data?.price) <= 0 || hlusdBalance < Number(data?.price)) {
            setLoading(false);
            Store.addNotification(addNotify('Invalid balance.', 'danger'));
            return;
        }
        if (!marketContract) {
            setLoading(false);
            Store.addNotification(addNotify('Market contract invalid.', 'danger'));
            return;
        }
        if (!nftSelAddress) {
            setLoading(false);
            Store.addNotification(addNotify('NFT contract invalid.', 'danger'));
            return;
        }
        if (Number(data?.nftGenesis?.chainId) !== chainId) {
            setLoading(false);
            Store.addNotification(addNotify('Invalid chain.', 'danger'));
            return;
        }
        if (!data?.nftGenesis?.nftId) {
            setLoading(false);
            Store.addNotification(addNotify('Nft not found.', 'danger'));
            return;
        }

        await _buyNftHLUSD(marketContract , nftSelAddress, data?.nftGenesis?.nftId, Number(data?.price))
        .then((res) => {
            let resWait = res.wait();
            resWait.then(async (resTransaction) => {

                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    
                    let payload = {
                        "buyHash": res.hash,
                        "buyerAddress": account,
                        "id": data?.id,
                        "marketplaceId": data?.id,
                        "ownerAddress": data?.ownerAddress,
                        "price": Number(data?.price)
                    };
                    let resAp = await GenesesNftApi.buyNft(payload);
                    if (resAp.status === 201) {
                        Store.addNotification(addNotify('Buy successfully', 'success'));
                        setLoading(false);
                        setShowBuyModal(false);

                        setTimeout(() => {
                            localStorage.setItem('mainKey', 'my-nfts');
                            window.location.reload();
                        }, 2000);
                    }else{
                        Store.addNotification(addNotify('Server Error', 'danger'));
                        setLoading(false);
                    }
                    
                }
                else {
                    setLoading(false);
                    Store.addNotification(addNotify('Transaction failed', 'warning'));
                    return;
                }

            })
            .catch((error) => {
                setLoading(false);
                Store.addNotification(addNotify(error?.message ? error?.message : 'Server Error !!!', 'danger'));
            });
        })
        .catch((error) => {
            setLoading(false);
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        });
    }

    const handleChangeSort = (value) => {
       setSortActive(value);
        setParam({
            ...param,
            sortBy: value
        });
    };
    const handleChangeType = (value) => {
       setSltType(value);
        setParam({
            ...param,   
            nftTypeEnum: value ? value : null
        });
    };

    const onChange_Pagi = (number) => {
        setParam({
            ...param,
            pageNumber: number - 1
        });
    };
    
    let accountStr = account ? account.toLowerCase() : '';

    return (
        <>
            <Row gutter={30}>
                <Col xs={24} className="text-right">
                    <div className="filter-nft">
                        <Select
                            className="custom-slt"
                            defaultValue={sltType}
                            placeholder="Filter..."
                            style={{ width: 190, marginRight: '10px' }}
                            onChange={handleChangeType}
                            options={[
                                {
                                    value: '',
                                    label: 'All',
                                },
                                {
                                    value: 'OG_NFT',
                                    label: 'HeLa OG NFT',
                                },
                                {
                                    value: 'EARLY_BIRD_NFT',
                                    label: 'HeLa Early Bird NFT',
                                },
                                {
                                    value: 'RANGER_NFT',
                                    label: 'Super HeLa Ranger NFT',
                                },
                                {
                                    value: 'HELA_ZEALY_NFT',
                                    label: 'HeLa Zealy NFT',
                                }
                            ]}
                        />
                        <Select
                            className="custom-slt"
                            defaultValue={sortActive}
                            style={{ width: 120, marginRight: '10px' }}
                            onChange={handleChangeSort}
                            options={[
                                {
                                    value: 'PRICE_HIGH_2_LOW',
                                    label: 'High to low',
                                },
                                {
                                    value: 'PRICE_LOW_2_HIGH',
                                    label: 'Low to high',
                                }
                            ]}
                        />
                    </div>
                </Col>

                {!loadingData ? (
                    <>
                        {nfts.length > 0 ? (
                            nfts.map((item, index) => {

                                let nameByType = NFTBYTYPE[item?.nftGenesis?.nftTypeEnum];

                                return (
                                    <Col xs={24} sm={6}>
                                        <div className='mint-nft-item'>
                                            <div className='nft-img' onClick={()=>handleShowDetail(item)}>
                                                <div className='nft-id'>#{item?.nftGenesis?.nftId}</div>
                                                <img src={item?.nftGenesis?.nftImage ? item?.nftGenesis?.nftImage : '../images/hela-nft.png'} />
                                            </div>
                                            <div className='nft-info'>
                                                <p className='mt-0 mb-5 text-weight-500 text-regular-blue text-dot' title={nameByType} onClick={()=>handleShowDetail(item)}>{nameByType}</p>
                                                <div className='flex-btw mt-10'>
                                                    <p className='mt-0 mb-0 text-weight-600 logo-mint'>
                                                        <img width={23} src='../images/helalogo.svg' alt=''/> {item?.price} HLUSD
                                                    </p>
                                                    {item?.ownerAddress == accountStr ? (
                                                        <>
                                                            <button className='btn-basic w-fitcontent ptb-5' disabled>Buy</button>
                                                        </>
                                                    ):(
                                                        <>
                                                            <button className='btn-basic w-fitcontent ptb-5' disabled={!account || hlusdBalance <= 0} onClick={()=>handleShowBuy(item)}>Buy</button>
                                                        </>
                                                    )}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })
                        ) : (
                            <>
                                <Col className="text-center" xs={24}>
                                    <div className="no-data text-center">
                                        <img width={200} src="../images/box-nodata.svg" />
                                        <p className="text-black-green text-weight-500">No data.</p>
                                    </div>
                                </Col>
                                
                            </>
                        )}
                    </>
                ) : (<>
                    <Col xs={24}>
                        <div className="loading-data text-center">
                            <Space size="middle">
                                <Spin size="large" />
                            </Space>
                        </div>
                    </Col>
                    
                </>)}

                
                
            </Row>
            {totalRow > param.pageSize && (
                <Pagination
                    current={param.pageNumber + 1}
                    defaultCurrent={1}
                    total={totalRow}
                    pageSize={param.pageSize}
                    onChange={onChange_Pagi}
                    showSizeChanger={false}
                    className="pagiation-custom"
                />
            )}

            <Modal className="modal-mint modal-list-done" open={showBuyModal} onOk={()=> setShowBuyModal(false)} onCancel={()=> setShowBuyModal(false)}>
                <div className="modal-content-mint">
                    <div className="title text-darkblue">
                        Buy NFT
                    </div>
                    <div className="mt-15 mb-15 modal-nft">
                        <Row gutter={32}>
                            <Col className="gutter-row" xs={24} xl={7} md={7} sm={7}>
                                <div className="modal-nft-img">
                                    <img width={140} src={buyItem?.nftGenesis?.nftImage} />
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={17} md={17} sm={17}>
                                <div className="modal-nft-info">
                                    <div className="nft-info-title">#{buyItem?.nftGenesis?.nftId}</div>
                                    <div className="nft-info-name">{buyItem?.nftGenesis?.name}</div>
                                    <div className="nft-info-label">Listing price:</div>
                                    <div className="nft-info-price">
                                        <img width={25} src="../../images/helalogo.svg" alt="" /> 
                                        {new Intl.NumberFormat("ja-JP").format(buyItem?.price)} HLUSD
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    
                   
                    <div className="text-center mt-15">
                        <button disabled={loading || !account || hlusdBalance < Number(buyItem?.price)} onClick={()=>confirmBuy(buyItem)} className="btn-basic w-200">Buy </button>
                    </div>
                </div>
            </Modal>
            <Modal className="modal-mint modal-list-done" open={detailModal} onOk={()=> setDetailModal(false)} onCancel={()=> setDetailModal(false)}>
                <div className="modal-content-mint">
                    <div className="title text-darkblue">
                        {detailItem?.nftGenesis?.name}
                    </div>
                    <div className="mt-15 mb-15 modal-nft">
                        <Row gutter={32}>
                            <Col className="gutter-row" xs={24}>
                                <div className="modal-nft-img text-center">
                                    <img style={{maxWidth: '500px'}} src={detailItem?.nftGenesis?.nftImage} />
                                </div>
                            </Col>
                            
                        </Row>
                    </div>
                </div>
            </Modal>
            {loading && (
                <Loader />
            )}
        </>
    )
}

export default NftMarketPlace
