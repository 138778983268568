import { instance } from '../../apiBase/instance';
let tokenID = localStorage.getItem('tokenId');

export const GeneisNftApi = {

	getSettingKey(key) {
		return instance.get(`settings/by-key/${key}`, {});
	},
	getAllGeneisNft(params) {
		return instance.get(`nft-geneses`, { 
			params,
			headers: { Authorization: `Bearer ${tokenID}` }
		 });
	},
	getAllGeneisNftMarket(data) {
		return instance.post(`nft-genesis-markets/search`, data, {
			headers: { Authorization: `Bearer ${tokenID}` }
		 });
	},
	getMyGeneisNft(params) {
		return instance.get(`my-nft-genesis/${params?.ownerAddress}`, { 
			params, 
			headers: { Authorization: `Bearer ${tokenID}` } 
		});
	},
	generateNftId(data) {
		return instance.post(`nft-geneses/generate-nft-id`,  data , {
			headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	checkUserHasWhitelist(address) {
		return instance.get(`nft-genesis-whitelists/${address.toLowerCase()}/verify`, {
			headers: { Authorization: `Bearer ${tokenID}` }
		});
	},

};
