
import React from 'react';
// import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, useLocation } from 'react-router-dom';
import { Layout, Row, Col, Modal, Form, Steps, Breadcrumb } from 'antd';

import { FormOutlined, LeftCircleOutlined } from '@ant-design/icons';
import './style.scss';
import AirdropVerifyToken from './airdrop-verify-token';
import AirdropContent from './airdrop-content';
import FooterHome from '../../common/footer/footerHome';
const { Content } = Layout;
const { Step } = Steps;

const AirdropCreate = () => {
    const location = useLocation();
    const [form] = Form.useForm();
    let initStep = 0;
    const [current, setCurrent] = React.useState(initStep);



    const steps = [
        {
            title: 'Airdrop Verify Token',
            content: <AirdropVerifyToken next={setCurrent} />,
            description: ''
        },
        {
            title: 'Airdrop Info',
            content: <AirdropContent />,
            description: 'Enter the launchpad information that you want to raise, that should be enter all details about your resale'
        }
    ];
    // let storageAirdropTokenInfo = window.localStorage.getItem('airdropTokenInfo');
    // let airdropTokenInfo;
    // if (storageAirdropTokenInfo) {
    //     airdropTokenInfo = JSON.parse(storageAirdropTokenInfo);
    // }
    const prev = () => {
        setCurrent(current - 1);
    };
    return (
        <>
          
            <div className="airdrop-create-page">
                <div className='container'>
                    <Breadcrumb className='head-breadcrumb-airdrop'>
                        <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Create Airdrop</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="create-form">
                        <div className="airdrop-create-title">New Airdrop</div>
                        <div className="create-step">
                            <div className="anti-bot-block">
                                <div className="steps-content-antibot">
                                    {steps[current].content}
                                </div>
                                <div className="steps-action">
                                    {current > 0 && (
                                        <button className="btn-basic-default p-lr-20 w-200" type="button" style={{ margin: '0 8px' }} onClick={() => prev()}>
                                            Previous
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterHome />
        </>
    )
}

export default AirdropCreate
