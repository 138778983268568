import React from 'react';
import { Link } from 'react-router-dom'; 

const ItemGamehubRecommed = (props) => {  
    const {item, marketPriceArr} = props;  
    const marketPrice = marketPriceArr?.find(({id})=> id===item.tokenId);  
    return(
        <Link to={`/gamehub/${item?.id}`} className="c-listRecommen__item">
            <div className="c-listRecommen__img">
                <img src={item?.poster} alt="" />
            </div>
            <div className="c-listRecommen__body">
                <h3 className="c-listRecommen__title">{item?.title}</h3>
                <div className="c-listRecommen__text" dangerouslySetInnerHTML={{__html: item?.description}}></div>
                <div className="c-listRecommen__price">
                    <span className="c-listRecommen__logo"><img src={marketPrice?.image} alt="" /></span>
                    <p>$ {marketPrice?.current_price}<span className={marketPrice?.price_change_percentage_24h > 0 ? 'up' : 'down'}>{marketPrice?.price_change_percentage_24h}%</span></p>
                </div>
            </div>
        </Link>
    )
}

export default ItemGamehubRecommed;