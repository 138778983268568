
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { Layout, Row, Col, Form, Input, Select, Radio, Space } from 'antd';
import {
    INIT_PRIVATE_SALE_FEE,
    INIT_PRIVATE_SALE_TOKEN_FEE,
    INIT_PRIVATE_SALE_HUP_FEE
} from '../../../keyConfig';
import { privatesSaleApi } from '../../../../config/api/privatesSaleApi';
import { useActiveWeb3React } from '../../../../hooks';

const { Option } = Select;

const DefiLaunchpadInfo = (props) => {
    const { form } = props;
    const { account, chainId } = useActiveWeb3React();
    const [feeOptions, setFeeOptions] = useState();
    let feeOptionDefault = JSON.stringify([INIT_PRIVATE_SALE_FEE]);

    let storagePrivateSaleInfo = window.localStorage.getItem('privateSaleInfo');
    let storageStep1 = window.localStorage.getItem('step1');
    let privateSaleInfo;
    let step1;
    if (storagePrivateSaleInfo) {
        privateSaleInfo = JSON.parse(storagePrivateSaleInfo);
    }
    if (storageStep1) {
        step1 = JSON.parse(storageStep1);
    }
    const [initPrivateSaleFeeOpj, setInitPrivateSaleFee0pj] = useState();
    const [initPrivateSaleTokenFeeOpj, setInitPrivateSaleTokenFee0pj] = useState();
    const [initPrivateSaleW86FeeOpj, setInitPrivateSaleW86Fee0pj] = useState();
    const [feeList, setFeeList] = useState([]);
    const [hardCap, setHardCap] = useState(0);

    const [checkUpdate, setCheckUpdate] = useState(false);

    const getSettingsByChain = async () => {
        try {
            let res = await privatesSaleApi.getSettingsByChain(chainId);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setFeeList(res.data);

                    let initPrivateSaleFee = res.data.filter((itemSetting) => INIT_PRIVATE_SALE_FEE === itemSetting.settingKey);
                    if (initPrivateSaleFee && initPrivateSaleFee[0]) {
                        setInitPrivateSaleFee0pj(initPrivateSaleFee[0]);
                    }

                    let initPrivateSaleTokenFee = res.data.filter((itemSetting) => INIT_PRIVATE_SALE_TOKEN_FEE === itemSetting.settingKey);
                    if (initPrivateSaleTokenFee && initPrivateSaleTokenFee[0]) {
                        setInitPrivateSaleTokenFee0pj(initPrivateSaleTokenFee[0]);
                    }
                    let initPrivateSaleW86Fee = res.data.filter((itemSetting) => INIT_PRIVATE_SALE_HUP_FEE === itemSetting.settingKey);
                    if (initPrivateSaleW86Fee && initPrivateSaleW86Fee[0]) {
                        setInitPrivateSaleW86Fee0pj(initPrivateSaleTokenFee[0]);
                    }
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    }

    useEffect(() => {
        if (chainId) {
            getSettingsByChain();
        }
    }, [chainId]);

    useEffect(() => {
        if (!checkUpdate) {
            form.setFieldsValue({
                saleType: privateSaleInfo?.saleType || 'PUBLIC',
                claimTypeToken: privateSaleInfo?.claimTypeToken,
                swapRate: privateSaleInfo?.swapRate,
                joinContract: privateSaleInfo?.joinContract,
                networkChain: privateSaleInfo?.networkChain,
                ownerAddress: privateSaleInfo?.ownerAddress,
                softCap: privateSaleInfo ? privateSaleInfo.softCap : 0,
                minimum_buy: privateSaleInfo ? privateSaleInfo.minimum_buy : '',
                hardCap: privateSaleInfo ? privateSaleInfo.hardCap : 0,
                maximum_buy: privateSaleInfo ? privateSaleInfo.maximum_buy : '',
                start_time: privateSaleInfo ? privateSaleInfo.start_time : '',
                end_time: privateSaleInfo ? privateSaleInfo.end_time : '',
                claim_time: privateSaleInfo ? privateSaleInfo.claim_time : '',
                title: privateSaleInfo ? privateSaleInfo.title : '',
                first_fund_release: privateSaleInfo ? privateSaleInfo.first_fund_release : '',
                fund_vesting_period_each_cycle: privateSaleInfo ? privateSaleInfo.fund_vesting_period_each_cycle : '',
                fund_release_each_cycle: privateSaleInfo ? privateSaleInfo.fund_release_each_cycle : '',
            })
            setCheckUpdate(true);
        }

    }, [privateSaleInfo]);

    const changeValueHardCap = (values) => {
        form.setFieldsValue({
            softCap: parseFloat(values),
        });
    };

    const onChangeFee = e => {
        setFeeOptions(e.target.value);
    };

    let initPrivateSaleFeeDis = 0;
    let initPrivateSaleTokenFeeDis = 0;
    if (initPrivateSaleFeeOpj && initPrivateSaleTokenFeeOpj && initPrivateSaleW86FeeOpj) {
        initPrivateSaleFeeDis = initPrivateSaleFeeOpj.settingValue - (initPrivateSaleFeeOpj.settingValue * (initPrivateSaleW86FeeOpj.settingValue / 100));
        initPrivateSaleTokenFeeDis = initPrivateSaleTokenFeeOpj.settingValue - (initPrivateSaleTokenFeeOpj.settingValue * (initPrivateSaleW86FeeOpj.settingValue / 100));
    }

    return (
        <>
            <div className="title-steps">Private Sale Info</div>
            <div className="launchpad-info-form">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                    <Form.Item name="step_launchpad_info" label="Presale rate" initialValue={2} hidden={true}>
                        <Input className="amount-buy" />
                    </Form.Item>

                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <Form.Item
                            name="saleType"
                            label="Sale Type"
                            rules={[{ required: true, message: 'Please pick Sale Type!' }]}
                            help={<><small className="warning-form blue-text">You can enable/disable whitelist anytime</small></>}
                        >
                            <Radio.Group>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                                        <Radio value="PUBLIC">PUBLIC</Radio>
                                    </Col>
                                    <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                                        <Radio value="WHITE_LIST">WHITE_LIST</Radio>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <Form.Item
                            label="Swap Rate"
                            name="swapRate"
                            rules={[{ required: true, message: 'Swap Rate can not be null' }]}
                        >
                            <Input className="amount-buy" type="number" placeholder="Ex: 800" />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="softCap" label={`Softcap (${step1.currencyApply})`}
                            rules={
                                [
                                    { required: true, message: 'Please input Softcap!' },
                                    {
                                        validator: (rule, value, cb) => {
                                            !value || parseFloat(value) <= 0 || form.getFieldValue('hardCap') <= 0 || parseFloat(value) < form.getFieldValue('hardCap') / 2 || parseFloat(value) >= form.getFieldValue('hardCap')
                                                ? cb("Softcap must be >= 50% of Hardcap!")
                                                : cb();
                                        }
                                    }
                                ]
                            }
                            help="Softcap must be {'>='} 50% of Hardcap!"
                        >
                            <Input className="amount-buy" type="number" placeholder="0" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="hardCap" label={`HardCap (${step1.currencyApply})`}
                            rules={[
                                { required: true, message: 'Please input HardCap!' },
                                {
                                    validator: (rule, value, cb) => {
                                        !value || parseFloat(value) <= 0
                                            ? cb("HardCap must be > 0")
                                            : cb();
                                    }
                                }
                            ]}
                            help="    ">
                            <Input className="amount-buy" type="number" placeholder="0" onChange={(e) => {
                                changeValueHardCap(form.getFieldValue('softCap'));
                                setHardCap(parseFloat(e.target.value))
                            }} />

                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>

                        <Form.Item name="minimum_buy" label={`Minimum buy (${step1.currencyApply})`}
                            rules={[
                                { required: true, message: 'Please input Minimum buy!' },
                                {
                                    validator: (rule, value, cb) => {
                                        !value || parseFloat(value) <= 0
                                            ? cb("Minimum must be > 0")
                                            : cb();
                                    }
                                }
                            ]}
                        >
                            <Input className="amount-buy" type="number" placeholder="0" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="maximum_buy" label={`Maximum buy (${step1.currencyApply})`}

                            rules={[
                                { required: true, message: 'Please input Maximum buy!' },
                                {
                                    validator: (rule, value, cb) => {
                                        !value || parseFloat(value) <= 0
                                            ? cb("Maximum must be > 0")
                                            : cb();
                                    }
                                }
                            ]}>
                            <Input className="amount-buy" type="number" placeholder="0" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <p>
                            <strong className="warning-form"><i>Select start time & end time (UTC)*</i></strong><br />
                        </p>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="start_time" label="Start time"
                            rules={[
                                { required: true, message: 'Please input Start time!' },
                                {
                                    validator: (rule, value, cb) => {
                                        !value || new Date(value) <= new Date(Date.now())
                                            ? cb("Start time needs to be after now")
                                            : cb();
                                    }
                                }
                            ]}>
                            <Input className="amount-buy" type="datetime-local" placeholder="0" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="end_time" label="End time"
                            rules={[
                                { required: true, message: 'Please input End time!' },
                                {
                                    validator: (rule, value, cb) => {

                                        if (!value || new Date(value) <= new Date(Date.now())) {
                                            cb("End time needs to be after now time");
                                        } else if (new Date(value) <= form.getFieldValue('start_time')) {
                                            cb("End time needs to be after start time");
                                        } else {
                                            cb();
                                        }
                                    }
                                }
                            ]}>
                            <Input className="amount-buy" type="datetime-local" placeholder="0" />
                        </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="first_fund_release" label="First Fund Release For Project (%)"
                            rules={[
                                { required: true, message: 'Please input First Fund Release For Project (%)!' },
                                {
                                    validator: (rule, value, cb) => {
                                        if (!value || parseFloat(value) <= 0) {
                                            cb('Invalid Value');
                                        } else if (parseFloat(value) + parseFloat(form.getFieldValue('fund_release_each_cycle') || 0) > 100) {
                                            cb('Total Percent Must Be <= 100!');
                                        } else {
                                            cb();
                                        }
                                    }
                                }]}>
                            <Input className="amount-buy" type="number" placeholder="Ex: 40" />
                        </Form.Item>
                    </Col>
                    <Col className='gutter-row' xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name='fund_vesting_period_each_cycle'
                            label='Fund Vesting Period Each Cycle (minutes)' rules={[{
                                required: true,
                                message: 'Please input Fund Vesting Period Each Cycle (minutes)!'
                            }]}>
                            <Input className='amount-buy' type='number' placeholder='Enter mins. Ex: 5' />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="fund_release_each_cycle" label="Fund Release Each Cycle (percent)"
                            rules={[
                                { required: true, message: 'Please input Fund Release Each Cycle (percent)!' },
                                {
                                    validator: (rule, value, cb) => {
                                        if (!value || parseFloat(value) <= 0) {
                                            cb('Invalid Value');
                                        } else if (parseFloat(value) + parseFloat(form.getFieldValue('first_fund_release') || 0) > 100) {
                                            cb('Total Percent Must Be <= 100!');
                                        } else {
                                            cb();
                                        }
                                    }
                                }]}>
                            <Input className="amount-buy" type="number" placeholder="20" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                        <Form.Item
                            name="fee_options"
                            label="Fee Options"
                            initialValue={feeOptions}
                            rules={[{ required: true, message: 'Please pick fee options!' }]}
                        >
                            <Radio.Group onChange={onChangeFee}>
                                <Space direction="vertical" className='ml-15'>
                                    {initPrivateSaleFeeOpj && initPrivateSaleTokenFeeOpj && ( // BNB fee
                                        <Radio value={JSON.stringify([INIT_PRIVATE_SALE_FEE, INIT_PRIVATE_SALE_TOKEN_FEE])} key={'fee-1'}>
                                            {initPrivateSaleFeeOpj.settingValue}{initPrivateSaleFeeOpj.feeType === "PERCENT" ? '%' : ''} {initPrivateSaleFeeOpj?.payToken} + {initPrivateSaleTokenFeeOpj.settingValue}{initPrivateSaleTokenFeeOpj.feeType === "PERCENT" ? '%' : ''} of {initPrivateSaleTokenFeeOpj?.payToken} Sold
                                        </Radio>
                                    )}
                                    {initPrivateSaleW86FeeOpj && initPrivateSaleTokenFeeDis > 0 && initPrivateSaleFeeDis > 0 && ( // BNB only fee
                                        <Radio disabled={true} value={JSON.stringify([INIT_PRIVATE_SALE_HUP_FEE, INIT_PRIVATE_SALE_FEE])} key={'fee-11'}>
                                            Amount of <span className='text-info'>HLUP</span> equivalent to {initPrivateSaleFeeDis} {initPrivateSaleFeeOpj?.payToken} + {initPrivateSaleTokenFeeDis}{initPrivateSaleTokenFeeOpj.feeType === "PERCENT" ? '%' : ''} of {initPrivateSaleTokenFeeOpj?.payToken} Sold <span className='text-danger'>({initPrivateSaleW86FeeOpj.settingValue}{initPrivateSaleW86FeeOpj.feeType === "PERCENT" ? '%' : ''} discount)</span> <small className='text-info'>(Comming soon)</small>
                                        </Radio>
                                    )}

                                    
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default DefiLaunchpadInfo
