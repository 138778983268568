export const contractAddress = {
	97: {
		"REACT_APP_DEPLOY_LAUNCHPAD": "0x5A1f24E7d7a1ea6fE9804AC25936376abca1311E",
		"REACT_APP_DEPLOY_AIRDROP": "0xA032Ad03786a97a6e1Bc9EFf4B6b6e60c71768b9",
		"REACT_APP_DEPLOY_PRIVATE_SALE": "0x9d064dc2d80B7e06e8805555d8D1fB9B09DB3159",
		"REACT_APP_WAVE_LOCK": "0xe94B23FC4529fb00AE23162fC1dcB5Bc3FD72D75",
		"REACT_APP_ANTI_BOT": "0x072B47167C716C1C229F3B6C1ba676bA06a140cC",
		"REACT_APP_MULTI_SENDER": "0xD16BAa9d2888cb929B3c1cfa6684ac20A53383cE",
		"REACT_APP_RPC_URL": "https://data-seed-prebsc-1-s1.binance.org:8545/",
		"REACT_APP_SCAN_URL": "https://testnet.bscscan.com/",
		"REACT_APP_SYMBOL": "BNB",
		"REACT_APP_DECIMALS": 18
	},
	56: {
		"REACT_APP_DEPLOY_LAUNCHPAD": "0x3e061F4b77C8357E80c52590AF3bBaC1924fB705",
		"REACT_APP_DEPLOY_AIRDROP": "0x799e95F6761054421b7eB79f2E236e26c8a6DF94",
		"REACT_APP_DEPLOY_PRIVATE_SALE": "0x40AB3a5D6C991f001FF9Ec0347535B1cc461EA8f",
		"REACT_APP_WAVE_LOCK": "0x9e3998AE18619f2FFD1388d460b9243CdDc71E5D",
		"REACT_APP_ANTI_BOT": "0xB9607e42Ac332c33cb8d7219d7703FcB55581aD5",
		"REACT_APP_MULTI_SENDER": "0x25cE4c802C8309a3F0703CCcA08C91bFd213c567",
		"REACT_APP_RPC_URL": "https://bsc-dataseed.binance.org",
		"REACT_APP_SYMBOL": "BNB",
		"REACT_APP_DECIMALS": 18
	},
	137: {
		"REACT_APP_DEPLOY_LAUNCHPAD": "0x81F83AEA18cbc9C57a19F911A53aB4a2555d70DE",
		"REACT_APP_DEPLOY_AIRDROP": "0xFF4E3E10A34D8Cb2a7c34ddf0Fc1E79ecc419585",
		"REACT_APP_DEPLOY_PRIVATE_SALE": "0x21Fe40CB1d188f66F5b293171CA393E5171Ed343",
		"REACT_APP_WAVE_LOCK": "0xd601b8cB8d98db5b6147e1970d36876d816736c7",
		"REACT_APP_ANTI_BOT": "0x8CC847e44281953282fA6E783f9D62bb65cA6e2B",
		"REACT_APP_MULTI_SENDER": "0x297435ce68C345255045d61c727726Cac943BD4C",
		"REACT_APP_RPC_URL": "https://polygon-rpc.com",
		"REACT_APP_SYMBOL": "POLYGON",
		"REACT_APP_DECIMALS": 18
	},
	43114: {
		"REACT_APP_DEPLOY_LAUNCHPAD": "0xc3b52275f27ca68941181ec1F1aE12c2B2a599C9",
		"REACT_APP_DEPLOY_AIRDROP": "0xFF4E3E10A34D8Cb2a7c34ddf0Fc1E79ecc419585",
		"REACT_APP_DEPLOY_PRIVATE_SALE": "0x21Fe40CB1d188f66F5b293171CA393E5171Ed343",
		"REACT_APP_WAVE_LOCK": "0xd601b8cB8d98db5b6147e1970d36876d816736c7",
		"REACT_APP_ANTI_BOT": "0x8CC847e44281953282fA6E783f9D62bb65cA6e2B",
		"REACT_APP_MULTI_SENDER": "0x297435ce68C345255045d61c727726Cac943BD4C",
		"REACT_APP_RPC_URL": "https://api.avax.network/ext/bc/C/rpc",
		"REACT_APP_SYMBOL": "AVAX",
		"REACT_APP_DECIMALS": 18
	},
	66: {
		"REACT_APP_DEPLOY_LAUNCHPAD": "0xBFa19A66346B03BF03eF2908C33cC3b3d77f60fE",
		"REACT_APP_DEPLOY_AIRDROP": "0xAd76C15210E26EFF648A42476AbE402d4B2aAc4E",
		"REACT_APP_DEPLOY_PRIVATE_SALE": "0x21Fe40CB1d188f66F5b293171CA393E5171Ed343",
		"REACT_APP_WAVE_LOCK": "0xd601b8cB8d98db5b6147e1970d36876d816736c7",
		"REACT_APP_ANTI_BOT": "0xFF4E3E10A34D8Cb2a7c34ddf0Fc1E79ecc419585",
		"REACT_APP_MULTI_SENDER": "0x297435ce68C345255045d61c727726Cac943BD4C",
		"REACT_APP_RPC_URL": "https://exchainrpc.okex.org",
		"REACT_APP_SYMBOL": "OKT",
		"REACT_APP_DECIMALS": 18
	},
	5: {
		"REACT_APP_DEPLOY_LAUNCHPAD": "0x8CC847e44281953282fA6E783f9D62bb65cA6e2B",
		"REACT_APP_DEPLOY_AIRDROP": "",
		"REACT_APP_DEPLOY_PRIVATE_SALE": "",
		"REACT_APP_WAVE_LOCK": "0xAd76C15210E26EFF648A42476AbE402d4B2aAc4E",
		"REACT_APP_ANTI_BOT": "",
		"REACT_APP_MULTI_SENDER": "",
		"REACT_APP_RPC_URL": "https://goerli.infura.io/v3/",
		"REACT_APP_SCAN_URL": "https://goerli.etherscan.io",
		"REACT_APP_SYMBOL": "ETH",
		"REACT_APP_DECIMALS": 18
	},
	1: {
		"REACT_APP_DEPLOY_LAUNCHPAD": "0x7E8911660c54cEB94a8Ce71dB4E6eB68DfE630Fd",
		"REACT_APP_DEPLOY_AIRDROP": "0x81F83AEA18cbc9C57a19F911A53aB4a2555d70DE",
		"REACT_APP_DEPLOY_PRIVATE_SALE": "",
		"REACT_APP_WAVE_LOCK": "0x297435ce68C345255045d61c727726Cac943BD4C",
		"REACT_APP_ANTI_BOT": "0x8CC847e44281953282fA6E783f9D62bb65cA6e2B",
		"REACT_APP_MULTI_SENDER": "",
		"REACT_APP_RPC_URL": "https://mainnet.infura.io/v3/",
		"REACT_APP_SCAN_URL": "https://etherscan.io",
		"REACT_APP_SYMBOL": "ETH",
		"REACT_APP_DECIMALS": 18
	},
	666888: {
		"REACT_APP_DEPLOY_LAUNCHPAD": "0x1d3DDA1133553d5E40A30115C0aB2108ce2dc04b",
		"REACT_APP_DEPLOY_AIRDROP": "0x8dD3Ab04d5fC19Fac554a309c46e198E3D371A6e",
		"REACT_APP_DEPLOY_PRIVATE_SALE": "",
		"REACT_APP_WAVE_LOCK": "",
		"REACT_APP_ANTI_BOT": "",
		"REACT_APP_MULTI_SENDER": "",
		"REACT_APP_RPC_URL": "https://testnet-rpc.helachain.com",
		"REACT_APP_SCAN_URL": "https://testnet-blockexplorer.helachain.com/",
		"REACT_APP_SYMBOL": "HLUSD",
		"REACT_APP_DECIMALS": 18
	},
	8668: {
		"REACT_APP_DEPLOY_LAUNCHPAD": "0xcfc16da6B59358fD472A12892F05D59Cf771B6b5",
		"REACT_APP_DEPLOY_AIRDROP": "0x3e061F4b77C8357E80c52590AF3bBaC1924fB705",
		"REACT_APP_DEPLOY_PRIVATE_SALE": "",
		"REACT_APP_WAVE_LOCK": "",
		"REACT_APP_ANTI_BOT": "",
		"REACT_APP_MULTI_SENDER": "",
		"REACT_APP_RPC_URL": "https://mainnet-rpc.helachain.com",
		"REACT_APP_SCAN_URL": "https://testnet-blockexplorer.helachain.com/",
		"REACT_APP_SYMBOL": "HLUSD",
		"REACT_APP_DECIMALS": 18
	},

}

export const getInformationByChain = (chainId, key) => {
	const chainInfo = contractAddress[chainId];
	if (chainInfo) {
		return chainInfo[key];
	} else {
		return null;
	}
};
