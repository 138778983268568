import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';


import AOS from 'aos';

import { Loader } from '../common/component';
import HomeBanner from './banner';
import IdoProject from './ido-project';
import ToolToGrow from './tool-to-grow';
import Backer from './backer';
import Community from './community';
import FooterHome from '../common/footer/footerHome';

const Home = () => {
    const [loadingPage, setLoadingPage] = useState(false);

    useEffect(() => {
        AOS.init();
    },[]);
    
    return(
        <div className="p-home">
            {loadingPage && <Loader />}
            
            
            {/* Banner */}
            <HomeBanner />
            <IdoProject />
            <ToolToGrow />
            <Backer />
            <Community />
            <FooterHome />
        </div>
    )
}

export default Home;