import React, {useCallback} from 'react';
import Countdown from "react-countdown";
import { CAMPAIGN_STATUS } from '../../../constants';

const IdoDetailCountdown = ({item}) => { 
    var startJoinPoolDate = new Date(item.startJoinPool);
    var endJoinPoolDate = new Date(item.endJoinPool);
    var startClaimDate = new Date(item.startClaim);
    var nowDate = new Date(); 
    const getProgressTime = useCallback((startTime) => {
        if (!startTime) {
            return `TBA`
        }
        const now = new Date()
        const utcDate = Date.UTC(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            now.getHours(),
            now.getMinutes(),
            now.getSeconds(),
            0
        )
        const startDate = new Date(startTime)
        const startTS = Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            startDate.getHours(),
            startDate.getMinutes(),
            startDate.getSeconds(),
            0
        )
        let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600);
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60);  // in theory the modulus is not required
        if (days > 0) {
            return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000
    
        } else {
            return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000
        }
    }, []);
    
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            window.location.reload(false);
        }
        return (<><span>{days}d </span><span> {hours}h </span><span> {minutes}m </span><span> {seconds}s</span></>);
    };

    const countdownRender = () => {
        let progressTime = null;
        let statusElement = null;
        let text = "";
        
        if(item.roundStatus === CAMPAIGN_STATUS.TBA || item.startIDO === null){
            text = "Starts in ";
            statusElement = (
                <>
                    <div className='coundown-text'>{text}</div>
                    <div className='coundown-time'>TBA</div>
                </>
            );
        } 
        else if (item.roundStatus === CAMPAIGN_STATUS.UPCOMING){
            progressTime = startJoinPoolDate;
            
            text = "Starts in ";
            statusElement = (
                <>
                    <div className='coundown-text'>{text}<img src="../../images/icon-orange.png" alt="" /></div>
                    <div className='coundown-time'>
                        <Countdown date={getProgressTime(progressTime)} renderer={renderer} />
                    </div>
                </>
            );
        }
        else if (item.roundStatus === CAMPAIGN_STATUS.OPENING){
            progressTime = endJoinPoolDate;
            text = "Closes in ";
            statusElement = (
                <>
                    <div className='coundown-text'>{text}<img src="../../images/icon-orange.png" alt="" /></div>
                    <div className='coundown-time'>
                        <Countdown date={getProgressTime(progressTime)} renderer={renderer} />
                    </div>
                </>
            );
        }
        else if (item.roundStatus === CAMPAIGN_STATUS.COMPLETED){
            progressTime = startClaimDate;
            text = "Claim in ";
            statusElement = (nowDate > startClaimDate) ? "" : (
                <>
                    <div className='coundown-text'>{text}<img src="../../images/icon-orange.png" alt="" /></div>
                    <div className='coundown-time'>
                        <Countdown date={getProgressTime(progressTime)} renderer={renderer} />
                    </div>
                </>
            );
        }

        return statusElement;
    }
    return (
        <>{countdownRender()}</>
    );
}

export default IdoDetailCountdown;