import { Pagination } from 'antd';
import React,{useEffect, useState} from 'react' 
import {useDispatch, useSelector} from 'react-redux'; 
import allActions from '../../actions'; 
import ItemGamehubRecommed from './itemGamehubRecommed'; 

const GamehubRecommended = () => {
    const dispatch = useDispatch();
    const [loadingPage, setLoadingPage] = useState(false);
    const [page, setPage] = useState(0); 
    const [recommendPrice, setRecommendPrice] = useState([])
    const size = 6;
    useEffect(() => {
        setLoadingPage(true);  
        dispatch(allActions.gamehubAction.getGameHubByRecommendStatus("recommend", page,  (res)=> {   
            if(res.callResult === true) {  
                dispatch(allActions.gamehubAction.getPriceMarket(res.gamehubRecommendList , (res)=>{ 
                    setRecommendPrice(res?.data); 
                }));  
            }
            setLoadingPage(false);
        })); 
    }, [dispatch , page ]); 

    function onChangePaging(pageNumber) {
        setPage(pageNumber - 1);
    }   
    const gamehubRecommendList = useSelector(state=>state?.gamehub.gamehubRecommendList);
    // const gamehubRecommendPrices = useSelector(state=>state?.gamehub.gamehubRecommendPrices);  
    const gamehubRecommendCount = useSelector(state=>state?.gamehub.gamehubRecommendCount); 
    return (  
        <>
        {loadingPage && <div class="c-loading"><img src="../../images/loading-3s.gif" alt="Loading" /></div>}
        <div className="p-gameHub__box">
            <h3 className="p-gameHub__title">Recommended</h3>
            <div className="c-listRecommen">
                {gamehubRecommendList && gamehubRecommendList.map((item,index) => { 
                    return(
                        <ItemGamehubRecommed item={item} key={index} marketPriceArr={recommendPrice}/>
                    );
                })}
            </div>
            <div className="pani-list">
                {gamehubRecommendList && gamehubRecommendList.length > 0 && 
                <Pagination
                    defaultCurrent={1}
                    total={gamehubRecommendCount}
                    pageSize = {size}
                    onChange={onChangePaging}
                    showSizeChanger={false}
                />}
            </div>
        </div> 
        </>
    )
}
export default GamehubRecommended;