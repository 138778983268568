import { Space, Table } from 'antd';
import React, { useState } from 'react'

import {
	tokenEarnedPerThousandDollarsCompounding,
	getRoi,
} from './compoundApyHelpers'

const ApyCalculatorModal = (props) => {
	const {
		tokenPrice,
		apr,
		earningTokenSymbol,
		roundingDecimals = 2,
		compoundFrequency = 1,
		performanceFee,
	} = props;

	const oneThousandDollarsWorthOfToken = 1000 / tokenPrice
	const tokenEarnedPerThousand1D = tokenEarnedPerThousandDollarsCompounding({
		numberOfDays: 1,
		farmApr: apr,
		tokenPrice,
		roundingDecimals,
		compoundFrequency,
		performanceFee,
	})
	const tokenEarnedPerThousand7D = tokenEarnedPerThousandDollarsCompounding({
		numberOfDays: 7,
		farmApr: apr,
		tokenPrice,
		roundingDecimals,
		compoundFrequency,
		performanceFee,
	})
	const tokenEarnedPerThousand30D = tokenEarnedPerThousandDollarsCompounding({
		numberOfDays: 30,
		farmApr: apr,
		tokenPrice,
		roundingDecimals,
		compoundFrequency,
		performanceFee,
	})
	const tokenEarnedPerThousand365D = tokenEarnedPerThousandDollarsCompounding({
		numberOfDays: 365,
		farmApr: apr,
		tokenPrice,
		roundingDecimals,
		compoundFrequency,
		performanceFee,
	});

	return (
		<>
			{/* <Table pagination={false} dataSource={item.stakeRois} columns={columnsAllocations} /> */}

			<table className='table-roi'>
				<tr className='tr-header'>
					<th>TIMEFRAME</th>
					<th>ROI</th>
					<th>{earningTokenSymbol} PER $1,000</th>
				</tr>
				<tr>
					<td align='center'>1d</td>
					<td align='center'>
						{getRoi({
							amountEarned: tokenEarnedPerThousand1D,
							amountInvested: oneThousandDollarsWorthOfToken,
						}).toFixed(roundingDecimals)}
						%
					</td>
					<td align='center'>{tokenEarnedPerThousand1D}</td>
				</tr>
				<tr>
					<td align='center'>7d</td>
					<td align='center'>
						{getRoi({
							amountEarned: tokenEarnedPerThousand7D,
							amountInvested: oneThousandDollarsWorthOfToken,
						}).toFixed(roundingDecimals)}
						%
					</td>
					<td align='center'>{tokenEarnedPerThousand7D}</td>
				</tr>
				<tr>
					<td align='center'>30d</td>
					<td align='center'>
						{getRoi({
							amountEarned: tokenEarnedPerThousand30D,
							amountInvested: oneThousandDollarsWorthOfToken,
						}).toFixed(roundingDecimals)}
						%
					</td>
					<td align='center'>{tokenEarnedPerThousand30D}</td>
				</tr>
				<tr>
					<td align='center'>365d</td>
					<td align='center'>
						{getRoi({
							amountEarned: tokenEarnedPerThousand365D,
							amountInvested: oneThousandDollarsWorthOfToken,
						}).toFixed(roundingDecimals)}
						%
					</td>
					<td align='center'>{tokenEarnedPerThousand365D}</td>
				</tr>
			</table>
		</>


	)
}

export default ApyCalculatorModal
