import React, { useEffect, useState } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { Col, Tabs } from 'antd';
import NodataImg from '../common/asset/images/nodata.svg';
import { useSelector, useDispatch } from 'react-redux';
import { formatData } from '../../utils/StringHelper';
import TimeCountdown from '../common/countdown';
import { getCampaignTimeItem, getChainDecimals, getChainInformation } from '../../utils/campaignUltils';
import allActions from '../../actions';
import { useActiveWeb3React } from "../../hooks";
import abiBUSD from '../../config/abi/abiBUSD.json';
import {
    ADDRESS_USD,
    CAMPAIGN_STATUS,
    CHAINID_FULLNAME,
    CHAIN_INFO_DECIMALS,
    IDO_CLAIM_TYPE_DAILY,
    IDO_CLAIM_TYPE_MONTHLY,
    LOCAL_STORAGE,
    Networks,
    ROUND_TYPE
} from '../../constants';
import {
    useClaimDailyContract,
    useContract,
    useIdoTokenClaimContract,
    useJoinPoolContract,
    useJoinPoolContractV1
} from "../../hooks/useContract";
import {
    _isClaimedSC,
    _totalClaimedSC,
    _isRefundedSC,
    _isJoinedSC,
    _totalJoinedSC,
    _approveBUSD,
    _joinPool,
    _refund,
    _claimTokens,
    _userClaimSC,
    _totalClaimAble,
    _claimRemainingFund,
    convertFromWei,
    _totalDailyClaimedSC,
    _totalRefundedSC
} from "./utils";
import web3 from "web3";
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import MonthlyAllocation from './MonthlyAllocation';
import { Spin } from 'antd';
import { truncateNumber } from '../../utils/formatNumberCurrency';
import DailyAllocation from './DailyAllocation';
import IdoDetailCountdown from '../common/IdoDetailCountdown';
import whitelistAction from '../../actions/whitelistAction';
const spinLoading = () => <Spin size="small" style={{ position: 'absolute', marginLeft: "5px" }} />;

const CampaignRound = ({ item, keyRound, roundActive, setRoundActive }) => {
    const [value, setValue] = useState(0);
    const [valueCommitNative, setValueCommitNative] = useState(0);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const { TabPane } = Tabs;
    const size = useWindowSize();
    const sizeTablet = 991;
    const dispatch = useDispatch();
    const provider = process.env.REACT_APP_NETWORK_URL ?? '';
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [isLoadingRefund, setIsLoadingRefund] = useState(false);
    const [isLoadingRemainingFund, setIsLoadingRemainingFund] = useState(false);
    const [isLoadingApprove, setIsLoadingApprove] = useState(false);
    const [isLoadingCommit, setIsLoadingCommit] = useState(false);
    const { account, chainId } = useActiveWeb3React();
    const [isClaimed, setIsClaimed] = useState(false);
    const [isJoined, setIsJoined] = useState(false);
    const [totalClaimed, setTotalClaimed] = useState(0);
    const [totalRefunded, setTotalRefunded] = useState(0);
    const [totalRefundedEspecial, setTotalRefundedEspecial] = useState(0);
    const [isRefundedAll, setIsRefundedAll] = useState(false);
    const [isRefundedEspecial, setIsRefundedEspecial] = useState(false);
    const [isApprovedJoin, setIsApprovedJoin] = useState(false);
    const [totalDailyClaimed, setTotalDailyClaimed] = useState(0);
    const [totalJoined, setTotalJoined] = useState(0);
    const [isClaimedRemaining, setIsClaimedRemaining] = useState(false);
    const campaignsData = useSelector(state => state.campaignsData);
    const [paymentBalance, setPaymentBalance] = useState(0);
    const [allowance, setAllowance] = useState(0);

    const {
        addressClaimIdo,
        addressClaimDaily,
        addressCommited,
        addressRemaningFund,
        allowRefund,
        refundDuration,
        idoRate,
        processBar,
        round,
        totalRaise,
        totalTokenRaise,
        vesting,
        claimType,
        whitelist,
        allocations,
        phase,
        slots,
        roundType,
        refundEspecialAllow,
        refundEspecialClaimedAllowed,
        addressRefundEspecial,
        refundEspecialPercent,
        refundEspecialStart,
        refundEspecialEnd,
        commitRangeMaxPercent,
        commitRangeMinPercent,
        vestingInfos,
    } = item;

    const roundId = item && item.id;
    const {
        acceptCurrency,
        tokenInform,
        status,
        network,
        claimChain,
        oldVersion,
        modelType,
        startJoinPool, endJoinPool
    } = campaignsData;

    const roundTime = getCampaignTimeItem(campaignsData, item);
    const roundStatus = roundTime && roundTime.roundStatus;
    const symbol = tokenInform && tokenInform.symbol;
    const tokenDecimals = tokenInform && tokenInform.decimals;

    const actualFundNeeded = whitelist?.actualFundNeeded;
    // const claimedToken = whitelist?.claimedToken;
    const commitRange = whitelist?.commitRange;
    const fundCommitted = whitelist?.fundCommitted;
    const remainingFund = whitelist?.remainingFund;
    const tokenAllocation = whitelist?.tokenAllocation;
    const signatureRemainingFund = whitelist?.signatureRemainingFund;
    const signatureRefund = whitelist?.signatureRefund;
 
    const whitelistId = whitelist && whitelist.id;
    let addressTokenJoin = chainId && acceptCurrency ? ADDRESS_USD[chainId][acceptCurrency] : '';

    

    const claimContract = useIdoTokenClaimContract(addressClaimIdo);
    // const refundEspecialContractModel1 = useIdoTokenClaimContract(addressRefundEspecial);
    const joinPoolContract = useJoinPoolContract(addressCommited, oldVersion);
    const claimDailyContract = useClaimDailyContract(addressClaimDaily);
    const claimRemainingFundContract = useIdoTokenClaimContract(addressRemaningFund);
    const refundEspecialContract = useIdoTokenClaimContract(addressRefundEspecial);
    // const claimContractInTGE = useIdoTokenClaimContract(allocations && allocations[0]?.contractAddress);

    const nowDate = new Date();
    const startClaimDate = new Date(roundTime.startClaim);
    var refundAllEndDate = new Date(roundTime.startClaim);
    refundAllEndDate.setHours(startClaimDate.getHours() + (refundDuration ? refundDuration : 2));
    // let isWhitelisted = whitelist ? true : false;
    let isWhitelisted = (whitelist || phase === 2) ? true : false;

    const [isOpen, setOpen] = useState();
    const isChainBSC = network === Networks.BSC;
    const isChainETH = network === Networks.ETH;
    const isChainPOLY = network === Networks.POLY;
    const isChainARB = network === Networks.ARB;
    const isChainARBTEST = network === Networks.ARBTESTNET;
    const isHelaTestnet = network === Networks.HELATESTNET;
    const isHela = network === Networks.HELA;
    const chainInformation = getChainInformation(chainId);

    // DAILY CLAIM
    useEffect(() => {
        try {
            if (claimDailyContract && addressClaimDaily && tokenDecimals) {
                _totalDailyClaimedSC(claimDailyContract, account).then((res) => {
                    if (res && res.tokensClaimed) {
                        setTotalDailyClaimed(convertFromWei(res.tokensClaimed, tokenDecimals));
                    }
                });
            }
        } catch (error) {
            // TODO
        }
    }, [account, claimDailyContract]);

    const currencyContract = useContract(chainId && acceptCurrency && ADDRESS_USD[chainId][acceptCurrency], abiBUSD);

    // Check for Approve
    useEffect(() => {
        if (addressTokenJoin != "0x0000000000000000000000000000000000000000" && currencyContract && addressCommited && chainInformation) {
            currencyContract.allowance(account, addressCommited).then((res) => {
                if (res) {
                    let _allowance = parseFloat(convertFromWei(res, chainInformation.decimal));
                    if (_allowance > 0) {
                        setAllowance(_allowance);
                        setIsApprovedJoin(true);
                    }
                }
            });

            currencyContract.balanceOf(account).then((res) => {
                setPaymentBalance(parseFloat(convertFromWei(res, chainInformation.decimal)));
            });
        }
    }, [currencyContract, account]);

    useEffect(() => {
        if (joinPoolContract && account && roundId && campaignsData?.id) {
            _isJoinedSC(joinPoolContract, account, roundId, campaignsData?.id, oldVersion)
                .then((res) => {
                    setIsJoined(res);
                    setIsApprovedJoin(res);
                });

            if (claimChain && network !== claimChain && actualFundNeeded && actualFundNeeded > 0) {
                setIsJoined(true);
                setIsApprovedJoin(true);
            }

            _totalJoinedSC(joinPoolContract, chainId).then((res) => {
                if (res) {
                    setTotalJoined(parseFloat(convertFromWei(res, chainInformation.decimal)));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [joinPoolContract, account, roundId]);

    useEffect(() => {
        if (account && claimContract) {
            _totalClaimedSC(claimContract, account).then((res) => {
                setTotalClaimed(parseFloat(convertFromWei(res, tokenDecimals)));
            });

            _isRefundedSC(claimContract, account).then((res) => {
                setIsRefundedAll(res);
            });

            _totalRefundedSC(claimContract, account).then((res) => {
                setTotalRefunded(parseFloat(convertFromWei(res, chainInformation.decimal)));
            });

            _isClaimedSC(claimContract, account).then((res) => {
                setIsClaimed(res);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, claimContract]);

    useEffect(() => {
        if (account && claimRemainingFundContract) {
            _isRefundedSC(claimRemainingFundContract, account).then((res) => {
                setIsClaimedRemaining(res);
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, claimRemainingFundContract]);

    useEffect(() => {
        if (account && refundEspecialContract) {
            _isRefundedSC(refundEspecialContract, account).then((res) => {
                setIsRefundedEspecial(res);
            });

            _totalRefundedSC(refundEspecialContract, account).then((res) => {
                setTotalRefundedEspecial(parseFloat(convertFromWei(res, chainInformation.decimal)));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, refundEspecialContract]);

    const onChange = (e) => {
        setValue(parseFloat(e.target.value));
        setMin(parseFloat(e.target.min));
        setMax(parseFloat(e.target.max));
    };

    const onChangeInputNative = (e) => {
        setValueCommitNative(parseFloat(e.target.value));
        setMin(parseFloat(e.target.min));
        setMax(parseFloat(e.target.max));
    };

    const handleSubmit = () => {
        if (value) {
            if (value >= min && value <= max) {

                if ((roundType === ROUND_TYPE.COMMUNITY || roundType === ROUND_TYPE.MVKB) && ((value + totalJoined) > totalRaise)) {
                    Store.addNotification(addNotify('Total raise is over!', 'danger'));
                    return;
                }

                _approveJoinPool(value);
            } else {
                Store.addNotification(addNotify('Your fund isn’t in the commit range', 'danger'));
                return;
            }
        }
    };

    const _approveJoinPool = async (commitFundNeeded) => {

        if (!addressCommited) {
            Store.addNotification(addNotify('Address commited is invalid !', 'danger'));
            return;
        }

        const balance = await currencyContract.balanceOf(account);

        const _ownerBalance = convertFromWei(balance, tokenDecimals);

        if (commitFundNeeded && _ownerBalance < commitFundNeeded) {
            Store.addNotification(addNotify("Insufficient balance. Balance: " + formatData(_ownerBalance, acceptCurrency), 'danger'));
            return;
        }

        if (!commitFundNeeded) {
            Store.addNotification(addNotify('Commit fund needed is invalid !', 'danger'));
            return;
        }

        if (commitFundNeeded < min && commitFundNeeded > max) {
            Store.addNotification(addNotify('Fund committed is invalid', 'danger'));
            return;
        }

        setIsLoadingApprove(true);
        await _approveBUSD(currencyContract, addressCommited, commitFundNeeded, tokenDecimals)
            .then((res) => {

                let resWait = res.wait();
                resWait.then((resTransaction) => {

                    if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                        Store.addNotification(addNotify('Approved successfully', 'success'));
                        setIsApprovedJoin(true);
                        setAllowance(commitFundNeeded);
                    } else {
                        Store.addNotification(addNotify('Approved failed', 'danger'));
                    }
                    setIsLoadingApprove(false);
                })
                    .catch((error) => {
                        setIsLoadingApprove(false);
                        if (error.data) {
                            Store.addNotification(addNotify("Approvals Error", 'danger'));
                        }
                        if (error) {
                            Store.addNotification(addNotify(error?.reason, 'danger'));
                        }
                    });
            })
            .catch((error) => {
                setIsLoadingApprove(false);
                if (error) {
                    if (error.code == 4001 && error.message) {
                        Store.addNotification(addNotify(error.message, 'danger'));
                    } else if (error.reason) {
                        Store.addNotification(addNotify(error.reason, 'danger'));
                    } else {
                        if (error.data && error.data.message) {
                            Store.addNotification(addNotify(error.data.message, 'danger'));
                        }
                    }
                }
            });
    }

    // HANDLE CLICK JOIN POOL
    const _handleJoinPool = async () => {

        if (!joinPoolContract) {
            return;
        }

        if (isChainPOLY && chainId !== 137) {
            Store.addNotification(addNotify('Please select network Polygon', 'danger'));
            return;
        }

        if (isChainBSC && chainId !== 56) {
            Store.addNotification(addNotify('Please select network BSC', 'danger'));
            return;
        }

        if (isChainETH && chainId !== 1) {
            Store.addNotification(addNotify('Please select network Ethereum', 'danger'));
            return;
        }

        if (isChainARB && chainId !== 42161) {
            Store.addNotification(addNotify('Please select network Arbitrum', 'danger'));
            return;
        }

        if (isChainARBTEST && chainId !== 421613) {
            Store.addNotification(addNotify('Please select network Arbitrum Testnet', 'danger'));
            return;
        }
        if (isHelaTestnet && chainId !== 666888) {
            Store.addNotification(addNotify('Please select network Hela Testnet', 'danger'));
            return;
        }

        if (isHela && chainId !== 8668) {
            Store.addNotification(addNotify('Please select network Hela Testnet', 'danger'));
            return;
        }

        if(addressTokenJoin != "0x0000000000000000000000000000000000000000"){
            if (!isApprovedJoin) {
                Store.addNotification(addNotify('Not yet approve', 'danger'));
                return;
            }

            if (allowance && allowance > 0 && allowance <= min && allowance > max) {
                Store.addNoification(addNotify('Fund committed is invalid', 'danger'));
                return;
            }
        }

        let amountJoin = allowance;

        if(addressTokenJoin == "0x0000000000000000000000000000000000000000"){
            amountJoin = valueCommitNative;
        }

        if (amountJoin <= 0) {
            Store.addNotification(addNotify('Amount join is invalid', 'danger'));
            return;
        }
        

        if (whitelistId === 0) {
            Store.addNotification(addNotify('Commit args is invalid', 'danger'));
            return;
        }

        setIsLoadingCommit(true);

        let payloadCommit = {
            "address": account,
            "roundId": roundId,
            "amount": amountJoin
        };

        dispatch(allActions.whitelistAction.getSignatureCommit(payloadCommit, async (response) => {
            let signatureCommitData = response.signatureCommit;
            let _whitelistId = 0;
            if (phase === 2) {
                _whitelistId = response.whitelistId;
            }
            else {
                _whitelistId = whitelistId;
            }

            await _joinPool(joinPoolContract, amountJoin, _whitelistId, roundId, signatureCommitData, chainInformation.decimal, addressTokenJoin)
                .then((res) => {
                    let resWait = res.wait();
                    resWait.then((resTransaction) => {
                        if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                            Store.addNotification(addNotify('Committed successfully', 'success'));

                            // Update PUT /campaign/commit-fund/client
                            let payload = {
                                "address": account,
                                "roundId": roundId,
                                "fundCommitted": amountJoin,
                                "joinedTxn": res.hash,
                                "joinedPool": true
                            };

                            dispatch(allActions.whitelistAction.updateCommitFund(payload));
                            setIsJoined(true);
                            setIsLoadingCommit(false);

                        } else {
                            setIsLoadingCommit(false);
                            Store.addNotification(addNotify('Committed failed', 'danger'));
                        }
                    })
                        .catch((error) => {
                            setIsLoadingCommit(false);
                            if (error.data) {
                                Store.addNotification(addNotify("Committed Error", 'danger'));
                            }
                            if (error) {
                                Store.addNotification(addNotify(error?.reason, 'danger'));
                            }
                            return;
                        });
                })
                .catch((error) => {
                    console.log('join err', error);
                    setIsLoadingCommit(false);
                    if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
                    return;
                });
        }));
    }

    const _handleRefund = async () => {

        if (isChainPOLY && chainId !== 137) {
            Store.addNotification(addNotify('Please select network Polygon', 'danger'));
            return;
        }

        if (isChainBSC && chainId !== 56) {
            Store.addNotification(addNotify('Please select network BSC', 'danger'));
            return;
        }

        if (isChainETH && chainId !== 1) {
            Store.addNotification(addNotify('Please select network Ethereum', 'danger'));
            return;
        }

        if (isChainARB && chainId !== 42161) {
            Store.addNotification(addNotify('Please select network Arbitrum', 'danger'));
            return;
        }

        if (modelType === 2) {
            if (!addressClaimIdo) {
                Store.addNotification(addNotify('Invalid claim contract', 'danger'));
                return;
            }
            if (!signatureRefund) {
                Store.addNotification(addNotify('Signature refund is invalid', 'danger'));
                return;
            }
        }

        if (!actualFundNeeded) {
            Store.addNotification(addNotify('Invalid actual fund needed', 'danger'));
            return;
        }

        if (!chainInformation?.decimal) {
            Store.addNotification(addNotify('Chain decimal is invalid', 'danger'));
            return;
        }
        setIsLoadingRefund(true);

        //MODEL 2

        await _refund(claimContract, actualFundNeeded, signatureRefund, chainInformation?.decimal)
            .then((res) => {
                let resWait = res.wait();
                resWait.then((resTransaction) => {
                    if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                        Store.addNotification(addNotify('Refunded successfully', 'success'));

                        // Update PUT /api/campaign/refund-ido/client
                        let payload = {
                            "address": account,
                            "roundId": roundId,
                            "refundedTxn": res.hash,
                            "refunded": true,
                            "refundedAmount": actualFundNeeded
                        };
                        dispatch(allActions.whitelistAction.updateRefundIdo(payload));
                        setIsRefundedAll(true);
                        setIsLoadingRefund(false);

                    } else {
                        setIsLoadingRefund(false);
                        Store.addNotification(addNotify('Refunded failed', 'danger'));
                    }
                })
                    .catch((error) => {
                        setIsLoadingRefund(false);
                        setIsRefundedAll(false);
                        if (error.data) {
                            Store.addNotification(addNotify("Refunded Error", 'danger'));
                        }
                        if (error) {
                            Store.addNotification(addNotify(error?.reason, 'danger'));
                        }
                        return false;
                    });
            })
            .catch((error) => {
                setIsLoadingRefund(false);
                setIsRefundedAll(false);
                if (error.data) {
                    Store.addNotification(addNotify("Refunded Error", 'danger'));
                }
                if (error) {
                    Store.addNotification(addNotify(error?.reason, 'danger'));
                }
                return false;
            });

    }

    const _handleRefundEspecial = async (fundAmount) => {

        if (isChainPOLY && chainId !== 137) {
            Store.addNotification(addNotify('Please select network Polygon', 'danger'));
            return;
        }

        if (isChainBSC && chainId !== 56) {
            Store.addNotification(addNotify('Please select network BSC', 'danger'));
            return;
        }

        if (isChainETH && chainId !== 1) {
            Store.addNotification(addNotify('Please select network Ethereum', 'danger'));
            return;
        }

        if (isChainARB && chainId !== 42161) {
            Store.addNotification(addNotify('Please select network Arbitrum', 'danger'));
            return;
        }

        if (!addressRefundEspecial) {
            Store.addNotification(addNotify('Invalid claim contract', 'danger'));
            return;
        }

        if (!fundAmount) {
            Store.addNotification(addNotify("You can't refund any more !", 'danger'));
            return;
        }

        if (isRefundedAll || isRefundedEspecial) {
            Store.addNotification(addNotify("You've refunded", 'danger'));
            return;
        }

        if (!chainInformation?.decimal) {
            Store.addNotification(addNotify('Chain decimal is invalid', 'danger'));
            return;
        }

        setIsLoadingRefund(true);

        let _payloadEsRefund = {
            "address": account,
            "roundId": roundId,
            "amount": fundAmount
        };

        console.log("fundAmount", fundAmount);

        dispatch(whitelistAction.getSignatureEspecialRefund(_payloadEsRefund, async (response) => {

            let _signatureEspecialRefund = response.signatureEspecialRefund;
            if (_signatureEspecialRefund && _signatureEspecialRefund?.status === 400) {
                Store.addNotification(addNotify(_signatureEspecialRefund?.title, 'danger'));
                setIsLoadingRefund(false);
                return;
            }

            await _refund(refundEspecialContract, fundAmount, _signatureEspecialRefund, chainInformation?.decimal)
                .then((res) => {
                    let resWait = res.wait();
                    resWait.then((resTransaction) => {
                        if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                            Store.addNotification(addNotify('Refunded successfully', 'success'));

                            // Update PUT /api/white-lists/refund-especial
                            let payload = {
                                "address": account,
                                "hash": res.hash,
                                "roundId": roundId
                            };
                            dispatch(allActions.whitelistAction.updateRefundEspecial(payload));
                            setIsRefundedEspecial(true);
                            setIsLoadingRefund(false);
                        } else {
                            setIsLoadingRefund(false);
                            Store.addNotification(addNotify('Refunded failed', 'danger'));
                        }
                    })
                        .catch((error) => {
                            setIsLoadingRefund(false);
                            setIsRefundedEspecial(false);
                            if (error.data) {
                                Store.addNotification(addNotify("Refunded Error", 'danger'));
                            }
                            if (error) {
                                Store.addNotification(addNotify(error?.reason, 'danger'));
                            }
                            return false;
                        });
                })
                .catch((error) => {
                    setIsLoadingRefund(false);
                    setIsRefundedEspecial(false);
                    if (error.data) {
                        Store.addNotification(addNotify("Refunded Error", 'danger'));
                    }
                    if (error) {
                        Store.addNotification(addNotify(error?.reason, 'danger'));
                    }
                    return false;
                });
        }));
    }

    const _handleClaimRemainingFund = async () => {

        if (isChainPOLY && chainId !== 137) {
            Store.addNotification(addNotify('Please select network Polygon', 'danger'));
            return;
        }

        if (isChainBSC && chainId !== 56) {
            Store.addNotification(addNotify('Please select network BSC', 'danger'));
            return;
        }

        if (isChainETH && chainId !== 1) {
            Store.addNotification(addNotify('Please select network Ethereum', 'danger'));
            return;
        }

        if (isChainARB && chainId !== 42161) {
            Store.addNotification(addNotify('Please select network Arbitrum', 'danger'));
            return;
        }

        if (!claimRemainingFundContract) {
            Store.addNotification(addNotify('Invalid remaining fund contract', 'danger'));
            return;
        }

        if (!remainingFund) {
            Store.addNotification(addNotify('Invalid remaining fund', 'danger'));
            return;
        }

        if (!signatureRemainingFund) {
            Store.addNotification(addNotify('Signature remaining fund is invalid', 'danger'));
            return;
        }

        if (!chainInformation?.decimal) {
            Store.addNotification(addNotify('Chain decimal is invalid', 'danger'));
            return;
        }
        setIsLoadingRemainingFund(true);
        await _refund(claimRemainingFundContract, remainingFund, signatureRemainingFund, chainInformation?.decimal)
            .then((res) => {
                let resWait = res.wait();
                resWait.then((resTransaction) => {
                    if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                        Store.addNotification(addNotify('Claim remaining fund successfully', 'success'));

                        // Update PUT /api/campaign/claim-remaining-fund/client
                        let payload = {
                            "address": account,
                            "roundId": roundId,
                            "claimedRemainingTxn": res.hash
                        };
                        dispatch(allActions.whitelistAction.updateClaimRemainingFund(payload));
                        setIsLoadingRemainingFund(false);
                        setIsClaimedRemaining(true);

                    } else {
                        setIsLoadingRemainingFund(false);
                        Store.addNotification(addNotify('Claim remaining fund failed', 'danger'));
                    }
                })
                    .catch((error) => {
                        setIsLoadingRemainingFund(false);
                        setIsClaimedRemaining(false);
                        if (error.data) {
                            Store.addNotification(addNotify("Refunded Error", 'danger'));
                        }
                        if (error) {
                            Store.addNotification(addNotify(error?.reason, 'danger'));
                        }
                        return false;
                    });
            })
            .catch((error) => {
                setIsLoadingRemainingFund(false);
                setIsClaimedRemaining(false);
                if (error.data) {
                    Store.addNotification(addNotify("Refunded Error", 'danger'));
                }
                if (error) {
                    Store.addNotification(addNotify(error?.reason, 'danger'));
                }
                return false;
            });
    }

    const btnApprove = (item) => {
        const obj = {
            className: `c-btn`,
            disabled: isLoadingApprove || isApprovedJoin || !isWhitelisted || (totalJoined >= totalRaise),
        };
        return obj;
    }

    const btnRefund = (item) => {
        const obj = {
            className: `c-btn c-btn--border mobile`,
            disabled: isLoadingRefund || isRefundedAll || !actualFundNeeded,
        };
        return obj;
    }

    const btnRefundEspecial = () => {
        const obj = {
            className: `c-btn c-btn--border mobile`,
            disabled: isLoadingRefund || isRefundedEspecial,
        };
        return obj;
    }

    const btnClaimRemaining = (item) => {
        const obj = {
            className: `c-btn mobile`,
            disabled: isLoadingRemainingFund || (isClaimedRemaining && remainingFund > 0),
        };
        return obj;
    }

    

    const refundButtonRenderer = () => {
        let element = '';
        if (isRefundedAll || isRefundedEspecial) {
            element = (
                <span className="c-action">Refunded</span>
            );
        }

        else {
            let refundEspecialStartDate = new Date(refundEspecialStart);
            let refundEspecialEndDate = new Date(refundEspecialEnd);
            //model 1
            if (modelType === 1) {
                if (isJoined && allowRefund && refundEspecialAllow) {
                    let actualFundRefundAmount = actualFundNeeded ? +actualFundNeeded * (+refundEspecialPercent / 100) : 0;

                    if (actualFundRefundAmount !== 0 && +refundEspecialPercent !== 0 && (nowDate >= refundEspecialStartDate && nowDate <= refundEspecialEndDate)) {
                        element = (<button {...btnRefundEspecial()} onClick={() => _handleRefundEspecial(actualFundRefundAmount)}>
                            Refund {refundEspecialPercent}% {isLoadingRefund && spinLoading()}
                        </button>);
                    }
                }
            }
            //model 2
            else {
                // Doi voi round cho phep refund (User da join, chua claim, trong khoang time refund): Refund 100% at TGE
                if (isJoined && !isClaimed && allowRefund && !refundEspecialAllow && (nowDate >= startClaimDate && nowDate <= refundAllEndDate && +actualFundNeeded > 0)) {
                    element = (
                        <button {...btnRefund(item)} onClick={() => _handleRefund()}>
                            Refund {isLoadingRefund && spinLoading()}
                        </button>
                    );
                }
                // Doi voi round cho phep refund: Refund ngoai le, User da join, trong khoang time refund ngoai le
                else if (isJoined && allowRefund && refundEspecialAllow && (+refundEspecialPercent > 0) && (nowDate >= refundEspecialStartDate && nowDate <= refundEspecialEndDate)) {
                    // cho phep refund voi user da claim
                    let _refundPercent = 0;
                    if (refundEspecialClaimedAllowed) {
                        // Da claim + chua refund TGE + chua refund ngoai le : set theo refundEspecialPercent
                        if (isClaimed && !isRefundedAll && !isRefundedEspecial) {
                            _refundPercent = refundEspecialPercent;
                        }
                        // Chua claim + chua refund TGE + chua refund ngoai le : refund 100%
                        else if (!isClaimed && !isRefundedAll && !isRefundedEspecial) {
                            _refundPercent = 100;
                        }
                    }
                    else {
                        if (!isClaimed && !isRefundedAll && !isRefundedEspecial) {
                            _refundPercent = 100;
                        }
                    }

                    let actualFundRefundAmount = actualFundNeeded * Number((_refundPercent / 100));
                    element = _refundPercent !== 0 ? (
                        <button {...btnRefundEspecial()} onClick={() => _handleRefundEspecial(actualFundRefundAmount)}>
                            Refund {_refundPercent}% {isLoadingRefund && spinLoading()}
                        </button>
                    ) : "";
                }
                else {
                    element = (<div className="mt-30"></div>);
                }
            }
        }
        return element;
    }

    const claimRemainingButtonRenderer = () => {

        let element = "";
        if (isClaimedRemaining) {
            element = (
                <span className="c-action">Claimed Remaining Fund</span>
            );
        }
        else {
            if (remainingFund > 0) {
                element = (
                    <button {...btnClaimRemaining(item)} onClick={() => _handleClaimRemainingFund()}>
                        Claim Remaining Fund {isLoadingRemainingFund && spinLoading()}
                    </button>
                );
            }
            else {
                
                element = (
                    <span className="c-action">
                        {/* Waiting Claim */}
                    </span>
                );
                
                
            }
        }

        return element;
    }

    const btnCommit = (item) => {
        const obj = {
            className: `c-btn`,
            disabled: isLoadingCommit || !isWhitelisted || isJoined,
        }
        return obj
    }

    const txtCommit = (item) => {
        const obj = {
            disabled: isLoadingCommit || !isWhitelisted || isJoined || isApprovedJoin || (totalJoined >= totalRaise),
        }
        return obj
    }


    const commitButtonRenderer = () => {
        let element = '';
        if (roundStatus === CAMPAIGN_STATUS.OPENING) {
            if (isApprovedJoin && addressTokenJoin != "0x0000000000000000000000000000000000000000") {
                if (isJoined) {
                    element = (
                        <div className="c-commit">
                            <div className="c-commit__form">
                                {/* <input {...txtCommit(item)} type="text" value='' style={{ background: "none", border: "none" }} /> */}
                                <button {...btnCommit(item)} style={{ borderRadius: "5px", marginBottom: '10px' }}>
                                    Commited
                                </button>
                            </div>
                        </div>
                    );
                }
                else {
                    element = (
                        <div className="c-commit">
                            <div className="c-commit__form">
                                <input {...txtCommit(item)} type="text" value={allowance} />
                                <button {...btnCommit(item)} onClick={() => _handleJoinPool()}>
                                    Commit {isLoadingCommit && spinLoading()}
                                </button>
                            </div>
                        </div>
                    );
                }

            } else {
                if (addressTokenJoin == "0x0000000000000000000000000000000000000000" && isJoined) {
                    element = (
                        <div className="c-commit">
                            <div className="c-commit__form">
                                {/* <input {...txtCommit(item)} type="text" value='' style={{ background: "none", border: "none" }} /> */}
                                <button {...btnCommit(item)} style={{ borderRadius: "5px", marginBottom: '10px' }}>
                                    Commited
                                </button>
                            </div>
                        </div>
                    );
                }else{
                    element = (
                    <>
                        {addressTokenJoin == "0x0000000000000000000000000000000000000000" ? (
                            <>
                                <div className="c-commit">
                                    <div className="c-commit__form">
                                        <input {...txtCommit(item)} type="number" placeholder="Enter your fund" min={commitRangeArray && commitRangeArray[0]} max={commitRangeArray && commitRangeArray[1]} onChange={(e) => onChangeInputNative(e)} />
                                        <button {...btnCommit(item)} onClick={() => _handleJoinPool()}>
                                            Commit {isLoadingCommit && spinLoading()}
                                        </button>
                                    </div>
                                </div>
                            </>
                        ):(
                            <>
                                <div className="c-commit">
                                    <div className="c-commit__form">
                                        <input {...txtCommit(item)} type="number" placeholder="Enter your fund" min={commitRangeArray && commitRangeArray[0]} max={commitRangeArray && commitRangeArray[1]} onChange={(e) => onChange(e)} />
                                        <button {...btnApprove(item)} onClick={() => handleSubmit()}>
                                            Approve {isLoadingApprove && spinLoading()}
                                        </button>
                                        <p>* Your commit range is {formatData(commitRangeArray && commitRangeArray[0], acceptCurrency)} - {formatData(commitRangeArray && commitRangeArray[1], acceptCurrency)}</p>
                                    </div>
                                </div>
                            </>
                        )}
                    
                    </>
                );
                }
                
            }
        }
        else {
            element = (<div className="mt-30"></div>);
        }

        return element;
    }

    const handleCampaignRound = () => {
        let elmDetailPool = '';
        if (roundStatus === CAMPAIGN_STATUS.UPCOMING || roundStatus === CAMPAIGN_STATUS.TBA) {

            elmDetailPool = (
                <>
                    <div className='not-whitelist'>Waiting Commit</div>
                </>
            );

        } else if (roundStatus === CAMPAIGN_STATUS.OPENING) {

            elmDetailPool = (<>{commitButtonRenderer()}</>);

        } else if (roundStatus === CAMPAIGN_STATUS.COMPLETED) {
            if(isJoined){
                elmDetailPool = (<div className="c-campaignRound__btn">
                    {claimRemainingButtonRenderer()}
                    {refundButtonRenderer()}
                </div>);
            }else{
                elmDetailPool = (<div className='not-whitelist'>You've not joined</div>);
            }
            

        }
        return elmDetailPool;
    }

    let _soldPercent = 0;
    let _totalJoined;

    let _processBar = processBar ?? null;

    if (_processBar && _processBar >= 0 && _processBar <= 100) {
        _soldPercent = _processBar.toFixed(2);
        _totalJoined = totalRaise * _processBar / 100;
    }
    else if (!_processBar) {
        _soldPercent = totalJoined && totalRaise && (parseFloat(totalJoined / totalRaise) * 100).toFixed(2);
        _totalJoined = totalJoined;
    }

    allocations && allocations?.sort(function (a, b) {
        return new Date(a.claimTime) - new Date(b.claimTime);
    });

    const allocationsMonthlyRenderer = allocations && allocations?.length > 0 && allocations?.map((item, key) => {
        const vestingInfo = vestingInfos && vestingInfos.filter((vestingInfosItem) => {
            return (new Date(vestingInfosItem.vestingTime + ':00Z')).getTime() === (new Date(item.claimTime)).getTime();
        });

        return (<MonthlyAllocation
            allocationItem={item}
            whitelist={whitelist}
            tokenDecimals={tokenDecimals}
            actualFundNeeded={actualFundNeeded}
            isLoadingRefund={isLoadingRefund}
            isRefundedAll={isRefundedAll || isRefundedEspecial}
            oldVersion={oldVersion}
            key={key}
            vestingInfo={vestingInfo && vestingInfo?.length > 0 ? vestingInfo[0] : {}}
            allowRefundRound={allowRefund}
            refundEspecialAllow={refundEspecialAllow}
        />);
    });

    const allocationsDailyFirstRenderer = allocations && allocations?.length > 0 && allocations.map((item, key) => {

        if ((allocations?.length - 1) !== key) {
            return (<MonthlyAllocation
                allocationItem={item}
                whitelist={whitelist}
                tokenDecimals={tokenDecimals}
                isRefundedAll={isRefundedAll || isRefundedEspecial}
                oldVersion={oldVersion}
                key={key}
            />);
        }
    });

    const allocationsZWZDailyFirstRenderer = allocations && allocations?.length > 0 && allocations.map((item, key) => {

        if (key == 0) {
            return (<MonthlyAllocation
                allocationItem={item}
                whitelist={whitelist}
                tokenDecimals={tokenDecimals}
                isRefundedAll={isRefundedAll || isRefundedEspecial}
                oldVersion={oldVersion}
                key={key}
            />);
        }
    });

    const allocationsDailyRenderer = (
        <>
            {(roundId === 14202 || roundId === 14201) ? allocationsZWZDailyFirstRenderer : allocationsDailyFirstRenderer}

            <DailyAllocation
                allocationItem={allocations && allocations?.length > 0 && allocations[allocations && allocations?.length - 1]}
                key={allocations && allocations?.length - 1}
                addressClaimDaily={addressClaimDaily}
                isRefunded={isRefundedAll || isRefundedEspecial}
            />
        </>
    );

    const allocationsNoDataRenderer = (
        <tr>
            <td colSpan="4" className="nodata">
                <div className="c-nodata">
                    <img src={NodataImg} alt="No Data" />
                    <p>No Data</p>
                </div>
            </td>
        </tr>
    );

    let commitRangeArray = [];
    if (phase === 2) {
        let commitStandard = totalRaise && slots && parseFloat(totalRaise / slots);

        if (commitStandard) {
            let minCommit = commitRangeMinPercent && (commitStandard * commitRangeMinPercent) / 100;
            let maxCommit = commitRangeMaxPercent && (commitStandard * commitRangeMaxPercent) / 100;
            commitRangeArray.push(minCommit);
            commitRangeArray.push(maxCommit);
        }
    }
    else if (oldVersion) {
        commitRangeArray = commitRange && commitRange.split("-");
    }
    else {
        commitRangeArray = commitRange && commitRange.split("-");
    }

    const allocationRender = () => {
        if (campaignsData?.id === 100028){
            if (allocations && allocations?.length === 0
                || !whitelist.fundCommitted
                || whitelist.fundCommitted === 0
                || campaignsData?.id === 100016 //cheat campainId: 100016
            ) {
                return <>{allocationsNoDataRenderer}</>;
            }
        }else{
            if (allocations && allocations?.length === 0
                || !isJoined
                || !whitelist.fundCommitted
                || whitelist.fundCommitted === 0
                || campaignsData?.id === 100016 //cheat campainId: 100016
            ) {
                return <>{allocationsNoDataRenderer}</>;
            }
        }
        

        if (claimType === IDO_CLAIM_TYPE_MONTHLY) {
            return <>{allocationsMonthlyRenderer}</>;
        } else if (claimType == IDO_CLAIM_TYPE_DAILY) {
            return <>{allocationsDailyRenderer}</>;
        }
    }

    const handleActiveRound = (roundActive)=>{
        if(isWhitelisted){
            setRoundActive(roundActive);
        }
    }   

    let newDateStart= null;
    let newDateEnd= null;
    // console.log('startJoinPool', item);
    // console.log('endJoinPool', endJoinPool);
    if(startJoinPool && endJoinPool){
        const formatAMPM = (date) => {
            var hours = date.getUTCHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            minutes = minutes < 10 ? '0'+minutes : minutes;
            var strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }

        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        const startJoinPoolDate = new Date(startJoinPool);
        const endJoinPoolDate = new Date(endJoinPool);
    
        newDateStart = formatAMPM(startJoinPoolDate)+' ' + months[startJoinPoolDate.getMonth()] + ' ' + startJoinPoolDate.getUTCDate();
        newDateEnd = formatAMPM(endJoinPoolDate) + ' ' + months[endJoinPoolDate.getMonth()] + ' ' + endJoinPoolDate.getUTCDate(); 
    }

    

    return (
        <>
            <Col xs={24} sm={8} xl={8} md={8}>
                <div className={`c-campaignRound ${roundActive?.id === item?.id ? 'is-open' : 'is-close'}`} key={keyRound} onClick={() => handleActiveRound(item)}>
                    <div className="c-campaignRound__title">
                        <h3>{round}</h3>
                        <span>{tokenInform?.symbol} / {acceptCurrency}</span>
                        {/* <p>{handleStatusRound(roundStatus, isWhitelisted, isJoined)}</p> */}
                    </div>
                    <div className="c-campaignRound__body">
                        <div className="c-campaignRound__content">
                            
                            <div style={{minHeight: '175px'}} className='p-15 bg-darkblue'>
                                <p className="c-campaignRound__row">
                                    <span>Total Raise:</span>
                                    <span>{formatData(totalRaise, acceptCurrency)}</span>
                                </p>
                                <p className="c-campaignRound__row">
                                    <span>Swap Rate:</span>
                                    <span>1 {tokenInform?.symbol} = {formatData(idoRate, acceptCurrency)}</span>
                                </p>
                                <p className="c-campaignRound__row">
                                    <span>Start Pool:</span>
                                    <span>{newDateStart ? newDateStart : ''}</span>
                                </p>
                                <p className="c-campaignRound__row">
                                    <span>End Pool:</span>
                                    <span>{newDateEnd ? newDateEnd : ''}</span>
                                </p>
                                <p className="c-campaignRound__row">
                                    <span>Vesting:</span>
                                    <span className="is-text">{vesting ?? "--"}</span>
                                </p>
                            </div>

                            <div className="c-progress">
                                {roundStatus === CAMPAIGN_STATUS.OPENING || roundStatus === CAMPAIGN_STATUS.UPCOMING ? (
                                    <>
                                        <p className="c-progress__ttl">Commit Progress:</p>
                                    </>
                                ):(
                                    <>
                                        <p className="c-progress__ttl">Commit Progress:</p>
                                    </>
                                )}
                                
                                <div className="c-progress__bar"><span style={{ width: `${_soldPercent}%` }}></span></div>
                                <p className="c-progress__txt">
                                    <span>{_soldPercent}%</span>
                                    <span>{formatData(_totalJoined, '')} / {formatData(totalRaise, acceptCurrency)}</span>
                                </p>
                            </div>

                            <p className="c-campaignRound__time">
                                <IdoDetailCountdown item={roundTime} />
                            </p>

                            {account && chainId ? (
                                <>
                                    {isWhitelisted ? (
                                        <>
                                            {handleCampaignRound()}
                                        </>
                                    ):(
                                        <>
                                            <div className='not-whitelist text-danger'>You're not whitelisted</div>
                                        </>
                                    )}
                                </>
                            ):(
                                <>
                                    <div className='not-whitelist text-danger'>Please connect wallet</div>
                                </>
                            )}

                            

                            

                            {/* <Tabs className="c-tabs" defaultActiveKey={item?.allocations?.length > 0 ? "2" : "1"}>
                                <TabPane tab="Your Investment" key="1">
                                    <div className="c-campaignRound__info">
                                        <p>
                                            <span>Commit Range</span>
                                            {commitRangeArray && commitRangeArray?.length > 0
                                                ? (
                                                    commitRangeArray && commitRangeArray[0] === commitRangeArray[1]
                                                        ? (formatData(truncateNumber(commitRangeArray && commitRangeArray[0], 1), acceptCurrency, isWhitelisted))
                                                        : (formatData(truncateNumber(commitRangeArray && commitRangeArray[0], 1), '', isWhitelisted) + " - " + formatData(parseFloat(commitRangeArray && commitRangeArray[1]).toFixed(1), acceptCurrency, isWhitelisted))
                                                ) : '--'}
                                        </p>
                                        <p><span>Fund Committed</span>{formatData(fundCommitted, acceptCurrency, isWhitelisted)}</p>
                                        <p><span>Actual Fund Needed</span>{formatData(actualFundNeeded, acceptCurrency, isWhitelisted)}</p>
                                        <p><span>Remaining Fund</span>{formatData(remainingFund, acceptCurrency, isWhitelisted)}</p>
                                        <p><span>Allocation</span>{formatData(tokenAllocation, symbol, isWhitelisted)}</p>
                                        {(Number(totalRefunded) + Number(totalRefundedEspecial)) > 0 ?
                                            (<p><span>Total Refunded</span>{formatData(Number(totalRefunded) + Number(totalRefundedEspecial), acceptCurrency, isWhitelisted)}</p>)
                                            : (<p><span>Claimed Token</span>{formatData(Number(totalClaimed) + Number(totalDailyClaimed), symbol, isWhitelisted)}</p>)}
                                    </div>
                                </TabPane>
                                <TabPane tab="Your Allocation" key="2">
                                    <div className="c-campaignRound__table">
                                        <table>
                                            <tbody>
                                                {size.width < sizeTablet ? <>{allocationRender()}</> : <>
                                                    <tr>
                                                        <th>Start Claim</th>
                                                        <th>Allocation</th>
                                                        <th>Percentage</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    {allocationRender()}
                                                </>}
                                            </tbody>
                                        </table>
                                    </div>
                                </TabPane>
                            </Tabs> */}
                        </div>
                    </div>
                </div>
            </Col>


        </>
        
    );
};

export default CampaignRound;