import {
    GET_AUTH_KEY,
    GET_AUTH_TOKEN
} from "../actions/authType"
import {
    SET_CURRENT_USER,
    GET_STAKING_USER
} from "../actions/userType"

const currentUser = (state = {}, action) => {
    switch(action.type){
        case SET_CURRENT_USER:
            return {
                ...state,
                address: action.payload.address
            }
        case GET_STAKING_USER:
            return {
                ...state,
                infoStake: action.payload
            }
        case "SET_CHAIN":
            return {
                ...state,
                chainId: action.payload
            }
        case "LOG_OUT":
            return {
                ...state,
                user: {}
            }
        case GET_AUTH_TOKEN:
            return {
                ...state,
                b: action.payload.token // bearer token
            }
        case GET_AUTH_KEY:
            return {
                ...state,
                x: action.payload.x, // public key
                y: action.payload.y  // private key
            }
        default:
            return state
    }
}

export default currentUser;