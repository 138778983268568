import React from "react";
import FooterHome from "../common/footer/footerHome";

import {
  Container,
  Content,
  MainTitle,
  Wrapper,
  Desc,
  Title,
} from "../LegalStyles";

const CookiesPage = () => {
    return(
        <div className="p-security">
            <div className="container">
                <Content>
          <MainTitle>Cookie Policy</MainTitle>
          <Desc>
            In order to make your user experience more enjoyable and to get the
            most from using our website, we use cookies. Cookies are small text
            files stored on your device, which are loaded when you visit our
            Website. With your cookie consent, we like to tailor our services to
            better fit your needs and preferences. Which cookies we use and why
            we use them are explained in this Cookie Policy.
          </Desc>
          <Title>How do we use Cookies?</Title>
          <Desc>
            <span className="bold">Technical Cookies</span> are essential
            cookies that are always active and you can't reject them. These
            cookies guarantee safe navigation and ensure that our Website
            functions correctly. You can set your web browser to block these
            cookies, but then some parts of our Website won't work properly
            without them.
          </Desc>
          <Desc>
            <span className="bold">Temporary cookies</span> are deleted after
            the end of the browser session, when you close your web browser or
            application. These cookies store only temporary data information
            (e.g. a previous page that you've visited on our Website).
          </Desc>
          <Desc>
            <span className="bold">Persistent cookies</span> are those cookies
            that remain on your device until they expire or you manually delete
            them. They improve your ease of use as they recognize you the next
            time you visit our Website so you don't have to re-enter some
            information (such as your login name, password, etc.).
          </Desc>
          <Desc>
            <span className="bold">Functional</span> cookies collect your
            activity on our Website and allow us to remember your previously
            entered information (e.g. the language). These cookies boost your
            experience on our Website.
          </Desc>
          <Desc>
            <span className="bold">Performance cookies</span> are used to
            measure your browsing activity, e.g. which pages you go to most
            frequently or do you have any error messages. These cookies do not
            store information that identifies you.
          </Desc>
          <Desc>
            <span className="bold">Advertising cookies</span> are used for the
            purpose of targeted advertising, to deliver adverts relevant to you.
            They are also used to limit advertisement frequency as well as
            measure the effectiveness of advertising campaigns.
          </Desc>
          <Desc>
            <span className="bold">Third-party cookies</span> are cookies that
            are placed by other domains outside our organization and they can
            track the use of our Website. Google Analytics is an example, as
            collects visitor information on how many visitors came to our
            Website, the website they came from, numbers of visits for each
            visitor and how much average time a visitor spends on our Website.
            For more information, please read the Google Privacy Policy.
          </Desc>
          <Title>How can you manage Cookies?</Title>
          <Desc>Most web browsers accept cookies automatically.</Desc>
          <Desc>
            If you want to change your cookie settings, you can do so at any
            time, by selecting an appropriate option in your web browser
            settings (depending on whether it's Firefox, Chrome, Internet
            Explorer, or Safari).
          </Desc>
          <Desc>
            If you don't accept cookies or delete them, some functionalities may
            not work properly at all (e.g. profile logging-in).
          </Desc>
          <Desc>
            Cookies are not used to run our Website or to send viruses to your
            device. We are not associated with any spam messages sent to your
            email address!
          </Desc>
          <Title>Use of Cookies</Title>
          <Desc>
            You may, at any time, request and receive confirmation on how your
            personal data is processed with your cookie consent, by sending a
            written request by post to the following address: UpSwap Ltd.,
            Intershore Chambers, Road Town, Tortola, British Virgin Islands or
            by e-mail: contact@upswap.finance
          </Desc>
          <Title>Updates to the Cookie Policy</Title>
          <Desc>
            The Cookie Policy is an integral part of the General Terms and
            Conditions of UpSwap.
          </Desc>
          <Desc>
            This Cookie Policy replaces Cookie Policy that has been applied
            prior to this date. We can change this Cookie Policy at any time
            going forward.
          </Desc>
          <Desc>
            The amended Cookie Policy shall take effect immediately upon its
            publication on the Website, and implies that the user confirm and
            accept it.
          </Desc>
        </Content>
            </div>
            <FooterHome />
        </div>
    )
}

export default CookiesPage;