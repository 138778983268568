import AuthService from "../services/AuthService";
import {
  GET_AUTH_KEY,
  GET_AUTH_TOKEN
} from "./authType";

const getAuthToken = (payload) => async (dispatch) => {
    try {
      const res = await AuthService.getAuthToken(payload);
      if(res && res.status === 200) {
        // console.log('res.data', res.data.token);
        localStorage.setItem("tokenId", res.data.token);
        dispatch({
          type: GET_AUTH_TOKEN,
          payload: res.data
        });
      }
      
    } catch (err) {
      console.log(err);
    }
}

const getAuthKey = (payload) => async (dispatch) => {
  try {
    const res = await AuthService.getAuthKey(payload);
    console.log(res.data);

    let public_key = res.data.x;
    public_key = public_key ? public_key.substring(0, public_key.length - 12) : '';

    console.log(public_key);
    if(res && res.status === 200) {
      dispatch({
        type: GET_AUTH_KEY,
        payload: res.data
      });
    }
    
  } catch (err) {
    console.log(err);
  }
}

export default {
  getAuthToken,
  getAuthKey
}