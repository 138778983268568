import { 
    SEARCH_GAMEHUB, 
    CATALOGS_GAMEHUB,  
    GET_GAMEHUB_BY_HOT_STATUS,
    GET_GAMEHUB_BY_POPULAR_STATUS,
    GET_GAMEHUB_BY_RECOMMEND_STATUS,
    GET_GAMEHUB_BY_HOT_PRICES
} from "../actions/gamehubType";

const initialState = [];

const gamehub = (gamehub = initialState, action) => {  
    switch(action.type){
        case GET_GAMEHUB_BY_HOT_STATUS:
            return {
                ...gamehub,
                gamehubHotList: action.payload.gamehubHotList,
            } 

        case GET_GAMEHUB_BY_HOT_PRICES: 
            return {
                ...gamehub,
                gamehubHotPrices: action.payload.gamehubHotPrices,
            } 

        case GET_GAMEHUB_BY_POPULAR_STATUS:
            return {
                ...gamehub,  
                gamehubPopularList: action.payload.gamehubPopularList,
                gamehubPopularPrices: action.payload.gamehubPopularPrices,
                gamehubPopularCount: action.payload.gamehubPopularCount
            }
        case GET_GAMEHUB_BY_RECOMMEND_STATUS:
            return {
                ...gamehub,  
                gamehubRecommendList: action.payload.gamehubRecommendList,
                gamehubRecommendPrices: action.payload.gamehubRecommendPrices,
                gamehubRecommendCount: action.payload.gamehubRecommendCount,
            }    

        case SEARCH_GAMEHUB:
            return {
                ...gamehub,
                gamehubSearch: action.payload?.gamehubSearch,
                gamehubSearchCount: action.payload?.gamehubSearchCount,
                gamehubSearchPrices: action.payload?.gamehubSearchPrices
            }  
        case CATALOGS_GAMEHUB:
            return {
                ...gamehub,
                catalogsList: action.payload.catalogsList,
                catalogsCount: action.payload.catalogsCount,
                catalogsPrices: action.payload.catalogsPrices,
            }  
        default:
            return gamehub
    }
}

export default gamehub;