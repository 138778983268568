import { instance } from '../../apiBase/instance';

export const AirdropApi = {

	createAirDrop(data) {
		return instance.post(`air-drop-rewards/client`, data, {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	getAirdrops(params) {
		return instance.get(`air-drop-rewards/client`, { params }, '');
	},

	getOwnerAirdrops(params, account) {
		return instance.get(`air-drop-rewards/${account}/client`, { params }, '');
	},


	getAirdropDetail(handle) {
		return instance.get(`air-drop-rewards/${handle}/details` , {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},

	updateAirDrop(id, data) {
		return instance.put(`air-drop-rewards/${id}/status`,  data , {

		});
	}
};
