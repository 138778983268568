import React, { useEffect, useLayoutEffect, useState } from "react";  
import {_getSoldBox } from "../ino/utils";
import {useSelector, useDispatch} from 'react-redux';
import { useActiveWeb3React } from "../../hooks";

 const BoxType = (props)=> {
    const [remainingBox, setRemainingBox] = useState(null);
    const [totalBox, setTotalBox] = useState(0);
    const {item, handleSelected, indexBox, marketContract} = props; 
    const inoData = useSelector(state => state.inoData?.inoDetail); 
     
    useEffect(() => {
        (async () =>{
            if(marketContract && inoData && item){
                
                const campaignInfo = await _getSoldBox(marketContract, inoData?.id, item?.id);
                setRemainingBox(campaignInfo[0].toNumber());
                setTotalBox(campaignInfo[1].toNumber()); 
            } 
        })(); 
    }, [inoData, item]);  
  
    if(item?.soldOut >0){
        return (  
            <button disabled={item?.soldOut == item?.total ? true: false} onClick={()=>handleSelected(item)} className={`c-inoType-item ${item?.soldOut == item?.total ? "is_soldout" : ""} ${indexBox === item?.id ? "active" : ""}`}>
                {item?.soldOut == item?.total && <div className="soldout">
                    <span className="soldout_text">SOLD OUT</span>
                </div> } 
                <img src={item?.imageLink} alt=""/>
                <div className="c-inoType-item-nameTotal">
                    <span className="name">{item?.name}</span>
                    <span className="total">{item?.soldOut}/{item?.total}</span>
                </div> 
            </button> 
        )
    }
    return ( 
        <button disabled={remainingBox === 0 ? true : false} onClick={()=>handleSelected(item)} className={`c-inoType-item ${remainingBox === 0 ? "disable" : ""} ${indexBox === item?.id ? "active" : ""}`} >
            <img src={item?.imageLink} alt=""/>
            <div className="c-inoType-item-nameTotal">
                <span className="name">{item?.name}</span>
                <span className="total">{totalBox - remainingBox}/{totalBox}</span>
            </div> 
        </button>  
    )
}

export default BoxType;