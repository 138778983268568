import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useLocation } from 'react-router-dom';
import { Layout, Row, Col, Form, Select, Input, Button, Tag, Progress, Pagination, Empty } from 'antd';
import { SearchOutlined, TwitterOutlined, CodeSandboxOutlined, GlobalOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from '../../../hooks';
import { ethers } from 'ethers';
import launchpadAbi from '../../../config/abi/launchpadAbi.json';
import Countdown from 'react-countdown';
import { getLaunchpadInfoByChainId } from '../../utils';
import { getCurrencyDecimals } from '../../../config/network/config';
import { CHAINID_FULLNAME_BY_ID, CHAINID_LOGO_BY_ID } from '../../../constants';



const MyItem = (props) => {
    const { item, index } = props;
    const { chainId, account, library } = useActiveWeb3React();

    const [raisedAmount, setRaisedAmount] = useState(0);
    const [hardCap, setHardCap] = useState(0);

    let logoNetwork = CHAINID_LOGO_BY_ID[item?.roundIcos[0]?.chainId] ? CHAINID_LOGO_BY_ID[item?.roundIcos[0]?.chainId] : '';
    let titleNetwork = CHAINID_FULLNAME_BY_ID[item?.roundIcos[0]?.chainId] ? CHAINID_FULLNAME_BY_ID[item?.roundIcos[0]?.chainId] : '';

    const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            window.location.reload();
        }
        if (days > 1) {
            return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
        } else if (days === 1) {
            return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
        } else {
            return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
        }
    };
    const getProgressTime = useCallback((startTime) => {
        const now = new Date();
        const utcDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), 0);
        const startDate = new Date(startTime);
        const startTS = Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            startDate.getHours(),
            startDate.getMinutes(),
            startDate.getSeconds(),
            0
        );
        let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600);
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60);
        if (days >= 1) {
            return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        } else {
            return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        }
    }, []);

    const getLaunchpadInformation = async (roundAddress, itemChainId) => {

        try {
            let infoSC = await getLaunchpadInfoByChainId(launchpadAbi, roundAddress, itemChainId);
            let decimals = item?.roundIcos[0]?.currencyPayDecimals || getCurrencyDecimals(itemChainId, item?.roundIcos[0]?.currencyPay) || 18
            if (infoSC) {
                setRaisedAmount(parseFloat(ethers.utils.formatUnits(infoSC.raisedAmount, decimals)));
                setHardCap(parseFloat(ethers.utils.formatUnits(infoSC.hardCap, decimals)));
            }

        } catch (e) {
            console.log(e);

        }

    }

    useEffect(() => {
        if (account && item?.roundIcos[0]?.roundAddress && item?.roundIcos[0]?.roundAddress.length > 0 && item?.roundIcos[0]?.chainId) {
            getLaunchpadInformation(item?.roundIcos[0]?.roundAddress, item?.roundIcos[0]?.chainId)
        }

    }, [account, item]);
   
    const StatusRender = (nowDate, startDate, endDate, roundStatus) => {
        let status = '';
        let color = '';
        if (startDate > nowDate && endDate > nowDate && roundStatus != 'CANCEL') {
            status = 'upcoming';
            color = '#108ee9';
        }
        if (startDate < nowDate && endDate > nowDate && roundStatus != 'CANCEL') {
            status = 'opening';
            color = '#f5c112';
        }
        if (startDate < nowDate && endDate < nowDate && roundStatus != 'CANCEL') {
            status = 'closed';
            color = '#919191';
        }
        if (roundStatus == 'FILLED') {
            status = 'fiiled';
            color = '#87d068';
        }
        if (roundStatus == 'CANCEL') {
            status = 'canceled';
            color = 'red';
        }
        return <Tag color={color}>{status}</Tag>;
    }
    function isImgLink(url) {
        if(typeof url !== 'string') return false;
        return(url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|webp)(\?(.*))?$/gmi) != null);
    }

    

    let nowDate = new Date(Date.now());
    let startDate = new Date(item?.roundIcos[0]?.startTime);
    let endDate = new Date(item?.roundIcos[0]?.endTime);


    return (
        <>
            <Col xs={24} sm={8} xl={8} md={8} key={index}>
                <Link to={`/ico/detail/${item?.id}/${item?.campaignIco?.handle}`}>
                    <div className="project-item project-item-close">
                        <div className="project-image">
                            <Link to={`/ico/detail/${item?.campaignIco?.id}/${item?.campaignIco?.handle}`}>
                                <img src={item?.backgroundLink?.length > 0 && isImgLink(item?.backgroundLink) ? item?.backgroundLink : "../../../../images/ico-default.png"} />
                            </Link>
                            
                            <div className="project-chain">
                                <div className="project-token-main">{item?.tokenInfo?.symbol}</div>
                                {titleNetwork.length > 0 && logoNetwork.length > 0 && (
                                    <div className="project-token-chain">
                                        <img title={titleNetwork} src={logoNetwork} />
                                    </div>
                                )}
                                
                            </div>
                        </div>
                        <div className="project-item-content">
                            <div className="project-name flex-btw gap-10">
                                <Link className='text-darkblue text-18 text-weight-600' to={`/ico/detail/${item?.id}/${item?.campaignIco?.handle}`}>{item?.name} {item?.roundIcos[0]?.name ? ' - ' + item?.roundIcos[0]?.name : ''}</Link>
                                {StatusRender(nowDate, startDate, endDate, item?.roundIcos[0]?.roundStatus)}
                            </div>
                            
                            <div className="project-total-raise">
                                <span>Soft /Hard Cap</span>
                                <span className='text-info'>{item?.softCap} {item?.roundIcos[0]?.currencyPay}/ {item?.hardCap} {item?.roundIcos[0]?.currencyPay}</span>
                            </div>
                            <div className="project-total-raise">
                                <span>Price</span>
                                <span className='text-info'>1 {item?.roundIcos[0]?.currencyPay} = {item?.roundIcos[0]?.swapRate} {item?.tokenInfo?.symbol}</span>
                            </div>
                            <hr></hr>
                            <div className="project-registration">
                                {startDate > nowDate && endDate > nowDate && (
                                    <>
                                        <span className="sale-start-in">Sale start in </span>
                                        <Countdown
                                            date={getProgressTime(startDate)}
                                            renderer={rendererCountDown}
                                        />
                                    </>
                                )}
                                {startDate < nowDate && endDate > nowDate && (
                                    <>
                                        <span className="sale-start-in">Sale end in </span>
                                        <Countdown
                                            date={getProgressTime(endDate)}
                                            renderer={rendererCountDown}
                                        />
                                    </>
                                )}
                                {endDate < nowDate && (
                                    <>
                                        <span className="sale-start-in">Canceled </span>
                                    </>
                                )}
                            </div>
                            <Progress percent={item?.roundIcos[0]?.soldPercent !== null && item?.roundIcos[0]?.soldPercent > 0 ? item?.roundIcos[0]?.soldPercent : Math.ceil(raisedAmount / hardCap * 100)} className="project-progress-percent" />
                            <div className="project-total-sell">
                                <span className={`text-black-green text-weight-500 ${item?.roundIcos[0]?.soldPercent !== null ? 'text-hidden' : ''}`}>{new Intl.NumberFormat("ja-JP").format(raisedAmount)} {item?.roundIcos[0]?.currencyPay}</span>
                                <span className='text-black-green text-weight-500'>{new Intl.NumberFormat("ja-JP").format(hardCap)} {item?.roundIcos[0]?.currencyPay}</span>

                            </div>

                            <div className="project-total-raise">
                                <span>Lockup Time : </span>
                                <span>
                                    {item?.doManualListing ? (
                                        <>
                                            Unlocked
                                        </>
                                    ) : (
                                        <>
                                            {item?.liquidityLockup} days
                                        </>
                                    )}

                                </span>
                            </div>
                        </div>
                    </div>
                </Link>
                
            </Col>
            
        </>
    )
}

export default MyItem
