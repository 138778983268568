import { instance } from '../../apiBase/instance';
let tokenID = localStorage.getItem('tokenId');

export const chainApi = {

	getChainNetworkList(params) {
		return instance.get(`chain-lists/client`, { params, headers: { Authorization: `Bearer ${tokenID}` } });
	},
	getSettingsByChain(chainId) {
		return instance.get(`settings/chain-id/${chainId}`, {});
	},
	// getChainDetail(chainId) {
	// 	return instance.get<any>(`chain-lists/info?chainId=${chainId}&isTestnet=false`, {});
	// },
	// private api


};
