import React, { useEffect, useState } from 'react';
import { useActiveWeb3React } from '../../hooks';
import { Spin } from 'antd';
import { formatShortDateAndTimeAllocations } from "../../utils/formatDatetime";
import { formatNumberCurrency, truncateNumber } from "../../utils/formatNumberCurrency";
import { useIdoTokenClaimContract } from "../../hooks/useContract";
import {
    _isClaimedSC,
    _isRefundedSC,
    _isJoinedSC,
    _totalJoinedSC,
    _joinPool,
    _refund,
    _claimTokens,
    _userClaimSC,
    _userClaimRoundSC,
    _totalClaimedSC,
    convertFromWei,
    _totalRefundedSC,
    // _totalClaimAble
} from "./utils";
import useWindowSize from '../../hooks/useWindowSize';
import allActions from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import { CHAINID_FULLNAME, Networks } from '../../constants';
import { getChainInformation } from '../../utils/campaignUltils';
import { id } from 'ethers/lib/utils';


const spinLoading = () => <Spin size="small" style={{ position: 'absolute', marginLeft: "5px" }} />;

const MonthlyAllocation = ({ allocationItem, whitelist, tokenDecimals, isRefundedAll, actualFundNeeded, isLoadingRefund, oldVersion, key, vestingInfo, allowRefundRound, refundEspecialAllow }) => {

    const { account, chainId } = useActiveWeb3React();
    const [isLoading, setIsLoading] = useState(false);
    const [isClaimed, setIsClaimed] = useState(false);
    const [isVestingRefund, setIsVestingRefund] = useState(false);
    const claimContract = useIdoTokenClaimContract(allocationItem?.contractAddress);
    const dispatch = useDispatch();
    const nowDate = new Date(Date.now());
    const size = useWindowSize();
    const sizeTablet = 991;
    const campaignsData = useSelector(state => state.campaignsData);
    const { network, claimChain } = campaignsData;
    const chainInformation = getChainInformation(chainId);
    const isChainBSC = (claimChain && claimChain === Networks.BSC || !claimChain && network === Networks.BSC) ? true : false;
    const isChainETH = (claimChain && claimChain === Networks.ETH || !claimChain && network === Networks.ETH) ? true : false;
    const isChainPOLY = (claimChain && claimChain === Networks.POLY || !claimChain && network === Networks.POLY) ? true : false;
    const isChainARB = (claimChain && claimChain === Networks.ARB || !claimChain && network === Networks.ARB) ? true : false;

    useEffect(() => {
        if (account && claimContract) {
            _userClaimSC(claimContract, account, allocationItem, oldVersion).then((res) => {
                setIsClaimed(res);
                if (res === false) {
                    if (((allocationItem.roundId === 99998 || allocationItem.roundId === 99999 ||
                        allocationItem.roundId === 100000 || allocationItem.roundId === 100001 ||
                        allocationItem.roundId === 100002 || allocationItem.roundId === 100005
                    ) && allocationItem.claimTime.includes('2022-10-06')) ||
                        ((allocationItem.roundId === 100007 || allocationItem.roundId === 100008 ||
                            allocationItem.roundId === 100009 || allocationItem.roundId === 100016 ||
                            allocationItem.roundId === 100017
                        ) && allocationItem.claimTime.includes('2022-10-17')) ||
                        ((allocationItem.roundId === 100012 || allocationItem.roundId === 100013 ||
                            allocationItem.roundId === 100014 || allocationItem.roundId === 100015)
                            && allocationItem.claimTime.includes('2022-10-13'))
                    ) {
                        _userClaimRoundSC(claimContract, account, allocationItem, oldVersion).then((res) => {
                            setIsClaimed(res);
                        });
                    }
                }
            });

            _isRefundedSC(claimContract, account).then((res) => {
                setIsVestingRefund(res);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, claimContract]);

    const _handleClaim = async (claimItem) => {

        if (claimItem && claimItem.tokenAmount > 0) {
            if (isChainPOLY && chainId !== 137) {
                Store.addNotification(addNotify('Please select network Polygon', 'danger'));
                return;
            }

            if (isChainBSC && chainId !== 56) {
                Store.addNotification(addNotify('Please select network BSC', 'danger'));
                return;
            }

            if (isChainETH && chainId !== 1) {
                Store.addNotification(addNotify('Please select network Ethereum', 'danger'));
                return;
            }

            if (isChainARB && chainId !== 42161) {
                Store.addNotification(addNotify('Please select network Arbitrum', 'danger'));
                return;
            }

            if (!claimItem.signatureIDO) {
                Store.addNotification(addNotify('Signature claim is invalid', 'danger'));
                return;
            }

            if (!whitelist.fundCommitted || whitelist.fundCommitted === 0 || Number(whitelist.fundCommitted) < Number(whitelist.actualFundNeeded)) {
                Store.addNotification(addNotify('Fund committed is invalid', 'danger'));
                return;
            }

            setIsLoading(true);

        
            await _claimTokens(claimContract, claimItem, tokenDecimals, oldVersion)
                .then((res) => {
                    let resWait = res.wait();
                    resWait.then((resTransaction) => {

                        if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                            Store.addNotification(addNotify('Tokens claimed successfully', 'success'));

                            // Update PUT api/campaign/claim-ido/client
                            let payload = {
                                "address": account,
                                "allocationId": claimItem.id,
                                "claimedAmount": claimItem.tokenAmount,
                                "claimedTxn": res.hash,
                                "isClaimed": true,
                                "roundId": claimItem.roundId
                            };
                            dispatch(allActions.allocationAction.updateClaimIdo(payload));
                            setIsLoading(false);
                            setIsClaimed(true);
                        }
                        else {
                            setIsLoading(false);
                            Store.addNotification(addNotify('Transaction failed', 'warning'));
                            return;
                        }

                    })
                        .catch((error) => {
                            setIsLoading(false);
                            if (error.data) {
                                Store.addNotification(addNotify("Transaction failed", 'danger'));
                            }
                            if (error) {
                                Store.addNotification(addNotify(error?.reason, 'danger'));
                            }
                        });
                })
                .catch((error) => {
                    setIsLoading(false);
                    if (error) {
						if (error.code === 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
                });
        }
        else {
            Store.addNotification(addNotify('Claim token amount is invalid!', 'warning'));
        }
    }

    const _handleRefund = async () => {
        if (isChainPOLY && chainId !== 137) {
            Store.addNotification(addNotify('Please select network Polygon', 'danger'));
            return;
        }
        if (isChainBSC && chainId !== 56) {
            Store.addNotification(addNotify('Please select network BSC', 'danger'));
            return;
        }

        if (isChainETH && chainId !== 1) {
            Store.addNotification(addNotify('Please select network Ethereum', 'danger'));
            return;
        }
        if (isChainARB && chainId !== 42161) {
            Store.addNotification(addNotify('Please select network Ethereum', 'danger'));
            return;
        }

        if (!allocationItem?.contractAddress) {
            Store.addNotification(addNotify('Invalid refund contract', 'danger'));
            return;
        }

        if (!allocationItem.refundAmount) {
            Store.addNotification(addNotify('Invalid actual fund needed', 'danger'));
            return;
        }

        if (!chainInformation?.decimal) {
            Store.addNotification(addNotify('Chain decimal is invalid', 'danger'));
            return;
        }

        const payload = {
            address: account,
            allocationId: allocationItem?.id
        }


        await dispatch(allActions.allocationAction.getSignatureRefundIdo(payload, async (res) => {

            await _refund(claimContract, allocationItem.refundAmount, res.signatureRefundIdo, chainInformation?.decimal)
                .then((res) => {
                    let resWait = res.wait();
                    resWait.then((resTransaction) => {
                        if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                            const payload = {
                                allocationId: allocationItem?.id,
                                refundedTxn: res.hash
                            }
                            dispatch(allActions.allocationAction.updateRefund(payload, (res) => {
                                if (res) {
                                    Store.addNotification(addNotify('Refunded successfully', 'success'));
                                    setIsVestingRefund(true);
                                }
                            }));

                        } else {
                            Store.addNotification(addNotify('Refunded failed', 'danger'));
                        }
                    })
                        .catch((error) => {
                            setIsVestingRefund(false);
                            if (error.data) {
                                Store.addNotification(addNotify("Refunded Error", 'danger'));
                            }
                            if (error) {
                                Store.addNotification(addNotify(error?.reason, 'danger'));
                            }
                            return false;
                        });
                })
                .catch((error) => {
                    setIsVestingRefund(false);
                    if (error.data) {
                        Store.addNotification(addNotify("Refunded Error", 'danger'));
                    }
                    if (error) {
                        Store.addNotification(addNotify(error?.reason, 'danger'));
                    }
                    return false;
                });
        }));
    }

    const btnClaim = (allocationItem) => {
        let claimTime;
        if (allocationItem.claimTime) {
            claimTime = new Date(allocationItem.claimTime);
        }
        else {
            claimTime = null;
        }
        const activatedItem = allocationItem && (allocationItem.activated === true && (nowDate >= claimTime && claimTime)) ? true : false;

        const obj = {
            className: `c-btn claim`,
            disabled: isLoading | isClaimed | !activatedItem,
        };
        return obj;
    }

    const btnRefund = (item) => {
        const obj = {
            className: `c-btn c-btn--border mobile`,
            disabled: isLoadingRefund || isRefundedAll || !actualFundNeeded,
        };
        return obj;
    }

    const claimButtonRenderer = () => {

        let element = '';
        if (isClaimed) {
            element = (<span className="c-action">Claimed</span>);
        }
        else if (!isClaimed && isRefundedAll) {
            element = (<span className="c-action">Refunded</span>);
        }
        else if (!isClaimed && !isRefundedAll) {
       
            // console.log('allowRefundRound', allowRefundRound);
            // console.log('vestingInfo?.allowRefund', vestingInfo?.allowRefund);
            if (campaignsData?.modelType === 1 && vestingInfo?.allowRefund) {
                if (isVestingRefund) {
                    element = (<span className="c-action">Refunded</span>);
                }
                else {
                    if (allowRefundRound) {
                        element = (
                            <div className='btn-action'>
                                <button {...btnClaim(allocationItem)} onClick={() => _handleClaim(allocationItem)}>
                                    Claim {isLoading && spinLoading()}
                                </button>
                                <button {...btnRefund(allocationItem)} onClick={() => _handleRefund()}>
                                    Refund {isLoading && spinLoading()}
                                </button>
                            </div>
                        );
                    }
                }
            }
            else if (campaignsData?.modelType === 2 || campaignsData?.modelType === 1 && !vestingInfo?.allowRefund) {
               
                if (oldVersion) {
                    element = (
                        <button {...btnClaim(allocationItem)} onClick={() => _handleClaim(allocationItem)}>
                            Claim {isLoading && spinLoading()}
                        </button>
                    );
                }
                else {
                    if (allowRefundRound) {
                        element = (
                            <button {...btnClaim(allocationItem)} onClick={() => _handleClaim(allocationItem)}>
                                Claim {isLoading && spinLoading()}
                            </button>
                        );
                    }
                    if (campaignsData?.modelType === 1 && !refundEspecialAllow) {
                        element = (
                            <button {...btnClaim(allocationItem)} onClick={() => _handleClaim(allocationItem)}>
                                Claim {isLoading && spinLoading()}
                            </button>
                        );
                    }
                }


            }
        }

        return element;
    }

    return (
        <>
            {size.width < sizeTablet ? <>
                <tr>
                    <td colSpan="4">
                        <div className="c-campaignRound__tableSP">
                            <p>{formatShortDateAndTimeAllocations(allocationItem.claimTime, true)}</p>
                            <p><span>Token Allocation</span>{formatNumberCurrency(allocationItem.tokenAmount)}</p>
                            <p><span>Percentage</span>{allocationItem.percentage} {"%"}</p>
                            {claimButtonRenderer()}
                        </div>
                    </td>
                </tr>
            </> : <tr>
                <td>{formatShortDateAndTimeAllocations(allocationItem.claimTime)}</td>
                <td>{formatNumberCurrency(allocationItem.tokenAmount)}</td>
                <td>{allocationItem.percentage} {"%"}</td>
                <td align='center'>
                    <div className='text-center'>
                        {claimButtonRenderer()}
                    </div>
                </td>
            </tr>}
        </>
    );
};

export default MonthlyAllocation;