import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
// import 'antd/dist/antd.css';
import { Alert, DatePicker, Card, Col, Space, Form, Input, Radio, Row, Spin, message } from 'antd';
import { LinkOutlined, FacebookFilled, FundOutlined, GithubFilled, IeOutlined, QuestionCircleOutlined, RedditCircleFilled, TwitterCircleFilled, InstagramOutlined } from '@ant-design/icons';
import './style.css';
import { useActiveWeb3React } from '../../../../hooks';
import web3 from 'web3';
import { ethers } from 'ethers';

import { useDeployAirdropContract } from '../../../../hooks/useContract';
import { Store } from 'react-notifications-component';
import addNotify from '../../../common/Notify/addNotify';
import { AirdropApi } from '../../../../config/api/airdropApi';
import { getInformationByChain } from '../../../../config/network/multichainAddresses';
import { deployAirdrop } from '../../../utils';
import {
    INIT_AIRDROP_FEE,
    INIT_TOKEN_MAIN_FEE
} from '../../../../components/keyConfig';
import { BaseAPI } from '../../../../config/api/BaseApi';
import { Loader } from '../../../common/component';

// declare const window: Window & typeof globalThis & { ethereum: any };
const { TextArea } = Input;

const AirDropContent = (props) => {
    
    const { account, library, chainId } = useActiveWeb3React();

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

    const [loadingFull, setLoadingFull] = useState(false);
    const [loading, setLoading] = useState(false);
    const [initAirdropFee, setInitAirdropFee] = useState(0);
    const [initAirdropFeeOpj, setInitAirdropFee0pj] = useState(null);
    const [feeList, setFeeList] = useState([]);
    const [feeOptions, setFeeOptions] = useState();
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState(""); 

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    let airdropTokenInfoStorage = window.localStorage.getItem('airdropTokenInfo');
    let tokenInfo;
    if (airdropTokenInfoStorage) {
        tokenInfo = JSON.parse(airdropTokenInfoStorage);
    }

    const deployAirdropAddress = getInformationByChain(chainId, 'REACT_APP_DEPLOY_AIRDROP');

    const deployAirdropContract = useDeployAirdropContract(deployAirdropAddress);

    const getSettingsByChain = async () => {
        try {
            let res = await BaseAPI.getSettingsByChain(chainId);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setFeeList(res.data);
                    let initFee = res.data.filter((itemSetting) => INIT_AIRDROP_FEE === itemSetting.settingKey);
                    if (initFee.length > 0) {
                        setInitAirdropFee(parseFloat(initFee[0].settingValue));
                        setInitAirdropFee0pj(initFee[0]);
                    }
                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    }

    useEffect(() => {
        if (chainId) {
            getSettingsByChain();
        }
    }, [chainId]);

    const onChangeFee = e => {
        setFeeOptions(e.target.value);
    };

    const onConfirmCreate = async (values) => {
        try {
            if (tokenInfo.chainId != chainId) {
                message.error('Invalid Network. Please Connect Correct Network!')
                return;
            }
            setLoadingFull(true);


            let feeIds = [];

            let airdropFee;
            let isW86fee = false;

            let airdropFeeObj;
            let airdropFeeW86Obj;

            JSON.parse(values.fee_options).forEach(element => {

                let initFeeAirdrop = feeList.filter((itemSetting) => element === itemSetting.settingKey);
                if (initFeeAirdrop.length > 0) {
                    feeIds.push(initFeeAirdrop[0].id);
                }

                // set init airdrop fee
                if (element == INIT_AIRDROP_FEE) {
                    let initFeeAirdropKey = feeList.filter((itemSetting) => INIT_AIRDROP_FEE === itemSetting.settingKey);
                    if (initFeeAirdropKey.length > 0) {
                        airdropFeeObj = initFeeAirdropKey[0]
                    }
                }
                if (element == INIT_TOKEN_MAIN_FEE) {
                    let initFeeAirdropW86Key = feeList.filter((itemSetting) => INIT_AIRDROP_FEE === itemSetting.settingKey);
                    if (initFeeAirdropW86Key.length > 0) {
                        airdropFeeW86Obj = initFeeAirdropW86Key[0]
                    }
                }
            });
            if (JSON.parse(values.fee_options).length === 1) {
                if (airdropFeeObj) {
                    airdropFee = airdropFeeObj.settingValue
                }
            } else if (JSON.parse(values.fee_options).length === 2) {
                if (airdropFeeObj && airdropFeeW86Obj) {
                    airdropFee = airdropFeeObj.settingValue - (airdropFeeObj.settingValue * (airdropFeeW86Obj.settingValue / 100));
                    isW86fee = true;
                }
            }

            if (!feeIds || !airdropFee) {
                message.error('Invalid Fee!')
                return;
            }

            const fundPercent = 100;
            const feeAmount = ethers.utils.parseUnits(`${airdropFee}`, 18)

            await deployAirdrop(deployAirdropContract, tokenInfo.token_address, fundPercent, feeAmount).then((txn) => {
                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {

                                    if (!countNoti) {
                                        countNoti++;
                                        let newSCAddress;

                                        for (let i = 0; i < res.logs.length; i++) {
                                            if (res.logs[i].topics && res.logs[i].topics.length == 2 && res.logs[i].topics[0] === '0xb5f54381f1b62853b1376e6d5833ba01af971a761c4efef72944cb6e64d591f1') {
                                                newSCAddress = res.logs[i].topics[1];
                                            }
                                        }
                                       
                                        if (newSCAddress) {
                                            newSCAddress = w3.eth.abi.decodeParameter('address', newSCAddress);

                                            let startTime = new Date(values.startTime);
                                            let endTime = new Date(values.endTime);
                                            let timeClaimReward = new Date(values.timeClaimReward);

                                            const payload = {
                                                
                                                airDropAddress: newSCAddress,
                                                airDropStatus: 'CREATE',
                                                airDropTitle: values.airdrop_title || '',
                                                chainId: tokenInfo.chainId,
                                                description: values.description || '',
                                                ownerAddress: account,
                                                totalTokenReward: Number(values.totalTokenReward) || 0,
                                                startTime: values.startTime ? startTime.toISOString() : '',
                                                endTime: values.endTime ? endTime.toISOString() : '',
                                                timeClaimReward: values.endTime ? endTime.toISOString() : '',
                                                logoUrl: values.logo_url || '',
                                                tokenAddress: tokenInfo.token_address,
                                                tokenName: tokenInfo.token_name,
                                                tokenSymbol: tokenInfo.token_symbol,
                                                website: values.website || '',
                                                facebook: values.facebook || '',
                                                twitter: values.twitter || '',
                                                github: values.github || '',
                                                telegram: values.telegram || '',
                                                instagram: values.instagram || '',
                                                discord: values.discord || '',
                                                reddit: values.reddit || '',
                                                onHome: false,
                                                virtualMachine: "EVM",
                                                taskLink: values.taskLink || '',
                                                settingIds: feeIds
                                            }

                                            // payload.rounds[0].roundAddress = w3.eth.abi.decodeParameter('address', newSCAddress);
                                            let res = await AirdropApi.createAirDrop(payload);
                                            window.localStorage.removeItem('airdropTokenInfo');
                                            if (res && res.data && res.data.handle) {
                                                Store.addNotification(addNotify('Successfully!', 'success'));

                                                setTimeout(() => {
                                                    navigate(`/airdrop/detail/${newSCAddress}/${res.data.handle}`);
                                                }, 2000);
                                            }else{
                                                Store.addNotification(addNotify('Failed!', 'danger'));
                                            }
                                        }
                                    }
                                } else {
                                    
                                    Store.addNotification(addNotify('Create Airdrop Failed!', 'danger'));
                                }
                                setLoadingFull(false);

                            }
                        })();
                    }, 1000);
                }
            }).catch((error) => {
                console.log(error);
                setLoadingFull(false);
                if (error) {
                    if (error.code == 4001 && error.message) {
                       
                        Store.addNotification(addNotify(error.message, 'danger'));
                    } else if (error.reason) {
                        Store.addNotification(addNotify(error.reason, 'danger'));
                    } else {
                        if (error.data && error.data.message) {
                            Store.addNotification(addNotify(error.data.message, 'danger'));
                        }
                    }
                }
            });
        } catch (error) {
            setLoadingFull(false)
            console.log('Error: ', error);
        }
    };
    const onOkStartTime = (dates) => {  
        console.log('setStartTime: ', dates?.toISOString());
        setStartTime(dates?.toISOString()) 
    }
    const onOkEndTime = (dates) => {  
        console.log('setEndTime: ', dates?.toISOString());
        setEndTime(dates?.toISOString()) 
    }
    return (
        <>
            <Col className="gutter-row" xs={24}>
                <Card style={{ width: '100%' }} className="card-title">
                    <Form form={form} layout="vertical" autoComplete="off" className='basic-form'
                        onFinish={onConfirmCreate}
                    >
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="airdrop_title" label="Airdrop Title" rules={[{ required: true, message: 'Airdrop Title cannot be blank!' }]}>
                                    <Input className="amount-buy" type="text" placeholder="Airdrop Title..." />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="logo_url"
                                    label={<>
                                        Logo URL (Click here upload your image and copy link:<a style={{marginLeft: '5px'}} target="_blank" href="/upload-image"> Upload Image</a>)
                                    </>}
                                    rules={[{ required: true, message: 'Logo URL cannot be blank!' }]}>
                                    <Input className="amount-buy" type="text" placeholder="Logo URL..." prefix={<FundOutlined className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="totalTokenReward" label="Total Token Reward" rules={[
                                    { required: true, message: 'Total Token Reward cannot be blank!' },
                                    {
                                        validator: (rule, value, cb) => {
                                            !value || parseFloat(value) <= 0
                                                ? cb("Total Token Reward must be > 0")
                                                : cb();
                                        }
                                    }
                                ]}>
                                    <Input className="amount-buy" type="number" placeholder="100.000..." />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="taskLink" label="Task Link">
                                    <Input className="amount-buy" type="text" placeholder="Task Link..." prefix={<LinkOutlined className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="startTime" label="Start Time (UTC)"
                                    rules={[
                                        { required: true, message: 'Please input Start time!' },
                                        {
                                            validator: (rule, value, cb) => {
                                                !value || new Date(value) <= new Date(Date.now())
                                                    ? cb("Start time needs to be after now")
                                                    : cb();
                                            }
                                        }
                                    ]}>
                                    <DatePicker className='form-picker' showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onOk={onOkStartTime} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="endTime" label="End Time (UTC)"
                                    rules={[
                                        { required: true, message: 'Please input End Time!' },
                                        {
                                            validator: (rule, value, cb) => {

                                                let startTime = new Date(form.getFieldValue('startTime'));

                                                if (!value || new Date(value) <= new Date(Date.now())) {
                                                    cb("End time needs to be after now time");
                                                } else if (form.getFieldValue('startTime') && new Date(value) <= startTime) {
                                                    cb("End time needs to be after start time");
                                                } else {
                                                    cb();
                                                }
                                            }
                                        }
                                    ]}>
                                    <DatePicker className='form-picker' showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onOk={onOkEndTime} />
                                </Form.Item>
                            </Col>
                            {/* <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="timeClaimReward" label="Time Claim Reward (UTC)"
                                    rules={[
                                        { required: true, message: 'Please input Time Claim Reward!' },
                                        
                                    ]}>
                                    <DatePicker className='form-picker' showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onOk={onOkEndTime} />
                                </Form.Item>
                            </Col> */}
                            
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="website" label="Website">
                                    <Input className="amount-buy" type="text" placeholder="Website..." prefix={<IeOutlined className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="facebook" label="Facebook">
                                    <Input className="amount-buy" type="text" placeholder="Facebook..." prefix={<FacebookFilled className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="twitter" label="Twitter">
                                    <Input className="amount-buy" type="text" placeholder="Twitter..." prefix={<TwitterCircleFilled className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="github" label="Github">
                                    <Input className="amount-buy" type="text" placeholder="Github..." prefix={<GithubFilled className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="telegram" label="Telegram">
                                    <Input className="amount-buy" type="text" placeholder="Telegram..." prefix={<img width={17} src='../images/telegram.svg' />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="instagram" label="Instagram">
                                    <Input className="amount-buy" type="text" placeholder="Instagram..." prefix={<InstagramOutlined className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="discord" label="Discord">
                                    <Input className="amount-buy" type="text" placeholder="Discord..." prefix={<img width={17} src='../images/discord.svg' />} />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                <Form.Item name="reddit" label="Reddit">
                                    <Input className="amount-buy" type="text" placeholder="Reddit..." prefix={<RedditCircleFilled className="site-form-item-icon" />} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={24}>
                                <Form.Item name="description" label="Description">
                                    <TextArea rows={5} className="amount-buy" placeholder="Enter description" />
                                </Form.Item>
                                {/* <p className='text-info'>Create Airdrop fee: {initAirdropFeeOpj?.settingValue} {initAirdropFeeOpj?.payToken}</p> */}
                            </Col>

                            <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                                <Form.Item
                                    name="fee_options"
                                    label="Fee Options"
                                    initialValue={feeOptions}
                                    rules={[{ required: true, message: 'Please pick fee options!' }]}
                                >
                                    <Radio.Group onChange={onChangeFee}>
                                        <Space direction="vertical" className='ml-15'>
                                            {feeList.map((item, index) => {
                                                let valFeeMainToken;
                                                if (item.settingKey === INIT_TOKEN_MAIN_FEE && item.feeType === "PERCENT") {
                                                    valFeeMainToken = parseFloat(initAirdropFeeOpj?.settingValue) - (initAirdropFeeOpj?.settingValue * (item.settingValue / 100));
                                                }
                                                return (
                                                    <>
                                                        {item.settingKey === INIT_AIRDROP_FEE && ( // BNB fee
                                                            <Radio value={JSON.stringify(['INIT_AIRDROP_FEE'])} key={'fee-12' + index}>
                                                                {item.settingValue}{item.feeType === "PERCENT" ? '%' : ''} {initAirdropFeeOpj?.payToken}
                                                            </Radio>
                                                        )}
                                                        {item.settingKey === INIT_TOKEN_MAIN_FEE && ( // BNB only fee
                                                            <Radio disabled={true} value={JSON.stringify(['INIT_TOKEN_MAIN_FEE', 'INIT_AIRDROP_FEE'])} key={'fee-122' + index}>
                                                                Amount of <span className='text-info'>HLUP</span> equivalent to {valFeeMainToken} {initAirdropFeeOpj?.payToken} <span className='text-danger'>({item.settingValue}{item.feeType === "PERCENT" ? '%' : ''} discount)</span>
                                                            </Radio>
                                                        )}
                                                    </>
                                                )
                                            })}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>



                            <Col className="gutter-row" xs={24}>
                                <Form.Item className="btn-content btn-content-config">
                                    <button className="btn-basic p-lr-20 w-200" disabled={!initAirdropFeeOpj ? true : false} type="submit">Create Airdrop {loading && <Spin size="small" />}</button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>
            {loadingFull && (
                <Loader />
            )}
        </>
    )
}

export default AirDropContent
