import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import allActions from '../../actions';
import { useMyBoxContract } from '../../hooks/useContract'; 
import { _myBoxInfo } from "./utils";

const MyOrderItem = ({data, boxContract}) => {
    const currentUser = useSelector(state => state.currentUser);
    const account = currentUser.address;
    const [boxBought, setBoxBought] = useState(0);

    const idBox = data?.id;
    
    const contractBox = useMyBoxContract(boxContract);

    useEffect(() => {
        if(account && idBox) {
            _myBoxInfo(contractBox, account, idBox).then((resData) => { 
                if(resData && resData.toNumber() > 0) {
                    setBoxBought(resData.toNumber());
                }
            });
        } 
    }, [contractBox, account, idBox, boxBought]);

    return(<>
        {boxBought > 0 && <div className="list-myorder">
            <div className="myorder__item is-box">
                <img src={data?.imageLink} alt="" />
                {data?.name}
            </div>
            <div className="myorder__item">
                <span className="title">Quantity</span>
                <span>{boxBought} BOXES</span>
            </div>
            <div className="myorder__item">
                <span className="title">Price/box</span>
                <span>{data?.price} {data?.currency}</span>
            </div>
        </div>}
        </>
    );
}

export default MyOrderItem;