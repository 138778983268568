import React from "react";
import IconNew from '../asset/images/new-fire-3.gif';

const IconHome = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <path d="M2.15918 23.751H23.7508" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.18457 23.7509L3.23855 10.7635C3.23855 10.105 3.55163 9.47885 4.06983 9.06861L11.6269 3.18487C12.4042 2.5803 13.4946 2.5803 14.2827 3.18487L21.8398 9.05781C22.3688 9.46805 22.6711 10.0942 22.6711 10.7635V23.7509" strokeWidth="1.5" strokeMiterlimit="10" strokeLinejoin="round"/>
        <path d="M16.7331 11.8755H9.17602C8.27996 11.8755 7.55664 12.5988 7.55664 13.4949V23.7509H18.3525V13.4949C18.3525 12.5988 17.6292 11.8755 16.7331 11.8755Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const IconLaunchpad = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <mask id="mask0_3089_5551" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="26">
        <rect width="25.91" height="25.91" fill="#FF0000"/>
        </mask>
        <g mask="url(#mask0_3089_5551)">
            <path d="M8.59961 8.6001V4.9001C8.59961 4.1001 9.19961 3.4001 9.99961 3.2001C11.9996 2.9001 13.9996 2.9001 15.9996 3.2001C16.7996 3.3001 17.3996 4.0001 17.3996 4.9001V10.8001C17.3996 12.0001 16.3996 13.0001 15.1996 13.0001H10.7996C9.59961 13.0001 8.59961 14.0001 8.59961 15.2001V21.1001C8.59961 21.9001 9.19961 22.6001 9.99961 22.8001C11.9996 23.1001 13.9996 23.1001 15.9996 22.8001C16.7996 22.7001 17.3996 22.0001 17.3996 21.1001V17.4001" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.4 8.6001H21.1C21.9 8.6001 22.6 9.2001 22.8 10.0001C23.1 12.0001 23.1 14.0001 22.8 16.0001C22.7 16.8001 22 17.4001 21.1 17.4001H13" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.9996 8.6001H4.89961C4.09961 8.6001 3.39961 9.2001 3.19961 10.0001C2.89961 12.0001 2.89961 14.0001 3.19961 16.0001C3.29961 16.8001 3.99961 17.4001 4.89961 17.4001H8.59961" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 5.8501V5.8501" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15 20.1499V20.1499" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>
);

const IconGamehub = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
        <mask id="mask0_3089_5542" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="26">
            <rect x="0.0800781" width="25.91" height="25.91" fill="#060606"/>
        </mask>
        <g mask="url(#mask0_3089_5542)">
            <path d="M10.5695 13.46L7.51953 16.51" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.55078 13.4902L10.6008 16.5402" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.5293 15H14.5393" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.4707 15H18.4807" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.5 16.98V16.96" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.5 13.04V13.02" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 23H16C21 23 23 21 23 16V14C23 9 21 7 16 7H10C5 7 3 9 3 14V16C3 21 5 23 10 23Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.0105 3L14.0005 4.01C13.9905 4.56 13.5505 5 13.0005 5H12.9705C12.4205 5 11.9805 5.45 11.9805 6C11.9805 6.55 12.4305 7 12.9805 7H13.9805" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </svg>
);

const IconAirdrop = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
        <path d="M10.5 15.7502C10.5 16.7202 11.25 17.5002 12.17 17.5002H14.05C14.85 17.5002 15.5 16.8202 15.5 15.9702C15.5 15.0602 15.1 14.7302 14.51 14.5202L11.5 13.4702C10.91 13.2602 10.51 12.9402 10.51 12.0202C10.51 11.1802 11.16 10.4902 11.96 10.4902H13.84C14.76 10.4902 15.51 11.2702 15.51 12.2402" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13 9.5V18.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23 14C23 19.52 18.52 24 13 24C7.48 24 3 19.52 3 14C3 8.48 7.48 4 13 4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18 5V9H22" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M23 4L18 9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const DataMenu = [
    {title: 'Home', icon: IconHome(), to: '/'},
    {title: 'IDO LaunchPad', icon: IconLaunchpad(), to: '#', submenu: [
        // {title: "Apply LaunchPad", to: "/apply-launchpad"},
        {title: "Join IDO", to: "/launchpad/upcoming"},
        {title: "Staking", to: "/stake", linkTo: 'https://stake.kingdomstarter.io/'},
        // {title: "Voting", to: "/voting"},
    ]},
    {title: 'INO LaunchPad', icon: IconLaunchpad(), to: "/ino/upcoming"},
    {title: 'Gamehub', icon: IconGamehub(), to: '/gamehub'},
    {title: 'Airdrop', icon: IconAirdrop(), to: '/airdrop/upcoming', iconNew: IconNew}
];


export default DataMenu;