import React, { useEffect, useState } from "react";
import {Link , useNavigate} from "react-router-dom";
import FooterHome from '../common/footer/footerHome';
import { useActiveWeb3React } from "../../hooks";
import { Tabs , Empty, Row, Col, Upload, Breadcrumb, Form, Spin, Input } from "antd";
import { FormOutlined } from '@ant-design/icons';

import { NftApi } from "../../config/api/apiNFT";
import { Store } from "react-notifications-component";
import addNotify from "../common/Notify/addNotify";

const { TextArea } = Input;
const { Dragger } = Upload;

const UserProfile = () => {
    const { account } = useActiveWeb3React();

    let tokenId = localStorage.getItem('authUser');
    
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [profileDetail, setProfileDetail] = useState(null);
    const [image, setImage] = useState('');


    const getProfileDetail = async (account) => {
        let rs = await NftApi.getProfileByAddress(account);
        if(rs.status == 200){
            setProfileDetail(rs.data);
        }
    }
    useEffect(() => {
        if(account){
            getProfileDetail(account)
        }
    }, [account]);

    const props = {
        multiple: false,
        beforeUpload: file =>{
            const formData = new FormData();
            formData.append('file', file);
           

            NftApi.uploadImage(formData, tokenId).then((res)=>{
                console.log('uploadImage', res);
                if(res.status === 200 && res.data){
                    setImage(res.data);
                    Store.addNotification(addNotify('Upload Successfully!', 'success'));
                }
            }).catch((error)=>{
                console.log('error', error);
                Store.addNotification(addNotify('Upload Failed!', 'danger'));
            })
        
            
        }
    };


    const onSubmitProfile = async (values) => {
        setLoading(true);

        try {
            if(profileDetail?.id && values?.type !== ''){
                values.id = profileDetail?.id;
                await NftApi.editProfile(profileDetail?.id, values).then((res)=>{
                    if(res.status == 200){
                        Store.addNotification(addNotify('Edit profile success!', 'success'));
                        getProfileDetail(account)
                        setLoading(false);
                    }else{
                        setLoading(false);
                    
                        Store.addNotification(addNotify('Edit Failed!', 'danger'));
                    }
                })
            }else{
                await NftApi.postProfile(values).then((res)=>{
                    if(res.status == 201){
                        Store.addNotification(addNotify('Setup profile success!', 'success'));
                        getProfileDetail(account)
                        setLoading(false);
                    }else{
                        setLoading(false);
                    
                        Store.addNotification(addNotify('Setup Error!', 'danger'));
                    }
                })
            }
            
        } catch (e) {
            setLoading(false);
            Store.addNotification(addNotify('Server Error!', 'danger'));
        }
    }

    useEffect(() => {
        if(account){
            form.setFieldsValue({
                ownerAddress: account,
                username: profileDetail?.username ?? form.getFieldValue('username'),
                bio: profileDetail?.bio ?? form.getFieldValue('bio'),
                email: profileDetail?.email ?? form.getFieldValue('email'),
                avatarLink: image !== '' ? image : profileDetail?.avatarLink,
                type: profileDetail?.id ? profileDetail?.id : ''
            })
        }
        
    }, [account, profileDetail, image]);

    const closeProfile = ()=>{
        navigate('/genesis-nft/profile')
    }

    return (
        <>
            <div className="profile-page">
                
                <div className="container">
                    <Breadcrumb className='head-breadcrumb-detail'>
                        <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                        <Breadcrumb.Item><a href='#'>Profile</a></Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="profile-content">
                        <Row gutter={40}>
                            <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                                <div className="profile-avt">
                                    <div className="mb-10 text-weight-500 text-darkblue text-16">Profile Image</div>
                                    {image || (profileDetail && profileDetail?.avatarLink) ? (
                                        <img src={image !== '' ? image : profileDetail?.avatarLink} />
                                    ):(
                                        <img src="../images/avatar-default.png" alt=""/>
                                    )}
                                    
                                </div>
                            </Col>
                            <Col className="gutter-row border-left" xs={24} xl={18} md={18} sm={18}>
                                <Form
                                    form={form}
                                    name="basic"
                                    autoComplete="off"
                                    onFinish={onSubmitProfile}
                                    layout="vertical"
                                    className='basic-form'
                                >
                            
                                    <Form.Item name="username" label="Username" rules={[{ required: true, message: 'Please input Enter username!' }]}>
                                        <Input  placeholder="Enter username" />
                                    </Form.Item>

                                    <Form.Item name="type" hidden>
                                        <Input  placeholder="Enter username" />
                                    </Form.Item>

                                    <div>
                                        <Dragger {...props}
                                            accept=".png,.jpg,.jpeg,.svg, gif"
                                            showUploadList={false}
                                            className="custom-upload-no-bg"
                                        >
                                            <span>Upload Image</span>
                                        </Dragger>
                                    </div>
                                    <Form.Item name="avatarLink" label="Avatar Link" rules={[{ required: true, message: 'Please input Avatar Link!' }]}>
                                        <Input  placeholder="http://imagelink.com..." />
                                    </Form.Item>
                                    

                                    <Form.Item name="bio" label="Bio" rules={[{ required: true, message: 'Please input Bio!' }]}>
                                        <TextArea rows={4} placeholder="Tell the world your story!"></TextArea>
                                    </Form.Item>

                                    <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please input Email!' }]}>
                                        <Input type="email" placeholder="Ex: bio@gamil.com" />
                                    </Form.Item>
                                    <Form.Item name="ownerAddress" label="Wallet address" rules={[{ required: true, message: 'Please input Email!' }]}>
                                        <Input type="text" readOnly placeholder="Ex: bio@gamil.com" />
                                    </Form.Item>

                                    
                                    <div className="modal-btn-group max-with-400">
                                        <button className="btn-basic" disabled={loading || !account} type="submit">Confirm {loading && <Spin size="small" />}</button>
                                        <button className="btn-basic-default" disabled={loading} onClick={closeProfile}>Close</button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <FooterHome />
        </>
    )
}
export default UserProfile
