import web3 from 'web3'
import BigNumber from 'bignumber.js'
import { MaxUint256 } from '@ethersproject/constants'

export const convertToWei = (value, tokenDecimals) => {
    return web3.utils.toWei(value.toString(), tokenDecimals);
  }
  
  export const convertFromWei = (value, tokenDecimals) => {
    return web3.utils.fromWei(value.toString(), tokenDecimals);
  }
  

export const _getBoughtBoxes = async (boxContract, userAddress, campaignId, boxId) => {

    try {
        return boxContract && boxContract.getBoughtBoxes(userAddress, campaignId, boxId)
    } catch (error) {
        return undefined
    }
}

export const _getCampaignBoxInfo = async (boxContract, _campaignId, _boxId) => {
    if (_campaignId === undefined) {
        return {}
    }
    try {
        const result = await boxContract && boxContract.getCampBoxInfo(_campaignId, _boxId)
        return result
    } catch (error) {
        return {}
    }
}

export const _getSoldBox = async (boxContract, _campaignId, _boxId) => {
    if (_campaignId === undefined) {
        return {}
    }
    try {
        const result = await boxContract && boxContract.getSoldBox(_campaignId, _boxId);
        return result;
    } catch (error) {
        return {}
    }
}

export const _maxBuyBox = async (boxContract) => {

    try {
        return boxContract && boxContract.maxBuyBox()
    } catch (error) {
        return undefined
    }
}

export const _getCampaignInfo = async (boxContract, _campaignId) => {
    if (_campaignId === undefined) {
        return {}
    }
    try {
        const result = await boxContract && boxContract.getCampaignInfo(_campaignId)
        return result
    } catch (error) {
        return {}
    }
}

export const _getCampaigns = async (boxContract, _campaignId) => {
    if (_campaignId === undefined) {
        return {}
    }
    try {
        const result = await boxContract && boxContract.campaigns(...[_campaignId])
        return result
    } catch (error) {
        return {}
    }
}

export const _buyMysteryBox = async (boxContract, _campaignId, _mysteryBoxId, _quantity, _tokenPayment, _price, _sig) => {

    const args = [_campaignId, _mysteryBoxId, _quantity, _sig];
    

    let options = {
        value: convertToWei('0', 'ether')
    };
    
    if (_tokenPayment === 'BNB') {
        options['value'] = web3.utils.toWei((_price * _quantity).toString(), 'ether')
    }
    
    try {
        return boxContract.buyMysteryBox(...args, options);
    } catch (error) {
        return error
    } 
    
}

export const _mysteryBoxInfo = async (boxContract, _campaignId, id) => {

    try {
        return boxContract && boxContract.getSoldBox(_campaignId, id)
    } catch (error) {
        // console.log('error>>', error)
        return undefined
    }
}

export const _myBoxInfo = async (boxContract, address, boxid) => {  
    try {
        return boxContract && boxContract.balanceOf(address, boxid)
    } catch (error) {
        // console.log('error>>', error)
        return undefined
    }
}


export const _approveBEP20 = async (contract, address, amount) => {  
    const args = [address, web3.utils.toBN(2).pow(web3.utils.toBN(256)).sub(web3.utils.toBN(1)).toString()]; 
    return await contract.approve(...args);
}
export const _openBox = async (openBoxContract, boxSC, nftContract, _boxID, _tokenId, mintable, _uri, _attrs, sig) => {
    try {
        const args = [boxSC, nftContract, _boxID, _tokenId, mintable, _uri, _attrs, sig];
        const estimatedGas = await openBoxContract.estimateGas.OpenBox(...args)
        return openBoxContract.OpenBox(...args, {
            gasLimit: estimatedGas
        })
    } catch (error) {
        // console.log('error>>', error)
        return error
    }
}
export const getNfts = async (openBoxContract, address) => {

    try {
        return openBoxContract && openBoxContract.getNtfs(address)
    } catch (error) {
        // console.log('error>>', error)
        return undefined
    }
}
