import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import useWindowSize from '../../hooks/useWindowSize';
import { Layout, Row, Col } from 'antd';
import { Loader } from '../common/component';
import { Helmet } from "react-helmet"
import { ArrowRightOutlined } from '@ant-design/icons';

const ToolToGrow = () => {

    
    return(
        <>
            <div className="tool-to-grow">
                <div className='container'>
                    <div className='tool-to-grow-title' data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
                        <span>Why choose us?</span>
                    </div>
                    <p className='text-center text-blue-white mb-20'>Provide you with the best tools to grow</p>
                    <Row className='mt-20' gutter={30} data-aos="fade-up" data-aos-duration="1400" data-aos-offset="200">
                        <Col xs={24} sm={6} xl={6} md={6}>
                            <div className='grow-item'>
                                <div className='grow-img'>
                                    <img src='../images/trade.png' alt='' />
                                </div>
                                <div className='grow-title'>Launchpad</div>
                                <div className='grow-description'>
                                    A fun and innovative decentralized IDO/ICO/INO launchpad across all major blockchain networks.
                                </div>
                                <div className='grow-button'>
                                    <a href='/launchpad/opening'>Get Started</a>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={6} xl={6} md={6}>
                            <div className='grow-item'>
                                <div className='grow-img'>
                                    
                                    <img src='../images/earn.png' alt='' />
                                </div>
                                <div className='grow-title'>Earn</div>
                                <div className='grow-description'>
                                    Earn an attractive flow of passive income with UpSwap Staking and Farming pools.
                                </div>
                                <div className='grow-button'>
                                    <a href='/farms'>Earn Now</a>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={6} xl={6} md={6}>
                            <div className='grow-item'>
                                <div className='grow-img'>
                                    
                                    <img src='../images/bridge.png' alt='' />
                                </div>
                                <div className='grow-title'>NFT Marketplace</div>
                                <div className='grow-description'>
                                    Mint, collect and trade your favorite NFTs with fellow art-lovers.
                                </div>
                                <div className='grow-button'>
                                    <a href='/genesis-nft'>Trade NFTs</a>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={6} xl={6} md={6}>
                            <div className='grow-item'>
                                <div className='grow-img'>
                                    <img src='../images/launchpad.png' alt='' />
                                </div>
                                <div className='grow-title'>Airdrop </div>
                                <div className='grow-description'>
                                    Earn free token while having fun in UpSwap community.
                                </div>
                                <div className='grow-button'>
                                    <a href='/airdrop'>Join Airdrop</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            
        </>
    )
}

export default ToolToGrow;