/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import { Progress, Steps, Checkbox, Radio, Modal, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import web3 from "web3";
import { useActiveWeb3React } from "../../hooks";
// import Action from "./action";
import { useContract } from "../../hooks/useContract";
import { ADDRESS_HLUP_ADDRESS } from "../../constants";
import abiBSCS from "./utils/abiBSCS.json";
import { SC_STAKE_FIXED } from "../../constants";
import abiStake from "./utils/abiStake.json"
import { chainList } from "../../config/network/chainlist";
import { Store } from "react-notifications-component";
import addNotify from "../common/Notify/addNotify";
import { getChainInformation } from "../../utils/campaignUltils";


const steps = [
    {
        title: '1',
        content: 'First-content',
    },
    {
        title: '2',
        content: '2-content',
    },
    {
        title: '3',
        content: '3-content',
    },
    {
        title: '4',
        content: '4-content',
    },
    {
        title: '5',
        content: 'Last-content',
    },
];

const provider = "https://testnet-rpc.helachain.com";

const Stake = (props) => {
    const { account, chainId } = useActiveWeb3React();
    const bscsContract = useContract(ADDRESS_HLUP_ADDRESS, abiBSCS);
    const stakeContract = useContract(SC_STAKE_FIXED, abiStake)
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [bnbBalance, setBnbBalance] = useState(0);

    const [isSuccess, setIsSuccess] = useState(false)
    const [amountStake, setAmountStake] = useState()
    const [current, setCurrent] = useState(0);
    const [amountApproved, setAmountApproved] = useState(0);
    const [amountBscs, setAmountBscs] = useState(0);
    const [isCheck, setIsCheck] = useState(false);
    const [isCheckRule, setIsCheckRule] = useState(false);
    const [showNext, setShowNext] = useState(false);
    const [hashStake, setHashStake] = useState('');
    const [unstakeAmount, setUnstakeAmount] = useState(0);
    let chainInfo = chainList[chainId];

    const { Step } = Steps;
    useEffect(() => {
        getAmountApprove()
    }, [account, props.tabBox])

    const next = () => {
        if (current === 2) {
            if (amountApproved < amountStake) {
                handleApprove()
            } else {
                setCurrent(current + 1);
            }
        } else {
            if (current === 3) {
                handleStaking()
            } else {
                setCurrent(current + 1);
            }

        }

    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const renderImg1 = () => {
        return (
            <img className="img-step-stake" src="./images/stake-ac-1.png" alt="" />
        )
    }
    const renderImg2 = () => {
        if (current < 1) {
            return <img className="img-step-stake" src="./images/stake-ac.png" alt="" />
        } else {
            return <img className="img-step-stake" src="./images/stake-ac-1.png" alt="" />
        }
    }
    const renderImg3 = () => {
        if (current < 2) {
            return <img className="img-step-stake" src="./images/stake-ac.png" alt="" />
        } else {
            return <img className="img-step-stake" src="./images/stake-ac-1.png" alt="" />
        }
    }
    const renderImg4 = () => {
        if (current < 3) {
            return <img className="img-step-stake" src="./images/stake-ac.png" alt="" />
        } else {
            return <img className="img-step-stake" src="./images/stake-ac-1.png" alt="" />
        }
    }
    const renderImg5 = () => {
        if (current < 4) {
            return <img className="img-step-stake" src="./images/stake-ac.png" alt="" />
        } else {
            return <img className="img-step-stake" src="./images/stake-ac-1.png" alt="" />
        }
    }

    const renderText1 = () => {
        return <span className="text-ac">Checkpoints</span>
    }
    const renderText2 = () => {
        if (current < 1) {
            return "Amount to Stake"
        } else {
            return <span className="text-ac">Amount to Stake</span>
        }

    }

    const renderText3 = () => {
        if (current < 2) {
            return "Pre-authorization"
        } else {
            return <span className="text-ac">Pre-authorization</span>
        }

    }
    const renderText4 = () => {
        if (current < 3) {
            return "Confirm"
        } else {
            return <span className="text-ac">Confirm</span>
        }

    }
    const renderText5 = () => {
        if (current < 4) {
            return "Confirmation"
        } else {
            return <span className="text-ac">Confirmation</span>
        }

    }

    const onChange = (e) => {

        setIsCheck(e.target.checked)
    };
    const onChangeAll = () => {

        setIsCheck(!isCheck)
    };

    const onChangeRule = (e) => {
        setIsCheckRule(e.target.checked)
    }
    const onChangeRuleAll = () => {
        setIsCheckRule(!isCheckRule)
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const getBscs = async () => {
        if (bscsContract) {
            const amount = await bscsContract.balanceOf(account);
            setAmountBscs(Number(amount.toString() / 1e18).toFixed(4));
        }
    };
    
    useEffect(() => {
        if (account) {
            getBscs();
        }
    }, [account, props.tabBox]);

    useEffect(() => {
        if (account) {
            w3.eth.getBalance(account, (err, balance) => {
                if (balance) {
                    let _balance = web3.utils.fromWei(
                        web3.utils.toBN(balance),
                        "ether"
                    );
                    let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
                    setBnbBalance(bnbBl);
                }
            });
        }
    }, [account, chainId, props.tabBox]);

    const getAmountApprove = async () => {
        try {
            await bscsContract.allowance(account, SC_STAKE_FIXED).then((res) => {
                let amountApproved = web3.utils.fromWei(
                        web3.utils.toBN(res),
                        "ether"
                    );
                setAmountApproved(amountApproved)
            })
                .catch((err) => {
                    console.log('err', err)
                    setAmountApproved(0)
                })
        } catch (error) {

        }
    }

    const handleApprove = async () => {
        try {
            if (!bscsContract) {
                Store.addNotification(addNotify("Can't reading contract Staking", 'danger'));
                return;
            }
            setIsModalOpen(true);
            
            let amount = web3.utils.toWei(amountStake.toString(), "ether");
         
            await bscsContract.approve(SC_STAKE_FIXED, amount).then((res) => {
                res.wait()
                    .then((resWait) => {
                        if (resWait?.status === 1) {
                            let amountApproved = web3.utils.fromWei(
                                web3.utils.toBN(resWait?.logs[0]?.data),
                                "ether"
                            );
                            if (Number(amountApproved) < amountStake) {
                               
                                Store.addNotification(addNotify("Please approve more token for staking", 'danger'));
                                setIsModalOpen(false);
                            } else {
                                Store.addNotification(addNotify("Approved successfully", 'success'));
                                setIsModalOpen(false);
                                setCurrent(current + 1);
                            }
                        } else {
                            Store.addNotification(addNotify("Unknown error (status hash fail)", 'danger'));
                            setIsModalOpen(false);
                        }

                    })
                    .catch((errWait) => {
                        console.log('errWait', errWait)
                    })

            })
                .catch((err) => {
                    console.log('err', err)
                    setIsModalOpen(false);
                
                    Store.addNotification(addNotify(err?.message || "Unknown error", 'danger'));
                })
        } catch (error) {
            console.log('error', error)
            setIsModalOpen(false);
        }
    }

    const handleStaking = async () => {
        try {
            setIsModalOpen(true);
            let amount = web3.utils.toWei(amountStake.toString(), "ether");
            await stakeContract.stake(amount)
                .then((res) => {
                    res.wait()
                        .then((resWait) => {
                            if (resWait?.status === 1) {
                                console.log('resWait', resWait)
                                setHashStake(resWait.transactionHash)
                                
                                Store.addNotification(addNotify("Stake successfully", 'success'));
                                props.setIsCheckAction(!props.isCheckAction)
                                setIsSuccess(!isSuccess)
                                setIsModalOpen(false);
                                setCurrent(current + 1);
                                window.location.reload()
                            } else {
                                Store.addNotification(addNotify("Unknown error (status hash fail)", 'danger'));
                                setIsModalOpen(false);
                            }

                        })
                        .catch((err) => {
                            Store.addNotification(addNotify("Unknown error", 'danger'));
                            setIsModalOpen(false);
                        })
                })
                .catch((err) => {
                    console.log('err', err)
                
                    Store.addNotification(addNotify(err.reason || err?.message, 'danger'));
                    setIsModalOpen(false);
                })
        } catch (error) {
            console.log('err catch-->', error)
            Store.addNotification(addNotify(error, 'danger'));
            setIsModalOpen(false);

        }
    }

    const checkFlag = async () => {
        try {
            await stakeContract.userMap(account).then((res) => {
                if (res) {
                    setUnstakeAmount(res.inUnStakeAmount / 1e18)
                } else {
                    setUnstakeAmount(0)
                }
            })
                .catch((err) => {
                    console.log('err', err)
                })
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        if (stakeContract) {
            checkFlag()
        }

    }, [account, stakeContract, props.tabBox])

    const handleShowNext = () => {
        if (current === 0) {
            if (account && amountBscs > 0 && bnbBalance > 0 && isCheck && unstakeAmount <= 0) {
                setShowNext(true)
            } else {
                setShowNext(false)
            }
        }
        if (current === 1) {
            if (amountStake && Number(amountStake) > 0 && amountStake <= amountBscs && isCheckRule) {
                setShowNext(true)
            } else {
                setShowNext(false)
            }
        }
        if (current === 2) {

        }

    }

    useEffect(() => {
        handleShowNext()
    }, [account, amountBscs, bnbBalance, isCheck, current, amountStake, isCheckRule, unstakeAmount, props.tabBox])

    const handleChangeInputStake = (event) => {
        let x = event.target.value.replace(/[^0-9.]/g, "");
        setAmountStake(Number(x))
        console.log('x', x)

    }
    const handleAmountMax = () => {
        setAmountStake(amountBscs)
    }

    return (
        <div className="wrap-stake">
            <div className="stake">
                <div className="wrap-all">

                    <div className="stake-your-token">Stake your $HLUP</div>
                    <div className="content-stake">

                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Steps current={current}>
                            <Step
                                title={renderText1()}
                                icon={renderImg1()}
                            />
                            <Step
                                title={renderText2()}
                                icon={renderImg2()}
                            />
                            <Step
                                title={renderText3()}
                                icon={renderImg3()}
                            />
                            <Step
                                title={renderText4()}
                                icon={renderImg4()}
                            />
                            <Step
                                title={renderText5()}
                                icon={renderImg5()}
                            />
                        </Steps>


                        {/* <div className="steps-content">{steps[current].content}</div> */}
                        {current === 0 ? (
                            <div className="content-step1">
                                <div className="title">Checkpoints</div>
                                <div className="des">The following conditions must be met to proceed:</div>
                                <div className="item-step1">
                                    <div className="card-item">
                                        <div className={`${account ? 'check-card acive' : 'check-card'}`}>

                                            <img src="./images/check-ac.png" alt="" />
                                            <img src="./images/check-no-ac.png" alt="" />
                                        </div>
                                        <div className="title-card">Connected with MetaMask</div>
                                        <div className="content-card">If not connected, click the "Connect Wallet" button in the top right corner.</div>
                                    </div>
                                    <div className="card-item"
                                    >
                                        <div className={`${amountBscs > 0 ? 'check-card acive' : 'check-card'}`}>

                                            <img src="./images/check-ac.png" alt="" />
                                            <img src="./images/check-no-ac.png" alt="" />

                                        </div>
                                        <div className="title-card">$HLUP available to deposit</div>
                                        <div className="content-card">
                                            Current Balance: <span className="balance">{new Intl.NumberFormat("ja-JP").format(amountBscs)}</span>
                                        </div>
                                    </div>
                                    <div className="card-item">
                                        <div className={`${bnbBalance > 0 ? 'check-card acive' : 'check-card'}`}>
                                            <img src="./images/check-ac.png" alt="" />
                                            <img src="./images/check-no-ac.png" alt="" />

                                        </div>
                                        <div className="title-card">HULSD available in wallet</div>
                                        <div className="content-card">HLUSD is required to pay transaction fees on the {chainInfo?.NAME} network.<br />HULSD Balance: <span className="balance">{Number(bnbBalance.toFixed(3)).toLocaleString()}</span></div>
                                    </div>
                                    <div className="card-item">
                                        <div className={`${unstakeAmount <= 0 ? 'check-card acive' : 'check-card'}`}>
                                            <img src="./images/check-ac.png" alt="" />
                                            <img src="./images/check-no-ac.png" alt="" />

                                        </div>
                                        <div className="title-card">Eligible to stake</div>
                                        <div className="content-card">You cannot stake if you have an active $HLUP unstake/withdrawal request.</div>
                                    </div>
                                </div>
                                <div className="check-real" onClick={onChangeAll}>
                                    <Checkbox checked={isCheck} onChange={onChange} /> <span >I have read the <a href="#" className="terms">Terms and Conditions</a></span>
                                </div>
                            </div>
                        ) : (
                            <>
                                {current === 1 ? (
                                    <div className="content-step2">
                                        <div className="title">Amount to Stake</div>
                                        <div className="des">Stake $HLUP</div>
                                        <div className="content-input">
                                            <div className="amount-stake-title">
                                                <div className="title-left">Staking amount</div>
                                                <div className="title-right"><span>{new Intl.NumberFormat("ja-JP").format(amountBscs)}</span> $HLUP available</div>
                                            </div>
                                            <div className="input-amount">
                                                <input
                                                    onChange={handleChangeInputStake}
                                                    value={amountStake}
                                                    className="amount-stake"

                                                // type="text"
                                                />
                                                <div className="posi">
                                                    <span className="palce-token">$HLUP</span>
                                                    <button className="btn-max-stake" onClick={handleAmountMax}>Max</button>
                                                </div>

                                            </div>
                                            {/* <div className="des-amount">
                                                <div className="staking-rule">Staking rules:</div>
                                                <ul>
                                                    <li>Stake token in smart contract during staking period for passive income.</li>
                                                    <li>Token distribution will be released after the smart contract ends.</li>
                                                    <li>During locking period, <a className="un-fee" href="#">unstaking fee</a> will be applied and you will withdraw without earnings.</li>
                                                </ul>
                                            </div> */}
                                            <div className="check-rule" onClick={onChangeRuleAll}>
                                                <Checkbox checked={isCheckRule} onChange={onChangeRule} />
                                                <span className="check-rule text">I understand the staking rules.</span>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {current === 2 ? (
                                            <div className="content-step3">
                                                <div className="title">Pre-authorization</div>
                                                <div className="des">Required transaction 1 of 2</div>
                                                <div className="autho-content">
                                                    <div className="autho-title">In this step, you grant access to the staking smart contract to accept your $HLUP.</div>
                                                    <div className="autho-progress">
                                                        <Progress
                                                           
                                                            percent={50}
                                                            status="active"
                                                        />
                                                    </div>
                                                    <div className="autho-please">Please wait for the transaction to confirm before proceeding.</div>
                                                    <a className="autho-link" rel="noreferrer" target="_blank" href={`https://testnet-blockexplorer.helachain.com/address/${account}`}>{account ? account : ''}</a>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {current === 3 ? (
                                                    <div className="content-step4">
                                                        <div className="title">Confirm</div>
                                                        <div className="des">Required transaction 2 of 2</div>
                                                        <div className="confirm-content">
                                                            <div className="cf-content1">In this step, you deposit the tokens into the staking contract.</div>
                                                            <div className="cf-content1">After this step, your tokens will be successfully staked.</div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {current === 4 ? (
                                                            <div className="content-step5">
                                                                <div className="title">Confirmation</div>
                                                                <div className="des">Congratulations! Your tokens are now staked.</div>
                                                                <div className="success-content">
                                                                    <div className="img-success">
                                                                        <img src="./images/sus.png" alt="" />
                                                                    </div>
                                                                    <div className="mid-content">Success</div>
                                                                    <div className="mid-content1">Congratulations! Your tokens are now staked.</div>
                                                                    <div className="mid-content2">If desired, you may check {chainInfo?.NAME} to confirm the transaction.</div>
                                                                    <a className="mid-content3" target="_blank" rel="noreferrer" href={`https://testnet-blockexplorer.helachain.com/tx/${hashStake}`}>{hashStake}</a>
                                                                </div>
                                                            </div>
                                                        ) : ('')}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>

                </div>
                {/* <Action isSuccess={isSuccess} tabBox={props.tabBox} /> */}

            </div>
            <div className="steps-action">

                {current > 0 && current !== steps.length - 1 && (
                    <button className="btn-basic-default w-fitcontent" onClick={() => prev()}> Previous</button>
                )}
                {current < steps.length - 1 && (
                    <>
                        {showNext ? (
                            <button className="btn-basic w-200" onClick={() => next()}> Next</button>
                        ) : (
                            <button className="btn-basic w-200" disabled> Next</button>
                        )}
                    </>
                )}
                {current === steps.length - 1 && (
                    <button className="btn-basic w-200" onClick={() => setCurrent(0)}> Done</button>
                )}

            </div>

            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                title={current === 2 ? "Approving" : "Staking"}
                visible={isModalOpen}
                // onOk={handleOk}
                // onCancel={handleCancel}
                footer={false}
                wrapClassName="modal-confirm"
            >
                <p className="modal-text1">Your request is being processed. Please wait...</p>
                <p className="spin-stake">
                    <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 55 }} spin />} />
                </p>

            </Modal>
            {/* <Button type="primary" onClick={showModal}>
                Open Modal
            </Button> */}
        </div>


    )
}

export default Stake;
