import { sqrt } from "mathjs";
import bigInt from "big-integer";

function calcPriceSqrtX96(current_price, token1_decimals, token0_decimals) {
  let sqrt_price_x96 =
    sqrt(current_price * 10 ** (token1_decimals - token0_decimals)) * 2 ** 96;
  const sqrtPrice = bigInt(sqrt_price_x96);
  return sqrtPrice.value.toString();
}

export default calcPriceSqrtX96;
