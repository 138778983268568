import { instance } from '../../apiBase/instance';

export const NftApi = {

	getNftByOwner(params) {
		return instance.get(`my-nfts`, { params }, '');
	},
	updateListingNft(id, data) {
		return instance.put(`merlion-nft/${id}/list`,  data , {});
	},
	unListingNft(id, data) {
		return instance.put(`merlion-nft/${id}/un-list`,  data , {});
	},
	buyNft(data) {
		return instance.post(`buy-nft`,  data , {});
	},
	claimNft(id, data) {
		return instance.put(`merlion-nft/${id}/claim`,  data , {});
	},

	getNftLogs(merlionId, params) {
		return instance.get(`order-logs/${merlionId}/nft`, { params }, '');
	},
	marketSummary() {
		return instance.get(`marketplaces/summary`, { }, '');
	},
	getProfileByAddress(address) {
		return instance.get(`minter/${address.toLowerCase()}/profile`, { }, '');
	},
	postProfile(values) {
		let data = {
			"avatarLink": values?.avatarLink,
			"bio": values?.bio,
			"email": values?.email,
			"ownerAddress": values?.ownerAddress,
			"username": values?.username,
		}
		return instance.post(`/minter/profile`, data, '');
	},
	editProfile(id, values) {
		let data = {
			"avatarLink": values?.avatarLink,
			"id": values?.id,
			"bio": values?.bio,
			"email": values?.email,
			"ownerAddress": values?.ownerAddress,
			"username": values?.username,
		}
		return instance.put(`/minter/${id}/update-profile`, data, '');
	},
	uploadImage(data, token) {
		
		return instance.post(`/upload-to-s3`, data, {
			headers: {
				Authorization: `Bearer ${token}`
			}
		});
	},
	getOfferLogs(merlionId) {
		let data = {
			"marketEnum": "OFFER",
			"nftId": Number(merlionId)
		}
		return instance.post(`/order-logs/filter`, data, '');
	},
	getOfferLogsByAddress(account) {
		let data = {
			"marketEnum": "OFFER",
			"address": account.toLowerCase()
		}
		return instance.post(`/nft-orders/filter`, data, '');
	},

};
