import http from "./http-common";

const getAirdropByStatus = (payload) => {
  return http.get("/airdrop/by-status", { params: payload });
}

const getAirdropByHandle = (handle) => {
  return http.get(`/airdrop/${handle}`);
}

const getWhitelistAirdropByAddress = (airdropId, account) => {
  return http.get(`airdrop/${airdropId}/address/${account}/whitelist`);
}

const getBanner = (payload) => {
  return http.post("/banners/filter", payload);
}

const AirdropService = {
  getBanner,
  getAirdropByStatus,
  getAirdropByHandle,
  getWhitelistAirdropByAddress
};
export default AirdropService;