
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Button, Form, Input, Select } from 'antd';

const { Option } = Select;
const { Content } = Layout;
const { TextArea } = Input;

const AdditonalInfo = (props) => {
    const { form } = props;
    let storageAdditionalInfo = window.localStorage.getItem('additionalInfo');
    let additionalInfo;
    if (storageAdditionalInfo) {
        additionalInfo = JSON.parse(storageAdditionalInfo);
    }

    useEffect(() => {
        form.setFieldsValue({
            logo_url: additionalInfo ? additionalInfo.logo_url : '',
            background_link: additionalInfo ? additionalInfo.background_link : '',
            facebook: additionalInfo ? additionalInfo.facebook : '',
            github: additionalInfo ? additionalInfo.github : '',
            instagram: additionalInfo ? additionalInfo.instagram : '',
            reddit: additionalInfo ? additionalInfo.reddit : '',
            website: additionalInfo ? additionalInfo.website : '',
            twitter: additionalInfo ? additionalInfo.twitter : '',
            telegram: additionalInfo ? additionalInfo.telegram : '',
            discord: additionalInfo ? additionalInfo.discord : '',
            description: additionalInfo ? additionalInfo.description : '',
        })
    }, [additionalInfo]);

    return (
        <>
            <div className="title-steps">Add Additional Info</div>
            <div className="launchpad-info-form">

                {/* <Form form={form} layout="vertical" autoComplete="off" onFinish={onConfirmLaunchpadInfo} initialValues={{ currency_pay: 'bnb' }}> */}
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="step_additional_info" label="Presale rate" initialValue={3} hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item name="logo_url" label="Logo URL"
                            help="URL must end with a supported image extension png, jpg, jpeg or gif"
                        >
                            <Input className="amount-buy" placeholder="http://img.io/image.png" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="background_link" label={
                            <>
                                Background URL (Click here upload your image and copy link:<a style={{marginLeft: '5px'}} target="_blank" href="/upload-image"> Upload Image</a>)
                            </>
                        }
                            help={
                                <>
                                    <p>URL must end with a supported image extension png, jpg, jpeg, webp or gif</p>
                                    <p>Size: 380x210</p>
                                </>
                            }
                            rules={[
                                { required: true, message: 'Please input background link!' }
                            ]}
                        >
                            <Input className="amount-buy" placeholder="http://img.io/image.png" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="facebook" label="Facebook">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="website" label="Website">
                            <Input className="amount-buy" placeholder="http://site.io" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="github" label="Github">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="twitter" label="X">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="instagram" label="Instagram">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="telegram" label="Telegram">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="reddit" label="Reddit">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="discord" label="Discord">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name="description" label="Description">
                    <TextArea rows={4} className="amount-buy" placeholder="Enter description" />
                </Form.Item>

                {/* </Form> */}
            </div>
        </>
    )
}

export default AdditonalInfo
