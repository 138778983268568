import http from "./http-common";

const getStakingUser =  (address) => { 
    return http.get(`/staking-balances/tier/${address}`);
};



const UserService = {
    getStakingUser
}
 
export default UserService;