export const chainList = {
	97: {
		'SYMBOL': 'BSC',
		'NAME': 'BNB Smart Chain Testnet',
		'CHAIN_ID': 97
	},
	56: {
		'SYMBOL': 'BSC',
		'NAME': 'BNB Smart Chain',
		'CHAIN_ID': 56
	},
	137: {
		'SYMBOL': 'POLYGON',
		'NAME': 'Polygon',
		'CHAIN_ID': 137
	},
	43114: {
		'SYMBOL': 'AVAX',
		'NAME': 'Avalanche C-Chain',
		'CHAIN_ID': 43114
	},
	66: {
		'SYMBOL': 'OKT',
		'NAME': 'OKXChain',
		'CHAIN_ID': 66
	},
	5: {
		'SYMBOL': 'ETH',
		'NAME': '',
		'CHAIN_ID': 5
	},
	1: {
		'SYMBOL': 'ETH',
		'NAME': 'Ethereum',
		'CHAIN_ID': 1
	},
	666888: {
		'SYMBOL': 'HelaTestnet',
		'NAME': 'HelaTestnet',
		'CHAIN_ID': 666888
	},
	8668: {
		'SYMBOL': 'HELA',
		'NAME': 'Hela',
		'CHAIN_ID': 8668
	}
};

export const getChainListInfo = (chainId, key) => {
	const chainInfo = chainList[chainId];
	if (chainInfo) {
		return chainInfo[key];
	} else {
		return null;
	}
};
