import React from "react";
import FooterHome from "../common/footer/footerHome";

import {
  Container,
  Content,
  MainTitle,
  Wrapper,
  Desc,
  Title,
} from "../LegalStyles";

const Privacy = () => {
    return(
        <div className="p-security">
            <div className="container">
                <Content>
                    <MainTitle>Privacy Policy</MainTitle>
                    <Desc>Effective Date: 1/1/2024</Desc>
                    <Desc>
                        This <span className="bold">Privacy Policy ("Privacy Policy")</span>{" "}
                        together with our{" "}
                        <span className="bold">
                        Terms of Services ("Terms of Services")
                        </span>{" "}
                        sets out the basis on which any Personal Data we collect from the
                        User. The “User" is an individual providing personal data to us
                        through our Website, Substack pages, Telegram Channels, Discord or
                        other social media accounts, or other means such as engaging and
                        completing additional UpSwap social media engagement tasks, for
                        any UpSwap event or interact with us on our social media
                        accounts. Hereinafter, We may refer to User as "You" in this{" "}
                        <span className="bold">Privacy Policy</span>.
                    </Desc>
                    <Desc>
                        By "Personal Data", we mean any information that enables you to be
                        directly or indirectly identified, for example, your name, profile
                        picture, username, email address, contact details, IP address,
                        device ID or other online identifiers.
                    </Desc>
                    <Desc>
                        Please read the following carefully to understand what data we
                        collect, how that data is used and the ways it can be shared by us.
                        By accessing, connecting, clicking on, using, or engaging in Our
                        Services, you indicate and confirm that you have consented and
                        agreed to the terms of this{" "}
                        <span className="bold">Privacy Policy</span>. If you do not agree,
                        either partially or wholly, to this{" "}
                        <span className="bold">Privacy Policy</span>, you should not access
                        or use the Website or use the services, functions, or features
                        offered from time to time, you must immediately discontinue
                        accessing, connecting, using, or engaging in Our Services and
                        Website. We will not use your Personal Information for any other
                        purpose that has not been covered in this{" "}
                        <span className="bold">Privacy Policy</span> or the
                        <span className="bold">Terms of Services</span> without providing
                        you prior notification or obtaining your consent. This{" "}
                        <span className="bold">Privacy Policy</span> does not apply to
                        services offered by other companies or service providers or other
                        websites linked from our Website.
                    </Desc>
                    <Desc>
                        You agree and acknowledge that we cannot guarantee that any losses,
                        misuses, unauthorized accession, or alteration of your Personal Data
                        will not occur. You hereby agree that You play a vital role in
                        protecting your Personal Data, including your credentials.
                    </Desc>
                    <Desc>
                        <span className="bold">
                        {" "}
                        IF YOU DO NOT AGREE WITH THIS POLICY PLEASE LEAVE THE WEB OR CEASE
                        USING OUR SERVICES IMMEDIATELY.
                        </span>
                    </Desc>
                    <Title>What Personal Data Do We Collect?</Title>
                    <Desc>
                        <span className="bold">Visiting our Website</span>
                        <br></br>
                        With regard to each of your visits to our website and access to the
                        services we may automatically collect the following information;
                        <ul>
                        <li>
                            Information about visits to our Website, which websites You
                            visited before accessing our services or Website, browser type,
                            information about your browser, date and time You visit the
                            Website, Volume of data transmitted and network operator. This
                            information will either be directly obtained by UpSwap or
                            through third-party service providers.
                        </li>
                        <li>Transaction data including purchases of crypto assets;</li>
                        <li>
                            The smart contract stored in your non-custodial wallet address;
                        </li>
                        <li>
                            Technical data including IP address, time zone setting and
                            locations, operating system, and other technologies on your
                            device used to access the platform;
                        </li>
                        <li>
                            Services or pools you viewed or searched for; page response
                            times, download errors, length of visits to certain pages, page
                            interaction information and methods used to browse away from the
                            page;
                        </li>
                        <li>
                            Our Websites utilize browser cookies (small text files stored on
                            a user’s web browser when you visit a website) and similar
                            tracking technologies (collectively, “Cookies”) to collect and
                            store certain information when you use, access, or interact with
                            our services.
                        </li>
                        </ul>
                    </Desc>
                    <Desc>
                        <span className="bold">Special categories of data</span>
                        <br></br>
                        We do not collect any special categories of Personal Data about you
                        (this includes details about your race or ethnicity, religious or
                        philosophical beliefs, sexual orientation, political opinions,
                        information about your health and genetic and biometric data).
                        However, you may be required to send us information relevant to
                        establishing your identity such as photographs and copies of your
                        government-issued identification documents.
                    </Desc>
                    <Desc>
                        <span className="bold">
                        Information we may collect from third-party sources
                        </span>
                        <br></br>
                        We may receive information about you from other sources, including:
                        (i) third parties that help us update, expand, and analyze our
                        records; prevent or detect fraud; process payments; or analyze your
                        use of our Services, and (ii) third parties with whom you choose to
                        link with the Services through functionality we make available to
                        you. Our Services may also include integrated content or links to
                        content provided by third parties (such as live chat, social media
                        content, plug-ins and applications). Additional third parties may
                        include our affiliated entities.
                    </Desc>
                    <Desc>
                        <span className="bold">Anonymized and Aggregated Data</span>
                        <br></br>
                        We may also aggregate personal and other data captured by us so that
                        the data is no longer capable of identifying an individual.
                        Aggregated data may cover patterns of usage or information and data
                        that you provide to us, and we reserve the right to use this
                        aggregated information for the purposes of improving and enhancing
                        our Services, generating insights, for use in marketing to other
                        users and current and potential partners and otherwise for the
                        purposes of our business. Provided that such aggregated data does
                        not directly or indirectly identify you as an individual, this data
                        is not considered to be Personal Data for the purpose of this
                        <span className="bold"> Privacy Policy</span>.
                    </Desc>
                    <Desc>
                        We use anonymized or aggregated users' information for the purposes
                        stipulated in this <span className="bold">Privacy Policy</span> at
                        any time, including gaining better understanding of Users' needs and
                        behaviors, conducting business intelligence and marketing
                        initiatives, and detecting security threats. In this regard, We
                        reserve the right to implement innovative technologies as they
                        become available to be used for strategic planning and operational
                        processes.
                    </Desc>
                    <Title>How We Use Your Personal Data</Title>
                    <Desc>
                        UpSwap will collect information about you for the purposes set
                        out below if you wish to transact on and use the Website or use the
                        Services,
                        <ul>
                        <li>
                            Establish and maintain a responsible commercial relationship
                            with you;
                        </li>
                        <li>To protect you,</li>
                        <li>
                            Understand your needs and your eligibility for products and
                            services;
                        </li>
                        <li>
                            Helps us provide you with a secure, smooth, efficient and
                            customized experience
                        </li>
                        <li>Inform you about Website features;</li>
                        <li>
                            Provide information to you about developments and new products,
                            including changes to the Website
                        </li>
                        <li>
                            To enforce Our Terms and Services, and other relevant
                            agreements;
                        </li>
                        <li>
                            Develop, enhance, and market products and services, and provide
                            products and services to you;
                        </li>
                        <li>Process billing and collection of any fees;</li>
                        <li>Conduct surveys and get feedback from you;</li>
                        <li>Deliver products and services to you;</li>
                        <li>
                            Provide you with news and other matters of general interest to
                            you as a customer;
                        </li>
                        <li>
                            To perform our obligations that arise out of the arrangement we
                            are about to enter or have entered with you;
                        </li>
                        <li>
                            To enforce the terms of the arrangement we have with you or any
                            third party;
                        </li>
                        <li>
                            As we believe reasonably necessary, advisable or appropriate to
                            comply with our legal or regulatory obligations and to respond
                            to legal, regulatory, arbitration or government process or
                            requests for information issued by government authorities or
                            other third parties or to protect your, our, or others’ rights.
                        </li>
                        </ul>
                        UpSwap and members of the UpSwap's group (including our
                        affiliates and subsidiaries) may send marketing communications. You
                        can opt-out of receiving marketing emails from us should you so
                        desire. Please note that even if you unsubscribe to marketing
                        communications, we still may need to send you communications
                        relating to your use of the Website, such as service announcements
                        and if we may need to send certain information to you under a legal
                        requirement.
                    </Desc>
                    <Desc>
                        UpSwap uses IP addresses to analyze trends, administer the
                        Website, and gather information for aggregate use. For systems
                        administration and detecting usage patterns and troubleshooting
                        purposes, the UpSwap's web servers also automatically log
                        standard access information including browser type and URL
                        requested. This information is not shared with third parties and is
                        used only within the Operator on a need-to-know basis. UpSwap
                        reserves the right to block access for any customer accessing the
                        Website via a proxy service intending to conceal originating
                        identity. This includes access via the Tor anonymity network.
                    </Desc>
                    <Title>How We Protect Your Personal Data</Title>
                    <Desc>
                        UpSwap is committed to protecting your privacy. Internally, only
                        people within the business need to know Personal Data, or whose
                        duties reasonably require access to it, are granted access to
                        customers’ Personal Data. Such individuals will only process your
                        Personal Data on our instructions and are subject to a duty of
                        confidentiality.
                    </Desc>
                    <Desc>
                        We will take reasonable care to protect the Website’s security as
                        well as your Personal Information from loss, misuse, disclosure,
                        alteration, and destruction. We take reasonable steps to employ and
                        maintain procedural safeguards to ensure the confidentiality, and
                        integrity of your Personal Data. The safeguards include the use of
                        firewalls, and data encryption, enforcing physical access controls
                        to Our premises and files, and limiting access to Personal Data only
                        to those employees, agents or third parties who need access to that
                        information to process it for Us.
                    </Desc>
                    <Desc>
                        The Website’s systems and data are reviewed periodically to ensure
                        that you are getting a quality service and that leading security
                        features are in place. We have put in place procedures to deal with
                        any actual or suspected data breach and will notify you and any
                        applicable regulator of a breach where we are legally required to do
                        so.
                    </Desc>
                    <Desc>
                        We take reasonable care to maintain physical, technical, and employ
                        procedural safeguards to ensure the confidentiality, and integrity
                        of your Personal Data. We also implement certain reasonable security
                        measures to protect your Personal Data from unauthorized access, and
                        such security measures are in compliance with the security practices
                        and procedures as prescribed under the applicable laws.
                    </Desc>
                    <Desc>
                        However, You hereby agree that no information system can be fully
                        secure. We cannot guarantee the absolute security of your
                        information by implementing the above-mentioned security measures.
                        It is not absolute protection to your Personal Data and by accessing
                        the Services. Moreover, we are not responsible for the security of
                        information you transmit to us over networks that we do not control,
                        including the internet and wireless networks.
                    </Desc>
                    <Desc>
                        You agree that We will not hold any responsibility for the acts
                        committed by those who gain unauthorized access or abuse your
                        information and services. In addition, We will not hold any
                        responsibility for any act committed by the third-party service
                        providers who are contractually engaged with Us to maintain an
                        appropriate security measure for protecting your Personal Data.
                        Thus, you are recommended to understand this responsibility and to
                        independently take safety precautions to protect your Personal Data,
                        particularly your credential information such as your login details,
                        passwords and wallet address.
                    </Desc>
                    <Desc>
                        You hereby agree that We will not be liable for any information
                        leakage and other damage or loss including, but not limited to,
                        hacker attacks, power interruptions, or unavoidable technical
                        failures in so far as they do not arise from Our intentional or
                        gross negligence including, but not limited to, hacker attacks,
                        power interruptions, or unavoidable technical failures.
                    </Desc>
                    <Desc>
                        We will not rent, sell, or disclose your Personal Data to any third
                        parties, except to those who require access to the information to
                        perform their tasks and duties under the binding agreements executed
                        with Us and to share with third parties who have a legitimate
                        purpose for accessing it. We may share, transfer, disclose, or allow
                        access to your Personal Data to the following third parties for the
                        purposes described below:
                    </Desc>
                    <Desc>
                        You hereby agree that we have the right to share your Personal Data
                        with:
                        <ul>
                        <li>
                            Any member of our group, which means our subsidiaries, our
                            ultimate holding company and its subsidiaries including their
                            respective contractors, affiliates, employees or
                            representatives.
                        </li>
                        <li>
                            Our service providers, to the extent necessary supply the
                            Services to you.
                        </li>
                        <li>
                            Selected third parties, including analytics and search engine
                            providers that assist us in the improvement and optimization of
                            the Services.
                        </li>
                        <li>
                            We may disclose or transfer a part of your Personal Data We
                            collect if We are involved in a business transition or any
                            merger and/or acquisition projects. In such merger or
                            acquisition event, your Personal Data might be among the assets
                            transferred, but it will be protected and secure in accordance
                            with this Privacy Policy.
                        </li>
                        <li>
                            Authorities and law enforcement agencies worldwide either when
                            ordered to do so or on a voluntary basis if this appears
                            reasonable and necessary to us.
                        </li>
                        </ul>
                    </Desc>
                    <Desc>
                        We may also disclose your Personal Data to third parties:
                        <ul>
                        <li>
                            If the Company or substantially all of its assets are acquired
                            by a third party, in which case Personal Data held by it about
                            its customers will be one of the transferred assets.
                        </li>
                        <li>
                            If we are under a duty to disclose or share your Personal Data
                            in order to comply with any legal obligation, or in order to
                            enforce or apply our{" "}
                            <span className="bold">Terms of Service</span> and other
                            agreements; or to protect the rights, property, or safety of us,
                            our clients, or others.
                        </li>
                        </ul>
                    </Desc>
                    <Title>How Long We Store Your Personal Data</Title>
                    <Desc>
                        You hereby agree that your Personal Data will continue to be stored
                        and retained by Us or by a third-party processor engaged by Us while
                        You have been using or accessing Website and the Services, and after
                        your use of the Services for a reasonable period of time stipulated
                        under the applicable law.
                    </Desc>
                    <Desc>
                        In addition, We may be required by applicable laws and regulatory
                        requirements to retain certain information, including your Personal
                        Data, information related to staking instructions, account
                        agreements, and other agreements between Us and third parties,
                        account statements, and other records after You stop accessing or
                        participating in the Website.
                    </Desc>
                    <Title>Third-Party Collection of Personal Data</Title>
                    <Desc>
                        This <span className="bold">Privacy Policy</span> only addresses the
                        use and disclosure of the Personal Data we collect from You. To the
                        extent that You disclose your information to other parties through
                        the use of Our Services such as by clicking on a link to any other
                        websites of our partner networks, different rules regarding Privacy
                        Policies may apply to their use or disclosure of the Personal Data
                        You disclose to them.
                    </Desc>
                    <Desc>
                        You hereby acknowledge that we will not be responsible for the
                        products, services, or descriptions of products or services that You
                        receive from the third-party websites or to the content or privacy
                        practices of the third-party websites. You hereby agree that we are
                        not responsible for or able to control, the privacy, security, or
                        other practices of any third parties. This{" "}
                        <span className="bold">Privacy Policy</span> will not be applied to
                        any such third-party products and services that You access through
                        our Web Site and social media channels. You hereby agree that
                        websites, applications and services of third parties (including
                        affiliate entities, associated companies, sponsors, advertisers or
                        other persons) will be governed by the privacy settings, policies,
                        and/or procedures of the third party, which may differ from this{" "}
                        <span className="bold">Privacy Policy</span>. It is your
                        responsibility to review the privacy statements, policies, terms,
                        and conditions of any person or company to whom you choose to link
                        or with whom you choose to contract. We are not responsible for the
                        privacy statements, policies, terms, conditions, or other content of
                        any website not owned or managed by the UpSwap.
                    </Desc>
                    <Title>GDPR Data Protection Rights</Title>
                    <Desc>
                        If you are located in the EEA zone, UK, or Switzerland as a data
                        subject (a person whose personal information is collected, stored
                        and processed) you have several rights under data privacy laws:
                        <ul>
                        <li>
                            <span className="bold">Right of access.</span> You have the
                            right to obtain confirmation if your personal information is
                            being processed by us. If that is the case, you can access your
                            personal information and the following information: (i) the
                            purposes of the processing; (ii) the categories of personal
                            information; (iii) to whom the personal information has been or
                            will be disclosed; (iv) the envisaged period for which the
                            personal information will be stored, or the criteria used to
                            determine that period.
                        </li>
                        </ul>
                        If you would like to have a copy of your personal information from
                        us, we will provide it if (i) you prove your identity, (ii) it will
                        not adversely affect the rights and freedoms of others. The first
                        copy will be provided for free, for any further copies, we may
                        charge a reasonable fee based on administrative costs.
                        <ul>
                        <li>
                            <span className="bold">Right to rectification.</span> You have
                            the right to demand that we correct without undue delay your
                            personal information which we have in our systems if it is
                            inaccurate or incomplete.
                        </li>
                        <li>
                            <span className="bold">
                            Right to erasure (“right to be forgotten”).
                            </span>{" "}
                            You have the right to demand that we erase your personal
                            information, and we shall erase it without undue delay where one
                            of the following grounds applies: (i) this personal information
                            is no longer necessary in relation to the purposes for which it
                            was processed; (ii) you withdraw consent on which the processing
                            is based, and where there is no other legal ground for the
                            processing; (iii) you object to the processing and there are no
                            overriding legitimate grounds; (iv) your personal information
                            has been unlawfully processed; (v) your personal information has
                            to be erased for compliance with a legal obligation.
                        </li>
                        <li>
                            <span className="bold">Right to restrict processing.</span> You
                            have the right to restrict us in the ability to process your
                            information where one of the following applies: (i) you contest
                            the accuracy of your personal information and we are verifying
                            it; (ii) the processing is unlawful and you want to restrict it
                            instead of erasure; (iii) we no longer need your personal
                            information, but you need it for the establishment, exercise or
                            defense of legal claims; (iv) you have objected to the
                            processing and we are verifying whether legitimate grounds
                            override your request.
                        </li>
                        <li>
                            <span className="bold">Right to data portability.</span> You
                            have the right to receive your personal information which you
                            provided to us in a structured, commonly used and
                            machine-readable format and have the right to transmit those
                            data to another company, where: (i) the processing is based on
                            your consent or on a contract; and (ii) the processing is
                            carried out by automated means.
                        </li>
                        </ul>
                        Where technically feasible, you can demand that we transmit those
                        data directly to another company.
                        <ul>
                        <li>
                            <span className="bold">Right to object.</span> You have the
                            right to object to the processing of your personal information
                            based on our legitimate interests. We shall no longer process
                            your personal information unless we demonstrate compelling
                            legitimate grounds for the processing or for the establishment,
                            exercise or defense of legal claims.
                        </li>
                        </ul>
                        Where personal information is processed for direct marketing
                        purposes, you have the right to object at any time to the processing
                        of your personal information for such marketing.
                        <ul>
                        <li>
                            <span className="bold">Right to withdraw consent.</span> You
                            have the right to withdraw your consent for the processing of
                            your personal information at any time. The withdrawal of consent
                            shall not affect the lawfulness of processing based on consent
                            before its withdrawal.
                        </li>
                        </ul>
                    </Desc>
                    <Title>Limitations</Title>
                    <Desc>
                        Our Services are not designed for individuals who are under the age
                        of 18 or under the legal age to provide consent under the applicable
                        laws of the country or jurisdiction where You are using Our Services
                        or Platform. If You're not over 18 or of the legal age of your
                        country, You will not provide any Personal Information to Us, or to
                        engage in Our services. We reserve the right to access and verify
                        any Personal Information collected from You. If we are aware that
                        You are under 18 or under the legal age who already shared your
                        Personal Information with us, we will then discard such information
                        and block your access to our Website immediately.
                    </Desc>
                    <Title>Changes and Updates</Title>
                    <Desc>
                        We may modify this <span className="bold">Privacy Policy</span> by
                        providing notice of such changes, such as by sending you an email,
                        providing notice through the UpSwap's official media channels or
                        our newsfeed, or updating the “Effective Date” date at the head of
                        this Privacy Policy. By continuing to access UpSwap or use our
                        Services, you confirm your agreement to the modified{" "}
                        <span className="bold">Privacy Policy</span>. If you do not agree to
                        any modification to this{" "}
                        <span className="bold">Privacy Policy</span>, you must stop
                        accessing UpSwap and using our Services. We encourage you to
                        frequently review the <span className="bold">Privacy Policy</span>{" "}
                        to ensure you understand the terms and conditions that apply to your
                        access to, and use of, UpSwap and our Services.
                    </Desc>
                    <Title>Contact</Title>
                    <Desc>
                        Questions, comments and requests regarding this{" "}
                        <span className="bold">Privacy Policy</span> should be addressed to
                        us through the email: contact@upswap.finance.
                    </Desc>
                    </Content>
            </div>
            <FooterHome />
        </div>
    )
}

export default Privacy;