import React from "react";
import { useConnectWallet } from "../../../hooks";
import ListWallet from "./listWallet";
import IconClose from '../asset/images/icon-close.svg';

const ConnectWallet = ({onCancel}) => {
    const { walletLogin } = useConnectWallet();

    // ---- HANDLE CONNECT ----
	const handleConnect = async (connectorId) => {
		try {
			await walletLogin(connectorId);
            setTimeout(() => {
                onCancel(false);
            }, 100);
		} catch (e) {
			console.error('Login failed');
		}        
	}; 
   

    const ListWalletDark = ListWallet.filter(item => item.status !== "light");

    const elmWallet = ListWalletDark.map((item,key) => (
        <li key={key} onClick={() => handleConnect(item.connectorId)}>
            <span><img src={item.icon} alt={item.title} /></span>
            <span>{item.title}</span>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M12.0249 4.94168L17.0832 10L12.0249 15.0583" stroke="#0EAD98" strokWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M2.9165 10H16.9415" stroke="#0EAD98" strokWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </span>
        </li>
    ));

    return (
        <div className="c-popup">
            <div className="c-popup__box" style={{maxWidth: '650px'}}>
                <div className="c-popup__head">
                    Connect to a wallet
                    <button className="c-popup__close" onClick={() => onCancel(false)}><img src={IconClose} alt="Close" /></button>
                </div>
                <div className="c-popup__body">
                    <div className="connect-description">Securely connect your wallet to start your Web3 journey</div>
                    <ul className="c-connectWallet">
                        {elmWallet}
                    </ul>
                </div>
            </div>            
        </div>        
    );
};

export default ConnectWallet;