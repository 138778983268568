import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import { Pagination } from 'antd';
import {useDispatch, useSelector} from 'react-redux'; 
import allActions from '../../actions';
import NodataImg from '../common/asset/images/nodata.svg';
import ItemCatalog from './itemCatalog';
import ItemGamehubRecommed from './itemGamehubRecommed';

const Catalog = () => {
    const [catalogMenu, setCatalogMenu] = useState([]);
    // const [catalogsList, setCatalogsList] = useState();
    const [dataCatalog, setDataCatalog] = useState();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { handle } = useParams(); 
    const [page, setPage] = useState(0);
    const size = 6; 
    function onChangePaging(pageNumber) {
        setPage(pageNumber - 1);
    }   
    const catalogsList = useSelector(state=>state?.gamehub.catalogsList);
    const catalogsCount = useSelector(state=>state?.gamehub.catalogsCount);  
    const catalogsPrices = useSelector(state=>state?.gamehub.catalogsPrices); 
     
    useEffect(() => { 
        setLoading(true)
        dispatch(allActions.gamehubAction.getCatalogs(async(res) => { 
            if(res) { 
                setCatalogMenu(res)
            }
        }));  
        dispatch(allActions.gamehubAction.getCatalogHandle(handle, async(res) => {
            if(res.data) {
                setDataCatalog(res);
            }
            if(res?.callResult === true ){
                setLoading(false)
            } 
        }));
    }, [handle]);
    
    
    useEffect(() => {
        setLoading(true)
        dispatch(allActions.gamehubAction.getCatalogByID(handle, page, (res)=>{
            if(res?.callResult === true ){
                setLoading(false)
            } 
        }));
    }, [page, handle])
    
     
    const handleDefault = ()=>{
        dispatch(allActions.gamehubAction.searchGameHub());   
    }    
    return(
        <>
        {loading && <div class="c-loading"><img src="../../images/loading-3s.gif" alt="Loading" /></div>}
        <div className="p-gameHub">
            <div className="p-gameHub__cate">
                <ul>
                    <li><Link onClick={()=>handleDefault()} to={"/gamehub"}>All Game</Link></li>
                    {catalogMenu && catalogMenu.map((item,index) => {
                        return(
                            <li key={index}>
                                <Link className={item.id === parseInt(handle) ? 'is-active' : ''} to={`/gamehub/catalog/${item.id}`}>{item.name}</Link>
                            </li>
                        )
                    })}
                </ul> 
            </div>
            <div className="p-gameHub__box">
                <h3 className="p-gameHub__title">{dataCatalog?.name}</h3>
                {catalogsList && catalogsList.length > 0 ? <div className="c-listRecommen">
                    {catalogsList && catalogsList.map((item,index) => {
                        return(
                            <ItemGamehubRecommed item={item} key={index} marketPriceArr={catalogsPrices} />
                        );
                    })}
                </div> : <div className="c-nodata">
                    <img src={NodataImg} alt="No Data" />
                    <p>No Data</p>
                </div>}
                <div className="pani-list">
                {catalogsList && catalogsList.length > 0 && 
                <Pagination
                    defaultCurrent={1}
                    total={catalogsCount}
                    pageSize = {size}
                    onChange={onChangePaging}
                    showSizeChanger={false}
                />}
            </div>
            </div>
        </div>
        </>
    )
}

export default Catalog;