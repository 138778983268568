import React, { useEffect, useState, useCallback } from 'react';
import ItemIDO from './itemIdo';
import { useSelector, useDispatch } from 'react-redux';
import allActions from '../../actions';
import NodataImg from '../common/asset/images/nodata-upcoming.png';
import { Col, Pagination, Row, Spin } from "antd";
import { Loader } from '../common/component';

const Upcoming = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [loadingPage, setLoadingPage] = useState(false);
    let size = 6;
    const status = 'UPCOMING,TBA';
    const sort = 'priority,asc';

    useEffect(() => {
        setLoadingPage(true);
        dispatch(allActions.campaignAction.getCampaignsByStatus({ status, sort, page, size }, (res) => {
            if (res.callResult === true) {
                setLoadingPage(false);
            }
        }));
    }, [dispatch, page, size]);

    function onChangePaging(pageNumber) {
        setPage(pageNumber - 1);
    }

    const campaignsData = useSelector(state => state.campaignsData);
    const { campaignList, campaignsCount } = campaignsData;

    return (
        <>
            
                <div className="c-list">
                    {loadingPage && <Loader />}
                    <Row gutter={30}>
                        {campaignList && campaignList.length > 0 ? (
                            <>
                                {campaignList.map((item, key) => (
                                    <Col xs={24} sm={8} xl={8} md={8}>
                                        <ItemIDO key={key} item={item} />
                                    </Col>
                                ))}
                            </>
                        ):(
                            <>
                                <div className="c-nodata c-nodata--upcoming">
                                    <img src={NodataImg} alt="No Data" />
                                    <p>Upcoming<span>10 projects (+)</span></p>
                                </div>
                            </>
                        )}
                        
                    </Row>
                    
                </div>
                <div className="pani-list">
                    {campaignList && campaignList.length > 6 &&
                        <Pagination
                            defaultCurrent={1}
                            total={campaignsCount}
                            pageSize={size}
                            onChange={onChangePaging}
                            showSizeChanger={false}
                        />}
                </div>
            
        </>
    );
};

export default Upcoming;