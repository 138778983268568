export const formatNumberCurrency = (number) => {
  return new Intl.NumberFormat('en-US', { maximumSignificantDigits: 10 }).format(number);
}

export const formatNumber = (number, digits) => {
  return new Intl.NumberFormat('en-US', { maximumSignificantDigits: digits }).format(number);
}

export const truncateNumber = (num, places) => {
  return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
}