import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import useWindowSize from '../../hooks/useWindowSize';
import { Layout, Row, Col } from 'antd';
import { Loader } from '../common/component';
import { Helmet } from "react-helmet"
import { ArrowRightOutlined } from '@ant-design/icons';

const Backer = () => {

    
    return(
        <>
            <div className="backer">
                <div className='container'>
                    <div className='backer-title' data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
                        <span>Partners & Backers</span>
                    </div>
                    <div className='backer-list' data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200">

                        <Row gutter={30} align="middle" justify="center">
                            <Col xs={12} sm={6}>
                                <div className='backer-item'>
                                    <img src='../images/partner/HeLa-Logo.png' />
                                </div>
                            </Col>
                            <Col xs={12} sm={6}>
                                <div className='backer-item'>
                                    <img src='../images/partner/stable-hodl.png' />
                                </div>
                            </Col>
                            <Col xs={12} sm={6}>
                                <div className='backer-item'>
                                    <img src='../images/partner/CytoswapLogo.svg' />
                                </div>
                            </Col>
                            <Col xs={12} sm={6}>
                                <div className='backer-item'>
                                    <img src='../images/partner/ProvidentCapital.png' />
                                </div>
                            </Col>
                            <Col xs={12} sm={6}>
                                <div className='backer-item'>
                                    <img src='../images/partner/send-tokens.jpg' />
                                </div>
                            </Col>
                            <Col xs={12} sm={6}>
                                <div className='backer-item'>
                                    <img src='../images/partner/Taskon-black.png' />
                                </div>
                            </Col>
                        </Row>

                    </div>
                    {/* <ul className='backer-list' data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200">
                        <li>
                            <img src='../images/partner/HeLa-Logo.png' />
                        </li>
                        <li>
                            <img src='../images/partner/StableHodl.png' />
                        </li>
                        <li>
                            <img src='../images/partner/CytoswapLogo.svg' />
                        </li>
                        <li>
                            <img src='../images/partner/ProvidentCapital.png' />
                        </li>
                        <li>
                            <img src='../images/partner/send-tokens.jpg' />
                        </li>
                        <li>
                            <img src='../images/partner/Taskon_color.png' />
                        </li>
                        
                    </ul> */}
                </div>
            </div>
            
        </>
    )
}

export default Backer;