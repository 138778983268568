import React, { useState, useEffect } from "react";
import {
  Input,
  Spin,
  Select,
  Upload,
  message,
  Tooltip,
  InputNumber,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { CaretDownOutlined } from "@ant-design/icons";
import { SC_ADD_LP, SCAN_URL } from "../../../../constants";
import addV2 from "../../../../constants/abi/addV2.json";
import {
  convertFromWei,
  convertToWei,
} from "../../../../utils/convertNumber";
import {
  notiError,
  notiSuccess,
  notiWarning,
} from "../../../../utils/notication";
import Web3 from "web3";
// import * as XLSX from "xlsx";
import { AbiItem } from "web3-utils";
import checkBalanceInfo from "../../../../utils/checkBalanceInfo";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { formatNum } from "../../../../utils/formatNumber";
import { useActiveWeb3React } from "../../../../hooks";

const AddLiqV2Details = (props) => {
  const { account , chainId } = useActiveWeb3React();
  const { Option } = Select;
  const navigate = useNavigate();
  const { TextArea } = Input;
  const contractA = props?.contractTokenA;
  const contractB = props?.contractTokenB;
  const [listAddress, setListAddress] = useState("");
  const web3 = new Web3(window.ethereum);
  const [isTransactionHash, setTransactionHash] = useState("");
  const [isPending, setPending] = useState(false);
  const [symBolTokenA, setSymbolTokenA] = useState("");
  const [symBolTokenB, setSymbolTokenB] = useState("");
  const [balanceA, setBalanceA] = useState("");
  const [balanceB, setBalanceB] = useState("");
  const [balanceTokenA, setBalanceTokenA] = useState("");
  const [balanceTokenB, setBalanceTokenB] = useState("");
  const [valueTo, setValueTo] = useState(0);
  const [valueForm, setValueForm] = useState(0);
  const [totalValueAddLP, setTotalValueAddLP] = useState(0);
  const amountPropsA = props?.amountA;
  const amountPropsB = props?.amountB;
  const antIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
  const [decimalsA, setDecimalsA] = useState("");
  const [decimalsB, setDecimalsB] = useState("");

  const onChangeValueTo = (event) => {
    setValueTo(event);
  };
  const onChangeValueForm = (event) => {
    setValueForm(event);
  };

  const list = listAddress
    .trim()
    .split("\n")
    .map((row) => {
      const [address, value] = row.split(",");
      return { address, value };
    });
  const addresses = list.map((item) => item?.address?.trim());
  const values = list.map((item) => {
    const trimmedValue = item?.value?.trim();

    if (trimmedValue && !isNaN(Number(trimmedValue))) {
      const toWeiValue = convertToWei(
        trimmedValue.toString(),
        Number(decimalsB)
      );
      return toWeiValue.toString();
    } else {
      return "0";
    }
  });

  const handleRidirectReturn = () => {
    navigate(`${"/add-liquidity?V2?step-1"}`);
  };
  
  const verifyNumberInput = async () => {
    if (listAddress) {
  
    let totalAddLP
      const valueFromWei = values.reduce(
        (total, value) => total + Number(convertFromWei(value.toString(),Number(decimalsB))),0
      );
      totalAddLP = valueFromWei + valueForm
      setTotalValueAddLP(totalAddLP)
    }
  };

  useEffect(() => {
    if (listAddress) {
        verifyNumberInput();
    }
  }, [listAddress, valueForm]);

  const checkInfoTokenA = async () => {
    try {
      if (contractA) {
        await checkBalanceInfo(contractA, SC_ADD_LP[chainId]).then(
          (res) => {
            setSymbolTokenA(res.symbol);
            setBalanceA(res.balanceOf);
            setDecimalsA(res.decimals);
          }
        );
        if (account) {
          const balanceTokenByAccount = await contractA.methods
            .balanceOf(account)
            .call();
          setBalanceTokenA(
            web3.utils.fromWei(balanceTokenByAccount.toString(), "ether")
          );
        }
      }
    } catch (error) {
      console.error("error check token A", error);
    }
  };
  useEffect(() => {
    if (contractA) {
      checkInfoTokenA();
    }
  }, [contractA, account, amountPropsA, amountPropsB]);

  const checkInfoTokenB = async () => {
    try {
      if (contractB) {
        await checkBalanceInfo(contractB, SC_ADD_LP[chainId]).then(
          (res) => {
            setSymbolTokenB(res.symbol);
            setBalanceB(res.balanceOf);
            setDecimalsB(res.decimals);
          }
        );
        if (account) {
          const balanceTokenByAccount = await contractB.methods
            .balanceOf(account)
            .call();
          setBalanceTokenB(
            web3.utils.fromWei(balanceTokenByAccount.toString(), "ether")
          );
        }
      }
    } catch (error) {
      console.error("error check token B", error);
    }
  };
  useEffect(() => {
    if (contractB) {
      checkInfoTokenB();
    }
  }, [contractB, account]);

  const handleFileUpload = (file) => {
    // try {
    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     const data = new Uint8Array(e.target.result);
    //     const workbook = XLSX.read(data, { type: "array" });
    //     const sheetName = workbook.SheetNames[0];
    //     const sheet = workbook.Sheets[sheetName];
    //     const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    //     const filteredData = jsonData.filter(
    //       (row) =>
    //         web3.utils.isAddress(row[0]?.trim()) &&
    //         !isNaN(row[1]?.toString().trim())
    //     );

    //     const formattedData = filteredData.map((row, index) => {
    //       const [address, amountBNB] = row;
    //       return `${address.trim()}, ${amountBNB.toString().trim()}`;
    //     });

    //     setListAddress(formattedData.join("\n"));
    //   };

    //   reader.readAsArrayBuffer(file);
    // } catch (error) {
    //   console.error(error);
    //   message.error({
    //     type: "error",
    //     content: error.message,
    //     className: "custom-class",
    //     duration: 5,
    //   });
    // }
  };

  const exportToExcel = () => {
    // const data = [
    //   {
    //     Address: "0x075AA49136664628E588493671d323928FD8835A",
    //     AmountBNB: "0.01",
    //   },
    //   {
    //     Address: "0xc2E311e9FA6B43002f02d5835D560f03c59604D7",
    //     AmountBNB: "0.01",
    //   },
    //   {
    //     Address: "0xD9888a6A6dA9A05091adb798e382E8a499C03F71",
    //     AmountBNB: "0.01",
    //   },
    //   {
    //     Address: "0x9dfeb78168826d95C75306832414705D19096979",
    //     AmountBNB: "0.01",
    //   },
    //   {
    //     Address: "0xa901FB223f7d5deff626E7A3E78f77344df42b0E",
    //     AmountBNB: "0.01",
    //   },
    // ];

    // const worksheet = XLSX.utils.json_to_sheet(data);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Sample_Add_LP");
    // XLSX.writeFile(workbook, "Sample_Add_LP.xlsx");
  };

  const addLiquidity = async () => {
    const gasPrice = await web3.eth.getGasPrice();
    try {
      setPending(true);
      if(totalValueAddLP <= Number(amountPropsB) ){
        const contract = new web3.eth.Contract(
            addV2 ,
            SC_ADD_LP[chainId]
          );
    
          const valueToToWei = convertToWei(
            valueTo.toString(),
            Number(decimalsA)
          );
          const valueFormFromWei = convertToWei(
            valueForm.toString(),
            Number(decimalsB)
          );
          await contract.methods
            .addSwapLiquidity(
              contractB,
              contractA,
              valueFormFromWei,
              valueToToWei,
              addresses,
              values
            )
            .send({
              from: account,
              gasPrice: web3.utils.toHex(String(gasPrice)),
            })
            .then((res) => {
              if (res.status) {
                notiSuccess("Success", 5);
                setTransactionHash(res.transactionHash);
                setPending(false);
                navigate(`${"/bot/liquidity?V2?step-1"}`);
              }
            });
      }else {
        notiWarning(`Please make sure: Your ${symBolTokenB} balance > Amount ${symBolTokenB} + Total amount ${symBolTokenB} from address list.`, 5);
        setPending(false);
      }
      
    } catch (error) {
      notiError(error?.message, 5);
      setPending(false);
    }
  };

  const toolTips = (
    <>
      <div className="tooltip-name">
        <div className="name">Address List</div>
        <div className="desc">
          Tooltips are used to describe or identify an element. In most
          scenarios, tooltips help the user understand the meaning, function or
          alt-text of an element.
        </div>
      </div>
    </>
  );
  return (
    <>
      <div className="section-add-liquidity">
        <div className="container">
          <div className="group-add-liquidity">
            <div className="breacrum">
              <div className="item active">
                <span className="number">1</span> Set up infomation LP
              </div>
              <div className="arrow">
                <img src="../images/bot/arrow.png" alt="" />
              </div>
              <div className="item active">
                <span className="number">2</span> Add liquidity V2
              </div>
            </div>
            <div className="group-row">
              <div className="title">Set up infomation LP</div>
              <div className="group-dex">
                <div className="item-master">
                  <div className="name">
                    {!isMobile ? (
                      <>Master Contract Management Address:</>
                    ) : (
                      <>Contract:</>
                    )}
                  </div>
                  <div className="row-smart-contract">
                    <span className="sc-contract">
                      {SC_ADD_LP[chainId]?.slice(0, 6)}...
                      {SC_ADD_LP[chainId]?.slice(-6)}
                    </span>
                    <CopyToClipboard
                      text={SC_ADD_LP[chainId]}
                      onCopy={() => {
                        notiSuccess("Copied", 5);
                      }}
                    >
                      <span className="icon">
                        <img src="../images/bot/copied.png" alt="" />
                      </span>
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="item-master">
                  <div className="name">Select Dex:</div>
                  <div className="select-fiter">
                    <Select
                      value={
                        chainId === 56
                          ? "pancake"
                          : chainId === 1
                          ? "uni"
                          : "uni"
                      }
                      suffixIcon={<CaretDownOutlined rev={undefined} />}
                    >
                      {chainId === 56 ? (
                        <>
                          <Option value="pancake">
                            <img src="../../../images/bot/cake-1.png" alt="" />{" "}
                            <span className="text-chain">Pancakeswap V2</span>
                          </Option>
                        </>
                      ) : (
                        <>
                          {chainId === 1 ? (
                            <>
                              <Option value="uni">
                                <img
                                  src="../../../images/bot/uni-hihi.png"
                                  alt=""
                                />{" "}
                                <span className="text-chain">Uniswap V3</span>
                              </Option>
                            </>
                          ) : (
                            <>
                              <Option value="uni">
                                <img
                                  src="../../../images/bot/uni-hihi.png"
                                  alt=""
                                />{" "}
                                <span className="text-chain">Uniswap V3</span>
                              </Option>
                            </>
                          )}
                        </>
                      )}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="group-pair-address mar-top-20">
                <div className="pair-name">
                  Pair:{" "}
                  <span className="pair">
                    {" "}
                    <img src="../images/bot/bnb.png" alt="" />{" "}
                    <img src="../images/bot/bnb.png" alt="" />{" "}
                    <span className="name">
                      {symBolTokenA}/{symBolTokenB}
                    </span>
                  </span>
                </div>
                <ul className="list-pair">
                  <li>
                    <img src="../images/bot/checked.png" alt="" /> Transfer{" "}
                    <span className="to-amount">
                      {" "}
                      {formatNum(Number(amountPropsA).toFixed(50))}{" "}
                      {symBolTokenA}
                      {/* {amountPropsA} {symBolTokenA} */}
                    </span>{" "}
                    <span className="form-amount">
                      {" "}
                      to {SC_ADD_LP[chainId]?.slice(0, 5)}...
                      {SC_ADD_LP[chainId]?.slice(-5)}
                    </span>
                  </li>
                  <li>
                    <img src="../images/bot/checked.png" alt="" /> Transfer{" "}
                    <span className="to-amount">
                      {" "}
                      {formatNum(Number(amountPropsB).toFixed(50))}{" "}
                      {symBolTokenB}
                      {/* {amountPropsB} {symBolTokenB} */}
                    </span>{" "}
                    <span className="form-amount">
                      {" "}
                      to {SC_ADD_LP[chainId]?.slice(0, 5)}...
                      {SC_ADD_LP[chainId]?.slice(-5)}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="row-select-pair mar-top-20">
                <div className="guide-wrap">
                  <div className="left">
                    <div className="item-form res">
                      <div className="txt">
                        Amount {symBolTokenA} want to add LP
                      </div>
                      <div className="input-round">
                        <span className="name-token">{symBolTokenA}</span>
                        <InputNumber
                          value={valueTo}
                          min={0}
                          max={amountPropsA}
                          onChange={onChangeValueTo}
                          className="form-control w-100"
                        />
                      </div>
                      <div className="balance-of">
                        Your balance:{" "}
                        <span>
                          {formatNum(Number(amountPropsA).toFixed(50))}{" "}
                          {symBolTokenA}
                          {/* {Number(amountPropsA).toFixed(0)} {symBolTokenA} */}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="item-form res">
                      <div className="txt">
                        Amount {symBolTokenB} want to add LP
                      </div>
                      <div className="input-round">
                        <span className="name-token">{symBolTokenB}</span>
                        <InputNumber
                          value={valueForm}
                          min={0}
                          max={amountPropsB}
                          onChange={onChangeValueForm}
                          className="form-control w-100"
                        />
                      </div>
                      <div className="balance-of">
                        Your balance:{" "}
                        <span>
                          {formatNum(Number(amountPropsB).toFixed(50))}{" "}
                          {symBolTokenB}
                          {/* {Number(amountPropsB)} {symBolTokenB} */}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-form mar-b-20 mar-top-20">
                <div className="txt">
                  Address List
                </div>
                <div className="input-round">
                  <TextArea
                    value={listAddress}
                    className="form-control w-100"
                    onChange={(e) => setListAddress(e.target.value)}
                    placeholder="Enter one address on each line.&#10;Supports only format.&#10;0x0858953Ba2599AF7a9C91xxxxxxxxxxxxx&#10;0x08589876129129AF7a9C91xxxxxxxxxxxxx"
                    autoSize={{
                      minRows: 10,
                      maxRows: 10,
                    }}
                  />
                </div>
              </div>
              <div className="group-upload">
                <Upload
                  accept=".xlsx, .xls"
                  showUploadList={false}
                  beforeUpload={handleFileUpload}
                >
                  <button className="btn-up">
                    <img src="../images/bot/upload.png" alt="" /> Upload Excel
                  </button>
                </Upload>
                <button className="btn-up" onClick={exportToExcel}>
                  <img src="../images/bot/download.png" alt="" /> Sample file
                </button>
              </div>
              <div className="group-btn mar-top-30">
                {!contractA || !contractB || !listAddress ? (
                  <>
                    <button type="button" className="btn-add w-180">
                      Add liquidity
                    </button>
                  </>
                ) : (
                  <>
                    {isPending ? (
                      <>
                        <button type="button" className="btn-add w-180">
                          <Spin indicator={antIcon} /> Add liquidity
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          className="btn-add click-now w-180"
                          onClick={addLiquidity}
                        >
                          Add liquidity
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="group-btn-back res">
                <button className="btn-back" onClick={handleRidirectReturn}>
                  <img src="../images/bot/arrow-right.png" alt="" /> Back
                </button>
              </div>
              <div className="group-dot res mar-top-30">
                <img src="../images/bot/dot-new-2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddLiqV2Details;
