
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Layout, Row, Col, Button, Form, Input, Select, Spin, InputNumber, Checkbox, Alert, Space, Radio, Breadcrumb } from 'antd';
import { LeftCircleOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from '../../hooks';
import web3 from 'web3';
import './style.css';
import { ethers } from 'ethers';
import { chainApi } from '../../config/api/chainApi';
import { getInformationByChain } from '../../config/network/multichainAddresses';
import {
    createAntiBotStandardToken,
    createStandardToken,
} from '../utils';
import antiBotStandardTokenAbi from '../../config/abi/antiBotStandardTokenAbi.json';
import standardTokenAbi from '../../config/abi/standardTokenAbi.json';
import {
    ANTI_BOT_TOKEN_BYTECODE,
    STANDARD_TOKEN_BYTECODE,
} from '../bytecodes/bytecodes';
import {
    INIT_LAUNCHPAD_FEE,
    INIT_TOKEN_FEE,
    INIT_TOKEN_FEE_WALLET,
    INIT_TOKEN_MAIN_FEE
} from '../../components/keyConfig';
import { dexExchanges } from '../../config/network/dex';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import { Loader } from '../common/component';

const { Content } = Layout;
const { Option } = Select;

const CreateToken = (props) => {
    const { account, library, chainId } = useActiveWeb3React();

    const [form] = Form.useForm();

    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const antiBotAddress = getInformationByChain(chainId, 'REACT_APP_ANTI_BOT');
    const scanUrl = getInformationByChain(chainId, 'REACT_APP_SCAN_URL');

    const [loading, setLoading] = useState(false);
    const [cexList, setCexList] = useState([]);
    const [tokenType, setTokenType] = useState('standard');

    const [feeList, setFeeList] = useState([]);
    const [feeOptions, setFeeOptions] = useState(JSON.stringify(["INIT_TOKEN_FEE"]));
    const [isCreateTokenVisible, setIsCreateTokenVisible] = useState(false);
    const [createPoolFee, setCreatePoolFee] = useState([]);
    const [tokenAddress, setTokenAddress] = useState('');
    const [tokenSymbol, setTokenSymbol] = useState('');
    const [tokenName, setTokenName] = useState('');
    const [tokenDecimal, setTokenDecimal] = useState(0);
    const [hash, setHash] = useState();
    const [created, setCreated] = useState(false);

    const [createTokenForm] = Form.useForm();

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [initTokenFee, setInitTokenFee] = useState(0);
    const [initToken, setInitToken] = useState();

    const [standardFeeWallet, setStandardFeeWallet] = useState("");
    const [lpFeeWallet, setLpFeeWallet] = useState("");



    const getSettings = async () => {

        try {
            let res = await chainApi.getSettingsByChain(chainId);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    let initFee = res.data.filter((itemSetting) => INIT_TOKEN_FEE === itemSetting.settingKey);
                    if (initFee.length > 0) {
                        setInitTokenFee(parseFloat(initFee[0].settingValue))
                        setInitToken(initFee[0]);
                    }

                    let standardWallet = res.data.filter((itemSetting) => INIT_TOKEN_FEE_WALLET === itemSetting.settingKey);
                    if (standardWallet.length > 0) {
                        setStandardFeeWallet(standardWallet[0].settingValue)
                    }

                   

                    setFeeList(res.data);
                    let poolFee = res.data.filter((itemSetting) => INIT_LAUNCHPAD_FEE === itemSetting.settingKey);
                    if (poolFee[0]) {
                        setCreatePoolFee(poolFee[0]);
                    }

                }
            }
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };



    useEffect(() => {
        if (chainId) {
            const dexList = dexExchanges[chainId];
            setCexList(dexList)
            getSettings();
        } else {
            setCexList([])
        }
    }, [chainId])

    const createStandardTokenForm = async (values) => {

        console.log('standardFeeWallet', standardFeeWallet);
        console.log('initTokenFee', initTokenFee);

        setLoading(true);
        try {
            await createStandardToken(standardTokenAbi,
                `0x${STANDARD_TOKEN_BYTECODE}`,
                library,
                account,
                values.name,
                values.symbol,
                parseInt(values.decimals),
                ethers.utils.parseUnits(`${values.total_supply}`, values.decimals),
                standardFeeWallet,
                ethers.utils.parseUnits(`${initTokenFee}`, 'ether').toHexString()
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber && res.contractAddress) {
                                        if (!countNoti) {
                                            countNoti++;
                                            setTokenAddress(res.contractAddress);
                                            Store.addNotification(addNotify("Create Standard Token Successfully", 'success'));
                                            const tokenInfo = {
                                                name: values.name,
                                                symbol: values.symbol,
                                                decimals: values.decimals,
                                                supply: values.total_supply
                                            };
                                            window.localStorage.setItem(`${chainId}/${res.contractAddress.toLowerCase()}`, JSON.stringify(tokenInfo));
                                            createTokenForm.resetFields();
                                            setTokenSymbol(values.symbol);
                                            setTokenName(values.name);
                                            setTokenDecimal(values.decimals);
                                            setHash(txn.hash);
                                            setCreated(true);
                                        }
                                    } else {
                                        Store.addNotification(addNotify("Create Standard Token Failed", 'danger'));
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            Store.addNotification(addNotify(error.message, 'danger'));
                        } else if (error.reason) {
                            Store.addNotification(addNotify(error.reason, 'danger'));
                        } else {
                            if (error.data && error.data.message) {
                                Store.addNotification(addNotify(error.data.message, 'danger'));
                            }
                        }
                    }
                });

        } catch (e) {
            setLoading(false);
            console.log('asdad', e);
        }
    };


    const createAntiStandardTokenForm = async (values) => {
        setLoading(true);
        try {
            await createAntiBotStandardToken(antiBotStandardTokenAbi,
                `0x${ANTI_BOT_TOKEN_BYTECODE}`,
                library,
                account,
                values.name,
                values.symbol,
                parseInt(values.decimals),
                ethers.utils.parseUnits(`${values.total_supply}`, values.decimals),
                standardFeeWallet,
                ethers.utils.parseUnits(`${initTokenFee}`, 'ether').toHexString(),
                antiBotAddress
            )
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber && res.contractAddress) {
                                        if (!countNoti) {
                                            countNoti++;

                                            setTokenAddress(res.contractAddress);
                                            Store.addNotification(addNotify("Create Standard Token Successfully", 'success'));
                                            const tokenInfo = {
                                                name: values.name,
                                                symbol: values.symbol,
                                                decimals: values.decimals,
                                                supply: values.total_supply
                                            };
                                            window.localStorage.setItem(`${chainId}/${res.contractAddress.toLowerCase()}`, JSON.stringify(tokenInfo));
                                            createTokenForm.resetFields();
                                            setTokenSymbol(values.symbol);
                                            setTokenName(values.name);
                                            setTokenDecimal(values.decimals);
                                            setHash(txn.hash);
                                            setCreated(true);
                                        }
                                    } else {
                                        Store.addNotification(addNotify("Create Standard Token Failed", 'danger'));
                                    }
                                    setLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            Store.addNotification(addNotify(error.message, 'danger'));
                        } else if (error.reason) {
                            Store.addNotification(addNotify(error.reason, 'danger'));
                        } else {
                            if (error.data && error.data.message) {
                                Store.addNotification(addNotify(error.data.message, 'danger'));
                            }
                        }
                    }
                });

        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    };


    

    const onSubmitCreateTokenForm = async (values) => {
        if (values.implement_anti_bot) {
            return createAntiStandardTokenForm(values);
        } else {
            return createStandardTokenForm(values);
        }
    };

    const closeBox = () => {
        setIsCreateTokenVisible(false);
    };

    const onCopy = () => {
        Store.addNotification(addNotify("Copied", 'success'));
    };
    const onChangeFee = e => {
        setFeeOptions(e.target.value);
    };
    const addToMetaMask = async () => {
        setLoading(true);
        try {
            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            const wasAdded = await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: tokenAddress, // The address that the token is at.
                        symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: tokenDecimal, // The number of decimals in the token
                    },
                },
            });
            
            if (wasAdded) {
                setLoading(false);
                Store.addNotification(addNotify("Successfully!", 'success'));
            } else {
                setLoading(false);
                Store.addNotification(addNotify("Failed!", 'danger'));
            }
        } catch (error) {
            setLoading(false);
            Store.addNotification(addNotify(error?.message, 'danger'));
        }
    };
    return (
        <>
            <div className='create-token-page'>
                <div className="container">
                    <Breadcrumb className='head-breadcrumb-airdrop'>
                        <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Create token</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className="create-token-title">Create Token</div>
                    <div className="create-step">
                        <div className="anti-bot-block">
                                {created ? (
                                    <div className='token-created'>
                                        <div className='message'>
                                            <Alert message="Your token was created!" type="success" showIcon />
                                        </div>
                                        <div className="token-info-anti">
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-name">Name</div>
                                                </Col>
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-value">{tokenName}</div>
                                                </Col>
                                            </Row>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-name">Address</div>
                                                </Col>
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-value">{tokenAddress}</div>
                                                </Col>
                                            </Row>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-name">Symbol</div>
                                                </Col>
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-value">{tokenSymbol}</div>
                                                </Col>
                                            </Row>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className="token-info-row">
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-name">Decimals</div>
                                                </Col>
                                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                                    <div className="token-value">{tokenDecimal}</div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className='btn-list'>
                                            <button className="btn-basic w-fitcontent" onClick={() => (window.open(`${scanUrl}tx/${hash}`))}>View transaction</button>
                                            <CopyToClipboard onCopy={onCopy} text={tokenAddress}>
                                                <button className="btn-basic w-fitcontent">Copy address</button>
                                            </CopyToClipboard>

                                            <button className="btn-basic w-fitcontent" onClick={addToMetaMask} disabled={loading}>Add to Metamask</button>

                                            {/* <button className="btn-basic w-fitcontent" onClick={(event) => (window.location.href = `/ico/create?token=${tokenAddress}`)}>Create launchpad</button> */}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="steps-content-antibot">
                                        <div className="sub-title-steps">
                                            Token Infomation
                                        </div>
                                        <div className="inputs-steps">
                                            <Form
                                                form={createTokenForm}
                                                name="basic"
                                                autoComplete="off"
                                                onFinish={onSubmitCreateTokenForm}
                                                layout="vertical"
                                                className='basic-form'
                                            >
                                                {/* <Form.Item name="token_type" label="Token type" initialValue={tokenType}
                                                    rules={[{ required: true, message: 'Please input round type!' }]}
                                                >
                                                    <Select className="select-create-ido" onChange={onChangeTokenType}>
                                                        <Option value="standard">Standard Token</Option>
                                                        <Option value="liquidity">Liquidity Generator Token</Option>
                                                    </Select>
                                                </Form.Item> */}

                                                <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input token name!' }]}>
                                                    <Input  placeholder="Ex: Ethereum" />
                                                </Form.Item>

                                                <Form.Item name="symbol" label="Symbol" rules={[{ required: true, message: 'Please input token Symbol!' }]}>
                                                    <Input  placeholder="Ex: ETH" />
                                                </Form.Item>

                                                <Form.Item name="decimals" placeholder="Ex: 18" label="Decimals" rules={[{ required: true, message: 'Please input Decimals!' }]}>
                                                    <Select className="select-create-ido">
                                                        <Option value="6">6</Option>
                                                        <Option value="9">9</Option>
                                                        <Option value="12">12</Option>
                                                        <Option value="18">18</Option>
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item name="total_supply" label="Total supply" rules={[
                                                        { required: true, message: 'Please input Total supply!' },
                                                        {
                                                            validator: (rule, value, cb) => {
                                                                !value || parseFloat(value) <= 0 ? cb("Total supply must be > 0.") : cb();
                                                            }
                                                        }
                                                    ]}>
                                                    <Input type="number" placeholder="Ex: 1000000" />
                                                </Form.Item>

                                                
                                                
                                                {/* set fee */}

                                                <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                                                    <Form.Item
                                                        name="fee_options"
                                                        label="Fee Options"
                                                        initialValue={feeOptions}
                                                        rules={[{ required: true, message: 'Please pick fee options!' }]}
                                                    >
                                                        <Radio.Group onChange={onChangeFee}>
                                                            <Space direction="vertical" className='ml-15'>
                                                                {feeList.map((item, index) => {
                                                                    let valFeeMainToken;
                                                                    if (item.settingKey === INIT_TOKEN_MAIN_FEE && item.feeType === "PERCENT") {
                                                                        valFeeMainToken = parseFloat(initTokenFee) - (initTokenFee * (item.settingValue / 100));
                                                                    }
                                                                    return (
                                                                        <>
                                                                            {item.settingKey === INIT_TOKEN_FEE && ( // BNB fee
                                                                                <Radio value={JSON.stringify(['INIT_TOKEN_FEE'])} key={'fee-' + index}>
                                                                                    {item.settingValue}{item.feeType === "PERCENT" ? '%' : ''} {initToken?.payToken}
                                                                                </Radio>
                                                                            )}
                                                                            {item.settingKey === INIT_TOKEN_MAIN_FEE && ( // BNB only fee
                                                                                <Radio disabled={true} value={JSON.stringify(['INIT_TOKEN_MAIN_FEE', 'INIT_TOKEN_FEE'])} key={'fee-11' + index}>
                                                                                    Amount of <span className='text-info'>HLUP</span> equivalent to {valFeeMainToken} {initToken?.payToken} <span className='text-danger'>({item.settingValue}{item.feeType === "PERCENT" ? '%' : ''} discount)</span>
                                                                                </Radio>
                                                                            )}
                                                                        </>
                                                                    )
                                                                })}
                                                            </Space>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>

                                                {/* <Form.Item name="implement_anti_bot" valuePropName="checked" initialValue={false}>
                                                    <Checkbox>Implement Anti-Bot System?</Checkbox>
                                                </Form.Item> */}
                                                <div className="modal-btn-group max-with-400">
                                                    <button className="btn-basic" disabled={loading || !account} type="submit">Create Token {loading && <Spin size="small" />}</button>
                                                    <button className="btn-basic-default" disabled={loading} onClick={closeBox}>Close</button>
                                                </div>
                                            </Form>

                                        </div>


                                        {loading && (
                                            <Loader />
                                        )}
                                    </div>
                                )}

                            </div>
                    </div>
                </div>
                
            </div>
            
            
        </>
    )
}

export default CreateToken
