
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { Layout, Row, Col, Button, Form, Input, Select } from 'antd';
import { convertDateTime } from '../../../../../utils/formatDatetime';


const Finish = () => {
    let storageLaunchpadInfo = window.localStorage.getItem('launchpadInfo');
    let storageAdditionalInfo = window.localStorage.getItem('additionalInfo');
    let storageTokenInfo = window.localStorage.getItem('tokenInfo');

    let additionalInfo;
    let launchpadInfo;
    let tokenInfo;
    if (storageTokenInfo) {
        tokenInfo = JSON.parse(storageTokenInfo);
    }
    if (storageAdditionalInfo) {
        additionalInfo = JSON.parse(storageAdditionalInfo);
    }
    if (storageLaunchpadInfo) {
        launchpadInfo = JSON.parse(storageLaunchpadInfo);
    }
    return (
        <>
            <div className="title-steps">Finish</div>
            <div className="launchpad-info-form">
                <div className="sub-title-steps">Verify Token</div>
                <Row gutter={30}>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Network</p>
                            <p>{tokenInfo ? tokenInfo.token_network_chain : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Currency Pay</p>
                            <p>{tokenInfo ? tokenInfo.currency_pay : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Total Token</p>
                            <p>{tokenInfo ? new Intl.NumberFormat("ja-JP").format(tokenInfo.total_supply) : ''} {tokenInfo ? tokenInfo.token_symbol : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Token Name</p>
                            <p>{tokenInfo ? tokenInfo.token_name : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Token Symbol</p>
                            <p>{tokenInfo ? tokenInfo.token_symbol : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Total Decimals</p>
                            <p>{tokenInfo ? tokenInfo.token_decimal : ''}</p>
                        </div>
                    </Col>
                </Row>
                <hr className="hr-cus-cre"></hr>
                <div className="sub-title-steps">Defi Launchpad Info</div>
                <Row gutter={30}>
                    <Col className="gutter-row" xs={12} sm={6}>
                        <div className="finish-content">
                            <p>Title</p>
                            <p>{launchpadInfo ? launchpadInfo.title : '___'}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={6}>
                        <div className="finish-content">
                            <p>Claim URL</p>
                            <p>{launchpadInfo ? <a href={launchpadInfo.claimLink}>Click here</a> : '___'}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={6}>
                        <div className="finish-content">
                            <p>Presale Rate</p>
                            <p>{launchpadInfo ? new Intl.NumberFormat("ja-JP").format(launchpadInfo.presale_rate) : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={6}>
                        <div className="finish-content">
                            <p>Listing Rate</p>
                            <p>{launchpadInfo ? new Intl.NumberFormat("ja-JP").format(launchpadInfo.listing_rate) : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={6}>
                        <div className="finish-content">
                            <p>Sale Method</p>
                            <p>{launchpadInfo && launchpadInfo.whitelist == 'disable' ? 'Public' : 'Private'}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={6}>
                        <div className="finish-content">
                            <p>Softcap</p>
                            <p>{launchpadInfo ? new Intl.NumberFormat("ja-JP").format(launchpadInfo.softcap) : ''} {tokenInfo ? tokenInfo.currency_pay : ''}</p>
                        </div>
                    </Col>

                    <Col className="gutter-row" xs={12} sm={6}>
                        <div className="finish-content">
                            <p>Hardcap</p>
                            <p>{launchpadInfo ? new Intl.NumberFormat("ja-JP").format(launchpadInfo.hardCap) : ''} {tokenInfo ? tokenInfo.currency_pay : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={6}>
                        <div className="finish-content">
                            <p>Unsold Tokens</p>
                            <p>{launchpadInfo ? launchpadInfo.refund_type.toUpperCase() : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={6}>
                        <div className="finish-content">
                            <p>Minimum Buy</p>
                            <p>{launchpadInfo ? new Intl.NumberFormat("ja-JP").format(launchpadInfo.minimum_buy) : ''} {tokenInfo ? tokenInfo.currency_pay : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} sm={6}>
                        <div className="finish-content">
                            <p>Maximum Buy</p>
                            <p>{launchpadInfo ? new Intl.NumberFormat("ja-JP").format(launchpadInfo?.maximum_buy) : ''} {tokenInfo ? tokenInfo.currency_pay : ''}</p>
                        </div>
                    </Col>
                    {launchpadInfo && launchpadInfo?.liquidity && launchpadInfo?.liquidity.length > 0 && (
                        <Col className="gutter-row" xs={12} sm={6}>
                            <div className="finish-content">
                                <p>Liquidity</p>
                                <p>{launchpadInfo.liquidity}%</p>
                            </div>
                        </Col>
                    )}
                    

                    <Col className="gutter-row" xs={12} sm={6}>
                        <div className="finish-content">
                            <p>Start Time</p>
                            <p className='text-upper'>{launchpadInfo ? convertDateTime(new Date(launchpadInfo.start_time)) : ''}</p>
                        </div>
                    </Col>
                    <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>End Time</p>
                            <p className='text-upper'>{launchpadInfo ? convertDateTime(new Date(launchpadInfo.end_time)) : ''}</p>
                        </div>
                    </Col>
                    {launchpadInfo && launchpadInfo?.liquidity_lockup && launchpadInfo?.liquidity_lockup.length > 0 && (
                        <Col xs={12} xl={6} md={6} sm={6}>
                            <div className="finish-content">
                                <p>Liquidity Lockup Time</p>
                                <p>{launchpadInfo ? launchpadInfo.liquidity_lockup : ''} Days</p>
                            </div>
                        </Col>
                    )}
                    
                </Row>
                <hr className="hr-cus-cre"></hr>
                <div className="sub-title-steps">Add Additional Info</div>
                <Row gutter={30}>
                    <Col xs={24} sm={6}>
                        <div className="finish-content">
                            <p>Website</p>
                            <p>{additionalInfo?.website ? additionalInfo.website : '___'}</p>
                        </div>
                    </Col>
                    <Col xs={24} sm={6}>
                        <div className="finish-content">
                            <p>Facebook</p>
                            <p>{additionalInfo?.facebook ? additionalInfo.facebook : '___'}</p>
                        </div>
                    </Col>
                    <Col xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>X</p>
                            <p>{additionalInfo?.twitter ? additionalInfo.twitter : '___'}</p>
                        </div>
                    </Col>
                    <Col xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Telegram</p>
                            <p>{additionalInfo?.telegram ? additionalInfo.telegram : '___'}</p>
                        </div>
                    </Col>

                    <Col xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Github</p>
                            <p>{additionalInfo?.github ? additionalInfo.github : '___'}</p>
                        </div>
                    </Col>
                    <Col xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Instagram</p>
                            <p>{additionalInfo?.instagram ? additionalInfo.instagram : '___'}</p>
                        </div>
                    </Col>
                    <Col xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Discord</p>
                            <p>{additionalInfo?.discord ? additionalInfo.discord : '___'}</p>
                        </div>
                    </Col>
                    <Col xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Reddit</p>
                            <p>{additionalInfo?.reddit ? additionalInfo.reddit : '___'}</p>
                        </div>
                    </Col>

                    <Col xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Description</p>
                            <p>{additionalInfo?.description ? additionalInfo.description : '___'}</p>
                        </div>
                    </Col>
                    {/* <Col xs={24} xl={6} md={6} sm={6}>
                        <div className="finish-content">
                            <p>Using Anti-Rug system (Team Vesting System)</p>
                            <p>{launchpadInfo && launchpadInfo.team_vesting_system && launchpadInfo?.team_vesting_system[0] ? 'Yes' : 'No'}</p>
                        </div>
                    </Col> */}

                </Row>
            </div>
        </>
    )
}

export default Finish
