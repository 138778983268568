import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useHistory } from 'react-router-dom';
import { Select, Row, Col, Form, Input, Space, Spin, Tabs, Pagination } from 'antd';
import NodataImg from '../../common/asset/images/nodata-upcoming.png';
import { useActiveWeb3React } from '../../../hooks';

import { IdoLaunchpad } from '../../../config/api/idoLaunchpad';
import './style.css';
import IcoItem from './ico-item';
import { chainApi } from '../../../config/api/chainApi';
import { CHAINID_FULLNAME_BY_ID, CHAINID_LOGO_BY_ID } from '../../../constants';
// import FilterChain from 'components/FilterChain';

const { TabPane } = Tabs;

const IcoProject = () => {

    const [dataLaunchpad, setDataLaunchpad] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [chainNetworkListMain, setChainNetworkListMain] = useState([]);
    const [chainNetworkListTest, setChainNetworkListTest] = useState([]);
    const { chainId, account } = useActiveWeb3React();
    const [activeTab, setActiveTab] = useState('all');
    const [sltChain , setSltChain] = useState(0);

    const [form] = Form.useForm();
    const [totalRow, setTotalRow] = useState(0);

    const [param, setParam] = useState(
        {
            page: 0,
            size: 9,
            sort: 'id,desc',
            tokenInfo: '',
            filterEnum: 'ALL',
            chainId: 0
        }
    );

    const getLaunchpadList = async (type, status, chainId) => {

        setLoadingData(true);
        let dataFilter = {
            ...param,
            filterEnum: type
        };
        try {
            let res = await IdoLaunchpad.getLaunchpads(dataFilter);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                if (res.data.length > 0) {
                    let launchpadList = [];
                    if (status == 'all' || status == 'filled') {
                        setLoadingData(false);
                        setDataLaunchpad(res.data);

                    } else {
                        res.data.forEach((item, index) => {
                            let nowDate = new Date(Date.now());
                            let startDate = new Date(item.startTime);
                            let endDate = new Date(item.endTime);

                            if (status == 'upcoming') {
                                if (startDate > nowDate && endDate > nowDate && item.roundStatus != "CANCEL") {
                                    launchpadList.push({ ...item });
                                }
                            }
                            if (status == 'opening') {
                                if (startDate < nowDate && endDate > nowDate && item.roundStatus != "CANCEL") {
                                    launchpadList.push({ ...item });
                                }
                            }
                            if (status == 'closed') {
                                if (startDate < nowDate && endDate < nowDate && item.roundStatus != "CANCEL") {
                                    launchpadList.push({ ...item });
                                }
                            }

                        });
                        setLoadingData(false);
                        setDataLaunchpad(launchpadList);
                        launchpadList.length > 0 ? setTotalRow(parseInt(res.headers['x-total-count'])) : setTotalRow(0);
                    }

                } else {
                    setLoadingData(false);
                    setDataLaunchpad([]);
                }


            }
        } catch (error) { console.log('Error: ', error); }
        finally {
            setLoadingData(false);
        }
    };


    useEffect(() => {
        if (activeTab) {
            switch (activeTab) {
                case 'all':
                    getLaunchpadList('ALL', 'all', chainId);
                    break;
                case 'upcoming':
                    getLaunchpadList('ALL', 'upcoming', chainId);
                    break;
                case 'opening':
                    getLaunchpadList('ALL', 'opening', chainId);
                    break;
                case 'filled':
                    getLaunchpadList('FILLED', 'filled', chainId);
                    break;
                case 'closed':
                    getLaunchpadList('ALL', 'closed', chainId);
                    break;
                default:
                    break;
            }
        }

    }, [activeTab, param, chainId]);



    const onChange_Pagi = (pageNumber) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
    };

    const IcoContent = () => {

        return (
            <>
                <Row gutter={30}>
                    {!loadingData ? (
                        <>
                            {dataLaunchpad?.length > 0 ? (
                                dataLaunchpad.map((item, index) => {

                                    let logoNetwork = CHAINID_LOGO_BY_ID[item?.chainId];
                                    let title = CHAINID_FULLNAME_BY_ID[item?.chainId] ? CHAINID_FULLNAME_BY_ID[item?.chainId] : '';
                                    

                                    return (
                                        <Col xs={24} sm={8} xl={8} md={8} key={'item' + index}>
                                            <IcoItem logoNetwork={logoNetwork} titleNetwork={title} item={item} />
                                        </Col>
                                    )
                                })
                            ) : (
                                <>
                                    <Col xs={24} className="text-center">
                                        <div className="c-nodata c-nodata--upcoming">
                                            <img src={NodataImg} alt="No Data" />
                                            <p>No data</p>
                                        </div>
                                    </Col>
                                </>
                            )}
                        </>
                    ) : (<>
                        <div className="loading-data">
                            <Space size="middle">
                                <Spin size="large" />
                            </Space>
                        </div>
                    </>)}

                </Row>
            </>
        );
    };

    const onSearchLaunpad = (values) => {
        let search = values.target.value;
        setParam({
            ...param,
            tokenInfo: search
        });
    };

    const onChangeTabs = (key) => {
        if(key === "my-project"){
            window.location.replace('/ico/my-project')
        }
        setActiveTab(key);
    };
    const handleChangeSltChain = (value) => {
       setSltChain(value);
        setParam({
            ...param,
            chainId: value
        });
    };
    return (
        <>
            <div className="projects-grid">
                <div className='container'>
                    <div className='filter-block-form d-xs'>
                        <Select
                            className="align-left custom-slt"
                            placeholder="Network..."
                            style={{ width: 190, marginRight: '10px'}}
                            onChange={handleChangeSltChain}
                            options={[
                                {
                                    value: '8668',
                                    label: 'HeLa',
                                },
                                {
                                    value: '56',
                                    label: 'BSC',
                                },
                                {
                                    value: '137',
                                    label: 'Polygon',
                                },
                                {
                                    value: '1',
                                    label: 'Ethereum',
                                },
                                {
                                    value: '42161',
                                    label: 'Arbitrum',
                                }
                            ]}
                        />
                        <button type="button" className="btn-basic w-fitcontent" onClick={()=> window.location.replace('/ico/create')}>
                            + New ICO
                        </button>
                    </div>
                    <Tabs defaultActiveKey="all" onChange={onChangeTabs} className="tab-ido-general c-tabs"
                        tabBarExtraContent={
                            <>
                                <div className='filter-block-form d-md'>
                                    <Select
                                        className="custom-slt"
                                        placeholder="Network..."
                                        style={{ width: 190, marginRight: '10px' }}
                                        onChange={handleChangeSltChain}
                                        options={[
                                            {
                                                value: '8668',
                                                label: 'HeLa',
                                            },
                                            {
                                                value: '56',
                                                label: 'BSC',
                                            },
                                            {
                                                value: '137',
                                                label: 'Polygon',
                                            },
                                            {
                                                value: '1',
                                                label: 'Ethereum',
                                            },
                                            {
                                                value: '42161',
                                                label: 'Arbitrum',
                                            }
                                        ]}
                                    />
                                    <button type="button" className="btn-basic w-fitcontent" onClick={()=> window.location.replace('/ico/create')}>
                                        + New ICO
                                    </button>
                                </div>
                            </>
                        }
                    >
                        <TabPane tab="All" key="all">
                            <IcoContent />
                        </TabPane>
                        <TabPane tab="Upcoming" key="upcoming">
                            <IcoContent />
                        </TabPane>
                        <TabPane tab="Opening" key="opening">
                            <IcoContent />
                        </TabPane>
                        <TabPane tab="Filled" key="filled">
                            <IcoContent />
                        </TabPane>
                        <TabPane tab="Closed" key="closed">
                            <IcoContent />
                        </TabPane>
                        <TabPane tab="My Project" key="my-project">
                            <IcoContent />
                        </TabPane>
                    </Tabs>

                    {totalRow > 9 && (
                        <Pagination
                            current={param.page + 1}
                            defaultCurrent={1}
                            total={totalRow}
                            pageSize={param.size}
                            onChange={onChange_Pagi}
                            showSizeChanger={false}
                            className="pagiation-custom"
                        />
                    )}
                </div>
            </div>
        </>
    )
}

export default IcoProject
