
import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Row, Col, Progress } from 'antd';
import { RightSquareOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from '../../../../hooks';
import web3 from 'web3';




const MarketItem = (props) => {
    const { item, key } = props;
    const { chainId, account, library } = useActiveWeb3React();

    let dateTime = new Date(item?.deploy_time * 1000).toLocaleDateString('en-US', {
        minute: '2-digit',
        hour: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour12: false
    })
    let percent = (Number(item?.minted)/Number(item?.total_supply) * 100).toFixed(1)
    return (
        <div className='mk-item' key={item ? item?.id : key}>

            <Row gutter={20}>
                <Col className="gutter-row" xs={12} xl={3} md={3} sm={3}>
                    <div className='table-mobile text-black-green'>Token</div>
                    <div className='text-darkblue text-16 text-weight-500'>{item?.tick}</div>
                </Col>
                <Col className="gutter-row" xs={12} xl={6} md={6} sm={6}>
                    <div className='table-mobile text-black-green'>Deploy Time</div>
                    <span className='text-darkblue text-16 text-weight-500'>{dateTime}</span>
                </Col>
                <Col className="gutter-row" xs={24} xl={6} md={6} sm={6}>
                    <div className='table-mobile text-black-green'>Progress</div>
                    <div className='mt-0'>
                        <Progress
                            strokeColor={{
                                '0%': 'rgba(9, 169, 203, 0.00)',
                                '100%': '#19CA89',
                            }}
                            percent={percent}
                        />
                    </div>
                </Col>
                <Col className="gutter-row" xs={12} xl={4} md={4} sm={4}>
                    <div className='table-mobile text-black-green'>Holders</div>
                    <span className='text-darkblue text-16 text-weight-500'>0</span>
                </Col>
                <Col className="gutter-row" xs={12} xl={5} md={5} sm={5}>
                    <div className='table-mobile text-black-green'>Transactions</div>
                    <div className='d-flex d-flex-btw'>
                        <span className='text-darkblue text-16 text-weight-500'>0</span>
                        <div className='text-darkblue text-weight-600 go-detail'>
                            <Link to={`/helascription-token/${item?.id}`}><RightSquareOutlined /></Link>
                        </div>
                    </div>
                </Col>
            </Row>

            
        </div>
    )
}

export default MarketItem
