import { Empty } from "antd";
import React, { useEffect, useState } from "react";
import { NftApi } from "../../../../config/api/apiNFT";

import { useActiveWeb3React } from "../../../../hooks";
import ProfileOfferItem from "./ProfileOfferItem";


const ProfileOfferHistory = (props) => {
    const { account, chainId } = useActiveWeb3React();
    const { data } = props;
    const [logs, setLogs] = useState([]);

    const getOfferLogs = async (account) => {
        let rs = await NftApi.getOfferLogsByAddress(account);
        if(rs.status === 200){
            setLogs(rs.data);
        }
    }

    useEffect(() => {
        if(account){
            getOfferLogs(account)
        }
    }, [account]);


    return (
        <>
            {logs.length > 0 ? (
                <>
                    <div className="main-table">
                        <table className="table-mint">
                            <thead>
                                <tr>
                                    <th>Price</th>
                                    <th>USD Price</th>
                                    <th>Expiration</th>
                                    <th>From </th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {logs.length > 0 && logs.map((item, index) => {
                                    return(
                                        <><ProfileOfferItem data={data} item={item} index={index} /></>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            ):(
                <>
                    <div className="no-data text-center">
                        <img width={200} src="../images/nodata.svg" />
                        <p className="text-black-green text-weight-500">You don't have any offer</p>
                        <button type="button" className="btn-basic w-fitcontent" onClick={()=> window.location.replace('/mint-nft')}>
                            Mint Now
                        </button>
                    </div>
                </>
            )}
            
        </>
    )
}
export default ProfileOfferHistory
