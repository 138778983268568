import http from "./http-common";

const getGameHubByStatus = (payload, page) => {
  return http.get(`/game-markets/by-${payload}?page=${page}&size=6`);
}

const getGameHubByHandle = (handle) => {
  return http.get(`/game-markets/${handle}`);
} 

const searchGameHub = (handle, page) => { 
  return http.get(`/game-markets/search/${handle}?page=${page}&size=6`);
} 
const getCatalogs = () => {
  return http.get(`/catalogs`);
}

const getCatalogHandle = (handle) => {
  return http.get(`/catalogs/${handle}`);
} 

const getCatalogsByID = (handle, page) => {
  return http.get(`/game-markets/by-catalog/${handle}?page=${page}&size=6`);
} 

const AirdropService = {
  getGameHubByStatus,
  getGameHubByHandle, 
  searchGameHub,
  getCatalogs,
  getCatalogsByID,
  getCatalogHandle
};
export default AirdropService;