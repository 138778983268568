import React, { useCallback, useEffect, useState } from "react";
import { Alert, Breadcrumb, Card, Col, Empty, Form, Input, Modal, Progress, Row, Spin } from 'antd';

import { Link, useLocation , useNavigate, useParams } from "react-router-dom";
import FooterHome from '../../../common/footer/footerHome';
import { useActiveWeb3React } from "../../../../hooks";
import Web3 from "web3";
import { RightSquareOutlined } from '@ant-design/icons';

import { MAPPING_CHAINID_DECIMAL } from "../../../../constants";

import ChainNotSupport from "../../../chain-not-support";
import { HelascriptionApi } from "../../../../config/api/apiHelascription";
import { Store } from "react-notifications-component";
import addNotify from "../../../common/Notify/addNotify";
import { Loader } from "../../../common/component";

const TokenDetail = () => {
    const { account, chainId } = useActiveWeb3React();
    const web3 = new Web3(window.ethereum);

    const [helascription, setHelascription] = useState();
    const [loadingDeloy, setLoadingDeloy] = useState(false);
  const navigate = useNavigate();

    let { id } = useParams();

    const getDetailToken = async () => {
        let res = await HelascriptionApi.getDetailToken(id);

        if (res.status === 200) {
            if (res.data.data) {
                setHelascription(res.data.data);
            } else {
                setHelascription([]);
            }
        }
    }

    useEffect(() => {
        if(id){
            getDetailToken()
        }
    }, [id]);



    if(chainId && MAPPING_CHAINID_DECIMAL.helatestnet !== chainId){
        return(
            <><ChainNotSupport /></>
        )
    }

    let percent = (Number(helascription?.minted)/Number(helascription?.total_supply) * 100).toFixed(1);
    let dateTime = new Date(helascription?.deploy_time * 1000).toLocaleDateString('en-US', {
        minute: '2-digit',
        hour: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour12: false
    });

    const mintToken = async()=>{
        setLoadingDeloy(true);

        let data = {
            "p":"hrc-20",
            "op":"mint",
            "tick": helascription?.tick,
            "amount": helascription?.limit_per_mint
        }

        const dataToSend = web3.utils.asciiToHex(`data:,${JSON.stringify(data)}`); // Convert 'xin chào' string to hex

        await web3.eth.sendTransaction({
            from:account,
            to: account,
            value: '0',
            data: dataToSend
        }).then((res) => {
            if(res && res.transactionHash){
                Store.addNotification(addNotify('Mint Successfully.', 'success'));
                setLoadingDeloy(false);
                getDetailToken();
                setTimeout(() => {
                    navigate('/helascription-personal')
                }, 1500);
            }
        }).catch((error) => {
            setLoadingDeloy(false);
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        });
    }
    
    return (
        <>
            <div className="helascription-totken-detail-page">
                <div className="container">
                    <Breadcrumb className='head-breadcrumb-airdrop'>
                        <Breadcrumb.Item><a href='/'>Helascription</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Tokens</Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="helascription-totken-detail">
                        
                        <div className="mt-15">
                            <Card className='card-airdrop' size='default' title={'Overview'} extra={
									<>
										{account && Number(helascription?.minted) < Number(helascription?.total_supply) && (
											<>
												<button className="btn-basic w-fitcontent" disabled={loadingDeloy} onClick={mintToken}>
													Mint Directly <RightSquareOutlined />
												</button>
											</>
										)}
									</>
								}>
                                    <Progress
                                        strokeColor={{
                                            '0%': 'rgba(9, 169, 203, 0.00)',
                                            '100%': '#19CA89',
                                        }}
                                        percent={percent}
                                    />
									<div className='airdrop-line pt-0 mt-25'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>Helascription ID</span></Col>
											<Col className='gutter-row text-right' xs={12}>
												<div className='text-regular-blue'>
													<span className='text-info'>
														{helascription?.inscriptions_id.substring(0, 8)}...{helascription?.inscriptions_id.substring(helascription?.inscriptions_id.length - 8)}
													</span>
													
												</div>
											</Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>Total Supply</span></Col>
											<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{Number(helascription?.total_supply).toLocaleString()}</span></Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>Minted</span></Col>
											<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{Number(helascription?.minted).toLocaleString()}</span></Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>Limit Per Mint</span></Col>
											<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{Number(helascription?.limit_per_mint).toLocaleString()}</span></Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>Decimal</span></Col>
											<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{helascription?.decimal}</span></Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>Deploy By</span></Col>
											<Col className='gutter-row text-right' xs={12}>
                                                <span className='text-regular-blue'>
                                                    {helascription?.creator.substring(0, 8)}...{helascription?.creator.substring(helascription?.creator.length - 8)}
                                                </span>
                                            </Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>Deploy Time</span></Col>
											<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{dateTime}</span></Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>Holders</span></Col>
											<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{helascription?.holders}</span></Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>Total transactions</span></Col>
											<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{helascription?.total_transactions}</span></Col>
										</Row>
									</div>

									
								</Card>
                        </div>
                    </div>
                </div>
            </div>
            {loadingDeloy && (<Loader />)}
            
            <FooterHome />
        </>
    )
}
export default TokenDetail
