import http from "./http-common";

const getAuthToken = (payload) => {
  return http.post("/auth-joker", payload);
};

const getAuthKey= (payload) => {
    return http.post("/positions", payload);
};

const AuthService = {
    getAuthToken,
    getAuthKey
};
export default AuthService;