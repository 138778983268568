import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import useWindowSize from '../../hooks/useWindowSize';
import { Layout, Row, Col } from 'antd';
import { TypeAnimation } from "react-type-animation";

const HomeBanner = () => {

    return(
        <>
            <div className='intro-banner'>
                <video id="video-container" loop autoPlay={true} muted>
                    <source src="../../images/video_banner/upswap-intro.mp4?ver=11" type="video/mp4" />
                </video>
            </div>
            <div className="home-banner" data-aos="zoom-in" data-aos-duration="1200" data-aos-offset="200">
                <div className='container'>
                    <Row gutter={30}>
                        <Col xs={24} sm={7}>
                            <div className='banner-block-left'>
                                <img src='../images/block-icon-left.png' alt='' />
                            </div>
                        </Col>
                        <Col xs={24} sm={10}>
                            <div className='banner-title'>
                                <TypeAnimation
                                    sequence={[
                                        // Same substring at the start will only be typed out once, initially
                                        "Play. Earn",
                                        1000,
                                        "Play. Earn. Buidl with UpSwap",
                                        2000, // wait 1s before replacing "Mice" with "Hamsters"
                                        "Play. Earn. Buidl ",
                                        500, // wait 1s before replacing "Mice" with "Hamsters"
                                        "Play. Earn. Buidl with UpSwap",
                                        2000, // wait 1s before replacing "Mice" with "Hamsters"

                                    ]}
                                    wrapper="div"
                                    speed={50}
                                    repeat={Infinity}
                                />

                                {/* The financical revolution <br></br> on the Hela Chain */}
                            </div>
                            <div className='banner-desciption'>
                                UpSwap - the first native launchpad and One-stop-service platform on HeLa chain!
                            </div>
                            <div className='banner-btn-list'>
                                <a href='/launchpad/opening'>See Live Projects</a>
                                <a href='https://docs.upswap.finance/' target="_blank">Read Docs</a>
                            </div>
                        </Col>
                        <Col xs={24} sm={7}>
                            <div className='banner-block-right'>
                                <img src='../images/block-icon-right.png' alt='' />
                            </div>
                        </Col>
                    </Row>
                    
                    
                    
                    
                    <div className='token-list'>
                        {/* <Helmet>
                            <script src="https://widgets.coingecko.com/coingecko-coin-price-marquee-widget.js"></script>
                            <script>
                                {`
                                    $(function () {
                                        let price = "<coingecko-coin-price-marquee-widget coin-ids='bitcoin,ethereum,binancecoin,ripple,litecoin' currency='usd' background-color='#fff' font-color='#2f70f4' locale='en'></coingecko - coin - price - marquee - widget>"
                                        $('#token-price-lits').html(price);
                                    })
                                `}
                            </script>
                        </Helmet>
                        <div id="token-price-lits"></div> */}
                    </div>
                    <div className='banner-logo'>
                        <div className='banner-logo-img'>
                            <img alt='' src='../images/upswap-banner-logo.svg' />
                        </div>
                    </div>
                    {/* <div className='banner-caculator'>
                        <div className='container'>
                            <Row gutter={30}>
                                <Col xs={12} sm={6} xl={6} md={6}>
                                    <div className='caculator-item bg-caculator-1'>
                                        <div className='caculator-num'>TBA</div>
                                        <div className='caculator-title'>Total Value Locked</div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} xl={6} md={6}>
                                    <div className='caculator-item bg-caculator-2'>
                                        <div className='caculator-num'>TBA</div>
                                        <div className='caculator-title'>Total IDO Raised</div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} xl={6} md={6}>
                                    <div className='caculator-item bg-caculator-3'>
                                        <div className='caculator-num'>TBA</div>
                                        <div className='caculator-title'>Total Volume</div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={6} xl={6} md={6}>
                                    <div className='caculator-item bg-caculator-4'>
                                        <div className='caculator-num'>TBA</div>
                                        <div className='caculator-title'>24H Volume</div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div> */}
                </div>
            </div>
            
        </>
    )
}

export default HomeBanner;
