import React, {useCallback} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {useSelector} from 'react-redux';
import { formatShortDateEnUs } from '../../utils/formatDatetime';
import TimeCountdown from '../common/countdown';
import { formatData } from '../../utils/StringHelper';
import { formatNumberCurrency } from '../../utils/formatNumberCurrency';
import { CopyOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Store } from 'react-notifications-component';
import { getNetworkObject } from '../../utils/campaignUltils';
import addNotify from '../common/Notify/addNotify';
// import { useNavigate } from "react-router-dom";

const ItemIDO = ({item, campaignPrices}) => {
    let location = useLocation();

    const { 
        title,
        status,
        network,
        claimChain,
        totalRaise,
        acceptCurrency,
        idoRate,
        bannerURL,
        logoUrl,
        tokenInform,
        startIDO,
        rounds
    } = item; 
    let handleStatus = status.toLowerCase(); 

    const { symbol, tokenId } = tokenInform;
    const startIDODate = new Date(startIDO);

    
    const priceTokenArray = campaignPrices && campaignPrices.filter(function (itemPrice) {
        return itemPrice.id === tokenId;
    });

    let currentPrice = priceTokenArray && priceTokenArray[0] && priceTokenArray[0].current_price && Number(priceTokenArray[0].current_price.toFixed(5));
    
    const conversionRateNumber = Number(idoRate);
    let roiToken = (conversionRateNumber !== 0 && currentPrice > conversionRateNumber) ? (+currentPrice / conversionRateNumber)*100 : (currentPrice ? (-conversionRateNumber / currentPrice*100) : 0);
    roiToken = roiToken ? roiToken.toFixed(0) : '';

    rounds && rounds.sort(function(a,b){
        return a.id - b.id;
    });

    const roundRaiseRenderer = rounds && rounds.map((item,key) => {
        return (
            <p className="c-list__row" key={key}>
                <span>{item.round}:</span>
                <span>{formatData(item.totalRaise , acceptCurrency)}</span>
            </p>
        );
    });

    const networkChain = getNetworkObject(network);
    const networkClaimChain = getNetworkObject(claimChain);

    if(location.pathname==='/launchpad/completed') {
        return(
            <Link to={item.handle}>
                <div className="c-list__item">
                    <div className="c-list__logo">
                        <div className="c-list__title">
                            <div className='pro-banner'><img src={logoUrl} alt={title} /></div>
                            <div className='pro-title'>{title}</div>
                        </div>
                    </div>
                    <div className="c-list__content">
                        <p className="c-list__row">
                            <span>IDO Date</span>
                            <span>{startIDO && formatShortDateEnUs(startIDODate)}</span>
                        </p>
                        <p className="c-list__row">
                            <span>Token</span>
                            <span>{symbol}</span>
                        </p>
                        <p className="c-list__row">
                            <span>IDO Rate</span>
                            <span>{formatData(idoRate, acceptCurrency)}</span>
                        </p>
                        <p className="c-list__row">
                            <span>Current Price</span>
                            <span>{formatData(currentPrice, acceptCurrency)}</span>
                        </p>
                        <p className="c-list__row">
                            <span>ROI</span>
                            {roiToken && currentPrice !== 0 ? <span className={`${roiToken >= 0 ? 'up' : 'down'}`}>{formatNumberCurrency(Math.abs(roiToken))}%</span> : <span className="">--</span>}
                        </p>
                        <p className="c-list__row">
                            <span>Network</span>
                            <span>
                                <img src={networkChain.logo} alt={networkChain.name} />
                                {networkChain.name}
                            </span>
                        </p>
                    </div>
                </div>
            </Link>
        );
    }
    return(
        <div className="c-list__item">
            <div className="c-list__img">
                <Link to={`/launchpad/${handleStatus.toLowerCase(0)}/${item.handle}`}>
                    <img src={bannerURL} alt={title} />
                    {item?.modelType === 1
                        ? <div className={`c-list__status protected`} style={{textTransform: 'capitalize'}}>
                            {/* <img src='../images/protected.svg'></img> */}
                            Riskless
                        </div>
                        : <div className={`c-list__status`} style={{textTransform: 'capitalize'}}>Shielded</div>
                    }
                </Link>
            </div>
            <div className="c-list__body">
                <h2 className="c-list__head">
                    {title} 
                    <span>{item?.tokenInform?.symbol} / {acceptCurrency}</span>
                    {/* <span><img src={logoUrl} alt={title} /></span> */}
                </h2>
                <p className="c-list__row">
                    <span>Join Network:</span>
                    <span>
                        <img src={networkChain.logo} alt={networkChain.name} />
                        {networkChain.name}
                    </span>
                </p>
                <p className="c-list__row">
                    <span>Claim Network:</span>
                    <span>
                        <img src={networkClaimChain.logo} alt={networkClaimChain.name} />
                        {networkClaimChain.name}
                    </span>
                </p>
                <div className='p-15 bg-darkblue mt-mobile-15'>
                    <p className="c-list__row mt-mobile-0">
                        <span>Total Raise:</span>
                        <span>{formatData(totalRaise, acceptCurrency)}</span>
                    </p>
                    <p className="c-list__row">
                        <span>IDO Rate:</span>
                        <span>{formatData(idoRate, acceptCurrency)}</span>
                    </p>
                </div>
                
                <p className="c-list__row mt-15">
                    <span>Community:</span>
                    <span>
                        <ul className="c-infoIdo__social">
                            {item?.telegramLink && <li><a href={item?.telegramLink} target="_blank"><img src="../../images/icon-tele.svg" alt="Telegram" /></a></li>}
                            {item?.twitterLink && <li><a href={item?.twitterLink} target="_blank"><img src="../../images/icon-tw.svg" alt="Twitter" /></a></li>}
                            {item?.mediumLink && <li><a href={item?.mediumLink} target="_blank"><img src="../../images/icon-m.svg" alt="M" /></a></li>}
                            {item?.website && <li><a href={item?.website} target="_blank"><img src="../../images/icon-web.svg" alt="Website" /></a></li>}
                            {item?.youtubeLink && <li><a href={item?.youtubeLink} target="_blank"><img src="../../images/icon-youtube.svg" alt="Youtube" /></a></li>}
                            {item?.discordLink && <li><a href={item?.discordLink} target="_blank"><img src="../../images/icon-discord.svg" alt="Discord" /></a></li>}
                            {item?.facebookLink && <li><a href={item?.facebookLink} target="_blank"><img src="../../images/icon-facebook.svg" alt="Facebook" /></a></li>}
                        </ul>
                    </span>
                </p>
                <p className="c-list__row">
                    <span>Contract:</span>
                    <span>
                        {item?.tokenInform?.tokenAddress ? `${item?.tokenInform?.tokenAddress.substring(0, 6)}...${item?.tokenInform?.tokenAddress.substring(item?.tokenInform?.tokenAddress.length - 6)}` : ''}
                        <CopyToClipboard
                            text={item?.tokenInform?.tokenAddress ? item?.tokenInform?.tokenAddress : ""}
                            onCopy={() => Store.addNotification(addNotify('Copied', 'success'))}
                        >
                            <CopyOutlined className='btn-copy' />
                        </CopyToClipboard>
                    </span>
                </p>
                <p className="c-list__row">
                    <TimeCountdown item={item} />
                </p>
                <Link className='link-detail' to={`/launchpad/${handleStatus.toLowerCase(0)}/${item.handle}`}>About Project</Link>
            </div>
            {/* <div className="c-list__footer">
                
                <p>
                    <TimeCountdown item={item} />
                </p>
                <Link to={`/launchpad/${handleStatus.toLowerCase(0)}/${item.handle}`}>Detail</Link>
            </div> */}
        </div>
    );
}

export default ItemIDO;
