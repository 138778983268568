import axios from 'axios';
import axiosInterceptor from './axiosInterceptor';

const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-type": "application/json"
    }
});

axiosInterceptor.setupInterceptors(http);
export default http;