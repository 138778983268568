import { Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

const FooterHome = () => {
    return(
        <div className="c-footerHome">
            <div className='container'>
                <div className="c-footerHome__link">
                    <div className="c-footerHome__row w-2">
                        <h1 className="c-sidebar__logo footer">
                            <a href={'/'}><img src="../../../../images/up-swap-logon.svg" alt="logo" className='logo-header' /></a> 
                        </h1> 
                        {/* <span className='c-footerHome_sologun'>The financical revolution on the Hela Chain</span> */}
                        {/* <div className='footer-token'>
                            <div className='token-add'>
                                <Row gutter={{ xs: 10 }}>
                                    <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                        <Row gutter={{ xs: 12}} className="align-center">
                                            <Col className="gutter-row" xs={6} xl={8} md={8} sm={8}>
                                                <img width={45} src="../../../../images/up-favi.svg" alt=''/>
                                            </Col>
                                            <Col className="gutter-row" xs={18} xl={16} md={16} sm={16}>
                                                <div className='token-name-footer'>HLUP Token</div>
                                                <div className='token-price-footer'>$___</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="gutter-row text-right" xs={12} xl={12} md={12} sm={12}>
                                        <button className='btn-basic w-fitcontent'>Add to wallet</button>
                                    </Col>
                                </Row>
                            </div>
                            <div className='token-row'>
                                <span>Max Supply:</span>
                                <span>TBA</span>
                            </div>
                            <div className='token-row'>
                                <span>Total supply:</span>
                                <span>TBA</span>
                            </div>
                            <div className='token-row'>
                                <span>Circulating supply:</span>
                                <span>TBA</span>
                            </div>
                            <div className='token-row'>
                                <span>Total Burned:</span>
                                <span>TBA</span>
                            </div>
                            
                            <div className='token-row'>
                                <span>Market Cap:</span>
                                <span>TBA</span>
                            </div>
                            
                        </div> */}

                        <ul className='footer-social'>
                            <li><span>Community</span></li>
                            <li><a href="mailto:business@upswap.finance" target="_blank"><img src='../../../images/social/email.svg' alt='business@upswap.finance'/></a></li>
                            <li><a href="https://t.me/upswap_official" target="_blank"><img src='../../../images/social/tele.svg' alt='https://t.me/upswap_official'/></a></li>
                            <li><a href="https://twitter.com/UpSwap_Finance" target="_blank"><img src='../../../images/social/twitter.svg' alt='https://twitter.com/UpSwap_Finance'/></a></li>
                            <li><a href="https://discord.com/invite/VjVfQWqMGP" target="_blank"><img src='../../../images/social/discord.svg' alt='#'/></a></li>
                        </ul>
                    </div>
                    <div className="c-footerHome__row">
                        <p>Features</p>
                        <ul>
                            <li><a href="/launchpad/upcoming" rel="noopener noreferrer">IDO Launchpad</a></li>
                            <li><a href="/ino/upcoming" rel="noopener noreferrer">INO Launchpad</a></li>
                            {/* <li><a href="#" rel="noopener noreferrer">Farms</a></li> */}
                            {/* <li><a href="/stake-fixible" rel="noopener noreferrer">Staking</a></li> */}
                            <li><a href="/ico" rel="noopener noreferrer">ICO Launchpad</a></li>
                            <li><a href="/genesis-nft" rel="noopener noreferrer">NFT Marketplace</a></li>
                        </ul>
                    </div>
                    <div className="c-footerHome__row">
                        <p>About Us</p>
                        <ul>
                            <li><a href="https://docs.upswap.finance" target="_blank" rel="noopener noreferrer">Docs</a></li>
                            {/* <li><a href='https://medium.com/upswap-finance' target="_blank" rel="noopener noreferrer">Blog</a></li> */}
                            {/* <li><a href="https://docs.upswap.finance/tokenomics/token-distribution" target="_blank" rel="noopener noreferrer">Tokenomic</a></li> */}
                            {/* <li><a href="#" target="_blank" rel="noopener noreferrer">Buy $HLUP</a></li> */}
                        </ul>
                    </div>
                    <div className="c-footerHome__row">
                        <p>Services</p>
                        <ul>
                            <li><a href="https://forms.gle/GMQWEysU4jpvFvwY9" target="_blank" rel="noopener noreferrer">Apply for IDO</a></li>
                            <li><a href="https://forms.gle/nqdWv5i98pq89wrr9" target="_blank" rel="noopener noreferrer">Apply for INO</a></li>
                            <li><a href="https://forms.gle/cx5dwEVp5b1KkeXj9" target="_blank" rel="noopener noreferrer">Apply for Staking</a></li>
                            <li><a href="https://forms.gle/sSnuP5Z4cgZyof7i7" target="_blank" rel="noopener noreferrer">Apply for Farming</a></li>
                        </ul>
                    </div>
                    <div className="c-footerHome__row">
                        <p>Legal</p>
                        <ul>
                            <li><Link to="/terms" rel="noopener noreferrer">Terms of Use</Link></li>
                            <li><Link to="/privacy" rel="noopener noreferrer">Privacy Policy</Link></li>
                            <li><Link to="/cookies" rel="noopener noreferrer">Cookies</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <p className="c-footerHome__copy">© 2023 UpSwap. All rights reserved.</p>
        </div>
    )
}

export default FooterHome;
