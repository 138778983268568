import { instance } from '../../apiBase/instance';
let tokenID = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImF1dGgiOiJST0xFX0FETUlOLFJPTEVfVVNFUiIsImV4cCI6MTY1MTk0MDI4MX0.OGkSOjRJjH5519O4K94Oiw7Qavs-FpYFryb8O_5JOoKbJxmw6-Ho3Zo5oHj_HidbZ42oNl3hdNMlzk6qvGBp0g';
export const IdoLaunchpad = {

	getChainNetworkList(params) {
		return instance.get(`chain-lists/client`, { params });
	},
	getSettings() {
		return instance.get(`settings`, {});
	},
	getSettingsByChain(chainId) {
		return instance.get(`settings/chain-id/${chainId}`, {});
	},
	getCexList(params) {
		return instance.get(`cex-lists/client`, { params });
	},
	getLaunchpads(data) {
		let params = {
			page: data.page,
			size: data.size,
			sort: data.sort
		}
		return instance.post(`list-launchpad`, data, { params });
	},
	getRoundIdoList(data) {
		let params = {
			page: data.page,
			size: data.size,
			sort: data.sort
		}
		return instance.post(`round-ido/list`, data, { params });
	},
	getRoundIdoCalendar(data) {
		let params = {
			page: data.page,
			size: data.size,
			sort: data.sort
		}
		return instance.post(`round-ido/calendar`, data, { params });
	},
	getLaunchpadDetailByHandle(handle, address) {
		return instance.get(`launchpad-ido/${handle}/details?ownerAddress=${address ? address : ''}`);
	},
	getLaunchpadByAddress(account, params) {
		return instance.get(`launchpad/${account}/address`, { params });
	},
	getWhitelistIdoByAddress(idoId, account) {
		return instance.get(`round-ido/${idoId}/address/${account}/white-list-ido`);
	},
	getAllocationByAddress(idoId, account) {
		return instance.get(`round-ido/${idoId}/claim-allocation/${account}`);
	},
	
	genLaunchpadById(id) {
		return instance.get(`launchpad/${id}`);
	},
	checkWhiteListById(roundIdoId, address) {
		return instance.get(`/round-ido/${roundIdoId}/my-address/${address}`);
	},
	getWhitelistByRound(roundId, params) {
		return instance.get(`round/${roundId}/white-lists`);
	},
	createIdoLaunchpad(data) {
		return instance.post(`create-launchpad`, data, {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	updateStatusIdoPool(roundId, data) {
		return instance.put(`rounds/${roundId}/action`, data, {
			headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	updateHashAfterJoinPool(tokenID, data) {
		return instance.put(`white-lists-ido/update-wl/client`, data, {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	updateIdoWhitelist(roundId, data) {
		return instance.put(`rounds/${roundId}/enable-whitelist`, data, {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	addWhiteList(data) {
		return instance.post(`white-lists/add`, data, {
			// headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	removeWhiteList(data) {
		return instance.delete(`white-lists/remove`, { data });
	},
	verifyWhitelist(roundId, account) {
		return instance.get(`round/${roundId}/address/${account}/verify-white-list`);
	},
	// private api

	createPrivate(data) {
		console.log(data);
		return instance.post(`private-sale`, data, {});
	},
};
