import React, {useEffect, useState} from 'react';
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import {useDispatch} from 'react-redux'; 
import allActions from '../../actions';
import ReactPlayer from 'react-player';
import axios from "axios";
import { Loader } from '../common/component';

const GameHubDetail = () => {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [dataDetail, setDataDetail] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingVideo, setLoadingVideo] = useState(true);
    const [idoPrice, setIdoPrice] = useState({})
    const dispatch = useDispatch();
    const { handle } = useParams();
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: true,
    };
    useEffect(() => {
        setLoading(true);
        dispatch(allActions.gamehubAction.getGameHubDetail(handle, async(response) => {
            if(response) {
                setDataDetail(response);
                setLoading(false);
            }
        }));
    }, [handle]);

    useEffect(() => {
        axios
            .get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids='+dataDetail?.tokenId)
            .then((res) => {
                setIdoPrice(res.data[0])
            })
    }, [dataDetail?.tokenId]);

    const percent24h = idoPrice?.atl_change_percentage;

    return(
        <>
        {loading && <Loader />}
        <div className="p-gameHubDetail">
            <div className="p-gameHubDetail__left">
                <Link to="/gamehub" className='btn-back'>
                    <img src="../images/arrow-square-left.png" alt="" />
                    <span className='text-back'>Game market</span>
                </Link>
                <div className="p-gameHubDetail__header">
                    <div className="title">
                        <span className='text-banner'>{dataDetail?.title} <img className='check' src="../images/check.png"></img></span> 
                    </div> 
                    <a href={dataDetail?.link} className='btn-play' target="_blank">
                        <span className='text-play'>Play game</span>
                    </a>
                </div>
                {dataDetail?.videoLink ? <div className="p-gameHubDetail__video">
                    <ReactPlayer onReady = {() => setLoadingVideo(false)} width="100%" height="100%" url={dataDetail?.videoLink} />
                    {loadingVideo && <div className="c-loadingVideo"><span>Loading</span></div>}
                </div> : <div className="p-gameHubDetail__banner">
                    <div className="c-sliderBanner">
                        <Slider asNavFor={nav2} ref={c => setNav1(c)} {...settings}>
                            {dataDetail?.gameImages && dataDetail?.gameImages.map((item, index) => {
                                return(
                                    <div key={index} className="c-sliderBanner__item">
                                        <img src={`${item.path}/${item.name}`} alt="" />
                                    </div>
                                )
                            })}                            
                        </Slider>
                    </div>
                    <div className="c-sliderNav">
                        <Slider
                            asNavFor={nav1}
                            ref={c => setNav2(c)}
                            slidesToShow={3}
                            swipeToSlide={true}
                            focusOnSelect={true}
                            arrows={true}
                            infinite={true}
                        >
                            {dataDetail?.gameImages && dataDetail?.gameImages.map((item, index) => {
                                return(
                                    <div className="c-sliderNav02__item">
                                        <img src={`${item.path}/${item.name}`} alt="" />
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>}                
            </div> 
            <div className="p-gameHubDetail__right">
                <div className='main-text'>
                    <span>Current Price (% Chg 24H)</span>
                    <img className='chart' src="../images/icon-chart.png"></img>
                </div>
                <div className='price-img'>
                    <div className='logo'><img src={idoPrice?.image} alt="" /> </div> 
                    <span className='price'>${parseFloat(idoPrice?.current_price).toFixed(6)}</span>
                    <span className={`percent ${percent24h < 0 ? 'down' : 'up'}`}>{percent24h}%</span>
                </div>
                <div className='tab-info'>
                    <div className='tab-info-item'>
                        <span className='left-text'>IDO Price</span>
                        <span className='right-text'>$ {dataDetail?.idoPrice}</span>
                    </div>
                    <div className='tab-info-item'>
                        <span className='left-text'>IDO ROI</span>
                        <span className='right-text'>{parseFloat(idoPrice?.ath/dataDetail?.idoPrice).toFixed(2)}x</span>
                    </div>
                    <div className='tab-info-item'>
                        <span className='left-text'>Volume (24h)</span>
                        <div className='right-volume'>
                            <span className='right-text'>${idoPrice?.total_volume}</span>
                            <span className={`percent-text ${percent24h < 0 ? 'down' : 'up'}`}>{percent24h}%</span>
                        </div> 
                    </div>
                    <div className='tab-info-item'>
                        <span className='left-text'>Developer</span>
                        <span className='right-text'>{dataDetail?.developerBy}</span>
                    </div>
                    <div className='tab-info-item'>
                        <span className='left-text'>Category</span>
                        <span className='right-text'>{dataDetail?.catalog.name}</span>
                    </div>
                    <div className='tab-info-item'>
                        <span className='left-text'>Community</span>
                        <ul className="media">
                            {dataDetail?.link && <li>
                                <a href={dataDetail?.link} target="_blank"><img src="../images/icon-web.png" alt="" /></a>
                            </li>}
                            {dataDetail?.socialNetwork.youtuberLink && <li>
                                <a href={dataDetail?.socialNetwork.youtuberLink} target="_blank"><img src="../images/icon-youtube.png" alt="" /></a>
                            </li>}
                            {dataDetail?.socialNetwork.facebookLink && <li>
                                <a href={dataDetail?.socialNetwork.facebookLink} target="_blank"><img src="../images/icon-facebook.png" alt="" /></a>
                            </li>}                            
                            {/* <li>
                                <a href="google.com" target="_blank"><img src="../images/icon-discord.png" alt="" /></a>
                            </li> */}
                            {dataDetail?.socialNetwork.telegramLink && <li>
                                <a href={dataDetail?.socialNetwork.telegramLink} target="_blank"><img src="../images/icon-tele.png" alt="" /></a>
                            </li>}
                            {dataDetail?.socialNetwork.twitterLink && <li>
                                <a href={dataDetail?.socialNetwork.twitterLink} target="_blank"><img src="../images/icon-tw.png" alt="" /></a>
                            </li>}                            
                            {/* <li>
                                <a href="google.com" target="_blank"><img src="../images/icon-medium.png" alt="" /></a>
                            </li> */}
                        </ul>
                    </div>
                </div> 
                <div className='aboutGame'>
                    <span className="header">About game</span>
                    <div className="description" dangerouslySetInnerHTML={{__html: dataDetail?.description}}></div>
                </div>
            </div> 
        </div>
        </>
    )
}

 
export default GameHubDetail;