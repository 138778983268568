import CampaignDataService from "../services/CampaignService";
import CoingeckoDataService from "../services/CoingeckoService";
import {
    GET_CAMPAIGNS_BY_STATUS,
    GET_CAMPAIGN_BY_HANDLE,
    GET_CAMPAIGNS_BY_COMMITED,
    GET_CAMPAIGNS_BY_CLAIMABLE,
    SET_CAMPAIGN_ACTIVE
} from "./campaignType";

const getPriceMarket = (data, onCallback) => async () => { 
  let tokenIdString = '';

  data.map((item, key) => {
    if (item.tokenInform && item.tokenInform.tokenId && !tokenIdString.includes(item.tokenInform.tokenId)) {
      tokenIdString += item.tokenInform.tokenId.replace(/\s/g, '-')  + ",";
    }
  });
  tokenIdString = tokenIdString.toLowerCase();

  tokenIdString = tokenIdString.substring(
    0,
    tokenIdString.length - 1
  );

  let resPrices;

  if (tokenIdString) {
    let paramsCoingecko = {
      vs_currency: "usd",
      ids: tokenIdString,
      order: "market_cap_desc",
      per_page: 100,
      page: 1,
      sparkline: false,
      price_change_percentage: "24h",
    };

    resPrices = await CoingeckoDataService.getMarketPrices(paramsCoingecko);
    if(resPrices && resPrices.status === 200) { 
        onCallback(resPrices);
    }
  } 
}

const getCampaignsByStatus = (payload, onCallback) => async (dispatch) => {
    try {
      const res = await CampaignDataService.getCampaignsByStatus(payload);
      
      dispatch({
        type: GET_CAMPAIGNS_BY_STATUS,
        payload: {
          campaignList: res && res.data || [],
          campaignsCount: res && res.headers['x-total-count'] || 0
        }
      });
      onCallback({callResult: true, campaignList: res && res.data || []});
    } catch (err) {
      onCallback({callResult: true});
      console.log(err);
    }
};
const getCampaignByHandle = (payload, onCallback) => async (dispatch) => {
  try {
    const res = await CampaignDataService.getCampaignByHandle(payload);
    if(res && res.data)  {
      dispatch({
        type: GET_CAMPAIGN_BY_HANDLE,
        payload: res && res.data
      });
      onCallback({callResult: true});
    } 
  } catch (err) {
    onCallback({callResult: true});
    console.log(err);
  }
}

const getCampaignsByCommited = (account, payload, onCallback) => async (dispatch) => {
  try {
    const res = await CampaignDataService.getCampaignsByCommited(account, payload);
    dispatch({
      type: GET_CAMPAIGNS_BY_COMMITED,
      payload: {
        campaigns: res && res.data || [],
        campaignsCount: res && res.headers['x-total-count'] || 0,
      }
    });
    onCallback({callResult: true});
  } catch (err) {
    onCallback({callResult: true});
    console.log(err);
  }
}

const getCampaignsByClaimable = (account, payload, onCallback) => async (dispatch) => {
  try {
    const res = await CampaignDataService.getCampaignsByClaimable(account, payload);
    dispatch({
      type: GET_CAMPAIGNS_BY_CLAIMABLE,
      payload: {
        campaigns: res && res.data || [],
        campaignsCount: res && res.headers['x-total-count'] || 0,
      }
    });
    onCallback({callResult: true});
  } catch (err) {
    onCallback({callResult: true});
    console.log(err);
  }
}

const setCampaignActive = (campaignId, roundId, account, isRefunded) => async (dispatch) => {
  dispatch({
    type: SET_CAMPAIGN_ACTIVE,
    payload: {
      campaignId, 
      roundId, 
      account, 
      isRefunded
    } || {}
  });
}

const campaignAction = {
    getCampaignsByStatus,
    getCampaignByHandle,
    getCampaignsByCommited,
    getCampaignsByClaimable,
    setCampaignActive,
    getPriceMarket
};

export default campaignAction;