import React, { useState, useRef, useEffect } from "react";
import { Input, Radio, message, InputNumber, Modal } from "antd";
import QRCode from "react-qr-code";
import web3 from "web3";
import { MAPPING_CHAINID_DECIMAL, status_difficulty } from "../../constants/index";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { notiError, notiSuccess, notiWarning } from "../../utils/notication";
import Loading from "../Loading";
import { useActiveWeb3React } from "../../hooks";
import { CHAINID_FULLNAME, MAPPING_CHAINID } from "../../constants";
import netLogoBSC from '../common/asset/images/binance.png';
import netLogoPOLY from '../common/asset/images/polygon.png';
import netLogoETH from '../common/asset/images/eth.png';
import netLogoARB from '../common/asset/images/arb.png';
import netLogoHELA from '../common/asset/images/helalogo.svg';
import networkChange from "../../utils/networkChange";
import { getInformationByChain } from "../../config/network/multichainAddresses";

const DetailGenerator = () => {
    const { account , chainId } = useActiveWeb3React();
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const Web3 = new web3(provider);
    const [valueCase, setValueCase] = useState(1);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [wallets, setWallets] = useState(null);
    const [countWallets, setCountWallets] = useState(0);
    const [vanity, setVanity] = useState("");
    const [valueMode, setValueMode] = useState(2);
    const [status, setStatus] = useState(false);
    const [statusDefault, setStatusDefault] = useState(true);
    const isMounted = useRef(true);

    const [isModalOpen, setIsModalOpen] = useState(false);

    let netName = '';
    let netLogo = '';
    let netActive = '';

    switch (chainId) {
        case 56:
            netName = CHAINID_FULLNAME.bsc;
            netLogo = netLogoBSC;
            netActive = MAPPING_CHAINID_DECIMAL.bsc;
            break
        case 137:
            netName = CHAINID_FULLNAME.poly;
            netLogo = netLogoPOLY;
            netActive = MAPPING_CHAINID_DECIMAL.poly;
            break
        case 1:
            netName = CHAINID_FULLNAME.eth;
            netLogo = netLogoETH;
            netActive = MAPPING_CHAINID_DECIMAL.eth;
            break
        case 42161:
            netName = CHAINID_FULLNAME.arb;
            netLogo = netLogoARB;
            netActive = MAPPING_CHAINID_DECIMAL.arb;
            break
        case 421613:
            netName = CHAINID_FULLNAME.arbtestnet;
            netLogo = netLogoARB;
            netActive = MAPPING_CHAINID_DECIMAL.arbtestnet;
            break
        case 666888:
            netName = CHAINID_FULLNAME.helatestnet;
            netLogo = netLogoHELA;
            netActive = MAPPING_CHAINID_DECIMAL.helatestnet;
            break
        default:
            netName = 'Chain...';
            netLogo = netLogoBSC;
            netActive = '';
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (isOnline) {
            showModal();
        }
    }, []);

    const checkNetworkStatus = () => {
        setIsOnline(navigator.onLine);
    };

    useEffect(() => {
        window.addEventListener("online", checkNetworkStatus);
        window.addEventListener("offline", checkNetworkStatus);

        return () => {
            window.removeEventListener("online", checkNetworkStatus);
            window.removeEventListener("offline", checkNetworkStatus);
        };
    }, []);

    const onChangeCase = (e) => {
        setValueCase(e.target.value);
    };
    const characterCount = vanity?.length;

    const onChangeMode = (e) => {
        setValueMode(e.target.value);
        setVanity("");
    };

    const createWallet = async () => {
        try {
            // if (!isOnline) {
            // if (true) {
            setStatus(true);
            setStatusDefault(!statusDefault);
            let count = 0;
            let wallet;
            const checkAddress = () => {
                wallet = Web3.eth.accounts.create();
                const address = wallet.address.toLowerCase();
                if (vanity && valueMode === 2) {
                    if (address.endsWith(vanity)) {
                        setWallets({
                            address: wallet.address,
                            privateKey: wallet.privateKey,
                            tries: count,
                        });
                        notiSuccess("New wallet generated successfully!", 5);

                        setStatus(false);
                        setStatusDefault(!statusDefault);
                    } else {
                        count++;
                        setCountWallets(count);
                        if (isMounted.current) {
                            setTimeout(checkAddress, 0); // Continue checking in next tick
                        }
                    }
                }

                if (vanity && valueMode === 1) {
                    if (address.slice(2).startsWith(vanity)) {
                        setWallets({
                            address: wallet.address,
                            privateKey: wallet.privateKey,
                            tries: count,
                        });
                        notiSuccess("New wallet generated successfully!", 5);
                        setStatus(false);
                        setStatusDefault(!statusDefault);
                    } else {
                        count++;
                        setCountWallets(count);
                        if (isMounted.current) {
                            setTimeout(checkAddress, 0); // Continue checking in next tick
                        }
                    }
                }
            };

            checkAddress();
            // } else {
            //   notiWarning("Please disconnect from the internet", 10);
            // }
        } catch (error) {
            console.error("Error creating wallet:", error);
            notiError(error.message, 5);
            setStatus(false);
            setStatusDefault(!statusDefault);
        }
    };

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);
    const renderText = () => {
        if (valueMode === 1) {
            return (
                <div className="text-eg">
                    Eg: 0x<span className="text-blue">{vanity}</span>
                    3D6C5Bb35051B666c659d29D97eA20b848
                </div>
            );
        }
        if (valueMode === 2) {
            return (
                <div className="text-eg">
                    Eg: 0xD9173D6C5Bb35051B666c659d29D97eA20b848
                    <span className="text-blue">{vanity}</span>
                </div>
            );
        }
    };

    const IconWarning = () => {
        return (
            <div className="gen-warning">
                <img src="../../../images/bot/IconW.png" alt="" />
                Warning
            </div>
        );
    };

    return (
        <>
            <div className="main-section">
                <div className="container">
                    <div className="section-detail-generator">
                        <div className="title">Vanity Address Generator</div>
                        <div className="item-form">
                            <div className="txt">Choice your network</div>
                            <div className="input-round">
                                <div className="choice-network-list">
                                    <button className={`network-choice ${chainId == MAPPING_CHAINID_DECIMAL.helatestnet ? 'network-active' : ''}`} onClick={() => networkChange(MAPPING_CHAINID.helatestnet)}><img src={netLogoHELA} alt="" />HelaTestnet</button>
                                    <button className={`network-choice ${chainId == MAPPING_CHAINID_DECIMAL.bsc ? 'network-active' : ''}`} onClick={() => networkChange(MAPPING_CHAINID.bsc)}><img src={netLogoBSC} alt=""/>BSC</button>
                                    <button className={`network-choice ${chainId == MAPPING_CHAINID_DECIMAL.poly ? 'network-active' : ''}`} onClick={() => networkChange(MAPPING_CHAINID.poly)}><img src={netLogoPOLY} alt=""/>Polygon</button>
                                    <button className={`network-choice ${chainId == MAPPING_CHAINID_DECIMAL.eth ? 'network-active' : ''}`} onClick={() => networkChange(MAPPING_CHAINID.eth)}><img src={netLogoETH} alt="" />Ethereum</button>
                                    <button className={`network-choice ${chainId == MAPPING_CHAINID_DECIMAL.arb ? 'network-active' : ''}`} onClick={() => networkChange(MAPPING_CHAINID.arb)}><img src={netLogoARB} alt="" />Arbitrum</button>
                                </div>
                            </div>
                        </div>
                        <div className="item-form mar-top-20">
                            <div className="txt">Enter your vanity number</div>
                            <div className="input-round">
                                <Input
                                    placeholder="Ex: 888"
                                    value={vanity}
                                    onChange={(e) => setVanity(e.target.value)}
                                    className="form-control w-100 set-input-number"
                                    style={{ width: "100%" }}
                                />
                            </div>

                            {vanity && renderText()}
                        </div>
                        <div className="item-form mar-top-20">
                            <div className="txt">Case Sensitive</div>
                            <Radio.Group onChange={onChangeCase} value={valueCase}>
                                <Radio value={1}>Yes</Radio>
                                <Radio value={2}>No</Radio>
                            </Radio.Group>
                        </div>
                        <div className="item-form mar-top-20">
                            <div className="txt">Mode</div>
                            <Radio.Group onChange={onChangeMode} value={valueMode}>
                                <Radio value={1}>Prefix</Radio>
                                <Radio value={2}>Suffix</Radio>
                            </Radio.Group>
                        </div>
                        <div className="item-form mar-top-20">
                            <div className="txt">Token Details</div>
                            <div className="box-detail-balance res">
                                <div className="item">
                                    <div className="row-name">
                                        <div className="name">Difficulty:</div>
                                        <div className="text">
                                            {status_difficulty(characterCount)}
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row-name">
                                        <div className="name">Generate:</div>
                                        <div className="text">{countWallets} Address</div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row-name">
                                        <div className="name">Probability:</div>
                                        <div className="text">
                                            {" "}
                                            {characterCount < 6 && valueMode !== 3 ? "100%" : "50%"}
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row-name">
                                        <div className="name">Status:</div>
                                        {statusDefault && !status ? (
                                            <div className="text res">Waiting</div>
                                        ) : (
                                            <div className="text res">
                                                {status ? "Waiting..." : "Success"}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="group-btn mar-top-30">
                            {account ? (
                                <>
                                    <button
                                        type="button"
                                        onClick={createWallet}
                                        className="btn-basic click-now w-200"
                                        disabled={status || !vanity || characterCount > 3}
                                    >
                                        <Loading
                                            status={status}
                                            content={<>Start Generator</>}
                                        />
                                    </button>
                                </>
                            ):(
                                <>
                                    <button
                                        type="button"
                                        className="btn-basic click-now w-200"
                                        disabled
                                    >
                                        <Loading
                                            status={status}
                                            content={<>Start Generator</>}
                                        />
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                    {wallets && (
                        <>
                            <div className="section-success-wallet">
                                <div className="box-img">
                                    <img src="../../images/bot/checkes-sc.png" alt="" />
                                </div>
                                <div className="title">Your wallet</div>
                                <div className="desc">
                                    Connected, you need to disconnect your Internet usage for
                                    security reasons.
                                </div>
                                <div className="box-qrcan">
                                    <div className="left">
                                        <QRCode value={wallets?.address} size={140} />
                                    </div>
                                    <div className="right">
                                        <div className="item">
                                            <div className="flex-l">
                                                <div className="txt-l">
                                                    <img src="../../images/bot/blue.png" alt="" />{" "}
                                                    Address:
                                                </div>
                                                <div className="txt-tight">{wallets?.address}</div>
                                            </div>
                                            <div className="flex-r">
                                                <button className="btn-copy">
                                                    <CopyToClipboard
                                                        text={`${wallets?.address}`}
                                                        onCopy={() => {
                                                            message.success("Copied", 0.4);
                                                        }}
                                                    >
                                                        <img src="../../images/bot/copy.png" alt="" />
                                                    </CopyToClipboard>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="flex-l">
                                                <div className="txt-l">
                                                    <img src="../../images/bot/yellow.png" alt="" />{" "}
                                                    Private Key:
                                                </div>
                                                <div className="txt-tight">{wallets?.privateKey}</div>
                                            </div>
                                            <div className="flex-r">
                                                <button className="btn-copy">
                                                    <CopyToClipboard
                                                        text={`${wallets?.privateKey}`}
                                                        onCopy={() => {
                                                            message.success("Copied", 0.4);
                                                        }}
                                                    >
                                                        <img src="../../images/bot/copy.png" alt="" />
                                                    </CopyToClipboard>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Modal
                style={{ top: 150 }}
                title={IconWarning()}
                className="modal-offline"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={false}
            >
                <p>You need to disconnect your Internet usage for security reasons.</p>
            </Modal>
        </>
    );
};
export default DetailGenerator;
