import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link, useParams } from 'react-router-dom';
// import 'antd/dist/antd.css';
import {
	Layout, Row, Col, Form, DatePicker, Button, Input, Spin, Alert, Table, Progress, Card, Modal, Radio, Space, Breadcrumb, Empty
} from 'antd';
import { CopyOutlined, DoubleRightOutlined, FacebookFilled, GithubOutlined, GlobalOutlined, InstagramOutlined, LeftCircleOutlined, RedditCircleFilled, TwitterCircleFilled } from '@ant-design/icons';

import { useActiveWeb3React } from '../../../hooks';
// import '../style.css';
import LoadingFull from '../../../components/loading-full';

import {
	cancelAirdrop,
	getEnsureExactAmount,
	getAirdropIsWLAdmin,
	removeAllAirdropAllocations,
	setAirdropAllocations,
	setAirdropEnableExactAmount,
	setAirdropVesting,
	startAirdrop,
	getAirdropTGEDate,
	getAirdropTGEBps,
	getAirdropCycle,
	getAirdropCycleBps,
	getAirdropState,
	getAirdropFundPercent,
	getAirdropTotalAllocationTokens,
	getAirdropUserClaimedTokens,
	getAirdropUserAllocationInfos,
	getAllAirdropAllocationCount,
	getAirdropAllocations,
	getTokenInformation,
	getAirdropClaimable, claimAirdrop, getAirdropOwner, getTokenAllowance, approveToken
} from '../../utils';
import airdropAbi from '../../../config/abi/airdropAbi.json';
import web3 from 'web3';

import { getInformationByChain } from '../../../config/network/multichainAddresses';
import { ethers } from 'ethers';
import { AirdropApi } from '../../../config/api/airdropApi';
import tokenInfoAbi from '../../../config/abi/tokenInfo.json';
import { convertDateTime } from '../../../utils/formatDatetime';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Store } from 'react-notifications-component';
import addNotify from '../../common/Notify/addNotify';
import FooterHome from '../../common/footer/footerHome';
import { Loader } from '../../common/component';
import { CHAINID_FULLNAME_BY_ID } from '../../../constants';
import Countdown from 'react-countdown';
import networkChangeByNumber from '../../../utils/networkChangeByNumber';

const { Content } = Layout;
const { TextArea } = Input;

const AirdropRewardDetail = () => {
	const { chainId, account, library } = useActiveWeb3React();
	
	let { handle, airdropAddress } = useParams();
	
	const [loading, setLoading] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	const [allocations, setAllocations] = useState([]);
	const [isFormAllocation, setIsFormAllocation] = useState(false);
	const [isFormVesting, setIsFormVesting] = useState(false);
	const [isStartForm, setIsStartForm] = useState(false);
	const [keyLoading, setKeyLoading] = useState(0);
	const [ensureExactAmount, setEnsureExactAmount] = useState(false);
	const [tgeDate, setTgeDate] = useState(null);
	const [tgeBps, setTgeBps] = useState(0);
	const [cycle, setCycle] = useState(0);
	const [cycleBps, setCycleBps] = useState(0);
	const [state, setState] = useState(0);
	const [fundPercent, setFundPercent] = useState(0);
	const [totalTokens, setTotalTokens] = useState(0);
	const [claimedTokens, setClaimedTokens] = useState(0);
	const [userAllocations, setUserAllocations] = useState(null);
	const [isAdmin, setIsAdmin] = useState(false);
	const [specificTime, setSpecificTime] = useState(false);
	const [decimals, setDecimals] = useState(18);
	const [dataAirdrop, setDataAirdrop] = useState(null);
	const [totalAllocations, setTotalAllocations] = useState(0);
	const [tokenAddress, setTokenAddress] = useState(null);
	const [claimable, setClaimable] = useState(null);
	const [isOwner, setIsOwner] = useState(false);
	const [allowance, setAllowance] = useState(0);
	const [startClaimSc, setStartClaimSc] = useState(0);
	const [progressPercent, setProgressPercent] = useState(0);
	const [totalToStartTokens, setTotalToStartTokens] = useState(0);
	const [startTime, setStartTime] = useState("");

	const [addWLForm] = Form.useForm();
	const [vestingForm] = Form.useForm();
	const [startForm] = Form.useForm();
	const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

	const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

	let nowDate = new Date(Date.now());
	let startDate = new Date(dataAirdrop?.startTime);
	let endDate = new Date(dataAirdrop?.endTime);

	let titleNetwork = CHAINID_FULLNAME_BY_ID[dataAirdrop?.chainId] ? CHAINID_FULLNAME_BY_ID[dataAirdrop?.chainId] : '';

	const getAirdropInfos = async () => {

		try {
			setLoadingData(true);
			const tokenInfoSC = await getTokenInformation(tokenInfoAbi, dataAirdrop.tokenAddress);
			setDecimals(tokenInfoSC.decimals);
			const claimable = await getAirdropClaimable(airdropAbi, airdropAddress, library, account);
			setClaimable(parseFloat(ethers.utils.formatUnits(claimable, tokenInfoSC.decimals)))

			// console.log(parseInt(claimable));
			let allowance = await getTokenAllowance(tokenInfoAbi, dataAirdrop.tokenAddress, account, airdropAddress)
			setAllowance(parseFloat(ethers.utils.formatUnits(allowance, decimals)))


			const exactAmountSC = await getEnsureExactAmount(airdropAbi, airdropAddress, library, account);
			const wlAdminSC = await getAirdropIsWLAdmin(airdropAbi, airdropAddress, library, account);
			const tgeDateSC = await getAirdropTGEDate(airdropAbi, airdropAddress, library, account);
			const tgeBpsSC = await getAirdropTGEBps(airdropAbi, airdropAddress, library, account);
			const cycleSC = await getAirdropCycle(airdropAbi, airdropAddress, library, account);
			const cycleBpsSC = await getAirdropCycleBps(airdropAbi, airdropAddress, library, account);
			const stateSC = await getAirdropState(airdropAbi, airdropAddress, library, account);
			const fundPercentSc = await getAirdropFundPercent(airdropAbi, airdropAddress, library, account);

			// console.log(parseInt(fundPercentSc));

			const totalTokensSC = await getAirdropTotalAllocationTokens(airdropAbi, airdropAddress, library, account);
			const claimedTokensSC = await getAirdropUserClaimedTokens(airdropAbi, airdropAddress, library, account);
			const userAllocationsSC = await getAirdropUserAllocationInfos(airdropAbi, airdropAddress, library, account);
			const totalAllocationsSC = await getAllAirdropAllocationCount(airdropAbi, airdropAddress, library, account);
			const owner = await getAirdropOwner(airdropAbi, airdropAddress, library, account);


			const allocationsSC = await getAirdropAllocations(airdropAbi, airdropAddress, library, account, 0, parseInt(totalAllocationsSC));
			setAllocations(allocationsSC);

			let claimTime = null;
			
			if(tgeDateSC && Number(tgeDateSC.toString()) > 0){
				let claimTimeNumber = Number(tgeDateSC.toString());
			
				claimTime = new Date(claimTimeNumber * 1000);
				
				setTgeDate(claimTime);
			}

			setUserAllocations(userAllocationsSC);
			setTotalAllocations(parseInt(totalAllocationsSC));
			setIsOwner(account?.toLowerCase() == owner?.toLowerCase());
			setEnsureExactAmount(exactAmountSC);
			setIsAdmin(wlAdminSC);
			
			setTgeBps(parseInt(tgeBpsSC) / 100);
			setCycle(parseInt(cycleSC) / 60);
			setCycleBps(parseInt(cycleBpsSC) / 100);
			setState(parseInt(stateSC));
			setFundPercent(parseInt(fundPercentSc) / 10000);
			setTotalTokens(parseFloat(ethers.utils.formatUnits(totalTokensSC, tokenInfoSC.decimals)));
			setClaimedTokens(parseFloat(ethers.utils.formatUnits(claimedTokensSC, tokenInfoSC.decimals)));
			setLoadingData(false);
		} catch (e) {
			console.log('allo', e);
			setLoadingData(false);
		}

	}


	const getAirDrop = async (_handle) => {
		setLoading(true);

		try {
			let res = await AirdropApi.getAirdropDetail(_handle);
			if (res.status === 200) {
				setDataAirdrop(res.data);
				setTokenAddress(res.data.tokenAddress)
			}
			setLoading(false);
		} catch (error) {
			console.log('Error: ', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (handle) {
			getAirDrop(handle);
		}
	}, [handle]);

	useEffect(() => {
        if (account && chainId && Number(chainId) !== Number(dataAirdrop?.chainId)) {
            networkChangeByNumber(dataAirdrop?.chainId);
        }
    }, [chainId, account, dataAirdrop]);


	useEffect(() => {
		if (totalTokens !== 0) {
			setProgressPercent((claimedTokens / totalTokens) * 100)
		}
	}, [totalTokens, claimedTokens]);

	useEffect(() => {
		setTotalToStartTokens(totalTokens + (totalTokens * fundPercent))
	}, [totalTokens, fundPercent]);

	useEffect(() => {
		if (tokenAddress && account && dataAirdrop.tokenAddress && airdropAddress && dataAirdrop?.chainId == chainId) {
			getAirdropInfos()
		}
	}, [tokenAddress, account, dataAirdrop, airdropAddress, chainId]);



	const closeBox = (e) => {
		e.preventDefault();
		setIsFormAllocation(false);
		setIsFormVesting(false);
		setIsStartForm(false)

		startForm.resetFields();
		vestingForm.resetFields();
		addWLForm.resetFields();
		setSpecificTime(0)
	};
	const showFormSetAllocation = () => {
		setIsFormAllocation(true);
	};

	const onShowStartForm = () => {
		setIsStartForm(true);
	};
	const showFormSetVesting = () => {
		vestingForm.setFieldsValue({
			tge_release_percent: tgeBps,
			cycle: cycle,
			cycle_release_percent: cycleBps
		});
		setIsFormVesting(true);
	};
	const removeAllocation = () => {
		try {
			setLoading(true);
			setKeyLoading(6);
			removeAllAirdropAllocations(airdropAbi, airdropAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;

										}
									} else {
										Store.addNotification(addNotify('Remove All Airdrop Allocations Failed', 'danger'));
									}
									setLoading(false);
									window.location.reload();
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	};
	//onClaimTokens

	const onClaimTokens = async () => {
		try {
			setLoading(true);
			setKeyLoading(12);
			await claimAirdrop(airdropAbi, airdropAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											Store.addNotification(addNotify('Claim Successfully', 'success'));
										}
									} else {
										Store.addNotification(addNotify('Claim Failed', 'danger'));
									}
									setLoading(false);
									window.location.reload();
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	};
	const disableExactAmount = async (status) => {
		try {
			setLoading(true);
			setKeyLoading(7);
			await setAirdropEnableExactAmount(airdropAbi, airdropAddress, library, account, status)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											setEnsureExactAmount(status)
											
											Store.addNotification(addNotify(`${status ? 'Enable' : 'Disable'} Exact Amount Successfully`, 'success'));
										}
									} else {
										Store.addNotification(addNotify(`${status ? 'Enable' : 'Disable'} Exact Amount Failed`, 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	};

	const onCancelAirdrop = () => {
		try {
			setLoading(true);
			setKeyLoading(2);
			cancelAirdrop(airdropAbi, airdropAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;


											const payload = {
												id: dataAirdrop.id,
												airDropStatus: 'CANCEL',
												ownerAddress: account,
											};
											AirdropApi.updateAirDrop(dataAirdrop.id, payload);
											Store.addNotification(addNotify('Cancel Airdrop Successfully', 'success'));
											setState(2);
										}
									} else {
										Store.addNotification(addNotify('Cancel Airdrop Failed', 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	};

	const onSubmitAddAllocation = async (values) => {

		// if(Number(values.totalToken) != Number(dataAirdrop?.totalTokenReward)){
		// 	Store.addNotification(addNotify('Total allocation cannot be less than total token reward', 'danger'));
		// 	return;
		// }

		try {
			setLoading(true);
			setKeyLoading(4);
			setAirdropAllocations(airdropAbi, airdropAddress, library, account, JSON.parse(values.addressList), JSON.parse(values.amountList))
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											Store.addNotification(addNotify('Set Airdrop Allocations Successfully', 'success'));
										}
									} else {
										
										Store.addNotification(addNotify('Set Airdrop Allocations Failed', 'danger'));
									}
									setLoading(false);
									window.location.reload();
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e) {
			console.log(e);
			setLoading(false);
		}



	};
	const onSubmitVesting = async (values) => {
		try {
			setLoading(true);
			setKeyLoading(4);
			setAirdropVesting(airdropAbi, airdropAddress, library, account, parseFloat(values.tge_release_percent) * 100, parseFloat(values.cycle) * 60, parseFloat(values.cycle_release_percent) * 100)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++; 
											Store.addNotification(addNotify('Set Airdrop Vesting Successfully', 'success'));
											setTimeout(() => {
												window.location.reload();
											}, 1500);
										}
									} else {
										Store.addNotification(addNotify('Set Airdrop Vesting Failed', 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	};

	const handleApproveToken = async (e) => {
		try {
			e.preventDefault()
			setLoading(true);
			setKeyLoading(2);
			await approveToken(tokenInfoAbi, dataAirdrop.tokenAddress, library, account, airdropAddress)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										!countNoti && Store.addNotification(addNotify(`Approve ${dataAirdrop.tokenSymbol} Token Successfully`, 'success'));
										countNoti++;
										setAllowance(Math.pow(2, 256));

									} else {
										Store.addNotification(addNotify(`Approve ${dataAirdrop.tokenSymbol} Token Failed`, 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const onStartAirdrop = async (values) => {
		try {
			setLoading(true);
			setKeyLoading(1);
			let startTime = Math.floor((new Date().getTime()) / 1000);
			if (values.specType == 1) {
				startTime = Math.floor((new Date(values.start_time).getTime()) / 1000)
			}
			startAirdrop(airdropAbi, airdropAddress, library, account, startTime)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;

											const payload = {
												id: dataAirdrop.id,
												airDropStatus: 'START',
												ownerAddress: account,
												startTime: new Date(startTime * 1000).toISOString()
											};
											AirdropApi.updateAirDrop(dataAirdrop.id, payload);
											setState(1);
							
											Store.addNotification(addNotify('Start Airdrop Successfully', 'success'));
											setIsStartForm(false);
										}
									} else {
					
										Store.addNotification(addNotify('Start Airdrop Failed', 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	};


	const columns = [
		{
			title: <span className='text-regular-blue'>ID</span>,
			dataIndex: 'id',
			key: 'id',
			render: (text, record, index) => {
				return (
					<span >
						{index + 1}
					</span>
				)
			}
		},
		{
			title: <span className='text-regular-blue'>Wallet</span>,
			dataIndex: 'user',
			key: 'user',
			render: (text, record) => {
				return (
					<span className='text-regular-blue'>
						{record.user?.substring(0, 6)}...{record.user?.substring(record.user.length - 6)}
					</span>
				)
			}
		},

		{
			title: <span className='text-regular-blue'>Amount</span>,
			dataIndex: 'amount',
			key: 'amount',
			render: (text, record) => {
				let amount = ethers.utils.formatUnits(record.amount, decimals);
				
				return (
					<span className='text-regular-blue'>
						{new Intl.NumberFormat("ja-JP").format(amount ? amount : 0)} {dataAirdrop.tokenSymbol}
					</span>
				)
			}
		}
	];
	const onCopy = () => {
		Store.addNotification(addNotify('Copied', 'success'));
	};

	const addToMetaMask = async () => {
        setLoading(true);
        try {
            // wasAdded is a boolean. Like any RPC method, an error may be thrown.
            const wasAdded = await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20', // Initially only supports ERC20, but eventually more!
                    options: {
                        address: tokenAddress, // The address that the token is at.
                        symbol: dataAirdrop?.tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
                        decimals: decimals, // The number of decimals in the token
                    },
                },
            });
            
            if (wasAdded) {
                setLoading(false);
                Store.addNotification(addNotify("Successfully!", 'success'));
            } else {
                setLoading(false);
                Store.addNotification(addNotify("Failed!", 'danger'));
            }
        } catch (error) {
            setLoading(false);
            Store.addNotification(addNotify(error?.message, 'danger'));
        }
    };

	function isImgLink(url) {
        if(typeof url !== 'string') return false;
        return(url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|webp)(\?(.*))?$/gmi) != null);
    }

	let chainName = CHAINID_FULLNAME_BY_ID[dataAirdrop?.chainId];

	const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            window.location.reload();
        }
        if (days > 1) {
            return (<span className='text-regular-blue font-20'>{days}d {hours}h {minutes}m {seconds}s</span>);
        } else if (days === 1) {
            return (<span className='text-regular-blue font-20'>{days}d {hours}h {minutes}m {seconds}s</span>);
        } else {
            return (<span className='text-regular-blue font-20'>{days}d {hours}h {minutes}m {seconds}s</span>);
        }
    };
    const getProgressTime = useCallback((startTime) => {
        const now = new Date();
        const utcDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), 0);
        const startDate = new Date(startTime);
        const startTS = Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            startDate.getHours(),
            startDate.getMinutes(),
            startDate.getSeconds(),
            0
        );
        let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600);
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60);
        if (days >= 1) {
            return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        } else {
            return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        }
    }, []);

	const onOkStartTime = (dates) => {
        setStartTime(dates?.toISOString()) 
    }

	

	let yourAllocation = userAllocations ? ethers.utils.formatUnits(userAllocations?.amount || '0', decimals) : 0;
	let yourClaimed = userAllocations ? ethers.utils.formatUnits(userAllocations?.unlockedAmount || '0', decimals) : 0;

	return (
		<>
			
			
			<div className='airdrop-detail-page'>
				
				<div className='container'>
					<Breadcrumb className='head-breadcrumb-airdrop'>
						<Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item><a href='/airdrop'>Airdrop</a></Breadcrumb.Item>
						<Breadcrumb.Item><a href='#'>{dataAirdrop?.airDropTitle}</a></Breadcrumb.Item>
					</Breadcrumb>
					{!account && (
						<>
							<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
								<Col className='gutter-row' xs={24} xl={8}>
									<p>
										<Alert message="Please connect wallet" type="error" showIcon />
									</p>
								</Col>
								<Col className='gutter-row' xs={24} xl={8}></Col>
							</Row>
						</>
					)}
					{account && chainId !== dataAirdrop?.chainId && (
						<>
							<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
								<Col className='gutter-row' xs={24} xl={8}>
									<p>
										<Alert message={`Incorrect Network, Please switch to ${titleNetwork}`} type="error" showIcon />
									</p>
								</Col>
								<Col className='gutter-row' xs={24} xl={8}></Col>
							</Row>
						</>
					)}
					
					
					<div className='airdrop-detail-top'>
						<Row gutter={16}>
							<Col className='gutter-row' xs={24} xl={16}>
								
								<div className='airdrop-detail-title'>{dataAirdrop?.airDropTitle}</div>
								<div className="airdrop-detail-social">
									{dataAirdrop?.website.length > 0 && (
										<span className="detail-social"><a target="_blank" href={dataAirdrop?.website}><GlobalOutlined className="icon" /></a></span>
									)}
									{dataAirdrop?.facebook.length > 0 && (
										<span className="detail-social"><a target="_blank" href={dataAirdrop?.facebook}><FacebookFilled className="icon" /></a></span>
									)}
									{dataAirdrop?.github.length > 0 && (
										<span className="detail-social"><a target="_blank" href={dataAirdrop?.github}><GithubOutlined className="icon" /></a></span>
									)}
									{dataAirdrop?.instagram.length > 0 && (
										<span className="detail-social"><a target="_blank" href={dataAirdrop?.instagram}><InstagramOutlined className="icon" /></a></span>
									)}
									{dataAirdrop?.telegram.length > 0 && (
										<span className="detail-social"><a target="_blank" href={dataAirdrop?.telegram}><GithubOutlined className="icon" /></a></span>
									)}
									{dataAirdrop?.twitter.length > 0 && (
										<span className="detail-social"><a target="_blank" href={dataAirdrop?.twitter}><TwitterCircleFilled className="icon" /></a></span>
									)}
									{dataAirdrop?.discord.length > 0 && (
										<span className="detail-social"><a target="_blank" href={dataAirdrop?.discord}><TwitterCircleFilled className="icon" /></a></span>
									)}
									{dataAirdrop?.reddit.length > 0 && (
										<span className="detail-social"><a target="_blank" href={dataAirdrop?.reddit}><RedditCircleFilled className="icon" /></a></span>
									)}
								</div>
								<div className='airdrop-detail-description'>
									{dataAirdrop?.description}	
								</div>
							</Col>
							<Col className='gutter-row' xs={24} xl={8}>
								<div className='airdrop-detail-banner'>
									<img src={dataAirdrop?.logoUrl?.length > 0 && isImgLink(dataAirdrop?.logoUrl) ? dataAirdrop?.logoUrl : "../../../images/airdrop-default.png"} />
								</div>
							</Col>
						</Row>
					</div>

					
					<div className='airdrop-detail-block'>

						<Row gutter={16}>
							<Col className='gutter-row' xs={24} xl={16}>
								<Card className='card-airdrop' size='default' title={'Airdrop Info'} extra={
									<>
										{dataAirdrop?.taskLink.length > 0 && (
											<>
												<button className="btn-basic" onClick={()=> window.open(`${dataAirdrop?.taskLink}`)}>
													Join Task <DoubleRightOutlined />
												</button>
											</>
										)}
									</>
								}>
									<div className='airdrop-line pt-0'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>Token Address</span></Col>
											<Col className='gutter-row text-right' xs={12}>
												<div className='text-regular-blue'>
													<span className='text-info mr-5'>
														{dataAirdrop?.tokenAddress.substring(0, 8)}...{dataAirdrop?.tokenAddress.substring(dataAirdrop?.tokenAddress.length - 8)}
													</span>
													<CopyToClipboard onCopy={onCopy} text={dataAirdrop?.tokenAddress}>
														<CopyOutlined className='text-info' />
													</CopyToClipboard>
												</div>
												<a className="text-danger" onClick={addToMetaMask} disabled={loading}>Add to Metamask</a>
											</Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>Name</span></Col>
											<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{dataAirdrop?.tokenName}</span></Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>Symbol</span></Col>
											<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{dataAirdrop?.tokenSymbol}</span></Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>Total Reward</span></Col>
											<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'><span className='text-info'>{new Intl.NumberFormat("ja-JP").format(dataAirdrop?.totalTokenReward)}</span> {dataAirdrop?.tokenSymbol}</span></Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>Network</span></Col>
											<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{chainName}</span></Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>Start Time</span></Col>
											<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue text-uppercase'>{dataAirdrop?.startTime ? convertDateTime(startDate) : '---'}</span></Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={16}>
											<Col className='gutter-row' xs={12}><span>End Time</span></Col>
											<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue text-uppercase'>{dataAirdrop?.endTime ? convertDateTime(endDate) : '---'}</span></Col>
										</Row>
									</div>

									{tgeBps > 0 && (
										<>
											<div className='airdrop-line'>
												<Row gutter={16}>
													<Col className='gutter-row' xs={12}><span>TGE Release Percent</span></Col>
													<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{tgeBps.toLocaleString()}%</span></Col>
												</Row>
											</div>
											<div className='airdrop-line'>
												<Row gutter={16}>
													<Col className='gutter-row' xs={12}><span>Cycle</span></Col>
													<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{cycle.toLocaleString()} Minutes</span></Col>
												</Row>
											</div>
											<div className='airdrop-line'>
												<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
													<Col className='gutter-row' xs={12}><span>Cycle Release Percent</span></Col>
													<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{cycleBps.toLocaleString()}%</span></Col>
												</Row>
											</div>
										</>
									)}
								</Card>
								<Card className='card-airdrop' title={`Allocation`} style={{ marginTop: '15px' }}
								>
									{loadingData ? (
										<>
											<div className="loading-center">
												<Space size="middle"><Spin size="large" /></Space>
											</div>
										</>
									) : (
										<>
											{totalAllocations === 0 ? (
												<>
													{/* {dataAirdrop?.timeClaimReward && (
														<p className='text-info' style={{ textAlign: 'center', fontSize: '16px' }}>
															Claimable after {convertDateTime(timeClaimReward)}
														</p>
													)} */}

													<Empty description="Allocation empty" />
												</>
											) : (
												<>
													
														<div className='table-allocation-airdrop'>
															<Table dataSource={allocations} columns={columns} />

															<p>Total address: {parseInt(totalAllocations)}</p>
														</div>
													

												</>
											)}
										</>
									)}

								</Card>
							</Col>
							<Col className='gutter-row' xs={24} xl={8}>
								<Card className='card-airdrop' title=''>
									{state === 0 && (
										<>
											{startDate > nowDate && endDate > nowDate && (
												<>
													<span className="text-regular-blue">Airdrop start in: </span>
													<Countdown
														date={getProgressTime(startDate)}
														renderer={rendererCountDown}
													/>
												</>
											)}
											{startDate < nowDate && endDate > nowDate && (
												<>
													<span className="text-regular-blue">Airdrop end in: </span>
													<Countdown
														date={getProgressTime(endDate)}
														renderer={rendererCountDown}
													/>
												</>
											)}

											{startDate < nowDate && endDate < nowDate && (
												<Alert message="Airdrop has ended." type="info" />
											)}
										</>
									)}

									{state === 1 && (
										<>
											{startDate > nowDate && endDate > nowDate && (
												<>
													
													{tgeDate && (
														<>
															<div className='mb-15'>
																<span className="text-regular-blue">Claim time: </span>
																<span className='text-upper text-info'>{convertDateTime(tgeDate)}</span>
															</div>
														</>
													)}
												</>
											)}
											{startDate < nowDate && endDate > nowDate && (
												<>
													
													<div className='mb-15'>
														<span className="text-regular-blue">Airdrop end in: </span>
														<Countdown
															date={getProgressTime(endDate)}
															renderer={rendererCountDown}
														/>
													</div>
													{tgeDate && (
														<>
															<div className='mb-15'>
																<span className="text-regular-blue">Claim time: </span>
																<span className='text-upper text-info'>{convertDateTime(tgeDate)}</span>
															</div>
														</>
													)}
												</>
											)}
											{parseFloat(yourAllocation) > 0 ? (
												<>
													{/* <Alert message="Congrats winners. You can now claim your rewards!" type="success" /> */}
												</>
											) : (
												<>
													<Alert message="You are not whitelisted." type="warning" />
												</>
											)}
										</>
									)}
									{state === 2 && (
										<Alert className='text-regular-blue' message="Airdrop is cancelled!" type="error" />
									)}

									<Progress
										strokeColor={{
											'0%': '#0261a5',
											'100%': '#89befe'
										}}
										percent={progressPercent}
										showInfo={false}
										className="mt-15"
									/>
									<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
										<Col className='gutter-row' xs={12}><span className='text-regular-blue'>{new Intl.NumberFormat("ja-JP").format(claimedTokens)}</span></Col>
										<Col className='gutter-row text-right'xs={12}>
											<span className='text-regular-blue'>{new Intl.NumberFormat("ja-JP").format(totalTokens ? totalTokens : 0)} {dataAirdrop?.tokenSymbol}</span>
										</Col>
									</Row>
									<div className='airdrop-line'>
										<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
											<Col className='gutter-row' xs={12}><span>Your Allocation</span></Col>
											<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'> {new Intl.NumberFormat("ja-JP").format(yourAllocation)}</span></Col>
										</Row>
									</div>
									<div className='airdrop-line'>
										<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
											<Col className='gutter-row' xs={12}><span>Your Claimed</span></Col>
											<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{new Intl.NumberFormat("ja-JP").format(yourClaimed)}</span></Col>
										</Row>
									</div>
									{startDate < nowDate && endDate > nowDate ? (
										<>
											{parseFloat(yourAllocation) > 0 && (
												<div className='airdrop-line'>
													<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
														<Col className='gutter-row' xs={24}>
															<button className='btn-basic' disabled={dataAirdrop?.chainId != chainId || (parseFloat(claimable) === 0) || (loading && keyLoading === 12)} onClick={onClaimTokens}>
																Claim {claimable > 0 ? `${new Intl.NumberFormat("ja-JP").format(claimable)} ${dataAirdrop?.tokenSymbol}` : ''} {(loading && keyLoading === 12) && <Spin className="ml-10" size="small" />}
															</button>
														</Col>

													</Row>
												</div>
											)}
										</>
									):(
										<div className='airdrop-line'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={24}>
													<button className='btn-basic' disabled>
														Claim
													</button>
												</Col>

											</Row>
										</div>
									)}
									
								</Card>
								{loadingData ? (
									<>
										
											<div className="loading-center">
												<Space size="middle"><Spin size="large" /></Space>
											</div>
										
									</>
								) : (
									<>
										{isOwner && (
											<Card className='card-airdrop' title={'Owner Zone'} style={{ marginTop: '15px' }}>
												{/* <Alert
													message="Please don't start the airdrop before you finalize the presale pool."
													type='warning' /> */}
												<Alert message='You must exclude fees, dividens, max tx for airdrop address to start the airdrop.' className='mb-15' type='warning' />
												<div className='airdrop-btn-grp'>
													{startDate < nowDate && endDate > nowDate && state === 0 && (
														<Button className='airdrop-btn' disabled={totalAllocations === 0} htmlType='button' onClick={onShowStartForm}>Start Airdrop</Button>
													)}

													
													<Button className='airdrop-btn' htmlType='button'
														disabled={loading && keyLoading === 2}
														onClick={onCancelAirdrop}
													>
														Cancel Airdrop {(loading && keyLoading === 2) && <Spin className="ml-10" size="small" />}
													</Button>
												</div>
												<p style={{ marginTop: '15px' }}><strong>Allocations Actions</strong></p>
												<div className='airdrop-btn-grp'>
													{startDate < nowDate && endDate > nowDate && (
														<>
															{state === 0 && (
																<>
																	<Button className='airdrop-btn' onClick={showFormSetAllocation} htmlType='button'>Set Allocations</Button>
																	<Button className='airdrop-btn' onClick={showFormSetVesting} htmlType='button'>Set Vesting</Button>
																</>
															)}
														</>
													)}
													

													<Button className='airdrop-btn' onClick={removeAllocation} disabled={(loading && keyLoading === 6) || totalAllocations === 0} htmlType='button'>Remove All Allocations {(loading && keyLoading === 6) && <Spin className="ml-10" size="small" />}</Button>
													{ensureExactAmount ?
														(
															<Button className='airdrop-btn' onClick={() => disableExactAmount(false)} disabled={loading && keyLoading === 7}
																htmlType='button'>Disable Exact Amount {(loading && keyLoading === 7) && <Spin className="ml-10" size="small" />}</Button>
														) : (
															<Button className='airdrop-btn' onClick={() => disableExactAmount(true)} disabled={loading && keyLoading === 7}
																htmlType='button'>Enable Exact Amount {(loading && keyLoading === 7) && <Spin className="ml-10" size="small" />}</Button>
														)
													}
												</div>
											</Card>
										)}
									</>
								)}

							</Col>
						</Row>
					</div>
					
				</div>
				<FooterHome />
				<Modal
					title={<div className='text-md custom-modal-title'>Add Allocations</div>}
					visible={isFormAllocation}
					className='custom-modal create-token-modal'
					footer={false}
					onOk={()=>{setIsFormAllocation(false)}} onCancel={()=>{setIsFormAllocation(false)}}
				>
					<div className='transer-box-content'>
						<Form
							form={addWLForm}
							name='basic'
							autoComplete='off'
							onFinish={onSubmitAddAllocation}
							layout='vertical'
						>

							<Form.Item name='addressList' hidden={true}>
								<Input className='amount-buy' placeholder='Ex: 0' />
							</Form.Item>
							<Form.Item name='amountList' hidden={true}>
								<Input className='amount-buy' placeholder='Ex: 0' />
							</Form.Item>
							<Form.Item name='totalToken' hidden={true}>
								<Input className='amount-buy' placeholder='Ex: 0' />
							</Form.Item>

							<Form.Item name='account_address' label='Allocations'
								rules={[
									{
										validator: (rule, value, cb) => {
											if (!value) {
												return cb('Allocations can not be blank');
											}
											if (value) {
												const wallets = value.trim().split('\n');
												let totalTokens = 0;

												let addressList = [];
												let allocationList = [];
												let amountList = [];
												for (let i = 0; i < wallets.length; i++) {
													const lineValues = wallets[i].trim().split(',');
													const address = (lineValues[0] || '').trim();
													const amount = (lineValues[1] || '0').trim();

													addressList.push(address);
													amountList.push(ethers.utils.parseUnits(`${amount}`, decimals).toHexString())
													allocationList.push({ address, amount })

													totalTokens = totalTokens + parseFloat(amount);

													if (!ethers.utils.isAddress(address) || !parseFloat(amount)) {
														return cb(`Incorrect value at line ${i + 1}`);
													}
												}
												addWLForm.setFieldsValue({
													'addressList': JSON.stringify(addressList),
													'amountList': JSON.stringify(amountList),
													'totalToken': totalTokens
												});
												cb();
											}

										}
									}]}>
								<TextArea rows={8} className='amount-buy' placeholder='Insert address: separate with breaks line. The amount of each user separate by comma(,) symbol. The format just like CSV file
																						Ex:
																						0x34E7f6A4d0BB1fa7aFe548582c47Df337FC337E6,1000
																						0xd8Ebc66f0E3D638156D6F5eFAe9f43B1eBc113B1,500
																						0x968136BB860D9534aF1563a7c7BdDa02B1A979C2,1234' />
							</Form.Item>
							<div className='modal-btn-group'>
								<button className='btn-basic' disabled={loading} type='submit'>Add
									Allocations {loading && <Spin className='ml-10' size='small' />}</button>
								<button className='btn-basic-default' disabled={loading} onClick={closeBox}>Close</button>
							</div>

						</Form>

					</div>
				</Modal>
				<Modal
					title={<div className='text-md custom-modal-title'>Set Vesting</div>}
					visible={isFormVesting}
					className='custom-modal create-token-modal'
					footer={false}
				>
					<div className='transer-box-content'>
						<Form
							form={vestingForm}
							name='basic'
							autoComplete='off'
							onFinish={onSubmitVesting}
							layout='vertical'
							className='basic-form'
						>
							<Form.Item name='tge_release_percent' label='TGE release percent (%)'
								rules={[
									{
										validator: (rule, value, cb) => {
											!value || parseFloat(value) <= 0 || parseFloat(value) > 100
												? cb('TGE percent must be a positive number')
												: cb();
										}
									}
								]}>
								<Input className='amount-buy' placeholder='Ex: 0' />
							</Form.Item>
							<Form.Item name='cycle_release_percent' label='Cycle release percent (%)'
								rules={[
									{
										validator: (rule, value, cb) => {
											!value || parseFloat(value) <= 0 || parseFloat(value) > 100
												? cb('Cycle release percent must be a positive number')
												: cb();
										}
									}]}>
								<Input className='amount-buy' placeholder='Ex: 0' />
							</Form.Item>
							<Form.Item name='cycle' label='Cycle (Minutes)'
								rules={[
									{
										validator: (rule, value, cb) => {
											!value || parseFloat(value) <= 0
												? cb('Cycle must be a positive number')
												: cb();
										}
									}]}>
								<Input className='amount-buy' placeholder='Ex: 0' />
							</Form.Item>
							<div className='modal-btn-group'>
								<button className='btn-basic' disabled={loading} type='submit'>Set vesting {loading &&
									<Spin className='ml-10' size='small' />}</button>
								<button className='btn-basic-default' disabled={loading} onClick={closeBox}>Close</button>
							</div>
						</Form>

					</div>
				</Modal>

				<Modal
					title={<div className='text-md custom-modal-title'>Setting time to start</div>}
					visible={isStartForm}
					className='custom-modal create-token-modal'
					footer={false}
					onOk={()=>{setIsStartForm(false)}} onCancel={()=>{setIsStartForm(false)}}
				>
					<div className='transer-box-content'>
						<Form
							form={startForm}
							name='basic'
							autoComplete='off'
							onFinish={onStartAirdrop}
							layout='vertical'
							className='basic-form'
						>
							<Form.Item name="specType" label="Set time to start airdrop" initialValue={0}>
								<Radio.Group className="radio-gr-create" onChange={e => setSpecificTime(!(!e.target.value))}>
									<Radio value={0}>Start now</Radio>
									<Radio value={1}>Start with specific time</Radio>
								</Radio.Group>
							</Form.Item>
							{specificTime ? (
								<Form.Item name="start_time" label="Start Time (UTC)"
									rules={[
										{ required: true, message: 'Please input Start time!' },
										{
                                            validator: (rule, value, cb) => {
                                                !value || new Date(value) <= new Date(Date.now())
                                                    ? cb("Start time needs to be after now")
                                                    : cb();
                                            }
                                        }
									]}>
									<DatePicker className='form-picker' showTime format={date => date.utc().format('DD/MM/YYYY HH:mm:ss')} onOk={onOkStartTime} />
									{/* <Input className="amount-buy" type="datetime-local" /> */}
								</Form.Item>
							) : (<></>)}

							<div className="start-info">
								<span className="start-info-detail"> You need at least {new Intl.NumberFormat("ja-JP").format(totalToStartTokens)} {dataAirdrop?.tokenSymbol} to start airdrop</span>
							</div>


							<div className='modal-btn-group'>

								{allowance < totalTokens ? (
									<button className='btn-basic' disabled={loading} onClick={handleApproveToken}>Approve {loading && <Spin className='ml-10' size='small' />}</button>
								) : (
									<button className='btn-basic' disabled={loading || totalToStartTokens == 0} type='submit'>
										Start Airdrop {loading && <Spin className='ml-10' size='small' />}
									</button>
								)}
								<button className='btn-basic-default' disabled={loading} onClick={closeBox}>Close</button>
							</div>
						</Form>

					</div>
				</Modal>
			</div>
			{loadingData || loading && (<Loader />)}
			
		</>
	);
};

export default AirdropRewardDetail;
