import React, { useState, useEffect, memo } from 'react';
import { Link } from 'react-router-dom';
import { useActiveWeb3React } from '../../hooks';
import ItemClaimableRound from './itemClaimableRound';

const ItemClaimable = ({item}) => {

    const {
        acceptCurrency,
        bannerURL,
        handle,
        logoUrl,
        roundList,
        status,
        symbolToken,
        title,
        oldVersion,
        network,
        claimChain
    } = item;
    
    const campaignId = item?.id;
    const [activeCampaign, setActiveCampaign] = useState(true);

    const { account, chainId } = useActiveWeb3React();
    let handleStatus = status.toLowerCase();
    if(status === "TBA") {
        handleStatus = 'Upcoming';
    }

    const roundListRender = roundList && roundList.map((item,key) => {

        if(item.actualFundNeeded && item.actualFundNeeded > 0){
            return <ItemClaimableRound
                        claimableCampaign={{
                            campaignId, 
                            roundId: item?.id, 
                            acceptCurrency, 
                            symbolToken, 
                            oldVersion, 
                            account,
                            chainId,
                            network,
                            claimChain,
                            status
                        }}
                        claimableRound={item} 
                        key={key}
                    /> 
        }
    });
    
    return(
        <>
        {activeCampaign && <div className="c-list__item">
            <Link to={handle} className="c-list__logo">
                <div className="c-list__img">
                    <img src={bannerURL} alt={title} />
                    {item?.modelType === 1
                        ? <div className={`c-list__status protected`} style={{textTransform: 'capitalize'}}>
                            {/* <img src='../images/protected.svg'></img> */}
                            Riskless
                            </div>
                        : <div className="c-list__status" style={{textTransform: 'capitalize'}}>Shielded</div>
                    } 
                </div>
                <div className="c-list__title">
                    <h3>{title}</h3>
                    <span><img src={logoUrl} alt={title} /></span>
                </div>
            </Link>
            <div className="c-list__body">
                {roundListRender}
            </div>
        </div>}
        </>
    );
}

export default ItemClaimable;
