import web3 from "web3";
import BigNumber from 'bignumber.js'
import { re } from "mathjs";

export const convertTokenToWei = (value, decimal) => {
  let amount = web3.utils.toWei(value.toString(), 'ether')
  if (decimal !== 18)
    amount = new BigNumber(value).times(new BigNumber(10).pow(decimal)).toString()
  return amount
}

// get total staked by account

export const _isMinted = async (poolContract, account) => {   
  let data = await poolContract.mints(account);
  return data;
}

export const _claim = async (poolContract) => {
  return await poolContract.claim()
}


