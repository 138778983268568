/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import bgMainheader from "./images/res-5.png";
import bgMainheaderRow from "./images/bg-radi.png";
import { rendererCountDown , rendererCountDown1 } from "./utils/render-countdown";
import './style.css'
import Stake from "./stake";
import UnStake from "./unStake";
import Withdraw from "./withdraw";
import Countdown from "react-countdown";
import web3 from "web3";
import {
    Spin, message
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useActiveWeb3React } from "../../hooks";
import { useContract } from "../../hooks/useContract";
import abiStake from "./utils/abiStake.json"
import { MAPPING_CHAINID_DECIMAL, SC_STAKE_FIXED } from "../../constants";

import FooterHome from "../common/footer/footerHome";
import { Store } from "react-notifications-component";
import addNotify from "../common/Notify/addNotify";
import ChainNotSupport from "../chain-not-support";

const timeDown = 7 * 24 * 60 * 60 * 1000
// const timeDown = 5 * 60 * 1000

const StakeFixible = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
    const { TabPane } = Tabs;
    const [defaultKey, setDefaultKey] = useState("stake");

    
    const [amountReward, setAmountReward] = useState(0);
    const { account, chainId } = useActiveWeb3React();
    const contractStake = useContract(SC_STAKE_FIXED, abiStake);
    
    const [getUser, setGetUser] = useState();
    const [totalStaked, setTotalStaked] = useState(0)
    const [numberStaked, setNumberStaked] = useState(0)
    const [isCheckAction, setIsCheckAction] = useState(false)
    const [loadingStake, setloadingStake] = useState(false)
    const [loadingWithdraw, setloadingWithdraw] = useState(false)
    const [isAction, setIsAction] = useState(false)
    const [amountBscsPool, setAmountBscsPool] = useState(0);
    const [timeUnstake, setTimeUnstake] = useState();
    const [apy, setApy] = useState(0);
    const [timeLock, settimeLock] = useState(0);
    const [stakeTime, setstakeTime] = useState(0);
    const [lockTimeUnstake, setlockTimeUnstake] = useState(0);

    const pathTabsLive = location.search.split("?");

    let tabBox = pathTabsLive[1] === undefined ? "stake" : pathTabsLive[1];

    if((stakeTime * 1000 + timeLock * 1000) > Date.now()){
        tabBox = 'stake';
    }

    const getBscsPool = async () => {
        if (contractStake) {
            const amount = await contractStake.balanceOf(account);
            let _balance = web3.utils.fromWei(
                web3.utils.toBN(amount),
                "ether"
            );
            setAmountBscsPool(_balance);
        }
    };

    useEffect(() => {
        if (account) {
            getBscsPool();
        }
    }, [account]);

    function callback(key) {
        setDefaultKey(key);
        navigate(`${"/stake-fixible"}?${key}`);
    }

    useEffect(() => {
        if (tabBox) {
            setDefaultKey(tabBox);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabBox]);

    const getTokenTotalStaked = async () => {
        if (contractStake) {
            const amount = await contractStake.tokenTotalStaked();
            setTotalStaked(amount.toString() / 1e18);
        } else {
            setTotalStaked(0)
        }
    };

    const getNumberStaked = async () => {
        try {
            await contractStake.stakerCount().then((res) => {

                setNumberStaked(Number(res))
            })
                .catch((err) => {
                    setNumberStaked(0)
                    console.log('err', err)
                })

        } catch (error) {

        }

    }
    useEffect(() => {
        if (contractStake) {
            getNumberStaked()
        }

    }, [contractStake, isCheckAction]);

    const getUserMap = async () => {
        if (contractStake) {
            const user = await contractStake.userMap(account);
            setGetUser(user.inUnStakeAmount / 1e18);
            setTimeUnstake(Number(user.unstakeTime))
            const amountRW = await contractStake.getEarnedRewardTokens(account);
            setAmountReward(amountRW / 1e18 || 0)
        }
    };

    const getTimeLock = async () => {
        if (contractStake && account) {
            let time = await contractStake.stakeLockPeriod();
            settimeLock(Number(time))
            let time1 = await contractStake.stakeTime(account)
            setstakeTime(Number(time1))
            let time2 = await contractStake.lockTimePeriod();
            setlockTimeUnstake(Number(time2))
        }
    }

    const getUserReward = async () => {
        if (contractStake) {
            const userReward = await contractStake.getEarnedRewardTokens(account);
            setAmountReward(userReward / 1e18)
        }
    };
    const getApy = async () => {
        if (contractStake) {
            const apy = await contractStake.stakeRewardAPY();
            setApy(apy.toString())
        }
    };


    useEffect(() => {
        if (account) {
            getUserMap()
            getTimeLock()
        } else {
            setGetUser(0)
            setTimeUnstake(0)
            setAmountReward(0)
        }
    }, [account, tabBox, isAction]);

    useEffect(() => {

        const intervalId = setInterval(getUserMap, 5000);

        return () => {
            clearInterval(intervalId);
        };
    }, [account]);

    useEffect(() => {
        if (account) {
            getUserReward();
            getApy();
        }
    }, [account, contractStake]);

    useEffect(() => {
        if (account) {
            getTokenTotalStaked();
        }
    }, [account, contractStake, isCheckAction]);

    const handleStakeReward = async () => {
        try {
            setloadingStake(true)
            await contractStake.stakeRewards().then(async (res) => {
                res.wait()
                    .then((resWait) => {
                        if (resWait?.status === 1) {
                            setloadingStake(false)
                            setIsAction(!isAction)
                        
                            Store.addNotification(addNotify('Stake rewards successfully', 'success'));
                            window.location.reload()
                        } else {
                            Store.addNotification(addNotify('Unknown error (status hash fail)', 'danger'));
                            setloadingStake(false)
                        }
                    })
                    .catch((error) => {
                        if (error) {
                            if (error.code == 4001 && error.message) {
                                Store.addNotification(addNotify(error.message, 'danger'));
                            } else if (error.reason) {
                                Store.addNotification(addNotify(error.reason, 'danger'));
                            } else {
                                if (error.data && error.data.message) {
                                    Store.addNotification(addNotify(error.data.message, 'danger'));
                                }
                            }
                        }
                        setloadingStake(false)
                    })



            })
            .catch((err) => {
                console.log('err stakeRewards', err)
               
                Store.addNotification(addNotify(err.reason || err?.message, 'danger'));
                setloadingStake(false)
            })
        } catch (error) {
            console.log('error stakeRewards', error)
            
            Store.addNotification(addNotify(error, 'danger'));
            setloadingStake(false)
        }
    }

    const handleWithdrawReward = async () => {
        try {
            setloadingWithdraw(true)
            await contractStake.claim().then((res) => {
                console.log('res Withdraw', res)
                res.wait()
                    .then((resWait) => {
                        if (resWait?.status === 1) {
                            setloadingWithdraw(false)
                            setIsAction(!isAction)
                           
                            Store.addNotification(addNotify("Withdraw rewards successfully", 'success'));
                            setTimeout(() => {
                                window.location.reload()
                            }, 1500);
                        } else {
                            
                            Store.addNotification(addNotify("Unknown error (status hash fail)", 'danger'));
                            setloadingWithdraw(false)
                        }
                    }
                    )
                    .catch((error) => {
                        console.log('err', error)
                        if (error) {
                            if (error.code == 4001 && error.message) {
                                Store.addNotification(addNotify(error.message, 'danger'));
                            } else if (error.reason) {
                                Store.addNotification(addNotify(error.reason, 'danger'));
                            } else {
                                if (error.data && error.data.message) {
                                    Store.addNotification(addNotify(error.data.message, 'danger'));
                                }
                            }
                        }
                        setloadingWithdraw(false)
                    })

            })
                .catch((error) => {
                    console.log('sc err ', error)
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            Store.addNotification(addNotify(error.message, 'danger'));
                        } else if (error.reason) {
                            Store.addNotification(addNotify(error.reason, 'danger'));
                        } else {
                            if (error.data && error.data.message) {
                                Store.addNotification(addNotify(error.data.message, 'danger'));
                            }
                        }
                    }
                    setloadingWithdraw(false)
                })
        } catch (error) {
            console.log('error ', error)
            Store.addNotification(addNotify('Error!', 'danger'));
            setloadingWithdraw(false)
        }
    }

    const formatNumber = (x, max) => {

        let tem = x * 10000000
        let tem1 = Math.floor(tem)

        let tem2 = tem1 / 10000000


        if (tem2) {
            return x.toLocaleString("en-US", {
                minimumFractionDigits: 1,
                maximumFractionDigits: max,
            });
        } else return 0;
    };

    if(MAPPING_CHAINID_DECIMAL.helatestnet !== chainId){
        return(
            <><ChainNotSupport /></>
        )
    }

    return (
        <>
            <div className="main-stake-pool-v2" style={{
                background: `url(${bgMainheaderRow}) no-repeat`,
            }}>
                <div className="container">
                    <div className="big-title">UpSwap Launch Pool</div>
                    <div className="text-center">
                        {timeUnstake && getUser > 0 ? (
                            <>
                                <div className="count-down-unstake mg-auto">
                                    {(timeUnstake * 1000 + timeDown) > Date.now() ? (
                                        <div className="title">Withdrawable in:</div>
                                    ) : (
                                        <div className="time-down"><span>Withdrawable Now</span></div>
                                    )}
                                    {(timeUnstake * 1000 + timeDown) > Date.now() && (
                                        <>
                                            <div className="time-down">
                                                <Countdown
                                                    date={timeUnstake * 1000 + timeDown}
                                                    renderer={rendererCountDown}
                                                />
                                            </div>
                                        </>
                                    )}
                                    
                                </div>
                            </>
                        ) : ''}
                    </div>
                    <div className="mar-center">
                    </div>
                    <div className="text-center">
                        {timeLock > 0 && amountBscsPool > 0 && stakeTime > 0 && getUser === 0 ? (
                            <>
                                <div className="count-down-unstake mg-auto">
                                    {(stakeTime * 1000 + timeLock * 1000) > Date.now() ? (<div className="title">Unstakeable in:</div>) : ('')}
                                    <div className="time-down">
                                        <Countdown
                                            date={stakeTime * 1000 + timeLock * 1000}
                                            renderer={rendererCountDown1}
                                        />  
                                    </div>
                                    
                                </div>  
                            </>
                        ) : ('')}
                    </div>
                    <div className="item-info row">
                        <div className="item-1">
                            <img src="./images/res-1.png" alt="" />
                            <div className="title-item">Total HLUP Staked</div>
                            {totalStaked && totalStaked > 0 ? (
                                <div className="num-item">{new Intl.NumberFormat("ja-JP").format(Number(totalStaked).toFixed(2))}</div>
                            ) : (
                                <div className="num-item">---</div>
                            )}
                        </div>
                        
                        <div className="item-1">
                            <img src="./images/res-2.png" alt="" />
                            <div className="title-item">Your Staked</div>
                            <div className="num-item">
                                {new Intl.NumberFormat("ja-JP").format(Number(amountBscsPool).toFixed(2))}
                                {/* {formatNumber(amountBscsPool, 3)} */}
                            </div>
                        </div>
                        <div className="item-1">
                            <img src="./images/res-2.png" alt="" />
                            <div className="title-item">Unstaked</div>
                            <div className="num-item">{formatNumber(getUser, 3)}</div>
                        </div>
                    </div>
                    <div className="item-info res">
                        <div className="item-1">
                            <img src="./images/res-3.png" alt="" />
                            <div className="title-item">Number of Stakers</div>
                            <div className="num-item">
                                {new Intl.NumberFormat("ja-JP").format(Number(numberStaked).toFixed(2))}
                            </div>
                        </div>
                        <div className="item-1">
                            <img src="./images/res-3.png" alt="" />
                            <div className="title-item">APY</div>
                            <div className="num-item">{apy}%</div>
                        </div>
                        <div className="item-1" style={{
                            background: `url(${bgMainheader}) 0% 50% / cover no-repeat`,
                        }}>
                            <div className="title-item">Rewards
                            </div>
                            <div className="num-item">
                                {new Intl.NumberFormat("ja-JP").format(Number(amountReward).toFixed(2))}
                                {/* {formatNumber(amountReward, 3)} */}
                            </div>
                            <div className="action-button">
                                {amountReward > 0.000001 && getUser <= 0 ? (
                                    <>
                                        <div className="btn-action">
                                            {loadingStake ? (
                                                <>
                                                    <button className="btn-stake-reward dis" >
                                                        Waiting{" "}
                                                        <Spin indicator={antIcon} />
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button className="btn-stake-reward" onClick={handleStakeReward}>
                                                        Stake reward
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                        <div className="btn-action">
                                            {loadingWithdraw ? (
                                                <>
                                                    <button className="btn-withdraw-reward dis" >
                                                        Waiting{" "}
                                                        <Spin indicator={antIcon} />
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button className="btn-withdraw-reward" onClick={handleWithdrawReward}>
                                                        Withdraw reward
                                                    </button>
                                                </>

                                            )}

                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="btn-action">
                                            <button className="btn-stake-reward dis">Stake reward</button>
                                        </div>
                                        <div className="btn-action">
                                            <button className="btn-withdraw-reward dis">Withdraw reward</button>
                                        </div>
                                    </>
                                )}

                            </div>
                        </div>
                    </div>
                    <div className="tabs-stake-pool v2">
                        <Tabs activeKey={defaultKey} onChange={callback}>
                            <TabPane tab="Stake" key="stake">
                                <Stake tabBox={tabBox} setIsCheckAction={setIsCheckAction} isCheckAction={isCheckAction} />
                            </TabPane>
                          
                            <TabPane tab="Unstake" key="unstake" disabled={(stakeTime * 1000 + timeLock * 1000) > Date.now() ? true : false}>
                                <UnStake tabBox={tabBox} setIsCheckAction={setIsCheckAction} isCheckAction={isCheckAction} />
                            </TabPane>
                            <TabPane tab="Withdraw" key="withdraw" disabled={(stakeTime * 1000 + timeLock * 1000) > Date.now() ? true : false}>
                                <Withdraw tabBox={tabBox} setIsCheckAction={setIsCheckAction} isCheckAction={isCheckAction} />
                            </TabPane>
                              
                            
                        </Tabs>
                    </div>
                </div>
            </div>
            <FooterHome />
        </>
    )
}

export default StakeFixible;
