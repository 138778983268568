
import React, { useState, useEffect, useCallback } from 'react';

import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Form, Select, Input, Space, Spin, Breadcrumb } from 'antd';
import { RedoOutlined } from '@ant-design/icons'

import NoneData from '../element/NoneData';
import FooterHome from '../common/footer/footerHome';
import NodataImg from '../common/asset/images/nodata-upcoming.png';
import { useActiveWeb3React } from '../../hooks';
import HelascriptionItem from './helascription-item';
import { HelascriptionApi } from '../../config/api/apiHelascription';
const { Content } = Layout;
const { Option } = Select;

const Helascripstion = () => {
    const { chainId, account, library } = useActiveWeb3React();
    const [totalRow, setTotalRow] = useState(0);
    const [classTab, setClassTabs] = useState('');
    const navigate = useNavigate();
    const typeProject = window.location.search.replace('?', '');
    const [form] = Form.useForm();
    const [helascriptions, setHelascriptions] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const [param, setParam] = useState(
        {
            page: 1,
            size: 20
        }
    );

    const getHelascriptionList = async () => {
        setLoadingData(true);
        try {
            let res = await HelascriptionApi.getHelascription(param);
            console.log('res', res)
            if (res.status === 200) {
                if (res.data.data.length > 0) {
                    setLoadingData(false);
                    setHelascriptions(res.data.data);
                } else {
                    setLoadingData(false);
                    setHelascriptions([]);
                }
            }
        } catch (error) { console.log('Error: ', error); }
        finally {
            setLoadingData(false);
        }
    }
    useEffect(() => {
        getHelascriptionList();
    }, []);

    return (
        <> 
            <div className='page-helascriptions'>
                
                <div className="container">
                    <Breadcrumb className='head-breadcrumb-airdrop'>
                        <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Stake Tokens</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className='p-helascriptions-title'>
                        <span className='title'>Latest Helascriptions</span>
                        <div className='d-flex'>
                            <Link className='text-weight-500 text-16 mr-15' to={`/helascription-market`}>Marketplace</Link>
                            <Link className='text-weight-500 text-16 mr-15' to={`/helascription-tokens`}>Tokens</Link>
                            <button className='btn-refresh'><RedoOutlined /> Refresh</button>
                        </div>
                    </div>
                    <div className="project-block">
                        {/* <div className="helascriptions-top">
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={24} xl={14} md={14} sm={14}>
                                    <div className='filter-staking-status'>
                                        <div className="projects-btn-status">
                                            <button type="button" className={classTab == 'active' ? 'active' : ''} onClick={() => displayTabs('active')}>
                                                Active
                                            </button>
                                            <button type="button" className={classTab == 'inactive' ? 'active' : ''} onClick={() => displayTabs('inactive')}>
                                                InActive
                                            </button>

                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} xl={10} md={10} sm={10}>
                                    <div className="project-filter">
                                        <Form form={form} name="horizontal_login" className='basic-form'>
                                            <Form.Item
                                                className='mb-0'
                                                name="search"
                                                rules={[{ required: true, message: 'Please input account address!' }]}
                                            >
                                                <Input className="input-search" placeholder="Search by keyword" />
                                            </Form.Item>

                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div> */}
                        <div className="project-content">
                            <Row gutter={20}>
                                {!loadingData ? (
                                <>
                                    {helascriptions?.length > 0 ? (
                                        helascriptions.map((item, index) => {
                                            return (
                                                <HelascriptionItem item={item} />
                                            )
                                        })
                                    ) : (
                                        <>
                                            <div className="c-nodata c-nodata--upcoming">
                                                <img src={NodataImg} alt="No Data" />
                                                <p>Upcoming<span>5 pools (+)</span></p>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (<>
                                <div className="loading-data">
                                    <Space size="middle">
                                        <Spin size="large" />
                                    </Space>
                                </div>
                            </>)}
                            </Row>
                        </div>
                    </div>
                </div>
                
            </div>

            
            <FooterHome />
        </>
    )
}

export default Helascripstion
