import React, {useState} from "react";
import { Tabs } from 'antd';
import ItemStake from "./itemStake";

//Images
import IconSearch from '../common/asset/images/icon-search.svg';
import IconList from '../common/asset/images/icon-list.svg';
import IconListActive from '../common/asset/images/icon-list-active.svg';
import IconGrid from '../common/asset/images/icon-grid.svg';
import IconGridActive from '../common/asset/images/icon-grid-active.svg';

const Stake = () => {
    const { TabPane } = Tabs;
    const displayView = localStorage.getItem("displayView");
    const [isDisplay, setIsDisplay] = useState(displayView === null ? 'list' : displayView);

    const displayList = () => {
        setIsDisplay('list');
        localStorage.setItem("displayView", "list");
    }
    const displayGrid = () => {
        setIsDisplay('grid');
        localStorage.setItem("displayView", "grid");
    }
    return(
        <div className="p-stake">
            <Tabs className="c-tabs" defaultActiveKey="1">
                <TabPane tab="Live" key="1">
                    <div className="p-stake__head">
                        <div className="p-stake__display">
                            <button onClick={() => displayList()}><img src={isDisplay==='list' ? IconListActive : IconList} alt="List" /></button>
                            <button onClick={() => displayGrid()}><img src={isDisplay==='grid' ? IconGridActive :IconGrid} alt="Grid" /></button>
                        </div>
                        <div className="p-stake__search">
                            <input type="text" placeholder="Search" />
                            <button type="submit"><img src={IconSearch} alt="Search" /></button>
                        </div>
                    </div>
                    <div className={`c-listStake is-${isDisplay}`}>
                        <ItemStake isDisplay={isDisplay} />
                        <ItemStake isDisplay={isDisplay} />
                        <ItemStake isDisplay={isDisplay} />
                        <ItemStake isDisplay={isDisplay} />
                    </div>
                </TabPane>
                <TabPane tab="Finished" key="2">
                Tab 2
                </TabPane>
            </Tabs>
        </div>
    )
}

export default Stake;