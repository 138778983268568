import { instance } from '../../apiBase/instance';
let tokenID = localStorage.getItem('tokenId');
export const BaseAPI = {

	getChainNetworkList(params) {
		return instance.get(`chain-lists/client`, { params });
	},
	getSettings() {
		return instance.get(`settings`, {});
	},
	getSettingsByChain(chainId) {
		return instance.get(`settings/chain-id/${chainId}`, {});
	},
	getMaxMintByWallet(ownerAddress) {
		return instance.get(`/total-current-nft/${ownerAddress}`, {
			headers: { Authorization: `Bearer ${tokenID}` }
		});
	},
	
};
