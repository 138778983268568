import web3 from 'web3'
import BigNumber from 'bignumber.js'

const convertNumber = (value, tokenDecimals) => {
  return web3.utils.toHex(web3.utils.toWei(value, tokenDecimals));
}

export const convertToWei = (value, tokenDecimals) => {
  if(tokenDecimals === '1e8') {
    return new BigNumber(value).times(new BigNumber(10).pow(8)).toString();
  }else if(tokenDecimals === '1e10') {
    return new BigNumber(value).times(new BigNumber(10).pow(10)).toString();
  }
  else {
    return web3.utils.toWei(value.toString(), tokenDecimals);
  }
  
}

export const convertFromWei = (value, tokenDecimals) => {
  if(tokenDecimals === '1e8') {
    return Number(value) * 1e8;
  }else if(tokenDecimals === '1e10') {
    return Number(value) * 1e10;
  }
  else {
    return web3.utils.fromWei(value.toString(), tokenDecimals);
  }
}

export const sleepSystem = (s) => {
  return new Promise(resolve => setTimeout(resolve, s));
}

export const _joinPool = async (
  joinPoolContract,
  amountStr,
  whiteListId,
  roundId,
  signature,
  tokenDecimals
) => {

  const amount = convertToWei(amountStr, tokenDecimals);
  const args = [amount, whiteListId, roundId, signature];
  return await joinPoolContract.join(...args);
}

export const _approveBUSD = async (contractBUSD, address, amount, tokenDecimals) => {
  const amountTemp = convertToWei(amount, tokenDecimals);
  const result = await contractBUSD.approve(address, amountTemp);
  return result;
}

export const setApproveFixed = async (itemContract, mkAddress, nftId) => {
	const args = [mkAddress, nftId];
	const transaction = await itemContract.approve(...args);
	return transaction;
};

export const setApproveAuction = async (itemContract, auctionAddress, nftId) => {
	const args = [auctionAddress, nftId];
	const transaction = await itemContract.approve(...args);
	return transaction;
};

export const isApprovedForAll = async (itemContract, mkAddress) => {
	const args = [mkAddress, true];
	const transaction = await itemContract.isApprovedForAll(...args);
	return transaction;
};

export const _nftListed = async (marketContract, nftAddress, nftId) => {
	const args = [nftAddress, nftId];
	const transaction = await marketContract.listings(...args);
	return transaction;
};

export const _createListing = async (marketContract, account, nftAddress, nftId, priceSale, currency) => {
  const amountTemp = convertToWei(priceSale, "ether");
  // const nftTemp = convertToWei(nftId, "ether");
  // console.log('nftAddress', nftAddress);
  // console.log('nftId', Number(nftId));
  // console.log('amountTemp', amountTemp);
  // console.log('currency', currency);
  let args = [nftAddress, nftId, amountTemp, currency];
  return marketContract.createListing(...args);
}
export const _cancelListing = async (marketContract, nftAddress, nftId) => {
  let args = [nftAddress, nftId];
  return marketContract.cancelListing(...args);
}
export const _buyNft = async (marketContract, nftAddress, nftId) => {
  let args = [nftAddress, nftId];
  return marketContract.buyNft(...args);
}

export const _buyNftHLUSD = async (marketContract, nftAddress, nftId, price) => {
  const amount = convertToWei(price, "ether");

  let args = [nftAddress, nftId];
  return marketContract.buyNft(...args, {value: amount});
}

// Aution create

export const _createListingAuction = async (contractBid, nftAddress, nftId, priceSale, currency, duration) => {

  const amountTemp = convertToWei(priceSale, "ether");

  let args = [nftAddress, currency, nftId, amountTemp, duration];
  return contractBid.createAuction(...args);
}

export const _cancelListingAuction = async (contractBid, nftId) => {
  let args = [nftId];
  return contractBid.refund(...args);
}

export const _claimToken = async (contractBid, auctionId) => {
  let args = [auctionId];
  return contractBid.claimToken(...args);
}

export const _claimNFT = async (contractBid, auctionId) => {
  let args = [auctionId];
  return contractBid.claimNFT(...args);
}

export const _makeOfferAuction = async (contractBid, nftId, priceSale) => {
  const amountTemp = convertToWei(priceSale, "ether");
  let args = [nftId, amountTemp];
  return contractBid.bid(...args);
}