
import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Modal, Form, Select, Input, Tooltip, Button, Table, Space, Spin } from 'antd';
import { CalculatorFilled, DownOutlined, PlusOutlined, QuestionCircleOutlined, RiseOutlined, UpOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from '../../../../hooks';
import web3 from 'web3';
import { BigNumber, ethers } from 'ethers';
import tokenInfoAbi from '../../../../config/abi/tokenInfo.json';
import { getInformationByChain } from '../../../../config/network/multichainAddresses';
import stakingAbi from '../../../../config/abi/stakingAbi.json';
import {
    useContract
} from "../../../../hooks/useContract";

import {
    approveToken, getTokenAllowance, getTokenInformation
} from '../../../utils';
import {
    getProgressTime,
    rendererCountDown,
    _totalStakedUser,
    _totalStaked,
    _aprRewardPerBlock,
    _stakeToken,
    _totalReward,
    _harvetReward,
    _unstaking
} from "../utilsStaking"
import TableRoi from './TableRoi';
import ApyCalculatorModal from '../ApyCalculatorModal';
import { Loader } from '../../../common/component';
import { Store } from 'react-notifications-component';
import addNotify from '../../../common/Notify/addNotify';
import { SCAN_URL } from '../../../../constants';
import { getBalanceNumber } from '../../../../utils/formatBalance';
import { getPoolApr } from '../../../../utils/apr';

let intervalReward = null;
const StakingItem = (props) => {
    const { item } = props;
    const { chainId, account, library } = useActiveWeb3React();
    const [form] = Form.useForm();
    const [showRoi, setShowRoi] = useState(false);
    const [showAmountPopup, setShowAmountPopup] = useState(false);
    const [allowance, setAllowance] = useState(0);
    const [isShowDetail, setIsShowDetail] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const [stakeTokenBalance, setStakeTokenBalance] = useState(0);
    const [totalStakedUsers, setTotalStakedUser] = useState(0);
    const [totalStaked, setTotalStaked] = useState(0);
    const [totalReward, setTotalReward] = useState(0);
    const [decimal, setDecimal] = useState(0);
    const [tokenEarnPrice, setTokenEarnPrice] = useState(1.5);
    const [tokenPerBlock, setTokenPerBlock] = useState(0);

    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    let earnTokenAddress = item.earnToken;
    let stakeTokenAddress = item.stakeToken;
    let poolContractAddress = item.poolContract;

    const poolContract = useContract(poolContractAddress, stakingAbi);
    const stakeTokenContract = useContract(stakeTokenAddress, tokenInfoAbi);

    let scanUrl = SCAN_URL[chainId];

    const onShowRoi = () => {
        setShowRoi(true);
    }
    const onShowAddAmount = () => {
        setShowAmountPopup(true);
    }

    const onShowDetail = (id) => {
        if (isShowDetail) {
            setIsShowDetail(false);
        } else {
            setIsShowDetail(true);
        }
    }

    async function stakeTokenBalanceSc() {
        if (typeof window.ethereum !== 'undefined') {
            if (account) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const contract = new ethers.Contract(stakeTokenAddress, tokenInfoAbi, provider);
                const tokenBalance = await contract.balanceOf(account);
                return tokenBalance.toString();
            } else {
                return 0;
            }
        }
    }
    const getStakeTokenBalance = () => {
        stakeTokenBalanceSc()
            .then((val) => {
                if (val > 0) {
                    let _balance = web3.utils.fromWei(web3.utils.toBN(val), 'ether');
                    let tokenBalance = Math.round(parseFloat(_balance) * 10000) / 10000;
                    setStakeTokenBalance(tokenBalance);
                } else {
                    setStakeTokenBalance(0);
                }
            })
            .catch((e) => { });
    };

    const closeBox = (e) => {
        e.preventDefault()
        setShowRoi(false);
        setShowAmountPopup(false);
    };
    // APPROVE TOKEN

    const handleApproveToken = async (e) => {
        try {
            setIsLoading(true);
            e.preventDefault()

            await approveToken(tokenInfoAbi, stakeTokenAddress, library, account, poolContractAddress)
                .then((txn) => {
                    if (txn && txn.hash) {
                        let countNoti = 0;
                        const interval = setInterval(function () {
                            (async () => {
                                const res = await w3.eth.getTransactionReceipt(txn.hash);
                                if (res) {
                                    clearInterval(interval);
                                    if (res.status && res.blockNumber) {
                                        !countNoti && Store.addNotification(addNotify('Approve Successfully', 'success'));
                                        countNoti++;
                                        setAllowance(Math.pow(2, 256));
                                    } else {
                                        Store.addNotification(addNotify('Approve Failed', 'danger'));
                                    }
                                    setIsLoading(false);
                                }
                            })();
                        }, 1000);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                    if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
                });
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    // confirm stake
    const onConfirmAmount = async (values) => {

        setIsLoading(true);

        await _stakeToken(poolContract, values.amount)
            .then((txn) => {

                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;
                                        let totalStakedNew = parseFloat(totalStaked) + parseFloat(values.amount);
                                        let totalStakedUsersNew = parseFloat(totalStakedUsers) + parseFloat(values.amount);
                                        Store.addNotification(addNotify('Stake Successfully', 'success'));
                                        setShowAmountPopup(false);
                                        setIsLoading(false);
                                        setTotalStaked(totalStakedNew);
                                        setTotalStakedUser(totalStakedUsersNew);
                                    }
                                } else {
                                    Store.addNotification(addNotify('Stake Failed', 'danger'));
                                }
                                setIsLoading(false);
                            }
                        })();
                    }, 1000);
                }
            })
            .catch((error) => {
                setShowAmountPopup(false);
                if (error) {
                    if (error.code == 4001 && error.message) {
                        Store.addNotification(addNotify(error.message, 'danger'));
                    } else if (error.reason) {
                        Store.addNotification(addNotify(error.reason, 'danger'));
                    } else {
                        if (error.data && error.data.message) {
                            Store.addNotification(addNotify(error.data.message, 'danger'));
                        }
                    }
                }
                setIsLoading(false);
            });
    }

    const onHarvetReward = async () => {

        setIsLoading(true);

        await _harvetReward(poolContract, true)
            .then((txn) => {

                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;
                                        Store.addNotification(addNotify('Harvest Successfully', 'success'));
                                        setIsLoading(false);
                                        setTotalReward(0);
                                    }
                                } else {
                                    Store.addNotification(addNotify('Harvest Failed', 'danger'));
                                }
                                setIsLoading(false);
                            }
                        })();
                    }, 1000);
                }
            })
            .catch((error) => {
                setShowAmountPopup(false);
                if (error) {
                    if (error.code == 4001 && error.message) {
                        Store.addNotification(addNotify(error.message, 'danger'));
                    } else if (error.reason) {
                        Store.addNotification(addNotify(error.reason, 'danger'));
                    } else {
                        if (error.data && error.data.message) {
                            Store.addNotification(addNotify(error.data.message, 'danger'));
                        }
                    }
                }
                setIsLoading(false);
            });


    }
    const onUnstake = async () => {

        setIsLoading(true);

        await _unstaking(poolContract, false)
            .then((txn) => {

                if (txn && txn.hash) {
                    let countNoti = 0;
                    const interval = setInterval(function () {
                        (async () => {
                            const res = await w3.eth.getTransactionReceipt(txn.hash);
                            if (res) {
                                clearInterval(interval);
                                if (res.status && res.blockNumber) {
                                    if (!countNoti) {
                                        countNoti++;
                                        Store.addNotification(addNotify('Unlock Successfully', 'success'));
                                        setIsLoading(false);
                                        setTotalReward(0);
                                        setTotalStaked(0);
                                        setTotalStakedUser(0);
                                    }
                                } else {
                                    Store.addNotification(addNotify('Unlock Failed', 'danger'));
                                }
                                setIsLoading(false);
                            }
                        })();
                    }, 1000);
                }
            })
            .catch((error) => {
                setShowAmountPopup(false);
                if (error) {
                    if (error.code == 4001 && error.message) {
                        Store.addNotification(addNotify(error.message, 'danger'));
                    } else if (error.reason) {
                        Store.addNotification(addNotify(error.reason, 'danger'));
                    } else {
                        if (error.data && error.data.message) {
                            Store.addNotification(addNotify(error.data.message, 'danger'));
                        }
                    }
                }
                setIsLoading(false);
            });


    }

    const onSetMax = (stakeTokenBalance) => {
        form.setFieldsValue(
            {
                amount: stakeTokenBalance
            }
        )
    }

    const getTokenInfo = async (stakeTokenAddress, poolContractAddress) => {
        setIsLoadingInfo(true);
        let allowanceSC = await getTokenAllowance(tokenInfoAbi, stakeTokenAddress, account, poolContractAddress)
        const tokenInfoSC = await getTokenInformation(tokenInfoAbi, stakeTokenAddress);
        setDecimal(tokenInfoSC.decimals);
        setIsLoadingInfo(false);
        setAllowance(parseFloat(ethers.utils.formatUnits(allowanceSC, parseInt(tokenInfoSC.decimals))));
    }

    const getRewardTokenInterval = () => {
        intervalReward = setInterval(() => {
            (async () => {
                try {
                    _totalReward(poolContract, earnTokenAddress, account).then(
                        (res) => {
                            let totalReward = ethers.utils.formatUnits(res, 'ether');
                            setTotalReward(totalReward);
                        }
                    );
                } catch (error) {
                    console.log(error);
                }
            })();
        }, 5000);
    };

    // get total staked by account

    useEffect(() => {
        if (account) {
            _totalStakedUser(poolContract, account).then(
                (res) => {
                    let totalStakedUser = ethers.utils.formatUnits(res.amount, 'ether');
                    setTotalStakedUser(totalStakedUser);

                }
            );
            _aprRewardPerBlock(poolContract, earnTokenAddress).then(
                (res) => {
                    // console.log('res-----', res.toString());
                    // let aprReward = ethers.utils.formatUnits(res, 'ether');
                    setTokenPerBlock(res.toString());

                }
            );
            _totalStaked(poolContract).then(
                (res) => {
                    let totalStaked = ethers.utils.formatUnits(res, 'ether');
                    setTotalStaked(totalStaked);
                }
            );

            _totalReward(poolContract, earnTokenAddress, account).then(
                (res) => {
                    let totalReward = ethers.utils.formatUnits(res, 'ether');
                    setTotalReward(totalReward);
                }
            );

            getRewardTokenInterval();

        }

    }, [account])

    useEffect(() => {
        if (account && stakeTokenAddress) {
            getStakeTokenBalance();
        }
    }, [account, stakeTokenAddress]);

    useEffect(() => {
        if (stakeTokenAddress && poolContractAddress && account) {

            getTokenInfo(stakeTokenAddress, poolContractAddress)
        }
    }, [account, stakeTokenAddress, poolContractAddress]);

    let stakingTokenDecimals = 18;
    let earningTokenDecimals = 18;
    let stakingTokenPrice = 1;
    // let earningTokenPrice = 1.5;

    const apr = item?.poolStatus === "ACTIVE" ? getPoolApr(
        stakingTokenPrice, 
        tokenEarnPrice,
        totalStaked,
        tokenPerBlock,
        earningTokenDecimals
        )
    : 0;

    return (
        <>

            <Col className="gutter-row" xs={24} xl={8} md={8} sm={8} key={item.id}>
                <div className='staking-item'>
                    <div className='staking-item-header'>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" xs={24} xl={24} md={24} sm={24}>
                                <div className='staking-period'>{item?.periodTime} day</div>
                                <div className='staking-title'> {item.title}</div>
                                <div className='staking-description'>Stake <span className='text-weight-600'>{item.stakeTokenName}</span> - Earn <span className='text-weight-600'>{item.earnTokenName}</span></div>
                                <div className='staking-status'>

                                    {item.maxAmountStake === 0 || item.maxAmountStake === null ? (
                                        <>Unlimited</>
                                    ) : (
                                        <>Max {Number(item.maxAmountStake).toLocaleString()}</>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='staking-item-content'>
                        <div className='apr-content bg-darkblue'>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={12}>
                                    <div className='apr-content-caculator'>
                                        APY
                                        {/* <span><CalculatorFilled onClick={() => onShowRoi()} /></span>
                                        <Tooltip placement="top" title={item.aprTip}>
                                            <QuestionCircleOutlined className='icon-title' />
                                        </Tooltip> */}
                                    </div>
                                </Col>
                                <Col className="gutter-row text-right" xs={12}>
                                    <div className='apr-content-percent text-darkblue text-weight-600 text-16'>{Number(apr).toFixed(1)}%</div>
                                </Col>
                            </Row>
                        </div>
                        <div className='apr-button'>
                            {account ? (
                                <>
                                    {isLoadingInfo ? (
                                        <>
                                            <Space align='center'><Spin size="small" /></Space>
                                        </>
                                    ) : (
                                        <>
                                            {allowance > 0 ? (
                                                <>
                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                        <Col className="gutter-row" xs={14}>
                                                            <div className='total-staking'>
                                                                <p className='mb-0'><small>Staked {item.stakeTokenName}</small></p>
                                                                <p className='mb-0 total'>{Number(totalStakedUsers).toLocaleString()} {item.stakeTokenName}</p>
                                                                <p className='mb-0'><small>$0.0000</small></p>
                                                            </div>
                                                        </Col>
                                                        <Col className="gutter-row text-right" xs={10}>
                                                            {item.maxAmountStake === 0 || item.maxAmountStake === null ? (
                                                                <button style={{ marginTop: '10px' }} disabled={item?.poolStatus !== "ACTIVE"} className='btn-basic w-fitcontent' onClick={onShowAddAmount}><PlusOutlined /></button>
                                                            ) : (
                                                                <button style={{ marginTop: '10px' }} disabled={totalStaked >= item.maxAmountStake || item?.poolStatus !== "ACTIVE"} className='btn-basic w-fitcontent' onClick={onShowAddAmount}><PlusOutlined /></button>
                                                            )}
                                                            
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                        <div className='modal-btn-group'>
                                                            {totalReward > 0 && (
                                                                <button className="btn-basic" disabled={item?.poolStatus !== "ACTIVE" || isLoading} onClick={onHarvetReward}>Harvest {isLoading && <Spin className="ml-10" size="small" />}</button>
                                                            )}
                                                            {totalStakedUsers > 0 && (
                                                                <button className="btn-basic-default" disabled={item?.poolStatus !== "ACTIVE" || isLoading} onClick={onUnstake}>Unstake {isLoading && <Spin className="ml-10" size="small" />}</button>
                                                            )}
                                                        </div>
                                                    </Row>
                                                </>
                                            ) : (
                                                <button onClick={handleApproveToken} disabled={item?.poolStatus !== "ACTIVE"} className='btn-basic w-200'>Approve {item.stakeTokenName}</button>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <Button htmlType='button' disabled className='btn-all'>Connect Wallet</Button>
                            )}
                        </div>
                        {account && (
                            <div className='apr-description'>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={12}>
                                        <p>Recent {item.earnTokenName} profit</p>
                                    </Col>
                                    <Col className="gutter-row text-right" xs={12}>
                                        {allowance > 0 && (
                                            <p><span className='text-darkblue text-weight-600 text-16'>{totalReward > 0 ? (Number(totalReward.toLocaleString()).toFixed(3)).toLocaleString() : 0}</span></p>
                                        )}
                                    </Col>
                                </Row>

                                <p className='mb-0'>
                                    <strong className='text-darkblue text-16'>{item.unStakeFee}% unstaking fee until</strong>
                                    <Tooltip placement="top" title={`Only applies within ${item.unStakeFeeTime} days and 2 hours of staking. Unstaking after ${item.unStakeFeeTime} days and 2 hours will not include a fee. Timer resets every time you stake new UPSwap Finance in the pool.`}>
                                        <QuestionCircleOutlined className='icon-title' />
                                    </Tooltip>
                                </p>
                            </div>
                        )}
                        {isShowDetail && (
                            <div className='staking-detail-content bg-darkblue'>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={12}>
                                        <p className='text-darkblue text-16'>Total staked</p>
                                    </Col>
                                    <Col className="gutter-row text-right" xs={12}>
                                        <p><span className='text-darkblue text-weight-600 text-16'>{Number(totalStaked).toLocaleString()} </span></p>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={14}>
                                        <p className='text-darkblue text-16'>
                                            Earned ({item.earnTokenName})
                                        </p>
                                    </Col>
                                    <Col className="gutter-row text-right" xs={10}>
                                        <p><span className='text-darkblue text-weight-600 text-16'>
                                                {allowance > 0 && (
                                                    <p><span className='text-darkblue text-weight-600 text-16'>{totalReward > 0 ? (Number(totalReward.toLocaleString()).toFixed(3)).toLocaleString() : 0}</span></p>
                                                )}
                                            </span>
                                        </p>
                                    </Col>
                                </Row>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" xs={12}>
                                        <p className='mb-0'>
                                            <a className='text-info text-16' target="_blank" href={`${scanUrl}/address/${item?.poolContract}`}>View Contract <RiseOutlined className='icon-title' /></a>
                                        </p>
                                    </Col>
                                    <Col className="gutter-row text-right" xs={12}>
                                        <p className='mb-0'>
                                            <Link className='text-info text-16' to="">UPSwap <RiseOutlined className='icon-title' /></Link>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        )}

                    </div>
                    <div className='staking-item-detail'>
                        <Button htmlType='button' onClick={() => onShowDetail('1')} className='btn-detail'>Detail {isShowDetail ? (<UpOutlined />) : (<DownOutlined />)}</Button>
                    </div>
                </div>
            </Col>

            <Modal
                title={<div className="text-md custom-modal-title">ROI</div>}
                visible={showRoi}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">
                    <p>Calculated based on current rates. Rates are estimates provided for your convenience only, and by no means represent guaranteed returns.</p>

                    <ApyCalculatorModal
                        tokenPrice={tokenEarnPrice}
                        apr={apr}
                        earningTokenSymbol={item.earnTokenName}
                        performanceFee={item.unStakeFee}
                    />

                    <div className="modal-btn-group mt-15">
                        <button className="btn-basic-default" onClick={closeBox}>Close</button>
                    </div>
                </div>

            </Modal>

            <Modal
                title={<div className="text-md custom-modal-title">Stake {item.stakeTokenName} Tokens</div>}
                visible={showAmountPopup}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">

                    <Form
                        form={form}
                        name="basic"
                        className='basic-form'
                        autoComplete="off"
                        onFinish={onConfirmAmount}
                    >

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" xs={12}>
                                <p className='mb-0' style={{ paddingTop: '7px' }}>
                                    <span className='text-darkblue text-16'>Max stake</span>: {item.maxAmountStake > 0 ? (
                                        <span className='text-darkblue text-weight-600 text-16'>{item.maxAmountStake.toLocaleString()}</span>
                                    ) : (
                                        <>Unlimit</>
                                    )}
                                </p>
                            </Col>
                            <Col className="gutter-row" xs={12}>
                                <p className='text-right text-darkblue text-16'>
                                    <strong className='text-danger'>{stakeTokenBalance.toLocaleString()}</strong> {item.stakeTokenName} Available
                                    {item.maxAmountStake == 0 || item.maxAmountStake == null ? (
                                        <>
                                            <Button htmlType='button' onClick={() => onSetMax(stakeTokenBalance)} className='btn-max btn-all text-darkblue'>Max</Button>
                                        </>
                                    ):(
                                        <>
                                            <Button htmlType='button' onClick={() => onSetMax(stakeTokenBalance > Number(item.maxAmountStake) ? Number(item.maxAmountStake) : stakeTokenBalance)} className='btn-max btn-all text-darkblue'>Max</Button>
                                        </>
                                    )}
                                </p>
                            </Col>
                        </Row>
                        <Form.Item name="amount" label="Amount"
                            rules={[
                                {
                                    validator: (rule, value, cb) => {

                                        if (!value || parseFloat(value) <= 0) {
                                            cb('Invalid value')
                                        } else if (item.maxAmountStake > 0 && parseFloat(value) > item.maxAmountStake) {
                                            cb('Invalid limit')
                                        } else if (parseFloat(value) > stakeTokenBalance) {
                                            cb("Insufficient Balance");
                                        } else {
                                            cb();
                                        }
                                    }
                                }
                            ]}
                        >
                            <Input className="amount-buy" placeholder="Ex: 1000" />

                        </Form.Item>
                        <div className="modal-btn-group">
                            <button className="btn-basic" type="submit" disabled={isLoading}>Confirm {isLoading && <Spin className="ml-10" size="small" />}</button>
                            <button className="btn-basic-default" onClick={closeBox} disabled={isLoading}>Close {isLoading && <Spin className="ml-10" size="small" />}</button>
                        </div>
                    </Form>

                </div>

            </Modal>

            {isLoading && (
                <Loader />
            )}

        </>
    )
}

export default StakingItem
