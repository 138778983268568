import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import { Tag, Progress } from 'antd';
import { useActiveWeb3React } from '../../../../hooks';
import Countdown from 'react-countdown';
import {
    getLaunchpadInfoByChainId
} from '../../../utils';
import launchpadAbi from '../../../../config/abi/launchpadAbi.json';

import { ethers } from 'ethers';
import { getCurrencyDecimals } from '../../../../config/network/config';

const IcoItem = (props) => {
    const { item, logoNetwork, titleNetwork } = props;

    const [hardCap, setHardCap] = useState(0);
    const [raisedAmount, setRaisedAmount] = useState(0);

    const { chainId, account, library } = useActiveWeb3React();
    let nowDate = new Date(Date.now());
    let startDate = new Date(item?.startTime);
    let endDate = new Date(item?.endTime);

    function isImgLink(url) {
        if(typeof url !== 'string') return false;
        return(url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|webp)(\?(.*))?$/gmi) != null);
    }

    const getLaunchpadInformation = async (roundAddress, itemChainId) => {

        try {
            let infoSC = await getLaunchpadInfoByChainId(launchpadAbi, roundAddress, itemChainId);
            let decimals = item?.currencyPayDecimals || getCurrencyDecimals(itemChainId, item?.currencyPay) || 18
            if (infoSC) {
                setRaisedAmount(parseFloat(ethers.utils.formatUnits(infoSC.raisedAmount, decimals)));
                setHardCap(parseFloat(ethers.utils.formatUnits(infoSC.hardCap, decimals)));
            }

        } catch (e) {
            console.log(e);

        }

    }

    useEffect(() => {
        if (item?.roundAddress.length > 0 && item?.chainId) {
            getLaunchpadInformation(item?.roundAddress, item?.chainId)
        }

    }, [item?.roundAddress]);

    const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            window.location.reload();
        }
        if (days > 1) {
            return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
        } else if (days === 1) {
            return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
        } else {
            return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
        }
    };
    const getProgressTime = useCallback((startTime) => {
        const now = new Date();
        const utcDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), 0);
        const startDate = new Date(startTime);
        const startTS = Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            startDate.getHours(),
            startDate.getMinutes(),
            startDate.getSeconds(),
            0
        );
        let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600);
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60);
        if (days >= 1) {
            return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        } else {
            return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
        }
    }, []);


    const StatusRender = (nowDate, startDate, endDate, roundStatus) => {
        let status = '';
        let color = '';
        if (startDate > nowDate && endDate > nowDate && roundStatus != 'CANCEL') {
            status = 'upcoming';
            color = '#e8e15a';
        }
        if (startDate < nowDate && endDate > nowDate && roundStatus != 'CANCEL') {
            status = 'opening';
            color = '#87d068';
        }
        if (startDate < nowDate && endDate < nowDate && roundStatus != 'CANCEL') {
            status = 'closed';
            color = '#919191';
        }
        if (roundStatus == 'FILLED') {
            status = 'fiiled';
            color = '#108ee9';
        }
        if (roundStatus == 'CANCEL') {
            status = 'canceled';
            color = 'red';
        }

        return <Tag className='mr-0' color={color}>{status}</Tag>;
    }



    return (
        <>
            <Link to={`/ico/detail/${item?.campaignIco?.id}/${item?.campaignIco?.handle}`}>
                <div className="project-item">
                    <div className="project-image">
                        <Link to={`/ico/detail/${item?.campaignIco?.id}/${item?.campaignIco?.handle}`}>
                            <img src={item?.campaignIco?.backgroundLink?.length > 0 && isImgLink(item?.campaignIco?.backgroundLink) ? item?.campaignIco.backgroundLink : "../../images/ico-default.png"} />
                        </Link>
                        <div className="project-chain">
                            <div className="project-token-main">{item?.campaignIco?.tokenInfo?.symbol}</div>
                            {titleNetwork.length > 0 && logoNetwork.length > 0 && (
                                <div className="project-token-chain">
                                    <img title={titleNetwork} src={logoNetwork} />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="project-item-content">
                        <div className="project-name flex-btw gap-10">
                            <Link className='text-darkblue text-18 text-weight-600' to={`/ico/detail/${item?.campaignIco?.id}/${item?.campaignIco?.handle}`}>{item?.campaignIco?.name} {item?.name ? ' - ' + item?.name : ''}</Link>
                            {StatusRender(nowDate, startDate, endDate, item?.roundStatus)}
                        </div>
                        
                        <div className="project-total-raise">
                            <span>Soft/Hard Cap</span>
                            <span className='text-black-green text-weight-500'>{item?.campaignIco?.softCap ? new Intl.NumberFormat("ja-JP").format(item?.campaignIco?.softCap) : 0} {item?.currencyPay}/ {item?.campaignIco?.hardCap ? new Intl.NumberFormat("ja-JP").format(item?.campaignIco?.hardCap) : 0} {item?.currencyPay}</span>
                        </div>
                        <div className="project-total-raise">
                            <span>Price</span>
                            <span className='text-black-green text-weight-500'>1 {item?.currencyPay} = {item?.swapRate ? new Intl.NumberFormat("ja-JP").format(item?.swapRate) : 0} {item?.campaignIco?.tokenInfo?.symbol}</span>
                        </div>
                        <hr></hr>
                        <div className="project-registration">
                            {startDate > nowDate && endDate > nowDate && (
                                <>
                                    <span className="sale-start-in">Sale start in </span>
                                    <Countdown
                                        date={getProgressTime(startDate)}
                                        renderer={rendererCountDown}
                                    />
                                </>
                            )}
                            {startDate < nowDate && endDate > nowDate && (
                                <>
                                    <span className="sale-start-in">Sale end in </span>
                                    <Countdown
                                        date={getProgressTime(endDate)}
                                        renderer={rendererCountDown}
                                    />
                                </>
                            )}
                            {endDate < nowDate && (
                                <>
                                    <span className="sale-start-in">Closed </span>
                                </>
                            )}

                        </div>
                        <Progress percent={item?.soldPercent !== null && item?.soldPercent > 0 ? item?.soldPercent : Math.ceil(raisedAmount / hardCap * 100)} className="project-progress-percent" />
                        <div className="project-total-sell">

                        
                            <span className={`text-black-green text-weight-500 ${item?.soldPercent !== null ? 'text-hidden' : ''}`}>{new Intl.NumberFormat("ja-JP").format(raisedAmount)} {item?.currencyPay}</span>
                            <span className='text-black-green text-weight-500'>{new Intl.NumberFormat("ja-JP").format(item?.campaignIco?.hardCap)} {item?.currencyPay}</span>

                        </div>
                        <div className="project-total-raise">
                            <span>Liquidity %: </span>
                            <span className='text-black-green text-weight-500'>
                                {item?.doManualListing ? (
                                    <>Manual Listing</>
                                ) : (
                                    <>
                                        {item?.liquidityPercent} %
                                    </>
                                )}

                            </span>
                        </div>
                        <div className="project-total-raise">
                            <span>Lockup Time : </span>
                            <span>
                                {item?.doManualListing ? (
                                    <>
                                        Unlocked
                                    </>
                                ) : (
                                    <>
                                        {item?.campaignIco?.liquidityLockup} days
                                    </>
                                )}

                            </span>
                        </div>
                    </div>
                </div>
            </Link>
            
        </>
    )
}

export default IcoItem
