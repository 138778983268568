
import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Modal, Form, Select, Input, Tooltip, Button, Table, Space, Spin } from 'antd';
import { CalculatorFilled, DownOutlined, PlusOutlined, QuestionCircleOutlined, RiseOutlined, UpOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from '../../../hooks';
import web3 from 'web3';
import { getInformationByChain } from '../../../config/network/multichainAddresses';


import { Loader } from '../../common/component';
import { Store } from 'react-notifications-component';
import addNotify from '../../common/Notify/addNotify';

const HelascriptionItem = (props) => {
    const { item } = props;
    const { chainId, account, library } = useActiveWeb3React();

    const [isLoading, setIsLoading] = useState(false);

    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    let content = JSON.parse(item?.content);

    return (
        <>

            <Col className="gutter-row" xs={24} xl={6} md={6} sm={6} key={item?.id}>
                <Link to={`/helascription/${item?.id}`}>
                    <div className='helascription-item'>
                        <div className='helascription-content'>
                            <code>
                                <p>"p": "{content?.p}",</p>  
                                <p>"op": "{content?.op}",</p>  
                                <p>"tick": "{content?.tick}",</p>  
                                <p>"limit": "{content?.limit}"</p>
                            </code>
                        </div>
                        <div className='item-row'>
                            <span className='text-weight-600 text-black-green'>
                                #{item?.id.substring(0, 4)}...{item?.id.substring(item?.id.length - 4)}
                            </span>
                            <span>{item?.from.substring(0, 4)}...{item?.from.substring(item?.from.length - 4)}</span>
                        </div>
                        <div className='item-row'>
                            <span className='text-darkblue text-16 text-weight-500'>Content type:</span> <span>{item?.content_type}</span>
                        </div>
                    </div>
                </Link>
            </Col>
            

            {isLoading && (
                <Loader />
            )}

        </>
    )
}

export default HelascriptionItem
