import React, {useRef, useState, useEffect} from 'react';
import { Link } from 'react-router-dom'; 
import { Pagination, Spin } from "antd"; 
import {useDispatch, useSelector} from 'react-redux'; 
import allActions from '../../actions'; 
import NodataImg from '../common/asset/images/nodata.svg';
import GamehubHot from './gamehubHot';
import GamehubPopular from './gamehubPopular';
import GamehubRecommended from './gamehubRecommended';
import ItemGamehubRecommed from './itemGamehubRecommed';  
 
const GameHub = () => {   
    const [loadingPage, setLoadingPage] = useState(false);
    const [hotPrice, setHotPrice] = useState([])
    const dispatch = useDispatch(); 
    
    useEffect(() => {
        setLoadingPage(true);
        dispatch(allActions.gamehubAction.getGameHubByHotStatus("hot", (res)=>{ 
            if(res.callResult === true) { 
                // call api price 
                dispatch(allActions.gamehubAction.getPriceMarket(res.gamehubHotList, (res)=>{ 
                    setHotPrice(res?.data)
                })); 
                setLoadingPage(false);
            }
        })); 
    }, [dispatch]); 

    const gamehubHotList = useSelector(state=>state?.gamehub?.gamehubHotList); 

    return(
        <> 
        {loadingPage && <div class="c-loading"><img src="../../images/loading-3s.gif" alt="Loading" /></div>}
        <div className="p-gameHub">
            <div className="p-gameHub__banner">
            {gamehubHotList && [gamehubHotList[0]].map((item,index)=>(
                 <GamehubHot key={index.toString()} item={item} gamehubHotPrices={hotPrice}/>
            ))}
            </div> 
            <Catalogs/>  
        </div> 
        </> 
    )
}  

const Catalogs = React.memo(()=>{ 
    const [loadingPage, setLoadingPage] = useState(false); 
    const [inputText, setInputText] = useState("");
    const [catalogMenu, setCatalogMenu] = useState([]); 
    const [searchPrice, setSearchPrice] = useState([])
    const [page, setPage] = useState(0);  
    const dispatch = useDispatch(); 
    const size = 6;

    useEffect(() => {
        dispatch(allActions.gamehubAction.getCatalogs(async(response) => { 
            if(response) { 
                setCatalogMenu(response)
            }
        }))
    }, []); 

    useEffect(() => {
        if(inputText){
            setLoadingPage(true); 
            dispatch(allActions.gamehubAction.searchGameHub(inputText, page, (res)=>{  
                if(res.callResult === true) { 
                    dispatch(allActions.gamehubAction.getPriceMarket(res.gamehubSearch, (res)=>{  
                        setSearchPrice(res?.data)
                    })); 
                    setLoadingPage(false);
                }
            })); 
        } 
    }, [page]); 
  
    const gamehubSearch = useSelector(state => state?.gamehub?.gamehubSearch);  
    const gamehubSearchCount = useSelector(state => state?.gamehub?.gamehubSearchCount);
     
    
    function onChangePaging(pageNumber) {
        setPage(pageNumber - 1);
    }    
    const handleSearch = () =>{  
        setLoadingPage(true); 
        dispatch(allActions.gamehubAction.searchGameHub(inputText, page, (res)=>{  
            if(res.callResult === true) { 
                dispatch(allActions.gamehubAction.getPriceMarket(res.gamehubSearch, (res)=>{  
                    setSearchPrice(res?.data)
                })); 
                setLoadingPage(false);
            }
        })); 
    } 
    const handleInput = (e) =>{ 
        setInputText(e.target.value); 
    } 
    const handleDefault = ()=>{
        dispatch(allActions.gamehubAction.searchGameHub());   
    }
    return(
        <>
         {loadingPage && <div class="c-loading"><img src="../../images/loading-3s.gif" alt="Loading" /></div>}
        <div className="p-gameHub__cate">
            <ul>
                <li>
                    <a onClick={()=>handleDefault()} className="is-active">All Game</a>
                </li>
                {catalogMenu.map((e,i) => (
                    <li key={i.toString()}>
                        <Link to={`/gamehub/catalog/${e?.id}`}>{e?.name}</Link>
                    </li>
                ))} 
            </ul>
            <label>
                <input onKeyDown={(e) => {
                    if(e.code === "Enter"){  
                        handleSearch(e); 
                    }}}
                placeholder="Search game" onChange={(e)=>handleInput(e)}/>
                <button onClick={()=>handleSearch()}><img src="../images/icon-search.svg" alt="" /></button>
            </label>
        </div> 
         {gamehubSearch ? 
            <div className="p-gameHub__box">
                <h3 className="p-gameHub__title">GAME SEARCH RESULT:</h3>
                {gamehubSearch.length > 0 ?
                <>
                    <div className="c-listRecommen">
                        {gamehubSearch && gamehubSearch.map((item,index) => {
                            return(
                                <ItemGamehubRecommed item={item} key={index} marketPriceArr={searchPrice} />
                            );
                        })}
                    </div> 
                    <div className="pani-list">
                     {gamehubSearch && gamehubSearch.length > 0 && 
                        <Pagination
                            defaultCurrent={1}
                            total={gamehubSearchCount}
                            pageSize = {size}
                            onChange={onChangePaging}
                            showSizeChanger={false}
                        />}
                    </div>
                </>
                : <div className="c-nodata">
                    <img src={NodataImg} alt="No Data" />
                    <p>No Data</p>
                </div>} 
            </div>  :
            <> 
            <GamehubPopular/>
            <GamehubRecommended/>
            </>
        }
        </>
    )
}); 

export default GameHub;