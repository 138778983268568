import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useParams, useHistory } from 'react-router-dom';
import {
	Layout, Row, Col, Form, Pagination, Button, Input, Spin, Alert, Table, Progress, Card, Modal, Radio, Tag, InputNumber, Breadcrumb
} from 'antd';
import { GlobalOutlined, RedditCircleFilled, CopyOutlined, FacebookFilled, GithubOutlined, InstagramOutlined, TwitterCircleFilled, YoutubeOutlined } from '@ant-design/icons';

import { useActiveWeb3React } from '../../../hooks';
import Countdown from 'react-countdown';
import web3 from 'web3';
import { getInformationByChain } from '../../../config/network/multichainAddresses';
import { ethers } from 'ethers';
import { privatesSaleApi } from '../../../config/api/privatesSaleApi';
import tokenInfoAbi from '../../../config/abi/tokenInfo.json';

import { getProgressTime, rendererCountDown } from '../utilsIDO';

import {
	approveToken, getSignature, getTokenAllowance,
	getTokenInformation,
	privateSaleCancel,
	privateSaleClaimFund,
	privateSaleContribute,
	privateSaleEmWithdrawContribute,
	privateSaleFinalize, privateSaleGetClaimAble, privateSaleGetCurrency,
	privateSaleGetCycle,
	privateSaleGetCyclePercent, privateSaleGetEndTime, privateSaleGetHardCap,
	privateSaleGetHoldingAmount,
	privateSaleGetHoldingToken,
	privateSaleGetInvestInfo, privateSaleGetInvestors, privateSaleGetMaxInvest, privateSaleGetMinInvest,
	privateSaleGetOwner,
	privateSaleGetPrivateSaleType,
	privateSaleGetRaisedAmount,
	privateSaleGetSoftCap, privateSaleGetStartTime,
	privateSaleGetState,
	privateSaleGetTgeDate,
	privateSaleGetTgePercent,
	privateSaleGetUnlockedAmount,
	privateSaleSetWhitelistPool, privateSaleTotalInvestors,
	privateSaleWithdrawContribute
} from '../../utils';
import privateSaleAbi from '../../../config/abi/privateSaleAbi.json';

import { convertDateTime } from '../../../utils/formatDatetime';
import { currencyDecimals, getCurrencyDecimals } from '../../../config/network/config';
import pairAbi from '../../../config/abi/pairAbi.json';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FooterHome from '../../common/footer/footerHome';
import { Store } from 'react-notifications-component';
import addNotify from '../../common/Notify/addNotify';
import { Loader } from '../../common/component';

const { Content } = Layout;
const { TextArea } = Input;

const PrivateDetail = () => {
	const { chainId, account, library } = useActiveWeb3React();
	
	const [form] = Form.useForm();
	let { handle, privateSaleAddress, privateSaleId } = useParams();
	const [detail, setDetail] = useState([]);
	const [loading, setLoading] = useState(false);
	const [whitelistPool, setWhitelistPool] = useState(-1);
	const [whitelists, setWhitelists] = useState([]);
	const [loadingData, setLoadingData] = useState(false);
	const [addWLForm] = Form.useForm();
	const [rmWLForm] = Form.useForm();
	const [holdingTokenForm] = Form.useForm();


	const [isShowPublicAnti, setIsShowPublicAnti] = useState(false);
	const [isFormAddWL, setIsFormAddWL] = useState(false);
	const [isFormRmWL, setIsFormRmWL] = useState(false);
	const [keyLoading, setKeyLoading] = useState(0);
	const [tgeBps, setTgeBps] = useState(0);
	const [cycle, setCycle] = useState(0);
	const [cycleBps, setCycleBps] = useState(0);


	const [totalAllocations, setTotalAllocations] = useState(0);
	const [isOwner, setIsOwner] = useState(true);
	const [allowance, setAllowance] = useState(0);
	const [progressPercent, setProgressPercent] = useState(0);
	const [loadingBuy, setLoadingBuy] = useState(false);

	const [poolState, setPoolPoolState] = useState(0);

	const [holdingToken, setHoldingToken] = useState('');
	const [holdingAmount, setHoldingAmount] = useState('');
	const [holdingName, setHoldingName] = useState('');
	const [holdingSymbol, setHoldingSymbol] = useState('');
	const [holdingDecimals, setHoldingDecimals] = useState(18);

	const [refund, setRefund] = useState();

	const [tgeDate, setTgeDate] = useState(0);
	const [raisedAmount, setRaisedAmount] = useState(0);
	const [unlockedAmount, setUnlockedAmount] = useState(0);
	const [investedAmount, setInvestedAmount] = useState(0);
	const [softCap, setSoftCap] = useState(0);
	const [hardCap, setHardCap] = useState(0);
	const [minInvest, setMinInvest] = useState(0);
	const [maxInvest, setMaxInvest] = useState(0);
	const [startTime, setStartTime] = useState(0);
	const [endTime, setEndTime] = useState(0);
	const [claimAble, setClaimAble] = useState(0);
	const [totalRow, setTotalRow] = useState(0);
	const [signature, setSignature] = useState('0x00');
	const [disableBuyBtn, setDisableBuyBtn] = useState(true);
	const [currency, setCurrency] = useState(ethers.constants.AddressZero);


	let nowDate = new Date(Date.now());
	let startDate = new Date(detail?.startTime);
	let endDate = new Date(detail?.endTime);
	const [param, setParam] = useState(
		{
			page: 0,
			size: 20,
			sort: 'id,desc'
		}
	);

	const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

	const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

	const getPrivateSaleId = async (handle) => {
		try {
			let res = await privatesSaleApi.getPrivateSaleId(handle);
			if (res.status === 200) {
				if (res.data) {
					setDetail(res.data);
					console.log(detail);
				}
			}
		} catch (error) {
			console.log('Error: ', error);
		} finally {
			// setLoading(false);
		}
	};

	const getWLByAcc = async (acc, id) => {
		try {
			let res = await privatesSaleApi.checkWLByAcc(acc, id);
			if (res.status === 200) {
				if (res?.data?.joinSign) {
					setSignature(res.data.joinSign);
				}
			}
		} catch (error) {
			console.log('Error: ', error);
		} finally {
			// setLoading(false);
		}
	};
	const getWhitelistByPriId = async (projectId) => {

		try {
			let res = await privatesSaleApi.getWhitelistByRound(projectId, param);
			setTotalRow(parseInt(res.headers['x-total-count']));
			setTotalAllocations(parseInt(res.headers['x-total-count']));
			if (res.status === 200) {
				if (res.data.length > 0) {
					setWhitelists(res.data);

				} else {
					setWhitelists([]);
					setTotalAllocations(0)
				}
			}
		} catch (error) {
			console.log('Error: ', error);
		} finally {
			// setLoading(false);
		}
	};

	useEffect(() => {
		getPrivateSaleId(handle);
	}, [handle]);

	useEffect(() => {

		let nowMil = Math.floor(Date.now() / 1000);
		console.log('startTime', startTime);	
		console.log('endTime', endTime);	
		console.log('nowMil', nowMil);	
		console.log('poolState', poolState);
		
		// console.log('signature', signature);
		if (startTime <= nowMil && endTime >= nowMil && poolState == 1 && account && raisedAmount < hardCap) {
		
		console.log('account', account);
		console.log('raisedAmount', raisedAmount);
		console.log('hardCap', hardCap);
		console.log('whitelistPool', whitelistPool);	
		
			if (whitelistPool === 0 || whitelistPool == 2) {
				setDisableBuyBtn(false)
			} else if (whitelistPool === 1) {
				
				if (parseInt(signature) > 0) {
					setDisableBuyBtn(false);
				}
			} else {
				setDisableBuyBtn(false);
			}

		}

	}, [startTime, endTime, signature, whitelistPool, poolState, account, raisedAmount, hardCap, currency, allowance]);

	useEffect(() => {
		if (account) {
			getWLByAcc(account, privateSaleId);
		}

	}, [account, privateSaleId]);

	useEffect(() => {
		getWhitelistByPriId(handle);
	}, [handle, param]);

	const onChange_Pagi = (pageNumber) => {
		setParam({
			...param,
			page: pageNumber - 1
		});
	};

	const getSCInfo = async () => {

		try {
			setLoadingData(true)
			const tgeDateSC = await privateSaleGetTgeDate(privateSaleAbi, privateSaleAddress, library, account);
			const tgeBpsSC = await privateSaleGetTgePercent(privateSaleAbi, privateSaleAddress, library, account);
			const cycleSC = await privateSaleGetCycle(privateSaleAbi, privateSaleAddress, library, account);
			const cycleBpsSC = await privateSaleGetCyclePercent(privateSaleAbi, privateSaleAddress, library, account);
			const raisedAmountSC = await privateSaleGetRaisedAmount(privateSaleAbi, privateSaleAddress, library, account);
			const unlockedAmountSC = await privateSaleGetUnlockedAmount(privateSaleAbi, privateSaleAddress, library, account);
			const investInfosSC = await privateSaleGetInvestInfo(privateSaleAbi, privateSaleAddress, library, account);
			const privateSaleTypeSC = await privateSaleGetPrivateSaleType(privateSaleAbi, privateSaleAddress, library, account);
			const holdingTokenSC = await privateSaleGetHoldingToken(privateSaleAbi, privateSaleAddress, library, account);
			const holdingAmountSC = await privateSaleGetHoldingAmount(privateSaleAbi, privateSaleAddress, library, account);
			const softCapSC = await privateSaleGetSoftCap(privateSaleAbi, privateSaleAddress, library, account);
			const hardCapSC = await privateSaleGetHardCap(privateSaleAbi, privateSaleAddress, library, account);
			const minInvestSC = await privateSaleGetMinInvest(privateSaleAbi, privateSaleAddress, library, account);
			const maxInvestSC = await privateSaleGetMaxInvest(privateSaleAbi, privateSaleAddress, library, account);
			const startTimeSC = await privateSaleGetStartTime(privateSaleAbi, privateSaleAddress, library, account);
			const endTimeSC = await privateSaleGetEndTime(privateSaleAbi, privateSaleAddress, library, account);
			const stateSC = await privateSaleGetState(privateSaleAbi, privateSaleAddress, library, account);
			const owner = await privateSaleGetOwner(privateSaleAbi, privateSaleAddress, library, account);
			const claimableSC = await privateSaleGetClaimAble(privateSaleAbi, privateSaleAddress, library, account);
			const currencySC = await privateSaleGetCurrency(privateSaleAbi, privateSaleAddress, library, account);


			const decimal = detail?.currencyPayDecimals || getCurrencyDecimals(chainId, detail?.currencyApply) || 18;


			setRaisedAmount(parseFloat(ethers.utils.formatUnits(raisedAmountSC, decimal)));
			setUnlockedAmount(parseFloat(ethers.utils.formatUnits(unlockedAmountSC, decimal)));
			setInvestedAmount(parseFloat(ethers.utils.formatUnits(investInfosSC.totalInvestment, decimal)));
			setSoftCap(parseFloat(ethers.utils.formatUnits(softCapSC, decimal)));
			setHardCap(parseFloat(ethers.utils.formatUnits(hardCapSC, decimal)));
			setMinInvest(parseFloat(ethers.utils.formatUnits(minInvestSC, decimal)));
			setMaxInvest(parseFloat(ethers.utils.formatUnits(maxInvestSC, decimal)));
			setMaxInvest(parseFloat(ethers.utils.formatUnits(maxInvestSC, decimal)));
			setHoldingAmount(parseFloat(ethers.utils.formatUnits(holdingAmountSC, decimal)));
			setClaimAble(parseFloat(ethers.utils.formatUnits(claimableSC, decimal)));
			setHoldingToken(ethers.constants.AddressZero == holdingTokenSC ? '' : holdingTokenSC);
			setStartTime(parseInt(startTimeSC));
			setEndTime(parseInt(endTimeSC));
			setRefund(investInfosSC.refund);
			setWhitelistPool(parseInt(privateSaleTypeSC));
			setCurrency(currencySC);
			setTgeDate(tgeDateSC);
			setTgeBps(parseInt(tgeBpsSC) / 100);
			setCycle(parseInt(cycleSC) / 60);
			setCycleBps(parseInt(cycleBpsSC) / 100);
			setPoolPoolState(parseInt(stateSC));


			setIsOwner(account?.toLowerCase() == owner?.toLowerCase());
			setLoadingData(false)
		} catch (e) {
			console.log(e);
			setLoadingData(false)
		}
	}

	const getInvestorInfo = async () => {

		try {
			setLoadingData(true)
			const totalInvestor = await privateSaleTotalInvestors(privateSaleAbi, privateSaleAddress, library, account);
			console.log(parseInt(totalInvestor));
			const investorsSC = await privateSaleGetInvestors(privateSaleAbi, privateSaleAddress, library, account, 0, parseInt(totalInvestor));

			console.log(investorsSC);
			// console.log(ethers.utils.formatUnits(investorsSC[0].totalInvestment, 18));



			setLoadingData(false)
		} catch (e) {
			console.log('getInvestorInfo error', e);
			setLoadingData(false)
		}
	}

	const getTokenInfo = async (tokenAddressVal, spender) => {
		let allowanceSC = await getTokenAllowance(tokenInfoAbi, tokenAddressVal, account, spender)
		const tokenInfoSC = await getTokenInformation(pairAbi, tokenAddressVal);
		
		setAllowance(parseFloat(ethers.utils.formatUnits(allowanceSC, parseInt(tokenInfoSC.decimals))));
	}


	useEffect(() => {
		if (account && privateSaleAddress && detail && detail.chainId == chainId) {
			getSCInfo()
			getInvestorInfo()
		}
	}, [account, privateSaleAddress, chainId, detail]);

	useEffect(() => {
		if (currency && privateSaleAddress && account &&
			currency.toLowerCase() !== ethers.constants.AddressZero.toLowerCase() &&
			privateSaleAddress.toLowerCase() !== ethers.constants.AddressZero.toLowerCase()) {
			getTokenInfo(currency, privateSaleAddress)
		}
	}, [account, currency, privateSaleAddress]);

	useEffect(() => {
		setProgressPercent(Math.ceil(raisedAmount / hardCap * 100))
	}, [raisedAmount, hardCap])


	const handleApproveToken = async (e) => {
		try {
			e.preventDefault()
			setLoading(true);
			setKeyLoading(20);
			await approveToken(tokenInfoAbi, currency, library, account, privateSaleAddress)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										!countNoti && Store.addNotification(addNotify(`Approve ${detail?.currencyApply} Successfully`, 'success'));
										countNoti++;
										setAllowance(Math.pow(2, 256));

									} else {
										Store.addNotification(addNotify(`Approve ${detail?.currencyApply} Failed`, 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					console.log(error);
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};

	const onSubmitBuy = async (values) => {
		try {
			setLoadingBuy(true);
			const decimals = detail.currencyPayDecimals || getCurrencyDecimals(detail.chainId, detail.currencyPay)

			const amount = ethers.utils.parseUnits(`${values.amount}`, decimals)
			privateSaleContribute(privateSaleAbi, privateSaleAddress, library, account, amount, signature)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											setRaisedAmount(raisedAmount + parseFloat(values.amount));
											setInvestedAmount(investedAmount + parseFloat(values.amount));

											const payload = {
												id: detail?.id,
												ownerAddress: account,
												amountToken: 0,
												amountCurrency: values.amount,
												joinHash: txn.hash,
												joinSign: signature,
												tokenSaleTimeId: detail?.id,
												tokenSaleTime: {
													id: detail?.id
												}
											};
											let res = await privatesSaleApi.joinPool(payload);
											console.log(res);
											if (res.status === 201) {
												Store.addNotification(addNotify('Buy Successfully', 'success'));
												// window.location.reload()
											}

											setLoadingBuy(false);
										}
									} else {
										Store.addNotification(addNotify('Contribute Failed', 'danger'));
									}
									setLoadingBuy(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoadingBuy(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e) {
			console.log(e);
			setLoadingBuy(false);
		}
	};


	const onEmergencyWithdraw = () => {
		try {

			setLoading(true);
			setKeyLoading(1);
			privateSaleEmWithdrawContribute(privateSaleAbi, privateSaleAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											Store.addNotification(addNotify('Emergency Withdraw Successfully', 'success'));
											setRaisedAmount(raisedAmount - investedAmount);
											setInvestedAmount(0);
										}
									} else {
										Store.addNotification(addNotify('Emergency Withdraw Failed', 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	}

	const onWithdrawContribute = () => {
		try {
			setLoading(true);
			setKeyLoading(1);
			privateSaleWithdrawContribute(privateSaleAbi, privateSaleAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											Store.addNotification(addNotify('Withdraw Successfully', 'success'));
											setRaisedAmount(raisedAmount - investedAmount);
											setInvestedAmount(0);
										}
									} else {
										Store.addNotification(addNotify('Withdraw Failed', 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	}

	const showFormAddWL = () => {
		setIsFormAddWL(true)
		addWLForm.resetFields();
	};
	const showFormRemoveWL = () => {
		setIsFormRmWL(true);
		rmWLForm.resetFields();
	};

	const closeBox = (e) => {
		e.preventDefault()
		setIsFormAddWL(false);
		setIsFormRmWL(false);
		setIsShowPublicAnti(false)
	};


	const onFinalizePool = async () => {
		try {

			if (poolState == 2) {
				const payload = {
					id: detail?.id,
					roundAction: 'FINALIZE'
				};
				let res = await privatesSaleApi.updateStatusIdoPool(detail?.id, payload);
				if (res.status === 200) {
					if (res.data) {
						setLoading(false);
						Store.addNotification(addNotify('Finalize Launchpad Successfully', 'success'));
						window.location.reload()
					}
				}
				return;
			}
			setLoading(true);
			setKeyLoading(5)
			privateSaleFinalize(privateSaleAbi, privateSaleAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											setPoolPoolState(2);

											const payload = {
												id: detail?.id,
												roundAction: 'FINALIZE'
											};
											let res = await privatesSaleApi.updateStatusIdoPool(detail?.id, payload);
											if (res.status === 200) {
												if (res.data) {
													setLoading(false);
													Store.addNotification(addNotify('Finalize Launchpad Successfully', 'success'));
													getPrivateSaleId(handle);
												}
											}
										}
									} else {
										Store.addNotification(addNotify('Finalize Launchpad Failed', 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	}

	const onClaimFund = async () => {
		try {
			setLoading(true);
			setKeyLoading(6);
			privateSaleClaimFund(privateSaleAbi, privateSaleAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											Store.addNotification(addNotify('Claim Fund Successfully', 'success'));
											setUnlockedAmount(unlockedAmount + claimAble)
											setClaimAble(0)
										}
									} else {
										Store.addNotification(addNotify('Claim fund Failed', 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	}

	const onCancelPool = async (status) => {
		try {
			if (!privateSaleId || !privateSaleAddress) {
				Store.addNotification(addNotify('Invalid Private Sale', 'danger'));
				return;
			}
			if (poolState == 3) {
				const payload = {
					id: parseInt(privateSaleId),
					roundAction: 'CANCEL'
				};
				let res = await privatesSaleApi.updateStatusIdoPool(detail?.id, payload);
				if (res.status === 200) {
					if (res.data) {
						setLoading(false);
						Store.addNotification(addNotify('Cancel Private Sale Successfully', 'success'));
						getPrivateSaleId(handle);
					}
				}
				return;
			}
			setLoading(true);
			setKeyLoading(7);
			privateSaleCancel(privateSaleAbi, privateSaleAddress, library, account)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											setPoolPoolState(3);
											const payload = {
												id: parseInt(privateSaleId),
												roundAction: 'CANCEL'
											};
											let res = await privatesSaleApi.updateStatusIdoPool(detail?.id, payload);
											if (res.status === 200) {
												if (res.data) {
													setLoading(false);
													Store.addNotification(addNotify('Cancel Private Sale Successfully', 'success'));
													getPrivateSaleId(handle);
												}
											}
										}
									} else {
										Store.addNotification(addNotify('Cancel Private Sale Failed', 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	}

	const onSubmitAddWL = async (values) => {
		try {
			if (!privateSaleId || !privateSaleAddress || !account) {
				Store.addNotification(addNotify('Invalid Private Sale', 'danger'));
				return
			}
			const addressList = JSON.parse(values.addressList);
			if (!addressList) {
				Store.addNotification(addNotify('Invalid Addresses', 'danger'));
				return
			}
			setLoading(true);
			setKeyLoading(2);
			const signatureWeb3 = await getSignature(account, library, 'private-sale-white-lists/add');
			const payload = {
				addressList: addressList,
				saleTokenTimeId: parseInt(privateSaleId),
				message: 'private-sale-white-lists/add',
				signature: signatureWeb3
			};
			let res = await privatesSaleApi.addWhiteList(payload);

			if (res.status === 204) {
				setLoading(false);
				Store.addNotification(addNotify('Add Whitelist Successfully', 'success'));
				setIsFormAddWL(false);
				addWLForm.resetFields()
				getPrivateSaleId(handle);
				getWhitelistByPriId(handle);
				getWLByAcc(account, privateSaleId)
			}
		} catch (error) {
			console.log('Error: ', error);
			Store.addNotification(addNotify('Add Whitelist Failed', 'danger'));
		} finally {
			setLoading(false);
		}
	};

	const onSubmitRemoveWL = async (values) => {
		console.log('payload', values);
		try {
			if (!privateSaleId || !privateSaleAddress || !account) {
				Store.addNotification(addNotify('Invalid Private Sale', 'danger'));
			}

			const addressList = JSON.parse(values.addressList);
			if (!addressList) {
				Store.addNotification(addNotify('Invalid Addresses', 'danger'));
				return
			}
			setLoading(true);
			setKeyLoading(3);
			const signatureWeb3 = await getSignature(account, library, 'private-sale-white-lists/remove');
			const payload = {
				addressList: addressList,
				saleTokenTimeId: parseInt(privateSaleId),
				message: 'private-sale-white-lists/remove',
				signature: signatureWeb3
			};
			let res = await privatesSaleApi.removeWhiteList(payload);

			if (res.status === 204) {
				setLoading(false);
				Store.addNotification(addNotify('Remove Successfully', 'success'));
				setIsFormRmWL(false);
				rmWLForm.resetFields()
				getPrivateSaleId(handle);
				getWhitelistByPriId(handle);
				getWLByAcc(account, privateSaleId)
			}
		} catch (error) {
			console.log('Error: ', error);
			Store.addNotification(addNotify('Remove Failed', 'danger'));

		} finally {
			setLoading(false);
		}
	};

	const onchangeRadioBtn = async ({ target: { value } }) => {
		try {
			if (value == 2) {
				setIsShowPublicAnti(true);
				return;
			}
			console.log('radio checked', value);
			if (value == whitelistPool || value < 0) {
				return;
			}

			setLoading(true);
			privateSaleSetWhitelistPool(privateSaleAbi, privateSaleAddress, library, account, value, ethers.constants.AddressZero, 0)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											const payload = {
												id: privateSaleId,
												saleType: value
											};
											let res = await privatesSaleApi.updateStatusWhitelist(privateSaleId, payload);
											if (res.status === 200) {
												setLoading(false);
												Store.addNotification(addNotify('Update Private Sale Successfully', 'success'));

												setWhitelistPool(value);
												holdingTokenForm.resetFields();
												setHoldingToken('');
												setHoldingName('');
												setHoldingSymbol('');
												setHoldingDecimals('');
												setHoldingAmount(0);
												getPrivateSaleId(handle);
											}
										}
									} else {
										Store.addNotification(addNotify('Update Private Sale Failed', 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
			

		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	};
	const onEnableWhitelist = async (val) => {
		try {
			setLoading(true);
			setKeyLoading(4)
			privateSaleSetWhitelistPool(privateSaleAbi, privateSaleAddress, library, account, val ? 1 : 0, ethers.constants.AddressZero, 0)
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											Store.addNotification(addNotify(`${val ? 'Enable' : 'Disable'} Whitelist Successfully`, 'success'));
											setWhitelistPool(val ? 1 : 0)
										}
									} else {
										Store.addNotification(addNotify(`${val ? 'Enable' : 'Disable'} Whitelist Failed`, 'danger'));
									}
									setLoading(false);
									window.location.reload();
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});
		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	};



	const onSetHoldingToken = (values) => {
		try {

			if (!values.token_address || !ethers.utils.isAddress(values.token_address) || !values.amount || !holdingDecimals) {
				console.log('calll');
				return;
			}

			setLoading(true);
			setKeyLoading(11);
			privateSaleSetWhitelistPool(privateSaleAbi, privateSaleAddress, library, account, 2, values.token_address, ethers.utils.parseUnits(`${values.amount}`, holdingDecimals))
				.then((txn) => {
					if (txn && txn.hash) {
						let countNoti = 0;
						const interval = setInterval(function () {
							(async () => {
								const res = await w3.eth.getTransactionReceipt(txn.hash);
								if (res) {
									clearInterval(interval);
									if (res.status && res.blockNumber) {
										if (!countNoti) {
											countNoti++;
											Store.addNotification(addNotify('Update Successfully', 'success'));
											setWhitelistPool(2);
											setIsShowPublicAnti(false);
										}
									} else {
										Store.addNotification(addNotify('Update Launchpad Failed', 'danger'));
									}
									setLoading(false);
								}
							})();
						}, 1000);
					}
				})
				.catch((error) => {
					setLoading(false);
					if (error) {
						if (error.code == 4001 && error.message) {
							Store.addNotification(addNotify(error.message, 'danger'));
						} else if (error.reason) {
							Store.addNotification(addNotify(error.reason, 'danger'));
						} else {
							if (error.data && error.data.message) {
								Store.addNotification(addNotify(error.data.message, 'danger'));
							}
						}
					}
				});


		} catch (e) {
			console.log(e);
			setLoading(false);
		}
	};
	const onCloseSetting = async (e) => {
		try {
			e.preventDefault();
			setIsShowPublicAnti(false);
			holdingTokenForm.setFieldsValue({
				'amount': holdingAmount,
				'token_address': holdingToken
			});

		} catch (e) {
			console.log(e);
		}
	};

	const onChangeTokenAddress = async (values) => {
		const tokenAddressVal = values.target.value;
		if (!tokenAddressVal || !parseInt(tokenAddressVal)) {
			return;
		}

		try {
			const tokenInfo = await getTokenInformation(tokenInfoAbi, tokenAddressVal);
			setHoldingName(tokenInfo.name);
			setHoldingSymbol(tokenInfo.symbol);
			setHoldingDecimals(tokenInfo.decimals);

		} catch (e) {
			console.log(e);
		}
	};





	const options = [
		{ label: 'Public', value: 0 },
		{ label: 'Whitelist', value: 1 },
		{ label: 'Public Anti', value: 2 }
	];

	const columnsWhitelist = [
		{
			title: 'No.',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Address',
			dataIndex: 'userAddress',
			key: 'userAddress',
		}
	];

	const StatusRender = (nowDate, startDate, endDate) => {
		let status = '';
		let color = '';
		if (startDate > nowDate && endDate > nowDate) {
			status = 'upcoming';
			color = 'orange';
		}
		if (startDate < nowDate && endDate > nowDate) {
			status = 'opening';
			color = '#f5c112';
		}
		if (startDate < nowDate && endDate < nowDate) {
			status = 'closed';
			color = '#919191';
		}
		if (detail?.roundAction === "CANCEL") {
			status = 'canceled';
			color = 'red';
		}

		return <Tag color={color}>{status}</Tag>;
	}


	const onCopy = () => {
		Store.addNotification(addNotify('Copied', 'success'));
	};

	return (
		<>
			
			<div className='private-detail-page'>

				<div className='container'>
					<Breadcrumb className='head-breadcrumb-airdrop'>
						<Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
						<Breadcrumb.Item><a href='/private-sale'>Private sale</a></Breadcrumb.Item>
						<Breadcrumb.Item><a href='#'>{detail?.title}</a></Breadcrumb.Item>
					</Breadcrumb>
					{!account && (
						<>
							<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
								<Col className='gutter-row' xs={24} xl={8}>
									<p>
										<Alert message="Please connect wallet" type="error" showIcon />
									</p>
								</Col>
								<Col className='gutter-row' xs={24} xl={8}></Col>
							</Row>
						</>
					)}
					<div className='create-form'>
						<div className='private-detail-title'>{detail?.title}</div>
						<div className="private-detail-social">
							{detail?.website && (
								<span className="detail-social"><a target="_blank" href={detail?.website}><GlobalOutlined className="icon" /></a></span>
							)}
							{detail?.facebook && (
								<span className="detail-social"><a target="_blank" href={detail?.facebook}><FacebookFilled className="icon" /></a></span>
							)}
							{detail?.github && (
								<span className="detail-social"><a target="_blank" href={detail?.github}><GithubOutlined className="icon" /></a></span>
							)}
							{detail?.instagram && (
								<span className="detail-social"><a target="_blank" href={detail?.instagram}><InstagramOutlined className="icon" /></a></span>
							)}
							{detail?.telegram && (
								<span className="detail-social"><a target="_blank" href={detail?.telegram}><GithubOutlined className="icon" /></a></span>
							)}
							{detail?.twitter && (
								<span className="detail-social"><a target="_blank" href={detail?.twitter}><TwitterCircleFilled className="icon" /></a></span>
							)}
							{detail?.discord && (
								<span className="detail-social"><a target="_blank" href={detail?.discord}><TwitterCircleFilled className="icon" /></a></span>
							)}
							{detail?.reddit && (
								<span className="detail-social"><a target="_blank" href={detail?.reddit}><RedditCircleFilled className="icon" /></a></span>
							)}
						</div>
						<div className='private-block-detail'>
							<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
								<Col className='gutter-row' xs={24} xl={16}>
									<Card title="Private Sale Info" className='card-private' extra={
										<>
											<div className='private-status'>
												{StatusRender(nowDate, startDate, endDate)}
											</div>
										</>
									}>
										
										<div className='airdrop-line'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={9}><span>Private Sale Address</span></Col>
												<Col className='gutter-row text-right' xs={15}>
													<span className='text-regular-blue d-flex flex-end'>
														{privateSaleAddress.substring(0, 8)}...{privateSaleAddress.substring(privateSaleAddress.length - 8)}
														<CopyToClipboard onCopy={onCopy} text={privateSaleAddress}>
															<CopyOutlined className='text-info ml-5' />
														</CopyToClipboard>
													</span>
													
													<p className='text-info mb-0'>(Do not send {detail?.currencyApply} to the private sale address. You will lose your funds.)</p>
												</Col>
											</Row>
										</div>
										<div className='airdrop-line'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={12}><span>Soft Cap</span></Col>
												<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{detail?.softCap} {detail?.currencyApply}</span></Col>
											</Row>
										</div>
										<div className='airdrop-line'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={12}><span>Hard Cap</span></Col>
												<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{detail?.hardCap} {detail?.currencyApply}</span></Col>
											</Row>
										</div>
										<div className='airdrop-line'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={12}><span>Private Sale Start Time</span></Col>
												<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{convertDateTime(startDate)}</span></Col>
											</Row>
										</div>
										<div className='airdrop-line'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={12}><span>Private Sale End Time</span></Col>
												<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{convertDateTime(endDate)}</span></Col>
											</Row>
										</div>
										<div className='airdrop-line'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={12}><span>First Release For Project</span></Col>
												<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{detail?.firstReleasePercent}%</span></Col>
											</Row>
										</div>
										<div className='airdrop-line'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={12}><span>Vesting For Project</span></Col>
												<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{detail?.releaseEachCyclePercent}%</span></Col> {/* each 5 minutes */}
											</Row>
										</div>

										<div className='airdrop-line'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={12}><span>Cycle</span></Col>
												<Col className='gutter-row text-right' xs={12}><span className='text-regular-blue'>{cycle.toLocaleString()} Minutes</span></Col> {/* each 5 minutes */}
											</Row>
										</div>
									</Card>
									{whitelistPool == 1 && (
										<Card title={`Allocation [${totalAllocations}]`} className="card-private" style={{ marginTop: '15px' }}>
											{whitelists.length > 0 && (
												<>
												
													<div className="whitelist-list">
														<Table pagination={false} dataSource={whitelists} columns={columnsWhitelist} />

														{totalRow > param.size && (
															<Pagination
																current={param.page + 1}
																defaultCurrent={1}
																total={totalRow}
																pageSize={param.size}
																onChange={onChange_Pagi}
																showSizeChanger={false}
																className="pagiation-custom"
															/>
														)}
													</div>
												</>
											)}
										</Card>
									)}

								</Col>
								<Col className='gutter-row' xs={24} xl={8}>
									<Card title='' className='card-private'>
										<p className='airdrop-time'><Alert message="Make sure the website is UpSwap Finance." type='warning' /></p>
										{startDate > nowDate && (detail?.roundAction != 'CANCEL'  || poolState != 3) && (
											<>
												<div className="detail-time">
													<span className="text-time">Sale start in </span>
													<div className="time-coundown">
														<Countdown
															date={getProgressTime(startDate)}
															renderer={rendererCountDown}
														/>
													</div>
												</div>
											</>
										)}
										{startDate < nowDate && endDate > nowDate && (detail?.roundAction != 'CANCEL' || poolState != 3) && (
											<>
												<div className="detail-time">
													<span className="text-time">Sale end in </span>
													<div className="time-coundown">
														<Countdown
															date={getProgressTime(endDate)}
															renderer={rendererCountDown}
														/>
													</div>
												</div>
												<Progress
													strokeColor={{
														'0%': '#0261a5',
														'100%': '#89befe'
													}}
													percent={progressPercent}
													showInfo={false}
												/>
												<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
													<Col className='gutter-row' xs={12}><span className='text-info'>0 {detail?.currencyApply}</span></Col>
													<Col className='gutter-row text-right' xs={12}><span className='text-info'>{raisedAmount.toLocaleString()}/{hardCap.toLocaleString()} {detail?.currencyApply}</span></Col>
												</Row>

												<Form className='mt-15 basic-form' form={form} layout="vertical" autoComplete="off" onFinish={onSubmitBuy}>
													<Form.Item name="amount"
														initialValue={0}
														label={`Amount (min: ${detail?.minBuy} ${detail?.currencyApply}, max: ${detail?.maxBuy} ${detail?.currencyApply})`}
														// label={`Amount`}
														rules={[
															{
																validator: (rule, value, cb) => {
																	!value || parseFloat(value) <= 0 || parseFloat(value) < detail?.minBuy || parseFloat(value) > detail?.maxBuy
																		? cb('Invalid value')
																		: cb();
																}
															}
														]}
													>
														<Input/>
													</Form.Item>
												

													{currency.toLowerCase() != ethers.constants.AddressZero.toLowerCase() && allowance === 0 ? (
														<Form.Item>
															<button className="btn-basic" disabled={loading && keyLoading == 20} onClick={handleApproveToken} >Approve {detail?.currencyApply} {(loading && keyLoading == 20) && <Spin className="ml-10" size="small" />}</button>
														</Form.Item>
													): (
														<Form.Item>
															<button className="btn-basic" htmlType="submit" disabled={disableBuyBtn} >Buy With {detail?.currencyApply} {loadingBuy && <Spin className="ml-10" size="small" />}</button>
														</Form.Item>
													)}

													{(investedAmount > 0 && (startDate <= nowDate && endDate >= nowDate) && poolState == 1) &&
														<Form.Item>
															<button className="btn-basic" onClick={onEmergencyWithdraw}
																disabled={loading && keyLoading === 1}>Emergency Withdraw {(loading && keyLoading === 1) && <Spin className="ml-10" size="small" />}</button>
														</Form.Item>}
													{(investedAmount > 0 && (poolState == 3 || (endDate <= nowDate && raisedAmount < softCap))) &&
														<div className="grp-btn-claim">
															<button className="btn-basic" onClick={onWithdrawContribute} disabled={refund || (loading && keyLoading === 7)}>Withdraw Contribute {(loading && keyLoading === 7) && <Spin className="ml-10" size="small" />}</button>
														</div>
													}

												</Form>
											</>
										)}
										{(endDate < nowDate || detail?.roundAction == 'CANCEL' || poolState == 3) && (
											<>
												{(investedAmount > 0 && (poolState == 3 || (endDate <= nowDate && raisedAmount < softCap))) &&
												<div className="grp-btn-claim">
													<button className="btn-basic" htmlType="button" onClick={onWithdrawContribute} disabled={refund || (loading && keyLoading === 7)}>Withdraw Contribute {(loading && keyLoading === 7) && <Spin className="ml-10" size="small" />}</button>
												</div>
												}
												<div className="detail-time">
													<span className="text-time text-danger">Sale ended </span>
												</div>
											</>
										)}
									</Card>
									<Card style={{ marginTop: '15px' }} className='card-private'>
										<div className='airdrop-line'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={12}><span>Status</span></Col>
												<Col className='gutter-row text-right text-danger' xs={12}><span>{StatusRender(nowDate, startDate, endDate)}</span></Col>
											</Row>
										</div>
										<div className='airdrop-line'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={12}><span>Your {detail?.currencyApply} balance</span></Col>
												<Col className='gutter-row text-right text-info' xs={12}><span>0 {detail?.currencyApply}</span></Col>
											</Row>
										</div>
										<div className='airdrop-line'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={12}><span>Minimum Buy</span></Col>
												<Col className='gutter-row text-right text-info' xs={12}><span>{minInvest.toLocaleString()} {detail?.currencyApply}</span></Col>
											</Row>
										</div>
										<div className='airdrop-line'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={12}><span>Maximum Buy</span></Col>
												<Col className='gutter-row text-right text-info' xs={12}><span>{maxInvest.toLocaleString()} {detail?.currencyApply}</span></Col>
											</Row>
										</div>
										<div className='airdrop-line'>
											<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
												<Col className='gutter-row' xs={12}><span>Your purchased</span></Col>
												<Col className='gutter-row text-right text-info' xs={12}><span>{investedAmount.toLocaleString()} {detail?.currencyApply}</span></Col>
											</Row>
										</div>
									</Card>
									{isOwner && (
										<Card className='card-private' title={'Owner Zone'} style={{ marginTop: '15px' }}>
											{poolState == 1 && (
												<div className='airdrop-btn-grp'>
													<Radio.Group
														className="m-10"
														options={options}
														onChange={onchangeRadioBtn}
														value={whitelistPool}
													/>
													<div className='mt-15'>

														{whitelistPool == 0 && (
															<button htmlType="button" className="btn-basic" onClick={() => onEnableWhitelist(true)} disabled={loading && keyLoading === 4}>Enable Whitelist {(loading && keyLoading === 4) && <Spin className="ml-10" size="small" />}</button>
														)}

														{whitelistPool === 1 && (
															<>
																<Button htmlType="button" className="airdrop-btn" onClick={showFormAddWL}>Add users to whitelist</Button>
																<Button htmlType="button" className="airdrop-btn" onClick={showFormRemoveWL} disabled={!whitelists.length} >Remove users from whitelist</Button>
																{/* <Button htmlType="button" className="airdrop-btn" onClick={showFormRemoveWL} >Remove users from whitelist</Button> */}
																<Button htmlType="button" className="airdrop-btn" onClick={() => onEnableWhitelist(false)} disabled={loading && keyLoading === 4}>Disable whitelist {(loading && keyLoading === 4) && <Spin className="ml-10" size="small" />}</Button>
															</>

														)}

														{whitelistPool == 2 && (
															<Button htmlType="button" className="btn-basic" onClick={() => setIsShowPublicAnti(true)} >Setting Token Holding</Button>
														)}
													</div>
												</div>
											)}
											<p style={{ marginTop: '15px' }}><strong>Pool Actions</strong></p>
											<div className='airdrop-btn-grp'>

												{poolState == 1 && (
													<>
														<Button htmlType="button" className="airdrop-btn" disabled={(loading && keyLoading === 5) || !((poolState === 1 && ((raisedAmount >= hardCap) || (endTime < Math.floor(Date.now() / 1000) && raisedAmount >= softCap))))} onClick={onFinalizePool}>Finalize Pool {(loading && keyLoading === 5) && <Spin className="ml-10" size="small" />}</Button>
														<Button htmlType="button" className="airdrop-btn btn-cancel-pool" disabled={(loading && keyLoading === 7) || (poolState != 1)} onClick={onCancelPool}>Cancel Pool {(loading && keyLoading === 7) && <Spin className="ml-10" size="small" />} </Button>
													</>
												)}
												{poolState == 2 && (
													<Button htmlType="button" className="btn-all" disabled={(loading && keyLoading === 6) || claimAble == 0} onClick={onClaimFund}>{claimAble > 0 ? ` Claim (${claimAble} )` : ` Claimed (${unlockedAmount}/${raisedAmount})`} {detail?.currencyApply} {(loading && keyLoading === 6) && <Spin className="ml-10" size="small" />}</Button>

												)}

											</div>
										</Card>
									)}
								</Col>
							</Row>
						</div>
					</div>
				</div>
				
			</div>
			<FooterHome />
			<Modal
				title={<div className="text-md custom-modal-title">Public with holding condition</div>}
				visible={isShowPublicAnti}
				className="custom-modal create-token-modal"
				footer={false}
			>
				<div className="transer-box-content">
					<div><Alert message="With this option you can control who can contribute to the pool. Only Users who hold a minimum amount of token you suggest would be able to contribute" type="warning" showIcon /></div>
					<Form
						form={holdingTokenForm}
						name="basic"
						className='basic-form'
						autoComplete="off"
						onFinish={onSetHoldingToken}
						layout="vertical"
					>
						<Form.Item name="token_address" label="Users" rules={[{ required: true, message: 'Please input Users!' }]} initialValue={holdingToken}>
							<input className="amount-buy" placeholder="Enter address" onBlur={onChangeTokenAddress} />
						</Form.Item>

						<Form.Item name="amount" label="Amount" rules={[{ required: true, message: 'Please input Amount!' }]} initialValue={holdingAmount}>
							<input className="amount-buy" placeholder="Enter Amount" />
						</Form.Item>

						<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
							<Col className="gutter-row" xs={24}>
								{holdingName && holdingDecimals && holdingSymbol && (
									<div className="bg-token-info token-info-detail">
										<div className="detail-text">
											<div className="left">Name</div>
											<div className="right">{holdingName}</div>
										</div>
										<div className="detail-text">
											<div className="left">Symbol</div>
											<div className="right">{holdingSymbol}</div>
										</div>
										<div className="detail-text mb-15">
											<div className="left">Decimals</div>
											<div className="right">{holdingDecimals}</div>
										</div>
									</div>
								)}
							</Col>
						</Row>
						<Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
							<Col className="gutter-row" xs={24}>
								<div className="modal-btn-group">
									<button className="btn-basic" disabled={loading && keyLoading === 11} type="submit">Confirm {loading && keyLoading === 11 && <Spin className="ml-10" size="small" />}</button>
									<button className="btn-basic-default" disabled={loading && keyLoading === 11} onClick={e => onCloseSetting(e)}>Close</button>
								</div>
							</Col>
						</Row>
					</Form>

				</div>
			</Modal>

			<Modal
				title={<div className="text-md custom-modal-title">Remove Users Whitelist</div>}
				visible={isFormRmWL}
				className="custom-modal create-token-modal"
				footer={false}
			>
				<div className="transer-box-content">
					<Form
						form={rmWLForm}
						name="basic"
						className='basic-form'
						autoComplete="off"
						onFinish={onSubmitRemoveWL}
						layout="vertical"
					>
						<Form.Item name='addressList' label='TGE release percent (%)' hidden={true}>
							<Input className='amount-buy' placeholder='Ex: 0' />
						</Form.Item>
						<Form.Item name="account_address" label="Users" rules={[
							{
								validator: (rule, value, cb) => {
									if (!value) {
										return cb('Allocations can not be blank');
									}
									if (value) {
										const wallets = value.trim().split('\n');

										let addressList = [];

										for (let i = 0; i < wallets.length; i++) {
											const address = wallets[i].trim();
											addressList.push(address);


											if (!ethers.utils.isAddress(address)) {
												return cb(`Incorrect value at line ${i + 1}`);
											}
										}
										rmWLForm.setFieldsValue({
											'addressList': JSON.stringify(addressList)
										});
										cb();
									}

								}
							}]}>
							<TextArea rows={8} className="amount-buy" placeholder="Enter account address" />
						</Form.Item>
						<div className="modal-btn-group">
							<button className="btn-basic" disabled={loading && keyLoading === 3} type="submit">Remove User {(loading && keyLoading === 3) && <Spin size="small" />}</button>
							<button className="btn-basic-default" disabled={loading && keyLoading === 3} onClick={closeBox}>Close</button>
						</div>
					</Form>

				</div>
			</Modal>

			<Modal
				title={<div className="text-md custom-modal-title">Add Users Whitelist</div>}
				visible={isFormAddWL}
				className="custom-modal create-token-modal"
				footer={false}
			>
				<div className="transer-box-content">
					<Form
						form={addWLForm}
						name="basic"
						autoComplete="off"
						className='basic-form'
						onFinish={onSubmitAddWL}
						layout="vertical"
					>
						<Form.Item name='addressList' label='TGE release percent (%)' hidden={true}>
							<Input className='amount-buy' placeholder='Ex: 0' />
						</Form.Item>
						<Form.Item name="account_address" label="Users" rules={[
							{
								validator: (rule, value, cb) => {
									if (!value) {
										return cb('Allocations can not be blank');
									}
									if (value) {
										const wallets = value.trim().split('\n');

										let addressList = [];

										for (let i = 0; i < wallets.length; i++) {
											const address = wallets[i].trim();
											addressList.push(address);


											if (!ethers.utils.isAddress(address)) {
												return cb(`Incorrect value at line ${i + 1}`);
											}
										}
										addWLForm.setFieldsValue({
											'addressList': JSON.stringify(addressList)
										});
										cb();
									}

								}
							}]}>
							<TextArea rows={8} className="amount-buy" placeholder="Enter account address" />
						</Form.Item>
						<div className="modal-btn-group">
							<button className="btn-basic" disabled={loading && keyLoading === 2} type="submit">Add User {(loading && keyLoading === 2) && <Spin size="small" />}</button>
							<button className="btn-basic-default" disabled={loading && keyLoading === 2} onClick={closeBox}>Close</button>
						</div>
					</Form>

				</div>
			</Modal>
				
				{loadingData && (<Loader />)}
			
		</>
	);
};

export default PrivateDetail;
