/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from "react";
import web3 from "web3";
import { Progress, Steps, Checkbox, Radio, Modal, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from "../../hooks";
// import Action from "./action";
import { useContract } from "../../hooks/useContract";
import abiStake from "./utils/abiStake.json"
import { SC_STAKE_FIXED } from "../../constants";
import { chainList } from "../../config/network/chainlist";
import { Store } from "react-notifications-component";
import addNotify from "../common/Notify/addNotify";


// const timeDown = 7 * 24 * 60 * 60 * 1000
const timeDown = 5 * 60 * 1000

const steps = [
    {
        title: '1',
        content: 'First-content',
    },
    {
        title: '2',
        content: '2-content',
    },
    {
        title: '3',
        content: '3-content',
    },
];

const provider = "https://testnet-rpc.helachain.com";

const Withdraw = (props) => {
    // console.log('props', props)
    const { account, chainId } = useActiveWeb3React();
    const contractStake = useContract(SC_STAKE_FIXED, abiStake);
    const [getUser, setGetUser] = useState();
    const [bnbBalance, setBnbBalance] = useState(0);
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [showNext, setShowNext] = useState(false);
    const [isCheckTerm, setIsCheckTerm] = useState(false);
    const [current, setCurrent] = useState(0);
    const [timeUnstake, setTimeUnstake] = useState();
    const [hashUnWr, setHashWr] = useState('');
    const [isSuccess, setIsSuccess] = useState(false)
    const { Step } = Steps;
    let chainInfo = chainList[chainId];
    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const renderImg1 = () => {
        return (
            <img className="img-step-stake" src="./images/stake-ac.png" alt="" />
        )
    }
    const renderImg2 = () => {
        if (current < 1) {
            return <img className="img-step-stake" src="./images/stake-ac.png" alt="" />
        } else {
            return <img className="img-step-stake" src="./images/stake-ac-1.png" alt="" />
        }
    }
    const renderImg5 = () => {
        if (current < 2) {
            return <img className="img-step-stake" src="./images/stake-ac.png" alt="" />
        } else {
            return <img className="img-step-stake" src="./images/stake-ac-1.png" alt="" />
        }
    }

    const renderText1 = () => {
        return <span className="text-ac">Checkpoints</span>
    }
    const renderText2 = () => {
        if (current < 1) {
            return "Initialize Withdrawal"
        } else {
            return <span className="text-ac">Initialize Withdrawal</span>
        }
    }

    const renderText5 = () => {
        if (current < 4) {
            return "Confirmation"
        } else {
            return <span className="text-ac">Confirmation</span>
        }
    }

    const onChange = (e) => {
        setIsCheckTerm(e.target.checked)
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    useEffect(() => {
        if (account) {
            w3.eth.getBalance(account, (err, balance) => {
                if (balance) {
                    let _balance = web3.utils.fromWei(
                        web3.utils.toBN(balance),
                        "ether"
                    );
                    let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
                    setBnbBalance(bnbBl);
                }
            });
        }
    }, [account, chainId, current]);

    const handleShowNext = () => {
        if (account && bnbBalance > 0 && isCheckTerm && getUser > 0 && ((timeUnstake * 1000 + timeDown) <= date)) {
            setShowNext(true)
        } else {
            setShowNext(false)
        }
    }
    useEffect(() => {
        handleShowNext()
    }, [account, bnbBalance, current, isCheckTerm, current, getUser])

    let date = Date.now();

    const getUserMap = async () => {
        if (contractStake) {
            const user = await contractStake.userMap(account);
            setTimeUnstake(Number(user.unstakeTime))
            setGetUser(user.inUnStakeAmount / 1e18);
        }
    };

    useEffect(() => {
        if (account) {
            getUserMap();
        }
    }, [account, current]);

    const handleWithdraw = async () => {
        try {
            setIsModalOpen(true);
            await contractStake.withdraw()
                .then((res) => {
                    res.wait()
                        .then((resWait) => {
                            if (resWait?.status === 1) {
                                console.log('resWait', resWait)
                                setHashWr(resWait.transactionHash)
                                
                                Store.addNotification(addNotify("Withdraw successfully", 'success'));
                                props.setIsCheckAction(!props.isCheckAction)
                                setIsSuccess(!isSuccess)
                                setIsModalOpen(false);
                                setCurrent(current + 1);
                                window.location.reload()
                            } else {
                                
                                Store.addNotification(addNotify("Unknown error (status hash fail)", 'danger'));
                                setIsModalOpen(false);
                            }

                        })
                        .catch((err) => {
                            
                            Store.addNotification(addNotify("Unknown error", 'danger'));
                            setIsModalOpen(false);
                        })
                })
                .catch((error) => {
                    console.log('err', error)
                    setIsModalOpen(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            Store.addNotification(addNotify(error.message, 'danger'));
                        } else if (error.reason) {
                            Store.addNotification(addNotify(error.reason, 'danger'));
                        } else {
                            if (error.data && error.data.message) {
                                Store.addNotification(addNotify(error.data.message, 'danger'));
                            }
                        }
                    }

                })
        } catch (error) {
            console.log('err catch-->', error)
            Store.addNotification(addNotify(error, 'danger'));
            setIsModalOpen(false);

        }
    }



    return (
        <div className="wrap-stake">
            <div className="stake">
                <div className="wrap-all">

                    <div className="stake-your-token">Withdraw your $HLUP</div>
                    <div className="content-stake">

                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Steps current={current}>
                            <Step
                                title={renderText1()}
                                icon={renderImg1()}
                            />
                            <Step
                                title={renderText2()}
                                icon={renderImg2()}
                            />

                            <Step
                                title={renderText5()}
                                icon={renderImg5()}
                            />
                        </Steps>

                        {/* <div className="steps-content">{steps[current].content}</div> */}
                        {current === 0 ? (
                            <div className="content-step1">
                                <div className="title">Checkpoints</div>
                                <div className="des">The following conditions must be met to proceed:</div>
                                <div className="item-step1">
                                    <div className="card-item">
                                        <div className={`${account ? 'check-card acive' : 'check-card'}`}>
                                        <img src="./images/check-ac.png" alt="" />
                                            <img src="./images/check-no-ac.png" alt="" />

                                        </div>
                                        <div className="title-card">Connected with MetaMask</div>
                                        <div className="content-card">If not connected, click the "Connect Wallet" button in the top right corner.</div>
                                    </div>
                                    <div className="card-item">
                                        <div className={`${(timeUnstake * 1000 + timeDown) <= date && getUser > 0 ? 'check-card acive' : 'check-card'}`}>
                                        <img src="./images/check-ac.png" alt="" />
                                            <img src="./images/check-no-ac.png" alt="" />

                                        </div>
                                        <div className="title-card">7 day waiting period elapsed</div>
                                        {/* <div className="content-card">Current Balance: 0.0000</div> */}
                                    </div>
                                    <div className="card-item">
                                        <div className={`${bnbBalance > 0 ? 'check-card acive' : 'check-card'}`}>
                                        <img src="./images/check-ac.png" alt="" />
                                            <img src="./images/check-no-ac.png" alt="" />

                                        </div>
                                        <div className="title-card">HLUSD available in wallet</div>
                                        <div className="content-card">HLUSD is required to pay transaction fees on the {chainInfo?.NAME} network.<br />HULSD Balance: {bnbBalance}</div>
                                    </div>
                                    <div className="card-item">
                                        <div className={`${getUser > 0 ? 'check-card acive' : 'check-card'}`}>
                                        <img src="./images/check-ac.png" alt="" />
                                            <img src="./images/check-no-ac.png" alt="" />

                                        </div>
                                        <div className="title-card">You have Unstaked your $HLUP</div>
                                        {/* <div className="content-card">You cannot stake if you have an active UpSwap unstake/withdrawal request</div> */}
                                    </div>
                                </div>
                                <div className="check-real">
                                    <Checkbox checked={isCheckTerm} onChange={onChange} /> <span>I have read the <a href="#" className="terms">Terms and Conditions</a></span>
                                </div>
                            </div>
                        ) : (
                            <>
                                {current === 1 ? (
                                    <div className="content-step2">
                                        <div className="title">Confirm Withdrawal</div>
                                    </div>
                                ) : (
                                    <>
                                        {current === 2 ? (
                                            <div className="content-step5">
                                                <div className="title">Confirmation</div>
                                                <div className="des">Congratulations! Your tokens have withdrawn.</div>
                                                <div className="success-content">
                                                    <div className="img-success">
                                                    <img src="./images/sus.png" alt="" />
                                                    </div>
                                                    <div className="mid-content">Success</div>
                                                    <div className="mid-content1">Congratulations! Your tokens have withdrawn</div>
                                                    <div className="mid-content2">If desired, you may check {chainInfo?.NAME} to confirm the transaction.</div>
                                                    <a className="mid-content3" target="_blank" rel="noreferrer" href={`https://testnet-blockexplorer.helachain.com/tx/${hashUnWr}`}>{hashUnWr}</a>
                                                </div>
                                            </div>
                                        ) : (
                                            <>

                                                {/* {current === 3 ? (
                                                    <div className="content-step4">
                                                        <div className="title">Confirm</div>
                                                        <div className="des">Required transaction 2 of 2</div>
                                                        <div className="confirm-content">
                                                            <div className="cf-content1">In this step, you deposit the tokens into the staking contract.</div>
                                                            <div className="cf-content1">After this step, your tokens will be successfully staked.</div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {current === 4 ? (
                                                            <div className="content-step5">
                                                                <div className="title">Confirmation</div>
                                                                <div className="des">Congratulations! Your tokens are now staked.</div>
                                                                <div className="success-content">
                                                                    <div className="img-success">
                                                                        <img src="./images/img/stake-success.png" alt="" />
                                                                    </div>
                                                                    <div className="mid-content">Success</div>
                                                                    <div className="mid-content1">Congratulations! Your tokens are now staked.</div>
                                                                    <div className="mid-content2">If desired, you may check BNB Smart Chain to confirm the transaction.</div>
                                                                    <a className="mid-content3" href="#">0x6db5d43a31599de3eb6804b64b9f6c2be670d52f02e1cf70248f720a1649fe5c</a>
                                                                </div>
                                                            </div>
                                                        ) : ('')}
                                                    </>
                                                )} */}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>

                </div>
                {/* <Action isSuccess={isSuccess} tabBox={props.tabBox} /> */}

            </div>
            <div className="steps-action">

                {current > 0 && current !== steps.length - 1 && (
                    <button className="btn-basic-default w-fitcontent" onClick={() => prev()}> Previous</button>
                )}
                {current < steps.length - 1 && (
                    <>
                        {showNext ? (
                            <>
                                {current === 1 ?
                                    <>
                                        <button className="btn-basic w-200" onClick={handleWithdraw}> Next</button>
                                    </>
                                    :
                                    <>
                                        <button className="btn-basic w-200" onClick={() => next()}> Next</button>
                                    </>
                                }
                            </>
                        ) : (
                            <button className="btn-basic w-200" disabled> Next</button>
                        )}
                    </>

                )}
                {current === steps.length - 1 && (
                    <button className="btn-basic w-200" onClick={() => setCurrent(0)}> Done</button>

                )}

            </div>

            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                title="Withdrawal"
                visible={isModalOpen}
                footer={false}
                wrapClassName="modal-confirm"
            >
                <p className="modal-text1">Your request is being processed. Please wait...</p>
                <p className="spin-stake">
                    <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 55 }} spin />} />
                </p>

            </Modal>
        </div>


    )
}

export default Withdraw;
