import { Empty } from "antd";
import React, { useEffect, useState } from "react";
import { NftApi } from "../../../config/api/apiNFT";

import { useActiveWeb3React } from "../../../hooks";
import TimeAgo from "../../../config/timeAgo";


const NftLogs = (props) => {
    const { account, chainId } = useActiveWeb3React();
    const { data } = props;
    const [logs, setLogs] = useState([]);

    const [param, setParam] = useState(
        {
            page: 0,
            size: 9,
            sort: 'id,desc'
        }
    );

    const getNftLogs = async (merlionId) => {
      
        let rs = await NftApi.getNftLogs(merlionId, param);
        if(rs){
            setLogs(rs.data);
        }
    }

    useEffect(() => {
        if(data){
            getNftLogs(data?.id)
        }
    }, [data, param]);


    return (
        <>
            {logs.length > 0 ? (
                <>
                    <div className="main-table">
                        <table className="table-mint">
                            <thead>
                                <tr>
                                    <th>Event</th>
                                    <th>Price</th>
                                    <th>From Address</th>
                                    <th>To Address</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logs.length > 0 && logs.map((item,key) => {

                                    let date = new Date(item?.createdDate);
                                    
                                    return(
                                        <tr key={`log-${key}`}>
                                            <td>
                                                {item?.marketEnum}
                                            </td>
                                            <td>
                                                <span>{Number(item?.price).toLocaleString()}</span> HLUP
                                            </td>
                                            <td>
                                                {item?.fromAddress && (<>
                                                    {item?.fromAddress.substring(0, 4)}...{item?.fromAddress.substring(item?.fromAddress.length - 4)}
                                                </>)}
                                                
                                            </td>
                                            <td>
                                                {item?.toAddress && (<>
                                                    {item?.toAddress.substring(0, 4)}...{item?.toAddress.substring(item?.toAddress.length - 4)}
                                                </>)}
                                            </td>
                                            <td>
                                                <TimeAgo timestamp={(date.getTime() / 1000)} />
                                            </td>
                                        </tr>
                                    )
                                })}
                                
                            </tbody>
                        </table>
                    </div>
                </>
            ):(
                <>
                    <Empty />
                </>
            )}
            
        </>
    )
}
export default NftLogs
