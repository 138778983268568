import React, { useEffect, useState } from "react";
import bgMainheader from "./banner-nft.png";
import {Link , useNavigate} from "react-router-dom";
import Marquee from "react-fast-marquee";
import { postData } from "./axios";
import FooterHome from '../common/footer/footerHome';
import { useActiveWeb3React } from "../../hooks";
import { Tabs , Empty, Row, Col, Menu, Dropdown, Collapse, Checkbox, Select } from "antd";
import { FilterOutlined, CaretRightOutlined } from "@ant-design/icons"
import "./style.css";
import nftAttrbutes from '../../components/common/asset/nft/attributes.json';
import { NftApi } from "../../config/api/apiNFT";
import { MAPPING_CHAINID_DECIMAL } from "../../constants";
import ChainNotSupport from "../chain-not-support";

const { Panel } = Collapse;

const MintNft = () => {
    const { account, chainId } = useActiveWeb3React()
    const [data , setData] = useState([]);
    const [sortActive , setSortActive] = useState('PRICE_HIGH_2_LOW');
    const [marketSummary , setMarketSummary] = useState();
    const [attributeSlt , setAttributeSlt] = useState([]);
    const navigate = useNavigate();

    const [param, setParam] = useState(
        {
            attrbuteIds: [],
            pageNumber: 0,
            pageSize: 20,
            sortBy: 'PRICE_HIGH_2_LOW'
        }
    );

    const fetchListData = async () => {
        await postData(`${process.env.REACT_APP_API_URL}/marketplaces/search`, param
        ).then(res => {
            setData(res)
        })
        
    }
    const getMarketSummary = async () => {
        let rs = await NftApi.marketSummary();
        if(rs.status == 200){
            setMarketSummary(rs.data);
        }
    }

    useEffect(() => {
        fetchListData()
    }, [param]);

    useEffect(() => {
        getMarketSummary()
    }, []);

    const handleRedirect = (id, item) => {
        // if(account && account.toLowerCase() == item?.ownerAddress.toLowerCase()){
        //     navigate(`/nft-owner-details/${id}`)
        // }else{
            navigate(`/nft-details/${id}`)
        // }
    }

    const Item = ({item}) => {
        
        return (
            <>
                <Col className="gutter-row" xs={24} xl={6} md={6} sm={6} key={item.id}>
                    <div className="nft-item" onClick={() => handleRedirect(item?.merlionNft.id, item)}>
                        <div className="nft-img">
                            <img src={item?.merlionNft.nftImageURL} alt="" />
                        </div>
                    
                        <div className="nft-info">
                            <span>NFT Collection</span>
                            <span>{item?.merlionNft.name}</span>
                        </div>
                        <div className="nft-info">
                            <span>Curent price:</span>
                            <span>{item?.price ? Number(item?.price).toLocaleString() : '___'} HLUP</span>
                        </div>
                        <div className="nft-info">
                            <span>Sale Type:</span>
                            <span>{item?.typeOfSale ? item?.typeOfSale : '___'}</span>
                        </div>
                    </div>
                </Col>
            </>
        )
    }

    const onChangeAttribute = (checkedValues) => {
        setAttributeSlt(checkedValues);
        setParam({
            ...param,
            attrbuteIds: checkedValues
        });
    };
    const onConfirmFilter = () => {
        if(attributeSlt.length > 0){
            setParam({
                ...param,
                attrbuteIds: attributeSlt
            });
        }
    };
    const onClearFilter = () => {
        setAttributeSlt([]);
        setParam({
            ...param,
            attrbuteIds: []
        });
        
    };

    const menuFilter = (
        <div className="menu-filter">
            <div className="pt-5 pr-10 d-flex d-flex-btw align-center">
                {/* <button className="btn-basic p-5 w-fitcontent height-auto" onClick={onConfirmFilter}>Confirm</button> */}
                <span className="text-darkblue text-right hver text-weight-600" onClick={onClearFilter}>Reset</span>
            </div>
            <Collapse defaultActiveKey={['111']} className="collap-filter" bordered={false} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                {Object.entries(nftAttrbutes).length === 0 ?
                    <>
                        <div className="no-data">
                            <Empty description={false} />
                        </div>
                    </>
                    :
                    <>
                        {Object.entries(nftAttrbutes).map((item,key) => {
                           
                            return(
                                <>
                                    <Panel header={item[0]} key={`atrr-${key}`}>
                                        <Checkbox.Group defaultValue={attributeSlt} style={{ width: '100%' }} onChange={onChangeAttribute}>
                                            <Row>
                                                {item.length > 1 && item[1].length > 0 && item[1].map((attrbute,index) => (
                                                    <Col span={12} className="mb-5" key={`attrbute-${index}`}>
                                                        <Checkbox value={attrbute?.id}>{attrbute?.name}</Checkbox>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </Panel>
                                </>
                            )
                        })}
                    </>
                }
                
                
            </Collapse>
        </div>
    );

    const handleChangeSort = (value) => {
       setSortActive(value);
        setParam({
            ...param,
            sortBy: value
        });
    };

    const FilterTab = () => {
        
        return (
            <>
                <div className="filter-nft">
                    <Select
                        className="custom-slt"
                        defaultValue={sortActive}
                        style={{ width: 120, marginRight: '10px' }}
                        onChange={handleChangeSort}
                        options={[
                            {
                                value: 'PRICE_HIGH_2_LOW',
                                label: 'High to low',
                            },
                            {
                                value: 'PRICE_LOW_2_HIGH',
                                label: 'Low to high',
                            }
                        ]}
                    />
                    <Dropdown placement="bottomRight" overlay={menuFilter}><button className="btn-filter"><FilterOutlined /> Filter</button></Dropdown>
                </div>
            </>
        )
    }

    const changeNftTabs = (key)=>{
        if(key === 'your-nft'){
            navigate('/genesis-nft/profile')
        }
    }

    if(chainId && MAPPING_CHAINID_DECIMAL.hela !== chainId){
        return(
            <>
                <ChainNotSupport />
            </>
        )
    }

    return (
        <>
            <div className="main-nft-section" style={{
                background: `url(${bgMainheader}) no-repeat`,
            }}>
                <div className="nft-banner">
                    <div className="container">
                        <div className="box-banner">
                            <div className="left">
                                <div className="title">
                                    Mint, buy, sell and collect NFTs
                                </div>
                                <div className="desc">
                                    Lucky Badge NFTs are a 10,000-piece unique collection with proof of ownership stored on the blockchain.
                                </div>
                                <div className="gr-button">
                                    <button type="button" className="btn-mint" onClick={()=> window.location.replace('/mint-nft')}>
                                        Mint NFT
                                    </button>
                                    {/* <button type="button" className="btn-mint">
                                        Visit OpenSea
                                    </button> */}
                                </div>
                            </div>
                            <div className="right">
                                <div className="box-img">
                                    <img src="./images/img-nft.png" alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
                <div className="group-number-info">
                    <div className="container">
                        <div className="content-info">
                            <div className="item">
                                <div className="number">
                                    {marketSummary?.volume ? new Intl.NumberFormat("ja-JP").format(marketSummary?.volume) : 0}
                                </div>
                                <div className="text">
                                    Total volume
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    {marketSummary?.price ? new Intl.NumberFormat("ja-JP").format(marketSummary?.price) : 0}
                                </div>
                                <div className="text">
                                    Floor price
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    {marketSummary?.bestOffer ? new Intl.NumberFormat("ja-JP").format(marketSummary?.bestOffer) : 0}
                                </div>
                                <div className="text">
                                    Best offer
                                </div>
                            </div>
                            <div className="item">
                                <div className="number">
                                    {marketSummary?.listed ? new Intl.NumberFormat("ja-JP").format(marketSummary?.listed) : 0}
                                </div>
                                <div className="text">
                                    Listed
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="group-images">
                    <div className="images-info">
                        <Marquee pauseOnHover={true} speed={80}>            
                            <div className="item">
                                <img src="./images/img-row.png" alt="" />
                            </div>
                            <div className="item">
                                <img src="./images/pic-1.png" alt="" />
                            </div>
                            <div className="item">
                                <img src="./images/pic-2.png" alt="" />
                            </div>
                            <div className="item">
                                <img src="./images/pic-3.png" alt="" />
                            </div>
                            <div className="item">
                                <img src="./images/pic.png" alt="" />
                            </div>
                            <div className="item">
                                <img src="./images/pic-1.png" alt="" />
                            </div>
                            <div className="item">
                                <img src="./images/pic-3.png" alt="" />
                            </div>
                        </Marquee>
                    </div>
                </div>
                <div className="main-explore-nft">
                    <div className="container">
                        <div className="group-row">
                            <div className="title">
                                Explore <span>NFTs</span>
                            </div>
                            <div className="main-tabs-nft">
                                <Tabs 
                                    defaultActiveKey="all"
                                    tabBarExtraContent={<>
                                        <div className="d-desktop"><FilterTab /></div>
                                    </>}
                                    onChange={changeNftTabs}
                                >
                                    <Tabs.TabPane tab="All" key="all">
                                        <div className="box-tasb-content">
                                            <Row gutter={16}>
                                                {data.length === 0 ?
                                                    <>
                                                        <Col className="text-center" xs={24}>
                                                            <div className="no-data text-center">
                                                                <img width={200} src="../images/box-nodata.svg" />
                                                                <p className="text-black-green text-weight-500">No data.</p>
                                                                
                                                            </div>
                                                        </Col>
                                                        
                                                    </>
                                                    :
                                                    <>
                                                        {data.length > 0 && data.map((item,key) => (
                                                            <Item item={item}/>
                                                        ))}
                                                    </>
                                                }
                                            </Row>
                                        </div>
                                    </Tabs.TabPane>  
                                    <Tabs.TabPane tab="Your NFT" key="your-nft">
                                        <></>
                                    </Tabs.TabPane>   
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
               
            <FooterHome />
        </>
    )
}
export default MintNft
