
import React from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout, Row, Col, Modal, Steps, Breadcrumb } from 'antd';
import { Loader } from '../common/component';
import './style.css';
import ProjectMenu from './menu-project';
import MyProject from './my-project';
import IcoProject from './ico-project';

const { Content } = Layout;
const IcoLaunchpad = () => {
    const location = useLocation();

    
    return (
        <>
            <div className="ico-list-page">
                <div className="container">
                    <Breadcrumb className='head-breadcrumb-airdrop'>
                        <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                        <Breadcrumb.Item>ICO Launchpad</Breadcrumb.Item>
                    </Breadcrumb>

                    <div className="project-content">
                        <IcoProject />
                    </div>
                </div>
            </div>
              
        </>
    )
}

export default IcoLaunchpad
