import { 
    GET_ALLOCATION_COMMUNITY_BY_ADDRESS, 
    GET_ALLOCATION_GUARANTEED_BY_ADDRESS, 
    GET_ALLOCATION_PRIVATE_BY_ADDRESS, 
    GET_ALLOCATION_BY_ADDRESS,
    GET_SIGNATURE_CLAIM_IDO,
    GET_SIGNATURE_REFUND_IDO
} from "../actions/allocationType";

const allocation = (state = {}, action) => {
    switch(action.type){
        case GET_SIGNATURE_CLAIM_IDO:
            return {
                ...state,
                signatureClaimIdo: action.payload
            }

        case GET_SIGNATURE_REFUND_IDO:
            return {
                ...state,
                signatureRefundIdo: action.payload
            }

        case GET_ALLOCATION_BY_ADDRESS:
            return {
                ...state,
                ownerAllocations: action.payload
            }
            
        default:
            return state
    }
}

export default allocation;