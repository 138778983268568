
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Layout, Row, Col, Modal, Form, Input, Select, Spin, InputNumber, Checkbox, Alert, Space, Radio, Tabs, Tag } from 'antd';
import { useActiveWeb3React } from '../../hooks';
import { StopOutlined, CheckCircleOutlined } from '@ant-design/icons';

import web3 from 'web3';
import { ethers } from 'ethers';
import { getInformationByChain } from '../../config/network/multichainAddresses';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import { Loader } from '../common/component';
// import { postData, putData, getData } from "../../axios";
import abiHelaMintNFT from "../../config/abi/abiHelaMintNFT.json"
import ChainNotSupport from "../chain-not-support";

import NftMarketPlace from './nft-marketplace';
import FooterHome from '../common/footer/footerHome';
import ProfileMarketPlace from './profile-marketplace';
import { postData, putData } from '../mint-nft/axios';
import { GeneisNftApi } from '../../config/api/apiGeneisNFT';
import { _isHelaMinted } from './utils';
import { convertToWei } from '../../utils/convertNumber';
import networkChangeByNumber from '../../utils/networkChangeByNumber';
import CountdownReloadComponent from '../../utils/countDownReload';
import Wallet from '../common/wallet/wallet';
import HelaNFTTab from './hela-nft-tab';
import ZealyNFTTab from './zealy-nft-tab';


const GenesisNft = () => {
    const { account, library, chainId } = useActiveWeb3React();

    const [form] = Form.useForm();
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

    const [loadingCheck, setLoadingCheck] = useState(false);
    const [loadingMint, setLoadingMint] = useState(false);
    // const [isMinted, setIsMinted] = useState(0);
    // const [totalMinted, setTotalMinted] = useState(0);
    // const [canMint, setCanMint] = useState(null);
    // const [count, setCount] = useState(1)

    let nftTabKey = localStorage.getItem('nftTabKey');
    const [nftTab, setNftTab] = useState(nftTabKey ? nftTabKey : "helaNft");

    let mainKey = localStorage.getItem('mainKey');
    const [activeListTab, setActiveListTab] = useState(mainKey ? mainKey : "nft-marketplace");

    const [nftAddress, setNftAddress] = useState('');
    const [zealyNftAddress, setZealyNftAddress] = useState('');
    const [marketAddress, setMarketAddress] = useState('');

    const [startTimeBe, setStartTime] = useState('');
    const [endTimeBe, setEndTime] = useState('');

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    let mintFee = 0.5;

    const getSCSettings = async (keyAddress, key)=>{
        try {
            let res = await GeneisNftApi.getSettingKey(keyAddress);
          
            if (res.status === 200 && res.data && res.data?.settingValue) {
                if(key == 'market'){
                    setMarketAddress(res.data?.settingValue);
                }
                if(key == 'nft'){
                    setNftAddress(res.data?.settingValue);
                }
                if(key == 'zealy_nft'){
                    setZealyNftAddress(res.data?.settingValue);
                }
                if(key == 'start_time'){
                    setStartTime(res.data?.settingValue);
                }
                if(key == 'end_time'){
                    setEndTime(res.data?.settingValue);
                }
                
            }else{
               
                setStartTime('');
                setEndTime('');
            }
        } catch (error) {
            setStartTime('');
            setEndTime('');
        }
    }
    useEffect(() => {
        if(nftTab == "helaNft"){
            getSCSettings('START_TIME_MINT_HELA_NFT', 'start_time');
            getSCSettings('END_TIME_MINT_HELA_NFT', 'end_time');
        }else{
            getSCSettings('START_TIME_MINT_ZEALY_NFT', 'start_time');
            getSCSettings('END_TIME_MINT_ZEALY_NFT', 'end_time');
        }
        
        getSCSettings('MARKET_HELA_NFT_CONTRACT', 'market');
        getSCSettings('HELA_NFT_CONTRACT', 'nft');
        getSCSettings('HELA_ZEALY_NFT_CONTRACT', 'zealy_nft');
    }, [startTimeBe, nftTab]);


    const onChangeTabList = (key) => {
        localStorage.setItem('mainKey', key);
        setActiveListTab(key);
        // window.location.reload();
    };
    const onChangeNftTab = (key) => {
        localStorage.setItem('nftTabKey', key);
        
        // window.location.reload();
        setNftTab(key);
    };

    let nowDate = new Date();
    let startTime = startTimeBe ? new Date(startTimeBe) : '';
    let endTime = endTimeBe ? new Date(endTimeBe) : '';

    const itemsListTab = [
        {
            key: 'nft-marketplace',
            label: 'NFT Marketplace',
            children: <><NftMarketPlace zealyNftAddress={zealyNftAddress} nftAddress={nftAddress} marketAddress={marketAddress}/></>,
        },
        {
            key: 'my-nfts',
            label: 'My NFTs',
            children: <><ProfileMarketPlace zealyNftAddress={zealyNftAddress} nftAddress={nftAddress} marketAddress={marketAddress}/></>,
        }
    ];
    const itemsNftTab = [
        {
            key: 'helaNft',
            label: 'HeLa NFT',
            children: <><HelaNFTTab nftTab={nftTab} nftAddress={nftAddress} marketAddress={marketAddress} startTime={startTime} endTime={endTime} nowDate={nowDate} endTimeBe={endTimeBe} startTimeBe={startTimeBe}/></>,
        },
        {
            key: 'zealyNFT',
            label: 'HeLa Zealy NFT',
            children: <><ZealyNFTTab nftTab={nftTab} nftAddress={zealyNftAddress} marketAddress={marketAddress} startTime={startTime} endTime={endTime} nowDate={nowDate} endTimeBe={endTimeBe} startTimeBe={startTimeBe} /></>,
        }
    ];

    return (
        <>
            <div className='market-page'>
                <div className="container">
                    <div className='mint-nft'>
                        <Tabs centered className='tab-ido-general c-tabs' activeKey={nftTab} items={itemsNftTab} onChange={onChangeNftTab}/>
                        
                        
                    </div>
                    <div className='mint-list'>
                        <div className='mint-list-title'>
                            Explore <span>HeLa NFT</span>
                        </div>
                        <div className='mint-list-tabs'>
                            <Tabs className='tab-ido-general c-tabs' activeKey={activeListTab} items={itemsListTab} onChange={onChangeTabList}/>
                        </div>
                    </div>
                </div>
            </div>
            <FooterHome />
            
            
            
        </>
    )
}

export default GenesisNft
