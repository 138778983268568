import http from "./http-common";

const updateCommitFund = (payload) => {
  return http.put("/campaign/fund-commit/client", payload);
};

const updateClaimRemainingFund = (payload) => {
  return http.put("/campaign/claim-remaining-fund/client", payload);
};

const updateRefundIdo = (payload) => {
  return http.put("/campaign/refund-ido/client", payload);
};

const updateRefundEspecial = (payload) => {
  return http.put("/white-lists/refund-especial", payload);
};

const updateClaimIdo = (payload) => {
  return http.put("/campaign/claim-ido/client", payload);
};

const getSignatureCommit = payload => {
  return http.post("/whitelist/get-sign-commit", payload);
}

const getSignatureRefund = payload => {
  return http.post("/whitelist/get-sign-refund", payload);
}

const getSignatureRemainingFund = payload => {
  return http.post("/whitelist/get-sign-remaining-fund", payload);
}

const getSignatureEspecialRefund = payload => {
  return http.post("/whitelist/get-sign-refund-especial", payload);
}

const getWhitelistByAddress = (roundId, account) => {
  return http.get(`/whitelist/${roundId}/${account}`);
}
const getSignatureRefundIdoModel1 = (payload) =>{
  return http.post(`/whitelist/get-sign-refund-especial`, payload);
}

 


const WhitelistService = {
  updateCommitFund,
  updateClaimRemainingFund,
  updateRefundIdo,
  updateClaimIdo,
  updateRefundEspecial,
  getSignatureCommit,
  getSignatureRefund,
  getSignatureRemainingFund,
  getSignatureEspecialRefund,
  getWhitelistByAddress,
  getSignatureRefundIdoModel1,
};
export default WhitelistService;