/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
import bgMainheader from "../../banner-nft.png";
import { Alert, Breadcrumb, Col, Empty, Form, Input, Modal, Row, Select, Spin } from 'antd';
import { getData , postData } from "../../axios";
import { Link, useLocation , useNavigate, useParams } from "react-router-dom";
import FooterHome from '../../../common/footer/footerHome';
import { CheckCircleFilled } from"@ant-design/icons";
import { useActiveWeb3React } from "../../../../hooks";
import web3 from "web3";
import { useAutionNFTContract, useMarketNFTContract, useNftContract } from "../../../../hooks/useContract";
import {
    _createListing,
    setApproveFixed,
    isApprovedForAll,
    _nftListed,
    _createListingAuction,
    setApproveAuction
} from "../../utilsNFT";
import { Store } from "react-notifications-component";
import addNotify from "../../../common/Notify/addNotify";
import approveContract from "../../utils/approve";
import { NftApi } from "../../../../config/api/apiNFT";
import { ADDRESS_HLUP_ADDRESS, AUCTION_ADDRESS_SC, MARKET_ADDRESS_SC, NFT_ADDRESS_SC } from "../../../../constants";
import { Loader } from "../../../common/component";

const ListForSale = () => {
    const { account, chainId } = useActiveWeb3React();
    let paramsString = location.search;
    let searchParams = new URLSearchParams(paramsString);
    let tokenSearch = searchParams.get("type") ? searchParams.get("type") : 'fixed';

    let { id } = useParams();
    const [form] = Form.useForm();
    const [showInfo, setShowInfo] = useState(false);
    const [allowanceToken, setAllowanceToken] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isListed, setIsListed] = useState(false);
    const [listingPrice, setListingPrice] = useState(0);
 
    const [loading, setLoading] = useState(false);
    const [showListForSale, setShowListForSale] = useState(false);
    const [data, setData] = useState()
    const [typeSale, setTypeSale] = useState(tokenSearch);
    const [timeBiding, setTimeBiding] = useState(0);
    const [nftApprove, setNftApprove] = useState('');

    const MARKET_ADDRESS = MARKET_ADDRESS_SC;
    const NFT_ADDRESS = NFT_ADDRESS_SC;
    const AUCTION_ADDRESS = AUCTION_ADDRESS_SC;

    const contractNft = useNftContract(NFT_ADDRESS);

    const marketContract = useMarketNFTContract(MARKET_ADDRESS);

    const contractBid = useAutionNFTContract(AUCTION_ADDRESS);

    const navigate = useNavigate();

    const fetchListData = async () => {
        await getData(`${process.env.REACT_APP_API_URL}/merlion-nfts/${id}/details`
        ).then(res => {
            setData(res)
        })
    }

    useEffect(() => {
        if(!data){
            fetchListData()
        }
    }, [id]);

    const checkAllowance = async () => {
        try {
            
            await contractNft.getApproved(data?.nftId).then(res => {
                setNftApprove(res)
            })
            
        } catch (error) {}
    }
    const checkIsListed = async () => {
        await marketContract.listings(NFT_ADDRESS, Number(data?.nftId)).then(res => {
            let price = web3.utils.fromWei(web3.utils.toBN(res.price.toString()), "ether");
            if(Number(price) > 0){
                setIsListed(true);
            }
            
        }).catch((err)=>{
            console.log('checkIsListed', err)
        })
    }

    useEffect(() => {
        if(account){
            checkAllowance();
            checkIsListed();
        }
        
    }, [account, data, typeSale]);

    const handleOk = () => {
        setShowSuccess(false);
        navigate(`/nft-owner-details/${id}`)
    };

    const handleCancel = () => {
        setShowSuccess(false);
        navigate(`/nft-owner-details/${id}`)
    };

    const handleChangeTime = (event) => {
        const selectedValue = Number(event);
        let timestamp = 0;
        if (selectedValue > 0) {
            timestamp = Math.floor(Date.now() / 1000) + selectedValue * 24 * 60 * 60;
            // switch (selectedValue) {
            //     case "1":
            //     timestamp = Math.floor(Date.now() / 1000) + 1 * 24 * 60 * 60;
            //     break;
            //     case "2":
            //     timestamp = Math.floor(Date.now() / 1000) + 7 * 24 * 60 * 60;
            //     break;
            //     case "3":
            //     timestamp = Math.floor(Date.now() / 1000) + 14 * 24 * 60 * 60;
            //     break;
            //     case "4":
            //     timestamp = Math.floor(Date.now() / 1000) + 30 * 24 * 60 * 60;
            //     break;
            //     default:
            //     timestamp = 0;
            // }
            setTimeBiding(timestamp);
        }
        return timestamp;
    };

    const handleApproveToken = async () => {
        try {
            if (account) {
                setLoading(true);
                let addressApprove = '';
                if(typeSale === 'fixed'){
                    addressApprove = MARKET_ADDRESS;
                }else{
                    addressApprove = AUCTION_ADDRESS;
                }
                await setApproveFixed(contractNft, addressApprove, data?.nftId)
                .then((res) => {
                    let resWait = res.wait();
                    resWait.then(async (resTransaction) => {

                        if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                            Store.addNotification(addNotify('Approved successfully', 'success'));
                            checkAllowance();
                            setLoading(false);  
                        }
                    })
                
                })
                .catch((error) => {
                    console.error("Error Approve Token", error);
                    setLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            Store.addNotification(addNotify(error.message, 'danger'));
                        } else if (error.reason) {
                            Store.addNotification(addNotify(error.reason, 'danger'));
                        } else {
                            if (error.data && error.data.message) {
                                Store.addNotification(addNotify(error.data.message, 'danger'));
                            }
                        }
                    }
                });
               
            }
        } catch (error) {
            setLoading(false);
            console.error("Error Handle Approve", error);
        }
    };

    const onConfirmListForSale = (value)=>{
       
        if(typeSale === 'fixed'){
            listForSaleByFixed(value);
        }else{
            listForSaleByAuction(value);
        }
        
    }

    const listForSaleByFixed = async (value)=>{
        setLoading(true);

        let priceSale = value?.price_sale;
        setListingPrice(priceSale);

        if (Number(priceSale) <= 0) {
            Store.addNotification(addNotify('Invalid sale price.', 'danger'));
            return;
        }
        if (!marketContract) {
            Store.addNotification(addNotify('Market contract invalid.', 'danger'));
            return;
        }
        if (Number(data?.chainId) !== chainId) {
            Store.addNotification(addNotify('Invalid sale price.', 'danger'));
            return;
        }

        await _createListing(marketContract ,account, NFT_ADDRESS, Number(data?.nftId), priceSale, ADDRESS_HLUP_ADDRESS)
        .then((res) => {
            let resWait = res.wait();
            resWait.then(async (resTransaction) => {

                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    
                    let payload = {
                        // "finishDate": "2023-12-14T13:28:42.579Z",
                        "id": data.id,
                        "marketHash": res.hash,
                        "ownerAddress": account,
                        // "startDate": "2023-12-14T13:28:42.579Z",
                        "startPrice": Number(priceSale),
                        "typeOfSale": "FIXED"
                    };
                    let resAp = await NftApi.updateListingNft(data.id, payload);
                    if (resAp.status === 200) {
                        Store.addNotification(addNotify('Listing successfully', 'success'));
                        setShowSuccess(true);
                        setLoading(false);

                        // setTimeout(() => {
                        //     navigate(`/nft-owner-details/${id}`)
                        // }, 1500);
                        
                    }else{
                        Store.addNotification(addNotify('Server Error', 'danger'));
                        setLoading(false);
                    }
                }
                else {
                    setLoading(false);
                    Store.addNotification(addNotify('Transaction failed', 'warning'));
                    return;
                }

            })
            .catch((error) => {
                setLoading(false);
                if (error.data) {
                    Store.addNotification(addNotify("Transaction failed", 'danger'));
                }
                if (error) {
                    Store.addNotification(addNotify(error?.reason, 'danger'));
                }
            });
        })
        .catch((error) => {
            setLoading(false);
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        });
    }
    const listForSaleByAuction = async (value)=>{
        setLoading(true);
        // console.log('typeSale', typeSale);
        // console.log('data', data);

        if(!value?.duration){
            Store.addNotification(addNotify('Please input duration.', 'danger'));
            setLoading(false);
            return;
        }

        let timeDuration = handleChangeTime(value?.duration);

        console.log('timeDuration', timeDuration);

        let priceSale = value?.price_sale;
        setListingPrice(priceSale);

        if (Number(priceSale) <= 0) {
            Store.addNotification(addNotify('Invalid sale price.', 'danger'));
            setLoading(false);
            return;
        }
        if (timeDuration === 0 || timeDuration === undefined) {
            Store.addNotification(addNotify('Invalid duration', 'danger'));
            setLoading(false);
            return;
        }
        if (!marketContract) {
            Store.addNotification(addNotify('Market contract invalid.', 'danger'));
            setLoading(false);
            return;
        }
        if (Number(data?.chainId) !== chainId) {
            Store.addNotification(addNotify('Invalid sale price.', 'danger'));
            setLoading(false);
            return;
        }

        let startDate = new Date();
        let endDate = new Date(timeDuration * 1000)

        console.log('startDate', startDate.toISOString());
        console.log('endDate', endDate.toISOString());

        

        // console.log('AUCTION_ADDRESS', AUCTION_ADDRESS);

        await _createListingAuction(contractBid, NFT_ADDRESS, Number(data?.nftId), priceSale, ADDRESS_HLUP_ADDRESS, timeDuration)
        .then((res) => {
            let resWait = res.wait();
            resWait.then(async (resTransaction) => {

                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {

            
                    let payload = {
                        "finishDate": endDate.toISOString(),
                        "id": data.id,
                        "marketHash": res.hash,
                        "ownerAddress": account,
                        "startDate": startDate.toISOString(),
                        "startPrice": Number(priceSale),
                        "typeOfSale": "AUCTION"
                    };
                    let resAp = await NftApi.updateListingNft(data.id, payload);
                    if (resAp.status === 200) {
                        Store.addNotification(addNotify('Listing successfully', 'success'));
                        setShowSuccess(true);
                        setLoading(false);

                        // setTimeout(() => {
                        //     navigate(`/nft-owner-details/${id}`)
                        // }, 1500);
                        
                    }else{
                        Store.addNotification(addNotify('Server Error', 'danger'));
                        setLoading(false);
                    }
                }
                else {
                    setLoading(false);
                    Store.addNotification(addNotify('Transaction failed', 'warning'));
                    return;
                }

            })
            .catch((error) => {
                setLoading(false);
                if (error.data) {
                    Store.addNotification(addNotify("Transaction failed", 'danger'));
                }
                if (error) {
                    Store.addNotification(addNotify(error?.reason, 'danger'));
                }
            });
        })
        .catch((error) => {
            setLoading(false);
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        });
    }

    const choiceTypeSale = (type)=>{
        setTypeSale(type);
        navigate(`?type=${type}`);
    }
    

    return (
        <>
            <div className="main-nft-section">
                <div className="container">
                    <div className="box-details-nft">
                        <Breadcrumb className='head-breadcrumb-detail'>
                            <Breadcrumb.Item><a href='/genesis-nft/profile'>NFT profile</a></Breadcrumb.Item>
                            <Breadcrumb.Item><a href='#'>Detail NFT's name</a></Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="mint-after-box">
                            <div className="box-left">
                                <img src={data?.nftImageURL} alt="" />
                            </div>
                            <div className="box-right">
                                <div className="bid-details">
                                    <div className="bid-name">
                                        {data?.name}
                                    </div>
                                    {/* <div className="bid-owner-address">
                                        Owned by: <span>{data?.ownerAddress.substring(0, 4)}...{data?.ownerAddress.substring(data?.ownerAddress.length - 4)}<img src="../../images/copy.png" alt="" /></span>
                                    </div> */}
                                    {account && account.toLowerCase() === data?.ownerAddress.toLowerCase() ? (
                                        <>
                                            <div className="list-for-sale-block">
                                                <div className="choose-type">Choose a type of sale</div>
                                                <div className="type-of-sale">
                                                    <ul>
                                                        <li className={`${typeSale === "fixed" ? "active" : ""}`} onClick={()=>choiceTypeSale("fixed")}>
                                                            <div className="sale-info">
                                                                <p className="text-darkblue text-16 text-weight-600">Fixed price</p>
                                                                <p>The item is listed at the price you set</p>
                                                            </div>
                                                            <span>
                                                                {typeSale === "fixed" && (<CheckCircleFilled className="icon-check"/>)}
                                                            </span>
                                                        </li>
                                                        <li className={`${typeSale === "bidder" ? "active" : ""}`} onClick={()=>choiceTypeSale("bidder")}>
                                                             <div className="sale-info">
                                                                <p className="text-darkblue text-16 text-weight-600">Sell to highest bidder</p>
                                                                <p>The item is listed for auction</p>
                                                            </div>
                                                            <span>
                                                                {typeSale === "bidder" && (<CheckCircleFilled className="icon-check"/>)}
                                                            </span>
                                                        </li>
                                                    </ul>

                                                    
                                                </div>
                                                <div className="choose-type">Set a price</div>
                                                <Form form={form} layout="vertical" autoComplete="off" className="basic-form"
                                                    onFinish={onConfirmListForSale}
                                                >
                                                    <Form.Item
                                                        label="Starting price"
                                                        name="price_sale"
                                                        rules={[
                                                            { required: true, message: 'Please input sale price!' },
                                                            {
                                                                validator: (rule, value, cb) => {
                                                                    !value || parseFloat(value) <= 0
                                                                        ? cb("Sale price must be > 0")
                                                                        : cb();
                                                                }
                                                            }
                                                        ]}
                                                    >
                                                        <Input placeholder="Ex: 5000" suffix={'HLUP'}/>

                                                    </Form.Item>
                                                    {typeSale === "bidder" && (
                                                        <Form.Item
                                                            label="Duration"
                                                            name="duration"
                                                            rules={[
                                                                { required: true, message: 'Please input Duration!' }
                                                            ]}
                                                        >
                                                            <Select placeholder="Select duration">
                                                                <Select.Option value="">Select duration</Select.Option>
                                                                <Select.Option value="0.125" key={'cex-05'}>3 hours</Select.Option>
                                                                <Select.Option value="0.25" key={'cex-05'}>6 hours</Select.Option>
                                                                <Select.Option value="0.5" key={'cex-05'}>12 hours</Select.Option>
                                                                <Select.Option value="1" key={'cex-1'}>1 day</Select.Option>
                                                                <Select.Option value="7" key={'cex-2'}>7 days</Select.Option>
                                                                <Select.Option value="14" key={'cex-3'}>14 days</Select.Option>
                                                                <Select.Option value="30" key={'cex-4'}>30 days</Select.Option>
                                                            </Select>

                                                        </Form.Item>
                                                    )}
                                                    {!isListed && (
                                                        <Form.Item className="btn-content">
                                                            {typeSale === "fixed" && (
                                                                <>
                                                                    {nftApprove.toLocaleLowerCase() === MARKET_ADDRESS.toLocaleLowerCase() ? (
                                                                        <>
                                                                            <button className="btn-basic p-lr-20 w-200" disabled={loading || !account} type="submit">Set Price {loading && <Spin size="small" />}</button>
                                                                        </>
                                                                    ):(
                                                                        <>
                                                                            <button type="button" disabled={loading || !account} className="btn-basic p-lr-20 w-200" onClick={handleApproveToken}>
                                                                                Approve {loading && <Spin size="small" />}
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                            {typeSale === "bidder" && (
                                                                <>
                                                                    {nftApprove.toLocaleLowerCase() === AUCTION_ADDRESS.toLocaleLowerCase() ? (
                                                                        <>
                                                                            <button className="btn-basic p-lr-20 w-200" disabled={loading || !account} type="submit">Set Price {loading && <Spin size="small" />}</button>
                                                                        </>
                                                                    ):(
                                                                        <>
                                                                            <button type="button" disabled={loading || !account} className="btn-basic p-lr-20 w-200" onClick={handleApproveToken}>
                                                                                Approve {loading && <Spin size="small" />}
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                            
                                                        </Form.Item>
                                                    )}
                                                    

                                                </Form>
                                            </div>
                                        </>
                                    ):(
                                        <>
                                            <Alert className="mt-15" message="You're not owned" type="error" />
                                        </>
                                    )}
                                    
                                   
                                    
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
                <Modal className="modal-mint modal-list-done" open={showSuccess} onOk={handleOk} onCancel={handleCancel}>
                    <div className="modal-content-mint">
                        <div className="title text-green">
                            Listing Successfully!
                        </div>
                        <div className="mt-15 mb-15 modal-nft">
                            <Row gutter={32}>
                                <Col className="gutter-row" xs={24} xl={7} md={7} sm={7}>
                                    <div className="modal-nft-img">
                                        <img src={data?.nftImageURL} alt="" />
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} xl={17} md={17} sm={17}>
                                    <div className="modal-nft-info">
                                        <div className="nft-info-title">Funny Merlion NFT</div>
                                        <div className="nft-info-name">{data?.name}</div>
                                        <div className="nft-info-label">Listing price:</div>
                                        <div className="nft-info-price">
                                            <img width={25} src="../../images/up-favi.svg" alt="" /> 
                                            {Number(listingPrice).toLocaleString()} HLUP
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-list-info">
                            <Row gutter={16}>
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="label-info">Listing price:</div>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="label-value">{Number(listingPrice).toLocaleString()} HLUP</div>
                                </Col>
                            </Row>
                            <Row gutter={16} className="mt-10">
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="label-info">Creator earnings:</div>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="label-value">{data?.creatorFee ? data?.creatorFee : 0} %</div>
                                </Col>
                            </Row>
                            <Row gutter={16} className="mt-10">
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="label-info">Creator earnings:</div>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="label-value">{data?.creatorFee ? data?.creatorFee : 0} %</div>
                                </Col>
                            </Row>
                            <Row gutter={16} className="mt-10">
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="label-info">Creator earnings:</div>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="label-value">{data?.creatorFee ? data?.creatorFee : 0} %</div>
                                </Col>
                            </Row>
                            <Row gutter={16} className="mt-10">
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="label-info">Type of Sale:</div>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="label-value">
                                        {data?.marketplaceDetails?.typeOfSale === "FIXED" ? (
                                            <>Fixed price</>
                                        ):(
                                            <>Auction price</>
                                        )}
                                        
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={16} className="mt-10">
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="label-info">Total potential earnings:</div>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                    <div className="label-value">
                                        {listingPrice > 0 ? listingPrice - ((data?.creatorFee/100) * listingPrice) : 0} HLUP
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        
                        <div className="text-center mt-15">
                            <button className="btn-basic w-200" onClick={handleOk}>Complete Listing!</button>
                        </div>
                    </div>
                </Modal>
            </div>
            <FooterHome />
            {loading && (<><Loader /></>)}
        </>
    )
}
export default ListForSale
