import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { InjectedConnector } from '@web3-react/injected-connector';
//import { WalletConnector } from './walletConnect';
import { BscConnector } from './bsc';

//export const walletconnect = new WalletConnector({});
export const walletconnect = new WalletConnect({
	bridge: "https://bridge.walletconnect.org",
	qrcodeModal: QRCodeModal
});
export const injected = new InjectedConnector({
	supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 137, 42161, 421613, 80001, 666888, 8668]
	// supportedChainIds: [666888]
});

export const bsc = new BscConnector({
	supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 137, 42161, 421613, 80001, 666888, 8668]
	// supportedChainIds: [666888]
});

