import userActions from './userActions';
import campaignAction from './campaignAction';
import whitelistAction from './whitelistAction';
import authAction from './authAction';
import allocationAction from './allocationAction';
import inoAction from './inoAction';
import airdropAction from './airdropAction';
import gamehubAction from './gamehubAction';

const allActions = {
    userActions,
    campaignAction,
    whitelistAction,
    authAction,
    allocationAction,
    inoAction,
    airdropAction,
    gamehubAction
}

export default allActions