import Web3 from "web3";
import {convertFromWei,convertToWei} from "./convertNumber";
const web3 = new Web3(window.ethereum);

async function approveContract(spender, tokenAddress, account) {
  const contract = new web3.eth.Contract(
    [
      {
        constant: true,
        inputs: [],
        name: "name",
        outputs: [{ name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [{ name: "", type: "uint8" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "totalSupply",
        outputs: [{ name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "symbol",
        outputs: [{ name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ name: "_owner", type: "address" }],
        name: "balanceOf",
        outputs: [{ name: "balance", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          { name: "_owner", type: "address" },
          { name: "_spender", type: "address" },
        ],
        name: "allowance",
        outputs: [{ name: "remaining", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          { name: "spender", type: "address" },
          { name: "amount", type: "uint256" },
        ],
        name: "approve",
        outputs: [{ name: "remaining", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
    ],
    tokenAddress
  );
  try {
    const supply = await contract.methods.totalSupply().call();
    const decimals = await contract.methods.decimals().call();
    const supplyToWei = await convertToWei(supply,Number(decimals))
    const gasPrice = await web3.eth.getGasPrice();
    const txh = await contract.methods
      .approve(spender, supplyToWei)
      .send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)), });

    return txh;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
export default approveContract;
