import { ROUND_TYPE } from "../constants";
import AllocationService from "../services/AllocationService";
import {
  UPDATE_CLAIM_IDO,
  GET_SIGNATURE_CLAIM_IDO,
  GET_ALLOCATION_BY_ADDRESS,
  GET_SIGNATURE_REFUND_IDO
} from "./allocationType";

const updateClaimIdo = (payload) => async (dispatch) => {
    try {
      const res = await AllocationService.updateClaimIdo(payload);
      dispatch({
        type: UPDATE_CLAIM_IDO,
        payload: {}
      });
    } catch (err) {
      console.log(err);
    }
}

const getSignatureClaimIdo = (payload, callback) => async (dispatch) => {

  await AllocationService.getSignatureClaimIdo(payload)
  .then((res) => {
    if(res && res.status===200) {
      let data = res.data && res.data.data;
      dispatch({type: GET_SIGNATURE_CLAIM_IDO, payload: data});
      callback({signatureClaimIdo: data});
    }
  })
  .catch(({ error }) => {
    console.log(error);
  });
}

const getSignatureRefundIdo = (payload, callback) => async (dispatch) => { 
  await AllocationService.getSignatureRefundIdo(payload)
  .then((res) => {
    if(res && res.status===200) {
      let data = res.data && res.data.data;
      dispatch({type: GET_SIGNATURE_REFUND_IDO, payload: data});
      callback({signatureRefundIdo: data});
    }
  })
  .catch(({ error }) => {
    console.log(error);
  });
} 

const getAllocationByAddress  = (roundId, roundType, account) => async (dispatch) => {

  await AllocationService.getAllocationByAddress(roundId, account)
  .then((res) => {
    if(res && res.status===200) {
      dispatch({type: GET_ALLOCATION_BY_ADDRESS, payload: res.data});
    }
  })
  .catch(({ error }) => {
    console.log(error);
  });
}

const updateRefund  = (payload, callback) => async (dispatch) => { 
  await AllocationService.updateRefund(payload)
  .then((res) => {
     callback(res);
  })
  .catch(({ error }) => {
    console.log(error);
  });
}

export default {
  updateClaimIdo,
  getSignatureClaimIdo,
  getAllocationByAddress,
  getSignatureRefundIdo,
  updateRefund,
}