import React from 'react';
import { Link } from 'react-router-dom';
import { useActiveWeb3React } from '../../hooks';
import { formatShortDateAndTimeAllocations } from '../../utils/formatDatetime';
import { formatData } from '../../utils/StringHelper';
import ItemCommitedRound from './itemCommitedRound';

const ItemCommitted = ({item}) => {

    const {
        acceptCurrency,
        bannerURL,
        handle,
        logoUrl,
        roundList,
        status,
        symbolToken,
        title,
        oldVersion,
        network,
        claimChain
    } = item;
    
    const campaignId = item?.id;

    // let handleStatus = status.toLowerCase();
    const { account, chainId } = useActiveWeb3React();
    // if(status === "TBA") {
    //     handleStatus = 'Upcoming';
    // }

    const roundListRender = roundList && roundList.map((item,key) => {
        if(item.actualFundNeeded && item.actualFundNeeded > 0){
            return <ItemCommitedRound 
                        commitedCampaign={{
                            campaignId, 
                            roundId: item?.id, 
                            acceptCurrency, 
                            symbolToken, 
                            oldVersion, 
                            account,
                            chainId,
                            network,
                            claimChain,
                            status
                        }}
                        commitedRound={item} 
                        key={key}
                    />;
        }
    });

    return(
        <div className="c-list__item">
            <Link to={handle} className="c-list__logo">
                <div className="c-list__img">
                    <img src={bannerURL} alt={title} />
                    {item?.modelType === 1
                        ? <div className={`c-list__status protected`} style={{textTransform: 'capitalize'}}>
                           {/* <img src='../images/protected.svg'></img> */}
                            Riskless
                            </div>
                        : <div className="c-list__status" style={{textTransform: 'capitalize'}}>Shielded</div>
                    } 
                    
                </div>
                <div className="c-list__title">
                    <h3>{title}</h3>
                    <span><img src={logoUrl} alt={title} /></span>
                </div>
            </Link>
            <div className="c-list__body">
                {roundListRender}
            </div>
        </div>
    );
}

export default ItemCommitted;
