import Web3 from "web3";
import BSC_PANCAKE_FACTORY_ABI_V2 from "../constants/abi/pancake/ARB_PANCAKE_FACTORY_ABI_V2.json";
import ETH_PANCAKE_FACTORY_ABI_V2 from "../constants/abi/pancake/ETH_PANCAKE_FACTORY_ABI_V2.json";
import ARB_PANCAKE_FACTORY_ABI_V2 from "../constants/abi/pancake/ARB_PANCAKE_FACTORY_ABI_V2.json";
import {
  WSS_PROVIDER_BSC,
  WSS_PROVIDER_ETH,
  WSS_PROVIDER_ARB,
  BSC_PANCAKE_FACTORY_ADDRESS_V2,
  ETH_PANCAKE_FACTORY_ADDRESS_V2,
  ARB_PANCAKE_FACTORY_ADDRESS_V2,
  WBNB_BSC,
  BUSD_BSC,
  USDT_BSC,
  USDC_BSC,
  WETH_ETH,
  USDT_ETH,
  USDC_ETH,
  WETH_ARB,
  USDC_ARB,
  USDT_ARB,
} from "../constants/index";

export function getFactory(chainId) {
  if (chainId === 56) {
    const web3 = new Web3(WSS_PROVIDER_BSC);
    return new web3.eth.Contract(
      BSC_PANCAKE_FACTORY_ABI_V2,
      BSC_PANCAKE_FACTORY_ADDRESS_V2
    );
  }
  if (chainId === 1) {
    const web3 = new Web3(WSS_PROVIDER_ETH);
    return new web3.eth.Contract(
      ETH_PANCAKE_FACTORY_ABI_V2,
      ETH_PANCAKE_FACTORY_ADDRESS_V2
    );
  }
  if (chainId === 42161) {
    const web3 = new Web3(WSS_PROVIDER_ARB);
    return new web3.eth.Contract(
      ARB_PANCAKE_FACTORY_ABI_V2,
      ARB_PANCAKE_FACTORY_ADDRESS_V2
    );
  }
}

export async function getPairsByToken_BSC(tokenAddress, chainId) {
  var pairCoins = [WBNB_BSC, USDT_BSC, BUSD_BSC, USDC_BSC];
  var pairs = new Map();
  await Promise.all(
    pairCoins.map(async (pairCoinAddress) => {
      let pair = await getFactory(chainId)
        .methods.getPair(tokenAddress, pairCoinAddress)
        .call(function (err, res) {});
      if (parseInt(pair, 16) !== 0) {
        pairs.set(pairCoinAddress, pair);
      }
    })
  );
  return pairs;
}

export async function getPairsByToken_ETH(tokenAddress, chainId) {
  var pairCoins = [WETH_ETH, USDT_ETH, USDC_ETH];
  var pairs = new Map();
  await Promise.all(
    pairCoins.map(async (pairCoinAddress) => {
      let pair = await getFactory(chainId)
        .methods.getPair(tokenAddress, pairCoinAddress)
        .call(function (err, res) {});
      if (parseInt(pair, 16) !== 0) {
        pairs.set(pairCoinAddress, pair);
      }
    })
  );
  return pairs;
}

export async function getPairsByToken_ARB(tokenAddress, chainId) {
  var pairCoins = [WETH_ARB, USDT_ARB, USDC_ARB];
  var pairs = new Map();
  await Promise.all(
    pairCoins.map(async (pairCoinAddress) => {
      let pair = await getFactory(chainId)
        .methods.getPair(tokenAddress, pairCoinAddress)
        .call(function (err, res) {});
      if (parseInt(pair, 16) !== 0) {
        pairs.set(pairCoinAddress, pair);
      }
    })
  );
  return pairs;
}

export async function checkPriceToken(tokenAddres, chainId) {
  if (chainId === 56) {
    const tokenPairs = await getPairsByToken_BSC(tokenAddres, chainId);
    if (tokenPairs.size !== 0) {
      return true;
    } else {
      return false;
    }
  }
  if (chainId === 42161) {
    const tokenPairs = await getPairsByToken_ARB(tokenAddres, chainId);
    if (tokenPairs.size !== 0) {
      return true;
    } else {
      return false;
    }
  }
  if (chainId === 1) {
    const tokenPairs = await getPairsByToken_ETH(tokenAddres, chainId);
    if (tokenPairs.size !== 0) {
      return true;
    } else {
      return false;
    }
  }
}
