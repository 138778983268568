import React,{useEffect, useState} from 'react' 
import {useDispatch, useSelector} from 'react-redux'; 
import allActions from '../../actions';
import Slider from "react-slick";
import ItemGameHub from './itemGamehub';

const GamehubHot = () => {
    const dispatch = useDispatch();
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState(); 
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: true,
    };
    const [popularPrice, setPopularPrice] = useState([])
    
    useEffect(() => { 
        dispatch(allActions.gamehubAction.getGameHubByPopularStatus("popular", (res)=> {  
            if(res.callResult === true) {  
                dispatch(allActions.gamehubAction.getPriceMarket(res.gamehubPopularList, (res)=>{ 
                    setPopularPrice(res?.data);
                }));  
            }
        })); 
    }, [dispatch]);

    const gamehubPopularList = useSelector(state=>state?.gamehub.gamehubPopularList);  
    return (  
        <div className="p-gameHub__box">
                <h3 className="p-gameHub__title">Popular Categories</h3>
            <div className="c-listCate">  
                {gamehubPopularList && [gamehubPopularList[0], gamehubPopularList[1], gamehubPopularList[2]]?.map((item,index)=>( 
                    <ItemGameHub item={item} key={index} marketPriceArr={popularPrice} />
                ))}  
            </div>
        </div>  
    )
}
export default GamehubHot;