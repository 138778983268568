export const formatDateTime = (dateTime) => {
  var options = { 
    weekday: 'short', 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric',
    hour: '2-digit', 
    minute: '2-digit',
    timeZone: 'UTC',
    timeZoneName: 'short',
    hour12: true
  };

  return dateTime.toLocaleTimeString('en-GB', options);
}
export const formatShortDateTime = (dateTime) => {
  var options = { 
    // weekday: 'short', 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric',
    hour: '2-digit', 
    minute: '2-digit',
    timeZone: 'UTC',
    // timeZoneName: 'short',
    hour12: false
  };

  return dateTime.toLocaleTimeString('en-GB', options);
}

export const formatShortDateEnUs = (dateTime) => {
  let _dateTime = new Date(dateTime);
  var options = { 
    year: 'numeric', 
    month: 'short', 
    day: 'numeric'
  };

  return _dateTime.toLocaleDateString('en-US', options);
}

export const convertDateTime = (dateTime) => {
  var options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC',
      timeZoneName: 'short',
      hour12: true
  };

  return dateTime.toLocaleTimeString('en-GB', options);
}

export const formatShortDateAndTimeAllocations = (dateTime, isMobile = false) => {
  let _dateTime = new Date(dateTime);
  var options = { 
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
    timeZoneName: 'short',
    hour12: true
  };
  let dateTime1 = _dateTime.toLocaleTimeString('en-GB', options);
  let dateTime2 = dateTime1.split(',');
  if(isMobile) {
    return <>{dateTime2[0]} {dateTime2[1]?.toUpperCase()}</>;
  }

  return <>{dateTime2[1]?.toUpperCase()} <br/> {dateTime2[0]}</>;
}
