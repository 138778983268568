import React, {useState, useEffect} from 'react';
import {useActiveWeb3React} from "../../hooks";
import {useSelector, useDispatch} from 'react-redux';
import allActions from '../../actions';
import NodataImg from '../common/asset/images/nodata-upcoming.png';
import { Pagination, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import ItemClaimable from './itemClaimable';
import { Loader, LoadingContent } from '../common/component';

const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

const EndedIno = () => { 
    const dispatch = useDispatch(); 
    const [page, setPage] = useState(0);
    const [loadingPage, setLoadingPage] = useState(false);
    let size = 6;  

    useEffect(() => { 
        setLoadingPage(true)
        dispatch(allActions.inoAction.getIno({
        filterEnum: "ENDED",
        networkChainEnum: "BSC",  
    }, (res)=>{
        if(res.callResult === true) {
            setLoadingPage(false);
        }
    }));
    }, [dispatch]); 

    function onChangePaging(pageNumber) {
        setPage(pageNumber - 1);
    }  
    const inoData = useSelector(state => state.inoData.inoList);
    
    const ItemClaimableList = inoData && inoData.map((item,key) => (
        <ItemClaimable key={key} item={item} />
    )); 

    return(
        <>
            <div className="c-statusRow">
                <h3>BOX NAME</h3>
                <h3>INO DATE</h3> 
                <h3>PRICE<br/><span className='sub_title'>(/BOX)</span></h3> 
                <h3>TOTAL SALE<br/><span className='sub_title'>(BOXES)</span></h3>
                <h3>NETWORK</h3>
            </div>
            <div className="c-list is-joinedClaim">
            {loadingPage ?<Loader /> : (
                <>
                {inoData && inoData.length > 0 ? 
                ItemClaimableList : 
                (<div className="c-nodata c-nodata--upcoming">
                        <img src={NodataImg} alt="No Data" />
                        <p>No data</p>
                    </div>)}
                </>)}
            </div>
            {!loadingPage && <div className="pani-list">
                {inoData && inoData.length > 0 && 
                <Pagination
                    defaultCurrent={1}
                    // total={campaignsCount}
                    pageSize = {size}
                    onChange={onChangePaging}
                    showSizeChanger={false}
                />}
            </div>}
        </>
    );
};

export default EndedIno;