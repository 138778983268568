import UserService from "../services/UserService";
import {
    SET_CURRENT_USER,
    GET_STAKING_USER
} from "./userType";

const setCurrentUser = (user) => {
    return {
        type: SET_CURRENT_USER,
        payload: user
    }
}

const setChainId = (chainObj) => {
    return {
        type: "SET_CHAIN",
        payload: chainObj
    }
}

const logOut = () => {
    return {
        type: "LOG_OUT"
    }
}

const getStakingUser = (address) => async (dispatch) => {
    try {
      const res = await UserService.getStakingUser(address);  
      dispatch({
        type: GET_STAKING_USER,
        payload: res.data
      });
    } catch (err) {
      console.log(err);
    }
  }

const userAction = {
    setCurrentUser,
    setChainId,
    logOut,
    getStakingUser
};

export default userAction;