import React, { useState, useEffect, useCallback } from 'react';
import { Layout, Row, Col, Tabs } from 'antd';
import { BrowserRouter as Router, Route, Link, useLocation } from 'react-router-dom';
import './style.css';
const { TabPane } = Tabs;

const ProjectMenu = () => {
    const location = useLocation();


    const onChangeTabs = (key) => {
        if(key === "my-project"){
            window.location.replace('/ico/my-project')
        }else{
            window.location.replace('/ico')
        }
    };

    return (
        <div className="project-menu mt-15">
            
            <Tabs defaultActiveKey="my-project" onChange={onChangeTabs} className="tab-ido-general c-tabs" >
                <TabPane tab="All" key="all">
                 
                </TabPane>
                <TabPane tab="Upcoming" key="upcoming">
                   
                </TabPane>
                <TabPane tab="Opening" key="opening">
                   
                </TabPane>
                <TabPane tab="Filled" key="filled">
                    
                </TabPane>
                <TabPane tab="Closed" key="closed">
                    
                </TabPane>
                <TabPane tab="My Project" key="my-project">
                    
                </TabPane>
            </Tabs>
        </div>
    );
}

export default ProjectMenu