import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import allActions from '../../actions';
import IconClose from '../common/asset/images/icon-close.svg';

const PopupBoxes = ({id, onCancel}) => {
    const dispatch = useDispatch(); 
    const inoBoxesById = useSelector(state => state.inoData?.inoBoxes); 
    useEffect(() => {
        if(id) {
            dispatch(allActions.inoAction.getBoxesById(id));
        }
    }, [id]);  
    
    return(
        <div className="c-popup">
            <div className="c-popup__box">
                <button className="c-popup__close" onClick={onCancel}><img src={IconClose} alt="Close" /></button>
                <div className="c-popup__name">
                    <img src={inoBoxesById?.imageLink ? inoBoxesById?.imageLink : '../../images/ino-default.svg'} alt={inoBoxesById?.name} />
                    {inoBoxesById?.name}
                </div>
                <p className="c-popup__des">{inoBoxesById?.description}</p>
                <button className="c-btn">Buy Now</button>
            </div>
        </div>
    );
}

export default PopupBoxes;