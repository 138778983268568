import React, { useEffect, useState } from 'react';
import useWindowSize from '../../hooks/useWindowSize';
import { Col, Tabs } from 'antd';
import NodataImg from '../common/asset/images/nodata.svg';
import { useSelector, useDispatch } from 'react-redux';
import { formatData } from '../../utils/StringHelper';
import { getCampaignTimeItem, getChainInformation } from '../../utils/campaignUltils';
import { useActiveWeb3React } from "../../hooks";
import {

    IDO_CLAIM_TYPE_DAILY,
    IDO_CLAIM_TYPE_MONTHLY,
} from '../../constants';
import {
    useClaimDailyContract,
    useIdoTokenClaimContract,
    useJoinPoolContract,
} from "../../hooks/useContract";
import {
    _totalClaimedSC,
    _isRefundedSC,
     _isJoinedSC,
    convertFromWei,
    _totalDailyClaimedSC,
    _totalRefundedSC
} from "./utils";
import web3 from "web3";
import MonthlyAllocation from './MonthlyAllocation';
import { Spin } from 'antd';
import { truncateNumber } from '../../utils/formatNumberCurrency';
import DailyAllocation from './DailyAllocation';

const spinLoading = () => <Spin size="small" style={{ position: 'absolute', marginLeft: "5px" }} />;

const YourInvetmentAllocation = ({ item }) => {
   
    const { TabPane } = Tabs;
    const size = useWindowSize();
    const sizeTablet = 991;
    const provider = process.env.REACT_APP_NETWORK_URL ?? '';
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    const { account, chainId } = useActiveWeb3React();
    const [isJoined, setIsJoined] = useState(false);
    const [totalClaimed, setTotalClaimed] = useState(0);
    const [totalRefunded, setTotalRefunded] = useState(0);
    const [totalRefundedEspecial, setTotalRefundedEspecial] = useState(0);
    const [isRefundedAll, setIsRefundedAll] = useState(false);
    const [isRefundedEspecial, setIsRefundedEspecial] = useState(false);
    const [totalDailyClaimed, setTotalDailyClaimed] = useState(0);
    const campaignsData = useSelector(state => state.campaignsData);

    const {
        addressClaimIdo,
        addressClaimDaily,
        allowRefund,
        refundDuration,
        addressCommited,
        totalRaise,
        claimType,
        whitelist,
        round,
        allocations,
        phase,
        slots,
        refundEspecialAllow,
        addressRefundEspecial,
        commitRangeMaxPercent,
        commitRangeMinPercent,
        vestingInfos,
    } = item;

    const roundId = item && item.id;
    const {
        acceptCurrency,
        tokenInform,
        oldVersion,
        network,
        claimChain,
    } = campaignsData;

    const roundTime = getCampaignTimeItem(campaignsData, item);
    const symbol = tokenInform && tokenInform.symbol;
    const tokenDecimals = tokenInform && tokenInform.decimals;

    const actualFundNeeded = whitelist?.actualFundNeeded;
    // const claimedToken = whitelist?.claimedToken;
    const commitRange = whitelist?.commitRange;
    const fundCommitted = whitelist?.fundCommitted;
    const remainingFund = whitelist?.remainingFund;
    const tokenAllocation = whitelist?.tokenAllocation;
    const joinPoolContract = useJoinPoolContract(addressCommited, oldVersion);  
    const claimContract = useIdoTokenClaimContract(addressClaimIdo);
    const claimDailyContract = useClaimDailyContract(addressClaimDaily);
    const refundEspecialContract = useIdoTokenClaimContract(addressRefundEspecial);

    const startClaimDate = new Date(roundTime.startClaim);
    var refundAllEndDate = new Date(roundTime.startClaim);
    refundAllEndDate.setHours(startClaimDate.getHours() + (refundDuration ? refundDuration : 2));
    let isWhitelisted = (whitelist || phase === 2) ? true : false;

    const chainInformation = getChainInformation(chainId);

    // DAILY CLAIM
    useEffect(() => {
        try {
            if (claimDailyContract && addressClaimDaily && tokenDecimals) {
                _totalDailyClaimedSC(claimDailyContract, account).then((res) => {
                    if (res && res.tokensClaimed) {
                        setTotalDailyClaimed(convertFromWei(res.tokensClaimed, tokenDecimals));
                    }
                });
            }
        } catch (error) {
            // TODO
        }
    }, [account, claimDailyContract]);

    useEffect(() => {
        if (joinPoolContract && account && roundId && campaignsData?.id) {
            _isJoinedSC(joinPoolContract, account, roundId, campaignsData?.id, oldVersion)
                .then((res) => {
                    setIsJoined(res);
                });

            if (claimChain && network !== claimChain && actualFundNeeded && actualFundNeeded > 0) {
                setIsJoined(true);
            }

            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [joinPoolContract, account, roundId]);


    useEffect(() => {
        if (account && claimContract) {
            _totalClaimedSC(claimContract, account).then((res) => {
                setTotalClaimed(parseFloat(convertFromWei(res, tokenDecimals)));
            });

            _isRefundedSC(claimContract, account).then((res) => {
                setIsRefundedAll(res);
            });

            _totalRefundedSC(claimContract, account).then((res) => {
                setTotalRefunded(parseFloat(convertFromWei(res, chainInformation.decimal)));
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, claimContract]);

   
    useEffect(() => {
        if (account && refundEspecialContract) {
            _isRefundedSC(refundEspecialContract, account).then((res) => {
                setIsRefundedEspecial(res);
            });

            _totalRefundedSC(refundEspecialContract, account).then((res) => {
                setTotalRefundedEspecial(parseFloat(convertFromWei(res, chainInformation.decimal)));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, refundEspecialContract]);

    


    allocations && allocations?.sort(function (a, b) {
        return new Date(a.claimTime) - new Date(b.claimTime);
    });

    const allocationsMonthlyRenderer = allocations && allocations?.length > 0 && allocations?.map((item, key) => {
        const vestingInfo = vestingInfos && vestingInfos.filter((vestingInfosItem) => {
            return (new Date(vestingInfosItem.vestingTime + ':00Z')).getTime() === (new Date(item.claimTime)).getTime();
        });

        return (<MonthlyAllocation
            allocationItem={item}
            whitelist={whitelist}
            tokenDecimals={tokenDecimals}
            isRefundedAll={isRefundedAll || isRefundedEspecial}
            oldVersion={oldVersion}
            key={key}
            vestingInfo={vestingInfo && vestingInfo?.length > 0 ? vestingInfo[0] : {}}
            allowRefundRound={allowRefund}
            refundEspecialAllow={refundEspecialAllow}
        />);
    });

    const allocationsDailyFirstRenderer = allocations && allocations?.length > 0 && allocations.map((item, key) => {

        if ((allocations?.length - 1) !== key) {
            return (<MonthlyAllocation
                allocationItem={item}
                whitelist={whitelist}
                tokenDecimals={tokenDecimals}
                isRefundedAll={isRefundedAll || isRefundedEspecial}
                oldVersion={oldVersion}
                key={key}
            />);
        }
    });

    const allocationsZWZDailyFirstRenderer = allocations && allocations?.length > 0 && allocations.map((item, key) => {

        if (key == 0) {
            return (<MonthlyAllocation
                allocationItem={item}
                whitelist={whitelist}
                tokenDecimals={tokenDecimals}
                isRefundedAll={isRefundedAll || isRefundedEspecial}
                oldVersion={oldVersion}
                key={key}
            />);
        }
    });

    const allocationsDailyRenderer = (
        <>
            {(roundId === 14202 || roundId === 14201) ? allocationsZWZDailyFirstRenderer : allocationsDailyFirstRenderer}

            <DailyAllocation
                allocationItem={allocations && allocations?.length > 0 && allocations[allocations && allocations?.length - 1]}
                key={allocations && allocations?.length - 1}
                addressClaimDaily={addressClaimDaily}
                isRefunded={isRefundedAll || isRefundedEspecial}
            />
        </>
    );

    const allocationsNoDataRenderer = (
        <tr>
            <td colSpan="4" className="nodata">
                <div className="c-nodata">
                    <img src={NodataImg} alt="No Data" />
                    <p>No Data</p>
                </div>
            </td>
        </tr>
    );

    let commitRangeArray = [];
    if (phase === 2) {
        let commitStandard = totalRaise && slots && parseFloat(totalRaise / slots);

        if (commitStandard) {
            let minCommit = commitRangeMinPercent && (commitStandard * commitRangeMinPercent) / 100;
            let maxCommit = commitRangeMaxPercent && (commitStandard * commitRangeMaxPercent) / 100;
            commitRangeArray.push(minCommit);
            commitRangeArray.push(maxCommit);
        }
    }
    else if (oldVersion) {
        commitRangeArray = commitRange && commitRange.split("-");
    }
    else {
        commitRangeArray = commitRange && commitRange.split("-");
    }

    const allocationRender = () => {
        // if (campaignsData?.id === 100028){
        //     if (allocations && allocations?.length === 0
        //         || !whitelist.fundCommitted
        //         || whitelist.fundCommitted === 0
        //         || campaignsData?.id === 100016 //cheat campainId: 100016
        //     ) {
        //         return <>{allocationsNoDataRenderer}</>;
        //     }
        // }else{
        //     if (allocations && allocations?.length === 0
        //         || !isJoined
        //         || !whitelist.fundCommitted
        //         || whitelist.fundCommitted === 0
        //         || campaignsData?.id === 100016 //cheat campainId: 100016
        //     ) {
        //         return <>{allocationsNoDataRenderer}</>;
        //     }
        // }

        // console.log('isJoined', isJoined);
        // console.log('whitelist.fundCommitted', whitelist.fundCommitted);
 
        if (allocations && allocations?.length === 0 || !isJoined || !whitelist.fundCommitted || whitelist.fundCommitted === 0) {
            return <>{allocationsNoDataRenderer}</>;
        }
        

        if (claimType === IDO_CLAIM_TYPE_MONTHLY) {
            return <>{allocationsMonthlyRenderer}</>;
        } else if (claimType == IDO_CLAIM_TYPE_DAILY) {
            return <>{allocationsDailyRenderer}</>;
        }
    }

    return (
        <>
            <div className='invest-allo-title'>{round}</div>
            <Tabs className="c-tabs p-15" defaultActiveKey={item?.allocations?.length > 0 ? "2" : "1"}>
                <TabPane tab="Your Investment" key="1">
                    <div className="c-campaignRound__info">
                        <p>
                            <span>Commit Range</span>
                            {commitRangeArray && commitRangeArray?.length > 0
                                ? (
                                    commitRangeArray && commitRangeArray[0] === commitRangeArray[1]
                                        ? (formatData(truncateNumber(commitRangeArray && commitRangeArray[0], 1), acceptCurrency, isWhitelisted))
                                        : (formatData(truncateNumber(commitRangeArray && commitRangeArray[0], 1), '', isWhitelisted) + " - " + formatData(parseFloat(commitRangeArray && commitRangeArray[1]).toFixed(1), acceptCurrency, isWhitelisted))
                                ) : '--'}
                        </p>
                        <p><span>Fund Committed</span>{formatData(fundCommitted, acceptCurrency, isWhitelisted)}</p>
                        <p><span>Actual Fund Needed</span>{formatData(actualFundNeeded, acceptCurrency, isWhitelisted)}</p>
                        <p><span>Remaining Fund</span>{formatData(remainingFund, acceptCurrency, isWhitelisted)}</p>
                        <p><span>Allocation</span>{formatData(tokenAllocation, symbol, isWhitelisted)}</p>
                        {(Number(totalRefunded) + Number(totalRefundedEspecial)) > 0 ?
                            (<p><span>Total Refunded</span>{formatData(Number(totalRefunded) + Number(totalRefundedEspecial), acceptCurrency, isWhitelisted)}</p>)
                            : (<p><span>Claimed Token</span>{formatData(Number(totalClaimed) + Number(totalDailyClaimed), symbol, isWhitelisted)}</p>)}
                    </div>
                </TabPane>
                <TabPane tab="Your Allocation" key="2">
                    <div className="c-campaignRound__table">
                        <table>
                            <tbody>
                                {size.width < sizeTablet ? <>{allocationRender()}</> : <>
                                    <tr>
                                        <th>Start Claim</th>
                                        <th>Allocation</th>
                                        <th>Percentage</th>
                                        <th>Action</th>
                                    </tr>
                                    {allocationRender()}
                                </>}
                            </tbody>
                        </table>
                    </div>
                </TabPane>
            </Tabs>
        </>
    );
};

export default YourInvetmentAllocation;