import React, { useState, useRef, useEffect } from "react";
import { Input, Radio, message, Modal, InputNumber } from "antd";
import QRCode from "react-qr-code";
import web3 from "web3";
import { status_difficulty } from "../../constants/index";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { notiError, notiSuccess, notiWarning } from "../../utils/notication";
import "./style.css";
import Loading from "../Loading";
import { useActiveWeb3React } from "../../hooks";


const DetailGeneratorPremium = () => {
    const { account , chainId } = useActiveWeb3React();
    const Web3 = new web3("https://bsc-dataseed1.binance.org");
    const [showDetails, setShowDetails] = useState(false);
    const [loading, setLoading] = useState(false);
    const [valueCase, setValueCase] = useState(1);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [wallets, setWallets] = useState(null);
    const [countWallets, setCountWallets] = useState(0);
    const [vanity, setVanity] = useState("");
    const [valueMode, setValueMode] = useState(2);
    const [status, setStatus] = useState(false);
    const [prefixVanity, setPrefixVanity] = useState("");
    const [suffixVanity, setSuffixVanity] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const isMounted = useRef(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalData, setModalData] = useState({});

    const [isModalOpenOff, setIsModalOpenOff] = useState(false);

    const showModalOff = () => {
        setIsModalOpenOff(true);
    };

    const handleOkOff = () => {
        setIsModalOpenOff(false);
    };

    const handleCancelOff = () => {
        setIsModalOpenOff(false);
    };

    useEffect(() => {
        if (isOnline) {
            showModalOff();
        }
    }, []);

    const showModal = (data) => {
        setModalData(data);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const checkNetworkStatus = () => {
        setIsOnline(navigator.onLine);
    };

    useEffect(() => {
        window.addEventListener("online", checkNetworkStatus);
        window.addEventListener("offline", checkNetworkStatus);

        return () => {
            window.removeEventListener("online", checkNetworkStatus);
            window.removeEventListener("offline", checkNetworkStatus);
        };
    }, []);

    const onChangeCase = (e) => {
        setValueCase(e.target.value);
    };
    const characterCount = vanity?.length;

    const onChangeMode = (e) => {
        setValueMode(e.target.value);
        setVanity("");
    };

    const renderInputs = () => {
        if (valueMode === 3) {
            return (
                <div className="two-input">
                    <Input
                        placeholder="Prefix"
                        value={prefixVanity}
                        onChange={handleInputPrefix}
                        className="form-control w-100 set-input-number"
                        style={{ width: "100%" }}
                    />
                    <span className="space-center">/</span>
                    <Input
                        placeholder="Suffix"
                        value={suffixVanity}
                        onChange={handleInputSuffix}
                        className="form-control w-100 set-input-number"
                        style={{ width: "100%" }}
                    />
                </div>
            );
        }
        return (
            <Input
                placeholder="Ex: 8888"
                value={vanity}
                onChange={handleInputChange}
                className="form-control w-100 set-input-number"
                style={{ width: "100%" }}
            />
        );
    };
    const handleInputPrefix = (e) => {
        setPrefixVanity(e.target.value);
    };
    const handleInputSuffix = (e) => {
        setSuffixVanity(e.target.value);
    };

    const renderText = () => {
        if (valueMode === 1) {
            return (
                <div className="text-eg">
                    Eg: 0x<span className="text-blue">{vanity}</span>
                    3D6C5Bb35051B666c659d29D97eA20b848
                </div>
            );
        }
        if (valueMode === 2) {
            return (
                <div className="text-eg">
                    Eg: 0xD9173D6C5Bb35051B666c659d29D97eA20b848
                    <span className="text-blue">{vanity}</span>
                </div>
            );
        }
        if (valueMode === 3) {
            return (
                <div className="text-eg">
                    Eg: 0x<span className="text-blue">{prefixVanity}</span>
                    D9173D6C5Bb35051B666c659d29D97eA20b848
                    <span className="text-blue">{suffixVanity}</span>
                </div>
            );
        }
    };

    const handleInputChange = (e) => {
        setVanity(e.target.value);
    };

    const createWallet = async () => {
        try {
            const packageID = localStorage.getItem("packageID");
            if (packageID && packageID !== "1") {
                // if (!isOnline) {
                setStatus(true);
                const targetGen = 5;
                let count = 0;
                let wallet;
                let countTarget = 0;
                let arrWallets = [];
                const checkAddress = () => {
                    wallet = Web3.eth.accounts.create();
                    const address = wallet.address.toLowerCase();
                    if (vanity && valueMode === 2) {
                        if (address.endsWith(vanity)) {
                            if (countTarget < targetGen) {
                                arrWallets.push({
                                    address: wallet.address,
                                    privateKey: wallet.privateKey,
                                    tries: count,
                                });
                                setWallets([...arrWallets]);
                                countTarget++;
                                setTimeout(checkAddress, 0);
                            } else {
                                notiSuccess("New list wallet generated successfully!", 5);
                                setStatus(false);
                            }
                        } else {
                            count++;
                            setCountWallets(count);
                            if (isMounted.current) {
                                setTimeout(checkAddress, 0); // Continue checking in next tick
                            }
                        }
                    }

                    if (vanity && valueMode === 1) {
                        if (address.slice(2).startsWith(vanity)) {
                            if (countTarget < targetGen) {
                                arrWallets.push({
                                    address: wallet.address,
                                    privateKey: wallet.privateKey,
                                    tries: count,
                                });
                                setWallets([...arrWallets]);
                                countTarget++;
                                setTimeout(checkAddress, 0);
                            } else {
                                notiSuccess("New list wallet generated successfully!", 5);

                                setStatus(false);
                            }
                        } else {
                            count++;
                            setCountWallets(count);
                            if (isMounted.current) {
                                setTimeout(checkAddress, 0); // Continue checking in next tick
                            }
                        }
                    }
                    if (prefixVanity && suffixVanity && valueMode === 3) {
                        if (
                            address.slice(2).startsWith(prefixVanity) &&
                            address.endsWith(suffixVanity)
                        ) {
                            if (countTarget < targetGen) {
                                arrWallets.push({
                                    address: wallet.address,
                                    privateKey: wallet.privateKey,
                                    tries: count,
                                });
                                setWallets([...arrWallets]);
                                countTarget++;
                                setTimeout(checkAddress, 0);
                            } else {
                                notiSuccess("New list wallet generated successfully!", 5);

                                setStatus(false);
                            }
                        } else {
                            count++;
                            setCountWallets(count);
                            if (isMounted.current) {
                                setTimeout(checkAddress, 0); // Continue checking in next tick
                            }
                        }
                    }
                };

                checkAddress();
                // } else {
                //   notiWarning("Please disconnect from the internet", 10);
                // }
            } else {
                notiWarning("Please upgrade the package!", 10);
            }
        } catch (error) {
            console.error("Error creating wallet:", error);
            notiError(error.message, 5);
            setStatus(false);
        }
    };

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    function WalletModal({ item }) {
        return (
            <Modal
                title={"Wallet Generator"}
                visible={isModalVisible}
                onOk={handleOk}
                footer={false}
                onCancel={handleCancel}
                className="modal-connect full-modal"
            >
                <div className="section-success-wallet">
                    <div className="box-qrcan">
                        <div className="left">
                            <QRCode value={item?.address} size={150} />
                        </div>
                        <div className="right">
                            <div className="item">
                                <div className="flex-l">
                                    <div className="txt-l">
                                        <img src="../../images/bot/blue.png" alt="" /> Address:
                                    </div>
                                    <div className="txt-tight">{item.address}</div>
                                </div>
                                <div className="flex-r">
                                    <button className="btn-copy custon-gen-wallet">
                                        <CopyToClipboard
                                            text={`${item.address}`}
                                            onCopy={() => {
                                                message.success("Copied", 0.4);
                                            }}
                                        >
                                            <img src="../../images/bot/copy.png" alt="" />
                                        </CopyToClipboard>
                                    </button>
                                </div>
                            </div>
                            <div className="item">
                                <div className="flex-l">
                                    <div className="txt-l">
                                        <img src="../../images/bot/yellow.png" alt="" /> Private
                                        Key:
                                    </div>
                                    <div className="txt-tight">{item.privateKey}</div>
                                </div>
                                <div className="flex-r">
                                    <button className="btn-copy custon-gen-wallet">
                                        <CopyToClipboard
                                            text={`${item?.privateKey}`}
                                            onCopy={() => {
                                                message.success("Copied", 0.4);
                                            }}
                                        >
                                            <img src="../../images/bot/copy.png" alt="" />
                                        </CopyToClipboard>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
    const IconWarning = () => {
        return (
            <div className="gen-warning">
                <img src="../../../images/bot/IconW.png" alt="" />
                Warning
            </div>
        );
    };

    return (
        <>
            <div className="main-section">
                <div className="container">
                    <div className="section-detail-generator">
                        <div className="title">ETH Vanity Address Generator</div>
                        <div className="item-form">
                            <div className="txt">Enter your vanity number</div>
                            <div className="input-round">{renderInputs()}</div>
                            {renderText()}
                        </div>
                        <div className="item-form mar-top-20">
                            <div className="txt">Case Sensitive</div>
                            <Radio.Group onChange={onChangeCase} value={valueCase}>
                                <Radio value={1}>Yes</Radio>
                                <Radio value={2}>No</Radio>
                            </Radio.Group>
                        </div>
                        <div className="item-form mar-top-20">
                            <div className="txt">Mode</div>
                            <Radio.Group onChange={onChangeMode} value={valueMode}>
                                <Radio value={1}>Prefix</Radio>
                                <Radio value={2}>Suffix</Radio>
                                <Radio value={3}>Both</Radio>
                            </Radio.Group>
                        </div>
                        <div className="item-form mar-top-20">
                            <div className="txt">Token Details</div>
                            <div className="box-detail-balance res">
                                <div className="item">
                                    <div className="row-name">
                                        <div className="name">Difficulty:</div>
                                        <div className="text">
                                            {status_difficulty(characterCount)}
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row-name">
                                        <div className="name">Generate:</div>
                                        <div className="text">{countWallets} Address</div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row-name">
                                        <div className="name">Probability:</div>
                                        <div className="text">
                                            {" "}
                                            {characterCount < 6 && valueMode !== 3 ? "100%" : "50%"}
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="row-name">
                                        <div className="name">Status:</div>
                                        <div className="text res">
                                            {status ? "Waiting..." : "Success"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="group-btn mar-top-30">
                            <button
                                type="button"
                                onClick={createWallet}
                                className="btn-add click-now w-200"
                                disabled={
                                    status ||
                                    (!vanity && valueMode !== 3) ||
                                    (valueMode === 3 && (!prefixVanity || !suffixVanity))
                                }
                            >
                                <Loading status={status} content="Start Wallet Generator" />
                            </button>
                        </div>
                    </div>
                    {wallets && (
                        <>
                            {" "}
                            <div className="section-success-wallet">
                                <div className="box-img">
                                    <img src="../../images/bot/checkes-sc.png" alt="" />
                                </div>
                                <div className="title">List Wallet Generator</div>
                                <div className="desc">
                                    Please ensure the following details are correct
                                </div>
                            </div>
                            <div className="round-history">
                                <div className="round-history-content">
                                    <div className="title">List wallet</div>
                                    <div className="main-table">
                                        <table className="table table-history">
                                            <tr>
                                                <th>STT</th>
                                                <th>Wallet address</th>
                                                <th>PrivateKey</th>
                                                <th>{""}</th>
                                            </tr>
                                            {wallets && (
                                                <>
                                                    {wallets?.map((item, index) => (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>
                                                                <span className="round">
                                                                    {item.address}{" "}
                                                                    <CopyToClipboard
                                                                        text={`${item.address}`}
                                                                        onCopy={() => {
                                                                            message.success("Copied", 0.4);
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src="../../images/bot/copied.png"
                                                                            alt=""
                                                                        />
                                                                    </CopyToClipboard>
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="round">
                                                                    {showPassword ? (
                                                                        <span>{item.privateKey}</span>
                                                                    ) : (
                                                                        <span>
                                                                            {"*".repeat(item.privateKey.length)}
                                                                        </span>
                                                                    )}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <span className="round-action">
                                                                    <button
                                                                        className="btn-pick"
                                                                        onClick={() => showModal(item)}
                                                                    >
                                                                        Pick
                                                                    </button>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <WalletModal item={modalData} />

            <Modal
                style={{ top: 150 }}
                title={IconWarning()}
                className="modal-offline"
                open={isModalOpenOff}
                onOk={handleOkOff}
                onCancel={handleCancelOff}
                footer={false}
            >
                <p>You need to disconnect your Internet usage for security reasons.</p>
            </Modal>
        </>
    );
};
export default DetailGeneratorPremium;
