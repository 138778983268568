const networkChangeByNumber = async (chainId) => { 

    
    try {
      
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: `0x${parseInt(chainId).toString(16)}` }],
        });
       
        setTimeout(() => {
            window.location.reload();
        }, 500);
       
        
    } catch (error) {
        if (error.code === 4902) {
            try {
                console.log('networkChange chainId error', chainId);
                if (chainId === 137) {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: 'Polygon Mainnet',
                                // chainId: '0x89',
                                chainId: `0x${Number(137).toString(16)}`,
                                rpcUrls: [
                                    "https://polygon-rpc.com",
                                    "https://rpc-mainnet.matic.network",
                                    "https://rpc-mainnet.maticvigil.com",
                                    "https://rpc-mainnet.matic.quiknode.pro",
                                    "https://matic-mainnet.chainstacklabs.com",
                                    "https://matic-mainnet-full-rpc.bwarelabs.com",
                                    "https://matic-mainnet-archive-rpc.bwarelabs.com",
                                    "https://poly-rpc.gateway.pokt.network/",
                                    "https://rpc.ankr.com/polygon",
                                    "https://rpc-mainnet.maticvigil.com/"
                                ],
                                blockExplorerUrls: ["https://polygonscan.com/"],
                                nativeCurrency: {
                                    name: 'MATIC',
                                    symbol: 'MATIC',
                                    decimals: 18
                                }
                            }
                        ]
                    });
                }
                if (chainId === 56) {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "Binance Smart Chain",
                                chainId: `0x${Number(56).toString(16)}`,
                                nativeCurrency: {
                                    name: "Binance Chain Native Token",
                                    symbol: "BNB",
                                    decimals: 18
                                },
                                rpcUrls: [
                                    "https://bsc-dataseed1.binance.org",
                                    "https://bsc-dataseed2.binance.org",
                                    "https://bsc-dataseed3.binance.org",
                                    "https://bsc-dataseed4.binance.org",
                                    "https://bsc-dataseed1.defibit.io",
                                    "https://bsc-dataseed2.defibit.io",
                                    "https://bsc-dataseed3.defibit.io",
                                    "https://bsc-dataseed4.defibit.io",
                                    "https://bsc-dataseed1.ninicoin.io",
                                    "https://bsc-dataseed2.ninicoin.io",
                                    "https://bsc-dataseed3.ninicoin.io",
                                    "https://bsc-dataseed4.ninicoin.io",
                                    "wss://bsc-ws-node.nariox.org"
                                ],
                                blockExplorerUrls: ["https://bscscan.com"]
                            }
                        ]
                    });
                }
                
                if (chainId === 666888) {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "HeLa Official Runtime Testnet",
                                chainId: '0xa2d08',
                                rpcUrls: ["https://testnet-rpc.helachain.com"],
                                nativeCurrency: {
                                    name: "HeLa Native Token",
                                    symbol: "HLUSD",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                } 
                if (chainId === 8668) {
                    await window.ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainName: "HeLa Official Runtime",
                                chainId: '0x21dc',
                                rpcUrls: ["https://mainnet-rpc.helachain.com"],
                                nativeCurrency: {
                                    name: "HeLa Native Token",
                                    symbol: "HLUSD",
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                } 
                
            } catch (err) {
                console.log(err);
            }
            }
        console.error(error);
    }
};

export default networkChangeByNumber;