import { LOCAL_STORAGE, ROUND_TYPE } from "../constants";
import WhitelistDataService from "../services/WhitelistService";
import {
  UPDATE_COMMIT_FUND,
  UPDATE_CLAIM_REMAINING_FUND,
  UPDATE_REFUND_IDO,
  UPDATE_REFUND_ESPECIAL,
  GET_SIGNATURE_COMMIT,
  GET_SIGNATURE_REMAINING_FUND,
  GET_SIGNATURE_REFUND,
  GET_WHITELIST_BY_ADDRESS,
  GET_SIGNATURE_ESPECIAL_REFUND
} from "./whitelistType";

const updateCommitFund = (payload) => async (dispatch) => {
    try {
      const res = await WhitelistDataService.updateCommitFund(payload);
      dispatch({
        type: UPDATE_COMMIT_FUND,
        payload: {}
      });
    } catch (err) {
      console.log(err);
    }
}

const updateClaimRemainingFund = (payload) => async (dispatch) => {
  try {
    const res = await WhitelistDataService.updateClaimRemainingFund(payload);

    dispatch({
      type: UPDATE_CLAIM_REMAINING_FUND,
      payload: {}
    });
  } catch (err) {
    console.log(err);
  }
}

const updateRefundIdo = (payload) => async (dispatch) => {
  try {
    const res = await WhitelistDataService.updateRefundIdo(payload);

    dispatch({
      type: UPDATE_REFUND_IDO,
      payload: {}
    });
  } catch (err) {
    console.log(err);
  }
}
const updateRefundEspecial = (payload) => async (dispatch) => {
  try {
    const res = await WhitelistDataService.updateRefundEspecial(payload);
    if(res && res.status===200) {
      dispatch({
        type: UPDATE_REFUND_ESPECIAL,
        payload: {}
      });
    }
  } catch (err) {
    console.log(err);
  }
}
const getSignatureCommit = (payload, callback) => async (dispatch) => {
  await WhitelistDataService.getSignatureCommit(payload).then((res) => {
    if(res && res.status===200) {
      let data = res.data && res.data.data;
      dispatch({type: GET_SIGNATURE_COMMIT, payload: data });
      callback({
        signatureCommit: data, 
        whitelistId: res.data && res.data.whitelistId
      });
    }
  })
  .catch(({ error }) => {
    console.log(error);
  });
}

const getSignatureRemainingFund = (payload, callback) => async (dispatch) => {
  await WhitelistDataService.getSignatureRemainingFund(payload)
  .then((res) => {
    if(res && res.status===200) {
      let data = res.data && res.data.data;
      dispatch({type: GET_SIGNATURE_REMAINING_FUND,payload: data});
      callback({signatureRemainingFund: data});
    }
  })
  .catch(({ error }) => {
    console.log(error);
  });
}

const getSignatureRefund = (payload, callback) => async (dispatch) => {

  await WhitelistDataService.getSignatureRefund(payload)
  .then((res) => {
    if(res && res.status===200) {
      let data = res.data && res.data.data;
      dispatch({type: GET_SIGNATURE_REFUND,payload: data});
      callback({signatureRefund: data});
    }
  })
  .catch(({ error }) => {
    console.log(error);
  });
}

const getSignatureEspecialRefund = (payload, callback) => async (dispatch) => {

  await WhitelistDataService.getSignatureEspecialRefund(payload)
  .then((res) => {
    if(res && res.status===200) {
      let data = res.data && res.data.data;
      dispatch({type: GET_SIGNATURE_ESPECIAL_REFUND, payload: data});
      callback({signatureEspecialRefund: data});
    }
  })
  .catch((error) => {
    dispatch({type: GET_SIGNATURE_ESPECIAL_REFUND, payload: error});
    callback({signatureEspecialRefund: error?.response?.data });
  });
}

const getWhitelistByAddress  = (roundId, roundType, account) => async (dispatch) => {
  
  await WhitelistDataService.getWhitelistByAddress(roundId, account)
  .then((res) => { 
    if(res && res.status===200) { 
      if(res.data)
      { dispatch({type: GET_WHITELIST_BY_ADDRESS, payload: res.data})}  
    }
  })
  .catch(({ error }) => {
    console.log(error);
  });
}


const getSignatureRefundIdoModel1 = (payload, callback) => async (dispatch) => { 
  await WhitelistDataService.getSignatureRefundIdoModel1(payload)
  .then((res) => {
    callback(res.data) 
  })
  .catch(({ error }) => {
    console.log(error);
  });
}

const updateRefundIdoModel1 = (payload) => async (dispatch) => {
  try {
    const res = await WhitelistDataService.updateRefundEspecial(payload);
    if(res && res.status===200) {
      dispatch({
        type: UPDATE_REFUND_ESPECIAL,
        payload: {}
      });
    }
  } catch (err) {
    console.log(err);
  }
}

export default {
  updateCommitFund,
  updateClaimRemainingFund,
  updateRefundIdo,
  updateRefundEspecial,
  getSignatureCommit,
  getSignatureRemainingFund,
  getSignatureRefund,
  getSignatureEspecialRefund,
  getWhitelistByAddress,
  getSignatureRefundIdoModel1,
  updateRefundIdoModel1
}