/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState, useLayoutEffect } from "react";
import { Progress, Steps, Checkbox, Radio, Modal, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useContract } from "../../hooks/useContract";
import { ADDRESS_HLUP_ADDRESS } from "../../constants";
import abiBSCS from "./utils/abiBSCS.json";
import web3 from "web3";
import { useActiveWeb3React } from "../../hooks";
// import Action from "./action";
import abiStake from "./utils/abiStake.json"
import { SC_STAKE_FIXED } from "../../constants";
import { chainList } from "../../config/network/chainlist";
import { Store } from "react-notifications-component";
import addNotify from "../common/Notify/addNotify";

const provider = "https://testnet-rpc.helachain.com";

const UnStake = (props) => {

    const { account, chainId } = useActiveWeb3React();
    const bscsContract = useContract(ADDRESS_HLUP_ADDRESS, abiBSCS);
    const contractStake = useContract(SC_STAKE_FIXED, abiStake);
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const [bnbBalance, setBnbBalance] = useState(0);
    const [current, setCurrent] = useState(0);
    const [amountBscs, setAmountBscs] = useState(0);
    const [amountBscsPool, setAmountBscsPool] = useState(0);
    const [hashUnStake, setHashUnStake] = useState('');
    const [isCheck, setIsCheck] = useState(false);
    const { Step } = Steps;
    const [showNext, setShowNext] = useState(false);
    const [amountValue, setAmountValue] = useState();

    const [unstakeAmount, setUnstakeAmount] = useState(0);
    const [isSuccess, setIsSuccess] = useState(false)
    let chainInfo = chainList[chainId];

    const checkFlag = async () => {
        try {
            await contractStake.userMap(account).then((res) => {
                if (res) {
                    setUnstakeAmount(res.inUnStakeAmount / 1e18)
                    console.log('res', res.inUnStakeAmount / 1e18)
                } else {
                    setUnstakeAmount(0)
                }
            })
                .catch((err) => {
                    console.log('err', err)
                })
        } catch (error) {
            console.log('error', error)
        }
    }

    useLayoutEffect(() => {
        if (contractStake) {
            checkFlag()
        }

    }, [account, contractStake])

    console.log('unstakeAmount', unstakeAmount)

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const onChange = (e) => {
        setIsCheck(e.target.checked)
    };

    const onChangeValue = (event) => {
        let x = event.target.value.replace(/[^0-9.]/g, "");
        setAmountValue(x)
    };

    const steps = [
        {
            title: '1',
            content: 'First-content',
        },
        {
            title: '2',
            content: '2-content',
        },
        {
            title: '3',
            content: '3-content',
        },
        {
            title: '4',
            content: '4-content',
        },
        {
            title: '5',
            content: 'Last-content',
        },
    ];

    const renderImg1 = () => {
        return (
            <img className="img-step-stake" src="./images/stake-ac-1.png" alt="" />
        )
    }
    const renderImg2 = () => {
        if (current < 1) {
            return <img className="img-step-stake" src="./images/stake-ac.png" alt="" />
        } else {
            return <img className="img-step-stake" src="./images/stake-ac-1.png" alt="" />
        }
    }
    const renderImg3 = () => {
        if (current < 2) {
            return <img className="img-step-stake" src="./images/stake-ac.png" alt="" />
        } else {
            return <img className="img-step-stake" src="./images/stake-ac-1.png" alt="" />
        }
    }
    const renderImg4 = () => {
        if (current < 3) {
            return <img className="img-step-stake" src="./images/stake-ac.png" alt="" />
        } else {
            return <img className="img-step-stake" src="./images/stake-ac-1.png" alt="" />
        }
    }
    const renderImg5 = () => {
        if (current < 4) {
            return <img className="img-step-stake" src="./images/stake-ac.png" alt="" />
        } else {
            return <img className="img-step-stake" src="./images/stake-ac-1.png" alt="" />
        }
    }

    const renderText1 = () => {
        return <span className="text-ac">Warning</span>
    }
    const renderText2 = () => {
        if (current < 1) {
            return "Checkpoints"
        } else {
            return <span className="text-ac">Checkpoints</span>
        }

    }

    const renderText3 = () => {
        if (current < 2) {
            return "Amount to Unstake"
        } else {
            return <span className="text-ac">Amount to Unstake</span>
        }

    }
    const renderText4 = () => {
        if (current < 3) {
            return "Initialize Unstake"
        } else {
            return <span className="text-ac">Initialize Unstake</span>
        }

    }
    const renderText5 = () => {
        if (current < 4) {
            return "Confirmation"
        } else {
            return <span className="text-ac">Confirmation</span>
        }

    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };

    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };

    const getBscs = async () => {
        if (bscsContract) {
            const amount = await bscsContract.balanceOf(account);
            setAmountBscs(amount.toString() / 1e18);
        }
    };

    useLayoutEffect(() => {
        if (account) {
            getBscs();
        }
    }, [account]);

    const getBscsPool = async () => {
        if (contractStake) {
            const amount = await contractStake.balanceOf(account);
            setAmountBscsPool(amount.toString() / 1e18);
        }
    };

    useLayoutEffect(() => {
        if (account) {
            getBscsPool();
        }
    }, [account]);

    useLayoutEffect(() => {
        if (account) {
            w3.eth.getBalance(account, (err, balance) => {
                if (balance) {
                    let _balance = web3.utils.fromWei(
                        web3.utils.toBN(balance),
                        "ether"
                    );
                    let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
                    setBnbBalance(bnbBl);
                }
            });
        }
    }, [account, chainId]);

    useLayoutEffect(() => {
        if (account && amountBscs > 0 && bnbBalance > 0 && isCheck && unstakeAmount <= 0 && amountBscsPool) {
            if (current === 2) {
                if (amountValue <= amountBscsPool) {
                    setShowNext(true)
                } else {
                    setShowNext(false)
                }
            } else {
                setShowNext(true)
            }

        } else {
            setShowNext(false)
        }

    }, [account, amountBscs, bnbBalance, isCheck, unstakeAmount, amountValue, current])
   
    const handleMax = () => {
        setAmountValue(amountBscsPool)
    }

    const handleUnstake = async () => {
        try {
            setIsModalOpen(true);
            let amount = web3.utils.toWei(amountValue.toString(), "ether");
            console.log('amount', amount)
            await contractStake.unstake(amount)
                .then((res) => {
                    res.wait()
                        .then((resWait) => {
                            if (resWait?.status === 1) {
                                console.log('resWait', resWait)
                                setHashUnStake(resWait.transactionHash)
                                
                                Store.addNotification(addNotify("UnStake successfully", 'success'));
                                props.setIsCheckAction(!props.isCheckAction)
                                setIsSuccess(!isSuccess)
                                setIsModalOpen(false);
                                setCurrent(current + 1);
                                window.location.reload()
                            } else {
                                Store.addNotification(addNotify("Unknown error (status hash fail)", 'danger'));
                                setIsModalOpen(false);
                            }

                        })
                        .catch((err) => {
                            Store.addNotification(addNotify("Unknown error", 'danger'));
                            setIsModalOpen(false);
                        })
                })
                .catch((err) => {
                    console.log('err', err)
                    Store.addNotification(addNotify(err.reason || err?.message, 'danger'));
                    setIsModalOpen(false);
                })
        } catch (error) {
            console.log('err catch-->', error)
            Store.addNotification(addNotify(error, 'danger'));
            setIsModalOpen(false);
        }
    }

    return (
        <div className="wrap-stake">
            <div className="stake">
                <div className="wrap-all">

                    <div className="stake-your-token">Unstake your $HLUP</div>
                    <div className="content-stake">

                        {/* @ts-ignore this lib is incompatible with react18  */}
                        <Steps current={current}>
                            <Step
                                title={renderText1()}
                                icon={renderImg1()}
                            />
                            <Step
                                title={renderText2()}
                                icon={renderImg2()}
                            />
                            <Step
                                title={renderText3()}
                                icon={renderImg3()}
                            />
                            <Step
                                title={renderText4()}
                                icon={renderImg4()}
                            />
                            <Step
                                title={renderText5()}
                                icon={renderImg5()}
                            />
                        </Steps>


                        {/* <div className="steps-content">{steps[current].content}</div> */}
                        {current === 0 ? (
                            <div className="content-step1">
                                <div className="unstake-warning">
                                    <div className="img-warning">
                                        <img src="./images/war.png" alt="" />
                                    </div>
                                    <div className="content-warning1">
                                        After Unstaking, you must wait 7 days before you can withdraw your $HLUP and rewards.
                                    </div>
                                    <div className="content-warning2">
                                        The amount of tokens you Unstake will not count towards your tier level for upcoming.
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                {current === 1 ? (
                                    <div className="content-step2">
                                        <div className="title">Checkpoints</div>
                                        <div className="des">The following conditions must be met to proceed:</div>
                                        <div className="item-step1">
                                            <div className="card-item">
                                                <div className={`${account ? 'check-card acive' : 'check-card'}`}>
                                                <img src="./images/check-ac.png" alt="" />
                                            <img src="./images/check-no-ac.png" alt="" />
                                                </div>
                                                <div className="title-card">Connected with MetaMask</div>
                                                <div className="content-card">If not connected, click the "Connect Wallet" button in the top right corner.</div>
                                            </div>
                                            <div className="card-item">
                                                <div className={`${amountBscsPool > 0 ? 'check-card acive' : 'check-card'}`}>
                                                    {/* <div className="check-card acive"> */}
                                                    <img src="./images/check-ac.png" alt="" />
                                            <img src="./images/check-no-ac.png" alt="" />

                                                </div>
                                                <div className="title-card">Have an active $HLUP stake</div>
                                                <div className="content-card">You currently have {new Intl.NumberFormat("ja-JP").format(amountBscsPool)} $HLUP staked</div>
                                            </div>
                                            <div className="card-item">
                                                <div className={`${bnbBalance > 0 ? 'check-card acive' : 'check-card'}`}>
                                                <img src="./images/check-ac.png" alt="" />
                                            <img src="./images/check-no-ac.png" alt="" />
                                                </div>
                                                <div className="title-card">HLUSD available in wallet</div>
                                                <div className="content-card">HLUSD is required to pay transaction fees on the {chainInfo?.NAME} network.<br />HLUSD Balance: {bnbBalance}</div>
                                            </div>
                                            <div className="card-item">
                                                <div className={`${unstakeAmount > 0 ? 'check-card' : 'check-card acive'}`}>
                                                <img src="./images/check-ac.png" alt="" />
                                            <img src="./images/check-no-ac.png" alt="" />
                                                </div>
                                                <div className="title-card">Eligible to initiate unstake</div>
                                                <div className="content-card">You cannot unstake if you already have an active $HLUP unstake/withdrawal request</div>
                                            </div>
                                        </div>
                                        <div className="check-real">
                                            <Checkbox onChange={onChange} /> <span>I have read the <a href="#" className="terms">Terms and Conditions</a></span>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        {current === 2 ? (
                                            <div className="content-step3">
                                                <div className="title title-unstake">Please enter the amount of HLUP you want to unstake</div>

                                                <div className="content-input unstake">
                                                    <div className="amount-stake-title">
                                                        <div className="title-left">Amount</div>
                                                        {/* <div className="title-right"><span>50,000</span> UpSwap available</div> */}
                                                    </div>
                                                    <div className="input-amount">
                                                        <input type="text" className="amount-stake" placeholder="0.0" onChange={onChangeValue} value={amountValue} min="0" max={amountBscsPool} />
                                                        {/* <input className="amount-stake" placeholder="0.0" onChange={onChangeValue} value={amountValue} type="text" /> */}
                                                        <div className="posi-unstake">
                                                            <span className="palce-token">$HLUP</span>
                                                            <button className="btn-max-stake" onClick={handleMax}>Max</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                {current === 3 ? (
                                                    <div className="content-step4">
                                                        <div className="title">Confirm Unstaking Process</div>
                                                        <div className="confirm-content">
                                                            <div className="cf-content1">In this step, you initiate the unstaking process.</div>
                                                            <div className="cf-content1">After a 7 day waiting period, you will be allowed to withdraw your $HLUP.</div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {current === 4 ? (
                                                            <div className="content-step5">
                                                                <div className="title">Confirmation</div>
                                                                <div className="des">You have initiated the unstaking process and started the 7 day timer.</div>
                                                                <div className="success-content">
                                                                    <div className="img-success">
                                                                        <img src="./images/sus.png" alt="" />
                                                                    </div>
                                                                    <div className="mid-content">Confirmed</div>
                                                                    <div className="mid-content1">You have initiated the unstaking process and started the 7 day timer.</div>
                                                                    <div className="mid-content2">If desired, you may check {chainInfo?.NAME} to confirm the transaction.</div>
                                                                    <a className="mid-content3" target="_blank" rel="noreferrer" href={`https://testnet-blockexplorer.helachain.com/tx/${hashUnStake}`}>{hashUnStake}</a>
                                                                </div>
                                                            </div>
                                                        ) : ('')}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>

                </div>
                {/* <Action isSuccess={isSuccess} tabBox={props.tabBox} /> */}

            </div>
            <div className="steps-action">
                {current > 0 && current !== steps.length - 1 && (
                    <button className="btn-basic-default w-fitcontent" onClick={() => prev()}> Previous</button>
                )}
                {current === 0 ?
                    <>
                        <button className="btn-basic w-200" onClick={() => next()}> Next</button>
                    </>
                    :
                    <>
                        {current < steps.length - 1 && (
                            <>
                                {showNext ? (
                                    <>
                                        {current === 3 ?
                                            <>
                                                <button className="btn-basic w-200" onClick={handleUnstake}> Next</button>
                                            </>
                                            :
                                            <>
                                                <button className="btn-basic w-200" onClick={() => next()}> Next</button>
                                            </>
                                        }
                                    </>
                                ) : (
                                    <button className="btn-basic w-200" disabled> Next</button>
                                )}
                            </>
                        )}
                    </>
                }
                {current === steps.length - 1 && (
                    <button className="btn-basic w-200" onClick={() => window.location.reload()}> Done</button>

                )}
            </div>

            {/* @ts-ignore this lib is incompatible with react18  */}
            <Modal
                title="Unstaking"
                visible={isModalOpen}
                // onOk={handleOk}
                // onCancel={handleCancel}
                footer={false}
                wrapClassName="modal-confirm"
            >
                <p className="modal-text1">Your request is being processed. Please wait...</p>
                <p className="spin-stake">
                    <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 55 }} spin />} />
                </p>

            </Modal>
        </div>


    )
}

export default UnStake;
