
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Modal, Form, Input, Select, Spin, InputNumber, Checkbox, Alert, Space, Radio, Tabs } from 'antd';
import { useActiveWeb3React } from '../../hooks';
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons';

import web3 from 'web3';
import { ethers } from 'ethers';
import { getInformationByChain } from '../../config/network/multichainAddresses';
import {
    _isMinted,
    _claim
} from './utils';
import {
    useContract, useMarketGenesesNFTContract, useMarketNFTContract, useNftContract
} from "../../hooks/useContract";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';

import { setApproveFixed, _cancelListing, _createListing } from '../mint-nft/utilsNFT';
import { GeneisNftApi } from '../../config/api/apiGeneisNFT';
import NoneData from '../element/NoneData';
import { GenesesNftApi } from '../../config/api/genesNFTApi';
import { Loader } from '../common/component';
import { NFTBYTYPE } from '../../constants';

const ProfileMarketPlace = (props) => {
    const { zealyNftAddress, nftAddress, marketAddress } = props;
    const { account, library, chainId } = useActiveWeb3React();
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

    const [showSellModal, setShowSellModal] = useState(false);
    const [detailModal, setDetailModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isListed, setIsListed] = useState(false);
    const [listingPrice, setListingPrice] = useState(0);
    const [data, setData] = useState(null);
    const [nfts, setNfts] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [totalRow, setTotalRow] = useState(0);
    const [nftApprove, setNftApprove] = useState("");
    const [detailItem, setDetailItem] = useState(null);

    const MARKET_ADDRESS = marketAddress;
    const NFT_ADDRESS = nftAddress;
    const ADDRESS_HLUSD = '0x0000000000000000000000000000000000000000';

    const marketContract = useMarketGenesesNFTContract(MARKET_ADDRESS);
    const contractNft = useNftContract(NFT_ADDRESS);

    const contractZealyNft = useNftContract(zealyNftAddress);

    const [param, setParam] = useState(
        {
            page: 0,
            size: 20,
            sort: 'id,desc',
            ownerAddress: ''
        }
    );

    const getMyGeneisNft = async ()=>{
        setLoadingData(true);

        let defaultParam = {
            ...param,
            ownerAddress: account
        }
        try {
            let res = await GeneisNftApi.getMyGeneisNft(defaultParam);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                setNfts(res.data);
            } else {
                setNfts([]);
            }
            setLoadingData(false);
        } catch (error) {
            console.log('Error: ', error);
        }
    }

    useEffect(() => {
        if(account){
            getMyGeneisNft();
        }
    }, [param, account]);

    const handleShowSell = (item)=>{
        setData(item);
        checkAllowance(item);
        form.resetFields();
        setShowSellModal(true);
    }

    const handleShowDetail = (item)=>{
        setDetailItem(item);
        setDetailModal(true);
    }
    
    const onConfirmListForSale = async (value)=>{
        setLoading(true);

        let priceSale = value?.price_sale;
        setListingPrice(priceSale);

        if (Number(priceSale) <= 0) {
            Store.addNotification(addNotify('Invalid sale price.', 'danger'));
            return;
        }
        if (!marketContract) {
            Store.addNotification(addNotify('Market contract invalid.', 'danger'));
            return;
        }
        if (Number(data?.chainId) !== chainId) {
            Store.addNotification(addNotify('Invalid sale price.', 'danger'));
            return;
        }

        let nftSelAddress = NFT_ADDRESS;

        if(data?.nftTypeEnum == "HELA_ZEALY_NFT"){
            nftSelAddress = zealyNftAddress;
        }

        await _createListing(marketContract ,account, nftSelAddress, Number(data?.nftId), priceSale, ADDRESS_HLUSD)
        .then((res) => {
            let resWait = res.wait();
            resWait.then(async (resTransaction) => {

                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    
                    let payload = {
                        "id": data.id,
                        "marketHash": res.hash,
                        "ownerAddress": account,
                        "price": Number(priceSale)
                    };
                    let resAp = await GenesesNftApi.updateListingNft(data.id, payload);
                    if (resAp.status === 200) {
                        setShowSellModal(false);
                        Store.addNotification(addNotify('Listing successfully', 'success'));
                        setLoading(false);
                        
                        getMyGeneisNft();

                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                        
                    }else{
                        Store.addNotification(addNotify('Server Error', 'danger'));
                        setLoading(false);
                    }
                }
                else {
                    setLoading(false);
                    Store.addNotification(addNotify('Transaction failed', 'warning'));
                    return;
                }

            })
            .catch((error) => {
                setLoading(false);
                if (error.data) {
                    Store.addNotification(addNotify("Transaction failed", 'danger'));
                }
                if (error) {
                    Store.addNotification(addNotify(error?.reason, 'danger'));
                }
            });
        })
        .catch((error) => {
            setLoading(false);
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        });
    }

    const checkAllowance = async (item) => {
      try {
        
        if(item?.nftTypeEnum == "HELA_ZEALY_NFT"){
            await contractZealyNft.getApproved(item?.nftId).then((res) => {
            setNftApprove(res);
            });
        }else{
            await contractNft.getApproved(item?.nftId).then((res) => {
            setNftApprove(res);
            });
        }
        
      } catch (error) {
        console.log("checkAllowance error", error);
      }
    };

    const handleApproveToken = async () => {
      try {
        if (account) {
            setLoading(true);
            let addressApprove = MARKET_ADDRESS;

            if(!data){
                Store.addNotification(addNotify('NFT not found.', "danger"));
                setLoading(false);
                return;
            }

            let scApprove = contractNft;

            if(data?.nftTypeEnum == "HELA_ZEALY_NFT"){
                scApprove = contractZealyNft;
            }
            
            await setApproveFixed(scApprove, addressApprove, data?.nftId)
                .then((res) => {
                    let resWait = res.wait();
                    resWait.then(async (resTransaction) => {
                        if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                            Store.addNotification(addNotify("Approve successfully", "success"));
                            checkAllowance(data);
                            setLoading(false);
                        }
                    });
                })
                .catch((error) => {
                    console.error("Error Approve Token", error);
                    setLoading(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                        Store.addNotification(addNotify(error.message, "danger"));
                        } else if (error.reason) {
                        Store.addNotification(addNotify(error.reason, "danger"));
                        } else {
                        if (error.data && error.data.message) {
                            Store.addNotification(
                            addNotify(error.data.message, "danger")
                            );
                        }
                        }
                    }
                });
            }
        } catch (error) {
            setLoading(false);
            console.error("Error Handle Approve", error);
        }
    };

    const handleCancelListing = async (data)=>{
        setLoading(true);
        
        if (!marketContract) {
            Store.addNotification(addNotify('Market contract invalid.', 'danger'));
            return;
        }
        if (Number(data?.chainId) !== chainId) {
            Store.addNotification(addNotify('Chain invalid.', 'danger'));
            return;
        }

        if (data?.ownerAddress !== account.toLowerCase()) {
            Store.addNotification(addNotify('Owner address invalid.', 'danger'));
            return;
        }

        let nftSelAddress = NFT_ADDRESS;

        if(data?.nftTypeEnum == "HELA_ZEALY_NFT"){
            nftSelAddress = zealyNftAddress;
        }

        await _cancelListing(marketContract, nftSelAddress, Number(data?.nftId))
        .then((res) => {
            let resWait = res.wait();
            resWait.then(async (resTransaction) => {

                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    let payload = {
                        "id": data.id,
                        "marketHash": res.hash,
                        "ownerAddress": account,
                        "price": 0,
                    };
                    let resAp = await GenesesNftApi.unListingNft(data.id, payload);
                    if (resAp.status === 200) {
                        Store.addNotification(addNotify('UnList successfully', 'success'));
                        setLoading(false);
                        getMyGeneisNft();
                        // setTimeout(() => {
                        //     window.location.reload();
                        // }, 1500);
                        
                    }else{
                        Store.addNotification(addNotify('Server Error', 'danger'));
                        setLoading(false);
                    }
                    
                }
                else {
                    setLoading(false);
                    Store.addNotification(addNotify('Transaction failed', 'warning'));
                    return;
                }

            })
            .catch((error) => {
                setLoading(false);
                if (error.data) {
                    Store.addNotification(addNotify("Transaction failed", 'danger'));
                }
                if (error) {
                    Store.addNotification(addNotify(error, 'danger'));
                }
            });
        })
        .catch((error) => {
            setLoading(false);
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        });
    }

    return (
        <>
            <Row gutter={30}>
                {!loadingData ? (
                    <>
                        {nfts?.length > 0 ? (
                            nfts.map((item, index) => {

                                let nameByType = NFTBYTYPE[item?.nftTypeEnum];

                                return (
                                    <>
                                        <Col xs={24} sm={6}>
                                            <div className='mint-nft-item'>
                                                <div className='nft-img' onClick={()=>handleShowDetail(item)}>
                                                    <div className='nft-id'>#{item?.nftId}</div>
                                                    <img src={`${item?.nftImage ? item?.nftImage : '../images/hela-nft.png'}`} />
                                                </div>
                                                <div className='nft-info' onClick={()=>handleShowDetail(item)}>
                                                    <div className='flex-btw'>
                                                        <p className='mt-0 mb-0 text-regular-blue'>{nameByType}</p>
                                                        <span>#{item?.nftId}</span>
                                                        
                                                    </div>
                                                </div>
                                                <div className='text-center mt-5'>
                                                    {item?.marketEnum == 'LISTING' ? (
                                                        <>
                                                            <button className='btn-black w-fitcontent' onClick={()=>handleCancelListing(item)}>Cancel</button>
                                                        </>
                                                    ):(
                                                        <>
                                                            <button className='btn-basic w-fitcontent ptb-5' onClick={()=>handleShowSell(item)}>Sell</button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </>
                                )
                            })
                        ) : (
                            <>
                                <Col className="text-center" xs={24}>
                                    <div className="no-data text-center">
                                        <img width={200} src="../images/box-nodata.svg" />
                                        <p className="text-black-green text-weight-500">No data.</p>
                                    </div>
                                </Col>
                            </>
                        )}
                    </>
                ) : (<>
                    <Col xs={24}>
                        <div className="loading-data text-center">
                            <Space size="middle">
                                <Spin size="large" />
                            </Space>
                        </div>
                    </Col>
                    
                </>)}
               
                
            </Row>

            <Modal className="modal-mint modal-list-done" open={showSellModal} onOk={()=> setShowSellModal(false)} onCancel={()=> setShowSellModal(false)}>
                <div className="modal-content-mint">
                    <div className="title">
                        Sell {data?.name}
                    </div>
           
                    <Form form={form} layout="vertical" autoComplete="off" className="basic-form"
                        onFinish={onConfirmListForSale}
                    >
                        <Form.Item
                            label={<><span className='text-weight-600'>Set price</span></>}
                            name="price_sale"
                            rules={[
                                { required: true, message: 'Please input sale price!' },
                                {
                                    validator: (rule, value, cb) => {
                                        !value || parseFloat(value) <= 0
                                            ? cb("Sale price must be > 0")
                                            : cb();
                                    }
                                }
                            ]}
                        >
                            <Input disabled={nftApprove.toLocaleLowerCase() != MARKET_ADDRESS.toLocaleLowerCase()} placeholder="Ex: 5000" suffix={<><img width={23} className="img-border mr-5" src='../images/helalogo.svg' alt=''/> HLUSD</>} />
                        </Form.Item>
                        
                        {/* {!isListed && ( */}
                            <Form.Item className="btn-content">
                                
                                <>
                                    {nftApprove.toLocaleLowerCase() === MARKET_ADDRESS.toLocaleLowerCase() ? (
                                        <>
                                            <button className="btn-basic p-lr-20 w-200" disabled={loading || !account} type="submit">Set Price {loading && <Spin size="small" />}</button>
                                        </>
                                    ):(
                                        <>
                                            <button type="button" disabled={loading || !account} className="btn-basic p-lr-20 w-200" onClick={handleApproveToken}>
                                                {loading ? 'Approving' : 'Approve'} {loading && <Spin size="small" />}
                                            </button>
                                        </>
                                    )}
                                </>
                                
                                
                            </Form.Item>
                        {/* )} */}
                        

                    </Form>
                    
                </div>
            </Modal>
            <Modal className="modal-mint modal-list-done" open={detailModal} onOk={()=> setDetailModal(false)} onCancel={()=> setDetailModal(false)}>
                <div className="modal-content-mint">
                    {/* <div className="title text-darkblue">
                        {detailItem?.nftGenesis?.name}
                    </div> */}
                    <div className="mt-15 mb-15 modal-nft">
                        <Row gutter={32}>
                            <Col className="gutter-row" xs={24}>
                                <div className="modal-nft-img text-center">
                                    <img style={{maxWidth: '500px'}} src={detailItem?.nftImage} />
                                </div>
                            </Col>
                            
                        </Row>
                    </div>
                </div>
            </Modal>
            {loading && (
                <>
                    <Loader />
                </>
            )}
        </>
    )
}

export default ProfileMarketPlace
