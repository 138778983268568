
import React, { useState, useEffect, useCallback } from 'react';

import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Form, Select, Input, Space, Spin, Breadcrumb } from 'antd';
import { stakingApi } from '../../../config/api/stakingApi';
import StakingItem from './staking-item';
import NoneData from '../../element/NoneData';
import FooterHome from '../../common/footer/footerHome';
import NodataImg from '../../common/asset/images/nodata-upcoming.png';
import { useActiveWeb3React } from '../../../hooks';

const { Content } = Layout;
const { Option } = Select;
const StakeMainToken = () => {
    const { chainId, account, library } = useActiveWeb3React();

    const [totalRow, setTotalRow] = useState(0);
    const [classTab, setClassTabs] = useState('');
    const navigate = useNavigate();
    const typeProject = window.location.search.replace('?', '');
    const [form] = Form.useForm();
    const [stakingList, setStakingList] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const displayTabs = (action) => {
        navigate({
            pathname: '',
            search: action
        });
    };
    const [param, setParam] = useState(
        {
            page: 0,
            size: 9,
            sort: 'id,desc',
            typeEnum: 'STAKE',
            keyword: '',
            chainId: '',
            hlup: true,
        }
    );

    const getStakingList = async (status) => {
        setLoadingData(true);
        const dataFilter = {
            ...param,
            active: status,
            chainId: chainId ? chainId : '',
        };
        try {
            let res = await stakingApi.getStakingList(dataFilter);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setLoadingData(false);
                    let dataNew = res.data.filter((item)=> item?.mainToken === true);
                    setStakingList(dataNew);
                } else {
                    setLoadingData(false);
                    setStakingList([]);
                }
            }
        } catch (error) { console.log('Error: ', error); }
        finally {
            setLoadingData(false);
        }
    }
    useEffect(() => {
        if (classTab) {
            switch (classTab) {
                case 'active':
                    getStakingList(true);
                    break;
                case 'inactive':
                    getStakingList(false);
                    break;
                default:
                    break;
            }
        }
    }, [classTab, chainId]);


    useEffect(() => {
        if (typeProject) {
            setClassTabs(typeProject);
        } else {
            setClassTabs('active');
        }
    }, [typeProject]);

    const StakeItemContent = (props) => {
        return (
            <>

                {!loadingData ? (
                    <>
                        {stakingList?.length > 0 ? (
                            stakingList.map((item, index) => {
                                return (
                                    <StakingItem item={item} />
                                )
                            })
                        ) : (
                            <>
                                <Row gutter={20}>
                                    <Col xs={24} sm={12}>
                                        <h3 className='text-25 text-darkblue text-weight-600 mt-15'>Launch Your Project on UpSwap Now!  </h3>
                                        <p className='text-16 line-24'>Farms stimulate users to provide liquidity for your trading pair by distributing HLUP tokens to your pair’s LP token holders. Launchpool is a platform where a project owner can distribute tokens to HLUP users who stake HLUP tokens in the pool. When a project applies for UpSwap Launchpool we can also create a new farming pair (subject to discussion).</p>
                                        <p className='text-16 line-24'>UpSwap Launchpool and Farms are platforms that help project teams promote their token and get exposure to thousands of active UpSwap users across the globe. We look for strong teams with clear and innovative vision in the crypto space. If you think you are one of the projects, do not wait any longer and apply below.</p>
                                        <button className='btn-basic w-fitcontent' onClick={()=> window.open('https://docs.google.com/forms/d/1dQTFp8qzxEtmgW11gYyDNj22EoVSWK2juiJpVnq83lg/edit')}>Apply to Launch</button>
                                    </Col>
                                    <Col xs={24} sm={12}>
                                        <div className="c-nodata c-nodata--upcoming">
                                            <img src={NodataImg} alt="No Data" />
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </>
                ) : (<>
                    <div className="text-center loading-full">
                        <Space size="middle">
                            <Spin size="large" />
                        </Space>
                    </div>
                </>)}


            </>
        );
    };

    const returnContentProjects = () => {
        switch (classTab) {
            case 'active':
                return <StakeItemContent type="active" />;
                break;
            case 'inactive':
                return <StakeItemContent type="inactive" />;
                break;
            default:
                break;
        }
    };
    return (
        <>

            <div className='page-stake-token'>
                
                <div className="container">
                    <Breadcrumb className='head-breadcrumb-airdrop'>
                        <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Stake HLUP</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className='p-stake-title '>Stake HLUP</div>
                    <div className="project-block">
                        <div className="projects-top">
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" xs={24} xl={14} md={14} sm={14}>
                                    <div className='filter-staking-status'>
                                        <div className="projects-btn-status">
                                            <button type="button" className={classTab == 'active' ? 'active' : ''} onClick={() => displayTabs('active')}>
                                                Active
                                            </button>
                                            <button type="button" className={classTab == 'inactive' ? 'active' : ''} onClick={() => displayTabs('inactive')}>
                                                InActive
                                            </button>

                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} xl={10} md={10} sm={10}>
                                    <div className="project-filter">
                                        <Form form={form} name="horizontal_login" className='basic-form'>
                                            {/* <Form.Item
                                                name="token_type"
                                                rules={[{ required: true, message: 'Please input type!' }]}
                                                initialValue="ALL"
                                            >
                                                <Select className="select-chain" >
                                                    <Option value="ALL">ALL Chain</Option>
                                                    <Option value="BSC">Binance Smart Chain</Option>
                                                    <Option value="ETHEREUM">Ethereum</Option>
                                                    <Option value="FANTOM">Fantom Opera</Option>
                                                    <Option value="SOLANA">Solana</Option>
                                                    <Option value="CELO">Celo</Option>
                                                    <Option value="TRON">Tron</Option>
                                                    <Option value="AVAX">Avax</Option>
                                                    <Option value="POLYGON">Polygon</Option>
                                                </Select>
                                            </Form.Item> */}
                                            <Form.Item
                                                className='mb-0'
                                                name="search"
                                                rules={[{ required: true, message: 'Please input account address!' }]}
                                            >
                                                <Input className="input-search" placeholder="Search by keyword" />
                                            </Form.Item>

                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="project-content">

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                {returnContentProjects()}

                            </Row>
                        </div>
                    </div>
                </div>
                
            </div>

            
            <FooterHome />
        </>
    )
}

export default StakeMainToken
