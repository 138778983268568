import web3 from "web3";
import BigNumber from 'bignumber.js'

export const convertTokenToWei = (value, decimal) => {
  let amount = web3.utils.toWei(value.toString(), 'ether')
  if (decimal !== 18)
    amount = new BigNumber(value).times(new BigNumber(10).pow(decimal)).toString()
  return amount
}

// get total staked by account

export const _totalStakedUser = async (stakingSC, account) => {
  return await stakingSC.userInfo(account)
}
// get total reward by token stake

export const _totalReward = async (poolContract, tokenEarnAddress, account) => {
  return await poolContract.pendingRewardByToken(account, tokenEarnAddress)
}
export const _aprRewardPerBlock = async (poolContract, tokenEarnAddress) => {
  return await poolContract.rewardPerBlock(tokenEarnAddress)
}

// get total staked all
export const _totalStaked = async (poolContract) => {
  return await poolContract.totalSupply()
}
// stake token
export const _stakeToken = async (stakingSC, amount) => {
  let amountWei = web3.utils.toWei(`${amount}`, 'ether');
  return await stakingSC.deposit(amountWei);
}
export const _harvetReward = async (stakingSC, isHarvet) => {
  return await stakingSC.withdraw(isHarvet);
}
export const _unstaking = async (stakingSC, isHarvet) => {
  return await stakingSC.withdraw(isHarvet);
}


export const getProgressTime = (startTime) => {
  const now = new Date();
  const utcDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), 0);
  const startDate = new Date(startTime);
  const startTS = Date.UTC(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
    startDate.getHours(),
    startDate.getMinutes(),
    startDate.getSeconds(),
    0
  );
  let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;
  const hours = Math.floor(delta / 3600);
  delta -= hours * 3600;
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;
  const seconds = Math.floor(delta % 60);
  if (days >= 1) {
    return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
  } else {
    return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
  }
};

export const rendererCountDown = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}) => {
  // Render a countdown
  if (completed) {
    window.location.reload();
    return;
  }
  return (
    <div className="process-tc">
      <div className="timer-count">
        {days} <span>:</span><small>day</small>
      </div>
      <div className="timer-count">
        {hours} <span>:</span><small>hours</small>
      </div>
      <div className="timer-count">
        {minutes} <span>:</span><small>mins</small>
      </div>
      <div className="timer-count">
        {seconds} <small>sec</small>
      </div>
    </div>
  );
};
