import { instance } from '../../apiBase/instance';

export const stakingApi = {
	getSettings() {
		return instance.get(`settings`, {});
	},
	getChainNetworkList(params) {
		return instance.get(`chain-lists/client`, { params });
	},
	// private api

	getStakingList(data) {
		let params = {
			page: data.page,
			size: data.size,
			sort: data.sort,

		}
		return instance.post(`stake-infos/filter`, data, { params });
	},
	// getPrivateSaleId(id) {
	// 	return instance.get(`my-private-sale/${id}/client`);
	// },

	// updateStatusWhitelist(roundId, data) {
	// 	return instance.put(`private-sale/${roundId}/update-type`, data, {});
	// },
	// addWhiteList(data) { // add wl user
	// 	return instance.post(`white-lists-private-sale/add`, data, {});
	// },
	// joinPool(data) { // add wl user
	// 	return instance.post(`token-sales/join-pool`, data, {});
	// },
	// removeWhiteList(data) {
	// 	return instance.delete(`white-lists-private-sale/remove`, { data });
	// },
	// updateStatusIdoPool(roundId, data) {
	// 	return instance.put(`private-sale/${roundId}/update-status`, data, {});
	// },
	// getWhitelistByRound(roundId, params) {
	// 	return instance.get(`/private-sale/${roundId}/white-lists`, { params });
	// },

	// checkWLByAcc(address, id) {
	// 	return instance.get(`/white-list-private-sale/${address}/verify/${id}`);
	// },
};
