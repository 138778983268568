
import React, { useState, useEffect } from 'react';

import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Layout, Row, Col, Card, Button, Select, Pagination, Table, Upload, Space, Spin } from 'antd';
import { LeftCircleOutlined, UploadOutlined, InboxOutlined, CopyOutlined } from '@ant-design/icons';

import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import './style.css';

import CopyToClipboard from 'react-copy-to-clipboard';
import FooterHome from '../common/footer/footerHome';
import { NftApi } from '../../config/api/apiNFT';
const { Dragger } = Upload;

const UploadImage = () => {


    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState();
    let tokenID = localStorage.getItem('tokenId');

    const props = {
        multiple: false,
        beforeUpload: file => {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', file);
            
            NftApi.uploadImage(formData, tokenID).then((res)=>{
                
                if(res.status === 200 && res.data){
                    setImage(res.data);
                    Store.addNotification(addNotify('Upload Successfully!', 'success'));
                    setLoading(false);
                }
            }).catch((error)=>{
                console.log('error', error);
                Store.addNotification(addNotify('Upload Failed!', 'danger'));
                setLoading(false);
            })
        }
    };
    const onCopy = () => {
        Store.addNotification(addNotify('Copied.', 'success'));
    };

    return (
        <>
            <div className="page-content">
                
                <div className="project-block-create">
                    <div className='container'>
                        
                        <div className="create-form">
                            <div className="create-title upload-title">Upload Image</div>
                            <div className="upload-block">
                                <>
                                    <Dragger {...props}
                                        accept=".png,.jpg,.jpeg,.svg,.gif,.webp"
                                        showUploadList={false}
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">
                                            Support for a single upload. Strictly prohibit from uploading company data or other band files
                                        </p>
                                        <p className="ant-upload-hint">Max size: 1 Mb</p>
                                        <Button className='mt-15' style={{ marginRight: '10px' }} type="dashed" danger htmlType='button' icon={<UploadOutlined />}>Upload Image</Button>
                                    </Dragger>
                                    {loading ? (
                                        <>
                                            <div className="loading-center">
                                                <Space size="middle"><Spin size="large" /></Space>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {image && (
                                                <Card title="Image Info" className='mt-15' style={{ width: '100%' }}>
                                                    <div className='image-upload-review'>
                                                        <p>Image link: </p>
                                                        <p>
                                                            <a target="_blank" href={`${image}`}>{`${image}`}</a>
                                                            <CopyToClipboard onCopy={onCopy} text={`${image}`}>
                                                                <CopyOutlined className='upload-icon-copy' />
                                                            </CopyToClipboard>
                                                        </p>
                                                        <p>Image preview: </p>
                                                        <p><img src={image} /></p>
                                                    </div>
                                                </Card>
                                            )}
                                        </>
                                    )}


                                </>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <FooterHome />
            </div>
        </>
    )
}

export default UploadImage
