
import React, { useState, useEffect, useCallback } from 'react';

import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Form, Select, Input, Space, Spin, Breadcrumb, Modal } from 'antd';
import { stakingApi } from '../../../config/api/stakingApi';
import NoneData from '../../element/NoneData';
import FooterHome from '../../common/footer/footerHome';
import Web3 from "web3";
import { useActiveWeb3React } from '../../../hooks';
import MarketItem from './market-item';
import { Store } from 'react-notifications-component';
import addNotify from '../../common/Notify/addNotify';
import { HelascriptionApi } from '../../../config/api/apiHelascription';
import { Loader } from '../../common/component';


const HelascriptionToken = () => {
    const { chainId, account, library } = useActiveWeb3React();
    const [totalRow, setTotalRow] = useState(0);
    const [classTab, setClassTabs] = useState('all');
    const navigate = useNavigate();
    const typeProject = window.location.search.replace('?', '');
    const [form] = Form.useForm();
    const [formSearch] = Form.useForm();
    const [tokens, setTokens] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingDeloy, setLoadingDeloy] = useState(false);
    const [showDeloyModal, setShowDeloyModal] = useState(false);
    const web3 = new Web3(window.ethereum);

    const displayTabs = (action) => {
        navigate({
            pathname: '',
            search: action
        });
    };
    const [param, setParam] = useState(
        {
            page: 1,
            size: 20
        }
    );

    const getTokenDeloy = async (status) => {
        setLoadingData(true);
     
        try {
            let res = await HelascriptionApi.getTokens(param);
            // setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                if (res.data.data.length > 0) {
                    setLoadingData(false);
                    if(typeProject === "completed"){
                        let data = res.data.data.filter((item)=> Number(item?.minted) === Number(item?.total_supply));
                        setTokens(data);
                    }else{
                        setTokens(res.data.data);
                    }
                    
                } else {
                    setLoadingData(false);
                    setTokens([]);
                }
            }
        } catch (error) { console.log('Error: ', error); }
        finally {
            setLoadingData(false);
        }
    }
    useEffect(() => {
        getTokenDeloy();
    }, [param, typeProject]);


    useEffect(() => {
        if (typeProject) {
            setClassTabs(typeProject);
        } else {
            setClassTabs('all');
        }
    }, [typeProject]);

    const MarketItemContent = (props) => {

        const { type } = props;

        return (
            <>

                {!loadingData ? (
                    <>
                        <div className='helascription-header'>
                            <Row gutter={20}>
                                <Col className="gutter-row" xs={12} xl={3} md={3} sm={3} key={`key-1`}>
                                    <span className='text-darkblue text-16 text-weight-500'>Token</span>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={6} md={6} sm={6} key={`key-2`}>
                                    <span className='text-darkblue text-16 text-weight-500'>Deploy Time</span>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={6} md={6} sm={6} key={`key-3`}>
                                    <span className='text-darkblue text-16 text-weight-500'>Progress</span>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={4} md={4} sm={4} key={`key-4`}>
                                    <span className='text-darkblue text-16 text-weight-500'>Holders</span>
                                </Col>
                                <Col className="gutter-row" xs={12} xl={5} md={5} sm={5} key={`key-5`}>
                                    <span className='text-darkblue text-16 text-weight-500'>Transactions</span>
                                </Col>
                            </Row>
                        </div>
                        {/* <div className='helascription-mk'>
                            <MarketItem item={null} />
                        </div> */}
                        {tokens?.length > 0 ? (
                            <div className='helascription-mk'>
                                {tokens.map((item, index) => {
                                    return (
                                        <MarketItem item={item} key={index}/>
                                    )
                                })}
                                
                            </div>
                        ) : (
                            <>
                                
                                <div className="c-nodata c-nodata--upcoming">
                                    <NoneData />
                                </div>
                            </>
                        )}
                        
                    </>
                ) : (<>
                    <div className="loading-data">
                        <Space size="middle">
                            <Spin size="large" />
                        </Space>
                    </div>
                </>)}


            </>
        );
    };

    const returnContentProjects = () => {
        switch (classTab) {
            case 'all':
                return <MarketItemContent type="all" />;
                break
            case 'in-progress':
                return <MarketItemContent type="in-progress" />;
                break
            case 'completed':
                return <MarketItemContent type="completed" />;
                break
            default:
                break;
        }
    };
    const handleShowDeloy = ()=>{
        setShowDeloyModal(true);
    }
    const cancelDeloy = ()=>{
        setShowDeloyModal(false);
        form.resetFields();
    }
    const onConfirmDeloy = async (value)=>{
        setLoadingDeloy(true);

        let data = {
            "p":value?.protocol.toString(),
            "op":"deploy",
            "tick":value?.tick.toString(),
            "max":value?.max.toString(),
            "limit":value?.limit.toString()
        }


        const dataToSend = web3.utils.asciiToHex(`data:,${JSON.stringify(data)}`); // Convert 'xin chào' string to hex

        await web3.eth.sendTransaction({
            from:account,
            to: account,
            value: '0',
            data: dataToSend
        }).then((res) => {
            if(res && res.transactionHash){
                // console.log('Transaction Hash:', res.transactionHash);
                Store.addNotification(addNotify('Deloy Successfully.', 'success'));
                setLoadingDeloy(false);
                setShowDeloyModal(false);
                getTokenDeloy();
            }
        }).catch((error) => {
            setLoadingDeloy(false);
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        });
        
        
    }
    return (
        <> 
            <div className='page-helascription-market'>
                
                <div className="container">
                    <Breadcrumb className='head-breadcrumb-airdrop'>
                        <Breadcrumb.Item><a href='/'>Helascription</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Tokens</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className='helascription-market-title text-center mb-0'>Check out hela-20 balance of the address</div>
                    <p className='text-center text-weight-500 text-black-green mt-5'>Recognize all operations including DEPLOY, MINT and TRANSFER.</p>
                    <div className='d-flex d-flex-btw mt-25'>
                        <span className='text-weight-600 text-black-green text-25'>The full list of tokens</span>
                        <button className='btn-black' onClick={handleShowDeloy}>Deloy</button>
                    </div>
                    <div className="project-block">
                        <div className="projects-top">
                            <Row>
                                <Col className="gutter-row" xs={24} xl={14} md={14} sm={14}>
                                    <div className='filter-staking-status'>
                                        <div className="projects-btn-status">
                                            <button type="button" className={classTab == 'all' ? 'active' : ''} onClick={() => displayTabs('all')}>
                                                All
                                            </button>
                                            <button type="button" className={classTab == 'in-progress' ? 'active' : ''} onClick={() => displayTabs('in-progress')}>
                                                In-Progress
                                            </button>
                                            <button type="button" className={classTab == 'completed' ? 'active' : ''} onClick={() => displayTabs('completed')}>
                                                Completed
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} xl={10} md={10} sm={10}>
                                    <div className="project-filter">
                                        <Form form={formSearch} name="horizontal_login" className='basic-form'>
                                            
                                            <Form.Item
                                                className='mb-0'
                                                name="search"
                                                rules={[{ required: true, message: 'Please input account address!' }]}
                                            >
                                                <Input className="input-search" placeholder="Search by keyword" />
                                            </Form.Item>

                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="project-content">
                            {returnContentProjects()}
                        </div>
                    </div>
                </div>
                
            </div>
            <Modal className="modal-mint modal-list-done" open={showDeloyModal} onOk={()=> setShowDeloyModal(false)} onCancel={()=> setShowDeloyModal(false)}>
                <Form form={form} layout="vertical" autoComplete="off" className="basic-form"
                    onFinish={onConfirmDeloy}
                >
                    <div className="modal-content-mint">
                        <div className="title text-green">
                            Hela Chain Deploy
                        </div>
                        <div className="modal-make-offer">
                            <Form.Item
                                label="Protocol"
                                initialValue={'hrc-20'}
                                name="protocol"
                                rules={[
                                    { required: true, message: 'Please input Protocol!' }
                                ]}
                            >
                                <Input readOnly type="text" placeholder="hrc-20"/>
                            </Form.Item>
                            <Form.Item
                                label="Tick"
                                name="tick"
                                rules={[
                                    { required: true, message: 'Please input Tick!' }
                                ]}
                            >
                                <Input type="text" placeholder="Identifier of the asc-20, like “aval”"/>
                            </Form.Item>
                            <Form.Item
                                label="Total Supply"
                                name="max"
                                rules={[
                                    { required: true, message: 'Please input Total Supply!' }
                                ]}
                            >
                                <Input type="number" placeholder="21000000"/>
                            </Form.Item>
                            <Form.Item
                                initialValue={1000}
                                label="Limit Per Mint"
                                name="limit"
                                rules={[
                                    { required: true, message: 'Please input Limit Per Mint!' }
                                ]}
                            >
                                <Input type="number" placeholder="1000"/>
                            </Form.Item>
                        </div>
                        
                        
                        
                        <div className="text-center mt-15">
                            <button type="button" className="btn-basic-default w-fitcontent" onClick={cancelDeloy}>Cancel</button>
                            <button type="submit" disabled={!account || loadingDeloy} className="btn-basic w-200">Deloy {loadingDeloy && <Spin size="small" />}</button>
                        </div>
                    </div>
                </Form>
                
            </Modal>
            {loadingDeloy && (<Loader />)}
            <FooterHome />
        </>
    )
}

export default HelascriptionToken
