import React,{useEffect, useState} from 'react'  
import Slider from "react-slick";
import { Link } from 'react-router-dom'; 
const GamehubHot = ({item, gamehubHotPrices}) => { 
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState(); 
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        infinite: true, 
    }; 
    
    return (
        <>  
            <div className="c-sliderBanner">  
                <Slider asNavFor={nav2} ref={c => setNav1(c)} {...settings}>
                        {item?.gameImages.map((e,i)=>(
                            <div key={i.toString()} className="c-sliderBanner__item">
                                <img src={`${e?.path}/${e?.name}`} alt="" /> 
                            </div> 
                        ))}  
                    </Slider>
                    <div className="c-sliderBanner__content">
                        <h3>
                            <span><img src="../images/gameHub-iconTitle.png" alt="" /></span>
                            {item?.title}
                        </h3>
                        {[gamehubHotPrices.find(({id})=>id===item?.tokenId)].map((e,i)=>(
                            <p key={i.toString()}>
                             <span><img src={e?.image} alt="" /></span>
                             ${e?.current_price}
                             <span className={`${e?.price_change_percentage_24h > 0 ? "up" :"down"}`}>{e?.price_change_percentage_24h}%</span>
                            </p> 
                        ))} 
                        <Link to={`/gamehub/${item?.id}`}>
                            <button>View detail</button>
                        </Link>
                    </div>
                </div>
                <div className="c-sliderNav">
                    <Slider
                        asNavFor={nav1}
                        ref={c => setNav2(c)}
                        slidesToShow={3}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        arrows={true}
                        infinite={true}
                    > 
                        {item?.gameImages.map((e,i)=>(
                            <div className="c-sliderNav02__item" key={i.toString()}>
                                <img src={`${e?.path}/${e?.name}`} alt="" />
                            </div>
                        ))} 
                </Slider> 
                </div>  
        </>  
    )
}
export default GamehubHot;