import React, { useEffect, useLayoutEffect, useState } from "react";  
import Countdown from "react-countdown";  
import Wallet from '../common/wallet/wallet'; 
import Slider from "react-slick";
import {Form, Button, Select} from "antd";
import useWindowSize from '../../hooks/useWindowSize';  
import IconClose from '../../components/common/asset/images/icon-close.svg';
import {useSelector, useDispatch} from 'react-redux';
import allActions from '../../actions';
import {useLocation} from "react-router-dom"; 
import NodataImg from '../common/asset/images/nodata.svg';
import PopupBoxes from './popupBoxes';  
import { useContract, useBoxMarketContract, useMyBoxContract } from '../../hooks/useContract'; 
import { _approveBEP20, _buyMysteryBox, _getSoldBox, _myBoxInfo} from "./utils";
import abiBUSD from '../../config/abi/abiBUSD.json';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';  
import { useActiveWeb3React } from "../../hooks";
import web3 from "web3";
import MyOrderItem from "./MyOrderItem";
import { formatData } from '../../utils/StringHelper'; 
import networkChange from '../../utils/networkChange';
import { MAPPING_CHAINID } from '../../constants';
import BoxType from '../inoDetail/boxType';
import { getNetworkObject } from '../../utils/campaignUltils';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Loader, LoadingContent } from '../common/component';
const IconAsk = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M21.17 8.83003V15.67C21.17 16.79 20.57 17.83 19.6 18.4L13.66 21.83C12.69 22.39 11.49 22.39 10.51 21.83L4.57001 18.4C3.60001 17.84 3 16.8 3 15.67V8.83003C3 7.71003 3.60001 6.66999 4.57001 6.09999L10.51 2.67C11.48 2.11 12.68 2.11 13.66 2.67L19.6 6.09999C20.57 6.66999 21.17 7.70003 21.17 8.83003Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.159 7.5C12.753 7.5 13.2705 7.5945 13.7115 7.7835C14.1525 7.9725 14.4945 8.2425 14.7375 8.5935C14.9895 8.9445 15.1155 9.363 15.1155 9.849C15.1155 10.371 14.9985 10.8075 14.7645 11.1585C14.5395 11.5005 14.2335 11.7615 13.8465 11.9415C13.4685 12.1215 13.0455 12.2385 12.5775 12.2925V13.737H11.457V11.712C12.114 11.613 12.6225 11.4915 12.9825 11.3475C13.3515 11.2035 13.608 11.0235 13.752 10.8075C13.905 10.5915 13.9815 10.326 13.9815 10.011C13.9815 9.696 13.9095 9.426 13.7655 9.201C13.6305 8.976 13.4235 8.805 13.1445 8.688C12.8655 8.571 12.51 8.5125 12.078 8.5125C11.484 8.5125 10.998 8.679 10.62 9.012C10.251 9.336 10.0305 9.7815 9.9585 10.3485L9 9.795C9.099 9.336 9.288 8.9355 9.567 8.5935C9.855 8.2425 10.215 7.9725 10.647 7.7835C11.088 7.5945 11.592 7.5 12.159 7.5ZM11.997 14.925C12.258 14.925 12.4695 15.006 12.6315 15.168C12.7935 15.33 12.8745 15.5415 12.8745 15.8025C12.8745 16.0635 12.7935 16.275 12.6315 16.437C12.4695 16.599 12.258 16.68 11.997 16.68C11.736 16.68 11.5245 16.599 11.3625 16.437C11.2005 16.275 11.1195 16.0635 11.1195 15.8025C11.1195 15.5415 11.2005 15.33 11.3625 15.168C11.5245 15.006 11.736 14.925 11.997 14.925Z" />
    </svg>
);
const sizeMobile = 768;
const sizeTablet = 1024; 

const InoDetail = () => {  
    const { account } = useActiveWeb3React(); 
    const dispatch = useDispatch();
    const [loadingPage, setLoadingPage] = useState(false);
    let location = useLocation();
    let myPathname = location.pathname; 
    const sep = /\//;
    let isPathname = myPathname.split(sep); 
    const [check, setCheck] = useState(false);
    const currentUser = useSelector(state => state.currentUser); 
    // const account = currentUser.user;  
    const inoData = useSelector(state => state.inoData?.inoDetail);

    const marketContract = useBoxMarketContract(inoData?.inoContract); 

    const contractBox = useMyBoxContract(inoData?.boxContract);

    // const paymentContract = useContract(inoData?.paymentContract, abiBUSD); 
    const paymentContract = useContract(inoData?.paymentContract, abiBUSD); 
    
    const [indexBox, setIndexBox] = useState();
    const [arrBox, setArrBox] = useState(); 
   
    const size = useWindowSize();   
    useEffect(() => {
        inoData && inoData?.inoBoxes?.sort(function(a,b){
            return a.id - b.id;
        });
        setIndexBox(inoData?.inoBoxes[0]?.id);
        setArrBox(inoData?.inoBoxes[0]);
    }, [inoData])
 
    
   
    useEffect(() => {
        if(inoData?.networkChain) {  
            networkChange(MAPPING_CHAINID[inoData?.networkChain.toLowerCase()]);
        }
    }, [inoData]);
    
    useEffect(() => {  
        let _account = localStorage.getItem("account");

        if(!_account) {
            _account = account;
        }
        setLoadingPage(true);
        if(_account){
            dispatch(allActions.inoAction.getInoDetail(isPathname[3], _account, (res)=>{
                if(res.callResult === true) {
                    setLoadingPage(false);
                }
            }))
        } 
        else{
            dispatch(allActions.inoAction.getInoDetail(isPathname[3], "", (res)=>{
                if(res.callResult === true) {
                    setLoadingPage(false);
                }
            }))
        } 
        setLoadingPage(false);
    }, [account]);  
  
    const renderer = ({days, hours, minutes, seconds, completed }) => { 
        if (completed) { 
        return <div></div>;
        } else {
        return (
            <div className='countdown-detail'>
                <div className="flex"> 
                    <span>{days}d</span> <span>:</span> 
                    <span>{hours}h</span> <span>:</span> 
                    <span>{minutes}m</span> <span>:</span> 
                    <span>{seconds}s</span>  
                </div>  
            </div>
            )};
        } 
    const handleSelected =(value)=>{ 
        setIndexBox(value.id);
        setArrBox(value); 
    }
    const networkChain = getNetworkObject(inoData?.networkChain);

    useEffect(() => {
        for (let index = 0; index < inoData?.inoSettings.length; index++) { 
            if(inoData?.inoSettings[index]?.totalStaking >0){ 
                setCheck(true)
            }
            else{ 
                setCheck(false)
            }
        }
    }, [inoData]); 
    
    return (
        <>  {loadingPage ?  <div class="c-loading"><img src="../../images/loading-3s.gif" alt="Loading" /> </div>: (
            <>
            {inoData?.inoBoxes.length === 0 ?
            <div className="c-nodata">
                <img src={NodataImg} alt="No Data" />
                <p>No Data</p>
            </div> : 
            <>
            {[inoData]?.map((e,i)=>(
                <div key={i.toString()} className="c-inodetail">
                <div className="c-inodetail__left">  
                {[e?.inoBoxes.find(({ id }) => id === arrBox?.id)]
                .map((item,ix)=>(
                    <SwitchImage {...item} key={ix.toString()}/>
                ))}   
                </div>  
                <div key={i.toString()} className="c-inodetail__right">
                <div className="flex jc-between">  
                <span className="c-inodetail__main_title">{e?.title}</span>  
                {isPathname[2] === "upcoming" && check ? <a target="_blank" href="https://stake.kingdomstarter.io/#/" className="c-btnWallet stake">Stake Now</a> :null}
               
                </div> 
                <div className="flex">
                {isPathname[2] === "upcoming" ? <>
                    <span className="sub_title">PRE-SALE STARTING IN:</span> 
                        <div className='count'>
                            <Countdown date={e?.sellingTimePhase2 === null?`${e?.startTime}`:`${e?.sellingTimePhase2}`} renderer={renderer}/>
                        </div>
                    </> : isPathname[2] === "opening" ?<>
                    <span className="sub_title">SALE END IN:</span> 
                        <div className='count'>
                            <Countdown date={e?.sellingTimePhase2 === null?`${e?.sellingTime}`:`${e?.endTime}`} renderer={renderer}/>
                        </div></>
                    : null} 
                </div>
                <div className="c-inodetail__staking">  
                {isPathname[2] === "opening" && check ?
                <div className="ino_detail_ask">
                    <span>Staking conditions</span>
                    <div className="c-tabs__ask"> 
                        {IconAsk()}
                        <p>
                        - Stake 30 days (min stake 100,000 KDG):
                        can buy up to 5 boxes
                        - Stake 45 days (min stake 6,700 KDG-BUSD LP): can buy up to 10 boxes
                        - Stake 60 days (min stake 100,000 KDG):
                        can buy unlimited
                        </p>
                    </div> 
                </div> :  <span>No stake required</span>} 
                </div>
                <div className="c-inodetail__info"> 
                    <div className="c-inodetail__price">
                    {[e?.inoBoxes.find(({ id }) => id === arrBox?.id)]
                        .map((item,ix)=>(
                        <span key={ix.toString}>{item?.price} {item?.currency}</span>
                        ))} 
                    </div>  
                    <div className="c-inodetail__network">
                        <span>Network: </span>  
                        <div key={i.toString()} className="c-inodetail__unit"> 
                            <img src={`../${networkChain?.logo}`} alt={networkChain?.name} />
                            <span>{networkChain?.name}</span>
                        </div>  
                    </div>
                </div>
                <span className="main-text-inoType">TYPE</span>
                <div className="c-inoType"> 
                    {e?.inoBoxes?.map((item,i)=>( 
                       <BoxType
                       item={item}
                       handleSelected={handleSelected}
                       indexBox={indexBox}
                       marketContract={marketContract}/>
                    ))} 
                </div> 
                {[e?.inoBoxes.find(({ id }) => id === arrBox?.id)]
                .map((item,ix)=>(
                    <ProgressItem item={item} key={ix.toString()} marketContract={marketContract} inoData={inoData}/> 
                ))}
                {!localStorage.getItem("account") ? <div className="customWallet"><Wallet /></div> 
                :isPathname[2] ==="opening" ? 
                size.width <= sizeTablet ?
                    <InputAmount isNav={true} 
                        contractBox={contractBox}
                        isPathname={isPathname[2]}  
                        inoData={inoData} 
                        paymentContract={paymentContract}
                        account={account}
                        arrBox={arrBox}
                        marketContract={marketContract} 
                />:
                    <InputAmount 
                        contractBox={contractBox}
                        inoData={inoData} 
                        paymentContract={paymentContract}
                        account={account}
                        arrBox={arrBox}
                        marketContract={marketContract} 
                 />
               : 
               null
                }
                </div>  
            </div> ))}
            </>} 
        <div className="c-inodetail__projectinfo">
            <span className="c-inodetail__main_title">Project’s Info</span>
            {size.width <= sizeMobile
            ?  <NavTabProjectInfo inoData={inoData} />   
            :  <TabProjectInfo inoData={inoData}/>
            } 
        </div>  
         </>
        )}
        </> 
    ) 
}  

const InputAmount = React.memo((props)=>{  
    const {paymentContract, arrBox, inoData, marketContract, isNav, contractBox} = props; 
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(process.env.REACT_APP_NETWORK_URL ?? '');
    const [balance, setBalance] = useState(0); 
    const [showBuynow, setShowBuynow] = useState(false);
    const [allowanceApproval, setAllowanceApproval] = useState(0);
    const {account} = useActiveWeb3React(); 
    const [isBuyLoading, setIsBuyLoading] = useState(false);
    const dispatch = useDispatch();
    let [amount, setAmount] = useState(0);
    const [boxBought, setBoxBought] = useState(0);
    let [max, setMax] = useState(0);  
    useEffect(() => {
        //sort
        inoData?.inoBoxes.sort((a, b) =>{
            var keyA = new Date(a.totalSold),
            keyB = new Date(b.totalSold); 
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
        })
    }, [inoData]) 
  
    const HandleChange = (e) => {  
        if(!isNaN(e)){
            setAmount(Number(max)); 
        }
        else{
            setAmount(Number(e.target.value)); 
        } 
    }   
    const onFinishFailed = ()=>{
    }  
    const HandleBuyNow = (value) =>{
        setShowBuynow(value);
    }
    useEffect(() => {
        if(account && paymentContract) {
            (async () =>{
                const allowance = await paymentContract.allowance(account, marketContract.address);
                const _ownerallowance = web3.utils.fromWei(allowance.toString(), 'ether');
                setAllowanceApproval(_ownerallowance);
            })(); 

            if(arrBox?.currency === "BNB") {
                w3.eth.getBalance(account, (err, balance) => {
                    if (balance) {
                     let _balance = web3.utils.fromWei(web3.utils.toBN(balance), 'ether');
                     let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
                     setBalance(bnbBl);
                    }
                });
            }
            else if(arrBox?.currency === "USDC") {
                (async () =>{
                    const balance = await paymentContract.balanceOf(account);
                    const _ownerBalance = web3.utils.fromWei(balance.toString(), 'picoether'); 
                    setBalance(_ownerBalance);
                })(); 
            }
            else {
                (async () =>{
                    const balance = await paymentContract.balanceOf(account);
                    const _ownerBalance = web3.utils.fromWei(balance.toString(), 'ether'); 
                    setBalance(_ownerBalance);
                })(); 
            }
        }
    }, [account, paymentContract, marketContract, arrBox]);  
    
    useEffect(() => { 
        if(account && inoData){ 
            if(marketContract && inoData ){  
                _myBoxInfo(contractBox, account, arrBox?.id).then((resData) => {  
                    setBoxBought(resData.toNumber()); 
                });  
            } 
        } 
    }, [account, inoData]);   
     
    useEffect(() => { 
        if(arrBox?.soldOut > 0){
            setMax(arrBox?.total - arrBox?.soldOut ) 
        }
        else{
            setMax(arrBox?.total - boxBought) 
        } 
    }, [boxBought, inoData, arrBox])
   
    const approveBuyBox = async (amountApproved) => {  
        await _approveBEP20(paymentContract, marketContract.address , amountApproved) 
            .then((res) => {  
                let _resWait = res.wait(); 
                _resWait.then((resTransaction) => {  
                    if (resTransaction.status && resTransaction.blockNumber) { 
                        Store.addNotification(addNotify("Approved successfully", 'success')); 
                        (async () => {
                            buyMysteryBox(amountApproved)
                        })();
                    }
                }); 
            })
            .catch((err) => {
                setIsBuyLoading(false);  
                if (err) { 
                    Store.addNotification(addNotify(err?.reason, 'danger'));  
                }  
        })   
       
    }
   
    const buyMysteryBox = async (amountApproved) => { 
        if (!arrBox?.sign) {
            Store.addNotification(addNotify('Signature invalid', 'warning'));
            setIsBuyLoading(false); 
            return;
        }
        if (!inoData?.id) {
            Store.addNotification(addNotify('Campaign invalid', 'warning'));
            setIsBuyLoading(false); 
            return;
        }
        if (!arrBox?.id) {
            Store.addNotification(addNotify('Box invalid', 'warning'));
            setIsBuyLoading(false); 
            return;
        }
        
        //check out of stock 
        if (parseInt() <= 0) {
            Store.addNotification(addNotify('Out Of Stock', 'warning'));
            return;
        }
        else{
            // console.log('inoData?.id', inoData?.id);
            // console.log('arrBox?.id', arrBox?.id);
            // console.log('arrBox?.sign', arrBox?.sign);
            // console.log('arrBox?.currency,', arrBox?.currency,);
            // console.log('inoData?.boxContract', inoData?.boxContract);
            // console.log('inoData?.inoContract', inoData?.inoContract);
            await _buyMysteryBox(
                marketContract, 
                inoData?.id, 
                arrBox?.id, 
                amount,
                arrBox?.currency,
                arrBox?.price,
                arrBox?.sign
                )
            .then((resBuy) => { 
                let _resWaitMysteryBox = resBuy.wait(); 
                _resWaitMysteryBox.then((resBuyTransaction) => {  
                    // call API
                    const payload = {
                        "boxId": arrBox?.id,
                        "hash": resBuy.hash, 
                        "inoLaunchpadId": inoData?.id ,
                        "ownerAddress": account,
                        "quantity": amount,
                        "totalPrice": amount* arrBox?.price
                    }
                    dispatch(allActions.inoAction.postBuyBox(payload, async(response)  =>{ 
                        if(response) {
                            setIsBuyLoading(false);
                            Store.addNotification(addNotify("Buy box successfully", 'success'));

                            setTimeout(() => {
                                window.location.reload();
                            }, 1500);
                        }
                    }));
                })
                .catch((err)=> {
                    setIsBuyLoading(false); 
                    if(err.data && err.data.message){
                        Store.addNotification(addNotify("Approvals Error", 'danger')); 
                    }
                    if (err) {   
                        Store.addNotification(addNotify(err?.reason, 'danger'));  
                    }  
                  
                });
            })
            .catch((error)=> {
                setIsBuyLoading(false); 
                if (error) {
                    if (error.code == 4001 && error.message) {
                        Store.addNotification(addNotify(error.message, 'danger'));
                    } else if (error.reason) {
                        Store.addNotification(addNotify(error.reason, 'danger'));
                    } else {
                        if (error.data && error.data.message) {
                            Store.addNotification(addNotify(error.data.message, 'danger'));
                        }
                    }
                }
            });  
       } 
    } 
    const _buyBoxProcessing = async () => {  
        let amountApproved = amount* arrBox?.price;  
        if( amount <= 0 || !(Number.isInteger(amount))){
            Store.addNotification(addNotify("Amount wrongly", 'danger'));
            return;
        }
        if(balance <amountApproved)  { 
            Store.addNotification(addNotify("Insufficient balance. Balance: " + formatData(balance,arrBox?.currency), 'danger'));
            return;
        }  

        if(!inoData?.id || !arrBox?.id) { 
            Store.addNotification(addNotify("Box is invalid", 'danger'));
            return;
        }  

        setIsBuyLoading(true);

        if(Number(allowanceApproval) > 0 && Number(allowanceApproval) >= Number(amountApproved)) { 
            // Da approved -> Chi can call Buy SC -> Call API 
            await buyMysteryBox(amountApproved);
        }
        else {
            // Chua approved
            // approved -> Chi can call Buy SC -> Call API 
            await approveBuyBox(amountApproved);
        }               
    }   
    const handleMax =()=>{ 
        setAmount(0)
        HandleChange(max)
    }

    const onCancel=()=>{
        setShowBuynow(false)
    }
    if(isNav){
        return(
            <>
            {showBuynow ? <div className="form-transfer">
            <div className="c-popup">
                <div className="c-popup__box">
                    <div className="c-popup__head">
                        Buy Boxes
                        <button className="c-popup__close" onClick={() =>onCancel()}><img src={IconClose} alt="Close" /></button>
                    </div>
                    <div className="c-popup__body"> 
                        <Form 
                        name="basic" 
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }} 
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        >
                        <span className="header-title">Enter amount box</span> 
                        <Form.Item   
                            name="amount" 
                            rules={[{ 
                                required: false, 
                                message: "Amount wrong format",
                                pattern: new RegExp(/^\d*(\.\d+)?$/)
                            }]} 
                            >
                            <div className="form-transfer__input">
                            <input type="number" value={amount === 0 ? '' : amount} className="input" placeholder="0" title='Amount' onChange={(e)=>HandleChange(e)} />
                            <div className="BoxMax">
                                <span className="box">Box</span>
                                <button onClick={()=> handleMax()} className="max">MAX</button>
                            </div>
                            </div>  
                        </Form.Item>
                        <span className="available">{max} boxes available</span>
                        <Form.Item
                            wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}> 
                        <div className="form-transfer__btn">
                            <Button loading={isBuyLoading} onClick={() => _buyBoxProcessing()}className="submit" title='Submit' type='blue'>Confirm</Button>
                            <Button onClick={() => onCancel()} className="cancel" title='Cancel' type='flat'>Cancel</Button>
                        </div> 
                        </Form.Item> 
                        </Form> 
                    </div>
                </div>            
            </div> 
            </div> : null} 
            {props.isPathname ==="upcoming" ? null: <div className="btn-buynowMobile">
            <button onClick={() => HandleBuyNow(true)}>Buy now</button>  
            </div> } 
        </>
        )
    }
    return(
        <>
        <Form 
            name="basic" 
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }} 
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
        <div className="ino_amount"> 
            <div className="flex">
                <div className="ino_amount__inputBox">
                    <input type="number" value={amount === 0 ? '' : amount} onChange={(e)=>HandleChange(e)} className="input" placeholder="0"/>
                    <div className="ino_amount__BoxMax">
                        <div className="box">Box</div>
                        <div onClick={()=> handleMax()} className="max">Max</div> 
                    </div>  
                </div>   
                { amount 
                ?<Button loading={isBuyLoading} onClick={() => _buyBoxProcessing()} className="buy-now active">Buy Now</Button>
                :<button disabled={true} className="buy-now">Buy Now</button>
                }
                
            </div>  
        </div>
        <span className="max-Box">Total max box: {inoData?.whitelist?.totalCanBuy} </span>
        <div className="ino_amount__estimated">  
            <span className="text">Estimated Amount: </span>
            <span className="price">{Number(balance).toLocaleString()} {arrBox?.currency}</span> 
        </div>
        </Form>
        </>
    )
});  

const SwitchImage = React.memo((props)=>{   
        if(props.length === 0) {
            return(
            <div className="c-nodata">
                <img src={NodataImg} alt="No Data" />
                <p>No Data</p>
            </div>
            )
        }   
        return(   
        <div className="slick_custom">  
            <Slider arrows={false} className="img-slick">  
                <div className="item-img">
                    <img src={props?.imageLink} alt='' />
                </div>  
            </Slider>  
        </div> 
    
    )
})

const TabProjectInfo = React.memo((props) => {
    const [check, setCheck] = useState("Project");
    const HandleTab = (value) =>{
        setCheck(value)
    }

    const inoData = props.inoData;
    const info = inoData?.inoInformation;
    const rule = inoData?.inoRules;
    const boxes = inoData?.inoBoxes;
    const timeLine = inoData?.inoTimelines;
    const boxContract = inoData?.boxContract;

    return(
        <div className="c-project">
        <div className="c-project-header">
            <div onClick={()=>HandleTab("Project")} className={check==="Project"? "itemheader active" :"itemheader"}>
                <span>Project’s Info</span> 
            </div>
            <div onClick={()=>HandleTab("Rules")}  className={check==="Rules"? "itemheader active" :"itemheader"}>
                <span>Rules</span>
            </div>
            <div onClick={()=>HandleTab("WhatInBox")} className={check==="WhatInBox"? "itemheader active" :"itemheader"}>
                <span>What’s in the box</span>
            </div>
            <div onClick={()=>HandleTab("TimeLine")} className={check==="TimeLine"? "itemheader active" :"itemheader"}>
                <span>Timeline</span>
            </div>
            <div onClick={()=>HandleTab("MyOrder")} className={check==="MyOrder"? "itemheader active" :"itemheader"}>
                <span>My orders</span>
            </div> 
         </div>
         {check === "Project" && <DetailTabProjectInfo info={info}/>}
         {check === "Rules" && <DetailTabRule rule={rule}/>}
         {check === "WhatInBox" && <DetailTabWhatInBox boxes={boxes}/>}
         {check === "TimeLine" && <DetailTabTimeLine timeLine={timeLine} />}
         {check === "MyOrder" && <DetailTabMyOrder boxes={boxes} boxContract={boxContract} onCancel={props.onCancel}/>}
         </div> 
    )
});

const NavTabProjectInfo = React.memo((props)=>{
    const inoData = props.inoData;
    const info = inoData?.inoInformation;
    const rule = inoData?.inoRules;
    const boxes = inoData?.inoBoxes;
    const timeLine = inoData?.inoTimelines;
    const boxContract = inoData?.boxContract;
    const {Option} = Select;
    const [check, setCheck] = useState("Project"); 
    const changeTab = (e) =>{
        setCheck(e);
    }
    return(
        <div className="c-project">
            <Select  style={{ width: "100%" }} dropdownStyle={{ backgroundColor: "#32353E", width:"200px" }} className="c-selectChangeTab" defaultValue="Project's Info" placeholder='Select network' onChange={changeTab}>
                <Option value="Project">Project's Info</Option>
                <Option value="Rules">Rules</Option>
                <Option value="WhatInBox">What’s in the box</Option>
                <Option value="TimeLine">Timeline</Option>
                <Option value="MyOrder">My orders</Option>
            </Select>
            {check === "Project" && <DetailTabProjectInfo info={info}/>}
            {check === "Rules" && <DetailTabRule  rule={rule}/>}
            {check === "WhatInBox" && <DetailTabWhatInBox boxes={boxes}/>}
            {check === "TimeLine" && <DetailTabTimeLine timeLine={timeLine} />}
            {check === "MyOrder" && <DetailTabMyOrder boxes={boxes} boxContract={boxContract} onCancel={props.onCancel}/>}
        </div> 
    )
});



const DetailTabProjectInfo = React.memo(({info})=>{ 
    if(info === null) {
        return(
            <div className="detail">
                <div className="c-nodata">
                    <img src={NodataImg} alt="No Data" />
                    <p>No Data</p>
                </div>
            </div>
        )
    }
    return(
        <div className="detail">
            <div className="flex jc-between">
                <span>{info?.title}</span>
                <img style={{width: '80px'}} src={info?.logoLink ? info?.logoLink : '../../images/logo-default.svg'} alt={info?.title} />
            </div> 
            <span>{info?.description}</span>
        
            <ul className="c-footer">
                {info?.telegram && <li>
                    <a href={info?.telegram} target="_blank" rel="noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none">
                        <path d="M0.931254 7.01823L18.668 0.0848573C19.4912 -0.216661 20.2102 0.288461 19.9435 1.55049L19.945 1.54893L16.925 15.9737C16.7012 16.9963 16.1018 17.245 15.2632 16.7632L10.6642 13.3268L8.44599 15.4934C8.20071 15.7421 7.99376 15.9519 7.51853 15.9519L7.84506 11.2069L16.3685 3.40001C16.7395 3.06896 16.2857 2.88246 15.7967 3.21195L5.2635 9.93551L0.722766 8.49941C-0.262949 8.18235 -0.284411 7.50004 0.931254 7.01823Z" />
                    </svg>
                    </a>
                </li>}
                {info?.twitter && <li>
                    <a href={info?.twitter} target="_blank" rel="noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none">
                        <path d="M19.9032 1.91486C19.1711 2.23948 18.3836 2.45905 17.5577 2.5571C18.401 2.0519 19.0481 1.25251 19.3535 0.298435C18.5645 0.766492 17.6902 1.10604 16.7605 1.28906C16.0156 0.495769 14.9544 0 13.7792 0C11.5245 0 9.6958 1.82868 9.6958 4.08339C9.6958 4.40344 9.73204 4.71497 9.80208 5.01402C6.40844 4.84379 3.39942 3.21823 1.38529 0.747002C1.03387 1.34996 0.832269 2.0519 0.832269 2.80012C0.832269 4.21647 1.55369 5.46685 2.64877 6.19893C1.97972 6.17792 1.34966 5.99429 0.799381 5.68763C0.799076 5.70499 0.799076 5.72235 0.799076 5.7394C0.799076 7.71791 2.2072 9.36813 4.07486 9.743C3.73257 9.8368 3.3711 9.88643 2.99928 9.88643C2.73556 9.88643 2.48006 9.86116 2.23096 9.81365C2.75048 11.4356 4.25819 12.6162 6.04545 12.6494C4.64767 13.7448 2.88721 14.3974 0.973569 14.3974C0.644681 14.3974 0.318839 14.3782 0 14.3401C1.80614 15.4991 3.95305 16.1749 6.25892 16.1749C13.7697 16.1749 17.8772 9.95282 17.8772 4.55632C17.8772 4.37939 17.8732 4.20307 17.8653 4.02827C18.6638 3.45271 19.356 2.73373 19.9032 1.91486Z"/>
                    </svg>
                    </a>
                </li>}
                
                {info?.facebook && <li>
                    <a href={info?.facebook} className="is-face" target="_blank" rel="noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20" fill="none">
                        <path d="M6.31171 6.89454V4.5702C6.31171 3.92889 6.83188 3.40869 7.47462 3.40727H8.63754V0.5H6.31314C4.38635 0.5 2.82583 2.06049 2.8244 3.98727V3.98873V6.89454H0.5V9.80188H2.8244V19.1023H6.31314V9.80188H8.63754L9.80044 6.89454H6.31171Z"/>
                    </svg>
                    </a>
                </li>}
              
                {info?.medium && <li>
                    <a href={info?.medium} className="is-m" target="_blank" rel="noreferrer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                        <path d="M16.5637 1.76289L18 0.316579V0H13.0245L9.4785 9.29053L5.44425 0H0.22725V0.316579L1.905 2.44263C2.0685 2.59974 2.154 2.82947 2.13225 3.06079V11.4158C2.184 11.7166 2.091 12.0261 1.89 12.2447L0 14.6558V14.9684H5.35875V14.6518L3.46875 12.2447C3.264 12.0253 3.16725 11.7213 3.2085 11.4158V4.18895L7.9125 14.9724H8.45925L12.504 4.18895V12.7792C12.504 13.0058 12.504 13.0524 12.363 13.2008L10.908 14.6826V15H17.967V14.6834L16.5645 13.2379C16.4415 13.14 16.3778 12.9758 16.404 12.8163V2.18447C16.3778 2.02421 16.4408 1.86 16.5637 1.76289Z"/>
                    </svg>
                    </a>
                </li>}
            </ul>
        </div>
    )
});


const DetailTabRule = React.memo(({rule})=>{
    const elmRule = rule.map((item, index) => {
        return(
            <li key={index}>{index+1} {item?.title}</li>
        )
    });
    return(
        <div className="tab-rule">
            <ul className="tab-rule__list">
                {rule.length > 0 ? elmRule : <>
                    <div className="c-nodata">
                        <img src={NodataImg} alt="No Data" />
                        <p>No Data</p>
                    </div>
                </>}
            </ul>
        </div>
    )
});

const DetailTabWhatInBox = React.memo(({boxes})=>{
    const [isReadMore, setIsReadMore] = useState(false);
    const [isDetailBox, setIsDetailBox] = useState(false);
    const [isBoxId, setIsBoxId] = useState(null);
    const size = useWindowSize();

    const elmBoxes = boxes.map((item, index) => {
        return(
            <li className="c-whatBox__item" key={index}>
                <p className="is-name">
                    <img src={item?.imageLink ? item?.imageLink : '../../images/ino-default.svg'} alt={item?.name} />
                    {item?.name}
                </p>
                <p className="is-color">{size.width >= 767 ? '' : <span>Total Sale</span>}{item?.total ? item?.total : '--'}</p>
                <p className="is-color">{size.width >= 767 ? '' : <span>Price (/Box)</span>}{item?.price ? item?.price : '--'} {item?.price ? item?.currency : ''}</p>                
                {size.width >= 1024 ? <>
                    <p className={`is-des ${item?.description?.length >= 100 ? isReadMore ? '' : 'is-read' : ''}`}>
                        <span>{item?.description}</span>
                        {item?.description?.length >= 100 ? <span className="is-readMore" onClick={() => setIsReadMore(!isReadMore)}>
                            {isReadMore ? 'Read Less' : 'Read More'}
                        </span> : ''}
                    </p>
                </> : <>  
                    <p className="is-des">
                        {size.width >= 767 ? '' : <span>Description</span>}
                        <span className="is-readMore" onClick={() => {setIsDetailBox(true);setIsBoxId(item?.id)}}>Detail</span>
                    </p>                                       
                </>}
            </li>
        );
    });

    return(
        <>
        {isDetailBox ? <PopupBoxes id={isBoxId} onCancel={() => setIsDetailBox(false)} /> : ''} 
        <div className="tab-whatinbox">
            <ul className="c-whatBox">
                {size.width >= 767 ? <>
                    <li className="c-whatBox__head">
                        <p>Box’s Name</p>
                        <p>Total Sale</p>
                        <p>Price (/Box)</p>
                        <p>Description</p>
                    </li>
                </> : ''}                
                {boxes.length > 0 ? elmBoxes : <>
                    <div className="c-nodata">
                        <img src={NodataImg} alt="No Data" />
                        <p>No Data</p>
                    </div>
                </>}
            </ul>
        </div>
        </>
    )
});

const DetailTabTimeLine = React.memo(({timeLine}) => { 
    const elmTimeLine = timeLine.map((item,index) => {
        return(
            <li className="c-timeLine__item" key={index}>
                <span><img src="../../images/icon-timeLine.png" alt="" /></span>
                <h3>{item.title}</h3>
                <p>{item.description ? item.description : '--'}</p>
            </li>
        )
    });
    return(
        <ul className="c-timeLine">
            {timeLine.length > 0 ? elmTimeLine : <>
                <div className="c-nodata">
                    <img src={NodataImg} alt="No Data" />
                    <p>No Data</p>
                </div>
            </>}
        </ul>
    )
});



const DetailTabMyOrder = React.memo((props)=>{ 
    const {boxContract, boxes} =props;
    const currentUser = useSelector(state => state.currentUser);
    const account = currentUser.address; 
    const contractBox = useMyBoxContract(boxContract);
    const [totalBox, setTotalBox] = useState(0)

    useEffect(() => { 
        if(account && boxes) {
            let arrBoxes = [];
            for (let index = 0; index < boxes.length; index++) {  
                _myBoxInfo(contractBox, account, boxes[index]?.id).then((resData) => { 
                    if(resData && resData.toNumber() > 0) {
                        arrBoxes.push(resData.toNumber()); 
                        const sum = arrBoxes.reduce((partialSum, a) => partialSum + a, 0); 
                        setTotalBox(sum); 
                    }
                });
            } 
        }  
    }, [contractBox, account, boxes]);  
    
    const MyBoxList = boxes && boxes.map((item,index) => <MyOrderItem data={item} boxContract={boxContract} key={index} />)
    
    return(
        <div className="tab-myorder_border">
        <div className="tab-myorder">
            <div className="title">
                <span>Total Quantity</span> 
            </div>
            <div className="total-box">
                <span className="text-total"> {totalBox} Boxes</span> 
            </div> 
        </div>
            {MyBoxList}
        </div>
    )
}); 
  

const ProgressItem = React.memo((props)=>{ 
    const {inoData, marketContract, item} = props;
    const [percent, setPercent] = useState(0);
    const [remainingBox, setRemainingBox]=useState(0);
    const [totalBox, setTotalBox]=useState(0)
    
    useEffect(() => {
        (async () =>{
            if(marketContract && inoData && item){
                const campaignInfo = await _getSoldBox(marketContract, inoData?.id, item?.id);  
                setRemainingBox(campaignInfo[0].toNumber());
                setTotalBox(campaignInfo[1].toNumber());
            }
        })(); 
        
    }, [marketContract, inoData, item, remainingBox]);

   
    useEffect(() => { 
        if(item?.soldOut > 0){
            setPercent((item?.soldOut * 100 /item?.total))
        }
        else{
            setPercent((totalBox-remainingBox) * 100 /totalBox)
        } 
    }, [remainingBox, totalBox]); 

    return(
        <div className="c-progress-ino">  
            <img src="../../images/coin.png" alt='' />
            <div className="c-progress-ino__icon"/>  
            <div className="c-progress-ino__barIno"/>  
            <div style={{width: isNaN(percent)? "0%": `calc(${percent}% - 30px)`}} className="c-progress-ino__barIno running"/> 
            {
                item?.soldOut> 0 ? <p><span>Sale {item?.soldOut} / {item?.total} Boxes</span></p>
                : <p><span>Sale {totalBox - remainingBox} / {totalBox} Boxes</span></p> 
            } 
        </div>
    )
})

export default InoDetail;