import { CAMPAIGN_STATUS, CHAINID_CONVERT, CHAINID_FULLNAME, CHAINID_LOGO, CHAIN_INFO, MAPPING_CHAINID_DECIMAL, Networks } from '../constants';

export const getCampaignTimeItem = (campaign, round) => {

    let timeItem = null;
    if (round.startIDO && round.startJoinPool) {
        let _roundStatus = CAMPAIGN_STATUS.TBA;
        const nowDate = new Date();
        const startIDODate = new Date(round.startIDO);
        const startJoinPoolDate = new Date(round.startJoinPool);
        const endJoinPoolDate = new Date(round.endJoinPool);

        if (startIDODate <= nowDate && nowDate < startJoinPoolDate) {
            _roundStatus = CAMPAIGN_STATUS.UPCOMING;
        }
        else if (startJoinPoolDate <= nowDate && nowDate < endJoinPoolDate) {
            _roundStatus = CAMPAIGN_STATUS.OPENING;
        }
        else if (nowDate >= endJoinPoolDate) {
            _roundStatus = CAMPAIGN_STATUS.COMPLETED;
        }

        timeItem = {
            startIDO: round.startIDO ?? null,
            startClaim: round.startClaim ?? null,
            startJoinPool: round.startJoinPool ?? null,
            endJoinPool: round.endJoinPool ?? null,
            endIDO: round.endIDO ?? null,
            roundStatus: _roundStatus
        };
    }
    else {
        timeItem = {
            startIDO: campaign.startIDO ?? null,
            startClaim: campaign.startClaim ?? null,
            startJoinPool: campaign.startJoinPool ?? null,
            endJoinPool: campaign.endJoinPool ?? null,
            endIDO: campaign.endIDO ?? null,
            roundStatus: campaign.status
        };
    }
    return timeItem;
}

export const getNetworkObject = (network) => {
    if (network) {
        return {
            name: CHAINID_FULLNAME[network.toLowerCase()],
            logo: CHAINID_LOGO[network.toLowerCase()]
        }
    }
    else {
        return {
            name: '',
            logo: ''
        }
    }

}

export const getChainInformation = (chainId) => {
    if (chainId === MAPPING_CHAINID_DECIMAL.bsc) {
        return CHAIN_INFO.bsc
    }
    else if (chainId === MAPPING_CHAINID_DECIMAL.eth) {
        return CHAIN_INFO.eth
    }
    else if (chainId === MAPPING_CHAINID_DECIMAL.poly) {
        return CHAIN_INFO.poly
    }
    else if (chainId === MAPPING_CHAINID_DECIMAL.arb) {
        return CHAIN_INFO.arb
    }
    else if (chainId === MAPPING_CHAINID_DECIMAL.arbtestnet) {
        return CHAIN_INFO.arb
    }
    else if (chainId === MAPPING_CHAINID_DECIMAL.helatestnet) {
        return CHAIN_INFO.helatestnet
    }
    else if (chainId === MAPPING_CHAINID_DECIMAL.hela) {
        return CHAIN_INFO.hela
    }
}

export const networkTxnUrl = (network, hash) => {

    let url_prefix = "";
    if (network === Networks.BSC) {
        url_prefix = "https://bscscan.com/tx/";
    } else if (network === Networks.POLY) {
        url_prefix = "https://polygonscan.com/tx/";
    } else if (network === Networks.ETH) {
        url_prefix = "https://etherscan.io/tx/";
    } else if (network === Networks.ARB) {
        url_prefix = "https://goerli.arbiscan.io/";
    }
    return url_prefix + hash;
}

export const getClaimRoundId = (allocationItem) => {
    const claimTimeDay = allocationItem?.claimTime?.split("T");
    const claimString = claimTimeDay && claimTimeDay[0].replace(/-/g, '');
    return parseInt(allocationItem.roundId + claimString.substring(2, 8));
}
