import { Tokens } from '../constants';

const axiosInterceptor = {
    setupInterceptors: (axios, isToken = false, isFormData = false) => {
        axios.interceptors.request.use(config => {
                if (isToken) {
                    return config;
                }

                let token = localStorage.getItem(Tokens.AUTHUSER);
                if (token) {
                    config.headers['Authorization'] = `Bearer ${token}`;
                }
                
                if (isFormData) {
                    config.headers['Content-Type'] = 'multipart/form-data';
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
        axios.interceptors.response.use(
            response => successHandler(response),
            error => errorHandler(error)
        );
        const errorHandler = (error) => {
            return Promise.reject({ ...error })
        };
        const successHandler = (response) => {
            return response;
        };
    }
};

export default axiosInterceptor;