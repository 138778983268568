import React, { useEffect, useState } from 'react';
import CampaignRound from './campaignRound';
import { useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import allActions from '../../actions';
import NodataImg from '../common/asset/images/nodata.svg';
import IconCopy from '../common/asset/images/icon-copy.svg';
import { extractContent, formatData } from '../../utils/StringHelper';
import { Breadcrumb, Col, Row, Tabs } from 'antd';
import { useActiveWeb3React } from '../../hooks';
import networkChange from '../../utils/networkChange';
import { CAMPAIGN_STATUS, MAPPING_CHAINID } from '../../constants';
import { getNetworkObject } from '../../utils/campaignUltils';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import { Loader } from '../common/component';
import FooterHome from '../common/footer/footerHome';
import YourInvetmentAllocation from './YourInvetmentAllocation';
import { formatNumberCurrency } from '../../utils/formatNumberCurrency';

const LaunchpadDetail = () => {
    let location = useLocation();
    const dispatch = useDispatch();
    const { handle } = useParams();
    const { account, chainId } = useActiveWeb3React();
    const [loadingPage, setLoadingPage] = useState(false);
    const [roundActive, setRoundActive] = useState(null);
    const { TabPane } = Tabs;
    const campaignsData = useSelector(state => state.campaignsData);

    useEffect(() => {
        let _account = localStorage.getItem("account");

        if (!_account) {
            _account = account;
        }

        setLoadingPage(true);
        if (_account) {
            dispatch(allActions.campaignAction.getCampaignByHandle({ handle, address: _account }, (res) => {
                if (res.callResult === true) {
                    setLoadingPage(false);
                }
            }));

        }
        else {
            dispatch(allActions.campaignAction.getCampaignByHandle({ handle, address: '' }, (res) => {
                if (res.callResult === true) {
                    setLoadingPage(false);
                }
            }));
        }
    }, [account]);

    const {
        title,
        status,
        bannerURL,
        logoUrl,
        network,
        claimChain,
        totalRaise,
        idoRate,
        description,
        website,
        telegramLink,
        mediumLink,
        twitterLink,
        acceptCurrency,
        rounds,
        tokenInform,
        youtubeLink,
        discordLink,
        facebookLink,
        fullLink
    } = campaignsData;

    const campaignRoundPhase1 = rounds && rounds.filter(function (item) {
        return item.phase === 1 || item.phase === 2;
    });

    const campaignRoundPhase2 = rounds && rounds.filter(function (item) {
        return item.phase === 3;
    });

    const campaignRoundPhase1Renderer = campaignRoundPhase1 && campaignRoundPhase1.map((item, key) => (
        <CampaignRound item={item} key={key} />
    ));

    const campaignRoundPhase2Renderer = campaignRoundPhase2 && campaignRoundPhase2.map((item, key) => (
        <CampaignRound item={item} key={key} />
    ));

    rounds && rounds.sort(function (a, b) {
        return a.roundType - b.roundType;
    });

    const roundRaiseRenderer = rounds && rounds.map((item, key) => {
        return (
            <p className="c-infoIdo__row" key={key}>
                <span>{item.round}:</span>
                <span>{formatData(item.totalRaise, acceptCurrency)}</span>
            </p>
        );
    });

    useEffect(() => {
        if (claimChain && network !== claimChain && status === CAMPAIGN_STATUS.COMPLETED) {
            networkChange(MAPPING_CHAINID[claimChain?.toLowerCase()]);
        }
        else {
            networkChange(MAPPING_CHAINID[network?.toLowerCase()]);
        }
    }, [network, claimChain, status]);

    const commitNetworkChain = getNetworkObject(network);
    const claimNetworkChain = getNetworkObject(claimChain);

    return (
        <>
            <div className="p-launchpadDetail">
                <div className='banner-detail'>
                    <img src='../../images/banner-ido-icon-left.svg' alt=''/>
                    <img src='../../images/banner-ido-icon-right.svg' alt=''/>
                </div>
                <div className='container'>
                    
                    {loadingPage && <Loader path="../.." />}
                    <div className="launchpadDetail-info">
                        <Breadcrumb className='head-breadcrumb-detail'>
                            <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                            <Breadcrumb.Item>IDO Launchpad</Breadcrumb.Item>
                            <Breadcrumb.Item>{title}</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="c-infoIdo">
                            <Row gutter={30}>
                                <Col xs={24} sm={15} xl={15} md={15}>
                                    <div className="c-infoIdo__body">
                                        <h2 className="c-infoIdo__head">
                                            
                                             <div className='ido-name-social'>
                                                <span><img src={logoUrl} alt={title} /></span>
                                                <div className='ido-name'>
                                                    <div className='ido-detail-title'>
                                                        <div className='ido-main-title'>{title}</div>
                                                        <div className='ido-symbol'>{tokenInform?.symbol}</div>
                                                    </div>
                                                    <ul className="c-infoIdo__social">
                                                        {telegramLink && <li><a href={telegramLink} target="_blank"><img src="../../images/icon-tele.svg" alt="Telegram" /></a></li>}
                                                        {twitterLink && <li><a href={twitterLink} target="_blank"><img src="../../images/icon-tw.svg" alt="Twitter" /></a></li>}
                                                        {mediumLink && <li><a href={mediumLink} target="_blank"><img src="../../images/icon-m.svg" alt="M" /></a></li>}
                                                        {website && <li><a href={website} target="_blank"><img src="../../images/icon-web.svg" alt="Website" /></a></li>}
                                                        {youtubeLink && <li><a href={youtubeLink} target="_blank"><img src="../../images/icon-youtube.svg" alt="Youtube" /></a></li>}
                                                        {discordLink && <li><a href={discordLink} target="_blank"><img src="../../images/icon-discord.svg" alt="Discord" /></a></li>}
                                                        {facebookLink && <li><a href={facebookLink} target="_blank"><img src="../../images/icon-facebook.svg" alt="Facebook" /></a></li>}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="c-infoIdo__contract">
                                                <input readOnly value={tokenInform?.tokenAddress ? 'Contract: ' + tokenInform?.tokenAddress?.substring(0, 6) + "..." + tokenInform?.tokenAddress?.substr(tokenInform?.tokenAddress?.length - 6) : "TBA..."} />
                                                {tokenInform?.tokenAddress && <CopyToClipboard
                                                    text={tokenInform?.tokenAddress ? tokenInform?.tokenAddress : ""}
                                                    onCopy={() => Store.addNotification(addNotify('Copied', 'success'))}
                                                >
                                                    <img src={IconCopy} alt="Copy" />
                                                </CopyToClipboard>}
                                            </div>
                                             
                                        </h2>
                                        <div className="c-infoIdo__text" dangerouslySetInnerHTML={{ __html: description }}></div>
                                        {fullLink && <a href={fullLink} target="_blank" className="view-more">View more</a>}

                                        
                                        {/* <span className="c-infoIdo__line"></span> */}
                                        {/* {roundRaiseRenderer} */}
                                    </div>
                                </Col>
                                <Col xs={24} sm={9} xl={9} md={9}>
                                    <div className="c-infoIdo-totken-info">
                                        {claimChain && network !== claimChain ?
                                            (<>
                                                <p className="c-infoIdo__row">
                                                    <span>Commit Fund Network:</span>
                                                    <span><img src={"../" + commitNetworkChain.logo} alt={commitNetworkChain.name} />{commitNetworkChain.name}</span>
                                                </p>
                                                <p className="c-infoIdo__row">
                                                    <span>Claim Network:</span>
                                                    <span><img src={"../" + claimNetworkChain.logo} alt={claimNetworkChain.name} />{claimNetworkChain.name}</span>
                                                </p>
                                            </>) : (
                                                <p className="c-infoIdo__row">
                                                    <span>Network:</span>
                                                    <span><img src={"../" + commitNetworkChain.logo} alt={commitNetworkChain.name} />{commitNetworkChain.name}</span>
                                                </p>
                                            )}
                                        <p className="c-infoIdo__row">
                                            <span>Total Raise:</span>
                                            <span>{formatData(totalRaise, acceptCurrency)}</span>
                                        </p>
                                        <p className="c-infoIdo__row">
                                            <span>IDO Rate:</span>
                                            <span>{formatData(idoRate, acceptCurrency)}</span>
                                        </p>
                                        <span className="c-infoIdo__line"></span>
                                        <p className="c-infoIdo__row">
                                            <span>Token Name:</span>
                                            <span>{tokenInform?.name}</span>
                                        </p>
                                        <p className="c-infoIdo__row">
                                            <span>Token Symbol:</span>
                                            <span>{tokenInform?.symbol}</span>
                                        </p>
                                        <p className="c-infoIdo__row">
                                            <span>Total Supply:</span>
                                            <span>{formatNumberCurrency(tokenInform?.totalSupply)}</span>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        
                        </div>
                    </div>
                    <div className="p-launchpadDetail__right launchpadDetail-round">
                        <Tabs className="tab-ido-general c-tabs" defaultActiveKey={1}>
                            <TabPane tab="IDO Pool Details" key="1">
                                {campaignRoundPhase2 && campaignRoundPhase2.length > 0 ? (
                                    <Tabs className={`c-phase ${campaignRoundPhase2 ? 'is-phase2' : ''}`} defaultActiveKey={campaignRoundPhase2 ? '2' : '1'}>
                                        <TabPane tab="Phase 1" key="1">
                                            {campaignRoundPhase1Renderer}
                                        </TabPane>
                                        <TabPane tab="Phase 2" key="2">
                                            {campaignRoundPhase2Renderer}
                                        </TabPane>
                                    </Tabs>
                                ) :
                                    (campaignRoundPhase1 && campaignRoundPhase1.length > 0 ? 
                                        <>
                                            <Row gutter={30}>
                                                {campaignRoundPhase1 && campaignRoundPhase1.map((item, key) => (
                                                    <CampaignRound campaignsData={campaignsData} item={item} keyRound={key} roundActive={roundActive} setRoundActive={setRoundActive}/>
                                                ))}
                                            </Row>

                                            {roundActive && account && (
                                                <>
                                                    <YourInvetmentAllocation item={roundActive} />
                                                </>
                                            )}

                                            
                                        </> 
                                    :
                                        <>
                                            <div className="c-nodata">
                                                <img src={NodataImg} alt="No Data" />
                                                <p>No Data</p>
                                            </div>
                                        </>)}
                            </TabPane>
                            <TabPane tab="About Project" key="2">
                                {description}
                            </TabPane>
                            
                        </Tabs>
                        
                    </div>
                </div>
            </div>
            <FooterHome />
        </>
    );
}

export default LaunchpadDetail;