import currentUser from './currentUser';
import campaignsData from './campaign';
import inoData from './ino'; 
import whitelist from './whitelist';
import allocation from './allocation';
import airdrop from './airdrop';
import gamehub from './gamehub';
import {combineReducers} from 'redux';

const rootReducer = combineReducers({
    currentUser,
    campaignsData,
    whitelist,
    allocation,
    inoData,
    airdrop,
    gamehub
})

export default rootReducer;