import React,{useState, useEffect} from 'react'; 
import useWindowSize from '../../hooks/useWindowSize'; 
import {useActiveWeb3React} from "../../hooks";
import {useSelector, useDispatch} from 'react-redux';
import allActions from '../../actions';
import { Link } from 'react-router-dom';
import {useLocation} from "react-router-dom"; 
import {_getSoldBox } from "./utils";
import { useBoxMarketContract } from '../../hooks/useContract'; 
import { formatShortDateAndTimeAllocations } from '../../utils/formatDatetime';

const ItemClaimable = ({item,bought}) => {   
    const { title, logoLink } = item;  
    let location = useLocation();
    let myPathname = location.pathname; 
    const sep = /\//;
    let isPathname = myPathname.split(sep);  
    return(
        <Link to={`/ino/${isPathname[2]}/${item.handle}`} className="c-list__item">
            <div className="c-list__logo">
                <div className="c-list__img">
                    <img src={logoLink} alt={title} /> 
                </div>
                <div className="c-list__title">
                    <h3>{title}</h3>
                    <span><img src={logoLink} alt={title} /></span>
                </div>
            </div>
            <div className="c-list__body">
            {item?.inoBoxes.map((e,i)=>{
                return(
                    <ItemRender key={i.toString()} item={e} bought={bought} itemInoData={item}/>
                )
            })}  
            </div>
        </Link>
    );
} 
 
const ItemRender =(props) => {  
    const {item, itemInoData} =  props
    const size = useWindowSize();
    const sizeTablet = 767;  
    const marketContract = useBoxMarketContract(itemInoData?.inoContract); 
    const [remainingBox, setRemainingBox] = useState(0);
    const [totalBox, setTotalBox] = useState(0); 
    const [soldBox, setSoldBox] = useState(0); 
    const InoDate = itemInoData?.sellingTime ? formatShortDateAndTimeAllocations(new Date(itemInoData?.sellingTime)) : "--";
  
    useEffect(() => {
        (async () =>{
            if(marketContract && itemInoData?.id && item?.id){
                const campaignInfo = await _getSoldBox(marketContract, itemInoData?.id, item?.id);  
                setRemainingBox(campaignInfo[0].toNumber());
                setTotalBox(campaignInfo[1].toNumber()); 
            }
        })();  
        
    }, [marketContract, itemInoData, item]);  

    if(size.width <= sizeTablet){
    return(
        <div className="ino-list"> 
            <div className="list-item"> 
                <div className='nav-list'>
                    <span className="header-item">
                        BOX NAME
                    </span> 
                    <span className="name-item">{item?.name}</span> 
                </div> 
            </div>  
            <div className="list-item">  
                <div className='nav-list'>
                    <span className="header-item">
                        INO DATE
                    </span>  
                    <span className="name-item">{InoDate}</span> 
                </div>  
            </div>
            <div className="list-item">  
                <div className='nav-list'>
                    <span className="header-item">
                        PRICE(/BOX)
                    </span>  
                    <span className="name-item">{item?.price} {item?.currency}</span>  
                </div>  
            </div>
            
            <div className="list-item">  
                <div className='nav-list'>
                    <span className="header-item">
                        TOTAL AMOUNT(BOXES)
                    </span> 
                    {item?.soldOut > 0 
                    ? <span className="name-item">{item?.soldOut}/ {item?.total}</span>
                    : <span className="name-item">{totalBox - remainingBox} / {totalBox}</span> }  
                </div>  
            </div> 
            <div className="list-item">  
                <div className='nav-list'>
                    <span className="header-item">
                        ACTION
                    </span>  
                    <span className="name-item">{itemInoData.networkChain}</span>  
                </div> 
            </div>
        </div> ) }
    return (
        <div className="ino-list">
            <div className="list-item">
                <div className="img-item">
                    <img src={item.imageLink} alt=""/> 
                </div>  
                <span className="name-item">{item.name}</span> 
            </div> 
            <div className="list-item">  
                <span className="name-item">{InoDate}</span> 
            </div>
            <div className="list-item">  
           
                <span className="name-item">{item?.price} {item?.currency}</span> 
            </div>
            <div className="list-item">  
            {item?.soldOut > 0
            ? <span className="name-item">{item?.soldOut}/ {item?.total}</span>
            : <span className="name-item">{totalBox - remainingBox}/ {totalBox}</span>
            }
                
            </div> 
            <div className="list-item">  
                <img className="img-icon" src="../../images/binance.png" alt=''/> 
                <span className="name-item">{itemInoData.networkChain}</span> 
            </div>  
            
        </div> 
    );
}; 

 

export default ItemClaimable;