import useWindowSize from "../../hooks/useWindowSize";

export const Loader = ({path = '..'}) => {
    return(
        <>
            <div className="c-loading d-block">
                <p className="text-center">
                    <img className="spin-loader" style={{maxWidth: '70px'}} src={`${path}/../../images/up-favi.svg`} alt="Loading" />
                    <div className="text-center text-white font-20">Transaction is processing. Please do not close or refresh this page.</div>
                </p>
                
            </div>
            
        </>
    )
}

export const LoadingContent = () => {
    return(
        <div className="c-loadingContent">
            <img style={{maxWidth: '70px'}} src="../images/up-favi.svg" alt="Loading" />
        </div>
    )
}

export const CommingSoon = () => {
    const size = useWindowSize();
    return(
        <div className="c-commingSoon">
            {size.width >= 1024 ? <img src="../images/comingSoon-pc.gif" alt="Comming Soon" /> : <img src="../images/comingSoon-tablet.gif" alt="Comming Soon" />}
        </div>
    )
}