import React, { useCallback, useEffect, useState } from "react";
import bgMainheader from "../banner-nft.png";
import { Alert, Col, Empty, Form, Input, Modal, Row, Spin } from 'antd';

import { Link, useLocation , useNavigate, useParams } from "react-router-dom";
import FooterHome from '../../common/footer/footerHome';
import moment from "moment";
import { useActiveWeb3React } from "../../../hooks";
import Web3 from "web3";

import { MAPPING_CHAINID_DECIMAL } from "../../../constants";
import Countdown from "react-countdown";
import TimeAgo from "../../../config/timeAgo";
import ChainNotSupport from "../../chain-not-support";
import { HelascriptionApi } from "../../../config/api/apiHelascription";

const HelascriptionDetails = () => {
    const { account, chainId } = useActiveWeb3React();
    const web3 = new Web3(window.ethereum);
    const [showInfo, setShowInfo] = useState(false);
    const [showAtt, setShowAtt] = useState(false);
    const [showActive, setShowActive] = useState(false);
    const [showItem, setShowItem] = useState(false);
    const location = useLocation();
    const [helascription, setHelascription] = useState();

    const [showBuyModal, setShowBuyModal] = useState(false);
    const [showOfferModal, setShowOfferModal] = useState(false);


    const navigate = useNavigate();

    const handleShow = () => {
        setShowInfo(!showInfo)
    }

    const handleActive = () => {
        setShowActive(!showActive)
    }
  

    let { id } = useParams();

    const getDetailHelascription = async () => {
        let res = await HelascriptionApi.getDetailHelascription(id);
        console.log('res', res)
        if (res.status === 200) {
            if (res.data.data) {
                setHelascription(res.data.data);
            } else {
                setHelascription([]);
            }
        }
    }

    useEffect(() => {
        if(id){
            getDetailHelascription()
        }
    }, [id]);



    if(chainId && MAPPING_CHAINID_DECIMAL.helatestnet !== chainId){
        return(
            <><ChainNotSupport /></>
        )
    }

    console.log('asdad', helascription);
    let content = helascription ? JSON.parse(helascription?.content) : null;
    
    return (
        <>
            <div className="main-nft-section" style={{
                background: `url(${bgMainheader}) no-repeat`,
            }}>
                <div className="container">
                    <div className="box-details-nft">
                        <div className="breadcrum">
                            <ul>
                                <li>
                                    <Link to={`/helascription`}>Helascriptions</Link>
                                </li>
                                <li>
                                    {">"}
                                </li>
                                <li className="active">
                                    Detail
                                </li>
                            </ul>
                        </div>
                        <div className="mint-after-box">
                            <div className="box-left helascription-code">
                                <div className="helascription-content helascription-detail-code">
                                    <code>
                                        <p>"p": "{content?.p}",</p>  
                                        <p>"op": "{content?.op}",</p>  
                                        <p>"tick": "{content?.tick}",</p>  
                                        <p>"limit": "{content?.limit}"</p>
                                    </code>
                                </div>
                            </div>
                            <div className="box-right">
                                <div className="bid-details">
                                    <div className="bid-name">
                                        {content?.tick} #{helascription?.id.substring(0, 4)}...{helascription?.id.substring(helascription?.id.length - 4)}
                                    </div>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                                            <div className="mt-0">
                                                <div className="text-weight-500 text-darkblue">Supply</div>
                                                <div className="text-weight-600 mt-5">{Number(content?.max).toLocaleString()}</div>
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                                            <div className="mt-0">
                                                <div className="text-weight-500 text-darkblue">Holders</div>
                                                <div className="text-weight-600 mt-5">N/A</div>
                                            </div>  
                                        </Col>
                                        <Col className="gutter-row" xs={24} xl={8} md={8} sm={8}>
                                            <div className="mt-0">
                                                <div className="text-weight-500 text-darkblue">Creator</div>
                                                <div className="text-weight-600 mt-5">{helascription?.from.substring(0, 4)}...{helascription?.from.substring(helascription?.from.length - 4)}</div>
                                            </div>          
                                        </Col>
                                    </Row>
                                
                                
                                    <div className="group-detail-bid">
                                        <div className="title" onClick={handleShow}>
                                            <span className="left">
                                                Details
                                            </span>
                                            {!showInfo ?
                                                <>
                                                    <span className="right active">
                                                        <img src="../../images/arrow-circle-down-1.png" alt="" />
                                                    </span>
                                                </>
                                                :
                                                <>
                                                    <span className="right">
                                                        <img src="../../images/arrow-circle-down.png" alt="" />
                                                    </span>
                                                </>
                                            }
                                        </div>
                                        <div className={!showInfo ? "box-content" : "box-content dis-none"}>
                                            <ul>
                                                <li>
                                                    <div className="txt-left">
                                                        Helascription Number:
                                                    </div>
                                                    <div className="txt-right">
                                                        {Number(content?.max).toLocaleString()}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="txt-left">
                                                        Helascription ID:
                                                    </div>
                                                    <div className="txt-right">
                                                        {helascription?.token_id.substring(0, 4)}...{helascription?.token_id.substring(helascription?.token_id.length - 4)}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="txt-left">
                                                        Creator:
                                                    </div>
                                                    <div className="txt-right">
                                                        {helascription?.from.substring(0, 4)}...{helascription?.from.substring(helascription?.from.length - 4)}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="txt-left">
                                                        Owner:
                                                    </div>
                                                    <div className="txt-right">
                                                        {helascription?.from.substring(0, 4)}...{helascription?.from.substring(helascription?.from.length - 4)}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="txt-left">
                                                        Mime Type:
                                                    </div>
                                                    <div className="txt-right">
                                                        {helascription?.content_type}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="txt-left">
                                                        Created:
                                                    </div>
                                                    <div className="txt-right">
                                                        half a minute ago
                                                        {/* <TimeAgo timestamp={(mintedDate.getTime() / 1000)} /> */}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="group-detail-bid">
                            <div className="title" onClick={handleActive}>
                                <span className="left">
                                    Activities
                                </span>
                                {showActive ?
                                    <>
                                        <span className="right active">
                                            <img src="../../images/arrow-circle-down-1.png" alt="" />
                                        </span>
                                    </>
                                    :
                                    <>
                                        <span className="right">
                                            <img src="../../images/arrow-circle-down.png" alt="" />
                                        </span>
                                    </>
                                }
                            </div>
                            <div className={showActive ? "box-content" : "box-content dis-none"}>
                                <div className="main-table">
                                    <Empty />
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            
            
            <FooterHome />
        </>
    )
}
export default HelascriptionDetails
