import { useState, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { injected, walletconnect, bsc } from '../connectors';

export default function useConnectWallet() {
	const { connector, account, activate, deactivate, active } = useWeb3React();
	const [currentConnector, setCurrentConnector] = useState();
	const [currentConnectorId, setCurrentConnectorId] = useState();

	useEffect(() => {
		if (account) {
			if (currentConnectorId && currentConnector && currentConnector === connector) {
				localStorage.setItem('accountStatus', '1');
				localStorage.setItem('connectorId', currentConnectorId);
				localStorage.setItem('account', account);
			}
		}
	}, [account, currentConnectorId, currentConnector, connector]);

	async function walletLogin(connectorId) {
		
		if(connectorId === 'walletconnect') {
			if (!walletconnect.connected) {
				// create new session
				walletconnect.createSession();
			}
			walletconnect.on("connect", (error, payload) => {
				if (error) {
				  throw error;
				}
				window.location.reload();
			});
			  
			walletconnect.on("session_update", (error, payload) => {
				if (error) {
				  throw error;
				}
				window.location.reload();
			});
			  
			walletconnect.on("disconnect", (error, payload) => {
				if (error) {
				  throw error;
				}
				window.location.reload();
			});
		} else if(connectorId === 'bsc') {
			setCurrentConnectorId(connectorId);
			setCurrentConnector(bsc);

			!active && (await activate(bsc));
		} else {
			setCurrentConnectorId(connectorId);
			setCurrentConnector(injected);
			
			!active && (await activate(injected));
		}
	}

	function walletLogout() {
		deactivate();
		window.localStorage.removeItem('accountStatus');
		window.localStorage.removeItem('connectorId');
		window.localStorage.removeItem('account');
		window.localStorage.removeItem('walletconnect');
		window.location.reload();
	}

	return { walletLogin, walletLogout };
}
