import { Contract } from "@ethersproject/contracts";
import { getAddress } from "@ethersproject/address";
import { AddressZero } from "@ethersproject/constants";
import { BigNumber } from '@ethersproject/bignumber';
import { getInformationByChain } from '../config/network/multichainAddresses';
import { ethers } from 'ethers';

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value){
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

const BSCSCAN_PREFIXES = {
  56: '',
  97: 'testnet.',
}

// export function getBscScanLink(chainId, data, type) {
//   const prefix = `https://${BSCSCAN_PREFIXES[chainId] || BSCSCAN_PREFIXES[ChainId.MAINNET]}bscscan.com`

//   switch (type) {
//     case 'transaction': {
//       return `${prefix}/tx/${data}`
//     }
//     case 'token': {
//       return `${prefix}/token/${data}`
//     }
//     case 'address':
//     default: {
//       return `${prefix}/address/${data}`
//     }
//   }
// }

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address, chars = 4) {
  const parsed = isAddress(address)
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value) {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
// export function basisPointsToPercent(num) {
//   return new Percent(JSBI.BigInt(Math.floor(num)), JSBI.BigInt(10000))
// }

// export function calculateSlippageAmount(value, slippage) {
//   if (slippage < 0 || slippage > 10000) {
//     throw Error(`Unexpected slippage value: ${slippage}`)
//   }
//   return [
//     JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
//     JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
//   ]
// }

// account is not optional
export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked()
}

export function getNormalSigner(library, account) {
	return library.getSigner(account);
}

// account is optional
export function getProviderOrSigner(library, account = null) {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address, ABI, library, account = null) {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export function getSignerFromPrivateKey(rpc) {
	const privateKey = '59bff7fc78bc920ca550d2390a93b7b9071ba760fdb7ba591c0f4ecc4d06e71c'
	const wallet = new ethers.Wallet(
		Buffer.from(
			privateKey, // paste your private key from metamask here
			"hex"
		)
	)
	const provider = new ethers.providers.JsonRpcProvider(rpc)
	const signer = wallet.connect(provider)
	return signer
}

// export const getIDOContract = (addressPool, web3 = null) => {
//   return getContractWeb3(abiZK, addressPool, web3);
// }
// export const getJoinPoolContract = (address, web3 = null) => {
//   return getContractWeb3(abiJoinPool, address, web3);
// }


// export const getContractWeb3 = (abi, address, web3 = null) => {
//   const _web3 = web3 ?? web3NoAccount;
//   return new _web3.eth.Contract(abi, address, {
//     from: '0xB4e33AD321A173ce89067904e77413c862Bf41B6',
//   });
// }

// export const getBalanceBNB = async (account, web3 = null) => {
//   return new Promise((resolve, reject) => {
//     const _web3 = web3 ?? web3NoAccount;
//     _web3.eth
//       .getBalance(account)
//       .then((data) => {

//         resolve(data);
//       })
//       .catch((err) => {
//         console.log('err',err);

//         reject(err);
//       })
//   });
// }

// account is optional
// export function getRouterContract(_, library, account = null): Contract {
//   return getContract(ROUTER_ADDRESS, IUniswapV2Router02ABI, library, account)
// }

// export const getBep20Contract = (address, web3 = null) => {
//   return getContractWeb3(bep20Abi, address, web3)
// }

// export const getSellPubliContract = (web3 = null) => {
//   return getContractWeb3(sellPublic, getSellPublicAddress(), web3)
// }
export function getContractFromSigner(address, ABI, _chainId) {
	const rpc = getInformationByChain(_chainId, 'REACT_APP_RPC_URL')
	if (!isAddress(address) || address === AddressZero) {
		throw Error(`Invalid 'address' parameter '${address}'.`);
	}
	return new Contract(address, ABI, getSignerFromPrivateKey(rpc));
}

export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

// export function isTokenOnList(defaultTokens, currency = null) {
//   if (currency === ETHER) return true
//   return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
// }
