
import React, { useState, useEffect, useCallback } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useHistory } from 'react-router-dom';
import { Alert, Card, Modal, Tag } from 'antd';
import { FormOutlined, GlobalOutlined, CopyOutlined, FacebookFilled, GithubOutlined, InstagramOutlined, InstagramFilled, MediumCircleFilled, TwitterCircleFilled } from '@ant-design/icons';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import '../style.css';
import { convertDateTime } from '../../../../utils/formatDatetime';

import MetrictChart from './MetrictChart';
import { convertTimeByMint } from '../../../../components/utils';
import { Store } from 'react-notifications-component';
import addNotify from '../../../common/Notify/addNotify';
import { CHAINID_FULLNAME_BY_ID } from '../../../../constants';

const DetailInfo = (props) => {
    const { detailInfo, idoId, status, chainNetworkListTest, chainNetworkListMain, maxLP, dex } = props;

    let nowDate = new Date(Date.now());
    let startDate = new Date(detailInfo?.roundIcos[0]?.startTime);
    let endDate = new Date(detailInfo?.roundIcos[0]?.endTime);

    let isManualListing = detailInfo?.roundIcos[0]?.doManualListing;;

    const [isSwitchChainModal, setIsSwitchChainModal] = useState(false);
    const [textVestingTime, setTextVestingTime] = useState('');


    const StatusRender = (nowDate, startDate, endDate, roundStatus) => {
        let status = '';
        let color = '';
        if (startDate > nowDate && endDate > nowDate && roundStatus != 'CANCEL') {
            status = 'upcoming';
            color = '#108ee9';
        }
        if (startDate < nowDate && endDate > nowDate && roundStatus != 'CANCEL') {
            status = 'opening';
            color = '#f5c112';
        }
        if (startDate < nowDate && endDate < nowDate && roundStatus != 'CANCEL') {
            status = 'closed';
            color = '#919191';
        }
        if (roundStatus == 'FILLED') {
            status = 'fiiled';
            color = '#87d068';
        }
        if (roundStatus == 'CANCEL') {
            status = 'canceled';
            color = 'red';
        }
        return <Tag className='text-capita' color={color}>{status}</Tag>;
    }
    const closeBox = () => {
        setIsSwitchChainModal(false);
    };
    const ChainNetworkRender = (chainId) => {

        let logoNetwork = '';
        let title = '';
        let chain;
        chain = chainNetworkListMain.filter(itemChain => parseInt(chainId) === parseInt(itemChain.chainId));
        if (chain == undefined || chain.length == 0) {
            chain = chainNetworkListTest.filter(itemChain => parseInt(chainId) === parseInt(itemChain.chainId));
        }

        if (chain[0]) {
            logoNetwork = chain[0].logoLink;
            title = chain[0].name;
        }
        return (
            <>
                <img title={title} src={logoNetwork} /> <span>{title}</span>
            </>
        );
    }

    // const getConvertTimeByMint = (vestingCycle) => {
    //     try {

    //         let listVestingCaculator: any = convertTimeByMint(vestingCycle);
    //         if (listVestingCaculator) {
    //             setTextVestingTime(listVestingCaculator);
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }

    // useEffect(() => {
    //     if (detailInfo && detailInfo?.vestingContributor.vestingCycle > 0) {

    //         getConvertTimeByMint(detailInfo?.vestingContributor.vestingCycle);

    //     }
    // }, [detailInfo]);

    let titleNetwork = CHAINID_FULLNAME_BY_ID[detailInfo?.roundIcos[0]?.chainId] ? CHAINID_FULLNAME_BY_ID[detailInfo?.roundIcos[0]?.chainId] : '';

    const onCopy = () => {
        Store.addNotification(addNotify('Copied', 'success'));
    };
    return (
        <>
            <div className="detail-right">
                <div className="detail-title">
                    {detailInfo?.name} - {detailInfo?.roundIcos[0]?.name}
                    <div className="network-right">
                        {/* <img src="../../images/polygon-network.png" /> */}
                        {ChainNetworkRender(detailInfo?.roundIcos[0]?.chainId)}
                    </div>
                </div>
                <div className="detail-status">
                    {StatusRender(nowDate, startDate, endDate, detailInfo?.roundIcos[0]?.roundStatus)}

                    <span className="project-token-main">${detailInfo?.tokenInfo?.symbol}</span>
                    {detailInfo?.website && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.website}><img src="../../../images/icon-web.svg" alt="Website" /></a></span>
                    )}
                    {detailInfo?.socialNetwork?.facebook && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.socialNetwork?.facebook}><img src="../../../images/icon-facebook.svg" alt="Facebook" /></a></span>
                    )}
                    {detailInfo?.socialNetwork?.github && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.socialNetwork?.github}><img src="../../../images/icon-facebook.svg" alt="Facebook" /></a></span>
                    )}
                    {detailInfo?.socialNetwork?.discord && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.socialNetwork?.discord}><img src="../../../images/icon-facebook.svg" alt="Facebook" /></a></span>
                    )}
                    {detailInfo?.socialNetwork?.instagram && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.socialNetwork?.instagram}><img src="../../../images/icon-facebook.svg" alt="Facebook" /></a></span>
                    )}
                    {detailInfo?.socialNetwork?.medium && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.socialNetwork?.medium}><img src="../../../images/icon-m.svg" alt="M" /></a></span>
                    )}
                    {detailInfo?.socialNetwork?.telegram && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.socialNetwork?.telegram}><img src="../../../images/icon-tele.svg" alt="Telegram" /></a></span>
                    )}
                    {detailInfo?.socialNetwork?.twitter && (
                        <span className="detail-social"><a target="_blank" href={detailInfo?.socialNetwork?.twitter}><img src="../../../images/icon-tw.svg" alt="Twitter" /></a></span>
                    )}


                </div>
                <div className="detail-description">
                    {detailInfo?.description}
                </div>

                <Card title="Token Infomation" bordered={false} className='card-ico-detail' style={{ width: '100%', marginTop: '20px' }}>
                    {isManualListing && (
                        <div className="detail-text">
                            <div className="left">Manual Listing</div>
                            <div className="right">
                                <span className='text-black-green text-weight-500'>Liquidity will not be automatically added!</span>
                            </div>
                        </div>
                    )}
                    <div className="detail-text">
                        <div className="left">Presale Address</div>
                        <div className="right text-weight-500">
                            <span>{detailInfo?.roundIcos.length > 0 && detailInfo?.roundIcos[0].roundAddress.substring(0, 7)}...{detailInfo?.roundIcos[0].roundAddress.substring(detailInfo?.roundIcos[0].roundAddress.length - 8)} </span>
                            <CopyToClipboard onCopy={onCopy} text={detailInfo?.roundIcos.length > 0 ? detailInfo?.roundIcos[0].roundAddress : ''}>
                                <CopyOutlined />
                            </CopyToClipboard>
                        </div>
                    </div>

                    <div className="detail-text">
                        <div className="left">Token Address</div>
                        <div className="right text-weight-500">
                            <span>{detailInfo?.tokenInfo?.tokenAddress.substring(0, 7)}...{detailInfo?.tokenInfo?.tokenAddress.substring(detailInfo?.tokenInfo?.tokenAddress.length - 8)} </span>
                            <CopyToClipboard onCopy={onCopy} text={detailInfo?.tokenInfo?.tokenAddress}>
                                <CopyOutlined />
                            </CopyToClipboard>
                            <p className='mb-0 text-danger'>(Do not send {detailInfo?.roundIcos[0]?.currencyPay} to the token address)</p>
                        </div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Token Name</div><div className="right text-black-green text-weight-500">{detailInfo?.name}</div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Token Symbol</div><div className="right text-black-green text-weight-500">{detailInfo?.tokenInfo?.symbol}</div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Token Decimals</div><div className="right text-black-green text-weight-500">{detailInfo?.tokenInfo?.decimals}</div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Network</div><div className="right text-black-green text-weight-500">{titleNetwork}</div>
                    </div>
                </Card>
                <Card bordered={false} title="Defi Launchpad Info" className='card-ico-detail' style={{ width: '100%', marginTop: '20px' }}>
                    <div className="detail-text">
                        <div className="left">Total Supply</div><div className="right text-black-green text-weight-500">{new Intl.NumberFormat("ja-JP").format(detailInfo?.tokenInfo.totalSupply)} {detailInfo?.tokenInfo.symbol}</div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Tokens for Presale</div><div className="right text-black-green text-weight-500">{detailInfo?.roundIcos.length > 0 ?  new Intl.NumberFormat("ja-JP").format((detailInfo?.hardCap * detailInfo?.roundIcos[0]?.swapRate)) : 0} {detailInfo?.tokenInfo.symbol}</div>
                    </div>

                    <div className="detail-text">
                        <div className="left">Presale Rate</div><div className="right text-black-green text-weight-500">1 {detailInfo?.roundIcos[0]?.currencyPay} = {detailInfo?.roundIcos.length > 0 ? detailInfo?.roundIcos[0].swapRate ? new Intl.NumberFormat("ja-JP").format(detailInfo?.roundIcos[0].swapRate) : 0 : 0} {detailInfo?.tokenInfo.symbol}</div>
                    </div>
                    {!isManualListing && (
                        <>
                            <div className="detail-text">
                                <div className="left">Tokens For Liquidity</div><div className="right text-black-green text-weight-500">{ new Intl.NumberFormat("ja-JP").format(maxLP)} {detailInfo?.tokenInfo.symbol}</div>
                            </div>
                            <div className="detail-text">
                                <div className="left">Listing Rate</div><div className="right text-black-green text-weight-500">1 {detailInfo?.roundIcos[0]?.currencyPay} = {detailInfo?.roundIcos.length > 0 && detailInfo?.roundIcos[0].listingRate ? new Intl.NumberFormat("ja-JP").format(detailInfo?.roundIcos[0].listingRate) : 0} {detailInfo?.tokenInfo.symbol}</div>
                            </div>
                        </>
                    )}

                    {detailInfo?.vestingContributor.firstRelease > 0 && (
                        <div className="detail-text">
                            <div className="left">First Release For Presale</div><div className="right text-black-green text-weight-500">{detailInfo?.vestingContributor.firstRelease ? detailInfo?.vestingContributor.firstRelease : 100}%</div>
                        </div>
                    )}
                    {detailInfo?.vestingContributor.tgeLock > 0 && (
                        <div className="detail-text">
                            <div className="left">Claim after listing</div><div className="right text-black-green text-weight-500">{detailInfo?.vestingContributor.tgeLock} minutes</div>
                        </div>
                    )}
                    {detailInfo?.vestingContributor.lockAfterTGE > 0 && (
                        <div className="detail-text">
                            <div className="left">Cliff after TGE</div><div className="right text-black-green text-weight-500">{detailInfo?.vestingContributor.lockAfterTGE} days</div>
                        </div>
                    )}
                    {detailInfo?.vestingContributor.tokenReleaseCycle > 0 && (
                        <div className="detail-text">
                            <div className="left">Vesting For Presale</div>
                            <div className="right text-black-green text-weight-500">
                                {detailInfo?.vestingContributor.tokenReleaseCycle}% each {detailInfo?.vestingContributor.vestingCycle} days
                                {/* {detailInfo?.vestingContributor.tokenReleaseCycle}% each {convertTimeByMint(detailInfo?.vestingContributor.vestingCycle)} */}
                            </div>
                        </div>
                    )}

                    <div className="detail-text">
                        <div className="left">Softcap</div>
                        <div className="right text-black-green text-weight-500">{new Intl.NumberFormat("ja-JP").format(detailInfo?.softCap)} {detailInfo?.roundIcos[0]?.currencyPay}</div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Hardcap</div>
                        <div className="right text-black-green text-weight-500">{new Intl.NumberFormat("ja-JP").format(detailInfo?.hardCap)} {detailInfo?.roundIcos[0]?.currencyPay}</div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Unsold Tokens</div>
                        <div className="right text-black-green text-weight-500">{detailInfo?.roundIcos[0]?.refundType}</div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Presale Start Time</div>
                        <div className="right text-black-green text-weight-500 text-uppercase">{detailInfo?.roundIcos[0]?.startTime ? convertDateTime(startDate) : ''}</div>
                    </div>
                    <div className="detail-text">
                        <div className="left">Presale End Time</div>
                        <div className="right text-black-green text-weight-500 text-uppercase">{detailInfo?.roundIcos[0]?.endTime ? convertDateTime(endDate) : ''}</div>
                    </div>

                    {!isManualListing && dex && (
                        <div className="detail-text">
                            <div className="left">Listing On</div><div className="right text-black-green text-weight-500">{dex.name}</div>
                        </div>
                    )}
                    {!isManualListing && (
                        <>
                            <div className="detail-text">
                                <div className="left">Liquidity Percent</div><div className="right text-black-green text-weight-500">{detailInfo?.roundIcos[0]?.liquidityPercent}%</div>
                            </div>
                            {parseInt(detailInfo?.liquidityLockup) > 0 && (
                                <div className="detail-text">
                                    <div className="left">Liquidity Lockup Time</div><div className="right text-black-green text-weight-500">{convertTimeByMint(parseInt(detailInfo?.liquidityLockup) * (24 * 60))} after pool ends</div>
                                </div>
                            )}
                        </>
                    )}


                    {isManualListing && (
                        <>
                            <div className="detail-text">
                                <div className="left">Liquidity Percent</div>
                                <div className="right text-black-green text-weight-500">(Manual listing)</div>
                            </div>

                        </>
                    )}



                </Card>
                <Card bordered={false} title="Token Metrics" className='card-ico-detail' style={{ width: '100%', marginTop: '20px' }}>
                    <MetrictChart isManualListing={isManualListing} detailInfo={detailInfo} maxLP={maxLP} />
                </Card>


            </div>
            <Modal
                title={<div className="text-md custom-modal-title">Confirm Cancel Pool</div>}
                visible={isSwitchChainModal}
                className="custom-modal create-token-modal"
                footer={false}
            >
                <div className="transer-box-content">
                    <p><Alert message="Confirm Cancel This Pool" type="warning" showIcon /></p>
                    <div className="modal-btn-group">
                        <button className="btn-basic" type="button" onClick={() => { }}>Confirm </button>
                        <button className="btn-basic-default" onClick={closeBox}>Close</button>
                    </div>


                </div>
            </Modal>
        </>
    )
}

export default DetailInfo
