import http from "./http-common";

const getCampaignsByStatus = (payload) => {
  return http.get("/campaigns/by-status", { params: payload });
};

const getCampaignByHandle = (payload) => {
  return http.get("/campaigns/by-handle", { params: payload });
}

const getCampaignsByCommited = (account, payload) => {
  return http.get(`/campaigns/by-committed/${account}`, { params: payload });
};

const getCampaignsByClaimable = (account, payload) => {
  return http.get(`/campaigns/by-claimable/${account}`, { params: payload });
};

const CampaignService = {
    getCampaignsByStatus,
    getCampaignByHandle,
    getCampaignsByCommited,
    getCampaignsByClaimable
};
export default CampaignService;