import React,{useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Wallet from '../common/wallet/wallet';
import {useSelector} from 'react-redux';
import Countdown from "react-countdown";
import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom"; 
import { useActiveWeb3React } from "../../hooks";
import { getNetworkObject } from '../../utils/campaignUltils';

const ItemINO = (props) => { 
    const {item}=props 
    const [countDown, setCountDown] = useState(null) 
    const {account} = useActiveWeb3React(); 
    let location = useLocation();
    let myPathname = location.pathname; 
    const sep = /\//;
    let isPathname = myPathname.split(sep); 
    const formatSellingTime = new Date (item?.sellingTime);
    const formatSellingTimePhase2 = new Date (item?.sellingTimePhase2); 
    const formatStartTime = new Date (item?.startTime);
    const formatEndTime = new Date (item?.endTime);

    const networkChain = getNetworkObject(item?.networkChain);
     
    useEffect(() => {
        if(isPathname[2] === "upcoming"){
            if(item?.sellingTimePhase2){
                setCountDown(formatSellingTimePhase2);
            } 
            else{ 
                setCountDown(formatStartTime);
            } 
        }
        if(isPathname[2] === "opening"){ 
            if(item?.sellingTimePhase2){
                setCountDown(formatEndTime); 
            }
            else{
                setCountDown(formatSellingTime);
            } 
        } 
    }, [item]);  
   
    const Completionist = () => <div></div>;  
    const renderer = ({days, hours, minutes, seconds, completed }) => {
        if (completed) {
          // Render a completed state
          return <></>;
        } else {
          // Render a countdown
          return (
            <div className='countdown'>
                <div className="flex"> 
                    <span>{days}</span> <span>:</span> 
                    <span>{hours}</span> <span>:</span> 
                    <span>{minutes}</span> <span>:</span> 
                    <span>{seconds}</span>  
                </div> 
                <div className="flex"> 
                    <p>Days</p>
                    <p>Hrs</p>
                    <p>Min</p>
                    <p>Sec</p>  
                </div> 
            </div>
          ); 
        }
    }; 

    return(
        <>

            <div style= {{ paddingBottom: account ?0 : "74px"}} className={`c-list__item bg`}>
                <Link to={`/ino/${isPathname[2]}/${item.handle}`}>
                    {/* <div className='c-list__tag'>
                        <img src="../images/hot.png" alt="" />
                        <span>Hot</span>
                    </div>  */}
                    <div className="c-list__img"> 
                        <img src={item.logoLink} alt="Special Giveaway" /> 
                    </div> 
                </Link> 
                <div className="c-list__body bg">
                    <div className='c-list-ino'>
                        <h3 className="c-list-ino__head">
                            <Link to={`/ino/${isPathname[2]}/${item.handle}`}>{item.title}</Link>  
                        </h3> 
                        {/* <span>
                            <img style={{width: '25px !important'}} src={networkChain.logo} alt={networkChain.name} />
                            {networkChain.name}
                        </span> */}
                    </div>  
                    <div className='c-list-ino__row'>
                        <span>Network: </span>
                        <span>
                            <img style={{width: '25px !important'}} src={networkChain.logo} alt={networkChain.name} />
                            {networkChain.name}
                        </span>
                    </div>
                    {/* <div className='c-list-ino__row'>
                        <span>Network: </span>
                        <span>
                            <img style={{width: '25px !important'}} src={networkChain.logo} alt={networkChain.name} />
                            {networkChain.name}
                        </span>
                    </div> */}
                    <div className='c-list-ino__row'>
                        {isPathname[2] === "upcoming"? <span>Sale start in</span>
                        : <span>Sale end in:</span>}  
                        <span></span>
                    </div> 
                        <div className='c-list-ino__center'>
                            <Countdown date={`${countDown}`} renderer={renderer}/>
                        </div>
                    <div> 
                    </div> 
                </div>
                <div className="c-list__footer btn-ino">
                    {!account ? <Wallet /> : null}           
                </div>   
            </div> 
        </>
    );  
} 
 

export default ItemINO;