import web3 from 'web3'
import BigNumber from 'bignumber.js'
import { getClaimRoundId } from '../../utils/campaignUltils';

const convertNumber = (value, tokenDecimals) => {
  return web3.utils.toHex(web3.utils.toWei(value, tokenDecimals));
}

export const convertToWei = (value, tokenDecimals) => {
  if(tokenDecimals === '1e8') {
    return new BigNumber(value).times(new BigNumber(10).pow(8)).toString();
  }else if(tokenDecimals === '1e10') {
    return new BigNumber(value).times(new BigNumber(10).pow(10)).toString();
  }
  else {
    return web3.utils.toWei(value.toString(), tokenDecimals);
  }
  
}

export const convertFromWei = (value, tokenDecimals) => {
  if(tokenDecimals === '1e8') {
    return Number(value) * 1e8;
  }else if(tokenDecimals === '1e10') {
    return Number(value) * 1e10;
  }
  else {
    return web3.utils.fromWei(value.toString(), tokenDecimals);
  }
}

export const sleepSystem = (s) => {
  return new Promise(resolve => setTimeout(resolve, s));
}

export const _joinPool = async (
  joinPoolContract,
  amountStr,
  whiteListId,
  roundId,
  signature,
  tokenDecimals,
  addressTokenJoin
) => {

  const amount = convertToWei(amountStr, tokenDecimals);
  const args = [amount, whiteListId, roundId, signature];

  if(addressTokenJoin == "0x0000000000000000000000000000000000000000"){
      return await joinPoolContract.join(...args, {value: amount});
  }else{
    return await joinPoolContract.join(...args);
  }

  
}

export const _withDrawToken = async (poolContract, amount, account) => {
  return new Promise((resolve, reject) => {
    try {
      const amountTemp = new BigNumber(amount).times(new BigNumber(10).pow(18)).toString();

      const args = [amountTemp];
      poolContract.methods
        .claim(...args)
        .estimateGas({ from: account }, function (error, result) {
          if (result) {
            poolContract.methods
              .claim(amountTemp)
              .send({ gas: result, from: account })
              .on('transactionHash', (tx) => {
                resolve(tx);
              })
              .catch((err) => {
                reject(err);
              })
          }
        })
        .catch((error) => {
          reject(error);
        })
    } catch (error) {
      reject(error);
    }
  })
}

export const _isClaimSC = async (poolContract, account) => {
  return poolContract.methods.users(account.toLowerCase()).call();
}

export const _isClaimedSC = async (poolContract, account) => {
  return poolContract.isClaimed(account.toLowerCase());
}

export const _totalClaimedSC = async (poolContract, account) => {
  return await poolContract.totalClaimed(account.toLowerCase());
}

export const _isJoinedSC = async (poolJoinContract, account, roundId, campaignId, oldVersion) => {
  if (oldVersion === true) {
    return poolJoinContract.isJoined(account.toLowerCase(), campaignId, roundId);
  }
  return poolJoinContract.isJoined(account.toLowerCase(), roundId);
}

export const _approveBUSD = async (contractBUSD, address, amount, tokenDecimals) => {
  const amountTemp = convertToWei(amount, tokenDecimals);
  const result = await contractBUSD.approve(address, amountTemp);
  return result;
}

export const _claimTokens = async (claimContract, claimItem, tokenDecimals, oldVersion) => {
  
  const amountTemp = convertToWei(claimItem.tokenAmount, tokenDecimals);

  let args;
  if (oldVersion === true) {
    args = [amountTemp, claimItem.claimRoundId, claimItem.signatureIDO];
  }
  else {
    const claimRoundId = getClaimRoundId(claimItem);
    args = [amountTemp, claimRoundId, claimItem.signatureIDO];
  }
  const gas = await claimContract.estimateGas.claimTokens(...args);
  return claimContract.claimTokens(...args, { gasLimit: gas });
}

export const _refund = async (claimContract, amountBusd, signBusd, tokenDecimals) => {
  const amount = convertToWei(amountBusd, tokenDecimals);
  const args = [amount, signBusd];
  const estimatedGas = await claimContract.estimateGas.refund(...args);
  return claimContract.refund(...args, {
    gasLimit: estimatedGas,
  });
}
export const _isRefundedSC = async (claimContract, account) => {
  return claimContract.userRefund(account.toLowerCase());
}

export const _totalDailyClaimedSC = async (claimContract, account) => {
  return claimContract.userInfo(account.toLowerCase());
}

export const _totalJoinedSC = async (contract) => {
  return contract.totalJoined();
}

export const _userClaimSC = async (claimContract, account, item, oldVersion) => {
  let args = [account.toLowerCase(), item.claimRoundId];
  return claimContract.userClaim(...args);
}

export const _userClaimRoundSC = async (claimContract, account, item, oldVersion) => {
  let args;
  args = [account.toLowerCase(), item.roundId];
  return claimContract.userClaim(...args);
}

export const _totalClaimAble = async (claimContract, account) => {
  return await claimContract.claimAble(account.toLowerCase());
}

export const _dailyClaimTokensSC = async (dailyClaimSC) => {
  const args = [];

  const estimatedGas = await dailyClaimSC.estimateGas.claimTokens(...args);
  return dailyClaimSC.claimTokens(...args, {
    gasLimit: estimatedGas,
  });
}

export const _totalRefundedSC = async (claimContract, account) => {
  return await claimContract.totalRefunded(account.toLowerCase());
}
