import IconMetamask from '../asset/images/icon-metamask.svg';
import IconBinance from '../asset/images/icon-binanceChain.svg';
import IconSafepal from '../asset/images/icon-safepal.svg';
import IconWalletConnect from '../asset/images/icon-walletConnect.svg';
import IconTrust from '../asset/images/icon-trust.svg';
import IconSafepalLight from '../asset/images/icon-safepalLight.svg';

const ListWallet = [
    {title: 'Meta Mask', icon: IconMetamask, connectorId: 'injected'},
    {title: 'Binance Chain Wallet', icon: IconBinance, connectorId: 'bsc'},
    {title: 'SafePal Wallet', icon: IconSafepal, connectorId: 'injected', status:"dark"},
    {title: 'SafePal Wallet', icon: IconSafepalLight, connectorId: 'injected', status:"light"},
    {title: 'Wallet Connect', icon: IconWalletConnect, connectorId: 'walletconnect'},
    {title: 'Trust Wallet', icon: IconTrust, connectorId: 'injected'},
];


export default ListWallet;