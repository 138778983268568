import React, {useEffect, useState, useCallback} from 'react';
import ItemINO from './itemIno';
import {useSelector, useDispatch} from 'react-redux';
import allActions from '../../actions';
import NodataImg from '../common/asset/images/nodata-upcoming.png';
import { Col, Pagination, Row, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from "../../hooks";
import { Loader, LoadingContent } from '../common/component';

const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

const OpeningIno = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [loadingPage, setLoadingPage] = useState(false);
    let size = 6;  

    useEffect(() => { 
        setLoadingPage(true)
        dispatch(allActions.inoAction.getIno({
        filterEnum: "OPENING",
        networkChainEnum: "BSC",  
    }, (res)=> {
        if(res.callResult === true) {
            setLoadingPage(false);
        }
    })); 
    }, [dispatch]); 

    function onChangePaging(pageNumber) {
        setPage(pageNumber - 1);
    } 
    const inoData = useSelector(state => state.inoData.inoList); 
    
    const ItemINOList = inoData && inoData.map((item,key) => (
        <>
            <Col xs={24} sm={8} xl={8} md={8}>
                <ItemINO key={key} item={item}/>
            </Col>
        </>
        
    )); 
    return(
        <> 
            <div className="c-list">
                {loadingPage ?<Loader /> : (
                <>
                {inoData && inoData.length > 0 ? 
                <>
                    <Row gutter={30}>{ItemINOList}</Row>
                </> : 
                (<div className="c-nodata c-nodata--upcoming">
                        <img src={NodataImg} alt="No Data" />
                        <p>No data</p>
                    </div>)}
                </>)}
            </div>
            {!loadingPage && <div className="pani-list">
                {inoData && inoData.length > 0 && 
                <Pagination
                    defaultCurrent={1}
                    // total={campaignsCount}
                    pageSize = {size}
                    onChange={onChangePaging}
                    showSizeChanger={false}
                />}
            </div>}
        </>
    );
};

export default OpeningIno;