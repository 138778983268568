import { 
    GET_INO_LIST,
    GET_INO_LIST_BY_ADDRESS,
    GET_INO_DETAIL,
    GET_BOXES_BY_ID,
    GET_TOTAL_OWNER_BOX
} from "../actions/inoType";

const initialState = [];

function inoReducer(ino = initialState, action) {
    const { type, payload } = action; 
    switch (type) {
        case GET_INO_LIST:
            return {...ino, inoList:payload } 
        case GET_INO_LIST_BY_ADDRESS:
            return {...ino, inoListByAddress:payload }
        case GET_INO_DETAIL:
            return {...ino, inoDetail:payload }
        case GET_BOXES_BY_ID:
            return {...ino, inoBoxes: payload }
        case GET_TOTAL_OWNER_BOX:
            return {...ino, totalOwnerBox: ino.totalOwnerBox+payload } 
        default:
            return ino;
    }
};

export default inoReducer;