import { Empty, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import { NftApi } from "../../../config/api/apiNFT";
import TimeAgo from "../../../config/timeAgo";
import { AUCTION_ADDRESS_SC, MARKET_ADDRESS_SC } from "../../../constants";

import { useActiveWeb3React } from "../../../hooks";
import { useAutionNFTContract, useContract, useMarketNFTContract } from "../../../hooks/useContract";
import addNotify from "../../common/Notify/addNotify";
import { _claimNFT, _claimToken } from "../utilsNFT";
import { Loader } from "../../common/component";

const OfferItem = (props) => {
    const { account, chainId } = useActiveWeb3React();
    const { data, index, item, endAuction, currentBidOwner, currentBidPrice, auctionIndex, auctionStatus } = props;
    const [loadingBuy, setLoadingBuy] = useState(false);

    let date = new Date(item?.createdDate);
    let nowDate = new Date(Date.now());
    let endDate = new Date(endAuction * 1000);
    let finishDate = new Date(data?.marketplaceDetails?.finishTime);

    const MARKET_ADDRESS = MARKET_ADDRESS_SC;
    const AUCTION_ADDRESS = AUCTION_ADDRESS_SC;

    const contractBid = useAutionNFTContract(AUCTION_ADDRESS);

    const onConfirmClaimNFT = async (currentBidOwner)=>{
        
        setLoadingBuy(true);

        if (account.toLowerCase() !== currentBidOwner.toLowerCase()) {
            Store.addNotification(addNotify('Invalid offfer address.', 'danger'));
            setLoadingBuy(false);
            return;
        }
        if (!contractBid) {
            Store.addNotification(addNotify('Contract invalid.', 'danger'));
            setLoadingBuy(false);
            return;
        }
        if (Number(data?.chainId) !== chainId) {
            Store.addNotification(addNotify('Invalid chain.', 'danger'));
            setLoadingBuy(false);
            return;
        }
        if (!data?.nftId) {
            Store.addNotification(addNotify('Nft not found.', 'danger'));
            setLoadingBuy(false);
            return;
        }

        await _claimNFT(contractBid , Number(data?.nftId))
        .then((res) => {
            let resWait = res.wait();
            resWait.then(async (resTransaction) => {

                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {

                    
                    let payload = {
                        "claimHash": res.hash,
                        "id": data?.id,
                        "ownerAddress": currentBidOwner.toLowerCase(),
                        "orderLogId": item?.id,
                        "nftId": data?.nftId,
                    };
                    await NftApi.claimNft(data?.id, payload).then((resAp)=>{
                        if (resAp) {
                            Store.addNotification(addNotify('Claim NFT successfully', 'success'));
                            setLoadingBuy(false);

                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                        }
                    }).catch((err)=>{
                        Store.addNotification(addNotify(err, 'danger'));
                        setLoadingBuy(false);
                    })
                    
                }
                else {
                    setLoadingBuy(false);
                    Store.addNotification(addNotify('Transaction failed', 'warning'));
                    return;
                }

            })
            .catch((error) => {
                setLoadingBuy(false);
                Store.addNotification(addNotify(error?.message ? error?.message : 'Server Error !!!', 'danger'));
            });
        })
        .catch((error) => {
            setLoadingBuy(false);
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        });
    }

    const onConfirmClaimToken = async ()=>{
        
        setLoadingBuy(true);

        
        if (account.toLowerCase() !== data?.ownerAddress.toLowerCase()) {
            Store.addNotification(addNotify('You are not owner.', 'danger'));
            setLoadingBuy(false);
            return;
        }
        if (!contractBid) {
            Store.addNotification(addNotify('Contract invalid.', 'danger'));
            setLoadingBuy(false);
            return;
        }
        if (Number(data?.chainId) !== chainId) {
            Store.addNotification(addNotify('Invalid chain.', 'danger'));
            setLoadingBuy(false);
            return;
        }
        if (!data?.nftId) {
            Store.addNotification(addNotify('Nft not found.', 'danger'));
            setLoadingBuy(false);
            return;
        }

        await _claimToken(contractBid , Number(data?.nftId))
        .then((res) => {
            let resWait = res.wait();
            resWait.then(async (resTransaction) => {

                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                        
                    let payload = {
                        "claimHash": res.hash,
                        "id": data?.id,
                        "ownerAddress": currentBidOwner.toLowerCase(),
                        "nftId": data?.nftId,
                        "orderLogId": item?.id,
                    };

                    // console.log('res.hash', res.hash);
                    // console.log('data?.id', data?.id);
                    // console.log('payload', payload);

                    await NftApi.claimNft(data?.id, payload).then((resAp)=>{
                        if (resAp) {
                            Store.addNotification(addNotify('Claim token successfully', 'success'));
                            setLoadingBuy(false);

                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                        }
                    }).catch((err)=>{
                        Store.addNotification(addNotify(err?.message ? err?.message : 'Unknow Error', 'danger'));
                        setLoadingBuy(false);
                    })
                    
                }
                else {
                    setLoadingBuy(false);
                    Store.addNotification(addNotify('Transaction failed', 'warning'));
                    return;
                }

            })
            .catch((error) => {
                setLoadingBuy(false);
                Store.addNotification(addNotify(error?.message ? error?.message : 'Server Error !!!', 'danger'));
            });
        })
        .catch((error) => {
            setLoadingBuy(false);
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        });
    }

    // const onConfirmCancel = async (auctionIndex, currentBidOwner)=>{
        
    // }

    return (
        <>
            <tr key={`log-${index}`}>
                <td>
                    <span>{Number(item?.price).toLocaleString()}</span> HLUP
                </td>

                <td>--</td>
                <td>
                    <TimeAgo timestamp={(date.getTime() / 1000)} />
                </td>
            
                <td>
                    {item?.offerBy ? (
                        <>
                            {item?.offerBy.substring(0, 4)}...{item?.offerBy.substring(item?.offerBy.length - 4)}
                        </>
                    ):(
                        <>___</>
                    )}
                </td>
                <td align="center">
                    <>
                        {nowDate > finishDate ? (
                            <>
                                {auctionStatus === 2 ? (
                                    <Tag color="green">Sold</Tag>
                                ):(
                                    <>
                                        {item?.offerBy && (
                                            <>
                                                {Number(item?.price) === currentBidPrice && account.toLowerCase() === data?.ownerAddress.toLowerCase() && (
                                                    <button disabled={!account || loadingBuy} className="btn-basic w-fitcontent" onClick={()=>onConfirmClaimToken()}>Claim HLUP {loadingBuy && <Spin size="small" />}</button>
                                                )}
                                                {Number(item?.price) === currentBidPrice && account.toLowerCase() === item?.offerBy.toLowerCase() && account.toLowerCase() !== data?.ownerAddress.toLowerCase() && (
                                                    <button disabled={!account || loadingBuy} className="btn-basic w-fitcontent" onClick={()=>onConfirmClaimNFT(currentBidOwner)}>Claim NFT {loadingBuy && <Spin size="small" />}</button>
                                                )}
                                                {currentBidPrice >0 && Number(item?.price) !== currentBidPrice && currentBidOwner.toLowerCase() !== item?.offerBy.toLowerCase() && (
                                                    <Tag color="orange">Refunded</Tag>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        ):(
                            <>
                                {/* {account && item?.offerBy && account.toLowerCase() === item?.offerBy.toLowerCase() ? (
                                    <>
                                        <button disabled={!account || loadingBuy} className="btn-basic-default w-fitcontent" onClick={()=>onConfirmCancel(auctionIndex, currentBidOwner)}>Cancel {loadingBuy && <Spin size="small" />}</button>
                                    </>
                                ):(
                                    <>N/A</>
                                )} */}
                                
                            </>
                        )}
                    </>
                    
                </td>
            </tr>
            {loadingBuy && (<Loader />)}
        </>
    )
}
export default OfferItem
