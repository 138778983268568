import React, { useState, useEffect } from "react";
import { Input, Select, message, Spin, InputNumber, Skeleton } from "antd";
import { CaretDownOutlined, LoadingOutlined } from "@ant-design/icons";
import AddLiqV2Details from "./AddLiqV2Details";
import Loading from "../../Loading";
import {
    notiError,
    notiSuccess,
    notiWarning,
} from "../../../utils/notication";
import Web3 from "web3";
import { SC_ADD_LP } from "../../../constants";
import checkBalanceInfo from "../../../utils/checkBalanceInfo";
import tokenABI from "../../../constants/abi/abiTokenDefault.json";
import addV2 from "../../../constants/abi/addV2.json";
import { AbiItem } from "web3-utils";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
    convertFromWei,
    convertToWei,
} from "../../../utils/convertNumber";
import ChainNotSupport from "../../chain-not-support"
import { isMobile } from "react-device-detect";
import { useNavigate, useLocation } from "react-router-dom";
import { formatNum } from "../../../utils/formatNumber";
import { checkPriceToken } from "../../../utils/checkPriceToken_V2";
import "../style.css"
import { useActiveWeb3React } from "../../../hooks";
import FooterHome from "../../common/footer/footerHome";

const LiquidityV2 = () => {
    const { account , chainId } = useActiveWeb3React();
    const { Option } = Select;
    const [contractTokenB, setContractTokenB] = useState("");
    const [contractTokenA, setContractTokenA] = useState("");
    const web3 = new Web3(window.ethereum);
    const [symBolTokenA, setSymbolTokenA] = useState("");
    const [symBolTokenB, setSymbolTokenB] = useState("");
    const [balanceA, setBalanceA] = useState("");
    const [balanceB, setBalanceB] = useState("");
    const [balanceTokenA, setBalanceTokenA] = useState("");
    const [balanceTokenB, setBalanceTokenB] = useState("");
    const contractA = new web3.eth.Contract(
        tokenABI,
        contractTokenA
    );
    const contractB = new web3.eth.Contract(
        tokenABI,
        contractTokenB
    );
    const contract = new web3.eth.Contract(
        addV2,
        SC_ADD_LP[chainId]
    );
    const [valueTo, setValueTo] = useState(0);
    const [valueForm, setValueForm] = useState(0);
    const [showDetail, setShowDetail] = useState(false);
    const [transferTokenA, setTransferTokenA] = useState(false);
    const [approveTokenA, setApproveTokenA] = useState(false);
    const [approveTokenB, setApproveTokenB] = useState(false);
    const [transferTokenB, setTransferTokenB] = useState(false);
    const [allowanceA, setAllowanceA] = useState(false);
    const [allowanceB, setAllowanceB] = useState(false);
    const [amountA, setAmountA] = useState(0);

    const [approveLoadingA, setApproveLoadingA] = useState(false);
    const [approveLoadingB, setApproveLoadingB] = useState(false);

    const [amountB, setAmountB] = useState(0);
    const [isWarning, setWarning] = useState(false);
    const [isCheckPrice, setCheckPrice] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();
    const onChangeValueTo = (value) => {
        setValueTo(value);
    };
    const onChangeValueForm = (value) => {
        setValueForm(value);
    };

    const handleRidirect = () => {
        setShowDetail(!showDetail);
        navigate(`${"/add-liquidity?V2?step-2"}`);
    };

    const pathTabsLive = location.search.split("?");
    const tabStep = pathTabsLive[2] === undefined ? "null" : pathTabsLive[2];

    const handleChangeNework = (value) => {
        setContractTokenB(value);
    };


    const checkInfoTokenA = async () => {
        try {
            if (contractTokenA) {
                await checkBalanceInfo(contractTokenA, SC_ADD_LP[chainId]).then(
                    (res) => {
                        setSymbolTokenA(res.symbol);
                        setBalanceA(res.balanceOf);
                    }
                );
                if (account) {
                    const decimals = await contractA.methods.decimals().call();
                    const balanceTokenByAccount = await contractA.methods
                        .balanceOf(account)
                        .call();
                    const balanceFromWei = convertFromWei(
                        balanceTokenByAccount.toString(),
                        Number(decimals)
                    );
                    setBalanceTokenA(balanceFromWei);
                }
            }
        } catch (error) {
            console.error("error check token A", error);
        }
    };
    useEffect(() => {
        if (contractTokenA) {
            checkInfoTokenA();
        }
    }, [contractTokenA, account]);

    const checkInfoTokenB = async () => {
        try {
            if (contractTokenB) {
                await checkBalanceInfo(contractTokenB, SC_ADD_LP[chainId]).then(
                    (res) => {
                        setSymbolTokenB(res.symbol);
                        setBalanceB(res.balanceOf);
                    }
                );
                if (account) {
                    const decimals = await contractB.methods.decimals().call();
                    const balanceTokenByAccount = await contractB.methods
                        .balanceOf(account)
                        .call();
                    const balanceFromWei = convertFromWei(
                        balanceTokenByAccount.toString(),
                        Number(decimals)
                    );
                    setBalanceTokenB(balanceFromWei);
                }
            }
        } catch (error) {
            console.error("error check token B", error);
        }
    };

    useEffect(() => {
        if (contractTokenB) {
            checkInfoTokenB();
        }
    }, [contractTokenB, account]);

    const handleMaxA = () => {
        setValueTo(balanceTokenA);
    };

    const handleMaxB = () => {
        setValueForm(balanceTokenB);
    };

    const handleApproveTokenA = async () => {
        const gasPrice = await web3.eth.getGasPrice();
        try {
            setApproveTokenA(true);
            const decimals = await contractA.methods.decimals().call();
            console.log('decimals', decimals);
            const valueToToWei = convertToWei(
                valueTo.toString(),
                Number(decimals)
            );
            await contractA.methods
                .approve(SC_ADD_LP[chainId], valueToToWei)
                .send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
                .then((res) => {
                    if (res.status) {
                        notiSuccess("Success", 5);
                        setApproveTokenA(false);
                        checkInfoTokenA();
                        checkApproveTokenA();
                        setValueTo(0);
                    }
                });
        } catch (error) {
            setApproveTokenA(false);
            notiError(error?.message, 5);
        }
    };

    const checkApproveTokenA = async () => {
        try {
            if (contractTokenA) {
                const decimals = await contractA.methods.decimals().call();
                await contractA.methods
                    .allowance(account, SC_ADD_LP[chainId])
                    .call()
                    .then((res) => {
                        const xx = convertFromWei(res, Number(decimals));
                        if (Number(valueTo) <= Number(xx) && Number(xx !== 0)) {
                            setAllowanceA(true);
                        } else {
                            setAllowanceA(false);
                        }
                    });
            }
        } catch (error) {
            console.error("error check token A", error);
        }
    };
    useEffect(() => {
        checkApproveTokenA();
    }, [account, contractTokenA, valueTo]);

    const handleApproveTokenB = async () => {
        const gasPrice = await web3.eth.getGasPrice();
        try {
            setApproveTokenB(true);
            const decimals = await contractB.methods.decimals().call();
            const valueFormToWei = convertToWei(
                valueForm.toString(),
                Number(decimals)
            );
            await contractB.methods
                .approve(SC_ADD_LP[chainId], valueFormToWei)
                .send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
                .then((res) => {
                    if (res.status) {
                        notiSuccess("Success", 5);
                        setApproveTokenB(false);
                        checkInfoTokenB();
                        checkApproveTokenB();
                        setValueForm(0);
                    }
                });
        } catch (error) {
            setApproveTokenB(false);
            notiError(error?.message, 5);
        }
    };

    const checkApproveTokenB = async () => {
        try {
            if (contractTokenB) {
                const decimals = await contractB.methods.decimals().call();
                await contractB.methods
                    .allowance(account, SC_ADD_LP[chainId])
                    .call()
                    .then((res) => {
                        const xx = convertFromWei(res, Number(decimals));
                        if (Number(valueForm) <= Number(xx) && Number(xx !== 0)) {
                            setAllowanceB(true);
                        } else {
                            setAllowanceB(false);
                        }
                    });
            }
        } catch (error) {
            console.error("error check token A", error);
        }
    };

    useEffect(() => {
        checkApproveTokenB();
    }, [account, contractTokenB, valueForm]);

    const handelTransferTokenA = async () => {
        const gasPrice = await web3.eth.getGasPrice();
        try {
            setTransferTokenA(true);
            const decimals = await contractA.methods.decimals().call();
            const valueToToWei = convertToWei(
                valueTo.toString(),
                Number(decimals)
            );
            // console.log('decimals', decimals);
            // console.log('valueToToWei', valueToToWei);
            // console.log('web3.utils.toHex(String(gasPrice))', web3.utils.toHex(String(gasPrice)));
            await contract.methods
                .depositToken1(contractTokenA, valueToToWei)
                .send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
                .then((res) => {
                    if (res.status) {
                        notiSuccess("Success", 5);
                        setTransferTokenA(false);
                        checkInfoTokenA();
                        setValueTo(0);
                        checkAmountA();
                    }
                });
        } catch (error) {
            setTransferTokenA(false);
            notiError(error?.message, 5);
        }
    };


    const handelTransferTokenB = async () => {
        const gasPrice = await web3.eth.getGasPrice();
        try {
            setTransferTokenB(true);
            const decimals = await contractB.methods.decimals().call();
            const valueToToWei = convertToWei(
                valueForm.toString(),
                Number(decimals)
            );
            
            await contract.methods
                .depositBaseToken(contractTokenB, valueToToWei)
                .send({ from: account, gasPrice: web3.utils.toHex(String(gasPrice)) })
                .then((res) => {
                    if (res.status) {
                        notiSuccess("Success", 5);
                        setTransferTokenB(false);
                        checkInfoTokenB();
                        setValueForm(0);
                        checkAmountB();
                    }
                });
        } catch (error ) {
            setTransferTokenB(false);
            notiError(error?.message, 5);
        }
    };

    const checkAmountA = async () => {
        try {
            if (contractTokenA) {
                const decimals = await contractA.methods.decimals().call();
                await contract.methods
                    .userToken1s(account, contractTokenA)
                    .call()
                    .then((res ) => {
                        const valueFromWei  = convertFromWei(
                            res.token1Amount.toString(),
                            Number(decimals)
                        );
                        setAmountA(valueFromWei);
                    }).catch((err) => {
                        console.log('err', err)
                    })
            }
        } catch (error) {
            console.error("error check token A", error);
        }
    };


    const checkAmountB = async () => {
        try {
            if (contractTokenB) {
                const decimals = await contractB.methods.decimals().call();
                await contract.methods
                    .userBaseTokens(account, contractTokenB)
                    .call()
                    .then((res ) => {
                        const valueFromWei  = convertFromWei(
                            res.baseTkAmount.toString(),
                            Number(decimals)
                        );
                        console.log('valueFromWei', valueFromWei)
                        setAmountB(valueFromWei);
                    });
            }
        } catch (error) {
            console.error("error check token A", error);
        }
    };

    useEffect(() => {
        checkAmountA();
        checkAmountB();
    }, [account, contractTokenA, contractTokenB, amountA, amountB]);

    const renderImagesBEP20 = (address ) => {
        switch (address) {
            case "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c":
                return <img src="../images/bot/bnb.png" alt="" />;
                break;
            case "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56":
                return <img src="../images/bot/busd.webp" alt="" />;
                break;
            case "0x55d398326f99059fF775485246999027B3197955":
                return <img src="../images/bot/usdt.png" alt="" />;
                break;
            case "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd":
                return <img src="../images/bot/usdt.png" alt="" />;
                break;
            default:
                return "--";
                break;
        }
    };

    const renderImagesETH = (address ) => {
        switch (address) {
            case "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2":
                return <img src="../images/bot/eth.png" alt="" />;
                break;
            case "0xdAC17F958D2ee523a2206206994597C13D831ec7":
                return <img src="../images/bot/usdt.png" alt="" />;
                break;
            case "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48":
                return <img src="../images/bot/usdc.png" alt="" />;
                break;
            default:
                return "--";
                break;
        }
    };

    const renderImagesARB = (address) => {
        switch (address) {
            case "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1":
                return <img src="../images/bot/eth.png" alt="" />;
                break;
            case "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9":
                return <img src="../images/bot/usdt.png" alt="" />;
                break;
            case "0xaf88d065e77c8cC2239327C5EDb3A432268e5831":
                return <img src="../images/bot/usdc.png" alt="" />;
                break;
            default:
                return "--";
                break;
        }
    };


    const checkPrice = async () => {
        try {
            setWarning(true);
            await checkPriceToken(contractTokenA, chainId).then((res) => {
                setCheckPrice(res);
                setWarning(false);
            });
        } catch (error) {
            setWarning(false);
            console.error("error check pair", error);
        }
    };
    useEffect(() => {
        checkPrice();
    }, [contractTokenA, chainId]);

    return (
        <>
            {chainId !== undefined ? (
                <>
                    {chainId === 56 || chainId === 1 || chainId === 42161 || chainId === 97 ? (
                        <>
                            <div className="section-add-liquidity">
                                <div className="">
                                    {(!showDetail && tabStep !== "step-2") ||
                                        tabStep === "step-1" ||
                                        tabStep === null ? (
                                        <>
                                            <div className="group-add-liquidity">
                                                <div className="breacrum">
                                                    <div className="item active">
                                                        <span className="number">1</span> Set up infomation
                                                        LP
                                                    </div>
                                                    <div className="item">
                                                        <span className="number">2</span> Add liquidity V2
                                                    </div>
                                                </div>
                                                <div className="group-row">
                                                    <div className="title">Set up infomation LP</div>
                                                    <div className="group-dex">
                                                        <div className="item-master">
                                                            <div className="name">
                                                                {!isMobile ? (
                                                                    <>Master Contract Management Address:</>
                                                                ) : (
                                                                    <>Contract:</>
                                                                )}
                                                            </div>
                                                            <div className="row-smart-contract">
                                                                <span className="sc-contract">
                                                                    {SC_ADD_LP[chainId]?.slice(0, 6)}...
                                                                    {SC_ADD_LP[chainId]?.slice(-6)}
                                                                </span>
                                                                <CopyToClipboard
                                                                    text={SC_ADD_LP[chainId]}
                                                                    onCopy={() => {
                                                                        notiSuccess("Copied", 5);
                                                                    }}
                                                                >
                                                                    <span className="icon">
                                                                        <img src="../images/copied.png" alt="" />
                                                                    </span>
                                                                </CopyToClipboard>
                                                            </div>
                                                        </div>
                                                        <div className="item-master">
                                                            <div className="name">
                                                                Select Dex:
                                                            </div>
                                                            <div className="select-fiter">
                                                                <Select
                                                                    value={chainId === 56 || chainId === 97 ? "pancake" : chainId === 1 ? "uni" : "uni"}
                                                                    suffixIcon={
                                                                        <CaretDownOutlined rev={undefined} />
                                                                    }
                                                                >
                                                                    {chainId === 56 || chainId === 97 ?
                                                                        <>
                                                                            <Option value="pancake">
                                                                                <img src="../images/cake-1.png" alt="" /> {" "} <span className="text-chain">Pancakeswap V2</span>
                                                                            </Option>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {chainId === 1 ?
                                                                                <>
                                                                                    <Option value="uni">
                                                                                        <img src="../images/uni-hihi.png" alt="" /> {" "} <span className="text-chain">Uniswap V3</span>
                                                                                    </Option>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <Option value="uni">
                                                                                        <img src="../images/uni-hihi.png" alt="" /> {" "} <span className="text-chain">Uniswap V3</span>
                                                                                    </Option>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </Select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row-select-pair mar-top-20">
                                                        <div className="title">Select pair</div>
                                                        <div className="guide-wrap">
                                                            <div className="left">
                                                                <div className="item-form res">
                                                                    <div className="input-round">
                                                                        <Input
                                                                            placeholder="Token address"
                                                                            value={contractTokenA}
                                                                            onChange={(e) =>
                                                                                web3.utils.isAddress(e.target.value)
                                                                                    ? setContractTokenA(e.target.value)
                                                                                    : setContractTokenA("")
                                                                            }
                                                                            className="form-control w-100"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="center">
                                                                <img src="../images/plus.png" alt="" />
                                                            </div>
                                                            <div className="right">
                                                                <div className="item-form res">
                                                                    <div className="input-round">
                                                                        <div className="select-fiter w-12">
                                                                            <Select
                                                                                value={
                                                                                    contractTokenB === ""
                                                                                        ? "Select a token"
                                                                                        : contractTokenB
                                                                                }
                                                                                className="w-12"
                                                                                onChange={handleChangeNework}
                                                                                suffixIcon={
                                                                                    <CaretDownOutlined rev={undefined} />
                                                                                }
                                                                            >
                                                                                {chainId === 97 && (
                                                                                    <>
                                                                                        <Option value="0x337610d27c682E347C9cD60BD4b3b107C9d34dDd">
                                                                                            <img
                                                                                                src="../images/usdt.png"
                                                                                                alt=""
                                                                                            />{" "}
                                                                                            <span className="text-chain">
                                                                                                USDT
                                                                                            </span>
                                                                                        </Option>
                                                                                    </>
                                                                                )}
                                                                                {chainId === 56 ? (
                                                                                    <>
                                                                                        <Option value="0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c">
                                                                                            <img
                                                                                                src="../images/bnb.png"
                                                                                                alt=""
                                                                                            />{" "}
                                                                                            <span className="text-chain">
                                                                                                WBNB
                                                                                            </span>
                                                                                        </Option>
                                                                                        {/* <Option value="0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56">
                                                                                            <img
                                                                                                src="../images/busd.webp"
                                                                                                alt=""
                                                                                            />{" "}
                                                                                            <span className="text-chain">
                                                                                                BUSD
                                                                                            </span>
                                                                                        </Option> */}
                                                                                        <Option value="0x55d398326f99059fF775485246999027B3197955">
                                                                                            <img
                                                                                                src="../images/usdt.png"
                                                                                                alt=""
                                                                                            />{" "}
                                                                                            <span className="text-chain">
                                                                                                USDT
                                                                                            </span>
                                                                                        </Option>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {chainId === 1 ? (
                                                                                            <>
                                                                                                <Option value="0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2">
                                                                                                    <img
                                                                                                        src="../images/eth.png"
                                                                                                        alt=""
                                                                                                    />{" "}
                                                                                                    <span className="text-chain">
                                                                                                        WETH
                                                                                                    </span>
                                                                                                </Option>
                                                                                                <Option value="0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48">
                                                                                                    <img
                                                                                                        src="../images/usdc.png"
                                                                                                        alt=""
                                                                                                    />{" "}
                                                                                                    <span className="text-chain">
                                                                                                        USDC
                                                                                                    </span>
                                                                                                </Option>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {chainId != 97 && (
                                                                                                    <>
                                                                                                        <Option value="0x82aF49447D8a07e3bd95BD0d56f35241523fBab1">
                                                                                                            <img
                                                                                                                src="../images/eth.png"
                                                                                                                alt=""
                                                                                                            />{" "}
                                                                                                            <span className="text-chain">
                                                                                                                WETH
                                                                                                            </span>
                                                                                                        </Option>
                                                                                                        <Option value="0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9">
                                                                                                            <img
                                                                                                                src="../images/usdt.png"
                                                                                                                alt=""
                                                                                                            />{" "}
                                                                                                            <span className="text-chain">
                                                                                                                USDT
                                                                                                            </span>
                                                                                                        </Option>
                                                                                                    </>
                                                                                                )}
                                                                                                
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </Select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {isWarning ? (
                                                        <>
                                                            <Skeleton active />
                                                        </>
                                                    ) : (
                                                        <>
                                                            {(contractTokenB && contractTokenA) && (
                                                                <>
                                                                    {isCheckPrice ? (
                                                                        <>
                                                                            <div
                                                                                className="section-success-wallet"
                                                                                style={{
                                                                                    background: "#1c1f25",
                                                                                    padding: "40px 20px",
                                                                                    borderRadius: "6px",
                                                                                }}
                                                                            >
                                                                                <div className="title">
                                                                                    {symBolTokenA} token's liquidity existed
                                                                                </div>
                                                                                <div className="desc">
                                                                                    Please check or enter a new token contract
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {" "}
                                                                            <div className="row-deposit-amount mar-top-20">
                                                                                <div className="title">
                                                                                    Deposit Amounts
                                                                                </div>
                                                                                <div className="box-amount-deposit">
                                                                                    <div className="top">
                                                                                        <div className="name-token">
                                                                                            {chainId === 56 || chainId === 97 ? (
                                                                                                <img
                                                                                                    src="../images/bnb.png"
                                                                                                    alt=""
                                                                                                />
                                                                                            ) : chainId === 1 ? (
                                                                                                <img
                                                                                                    src="../images/eth.png"
                                                                                                    alt=""
                                                                                                />
                                                                                            ) : (
                                                                                                <img
                                                                                                    src="../images/arb.png"
                                                                                                    alt=""
                                                                                                />
                                                                                            )}{" "}
                                                                                            {symBolTokenA}
                                                                                        </div>
                                                                                        <div className="balance-token">
                                                                                            Balance:{" "}
                                                                                            <span>
                                                                                                {formatNum(
                                                                                                    Number(balanceTokenA).toFixed(5)
                                                                                                )}{" "}
                                                                                                {/* {Number(balanceTokenA).toFixed(0)}{" "} */}
                                                                                                {symBolTokenA}
                                                                                            </span>{" "}
                                                                                            <button
                                                                                                onClick={handleMaxA}
                                                                                                className="btn-max"
                                                                                            >
                                                                                                Max
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="bottom">
                                                                                        <div className="balance-input">
                                                                                            <InputNumber
                                                                                                min={0}
                                                                                                max={balanceTokenA}
                                                                                                onChange={onChangeValueTo}
                                                                                                value={valueTo}
                                                                                                className="input-balance"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="direct-input">
                                                                                            {valueTo ? (
                                                                                                <>
                                                                                                    {allowanceA ? (
                                                                                                        <button
                                                                                                            disabled={transferTokenA}
                                                                                                            className="btn-add w-180 click-now"
                                                                                                            onClick={
                                                                                                                handelTransferTokenA
                                                                                                            }
                                                                                                        >
                                                                                                            <Loading
                                                                                                                status={transferTokenA}
                                                                                                                content="Transfer"
                                                                                                            />
                                                                                                        </button>
                                                                                                    ) : (
                                                                                                        <button
                                                                                                            disabled={
                                                                                                                !valueTo ||
                                                                                                                approveTokenA
                                                                                                            }
                                                                                                            className="btn-add w-180 click-now"
                                                                                                            onClick={
                                                                                                                handleApproveTokenA
                                                                                                            }
                                                                                                        >
                                                                                                            <Loading
                                                                                                                status={approveTokenA}
                                                                                                                content="Approve"
                                                                                                            />
                                                                                                        </button>
                                                                                                    )}
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <button className="btn-add w-180">
                                                                                                        Enter an amount
                                                                                                    </button>
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="box-amount-deposit mar-top-20">
                                                                                    <div className="top">
                                                                                        <div className="name-token">
                                                                                            {chainId === 56 || chainId === 97
                                                                                                ? renderImagesBEP20(
                                                                                                    contractTokenB
                                                                                                )
                                                                                                : chainId === 1
                                                                                                    ? renderImagesETH(
                                                                                                        contractTokenB
                                                                                                    )
                                                                                                    : renderImagesARB(
                                                                                                        contractTokenB
                                                                                                    )}{" "}
                                                                                            {symBolTokenB}
                                                                                        </div>
                                                                                        <div className="balance-token">
                                                                                            Balance:{" "}
                                                                                            <span>
                                                                                                {formatNum(
                                                                                                    Number(balanceTokenB).toFixed(
                                                                                                        50
                                                                                                    )
                                                                                                )}{" "}
                                                                                                {symBolTokenB}
                                                                                                {/* {Number(balanceTokenB)} {symBolTokenB} */}
                                                                                            </span>{" "}
                                                                                            <button
                                                                                                onClick={handleMaxB}
                                                                                                className="btn-max"
                                                                                            >
                                                                                                Max
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="bottom">
                                                                                        <div className="balance-input">
                                                                                            <InputNumber
                                                                                                min={0}
                                                                                                max={balanceTokenB}
                                                                                                value={valueForm}
                                                                                                onChange={onChangeValueForm}
                                                                                                className="input-balance"
                                                                                            />
                                                                                        </div>
                                                                                        <div className="direct-input">
                                                                                            {valueForm ? (
                                                                                                <>
                                                                                                    {allowanceB ? (
                                                                                                        <button
                                                                                                            disabled={transferTokenB}
                                                                                                            className="btn-add w-180 click-now"
                                                                                                            onClick={
                                                                                                                handelTransferTokenB
                                                                                                            }
                                                                                                        >
                                                                                                            <Loading
                                                                                                                status={transferTokenB}
                                                                                                                content="Transfer"
                                                                                                            />
                                                                                                        </button>
                                                                                                    ) : (
                                                                                                        <button
                                                                                                            disabled={
                                                                                                                !valueForm ||
                                                                                                                approveTokenB
                                                                                                            }
                                                                                                            className="btn-add w-180 click-now"
                                                                                                            onClick={
                                                                                                                handleApproveTokenB
                                                                                                            }
                                                                                                        >
                                                                                                            <Loading
                                                                                                                status={approveTokenB}
                                                                                                                content="Approve"
                                                                                                            />
                                                                                                        </button>
                                                                                                    )}
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <button className="btn-add w-180">
                                                                                                        Enter an amount
                                                                                                    </button>
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="group-btn mar-top-30">
                                                                                <button
                                                                                    disabled={
                                                                                        amountA <= 0 || amountB <= 0
                                                                                    }
                                                                                    type="button"
                                                                                    className="btn-add w-150 click-now"
                                                                                    onClick={handleRidirect}
                                                                                >
                                                                                    Next
                                                                                </button>
                                                                            </div>
                                                                            <div className="group-dot res mar-top-30">
                                                                                <img
                                                                                    src="../images/bot/dot-new-1.png"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <>
                                                <AddLiqV2Details
                                                    balanceA={balanceA}
                                                    balanceB={balanceB}
                                                    symBolTokenA={symBolTokenA}
                                                    symBolTokenB={symBolTokenB}
                                                    contractTokenA={contractTokenA}
                                                    contractTokenB={contractTokenB}
                                                    amountA={amountA}
                                                    amountB={amountB}
                                                />
                                            </>
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <ChainNotSupport />
                        </>
                    )}
                </>
            ) : (
                <>
                    <div className="round-disconect">Please connect wallet</div>
                </>
            )}
        </>
    );
};
export default LiquidityV2;
