import React, {useEffect, useState} from 'react';
import { useActiveWeb3React } from '../../hooks';
import { Spin } from 'antd';
import { formatShortDateAndTimeAllocations } from "../../utils/formatDatetime";
import { formatNumberCurrency } from "../../utils/formatNumberCurrency";
import { useIdoTokenClaimContract, useClaimDailyContract } from "../../hooks/useContract";
import {
    _isRefundedSC, 
    _isJoinedSC, 
    _totalJoinedSC, 
    _approveBUSD, 
    _joinPool, 
    _refund,
    _dailyClaimTokensSC,
    _totalClaimAble,
    // _totalClaimAble
} from "./utils";
import useWindowSize from '../../hooks/useWindowSize';
import {useSelector, useDispatch} from 'react-redux';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import web3 from "web3";

const spinLoading = () => <Spin size="small" style={{position: 'absolute', marginLeft: "5px"}}/>;

const DailyAllocation = ({ allocationItem, addressClaimDaily, isRefunded }) => {
    
    const { account, chainId } = useActiveWeb3React();
    const [isLoading, setIsLoading] = useState(false);
    const [totalClaimed, setTotalClaimed] = useState(0);
    const [totalClaimDaily, setTotalClaimDaily] = useState(0);
    const claimContract = useIdoTokenClaimContract(allocationItem.contractAddress);
    const claimDailyContract = useClaimDailyContract(addressClaimDaily);
    const nowDate = new Date(Date.now());
    const size = useWindowSize();
    const sizeTablet = 991;
    const provider = process.env.REACT_APP_NETWORK_URL ?? '';
    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    useEffect(() => {
        try {
          if (claimDailyContract && addressClaimDaily) {
            _totalClaimAble(claimDailyContract, account).then((res) => {
              setTotalClaimDaily(Number(res));
            })
          }
        } catch (error) {
          // TODO
        }
    }, [account, claimDailyContract]);

    const _handleClaim = async () => {

        setIsLoading(true);

        await _dailyClaimTokensSC(claimDailyContract)
        .then((res) => {

            let resWait = res.wait();
            resWait.then((resTransaction) => {

                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    Store.addNotification(addNotify('Tokens claimed successfully', 'success'));
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                    Store.addNotification(addNotify('Transaction failed', 'warning'));
                    return;
                }
            })
            .catch ((error) => {
                setIsLoading(false);
                if (error.data) {
                    Store.addNotification(addNotify(error.data.message, 'warning'));
                    return;
                }
            });
        })
        .catch ((error) => {
            setIsLoading(false);
            if (error.data) {
                Store.addNotification(addNotify(error.data.message, 'warning'));
                return;
            }
        });
    }

    const btnClaim = (allocationItem) => {
        let claimTime;
        if(allocationItem.claimTime){
            claimTime = new Date(allocationItem.claimTime);
        }
        else {
            claimTime = null;
        }
        const activatedItem = allocationItem && ( allocationItem.activated === true && (nowDate >= claimTime && claimTime)) ? true : false;

        const obj = {
            className: `c-btn`,
            disabled: isLoading | !activatedItem | totalClaimDaily === 0,
        };
        return obj;
    }

    const claimButtonRenderer = () => {
        
        let element = '';
        if(totalClaimDaily === 0) {
            // element = (<span className="c-action">Claimed</span>);
        }
        else if(isRefunded) {
            element = (<span className="c-action">Refunded</span>);
        }
        else {
            element = (
                <button {...btnClaim(allocationItem)} onClick={() => _handleClaim(allocationItem)}>
                    Claim {isLoading && spinLoading()}
                </button>
            );
        }

        return element;
    }

    // Especial processing for ZWZ Guaranteed (RoundId 14202)
    if(allocationItem.roundId === 14201 || allocationItem.roundId === 14202) {
        return (
            <>
            {size.width < sizeTablet ? <>
                <tr>
                    <td colSpan="4">
                        <div className="c-campaignRound__tableSP">
                            <p>{formatShortDateAndTimeAllocations(new Date(allocationItem.claimTime), true)}</p>
                            <p><span>Token Allocation</span>{formatNumberCurrency(totalClaimDaily)}</p>
                            <p><span>Percentage</span>{"75% vesting daily"}</p>
                            {claimButtonRenderer()}
                        </div>
                    </td>
                </tr>
            </> : <tr>
                <td>{formatShortDateAndTimeAllocations(new Date(allocationItem.claimTime))}</td>
                <td>{formatNumberCurrency(totalClaimDaily)}</td>
                <td>{"75% vesting daily"}</td>
                <td>
                    {claimButtonRenderer()}
                </td>
            </tr>}
            </>
        );
    }

    return (
        <>
        {size.width < sizeTablet ? <>
            <tr>
                <td colSpan="4">
                    <div className="c-campaignRound__tableSP">
                        <p>{formatShortDateAndTimeAllocations(new Date(allocationItem.claimTime), true)}</p>
                        <p><span>Token Allocation</span>{formatNumberCurrency(totalClaimDaily)}</p>
                        <p><span>Percentage</span>{allocationItem.percentage} {"% vesting daily"}</p>
                        {claimButtonRenderer()}
                    </div>
                </td>
            </tr>
        </> : <tr>
            <td>{formatShortDateAndTimeAllocations(new Date(allocationItem.claimTime))}</td>
            <td>{formatNumberCurrency(totalClaimDaily)}</td>
            <td>{allocationItem.percentage} {"% vesting daily"}</td>
            <td>
                {claimButtonRenderer()}
            </td>
        </tr>}
    </>
    );
};

export default DailyAllocation;