export async function postData(url, body) {
    let tokenID = localStorage.getItem('tokenId');
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${tokenID}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('error postData', error)
        throw error;
    }
}

export async function putData(url, body) {
    let tokenID = localStorage.getItem('tokenId');
    try {
        const response = await fetch(url, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${tokenID}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('error putData', error)
        throw error;
    }
}

export async function getData(url) {
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                accept: "application/json",
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error getData:", error);
        throw error;
    }
}