import React from 'react';
import { Link } from 'react-router-dom'; 

const ItemGamehub = (props) => {  
    const {item, marketPriceArr} = props; 
    const marketPrice = marketPriceArr?.find(({id})=> id===item.tokenId);  
    return(
        <Link to={`/gamehub/${item?.id}`} className="c-listCate__item">
            <div className="c-listCate__img">
                <img src={item?.poster} alt="" />
            </div>
            <div className="c-listCate__body">
                <h3 className="c-listCate__title">{item?.title}</h3>
                <p className="c-listCate__text" dangerouslySetInnerHTML={{__html: item?.description}}></p>
                <div className="c-listCate__price">
                    <span className="c-listCate__logo"><img src={marketPrice?.image} alt="" /></span>
                    <p>$ {marketPrice?.current_price}<span className={marketPrice?.price_change_percentage_24h > 0 ? 'up' : 'down'}>{marketPrice?.price_change_percentage_24h}%</span></p>
                </div>
            </div>
        </Link>  
    )
}

export default ItemGamehub;