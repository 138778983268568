import React from "react";
import FooterHome from "../common/footer/footerHome";

const CoomingSoonPage = () => {
    return(
        <>
            <div className="p-coming-soon">
                <div className="container">
                    
                    <div className="coming-title">
                        We’re launching soon
                    </div>
                    <div className="coming-description">
                        We are creating something exciting in the house and about to launch soon. 
                        Thank you so much for your interest! Stay tuned!
                    </div>
                    <div className="wrongnet-button mt-15 text-center">
                        <button className="btn-basic w-fitcontent" onClick={()=> window.location.replace('/')}>Back to home</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CoomingSoonPage;