import {
    GET_CAMPAIGNS_BY_CLAIMABLE,
    GET_CAMPAIGNS_BY_COMMITED,
    GET_CAMPAIGNS_BY_STATUS,
    GET_CAMPAIGN_BY_HANDLE,
    SET_CAMPAIGN_ACTIVE
} from "../actions/campaignType";

const initialState = {};

function campaignReducer(campaigns = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CAMPAIGNS_BY_STATUS:
            return payload;

        case GET_CAMPAIGN_BY_HANDLE:
            return payload;

        case GET_CAMPAIGNS_BY_COMMITED:
            return payload;

        case GET_CAMPAIGNS_BY_CLAIMABLE:
            return payload;

        case SET_CAMPAIGN_ACTIVE: 
            return {
                ...campaigns,
                data: {
                    ...campaigns.data,
                    books: [
                        ...campaigns.data.books, payload
                    ]
                }
            }

        default:
            return campaigns;
    }
};

export default campaignReducer;