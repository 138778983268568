import GamehubService from "../services/GamehubService";
import CoingeckoDataService from "../services/CoingeckoService";
import { 
  GET_GAMEHUB_BY_HOT_STATUS,
  GET_GAMEHUB_BY_POPULAR_STATUS,
  GET_GAMEHUB_BY_RECOMMEND_STATUS, 
  SEARCH_GAMEHUB, 
  CATALOGS_GAMEHUB, 
} from "./gamehubType"; 

const getPriceMarket = (data, onCallback) => async () => { 
  let tokenIdString = ''; 
  data.map((item) => {
    if (item && item.tokenId && !tokenIdString.includes(item.tokenId)) {
      tokenIdString += item.tokenId.replace(/\s/g, '-')  + ",";
    }
  });
  tokenIdString = tokenIdString.toLowerCase();

  tokenIdString = tokenIdString.substring(
    0,
    tokenIdString.length - 1
  );

  let resPrices;

  if (tokenIdString) {
    let paramsCoingecko = {
      vs_currency: "usd",
      ids: tokenIdString,
      order: "market_cap_desc",
      per_page: 100,
      page: 1,
      sparkline: false,
      price_change_percentage: "24h",
    };  
    resPrices = await CoingeckoDataService.getMarketPrices(paramsCoingecko);  
    if(resPrices && resPrices.status === 200) { 
        onCallback(resPrices) 
    } 
  }   
}

const getGameHubByHotStatus = (payload, onCallback) => async (dispatch) => {  
   await GamehubService.getGameHubByStatus(payload, 0)
    .then(res =>{
      if(res && res.status===200) { 
        dispatch({
          type: GET_GAMEHUB_BY_HOT_STATUS,
          payload: {
            gamehubHotList: res && res.data || [],  
          }
        });  
        onCallback({
          gamehubHotList: res && res.data || [],
          callResult: true
        }); 
      }
    })  
    .catch((error) => {
      if (error.response) {
        onCallback({callResult: true});
      } 
    });
}

const getGameHubByPopularStatus = (payload, onCallback) => async (dispatch) => { 
  try {
    const res = await GamehubService.getGameHubByStatus(payload, 0); 
    if(res && res.status===200) {  
      dispatch({
        type: GET_GAMEHUB_BY_POPULAR_STATUS,
        payload: {
          gamehubPopularList: res && res.data || [], 
          gamehubPopularCount: res && res.headers['x-total-count'] || 0, 
        }
      });
      onCallback({
        gamehubPopularList: res && res.data || [],
        callResult: true
      }); 
    }  
  } catch (err) {
    console.log(err);
  }
}

const getGameHubByRecommendStatus = (payload, page, onCallback) => async (dispatch) => {  
  try {
    const res = await GamehubService.getGameHubByStatus(payload, page); 
    if(res && res.status===200) { 
      const resPrices = await getPriceMarket(res.data);
      if(resPrices){    
      dispatch({
        type: GET_GAMEHUB_BY_RECOMMEND_STATUS,
        payload: {
          gamehubRecommendList: res && res.data || [], 
          gamehubRecommendCount: res && res.headers['x-total-count'] || 0, 
        }
      });
      onCallback({
        gamehubRecommendList: res && res.data || [],
        callResult: true
      });  
      }
    }  
  } catch (err) {
    onCallback({callResult: true});
    console.log(err);
  }
} 


const getGameHubDetail = (handle, callback) => async (dispatch) => { 
  await GamehubService.getGameHubByHandle(handle)
  .then((res) => {
    if(res && res.status===200) {
      callback(res.data);
    }
  })
  .catch(({ error }) => {
    callback({});
    console.log(error);
  });
} 

const searchGameHub = (handle, page, onCallback) => async (dispatch) => { 
  if(!handle){  
    dispatch({type: SEARCH_GAMEHUB, payload: null});
  }
  else{
    await GamehubService.searchGameHub(handle, page)
    .then((res) => {
      if(res && res.status===200) {   
            dispatch({type: SEARCH_GAMEHUB, payload: {
              gamehubSearch: res.data,
              gamehubSearchCount: res && res.headers['x-total-count'] || 0, 
            }}); 
      }  
      onCallback({
        gamehubSearch: res && res.data || [],
        callResult: true
      });  
      
    })
    .catch(({ error }) => {
      onCallback({callResult: true});
      console.log(error);
    });
  } 
} 
 
const getCatalogs = (callback) => async (dispatch) => { 
  await GamehubService.getCatalogs()
  .then((res) => { 
    if(res && res.status===200) { 
      callback(res.data);
    }
  })
  .catch(({ error }) => {
    callback({});
    console.log(error);
  });
}  

const getCatalogByID = (handle, page, onCallback) => async (dispatch) => { 
  await GamehubService.getCatalogsByID(handle, page)
  .then((res) => {
    if(res && res.status===200) { 
      (async()=>{
      const resPrices = await getPriceMarket(res.data);
      dispatch({type: CATALOGS_GAMEHUB, payload: {
          catalogsList: res && res.data,
          catalogsCount:  res && res.headers['x-total-count'] || 0,
          catalogsPrices: resPrices && resPrices.data || [], 
        } 
      })
    })()
    onCallback({callResult: true});
  }})
  .catch(({ error }) => {
    onCallback({callResult: true});
    console.log(error);
  });
}

const getCatalogHandle = (handle, onCallback) => async (dispatch) => { 
  await GamehubService.getCatalogHandle(handle)
  .then((res) => {
    if(res && res.status===200) {
      onCallback({
        data: res.data,
        callResult: true});
    }
  })
  .catch(({ error }) => {
    onCallback({
      data: {},
      callResult: true});
    console.log(error);
  });
}  

export default {
  getGameHubByHotStatus,
  getGameHubByPopularStatus,
  getGameHubByRecommendStatus,
  getGameHubDetail, 
  searchGameHub,
  getCatalogs, 
  getCatalogByID,
  getCatalogHandle,
  getPriceMarket
}