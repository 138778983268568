import InoService from "../services/InoService";
import { 
    GET_INO_LIST,
    GET_INO_LIST_BY_ADDRESS,
    GET_INO_DETAIL,
    GET_BOXES_BY_ID,
    GET_TOTAL_OWNER_BOX
} from "./inoType";

const getIno = (payload, onCallback) => async (dispatch) => {
    try {
      const res = await InoService.getInoList(payload); 
      if(res && res.data){
        dispatch({
          type: GET_INO_LIST,
          payload: res.data 
        });
        onCallback({callResult: true});
      } 
    } catch (err) {
      console.log(err);
    }
}

const getInoByAddress = (payload) => async (dispatch) => {
  try {
    const res = await InoService.getInoList(payload);  
    dispatch({
      type: GET_INO_LIST_BY_ADDRESS,
      payload: res.data 
    });
  } catch (err) {
    console.log(err);
  }
}


const getInoDetail = (handle, account, onCallback) => async (dispatch) => {
    try {
      const res = await InoService.getInoDetail(handle, account);  
      if(res && res.data){
        dispatch({
          type: GET_INO_DETAIL,
          payload: res.data
        });
      }
      onCallback({callResult: true});
    } catch (err) {
      console.log(err);
    }
}

const postBuyBox = (payload, callback) => async (dispatch) => {
  try {
    const res = await InoService.postBuyBox(payload);  
    if(res && res.status===201) {  
      callback(res)
    }  
  } catch (err) {
    console.log("err", err);
    callback(err)
 }
}
const getBoxesById = (id) => async (dispatch) => {
  try {
    const res = await InoService.getBoxesById(id);  
    dispatch({
      type: GET_BOXES_BY_ID,
      payload: res.data
    });
  } catch (err) {
    console.log(err);
  }
}

const getTotalOwnerBox = (numberBox) => async (dispatch) => {
  dispatch({
    type: GET_TOTAL_OWNER_BOX,
    payload: numberBox
  });
}

const inoAction = {
    getIno, 
    getInoByAddress,
    getInoDetail,
    postBuyBox,
    getBoxesById,
    getTotalOwnerBox
};

export default inoAction;