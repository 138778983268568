import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import { Layout, Row, Col } from 'antd';

const Community = () => {
    
    
    return(
        <>
            <div className="community">
                <div className='commu-block-left'>
                    <img src='../images/commu-1.svg' alt='' />
                </div>
                <div className='commu-block-right'>
                    <img src='../images/commu-2.svg' alt='' />
                </div>
                <div className='container'>
                    <div className='community-title' data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200">
                        Community
                    </div>
                    <div className='community-description' data-aos="fade-up" data-aos-duration="1400" data-aos-offset="200">
                        We're having fun while earning good income in this Web3 space. <br></br>
                        It'd be great if you join us!
                    </div>
                    
                    <div className='community-list' data-aos="fade-up" data-aos-duration="1600" data-aos-offset="200">
                        <Row gutter={30}>
                            <Col xs={24} sm={8} xl={8} md={8}>
                                <div className='community-item bg-purple'>
                                    <a href="https://discord.com/invite/VjVfQWqMGP" target="_blank">
                                        <div className='community-img'>
                                            <img src="../images/big-discord.svg" alt=''/>
                                        </div>
                                        
                                    </a>
                                </div>
                            </Col>
                            <Col xs={24} sm={8} xl={8} md={8}>
                                <div className='community-item bg-black'>
                                    <a href="https://twitter.com/UpSwap_Finance" target="_blank">
                                        <div className='community-img'>
                                            <img src="../images/big-x.svg" alt=''/>
                                        </div>
                                        
                                    </a>
                                </div>
                            </Col>
                            <Col xs={24} sm={8} xl={8} md={8}>
                                <div className='community-item bg-blue'>
                                    <a href="https://t.me/upswap_official" target="_blank">
                                        <div className='community-img'>
                                            <img src="../images/big-tele.svg" alt=''/>
                                        </div>
                                       
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='community-end' data-aos="fade-up" data-aos-duration="1600" data-aos-offset="200">
                        <Row gutter={30}>
                            <Col xs={24} sm={18} xl={18} md={18}>
                                <div className='end-info'>
                                    <div className='end-des'>Keep in touch </div>
                                    <div className='end-title'>
                                        Want to launch your own token with UpSwap? <br></br>
                                        We have your back!
                                    </div>
                                    <div className='end-des'>
                                        Let's fill in this form to discuss your needs
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={6} xl={6} md={6} className="text-center">
                                <div className='end-btn'>
                                    <a href='https://forms.gle/GMQWEysU4jpvFvwY9' target="_blank">Apply for IDO</a>
                                </div>
                            </Col>
                            
                        </Row>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Community;
