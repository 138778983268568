import { Empty, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";

import TimeAgo from "../../../../config/timeAgo";


const ProfileOfferItem = (props) => {
    const { data, index, item } = props;

    let date = new Date(item?.createdDate);

    return (
        <>
            <tr key={`log-${index}`}>
                <td>
                    <span>{Number(item?.price).toLocaleString()}</span> HLUP
                </td>

                <td>--</td>
                <td>
                    <TimeAgo timestamp={(date.getTime() / 1000)} />
                </td>
            
                <td>
                    {item?.offerBy ? (
                        <>
                            {item?.offerBy.substring(0, 4)}...{item?.offerBy.substring(item?.offerBy.length - 4)}
                        </>
                    ):(
                        <>___</>
                    )}
                </td>
                
            </tr>
            
        </>
    )
}
export default ProfileOfferItem
