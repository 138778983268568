import React, {useEffect, useState, useCallback} from 'react';
import { Link } from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import useWindowSize from '../../hooks/useWindowSize';
import { Tabs, Row, Col } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import allActions from '../../actions';
import Countdown from 'react-countdown';
import { stakingApi } from '../../config/api/stakingApi';

const { TabPane } = Tabs;

const IdoProject = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [loadingPage, setLoadingPage] = useState(false);
    const [stakingTokens, setStakingTokens] = useState([]);
    const [stakingMainTokens, setStakingMainTokens] = useState([]);
    const [activeTab, setActiveTab] = useState('ido');
    let size = 5;
    const status = 'UPCOMING,TBA';
    const sort = 'priority,asc';

    useEffect(() => {
        setLoadingPage(true);
        dispatch(allActions.campaignAction.getCampaignsByStatus({ status, sort, page, size }, (res) => {
            if (res.callResult === true) {
                setLoadingPage(false);
            }
        }));
    }, [dispatch, page, size]);


    const getStakingTokens = async (status) => {
        const dataFilter = {
            page: 0, size: 9, sort: 'id,desc', typeEnum: 'STAKE', keyword: '', hlup: true, active: status
        };
        try {
            let res = await stakingApi.getStakingList(dataFilter);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    let dataNew = res.data.filter((item)=> item?.mainToken === false);
                    setStakingTokens(dataNew);
                } else {
                    setStakingTokens([]);
                }
            }
        } catch (error) {}
    }
    const getStakingMainTokens = async (status) => {
        const dataFilter = {
            page: 0, size: 9, sort: 'id,desc', typeEnum: 'STAKE', keyword: '', hlup: true, active: status
        };
        try {
            let res = await stakingApi.getStakingList(dataFilter);
            if (res.status === 200) {
                if (res.data.length > 0) {
                    let dataNew = res.data.filter((item)=> item?.mainToken === true);
                    setStakingMainTokens(dataNew);
                } else {
                    setStakingMainTokens([]);
                }
            }
        } catch (error) {}
    }

    useEffect(() => {
        getStakingTokens(true);
        getStakingMainTokens(true);
    }, []);

    const campaignsData = useSelector(state => state.campaignsData);
    const { campaignList, campaignsCount } = campaignsData;

    const onChangeTabs = (key) => {
        setActiveTab(key);
    };

    const currentDay = new Date('2024-06-10 00:00:00');
    


    const rendererCountDown = ({ days, hours, minutes, seconds, completed }) => {
		if (completed) {
			return (<></>);
		}
		if (days > 1) {
			return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
		} else if (days === 1) {
			return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
		} else {
			return (<span>{days}d {hours}h {minutes}m {seconds}s</span>);
		}
	};
	const getProgressTime = useCallback((startTime) => {
		const now = new Date();
		const utcDate = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), 0);
		const startDate = new Date(startTime);
		const startTS = Date.UTC(
			startDate.getFullYear(),
			startDate.getMonth(),
			startDate.getDate(),
			startDate.getHours(),
			startDate.getMinutes(),
			startDate.getSeconds(),
			0
		);
		let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
		const days = Math.floor(delta / 86400);
		delta -= days * 86400;
		const hours = Math.floor(delta / 3600);
		delta -= hours * 3600;
		const minutes = Math.floor(delta / 60) % 60;
		delta -= minutes * 60;
		const seconds = Math.floor(delta % 60);
		if (days >= 1) {
			return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
		} else {
			return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
		}
	}, []);
    
    return(
        <>
            <div className="home-project">
                <div className='container'>
                    <div className='project-upcoming'>
                        <div className='upcoming-title' data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">Ongoing Events </div>

                        <div className='upcoming-item' data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200" key={`event-1`}>
                            <Row gutter={30}>
                                <Col xs={24} sm={8} xl={8} md={8}>
                                    <div className='image'>
                                        <img src='../images/event-1.png' alt=''/>
                                    </div>
                                </Col>
                                <Col xs={24} sm={16} xl={16} md={16}>
                                    <div className='info'>
                                        <div className='title-head'>
                                            <img width={30} src='../images/default_nft.png' alt=''/>
                                            <div className='title'>UpSwap x Atlantia</div>
                                            <div className='status'><a href="https://t.me/upswap_official/133" target="_blank">View details <ArrowRightOutlined /></a></div>
                                        </div>
                                        <div className='description'>
                                            Dive into our Atlantia event and emerge with a treasure trove of HLPoints and exciting perks.
                                        </div>
                                        <div className='info-foot'>
                                            <Row>
                                                <Col xs={24} sm={16} xl={16} md={16}>
                                                    <div className='info-foot-left'>
                                                        <div className='network-info'>
                                                            Rewards: <span>HLPoints</span>
                                                        </div>
                                                        <div className='raise-info'>
                                                            Ends In: 
                                                            <span className='ml-5'>
                                                                Snapshot anytime
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={24} sm={7} xl={7} md={7}>
                                                    <div className='info-foot-right'>
                                                        <a href="https://atlantia.helalabs.com/campaign/upswap-finance" target="_blank">Join Now</a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='upcoming-item' data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200" key={`event-1`}>
                            <Row gutter={30}>
                                <Col xs={24} sm={8} xl={8} md={8}>
                                    <div className='image'>
                                        <img src='../images/event-2.png' alt=''/>
                                    </div>
                                </Col>
                                <Col xs={24} sm={16} xl={16} md={16}>
                                    <div className='info'>
                                        <div className='title-head'>
                                            <img width={30} src='../images/default_nft.png' alt=''/>
                                            <div className='title'>Engage to earn</div>
                                            <div className='status'><a href="https://t.me/upswap_official/148" target="_blank">View details <ArrowRightOutlined /></a></div>
                                        </div>
                                        <div className='description'>
                                            Step into our vibrant Discord community, engage with fellow enthusiasts, and reap incredible rewards.
                                        </div>
                                        <div className='info-foot'>
                                            <Row>
                                                <Col xs={24} sm={16} xl={16} md={16}>
                                                    <div className='info-foot-left'>
                                                        <div className='network-info'>
                                                            Rewards: <span>UpSwap discord points</span>
                                                        </div>
                                                        <div className='raise-info'>
                                                            Ends In: 
                                                            <span className='ml-5'>
                                                                Snapshot anytime
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={24} sm={7} xl={7} md={7}>
                                                    <div className='info-foot-right'>
                                                        <a href="https://discord.com/invite/VjVfQWqMGP" target="_blank">Join Now</a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='upcoming-item' data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200" key={`event-1`}>
                            <Row gutter={30}>
                                <Col xs={24} sm={8} xl={8} md={8}>
                                    <div className='image'>
                                        <img src='../images/event-3.png' alt=''/>
                                    </div>
                                </Col>
                                <Col xs={24} sm={16} xl={16} md={16}>
                                    <div className='info'>
                                        <div className='title-head'>
                                            <img width={30} src='../images/default_nft.png' alt=''/>
                                            <div className='title'>Warm-up event on Zealy</div>
                                            <div className='status'><a href="https://t.me/upswap_official/150" target="_blank">View details <ArrowRightOutlined /></a></div>
                                        </div>
                                        <div className='description'>
                                            Simple tasks can earn guarantee whitelist in UpSwap first IDO/ ICO. Remember, the first one is always big.
                                        </div>
                                        <div className='info-foot'>
                                            <Row>
                                                <Col xs={24} sm={16} xl={16} md={16}>
                                                    <div className='info-foot-left'>
                                                        <div className='network-info'>
                                                            Rewards: <span>IDO slots</span>
                                                        </div>
                                                        <div className='raise-info'>
                                                            Ends In: 
                                                            <span className='ml-5'>
                                                                <Countdown
                                                                    date={getProgressTime(currentDay)}
                                                                    renderer={rendererCountDown}
                                                                />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={24} sm={7} xl={7} md={7}>
                                                    <div className='info-foot-right'>
                                                        <a href="https://zealy.io/cw/upswapfinance/questboard" target="_blank">Join Now</a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        
                            
                    </div>
                    {/* <div className='project-banner' data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">
                        
                        <Row gutter={30}>
                            <Col xs={24} sm={8} xl={8} md={8}>
                                <div className='banner-item'>
                                    <img src='../images/banner-11.png' alt=''/>
                                    <div className='banner-title'>
                                        Token Distribution
                                    </div>
                                    <div className='banner-description'>
                                        Your token will be instantly distributed to a large user base that holds your token.
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8} xl={8} md={8}>
                                <div className='banner-item'>
                                    <img src='../images/banner-12.png' alt=''/>
                                    <div className='banner-title'>
                                        Liquidity
                                    </div>
                                    <div className='banner-description'>
                                        Projects launched on UpSwap Launchpad will be listed and have high trading liquidity.
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} sm={8} xl={8} md={8}>
                                <div className='banner-item'>
                                    <img src='../images/banner-13.png' alt=''/>
                                    <div className='banner-title'>
                                        Growth Opportunities
                                    </div>
                                    <div className='banner-description'>
                                        Your token might also be added to UpSwap Farms with high APR.
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div> */}
                    {/* <div className='project-upcoming'>
                        <div className='upcoming-title' data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">Upcoming Projects <a href="launchpad/completed">More <ArrowRightOutlined /></a></div>

                        <Tabs centered activeKey={activeTab} onChange={onChangeTabs} className="tab-ido-general c-tabs">
                            <TabPane tab="IDO" key="ido">
                                {campaignList && campaignList.length > 0 ? (
                                    <>
                                        {campaignList.map((item, key) => {
                                            
                                            return(
                                                <div className='upcoming-item' data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200" key={`ido-${item}`} onClick={()=>{window.location.replace(`/launchpad/${item?.status.toLowerCase(0)}/${item.handle}`)}}>
                                                    <Row gutter={30}>
                                                        <Col xs={24} sm={8} xl={8} md={8}>
                                                            <div className='image'>
                                                                <img src={item?.bannerURL ? item?.bannerURL : '../images/ido-1.png'} alt=''/>
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} sm={16} xl={16} md={16}>
                                                            <div className='info'>
                                                                <div className='title-head'>
                                                                    <img width={30} src={item?.logoUrl ? item?.logoUrl : '../images/helalogo.svg'} alt=''/>
                                                                    <div className='title'>{item?.title}</div>
                                                                    <span className='symbol'>{item?.tokenInform?.symbol}</span>
                                                                    <div className='status'>upcoming</div>
                                                                </div>
                                                                <div className='description'>
                                                                    {item?.description}
                                                                </div>
                                                                <div className='info-foot'>
                                                                    <Row>
                                                                        <Col xs={24} sm={16} xl={16} md={16}>
                                                                            <div className='info-foot-left'>
                                                                                <div className='network-info'>
                                                                                    Network: <span>{item?.network.toLowerCase()}</span>
                                                                                </div>
                                                                                <div className='raise-info'>
                                                                                    Total Raise: 
                                                                                    <span className='ml-5'>
                                                                                        {new Intl.NumberFormat("en-US", {
                                                                                            compactDisplay: "short",
                                                                                            maximumFractionDigits: 0,
                                                                                            minimumFractionDigits: 0,
                                                                                        }).format(item?.totalRaise)}
                                                                                    </span>
                                                                                    <small className='ml-5'>{item?.acceptCurrency}</small>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={24} sm={7} xl={7} md={7}>
                                                                            <div className='info-foot-right'>
                                                                                <a href={`/launchpad/${item?.status.toLowerCase(0)}/${item.handle}`}>View Details</a>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })}
                                    </>
                                ):(
                                    <>
                                        <div className="c-nodata c-nodata--upcoming">
                                            <img src={NodataImg} alt="No Data" />
                                            <p>Upcoming<span>10 projects (+)</span></p>
                                        </div>
                                    </>
                                )}
                            </TabPane>
                            <TabPane tab="ICO" key="ico">
                                {campaignList && campaignList.length > 0 ? (
                                    <>
                                        {campaignList.map((item, key) => {
                                            
                                            return(
                                                <div className='upcoming-item' data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200" key={`ido-${item}`} onClick={()=>{window.location.replace(`/launchpad/${item?.status.toLowerCase(0)}/${item.handle}`)}}>
                                                    <Row gutter={30}>
                                                        <Col xs={24} sm={8} xl={8} md={8}>
                                                            <div className='image'>
                                                                <img src={item?.bannerURL ? item?.bannerURL : '../images/ido-1.png'} alt=''/>
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} sm={16} xl={16} md={16}>
                                                            <div className='info'>
                                                                <div className='title-head'>
                                                                    <img width={30} src={item?.logoUrl ? item?.logoUrl : '../images/helalogo.svg'} alt=''/>
                                                                    <div className='title'>{item?.title}</div>
                                                                    <span className='symbol'>{item?.tokenInform?.symbol}</span>
                                                                    <div className='status'>upcoming</div>
                                                                </div>
                                                                <div className='description'>
                                                                    {item?.description}
                                                                </div>
                                                                <div className='info-foot'>
                                                                    <Row>
                                                                        <Col xs={24} sm={16} xl={16} md={16}>
                                                                            <div className='info-foot-left'>
                                                                                <div className='network-info'>
                                                                                    Network: <span>{item?.network.toLowerCase()}</span>
                                                                                </div>
                                                                                <div className='raise-info'>
                                                                                    Total Raise: 
                                                                                    <span className='ml-5'>
                                                                                        {new Intl.NumberFormat("en-US", {
                                                                                            compactDisplay: "short",
                                                                                            maximumFractionDigits: 0,
                                                                                            minimumFractionDigits: 0,
                                                                                        }).format(item?.totalRaise)}
                                                                                    </span>
                                                                                    <small className='ml-5'>{item?.acceptCurrency}</small>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={24} sm={7} xl={7} md={7}>
                                                                            <div className='info-foot-right'>
                                                                                <a href={`/launchpad/${item?.status.toLowerCase(0)}/${item.handle}`}>View Details</a>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })}
                                    </>
                                ):(
                                    <>
                                        <div className="c-nodata c-nodata--upcoming">
                                            <img src={NodataImg} alt="No Data" />
                                            <p>Upcoming<span>10 projects (+)</span></p>
                                        </div>
                                    </>
                                )}
                            </TabPane>
                            <TabPane tab="INO" key="ino">
                                {campaignList && campaignList.length > 0 ? (
                                    <>
                                        {campaignList.map((item, key) => {
                                            
                                            return(
                                                <div className='upcoming-item' data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200" key={`ido-${item}`} onClick={()=>{window.location.replace(`/launchpad/${item?.status.toLowerCase(0)}/${item.handle}`)}}>
                                                    <Row gutter={30}>
                                                        <Col xs={24} sm={8} xl={8} md={8}>
                                                            <div className='image'>
                                                                <img src={item?.bannerURL ? item?.bannerURL : '../images/ido-1.png'} alt=''/>
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} sm={16} xl={16} md={16}>
                                                            <div className='info'>
                                                                <div className='title-head'>
                                                                    <img width={30} src={item?.logoUrl ? item?.logoUrl : '../images/helalogo.svg'} alt=''/>
                                                                    <div className='title'>{item?.title}</div>
                                                                    <span className='symbol'>{item?.tokenInform?.symbol}</span>
                                                                    <div className='status'>upcoming</div>
                                                                </div>
                                                                <div className='description'>
                                                                    {item?.description}
                                                                </div>
                                                                <div className='info-foot'>
                                                                    <Row>
                                                                        <Col xs={24} sm={16} xl={16} md={16}>
                                                                            <div className='info-foot-left'>
                                                                                <div className='network-info'>
                                                                                    Network: <span>{item?.network.toLowerCase()}</span>
                                                                                </div>
                                                                                <div className='raise-info'>
                                                                                    Total Raise: 
                                                                                    <span className='ml-5'>
                                                                                        {new Intl.NumberFormat("en-US", {
                                                                                            compactDisplay: "short",
                                                                                            maximumFractionDigits: 0,
                                                                                            minimumFractionDigits: 0,
                                                                                        }).format(item?.totalRaise)}
                                                                                    </span>
                                                                                    <small className='ml-5'>{item?.acceptCurrency}</small>
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={24} sm={7} xl={7} md={7}>
                                                                            <div className='info-foot-right'>
                                                                                <a href={`/launchpad/${item?.status.toLowerCase(0)}/${item.handle}`}>View Details</a>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })}
                                    </>
                                ):(
                                    <>
                                        <div className="c-nodata c-nodata--upcoming">
                                            <img src={NodataImg} alt="No Data" />
                                            <p>Upcoming<span>10 projects (+)</span></p>
                                        </div>
                                    </>
                                )}
                            </TabPane>

                        </Tabs>
                        
                            
                    </div> */}
                    {/* <div className='earn-staking' data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200">
                        <div className='earn-staking-title' data-aos="fade-up" data-aos-duration="1000" data-aos-offset="200">Earn with UpSwap </div>
                        <Row gutter={30}>
                            <Col xs={24} sm={12} xl={12} md={12} data-aos="fade-up" data-aos-duration="1200" data-aos-offset="200">
                                <div className='earn-staking-title'>Staking <a href="/stake-token"> <ArrowRightOutlined /></a></div>

                                {stakingTokens && stakingTokens.length > 0 ? (
                                    <>
                                        {stakingTokens.map((item, key) => {
                                            
                                            return(
                                                <div className='earn-item' key={`earn-item-${key}`}>
                                                    <Row gutter={30}>
                                                        <Col xs={24} sm={13} xl={13} md={13}>
                                                            <Row>
                                                                <Col xs={6} sm={7} xl={7} md={7}>
                                                                    <div className='logo-chain text-center'>
                                                                        <img className='main-logo' src={item?.stakeTokenLogo} alt=''/>
                                                                        <img className='sub-logo' src={item?.earnTokenLogo} alt=''/>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={13} sm={12} xl={12} md={12}>
                                                                    <div className='title'>{item?.stakeTokenName}-{item?.earnTokenName}</div>
                                                                </Col>
                                                                <Col xs={5} sm={5} xl={5} md={5} className="text-center">
                                                                    <div className='price'>{item?.apr} %</div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={24} sm={11} xl={11} md={11}>
                                                            <Row>
                                                                <Col xs={12} sm={12} xl={12} md={12}>
                                                                    <div className='apr-info'>
                                                                        <div className='apr-title'>Period Time:</div>
                                                                        <div className='apr-value'>{item?.periodTime} <span>days</span></div> 
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={12} xl={12} md={12} className="align-middle">
                                                                    <a className='btn-farm' href='/stake-token'>Start Farm</a>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })}
                                    </>
                                ):(
                                    <>
                                        <div className="c-nodata c-nodata--upcoming">
                                            <p>Upcoming<span>5 pools (+)</span></p>
                                        </div>
                                    </>
                                )}
                                
                            </Col>
                            <Col xs={24} sm={12} xl={12} md={12} data-aos="fade-up" data-aos-duration="1600" data-aos-offset="200">
                                <div className='earn-staking-title'>Farming <a href="/stake-hlup"><ArrowRightOutlined /></a></div>

                                {stakingMainTokens && stakingMainTokens.length > 0 ? (
                                    <>
                                        {stakingMainTokens.map((item, key) => {
                                            
                                            return(
                                                <div className='staking-item' key={`earn-item-${key}`}>
                                                    <Row gutter={30}>
                                                        <Col xs={24} sm={13} xl={13} md={13}>
                                                            <Row gutter={20}>
                                                                <Col xs={8} sm={8} xl={8} md={8}>
                                                                    <div className='staking-logo-chain text-center'>
                                                                        <img className='staking-main-logo' src={item?.stakeTokenLogo} alt=''/>
                                                                        <img className='staking-sub-logo' src={item?.earnTokenLogo} alt=''/>
                                                                    </div>
                                                                </Col>
                                                                <Col xs={16} sm={16} xl={16} md={16}>
                                                                    <div className='staking-title'>{item?.title}</div>
                                                                    <div className='staking-description'>Stake {item?.stakeTokenName} - Earn {item?.earnTokenName}</div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs={24} sm={11} xl={11} md={11}>
                                                            <Row>
                                                                <Col xs={12} sm={12} xl={12} md={12}>
                                                                    <div className='staking-apr-info'>
                                                                        <div className='staking-apr-title'>Locking Period:</div>
                                                                        <div className='staking-apr-value'>{item?.periodTime} <span>days</span></div> 
                                                                    </div>
                                                                </Col>
                                                                <Col xs={12} sm={12} xl={12} md={12} className="align-middle">
                                                                    <a className='staking-btn-farm' href='/stake-hlup'>Stake HLUP</a>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        })}
                                    </>
                                ):(
                                    <>
                                        <div className="c-nodata c-nodata--upcoming">
                                            <p>Upcoming<span>5 pools (+)</span></p>
                                        </div>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </div> */}
                </div>
            </div>
            
        </>
    )
}

export default IdoProject;
