import { BigNumber } from "ethers";
import web3 from "web3";

export const convertToWei = (value, decimal) => {
  if (value && decimal) {
    if (Number(decimal) === 18)
      return web3.utils.toWei(value.toString(), "ether");
    else if (Number(decimal) === 6)
      return web3.utils.toWei(value.toString(), "picoether");
    else if (Number(decimal) === 9)
      return web3.utils.toWei(value.toString(), "nanoether");
    else if (Number(decimal) === 12)
      return web3.utils.toWei(value.toString(), "microether");
    else if (Number(decimal) === 15)
      return web3.utils.toWei(value.toString(), "milliether");
    else if (Number(decimal) === 10)
      return new BigNumber(value).times(new BigNumber(10).pow(10)).toString();
    else 
      return value.toString() / 10 ** Number(decimal);
  } else {
    return "";
  }
};

export const convertFromWei = (value, decimal) => {
  try {
    if (Number(decimal) === 18)
      return web3.utils.fromWei(value.toString(), "ether");
    else if (Number(decimal) === 6)
      return web3.utils.fromWei(value.toString(), "picoether");
    else if (Number(decimal) === 9)
      return web3.utils.fromWei(value.toString(), "nanoether");
    else if (Number(decimal) === 12)
      return web3.utils.fromWei(value.toString(), "microether");
    else if (Number(decimal) === 15)
      return web3.utils.fromWei(value.toString(), "milliether");
    else 
      return value.toString() / 10 ** Number(decimal);
  } catch (error) {
    return 0;
  }
};
