import AirdropService from "../services/AirdropService";
import {
  GET_AIRDROP_BY_HANDLE,
  GET_AIRDROP_BY_STATUS,
  GET_WHITELIST_AIRDROP_BY_ADDRESS
} from "./airdropType";

const getAirdropByStatus = (payload, onCallback) => async (dispatch) => { 
  await AirdropService.getAirdropByStatus(payload)
  .then((res) => {
    if(res && res.status===200) {
      dispatch({type: GET_AIRDROP_BY_STATUS, payload: {
        airdropList: res && res.data || [],
        airdropsCount: res && res.headers['x-total-count'] || 0,
      }});
      onCallback({callResult: true});
    }
    else {
      dispatch({type: GET_AIRDROP_BY_STATUS, payload: []});
      onCallback({callResult: true});
    }
  })
  .catch((error) => { 
      onCallback({callResult: true});
  });
}


const getAirdropDetail = (handle) => async (dispatch) => { 
  await AirdropService.getAirdropByHandle(handle)
  .then((res) => {
    if(res && res.status===200) {
      dispatch({type: GET_AIRDROP_BY_HANDLE, payload: res.data});
    }
  })
  .catch(({ error }) => {
    console.log(error);
  });
}

const getWhitelistAirdropByAddress = (airdropId, account, onCallback) => async (dispatch) => {

  await AirdropService.getWhitelistAirdropByAddress(airdropId, account)
  .then((res) => {
    if(res && res.status===200){
      onCallback(res)
    } 
  })
  .catch((error) => {
    onCallback(null)
  });
}
const getWhitelistAirdropByAddressDetail = (airdropId, account) => async (dispatch) => {
  await AirdropService.getWhitelistAirdropByAddress(airdropId, account)
  .then((res) => {
    if(res && res.status===200) {
      dispatch({type: GET_WHITELIST_AIRDROP_BY_ADDRESS, payload: res.data});
    }
  })
  .catch((error) => {
    if (error.response) {
      dispatch({type: GET_WHITELIST_AIRDROP_BY_ADDRESS, payload: []});
    }
  });
}



const getBanner = (payload, onCallback) => async (dispatch) => { 
  await AirdropService.getBanner(payload)
  .then((res) => { 
    if(res && res.status===200) {  
      onCallback(res.data)
    }
  })
  .catch((error) => { 
    onCallback([]);
  });
}

export default {
  getAirdropByStatus,
  getAirdropDetail,
  getWhitelistAirdropByAddress,
  getBanner,
  getWhitelistAirdropByAddressDetail
}