
import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import { BrowserRouter as Router, Route, Link, useLocation, useParams } from 'react-router-dom';
import { Layout, Row, Col, Button, Form, Input, Select } from 'antd';
import { useActiveWeb3React } from '../../../../hooks';

const { Option } = Select;
const { Content } = Layout;
const { TextArea } = Input;

const AdditonalInfo = (props) => {
    const { form } = props;
    let storageAdditionalInfo = window.localStorage.getItem('privateSaleAdditionalInfo');
    let additionalInfo;
    if (storageAdditionalInfo) {
        additionalInfo = JSON.parse(storageAdditionalInfo);
    }

    useEffect(() => {
        form.setFieldsValue({
            logoLink: additionalInfo ? additionalInfo.logoLink : '',
            background_link: additionalInfo ? additionalInfo.background_link : 'https://img.freepik.com/free-vector/abstract-blue-modern-elegant-design-background_1017-32105.jpg',
            facebook: additionalInfo ? additionalInfo.facebook : '',
            git: additionalInfo ? additionalInfo.git : '',
            instagram: additionalInfo ? additionalInfo.instagram : '',
            claimLink: additionalInfo ? additionalInfo.claimLink : '',
            reddit: additionalInfo ? additionalInfo.reddit : '',
            hostLink: additionalInfo ? additionalInfo.hostLink : '',
            youtube: additionalInfo ? additionalInfo.youtube : '',
            website: additionalInfo ? additionalInfo.website : '',
            twitter: additionalInfo ? additionalInfo.twitter : '',
            telegram: additionalInfo ? additionalInfo.telegram : '',
            discord: additionalInfo ? additionalInfo.discord : '',
            description: additionalInfo ? additionalInfo.description : '',
        })
    }, [additionalInfo]);

    return (
        <>
            <div className="title-steps">Add Additional Info</div>
            <div className="launchpad-info-form">

                {/* <Form form={form} layout="vertical" autoComplete="off" onFinish={onConfirmLaunchpadInfo} initialValues={{ currency_pay: 'bnb' }}> */}
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="step_additional_info" label="Presale rate" initialValue={3} hidden={true}>
                            <Input className="amount-buy" />
                        </Form.Item>
                        <Form.Item name="logoLink" label="Logo URL"
                            help="URL must end with a supported image extension png, jpg, jpeg or gif"
                        >
                            <Input className="amount-buy" placeholder="http://yourlink.com/yourpic.png" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="background_link" label={<>Background link (<span className='text-upload-image'>Upload your image and copy link:</span> <Link target="_blank" to="/upload-image"> Upload Image</Link>)</>}
                            help="URL must end with a supported image extension png, jpg, jpeg, webp or gif"
                            rules={[
                                { required: true, message: 'Please input background link!' }
                            ]}
                        >
                            <Input className="amount-buy" placeholder="http://yourlink.com/yourpic.png" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="facebook" label="Facebook">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="claimLink" label="Claim Link">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="hostLink" label="hostLink">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="website" label="Website">
                            <Input className="amount-buy" placeholder="http://wavecoin.finance/" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="git" label="Github">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="youtube" label="Youtube">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="twitter" label="Twitter">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="instagram" label="Instagram">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="telegram" label="Telegram">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="reddit" label="Reddit">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                        <Form.Item name="discord" label="Discord">
                            <Input className="amount-buy" placeholder="Enter a link" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item name="description" label="Description">
                    <TextArea rows={4} className="amount-buy" placeholder="Enter description" />
                </Form.Item>

                {/* </Form> */}
            </div>
        </>
    )
}

export default AdditonalInfo
