import { instance } from '../../apiBase/instance';
let tokenID = localStorage.getItem('tokenId');
export const HelaNodeApi = {

	getHelaNodes(params) {
		return instance.get(`hela-nodes`, { 
			params,
			headers: { Authorization: `Bearer ${tokenID}` }
		}, '');
	},
	getHelaNodeItems(params) {
		return instance.get(`hela-node-items`, { 
			params,
			headers: { Authorization: `Bearer ${tokenID}` }
		}, '');
	},
	getMyNodes(ownerAddress, params) {
		return instance.get(`my-nodes/${ownerAddress}`, { 
			params,
			headers: { Authorization: `Bearer ${tokenID}` }
		}, '');
	},
	getAmountByNodes(ownerAddress, tierEnum) {
		
		return instance.get(`/my-nodes/${ownerAddress}/tier/${tierEnum}`, {
			headers: { Authorization: `Bearer ${tokenID}` }
		}, '');
	},
	genSignBuyNode(data) {
		return instance.post(`buy-node` ,data, {});
	},
	updateOrderHash(data, orderId) {
		return instance.put(`finish-order/${orderId}` ,data, {});
	},
	getSettingKey(key) {
		return instance.get(`settings/by-key/${key}`, {
			headers: { Authorization: `Bearer ${tokenID}` }
		 });
	},
	// updateListingNft(id, data) {
	// 	return instance.put(`nft-geneses/${id}/list`,  data , {});
	// },
	// unListingNft(id, data) {
	// 	return instance.put(`nft-geneses/${id}/cancel-list`,  data , {});
	// },
	// buyNft(data) {
	// 	return instance.post(`buy-nft-genesis`,  data , {
	// 		headers: { Authorization: `Bearer ${tokenID}` }
	// 	});
	// },
	// claimNft(id, data) {
	// 	return instance.put(`merlion-nft/${id}/claim`,  data , {});
	// },

	// getNftLogs(merlionId, params) {
	// 	return instance.get(`order-logs/${merlionId}/nft`, { params }, '');
	// },
	// marketSummary() {
	// 	return instance.get(`marketplaces/summary`, { }, '');
	// },
	// getProfileByAddress(address) {
	// 	return instance.get(`minter/${address.toLowerCase()}/profile`, { }, '');
	// },
	// postProfile(values) {
	// 	let data = {
	// 		"avatarLink": values?.avatarLink,
	// 		"bio": values?.bio,
	// 		"email": values?.email,
	// 		"ownerAddress": values?.ownerAddress,
	// 		"username": values?.username,
	// 	}
	// 	return instance.post(`/minter/profile`, data, '');
	// },
	// editProfile(id, values) {
	// 	let data = {
	// 		"avatarLink": values?.avatarLink,
	// 		"id": values?.id,
	// 		"bio": values?.bio,
	// 		"email": values?.email,
	// 		"ownerAddress": values?.ownerAddress,
	// 		"username": values?.username,
	// 	}
	// 	return instance.put(`/minter/${id}/update-profile`, data, '');
	// },
	// uploadImage(data, token) {
		
	// 	return instance.post(`/upload-to-s3`, data, {
	// 		headers: {
	// 			Authorization: `Bearer ${token}`
	// 		}
	// 	});
	// },
	// getOfferLogs(merlionId) {
	// 	let data = {
	// 		"marketEnum": "OFFER",
	// 		"nftId": Number(merlionId)
	// 	}
	// 	return instance.post(`/order-logs/filter`, data, '');
	// },
	// getOfferLogsByAddress(account) {
	// 	let data = {
	// 		"marketEnum": "OFFER",
	// 		"address": account.toLowerCase()
	// 	}
	// 	return instance.post(`/nft-orders/filter`, data, '');
	// },

};
