import React, {useState} from "react";
import IconClose from '../common/asset/images/icon-close.svg';

const ModalStake = ({onCancel}) => {
    const [value, setValue] = useState(0);
    return(
        <div className="c-popup">
            <div className="c-popup__box">
                <div className="c-popup__head">
                    Stake KDG
                    <button className="c-popup__close" onClick={() => {onCancel(false); setValue(0)}}><img src={IconClose} alt="Close" /></button>
                </div>
                <div className="c-popup__body is-staking">
                    <div className="c-staking">
                        <p>Enter amount</p>
                        <div className="c-staking__stake">
                            <input type="number" name="stake" value={value} onChange={(e) => setValue(e.target.value)} />
                            <span>KDG</span>
                            <button onClick={() => setValue(1000)} className="c-btn">MAX</button>
                        </div>
                        <p className="is-amount">10.2351 KDG available</p>
                        <div className="c-staking__btn">
                            <button className="c-btn">Stake</button>
                            <button onClick={() => {onCancel(false); setValue(0)}} className="c-btn c-btn--cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalStake;