import React from "react";
import { CHAINID_FULLNAME_BY_ID, MAPPING_CHAINID } from "../../constants";
import { useActiveWeb3React } from "../../hooks";

const ChainNotSupport = () => {
    const { account, chainId } = useActiveWeb3React()
    return(
        <>
            <div className="p-wrongnet pt-0">
                <div className="container">
                    
                    <div className="wrongnet-title">
                        Network Unreachable 
                    </div>
                    <div className="wrongnet-description">
                        This feature doesn’t available on {CHAINID_FULLNAME_BY_ID[chainId]} network!
                    </div>
                    <div className="wrongnet-button mt-15 text-center">
                        <button className="btn-basic w-fitcontent" onClick={()=> window.location.replace('/')}>Back to home</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChainNotSupport;