import React, { useEffect, useState } from "react";
import bgMainheader from "../banner-nft.png";
import { Progress, Spin, Modal } from 'antd';
import { postData, putData, getData } from "../axios";
import { Link } from "react-router-dom";
import { useActiveWeb3React } from "../../../hooks";
import approveContract from "../utils/approve";
import abiToken from "../abi/abiToken.json"
import abiMint from "../abi/abiMint.json"
import Web3 from "web3";
import FooterHome from '../../common/footer/footerHome';
import moment from "moment";
import { Store } from "react-notifications-component";
import addNotify from '../../common/Notify/addNotify';
import { ADDRESS_HLUP_ADDRESS, MAPPING_CHAINID_DECIMAL, NFT_ADDRESS_SC } from "../../../constants";
import ChainNotSupport from "../../chain-not-support";
import { Loader } from "../../common/component";
import tokenInfoAbi from '../../../config/abi/tokenInfo.json';
import { useContract } from "../../../hooks/useContract";
import {
    convertFromWei,
    convertToWei,
} from "../../../utils/convertNumber";
import { BaseAPI } from "../../../config/api/BaseApi";

const MintFunction = () => {
    const { account, chainId } = useActiveWeb3React()

    const web3 = new Web3(window.ethereum);
    const [count, setCount] = useState(1)
    const [loadingMint, setLoadingMint] = useState(false);
    const [loadingApprove, setLoadingApprove] = useState(false);
    const [allowanceToken, setAllowanceToken] = useState("0");
    const [hlupBalance, setHlupBalance] = useState(0);
    const [mintedWallet, setMintedWallet] = useState(0);

    const [totalMint, setTotalMint] = useState(5000);

    const MINT_NFT_CONTRACT = NFT_ADDRESS_SC;

    const contractToken = new web3.eth.Contract(abiToken, ADDRESS_HLUP_ADDRESS);
    const hlupContract = useContract(ADDRESS_HLUP_ADDRESS, tokenInfoAbi);
    const contractMint = new web3.eth.Contract(abiMint, MINT_NFT_CONTRACT);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataNFT, setDataNFT] = useState();
    const [dataQuality, setDataQuality] = useState();
    let valueQuanlity = Number(dataQuality?.totalCurrentNFT) / 10000 * 100
    let dataStart = moment(dataQuality?.startMintTime).unix() * 1000

    let dataNow = Date.now()

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChangePlus = () => {
        setCount(count + 1);
    }

    const handleChangeApart = () => {
        if(count > 1){
            setCount(count - 1);
        }
        
    }

    const getMaxMintByWallet = async()=>{
        await BaseAPI.getMaxMintByWallet(account).then((res)=>{
            if(res.status == 200){
                console.log('getMaxMint res', res.data.totalCurrentNFT);
                setMintedWallet(Number(res.data.totalCurrentNFT));
            }
        }).catch((erro)=>{
            console.log('getMaxMint err', erro);
        });
    }

    const fetchDataQuality = async () => {
        await getData(`${process.env.REACT_APP_API_URL}/summary-nft/666888`).then(res => {
            setDataQuality(res)
        })
    }

    useEffect(() => {
        fetchDataQuality()
    }, []);

    useEffect(() => {
        if(account){
            getMaxMintByWallet();
        }
        
    }, [account]);

    const getHLupBalance = async () => {
        if (hlupContract && process.env.REACT_APP_MODE != "production") {
            const amount = await hlupContract.balanceOf(account);
            setHlupBalance(Number(convertFromWei(amount, 18)));
        }
    };
    useEffect(() => {
        if (account) {
            getHLupBalance();
        }
    }, [account, hlupContract]);

    

    const checkAllowance = async () => {
        if (account) {
            try {
                await contractToken.methods.allowance(account, MINT_NFT_CONTRACT).call().then(res => {
                    setAllowanceToken(res)
                })
            } catch (error) { }
        }
    }

    useEffect(() => {
        checkAllowance();
    }, [account]);

    const handleApproveToken = async () => {
        try {
            if (account) {
                setLoadingApprove(true);
                await approveContract(MINT_NFT_CONTRACT, ADDRESS_HLUP_ADDRESS, account)
                    .then((res) => {
                        if (res?.status) {
                            Store.addNotification(addNotify('Approved successfully', 'success'));
                            setLoadingApprove(false);
                            checkAllowance();
                        }
                    })
                    .catch((error) => {
                        console.error("Error Approve Token", error);
                        setLoadingApprove(false);
                        if (error) {
                            if (error.code == 4001 && error.message) {
                                Store.addNotification(addNotify(error.message, 'danger'));
                            } else if (error.reason) {
                                Store.addNotification(addNotify(error.reason, 'danger'));
                            } else {
                                if (error.data && error.data.message) {
                                    Store.addNotification(addNotify(error.data.message, 'danger'));
                                }
                            }
                        }
                    });
            }
        } catch (error) {
            setLoadingApprove(false);
            console.error("Error Handle Approve", error);
        }
    };

    const handleMint = async () => {
        try {
            if (account) {
                setLoadingMint(true)
                const gasPrice = await web3.eth.getGasPrice();
                let data = {
                    chainId: chainId.toString(),
                    minterAddress: account,
                    networkEnum: "HELA"
                };

                const dataRow = await postData(
                    `${process.env.REACT_APP_API_URL}/generate-nft-id`, data
                );

                if (dataRow) {
                    // console.log('account', account);
                    // console.log('count', count);
                    // console.log('dataRow.nftId', dataRow.nftId);
                    // console.log('gasPrice', gasPrice);

                    await contractMint.methods.safeMintMany(account, count, [Number(dataRow.nftId)]).send({
                        from: account,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    }).then(async (res) => {

                        const interval = setInterval(function () {
                            web3.eth.getTransactionReceipt(
                                res?.transactionHash,
                                function (err, rec) {
                                    if (rec.status === true && res.transactionHash) {
                                        let dataApi = {
                                            id: dataRow.id,
                                            minterAddress: account,
                                            nftId: dataRow.nftId,
                                            tnxHash: res.transactionHash,
                                        }
                                        putData(`${process.env.REACT_APP_API_URL}/mint-nft/${dataRow.id}`, dataApi).then(resAfter => {
                                            if (resAfter.mintEnum === "FAILED") {
                                                Store.addNotification(addNotify('Mint NFTs Failed', 'danger'));
                                            } else {
                                                Store.addNotification(addNotify('Mint NFTs Success', 'success'));
                                            }

                                            setDataNFT(resAfter)
                                            setIsModalOpen(true);
                                            setLoadingMint(false);
                                        })
                                        clearInterval(interval);
                                    }
                                    if (err) {
                                        console.log("err", err);
                                        Store.addNotification(addNotify('Mint NFTs Failed', 'danger'));

                                    }
                                }
                            );
                        }, 1000);
                    })
                }
            }
        } catch (error) {
            console.error("error getListNFT", error);
            setLoadingMint(false);
            // setLoadingMint(false);
            if (error) {
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        }
    }

    if(chainId && MAPPING_CHAINID_DECIMAL.hela !== chainId){
        return(
            <><ChainNotSupport /></>
        )
    }

    return (
        <>
            <div className="main-nft-section">
                <div className="container">
                    <div className="go-back">
                        <Link to={'/genesis-nft'}>
                            <img src="./images/arrow-right.png" alt="" /> Go back
                        </Link>

                    </div>
                    <div className="mint-after-box">
                        <div className="box-left">
                            <img src="../images/hela.gif?v=1" alt="" />
                        </div>
                        <div className="box-right">
                            <div className="row-info-after-mint">
                                <div className="title">
                                    Funny Merlion NFT
                                </div>
                                <div className="desc">
                                    Being a member of an exclusive community of cartoon unicorn is cool. But being a member of said group with an official, swaggy membership card, is even cooler! People want benefits and guess what, we have them!
                                </div>
                                <div className="process-mint">
                                    <div className="title-process text-darkblue text-16">
                                        Available items
                                    </div>
                                    <Progress percent={valueQuanlity.toFixed(2)} status="active" />
                                    <div className="number-mint text-darkblue text-16 text-weight-500">
                                        {dataQuality?.totalCurrentNFT}/10,000 NFT
                                    </div>
                                </div>
                                {dataStart < dataNow ?
                                    <>
                                        <div className="mint-release">
                                            <div className="open-minting">
                                                Open Minting:
                                            </div>
                                            <div className="mint-balance">
                                                <img src="./images/icon-mint.png" alt="" /> {count > 0 ? (count*totalMint).toLocaleString() : 0} HLUP <span>N/A</span>
                                            </div>
                                            <div className="mint-button">
                                                {/* <div className="left">
                                                    <span className="plus" onClick={handleChangeApart}>
                                                        -
                                                    </span>
                                                    <span className="value">
                                                        {count > 0 ? count : 0}
                                                    </span>

                                                    <span className="plus" onClick={handleChangePlus}>
                                                        +
                                                    </span>
                                                </div> */}
                                                <div className="right">
                                                    {hlupBalance > Number(count*totalMint) ? (
                                                        <>
                                                            {mintedWallet < Number(dataQuality?.maxNftPerWallet) ? (
                                                                <>
                                                                    {allowanceToken > 0 ?
                                                                        <>
                                                                            {loadingMint ?
                                                                                <>
                                                                                    <button type="button" disabled={loadingMint} className="btn-mint-row">
                                                                                        <Spin /> Mint NFT
                                                                                    </button>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <button type="button" className="btn-mint-row" onClick={handleMint}>
                                                                                        Mint NFT
                                                                                    </button>
                                                                                </>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {loadingApprove ?
                                                                                <>
                                                                                    <button type="button" disabled={loadingApprove} className="btn-mint-row">
                                                                                        <Spin /> Approve
                                                                                    </button>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <button type="button" className="btn-mint-row" onClick={handleApproveToken}>
                                                                                        Approve
                                                                                    </button>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            ):(
                                                                <>
                                                                    <button type="button" disabled className="btn-mint-row">
                                                                        Minted {mintedWallet}/{dataQuality?.maxNftPerWallet}
                                                                    </button>
                                                                </>
                                                            )}
                                                            
                                                        </>
                                                    ):(
                                                        <>
                                                            <button type="button" disabled className="btn-mint-row">
                                                                No enought balance
                                                            </button>
                                                        </>
                                                    )}
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="mint-start">
                                            <div className="start-now">
                                                Mint starts {moment(dataQuality?.startMintTime).format('LLL')}
                                            </div>
                                            <div className="start-in">
                                                Start in:
                                            </div>
                                            <div className="start-time">
                                                {moment(dataQuality?.startMintTime).format('LLL')}
                                            </div>
                                        </div>
                                    </>
                                }


                                <div className="mint-schedule">
                                    <div className="title-schedule">
                                        Mint schedule
                                    </div>
                                    <div className="schedule-box">
                                        <div className="eligible">
                                            Not Eligible
                                        </div>
                                        <div className="mint-public">
                                            Whitelisted Mint
                                        </div>
                                        <div className="time-public">
                                            {moment(dataQuality?.startMintTime).format('LLL')}
                                        </div>
                                        <div className="balace-public">
                                            5,000 HLUP
                                        </div>
                                    </div>
                                    <div className="schedule-box">
                                        <div className="eligible active">
                                            Eligible
                                        </div>
                                        <div className="mint-public">
                                            Public Mint
                                        </div>
                                        <div className="time-public">
                                            {moment(dataQuality?.startMintTime).format('LLL')}
                                        </div>
                                        <div className="balace-public">
                                            5,000 HLUP
                                        </div>
                                        <div className="time-public padd-15">
                                            {/* Limit: <span className="text-darkblue text-weight-600">{dataQuality?.maxNftPerWallet}</span> wallet */}
                                            You already reached your max mint.<span className="text-darkblue text-weight-600"> {mintedWallet > 0 && (<>{mintedWallet}/ </>)} {dataQuality?.maxNftPerWallet} </span> per wallet.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-mint" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="modal-content-mint">
                    <div className="title">
                        Congrats
                    </div>
                    <div className="desc">
                        You just got for yourself a Badge
                    </div>
                    <div className="img-modal">
                        <img src={dataNFT?.nftImageURL} alt="" />
                    </div>
                    <div className="name-modal">
                        <a href={`/nft-owner-details/${dataNFT?.id}`}>{dataNFT?.name}</a>
                    </div>
                    
                </div>
            </Modal>
            {loadingApprove && (<Loader />)}
            {loadingMint && (<Loader />)}
            <FooterHome />
        </>
    )
}
export default MintFunction