import React, {useEffect, useState} from 'react';
import ItemIDO from './itemIdo';
import {useSelector, useDispatch} from 'react-redux';
import allActions from '../../actions';
import NodataImg from '../common/asset/images/nodata.svg';
import { Pagination, Spin } from "antd";
import { Loader, LoadingContent } from '../common/component'; 

const Completed = () => {
    const dispatch = useDispatch();
    const [loadingPage, setLoadingPage] = useState(false);
    const [page, setPage] = useState(0);
    const [campaignPrices, setCampaignPrices] = useState([]);
    let size = 8;
    const status = 'COMPLETED';
    const sort = 'startIDO,desc';

    useEffect(() => {
        setLoadingPage(true);
        dispatch(allActions.campaignAction.getCampaignsByStatus({status, sort, page, size}, (res) => {
            if(res.callResult === true) {
                dispatch(allActions.campaignAction.getPriceMarket(res.campaignList, (res)=>{
                    setCampaignPrices(res?.data);
                })); 
                setLoadingPage(false);
            }
        }));
      
    }, [dispatch ,page, size]);

    function onChangePaging(pageNumber) {
        setPage(pageNumber - 1);
    }

    const campaignsData = useSelector(state => state.campaignsData);
    const {campaignList, campaignsCount} = campaignsData;
    
    // const ItemIDOList = campaignList && campaignList.map((item,key) => (
    //     <ItemIDO key={key} item={item} campaignPrices={campaignPrices} />
    // ));

    return(
        <>
            {/* <LoadingContent /> */}
            {/* <SortBy /> */}
            <div className="c-list is-completed">
                {loadingPage && <Loader />}
                {campaignList && campaignList.length > 0 ? (
                    <>
                        {campaignList.map((item, key) => (
                            <ItemIDO key={key} item={item} campaignPrices={campaignPrices} />
                        ))}
                    </>
                ):(
                    <>
                    <div className="c-nodata c-nodata--upcoming">
                        <img src={NodataImg} alt="No Data" />
                    </div>
                    </>
                )}
             
            </div>
            <div className="pani-list">
                {campaignList && campaignList.length > 0 && 
                <Pagination
                    defaultCurrent={1}
                    total={campaignsCount}
                    pageSize = {size}
                    onChange={onChangePaging}
                    showSizeChanger={false}
                />}
            </div>
      </>
    );
};

export default Completed;