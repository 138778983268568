
import React, { useState, useEffect, useCallback } from 'react';

import { BrowserRouter as Router, Route, Link, Switch, useLocation, useParams, useNavigate } from 'react-router-dom';
import { Layout, Row, Col, Form, Select, Input, Space, Spin, Breadcrumb, Modal } from 'antd';
import { RedoOutlined } from '@ant-design/icons'
import Web3 from "web3";
import NoneData from '../element/NoneData';
import FooterHome from '../common/footer/footerHome';
import NodataImg from '../common/asset/images/nodata-upcoming.png';
import { useActiveWeb3React } from '../../hooks';
import HelascriptionItem from './helascription-item';
import { HelascriptionApi } from '../../config/api/apiHelascription';
import addNotify from '../common/Notify/addNotify';
import { Store } from 'react-notifications-component';
import { Loader } from '../common/component';
const { Content } = Layout;
const { Option } = Select;

const HelascripstionPerson = () => {
    const { chainId, account, library } = useActiveWeb3React();
    const [itemTransfer, setItemTransfer] = useState();
    const [showTransfer, setShowTransfer] = useState(false);
    const [classTab, setClassTabs] = useState('');
    const navigate = useNavigate();
    const [loadingDeloy, setLoadingDeloy] = useState(false);
    const [form] = Form.useForm();
    const [helascriptions, setHelascriptions] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const web3 = new Web3(window.ethereum);

    const [param, setParam] = useState(
        {
            page: 1,
            size: 20
        }
    );

    const getHelascriptionList = async () => {
        setLoadingData(true);
        try {
            let res = await HelascriptionApi.getTokensPerson(param, account);
            
            if (res.status === 200) {
                if (res.data.data.length > 0) {
                    setLoadingData(false);
                    setHelascriptions(res.data.data);
                } else {
                    setLoadingData(false);
                    setHelascriptions([]);
                }
            }
        } catch (error) { console.log('Error: ', error); }
        finally {
            setLoadingData(false);
        }
    }
    useEffect(() => {
        if(account){
            getHelascriptionList();
        }
    }, [param, account]);

    const RefreshList = ()=>{
        getHelascriptionList();
    }
    const handleShowTransfer = (item)=>{
        setItemTransfer(item);
        setShowTransfer(true);
    }

    const onConfirmDeloy = async (value)=>{
        setLoadingDeloy(true);

        console.log('value', itemTransfer);

        let data = {
            "p": itemTransfer?.token?.protocol,
            "op":"transfer",
            "tick": itemTransfer?.tick,
            "amount": value?.amount
        }

        const dataToSend = web3.utils.asciiToHex(`data:,${JSON.stringify(data)}`); // Convert 'xin chào' string to hex

        await web3.eth.sendTransaction({
            from:account,
            to: value?.to,
            value: '0',
            data: dataToSend
        }).then((res) => {
            if(res && res.transactionHash){
                // console.log('Transaction Hash:', res.transactionHash);
                Store.addNotification(addNotify('Transfer Successfully.', 'success'));
                setLoadingDeloy(false);
                setShowTransfer(false);
                getHelascriptionList();
            }
        }).catch((error) => {
            setLoadingDeloy(false);
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        });
        
        
    }
    const cancelDeloy = ()=>{
        setShowTransfer(false);
        form.resetFields();
    }

    return (
        <> 
            <div className='page-helascriptions'>
                
                <div className="container">
                    <Breadcrumb className='head-breadcrumb-airdrop'>
                        <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Stake Token</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className='p-helascriptions-title'>
                        
                        <div className='d-flex'>
                            <button className='btn-refresh' onClick={RefreshList}><RedoOutlined /> Refresh</button>
                        </div>
                    </div>
                    <div className="project-block">
                       
                        <div className="project-content">
                            <Row gutter={20}>
                                {!loadingData ? (
                                <>
                                    {helascriptions?.length > 0 ? (
                                        helascriptions.map((item, index) => {
                                            return (
                                                <Col className="gutter-row" xs={24} xl={6} md={6} sm={6} key={item?.id}>
                                                    <div className='person-item'>
                                                        <div className='bg-darkblue p-10'>
                                                            <div className='text-16 text-weight-500'>{item?.tick}</div>
                                                            <div className='text-18 text-weight-600 text-center'>{Number(item?.balance).toLocaleString()}</div>
                                                            {/* <div className='text-16 text-center'>1 mints</div> */}
                                                        </div>
                                                        <div className='mt-10'>
                                                            <span className='text-darkblue text-16 text-weight-500'>#{item?.token_id.substring(0, 4)}...{item?.token_id.substring(item?.token_id.length - 4)}</span>
                                                        </div>
                                                        <div className='mt-10'>
                                                            <button className='btn-basic' disabled={Number(item?.balance) == 0} onClick={()=>handleShowTransfer(item)}>Transfer</button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    ) : (
                                        <>
                                            <div className="c-nodata c-nodata--upcoming">
                                                <NoneData />
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (<>
                                <div className="loading-data">
                                    <Space size="middle">
                                        <Spin size="large" />
                                    </Space>
                                </div>
                            </>)}
                            </Row>
                        </div>
                    </div>
                </div>
                
            </div>
            <Modal className="modal-mint modal-list-done" open={showTransfer} onOk={()=> setShowTransfer(false)} onCancel={()=> setShowTransfer(false)}>
                <Form form={form} layout="vertical" autoComplete="off" className="basic-form"
                    onFinish={onConfirmDeloy}
                >
                    <div className="modal-content-mint">
                        <div className="title text-green">
                            Transfer
                        </div>
                        <div className="modal-make-offer">
                           
                            <Form.Item
                                label="Amount"
                                name="amount"
                                initialValue={itemTransfer?.balance}
                                rules={[
                                    { required: true, message: 'Please input amount!' },
                                    {
                                        validator: (rule, value, cb) => {
                                            !value || parseFloat(value) <= 0
                                                ? cb("Amount must be > 0")
                                                : cb();
                                        }
                                    }
                                ]}
                            >
                                <Input type="number" placeholder="21000000"/>
                            </Form.Item>
                            <Form.Item
                                label="To"
                                name="to"
                                rules={[
                                    { required: true, message: 'Please input address!' }
                                ]}
                            >
                                <Input type="text" placeholder="0x..."/>
                            </Form.Item>
                        </div>
                        
                        
                        
                        <div className="text-center mt-15">
                            <button type="button" className="btn-basic-default w-fitcontent" onClick={cancelDeloy}>Cancel</button>
                            <button type="submit" disabled={!account || loadingDeloy} className="btn-basic w-200">Transfer {loadingDeloy && <Spin size="small" />}</button>
                        </div>
                    </div>
                </Form>
                
            </Modal>
            {loadingDeloy && (<Loader />)}
            <FooterHome />
        </>
    )
}

export default HelascripstionPerson
