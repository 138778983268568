import React, { useEffect, useState } from "react";
import bgMainheader from "../banner-nft.png";
import { Breadcrumb, Col, Empty, Row, Spin } from 'antd';
import { getData , postData } from "../axios";
import { Link, useLocation , useNavigate, useParams } from "react-router-dom";
import FooterHome from '../../common/footer/footerHome';
import moment from "moment";
import { useActiveWeb3React } from "../../../hooks";
import web3 from "web3";
import { useAutionNFTContract, useMarketNFTContract, useNftContract } from "../../../hooks/useContract";
import { Store } from "react-notifications-component";
import addNotify from "../../common/Notify/addNotify";
import { _cancelListing, _cancelListingAuction } from "../utilsNFT";
import { NftApi } from "../../../config/api/apiNFT";
import NftLogs from "../nft-logs";
import { AUCTION_ADDRESS_SC, MAPPING_CHAINID_DECIMAL, MARKET_ADDRESS_SC, NFT_ADDRESS_SC } from "../../../constants";
import OfferHistory from "../offer-history";
import ChainNotSupport from "../../chain-not-support";

const OwnerDetails = () => {
    const { account, chainId } = useActiveWeb3React();
    let { id } = useParams();
    const [showInfo, setShowInfo] = useState(false);
    const [showAtt, setShowAtt] = useState(false);
    const [showActive, setShowActive] = useState(false);
    const [showItem, setShowItem] = useState(false);
    const [loading, setLoading] = useState(false);
    const [priceListing, setPriceListing] = useState(0);
    const [data, setData] = useState()
    const [isListed, setIsListed] = useState(false);
    const [auctionIndex, setAuctionIndex] = useState(0);
    const [bidCount, setBidCount] = useState(0);
    const [endAuction, setEndAuction] = useState(0);
    const [currentBidPrice, setCurrentBidPrice] = useState(0);
    const [currentBidOwner, setCurrentBidOwner] = useState('');
    const [auctionStatus, setAuctionStatus] = useState('');
    const [showActiveOffer, setShowActiveOffer] = useState(false);

    const MARKET_ADDRESS = MARKET_ADDRESS_SC;
    const NFT_ADDRESS = NFT_ADDRESS_SC;
    const AUCTION_ADDRESS = AUCTION_ADDRESS_SC;

    // const contractNft = useNftContract(NFT_ADDRESS);

    const marketContract = useMarketNFTContract(MARKET_ADDRESS);

    const contractBid = useAutionNFTContract(AUCTION_ADDRESS);

    const navigate = useNavigate();

    const handleShow = () => {
        setShowInfo(!showInfo)
    }

    const handleAtt = () => {
        setShowAtt(!showAtt)
    }

    const handleActive = () => {
        setShowActive(!showActive)
    }
    const handleActiveOffer = () => {
        setShowActiveOffer(!showActiveOffer)
    }
    

    const fetchListData = async () => {
        await getData(`${process.env.REACT_APP_API_URL}/merlion-nfts/${id}/details`
        ).then(res => {
            setData(res)
        })
    }

    useEffect(() => {
        if(!data){
            fetchListData()
        }
    }, [id]);

    let typeOfSale = data?.marketplaceDetails?.typeOfSale;

    const checkIsListed = async () => {
        if(typeOfSale === "FIXED"){
            await marketContract.listings(NFT_ADDRESS, Number(data?.nftId)).then(res => {
                let price = web3.utils.fromWei(web3.utils.toBN(res.price.toString()), "ether");
                if(Number(price) > 0){
                    setIsListed(true);
                    setPriceListing(Number(price));
                }
                
            }).catch((err)=>{
                console.log('FIXED checkIsListed', err)
            })
        }else{
            await contractBid.nftIdToIndexes(Number(data?.nftId)).then(res => {
                let auctionIndex = res.toString();
               
                if(Number(auctionIndex) !== 0){
                    setIsListed(true);
                    setAuctionIndex(Number(auctionIndex));
                }
                
            }).catch((err)=>{
                console.log('Auction checkIsListed', err)
            })
        }
    }

    const checkNftAuction = async () => {
        if(typeOfSale !== "FIXED"){
            await contractBid.allAuctions(auctionIndex).then(res => {
                let auctionData = res;
                console.log('auctionData', auctionData)
                let bidCount = web3.utils.fromWei(web3.utils.toBN(auctionData.bidCount.toString()), "ether");
                if(Number(bidCount) > 0){
                    setBidCount(Number(bidCount));
                }
                let endAuction = auctionData?.endAuction ? auctionData?.endAuction.toString() : 0;
                if(endAuction > 0){
                    setEndAuction(endAuction);
                }
                let status = auctionData?.status ? auctionData?.status.toString() : 0;
                if(Number(status) > 0){
                    setAuctionStatus(Number(status));
                }
                let currentBidOwner = auctionData?.currentBidOwner ? auctionData?.currentBidOwner.toString() : '';
                if(currentBidOwner){
                    setCurrentBidOwner(currentBidOwner);
                }
                let currentBidPrice = web3.utils.fromWei(web3.utils.toBN(auctionData.currentBidPrice.toString()), "ether");
                if(Number(currentBidPrice) > 0){
                    setCurrentBidPrice(Number(currentBidPrice));
                }
            }).catch((err)=>{
                console.log('Auction checkNftAuction', err)
            })
        }
    }
    useEffect(() => {
        if(account && data && auctionIndex){
            checkNftAuction()
        }
    }, [account, data, auctionIndex]);

    useEffect(() => {
        if(account && data){
            checkIsListed();
        }
        
    }, [account, data]);

    

    

    useEffect(() => {
        if(data && account && account.toLowerCase() !== data?.ownerAddress.toLowerCase()){
            navigate(`/nft-details/${id}`)
        }
        
    }, [account, data]);

    

    const handleListForSale = async (id)=>{
        navigate(`/nft-owner/list-for-sale/${id}`)
    }

    const handleCancelListing = async ()=>{
        setLoading(true);
        console.log('data', data);
        
        if (!marketContract) {
            Store.addNotification(addNotify('Market contract invalid.', 'danger'));
            return;
        }
        if (Number(data?.chainId) !== chainId) {
            Store.addNotification(addNotify('Invalid sale price.', 'danger'));
            return;
        }

        await _cancelListing(marketContract, NFT_ADDRESS, Number(data?.nftId))
        .then((res) => {
            let resWait = res.wait();
            resWait.then(async (resTransaction) => {

                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    let payload = {
                        // "finishDate": "2023-12-14T13:28:42.579Z",
                        "id": data.id,
                        "marketHash": res.hash,
                        "ownerAddress": account,
                        // "startDate": "2023-12-14T13:28:42.579Z",
                        "startPrice": 0,
                        "typeOfSale": "FIXED"
                    };
                    let resAp = await NftApi.unListingNft(data.id, payload);
                    if (resAp.status === 200) {
                        Store.addNotification(addNotify('UnList successfully', 'success'));
                        setLoading(false);
                        setTimeout(() => {
                            window.location.reload();
                        }, 1500);
                        
                    }else{
                        Store.addNotification(addNotify('Server Error', 'danger'));
                        setLoading(false);
                    }
                    
                }
                else {
                    setLoading(false);
                    Store.addNotification(addNotify('Transaction failed', 'warning'));
                    return;
                }

            })
            .catch((error) => {
                setLoading(false);
                if (error.data) {
                    Store.addNotification(addNotify("Transaction failed", 'danger'));
                }
                if (error) {
                    Store.addNotification(addNotify(error, 'danger'));
                }
            });
        })
        .catch((error) => {
            setLoading(false);
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        });
    }
    const handleCancelListingAuction = async ()=>{
        setLoading(true);
        console.log('data', data);
        
        if (!contractBid) {
            Store.addNotification(addNotify('Auction contract invalid.', 'danger'));
            return;
        }
        if (auctionIndex === 0) {
            Store.addNotification(addNotify('Auction ID invalid.', 'danger'));
            return;
        }
        if (Number(data?.chainId) !== chainId) {
            Store.addNotification(addNotify('Invalid sale price.', 'danger'));
            return;
        }

        await _cancelListingAuction(contractBid, Number(data?.nftId))
        .then((res) => {
            let resWait = res.wait();
            resWait.then(async (resTransaction) => {

                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    let payload = {
                        // "finishDate": "2023-12-14T13:28:42.579Z",
                        "id": data.id,
                        "marketHash": res.hash,
                        "ownerAddress": account,
                        // "startDate": "2023-12-14T13:28:42.579Z",
                        "startPrice": 0,
                        "typeOfSale": "AUCTION"
                    };
                    await NftApi.unListingNft(data.id, payload).then((resAp)=>{
                        if (resAp.status === 200) {
                            Store.addNotification(addNotify('UnList successfully', 'success'));
                            setLoading(false);
                            setTimeout(() => {
                                window.location.reload();
                            }, 1500);
                            
                        }else{
                            Store.addNotification(addNotify('UnList Error', 'danger'));
                            setLoading(false);
                        }
                    }).catch((er)=>{
                            Store.addNotification(addNotify('Server Error', 'danger'));
                            setLoading(false);
                    });
                    
                    
                }
                else {
                    setLoading(false);
                    Store.addNotification(addNotify('Transaction failed', 'warning'));
                    return;
                }

            })
            .catch((error) => {
                setLoading(false);
                if (error.data) {
                    Store.addNotification(addNotify("Transaction failed", 'danger'));
                }
                if (error) {
                    Store.addNotification(addNotify(error?.reason, 'danger'));
                }
            });
        })
        .catch((error) => {
            setLoading(false);
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        });
    }
    

    const Item = ({item}) => {
        const handleRedirect = (id) => {
            navigate(`/nft-owner-details/${id}`)
        }

        return (
            <>
                <Col className="gutter-row" xs={24} xl={6} md={6} sm={6} key={item.id}>
                    <div className="nft-item" onClick={() => handleRedirect(item?.merlionNft.id)}>
                        <div className="nft-img">
                            <img src={item?.merlionNft.nftImageURL} alt="" />
                        </div>
                        <div className="nft-info">
                            <span>NFT Collection</span>
                            <span>{item?.merlionNft.name}</span>
                        </div>
                        <div className="nft-info">
                            <span>Curent price:</span>
                            <span>{item?.price ? Number(item?.price).toLocaleString() : '___'} HLUP</span>
                        </div>
                    </div>
                </Col>
            </>
        )
    }

    if(MAPPING_CHAINID_DECIMAL.helatestnet !== chainId){
        return(
            <><ChainNotSupport /></>
        )
    }

    return (
        <>
            <div className="main-nft-section">
                <div className="container">
                    <div className="box-details-nft">
                        <Breadcrumb className='head-breadcrumb-detail'>
                            <Breadcrumb.Item><a href='/genesis-nft/profile'>NFT profile</a></Breadcrumb.Item>
                            <Breadcrumb.Item><a href='#'>Detail NFT's name</a></Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="mint-after-box">
                            <div className="box-left">
                                <img src={data?.nftImageURL} alt="" />
                            </div>
                            <div className="box-right">
                                <div className="bid-details">
                                    <div className="row-user">
                                        <img src="../../images/user-nft.png" alt="" /> <span>Funny Merlion NFT</span> <img src="../../images/checked.png" alt="" />
                                    </div>
                                    <div className="bid-name">
                                        {data?.name}
                                    </div>
                                    <div className="bid-owner-address">
                                        Owned by: <span>{data?.ownerAddress.substring(0, 4)}...{data?.ownerAddress.substring(data?.ownerAddress.length - 4)}<img src="../../images/copy.png" alt="" /></span>
                                    </div>
                                    <div className="mint-release">
                                        <div className="open-minting">
                                            Current price:
                                        </div>
                                        <div className="mint-balance">
                                            <img src="../../images/icon-mint.png" alt="" /> {data?.marketplaceDetails?.price ? Number(data?.marketplaceDetails?.price).toLocaleString() : priceListing.toLocaleString()} HLUP
                                        </div>
                                        <div className="mint-button">
                                            {typeOfSale === "AUCTION" ? (
                                                <>
                                                    {auctionIndex !== 0 ? (
                                                        <>
                                                            {bidCount === 0 && (
                                                                <button type="button" disabled={loading} className="btn-basic-default" onClick={()=>handleCancelListingAuction()}>
                                                                    Cancel {loading && (<Spin />)}
                                                                </button>
                                                            )}
                                                            
                                                        </>
                                                    ):(<>
                                                        <button type="button" disabled={loading} className="btn-basic" onClick={()=>handleListForSale(id)}>
                                                            List for sale {loading && (<Spin />)}
                                                        </button>
                                                    </>)}
                                                    
                                                </>
                                            ):(
                                                <>
                                                    {isListed ? (
                                                        <>
                                                            <button type="button" disabled={loading} className="btn-basic-default" onClick={()=>handleCancelListing()}>
                                                                Cancel {loading && (<Spin />)}
                                                            </button>
                                                        </>
                                                    ):(
                                                        <>
                                                            <button type="button" disabled={loading} className="btn-basic" onClick={()=>handleListForSale(id)}>
                                                                List for sale {loading && (<Spin />)}
                                                            </button>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            
                                            
                                        </div>
                                    </div>
                                    <div className="group-detail-bid">
                                        <div className="title" onClick={handleShow}>
                                            <span className="left">
                                                Details
                                            </span>
                                            {!showInfo ?
                                                <>
                                                    <span className="right active">
                                                        <img src="../../images/arrow-circle-down-1.png" alt="" />
                                                    </span>
                                                </>
                                                :
                                                <>
                                                    <span className="right">
                                                        <img src="../../images/arrow-circle-down.png" alt="" />
                                                    </span>
                                                </>
                                            }
                                        </div>
                                        <div className={!showInfo ? "box-content" : "box-content dis-none"}>
                                            <ul>
                                                <li>
                                                    <div className="txt-left">
                                                        Contract address:
                                                    </div>
                                                    <div className="txt-right">
                                                        {data?.marketplaceDetails?.marketAddress.substring(0, 4)}...{data?.marketplaceDetails?.marketAddress.substring(data?.ownerAddress.length - 4)}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="txt-left">
                                                        Token ID:
                                                    </div>
                                                    <div className="txt-right">
                                                        {data?.nftId ? data?.nftId : "---"}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="txt-left">
                                                        Token Standard:
                                                    </div>
                                                    <div className="txt-right">
                                                        {data?.tokenStandard ? data?.tokenStandard : "---"}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="txt-left">
                                                        Chain:
                                                    </div>
                                                    <div className="txt-right">
                                                        {data?.networkEnum}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="txt-left">
                                                        Creator fees:
                                                    </div>
                                                    <div className="txt-right">
                                                        {data?.creatorFee ? data?.creatorFee : "---"} %
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="txt-left">
                                                        Last Updated:
                                                    </div>
                                                    <div className="txt-right">
                                                        {moment(data?.lastModifiedDate).format('MMMM Do YYYY, h:mm:ss a')}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="group-detail-bid">
                                        <div className="title" onClick={handleAtt}>
                                            <span className="left">
                                                Traits
                                            </span>
                                            {showAtt ?
                                                <>
                                                    <span className="right active">
                                                        <img src="../../images/arrow-circle-down-1.png" alt="" />
                                                    </span>
                                                </>
                                                :
                                                <>
                                                    <span className="right">
                                                        <img src="../../images/arrow-circle-down.png" alt="" />
                                                    </span>
                                                </>
                                            }
                                        </div>
                                        <div className={showAtt ? "box-content res" : "box-content res dis-none"}>
                                            <ul className="list-modal">
                                                <li>
                                                    <div className="content">
                                                        <span className="att-1">
                                                            {data?.attributes?.background?.attributeTypeEnum}
                                                        </span>
                                                        <span className="att-2">
                                                            {data?.attributes?.background?.name} {data?.attributes?.background?.rate}%
                                                        </span>
                                                        <span className="att-3">
                                                            Floor: {data?.attributes?.background?.floor} HLUP
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="content">
                                                        <span className="att-1">
                                                            {data?.attributes?.face?.attributeTypeEnum}
                                                        </span>
                                                        <span className="att-2">
                                                            {data?.attributes?.face?.name} {data?.attributes?.face?.rate}%
                                                        </span>
                                                        <span className="att-3">
                                                            Floor: {data?.attributes?.face?.floor} HLUP
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="content">
                                                        <span className="att-1">
                                                            {data?.attributes?.clothes?.attributeTypeEnum}
                                                        </span>
                                                        <span className="att-2">
                                                            {data?.attributes?.clothes?.name} {data?.attributes?.clothes?.rate}%
                                                        </span>
                                                        <span className="att-3">
                                                            Floor: {data?.attributes?.clothes?.floor} HLUP
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="content">
                                                        <span className="att-1">
                                                            {data?.attributes?.body?.attributeTypeEnum}
                                                        </span>
                                                        <span className="att-2">
                                                            {data?.attributes?.body?.name} {data?.attributes?.body?.rate}%
                                                        </span>
                                                        <span className="att-3">
                                                            Floor: {data?.attributes?.body?.floor} HLUP
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="content">
                                                        <span className="att-1">
                                                            {data?.attributes?.hat?.attributeTypeEnum}
                                                        </span>
                                                        <span className="att-2">
                                                            {data?.attributes?.hat?.name} {data?.attributes?.hat?.rate}%
                                                        </span>
                                                        <span className="att-3">
                                                            Floor: {data?.attributes?.hat?.floor} HLUP
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="content">
                                                        <span className="att-1">
                                                            {data?.attributes?.item?.attributeTypeEnum}
                                                        </span>
                                                        <span className="att-2">
                                                            {data?.attributes?.item?.name} {data?.attributes?.item?.rate}%
                                                        </span>
                                                        <span className="att-3">
                                                            Floor: {data?.attributes?.item?.floor} HLUP
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {typeOfSale === "AUCTION" && (
                            <>
                                <div className="group-detail-bid mb-15">
                                    <div className="title" onClick={handleActiveOffer}>
                                        <span className="left">
                                            Offer history
                                        </span>
                                        {!showActive ?
                                            <>
                                                <span className="right active">
                                                    <img src="../../images/arrow-circle-down-1.png" alt="" />
                                                </span>
                                            </>
                                            :
                                            <>
                                                <span className="right">
                                                    <img src="../../images/arrow-circle-down.png" alt="" />
                                                </span>
                                            </>
                                        }
                                    </div>
                                    <div className={!showActiveOffer ? "box-content" : "box-content dis-none"}>
                                        <div className="main-table">
                                            <OfferHistory data={data} endAuction={endAuction} currentBidOwner={currentBidOwner} currentBidPrice={currentBidPrice} auctionIndex={auctionIndex} auctionStatus={auctionStatus}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="group-detail-bid">
                            <div className="title" onClick={handleActive}>
                                <span className="left">
                                    Activities
                                </span>
                                {!showActive ?
                                    <>
                                        <span className="right active">
                                            <img src="../../images/arrow-circle-down-1.png" alt="" />
                                        </span>
                                    </>
                                    :
                                    <>
                                        <span className="right">
                                            <img src="../../images/arrow-circle-down.png" alt="" />
                                        </span>
                                    </>
                                }
                            </div>
                            <div className={!showActive ? "box-content" : "box-content dis-none"}>
                                <NftLogs data={data} />
                            </div>
                        </div>
                                
                    </div>
                </div>
            </div>
            <FooterHome />
        </>
    )
}
export default OwnerDetails
