import { instanceHelascriptions } from '../../apiBase/instanceHelascriptions';

export const HelascriptionApi = {

	getHelascription(params) {
		return instanceHelascriptions.get(`/inscriptions`, { params }, '');
	},
	getDetailHelascription(id) {
		return instanceHelascriptions.get(`/inscriptions/${id}`, { }, '');
	},
	getTokens(params) {
		return instanceHelascriptions.get(`/tokens`, { params }, '');
	},

	getDetailToken(id) {
		return instanceHelascriptions.get(`/tokens/${id}`, { }, '');
	},
	getTokensPerson(params, account) {
		return instanceHelascriptions.get(`/tokens/wallets/${account}`, { params }, '');
	},
};
