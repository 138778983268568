import { 
    GET_AIRDROP_BY_HANDLE,
    GET_AIRDROP_BY_STATUS,
    GET_WHITELIST_AIRDROP_BY_ADDRESS
} from "../actions/airdropType";

const initialState = [];

const airdrop = (airdrop = initialState, action) => {
    switch(action.type){
        case GET_AIRDROP_BY_STATUS:
            return action.payload;

        case GET_AIRDROP_BY_HANDLE:
            return {
                ...airdrop,
                airdropData: action.payload
            }

        case GET_WHITELIST_AIRDROP_BY_ADDRESS:
            return {
                ...airdrop,
                airdropWhitelist: action.payload
            }
            
        default:
            return airdrop
    }
}

export default airdrop;