import http from "./http-common";

const updateClaimIdo = (payload) => {
  return http.put("/campaign/claim-ido/client", payload);
};

const getSignatureClaimIdo = payload => {
  return http.post("/allocations/get-sign-claim", payload);
}
//model 1
const getSignatureRefundIdo = payload => {
  return http.post("/allocations/get-sign-refund", payload);
}
const getAllocationByAddress = (roundId, account) => {
  return http.get(`/allocations/${roundId}/${account}`);
}
const updateRefund = (payload) => {
  return http.put(`/allocations/refunded`, payload);
}


const AllocationService = {
  updateClaimIdo,
  getSignatureClaimIdo,
  getAllocationByAddress,
  getSignatureRefundIdo,
  updateRefund,
};
export default AllocationService;