import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
// import 'antd/dist/antd.css';
import { Layout, Row, Col, Form, Select, Space, Spin, Tag, Tabs, Pagination, Breadcrumb } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';

import { useActiveWeb3React } from '../../../hooks';
// import FilterChain from 'components/FilterChain';

import { AirdropApi } from '../../../config/api/airdropApi';

import NoneData from '../../element/NoneData';
import { convertDateTime } from '../../../utils/formatDatetime';
import FooterHome from '../../common/footer/footerHome';
import { CHAINID_FULLNAME_BY_ID } from '../../../constants';

const { TabPane } = Tabs;
const { Content } = Layout;

const AirdropList = () => {

    const [dataAirDrops, setDataAirDrops] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const { account, chainId } = useActiveWeb3React();
    const [activeTab, setActiveTab] = useState('all');
    const [totalRow, setTotalRow] = useState(0);
    const [sltChain , setSltChain] = useState(0);

    const [form] = Form.useForm();
    const [param, setParam] = useState(
        {
            page: 0,
            size: 9,
            sort: 'id,desc',
            chainId: 0
        }
    );

    const getAirDrops = async () => {
        setLoadingData(true);
        
        try {
            let res = await AirdropApi.getAirdrops(param);
            
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200 && res.data.length > 0) {
                setDataAirDrops(res.data);
            } else {
                setDataAirDrops([]);
            }
            setLoadingData(false);
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            setLoadingData(false);
        }
    };

    const getYourAirdrops = async () => {
        setLoadingData(true);
        
        try {
            let res = await AirdropApi.getOwnerAirdrops(param, account);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                setDataAirDrops(res.data);

            } else {
                setDataAirDrops([]);
            }
            setLoadingData(false);
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            setLoadingData(false);
        }
    };

    useEffect(() => {
        
            if (activeTab == 'all') {
                getAirDrops();
            }else{
                if(account){
                    getYourAirdrops();
                }
                
            }
        
        
    }, [activeTab, param, account]);

    const onChange_Pagi = (pageNumber) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
    };

    const handleChangeSltChain = (value) => {
       setSltChain(value);
        setParam({
            ...param,
            chainId: value
        });
    };

    const StatusRender = (nowDate, startDate, endDate, roundStatus) => {
        let status = '';
        let color = '';
        if (startDate > nowDate && endDate > nowDate) {
            status = 'upcoming';
            color = '#108ee9';
        }
        if (startDate < nowDate && endDate > nowDate) {
            status = 'opening';
            color = '#f5c112';
        }
        if (startDate < nowDate && endDate < nowDate) {
            status = 'ended';
            color = '#f50';
        }
        if (roundStatus == 'CANCEL') {
            status = 'canceled';
            color = '#676767';
        }
        return <Tag className='tag-status' color={color}>{status}</Tag>;
    }

    function isImgLink(url) {
        if(typeof url !== 'string') return false;
        return(url.match(/^http[^\?]*.(jpg|jpeg|gif|png|tiff|bmp|webp)(\?(.*))?$/gmi) != null);
    }

    const AirdropContent = () => {
        return (
            <>
                <Row gutter={16}>
                    {!loadingData ? (
                        <>
                            {dataAirDrops?.length > 0 ? (
                                dataAirDrops.map((item, index) => {
                                    let nowDate = new Date(Date.now());
                                    let startDate = new Date(item?.startTime);
                                    let endDate = new Date(item?.endTime);

                                    let chainName = CHAINID_FULLNAME_BY_ID[item?.chainId];

                                    return (
                                        <Col xs={24} sm={8} xl={8} md={8} key={'item-airdrop-' + index}>
                                            <div className="airdrop-item">
                                                <div className="airdrop-image">
                                                    <Link to={`/airdrop/detail/${item?.airDropAddress}/${item?.handle}`}>
                                                        <img src={item?.logoUrl?.length > 0 && isImgLink(item?.logoUrl) ? item?.logoUrl : "../../images/airdrop-default.png"} />
                                                    </Link>
                                                </div>
                                                <div className="airdrop-content">
                                                    <div className="airdrop-name">
                                                        <Link title={item.airDropTitle} to={`/airdrop/detail/${item?.airDropAddress}/${item?.handle}`}>{item.airDropTitle}</Link>
                                                    </div>
                                                    <div className="airdrop-row">
                                                        <span>Start Time</span>
                                                        <span className='text-orange text-upper'>{item?.startTime ? convertDateTime(startDate) : '---'}</span>
                                                    </div>
                                                    <div className="airdrop-row">
                                                        <span>Token Reward</span>
                                                        <span><span className='text-info'>{new Intl.NumberFormat("ja-JP").format(item.totalTokenReward)}</span> {item.tokenSymbol}</span>
                                                    </div>
                                                    <div className="airdrop-row">
                                                        <span>Status</span>
                                                        <span className='text-capita'>{StatusRender(nowDate, startDate, endDate, item.airDropStatus)}</span>
                                                    </div>
                                                    <div className="airdrop-row">
                                                        <span>Network</span>
                                                        <span className='text-capita text-info'>{chainName}</span>
                                                    </div>
                                                    <div className="airdrop-btn">
                                                        {item.taskLink.length > 0 && (
                                                            <button type="button" className="btn-basic mr-10" onClick={()=> window.open(`${item.taskLink}`)}>
                                                                Join Airdrop <DoubleRightOutlined />
                                                            </button>
                                                        )}
                                                        <button type="button" className={item.taskLink.length > 0 ? 'btn-basic-default' : 'btn-basic'} onClick={()=> window.location.replace(`/airdrop/detail/${item.airDropAddress}/${item.handle}`)}>
                                                            {item.taskLink.length > 0 ? (
                                                                <>View Detail <DoubleRightOutlined /></>
                                                            ) : (
                                                                <>Claim Your Reward <DoubleRightOutlined /></>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })
                            ) : (
                                <>
                                    <Col className="text-center" xs={24}>
                                        <div className="no-data text-center">
                                            <img width={200} src="../images/box-nodata.svg" />
                                            <p className="text-black-green text-weight-500">No data.</p>
                                        </div>
                                    </Col>
                                </>
                            )}
                        </>
                    ) : (<>
                        <div className="loading-data">
                            <Space size="middle">
                                <Spin size="large" />
                            </Space>
                        </div>
                    </>)}

                </Row>
            </>
        );
    };

    const onChangeTabs = (key) => {
        setActiveTab(key);
    };

    return (
        <>
            <div className="airdrop-page">
                <div className='container'>
                    <Breadcrumb className='head-breadcrumb'>
                        <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Airdrop</Breadcrumb.Item>
                    </Breadcrumb>
                    <div className='airdrop-title'>UpSwap Airdrop</div>
                    <div className="airdrop-content">
                        <div className="projects-grid mt-15">
                            <Tabs defaultActiveKey="all" onChange={onChangeTabs} className="tab-ido-general c-tabs"
                                tabBarExtraContent={
                                    <>
                                        <div className='filter-block-form'>
                                            
                                            <Select
                                                className="custom-slt"
                                                placeholder="Network..."
                                                style={{ width: 190, marginRight: '10px' }}
                                                onChange={handleChangeSltChain}
                                                options={[
                                                    {
                                                        value: '8668',
                                                        label: 'HeLa',
                                                    },
                                                    {
                                                        value: '56',
                                                        label: 'BSC',
                                                    },
                                                    {
                                                        value: '137',
                                                        label: 'Polygon',
                                                    },
                                                    {
                                                        value: '1',
                                                        label: 'Ethereum',
                                                    },
                                                    {
                                                        value: '42161',
                                                        label: 'Arbitrum',
                                                    }
                                                ]}
                                            />
                                            <button type="button" className="btn-basic w-fitcontent" onClick={()=> window.location.replace('airdrop/create')}>
                                                Create Airdrop
                                            </button>
                                        </div>
                                    </>
                                }
                            >
                                <TabPane tab="All" key="all">
                                    <AirdropContent />
                                </TabPane>
                                <TabPane tab="Created By You" key="create-by-you">
                                    <AirdropContent />
                                </TabPane>

                            </Tabs>

                            {totalRow > 9 && (
                                <Pagination
                                    current={param.page + 1}
                                    defaultCurrent={1}
                                    total={totalRow}
                                    pageSize={param.size}
                                    onChange={onChange_Pagi}
                                    showSizeChanger={false}
                                    className="pagiation-custom"
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <FooterHome />
        </>
    )
}

export default AirdropList
