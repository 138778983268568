export const dexExchanges = {
	97: [{
		id: 1,
		routerAddress: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
		factoryAddress: '0x6725F303b657a9451d8BA641348b6761A6CC7a17',
		name: 'PancakeSwap'

	}],
	56: [{id: 1,
		routerAddress: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
		factoryAddress: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
		name: 'PancakeSwap'
	}],
	137: [{id: 1,
		routerAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
		factoryAddress: '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32',
		name: 'QuickSwap'
	}],
	43114: [{id: 1,
		routerAddress: '0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106',
		factoryAddress: '0xefa94DE7a4656D787667C749f7E1223D71E9FD88',
		name: 'Pangolin'
	}],
	66: [{id: 1,
		routerAddress: '0x865bfde337C8aFBffF144Ff4C29f9404EBb22b15',
		factoryAddress: '0x709102921812B3276A65092Fe79eDfc76c4D4AFe',
		name: 'CherrySwap'
	}],
	5: [{id: 1,
		routerAddress: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
		factoryAddress: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
		name: 'UniSwap'
	}],
	1: [{id: 1,
		routerAddress: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
		factoryAddress: '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f',
		name: 'UniSwap'
	}],
	666888: [{id: 1,
		routerAddress: '',
		v3ManagerAddress: '0x7f34c60a76bc8cc4fbc138aa8ff63ea473394701',
		factoryAddress: '0x83334bf03fe52c10a0838066f4a1b9520af3d11c',
		name: 'CytoSwap'
	}],
	8668: [{id: 1,
		routerAddress: '',
		v3ManagerAddress: '0xa0afade4b0481c6ff44e26e3fe75c8acc54842ca',
		factoryAddress: '0x0c0cf6c2901b23762fa3eeccc4f6d1912b08719e',
		name: 'CytoSwap'
	}]
};

export const getDexInfo = (_chainId, _id) => {
	if (!_chainId || !_id) {
		return {}
	}
	const dexInfos = dexExchanges[_chainId];
	if (dexInfos && dexInfos.length > 0) {
		return dexInfos.find(e => e.id === _id);
	}
	return {};
};

