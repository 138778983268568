import { 
    GET_SIGNATURE_COMMIT, 
    GET_SIGNATURE_REFUND, 
    GET_SIGNATURE_REMAINING_FUND, 
    GET_WHITELIST_BY_ADDRESS
} from "../actions/whitelistType";

const whitelist = (state = {}, action) => {
    switch(action.type){
        case GET_SIGNATURE_COMMIT:
            return {
                ...state,
                signatureCommit: action.payload
            }

        case GET_SIGNATURE_REMAINING_FUND:
            return {
                    ...state,
                signatureRemainingFund: action.payload
            }

        case GET_SIGNATURE_REFUND:
            return {
                    ...state,
                signatureRefund: action.payload
            } 

        case GET_WHITELIST_BY_ADDRESS:
            return {
                ...state,
                ownerWhitelist: action.payload
            
        }

        default:
            return state
    }
}

export default whitelist;