export const rendererCountDown = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
}) => {
    // Render a countdown
    if (completed) {
        window.location.reload();
        return;
        
    }
    if (days > 1) {
        return (
            <span>
                {days}days : {hours}h : {minutes}m : {seconds}s
            </span>
        );
    } else if (days === 1) {
        return (
            <span>
                {days}day : {hours}h : {minutes}m : {seconds}s
            </span>
        );
    } else {
        return (
            <span>
                {hours}h : {minutes}m : {seconds}s
            </span>
        );
    }
};

export const rendererCountDown1 = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
}) => {
    // Render a countdown
    if (completed) {
        return ''
    }
    if (days > 1) {
        return (
            <span>
                {days}days : {hours}h : {minutes}m : {seconds}s
            </span>
        );
    } else if (days === 1) {
        return (
            <span>
                {days}day : {hours}h : {minutes}m : {seconds}s
            </span>
        );
    } else {
        return (
            <span>
                {hours}h : {minutes}m : {seconds}s
            </span>
        );
    }
};