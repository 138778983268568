import React from "react";
import Countdown from "react-countdown";

const CountdownReloadComponent = (countdownDate) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed){
      window.location.reload();
    };
    return (
      <div className="coming-time">
        <div className="time-count flex-left">
          <div className="time-item text-weight-600">{days}<small>D</small></div> 
          <div className="time-item text-weight-600">{hours}<small>H</small></div> 
          <div className="time-item text-weight-600">{minutes}<small>M</small></div> 
          <div className="time-item text-weight-600">{seconds}<small>S</small></div>
        </div>
      </div>
    );
  };

  return <Countdown date={countdownDate} renderer={renderer} />;
};

export default CountdownReloadComponent;
