
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Layout, Row, Col, Button, Form, Input, Select, Spin, InputNumber, Checkbox, Alert, Space, Radio, Breadcrumb } from 'antd';
import { useActiveWeb3React } from '../../hooks';
import { CopyOutlined } from '@ant-design/icons';

import web3 from 'web3';
import './style.css';
import { ethers } from 'ethers';
import { getInformationByChain } from '../../config/network/multichainAddresses';
import {
    _isMinted,
    _claim
} from './utils';
import {
    useContract
} from "../../hooks/useContract";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Store } from 'react-notifications-component';
import addNotify from '../common/Notify/addNotify';
import { Loader } from '../common/component';
import claimTokenTestAbi from '../../config/abi/claimTokenTestAbi.json';
import ChainNotSupport from "../chain-not-support";
import { MAPPING_CHAINID_DECIMAL } from '../../constants';

const ClaimHlup = () => {
    const { account, library, chainId } = useActiveWeb3React();

    const [form] = Form.useForm();

    let CLAIM_TOKEN_SC = "0x98B7D6D1f4Bca134a872db9574bCA672BA142DD9";
    let CLAIM_USDT_TOKEN_SC = "0xd8047eAA5939e051E70fcfa53dd66E911c5F3D1d";

    const provider = getInformationByChain(chainId, 'REACT_APP_RPC_URL');

    const [loading, setLoading] = useState(false);
    const [isMinted, setIsMinted] = useState(true);
    const [claimType, setClaimType] = useState("HLUP");

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);

    const claimContract = useContract(CLAIM_TOKEN_SC, claimTokenTestAbi);
    const claimUSDTContract = useContract(CLAIM_USDT_TOKEN_SC, claimTokenTestAbi);

    const checkIsMinted = (claimType)=>{
      
        let claimContractSlt = claimContract;
        if(claimType !== "HLUP"){
            claimContractSlt = claimUSDTContract;
        }
        _isMinted(claimContractSlt, account).then(
            (res) => {  
                setIsMinted(res);
            }
        ).catch((error)=>{
            console.log('error-----', error);    
        });
    }
    
    useEffect(() => {
        if (account) {
            
            checkIsMinted(claimType);
        }

    }, [account, claimType, claimContract, claimUSDTContract]);

    const changeTokenClaim = (e)=>{
        setClaimType(e);
    }


    const onSubmitCreateTokenForm = async (value) => {
        
        setLoading(true);

        let contractToken = claimContract;
        if(value.tokenAddress === "USDT"){
            contractToken = claimUSDTContract;
        }
        
        await _claim(contractToken)
        .then((txn) => {
            if (txn && txn.hash) {
                let countNoti = 0;
                const interval = setInterval(function () {
                    (async () => {
                        const res = await w3.eth.getTransactionReceipt(txn.hash);
                        if (res) {
                            clearInterval(interval);
                            if (res.status && res.blockNumber) {
                                if (!countNoti) {
                                    countNoti++;
                                    setLoading(false);
                                    Store.addNotification(addNotify("Claim Successfully", 'success'));
                                    checkIsMinted(value.tokenAddress);
                                    setInterval(() => {
                                        window.location.reload();
                                    }, 2000);
                                }
                            } else {
                                Store.addNotification(addNotify("Claim Failed", 'danger'));
                            }
                            setLoading(false);
                        }
                    })();
                }, 1000);
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
            if (error) {
                if (error.code == 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        });

    };

    const onCopy = () => {
		Store.addNotification(addNotify('Copied', 'success'));
	};

    if(chainId && MAPPING_CHAINID_DECIMAL.helatestnet !== chainId){
        return(
            <><ChainNotSupport /></>
        )
    }

    let tokenAddressClaim = CLAIM_TOKEN_SC;
    if(claimType === "USDT"){
        tokenAddressClaim = CLAIM_USDT_TOKEN_SC
    }

    return (
        <>
            <div className='create-token-page'>
                <div className="container">
                    <Breadcrumb className='head-breadcrumb-airdrop'>
                        <Breadcrumb.Item><a href='/'>Home</a></Breadcrumb.Item>
                        <Breadcrumb.Item>Claim tọken testnet</Breadcrumb.Item>
                    </Breadcrumb>
                
                    <div className="create-step">
                        <div className="anti-bot-block">
                            <div className="steps-content-antibot">
                                <div className="sub-title-steps text-25">
                                    Claim your <span className='text-darkblue text-weight-600'>{claimType}</span> testnet
                                </div>
                                <div className="inputs-steps">
                                    <Form
                                        form={form}
                                        name="basic"
                                        autoComplete="off"
                                        onFinish={onSubmitCreateTokenForm}
                                        layout="vertical"
                                        className='basic-form'
                                    >
                                        <p className='text-16 text-weight-500 mb-10 d-flex'>Your wallet: 
                                            <div className='text-darkblue mr-5 ml-5'>{account?.substring(0, 5)}...{account?.substring(account.length - 5)}</div>
                                            <CopyToClipboard onCopy={onCopy} text={account}>
                                                <CopyOutlined className='text-darkblue' />
                                            </CopyToClipboard>
                                        </p>
                                        
                                        <p className='text-16 text-weight-500'><span className='text-darkblue text-weight-600'>{claimType}</span> address:  <span className='text-darkblue text-weight-600'>
                                            {tokenAddressClaim?.substring(0, 5)}...{tokenAddressClaim?.substring(tokenAddressClaim.length - 5)}
                                            <CopyToClipboard onCopy={onCopy} text={tokenAddressClaim}>
                                                <CopyOutlined className='text-darkblue' />
                                            </CopyToClipboard>
                                        </span></p>
                                    
                                        <Form.Item name="tokenAddress" initialValue={claimType} label="Token claim" rules={[{ required: true, message: 'Please choice token claim!' }]}>
                                            <Select placeholder="Select token" onChange={changeTokenClaim}>
                                       
                                                <Select.Option value="HLUP" key={'cex-05'}>HLUP</Select.Option>
                                                <Select.Option value="USDT" key={'cex-1'}>USDH</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        
                                        <div className="">
                                            {account ? (
                                                <>
                                                    <button className="btn-basic w-fitcontent" disabled={loading || !account || isMinted} type="submit">
                                                        {isMinted ? ('Claimed'):('Claim now')}
                                                        
                                                        {loading && <Spin size="small" />}
                                                    </button>
                                                </>
                                            ):(
                                                <>
                                                    <button className="btn-basic w-fitcontent" disabled>Connect wallet</button>
                                                </>
                                            )}
                                            
                                        </div>
                                    </Form>

                                </div>


                                {loading && (
                                    <Loader />
                                )}
                            </div>

                        </div>
                    </div>
                </div>
                
            </div>
            
            
        </>
    )
}

export default ClaimHlup
