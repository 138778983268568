import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useLocation } from 'react-router-dom';
import { Layout, Row, Col, Form, Select, Space, Spin, Pagination, Empty } from 'antd';
import { SearchOutlined, TwitterOutlined, CodeSandboxOutlined, GlobalOutlined } from '@ant-design/icons';
import { useActiveWeb3React } from '../../../hooks';

import { IdoLaunchpad } from '../../../config/api/idoLaunchpad';
import Countdown from 'react-countdown';
import '../ico-project/style.css';
import './style.css';
import ProjectMenu from '../menu-project';
import MyItem from './MyItem';

const { Option } = Select;
const { Content } = Layout;

const MyIcoProject = () => {
 

    const [dataLaunchpad, setDataLaunchpad] = useState([]);
    const { chainId, account, library } = useActiveWeb3React();
    const [form] = Form.useForm();
    const [totalRow, setTotalRow] = useState(0);
    const [loadingData, setLoadingData] = useState(false);
    const [param, setParam] = useState(
        {
            page: 0,
            size: 9,
            sort: 'id,desc',
            chainId: chainId ? chainId : 0
        }
    );

    const getDataLaunchpad = async (account) => {
        setLoadingData(true);
        const dataFilter = {
            ...param
        };
        try {
            let res = await IdoLaunchpad.getLaunchpadByAddress(account, dataFilter);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setDataLaunchpad(res.data);
                }
            }
            setLoadingData(false);
        } catch (error) {
            console.log('Error: ', error);
        } finally {
            // setLoading(false);
        }
    };

    useEffect(() => {
        if (account) {
            getDataLaunchpad(account);
        }
    }, [account, param]);



    const onChange_Pagi = (pageNumber) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
        const dataFilter = {
            ...param,
            page: pageNumber - 1
        };
        // getDataAsset(dataFilter, tokenID);

    };

    const LaunchpadContent = () => {
       
        return (
            <>
                <Row gutter={30}>
                    {!loadingData ? (
                        <>
                            {dataLaunchpad?.length > 0 ? (
                                dataLaunchpad.map((item, index) => {
                                    
                                    return (
                                        <>
                                            <MyItem item={item} index={index} />
                                        </>
                                    )
                                })
                            ) : (
                                <>
                                    <Col xs={24} className="text-center"><Empty /></Col>
                                </>
                            )}
                        </>
                    ) : (<>
                        <div className="loading-data">
                            <Space size="middle">
                                <Spin size="large" />
                            </Space>
                        </div>
                    </>)}
                    
                </Row>
            </>
        );
    };


    return (
        <>
        <div className="ico-list-page">
            <div className="container">
                <ProjectMenu />
                <div className="projects-grid">
                    <LaunchpadContent />

                    {totalRow > 10 && (
                        <Pagination
                            // style={{ padding: 15, float: 'right' }}
                            current={param.page + 1}
                            defaultCurrent={1}
                            total={totalRow}
                            pageSize={param.size}
                            onChange={onChange_Pagi}
                            showSizeChanger={false}
                            className="pagiation-custom"
                        />
                    )}
                </div>
            </div>
        </div>
            
        </>
    )
}

export default MyIcoProject
