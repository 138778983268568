import React, {useState} from "react";
import IconClose from '../common/asset/images/icon-close.svg';

const ModalUnStake = ({onCancel}) => {
    const [value, setValue] = useState(0);
    return(
        <div className="c-popup">
            <div className="c-popup__box">
                <div className="c-popup__head">
                    Unstake KDG
                    <button className="c-popup__close" onClick={() => {onCancel(false); setValue(0)}}><img src={IconClose} alt="Close" /></button>
                </div>
                <div className="c-popup__body is-staking">
                    <div className="c-staking">
                        <p>Enter amount</p>
                        <div className="c-staking__stake">
                            <input type="number" name="stake" value={value} onChange={(e) => setValue(e.target.value)} />
                            <span>KDG</span>
                            <button onClick={() => setValue(1000)} className="c-btn">MAX</button>
                        </div>
                        <p className="is-amount">10.2351 KDG available</p>
                        <div className="c-staking__fee">
                            <p>Unstaking fee until</p>
                            <div className="c-staking__ans">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M21.17 8.83003V15.67C21.17 16.79 20.57 17.83 19.6 18.4L13.66 21.83C12.69 22.39 11.49 22.39 10.51 21.83L4.57001 18.4C3.60001 17.84 3 16.8 3 15.67V8.83003C3 7.71003 3.60001 6.66999 4.57001 6.09999L10.51 2.67C11.48 2.11 12.68 2.11 13.66 2.67L19.6 6.09999C20.57 6.66999 21.17 7.70003 21.17 8.83003Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M12.159 7.5C12.753 7.5 13.2705 7.5945 13.7115 7.7835C14.1525 7.9725 14.4945 8.2425 14.7375 8.5935C14.9895 8.9445 15.1155 9.363 15.1155 9.849C15.1155 10.371 14.9985 10.8075 14.7645 11.1585C14.5395 11.5005 14.2335 11.7615 13.8465 11.9415C13.4685 12.1215 13.0455 12.2385 12.5775 12.2925V13.737H11.457V11.712C12.114 11.613 12.6225 11.4915 12.9825 11.3475C13.3515 11.2035 13.608 11.0235 13.752 10.8075C13.905 10.5915 13.9815 10.326 13.9815 10.011C13.9815 9.696 13.9095 9.426 13.7655 9.201C13.6305 8.976 13.4235 8.805 13.1445 8.688C12.8655 8.571 12.51 8.5125 12.078 8.5125C11.484 8.5125 10.998 8.679 10.62 9.012C10.251 9.336 10.0305 9.7815 9.9585 10.3485L9 9.795C9.099 9.336 9.288 8.9355 9.567 8.5935C9.855 8.2425 10.215 7.9725 10.647 7.7835C11.088 7.5945 11.592 7.5 12.159 7.5ZM11.997 14.925C12.258 14.925 12.4695 15.006 12.6315 15.168C12.7935 15.33 12.8745 15.5415 12.8745 15.8025C12.8745 16.0635 12.7935 16.275 12.6315 16.437C12.4695 16.599 12.258 16.68 11.997 16.68C11.736 16.68 11.5245 16.599 11.3625 16.437C11.2005 16.275 11.1195 16.0635 11.1195 15.8025C11.1195 15.5415 11.2005 15.33 11.3625 15.168C11.5245 15.006 11.736 14.925 11.997 14.925Z" />
                                </svg>
                                <p>Only applies within 3 days of staking. Unstaking after 3 days will not include a fee. Timer resets every time you stake in the pool.</p>
                            </div>
                        </div>
                        <div className="c-staking__btn">
                            <button className="c-btn">Unstake</button>
                            <button onClick={() => {onCancel(false); setValue(0)}} className="c-btn c-btn--cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalUnStake;