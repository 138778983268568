import React from "react";
import "./style.css";

const Loading = ({ status, content }) => {
  if (status) {
    return (
      <div className="loading-container">
        <div className="loading-spinner">
          <span>{content}</span>
        </div>
      </div>
    );
  } else {
    return content;
  }
};

export default Loading;
