import React, { useCallback, useEffect, useState } from "react";
import bgMainheader from "../banner-nft.png";
import { Alert, Col, Empty, Form, Input, Modal, Row, Spin } from 'antd';
import { getData , postData } from "../axios";
import { useLocation , useNavigate, useParams } from "react-router-dom";
import FooterHome from '../../common/footer/footerHome';
import moment from "moment";
import { useActiveWeb3React } from "../../../hooks";
import Web3 from "web3";
import abiToken from "../abi/abiToken.json"
import abiMint from "../abi/abiMint.json"
import { useAutionNFTContract, useContract, useMarketNFTContract } from "../../../hooks/useContract";
import approveContract from "../utils/approve";
import { Store } from "react-notifications-component";
import addNotify from "../../common/Notify/addNotify";
import { _buyNft, _createListing, _makeOfferAuction } from "../utilsNFT";
import { NftApi } from "../../../config/api/apiNFT";
import NftLogs from "../nft-logs";
import { ADDRESS_HLUP_ADDRESS, AUCTION_ADDRESS_SC, MAPPING_CHAINID_DECIMAL, MARKET_ADDRESS_SC, NFT_ADDRESS_SC } from "../../../constants";
import OfferHistory from "../offer-history";
import Countdown from "react-countdown";
import TimeAgo from "../../../config/timeAgo";
import ChainNotSupport from "../../chain-not-support";
import { Loader } from "../../common/component";

const MintDetails = () => {
    const { account, chainId } = useActiveWeb3React();
    const web3 = new Web3(window.ethereum);
    const [showInfo, setShowInfo] = useState(false);
    const [showAtt, setShowAtt] = useState(false);
    const [showActive, setShowActive] = useState(false);
    const [showItem, setShowItem] = useState(false);
    const location = useLocation();
    const [data, setData] = useState();
    const [dataLike, setDataLike] = useState([]);
    const [allowanceToken, setAllowanceToken] = useState("0");
    const [loading, setLoading] = useState(false);
    const [loadingBuy, setLoadingBuy] = useState(false);
    const [showBuyModal, setShowBuyModal] = useState(false);
    const [showOfferModal, setShowOfferModal] = useState(false);
    const [hupBalance, setHupBalance] = useState(0);
    const [isSelling, setIsSelling] = useState(false);
    const [auctionIndex, setAuctionIndex] = useState(0);
    const [endAuction, setEndAuction] = useState(0);
    const [currentBidPrice, setCurrentBidPrice] = useState(0);
    const [currentBidOwner, setCurrentBidOwner] = useState('');
    const [auctionStatus, setAuctionStatus] = useState(0);
    const [form] = Form.useForm();
    const [showActiveOffer, setShowActiveOffer] = useState(false);

    const MARKET_ADDRESS = MARKET_ADDRESS_SC;
    const NFT_ADDRESS = NFT_ADDRESS_SC;
    const AUCTION_ADDRESS = AUCTION_ADDRESS_SC;
    const HUP_ADDRESS = ADDRESS_HLUP_ADDRESS;

    const contractToken = useContract(HUP_ADDRESS, abiToken);
    const contractBid = useAutionNFTContract(AUCTION_ADDRESS);
    const marketContract = useMarketNFTContract(MARKET_ADDRESS);
    const navigate = useNavigate();

    const handleShow = () => {
        setShowInfo(!showInfo)
    }

    const handleAtt = () => {
        setShowAtt(!showAtt)
    }

    const handleActive = () => {
        setShowActive(!showActive)
    }
    const handleActiveOffer = () => {
        setShowActiveOffer(!showActiveOffer)
    }

    const handleItem = () => {
        setShowItem(!showItem)
    }

    let { id } = useParams();

    const fetchListData = async () => {
        await getData(`${process.env.REACT_APP_API_URL}/merlion-nfts/${id}/details`
        ).then(res => {
            setData(res)
        })
    }

    useEffect(() => {
        if(!data){
            fetchListData()
        }
    }, [id]);

    let typeOfSale = data?.marketplaceDetails?.typeOfSale ? data?.marketplaceDetails?.typeOfSale : '';

    const fetchListDataRow = async () => {
        let dataBody = {
            attrbuteIds: [],
            price: data?.marketplaceDetails?.price ? data?.marketplaceDetails?.price : 0
        };
        await postData(`${process.env.REACT_APP_API_URL}/marketplaces/user-also-liked`,dataBody).then(res => {
            if(res){
                let dataNew = res.filter((item) => Number(item?.merlionNft?.nftId) !== Number(data?.nftId));
                setDataLike(dataNew)
            }
            
        })
    }

    const checkAllowance = async () => {
        if (account) {
            try {
                let addressApprove = MARKET_ADDRESS;
                if(typeOfSale === "AUCTION"){
                    addressApprove = AUCTION_ADDRESS;
                }
                await contractToken.allowance(account, addressApprove).then(res => {
                    let bal = web3.utils.fromWei(web3.utils.toBN(res.toString()), "ether");
                    setAllowanceToken(bal)
                })
            } catch (error) {}
        }
    }

    useEffect(() => {
        checkAllowance();
    }, [account]);

    const checkNftAuction = async () => {
        if(typeOfSale !== "FIXED"){
            await contractBid.allAuctions(auctionIndex).then(res => {
                let auctionData = res;
                console.log('auctionData', auctionData)

                let endAuction = auctionData?.endAuction ? auctionData?.endAuction.toString() : 0;
                if(endAuction > 0){
                    setEndAuction(endAuction);
                }
                let status = auctionData?.status ? auctionData?.status.toString() : 0;
                if(Number(status) > 0){
                    setAuctionStatus(Number(status));
                }
                let currentBidOwner = auctionData?.currentBidOwner ? auctionData?.currentBidOwner.toString() : '';
                if(currentBidOwner){
                    setCurrentBidOwner(currentBidOwner);
                }
                let currentBidPrice = web3.utils.fromWei(web3.utils.toBN(auctionData.currentBidPrice.toString()), "ether");
                if(Number(currentBidPrice) > 0){
                    setCurrentBidPrice(Number(currentBidPrice));
                }
            }).catch((err)=>{
                console.log('Auction checkNftAuction', err)
            })
        }
    }
    useEffect(() => {
        if(account && data && auctionIndex){
            checkNftAuction()
        }
    }, [account, data, auctionIndex]);

    const getHUPBalance = async () => {
        if (contractToken) {
            const amount = await contractToken.balanceOf(account);
            let bal = web3.utils.fromWei(web3.utils.toBN(amount.toString()), "ether");
            setHupBalance(Number(bal));
        }
    };

    const checkIsListed = async () => {
        if(typeOfSale === "FIXED"){
            await marketContract.listings(NFT_ADDRESS, Number(data?.nftId)).then(res => {
                let price = web3.utils.fromWei(web3.utils.toBN(res.price.toString()), "ether");
                if(Number(price) === 0){
                    setIsSelling(true);
                }
                
            }).catch((err)=>{
                console.log('checkIsListed', err)
            })
        }else{
            await contractBid.nftIdToIndexes(Number(data?.nftId)).then(res => {
                let auctionIndex = res.toString();
                console.log('auctionIndex', auctionIndex);
                if(Number(auctionIndex) !== 0){
                    setIsSelling(false);
                    setAuctionIndex(Number(auctionIndex));
                }
                
            }).catch((err)=>{
                console.log('Auction checkIsListed', err)
            })
        }
        
    }
    
    useEffect(() => {
        if (account && data) {
            checkIsListed();
            getHUPBalance();
        }
    }, [account, data]);

    

    useEffect(() => {
        if(data){
            fetchListDataRow()
        }
        
    }, [data]);

    

    const handleApproveToken = async () => {
        try {
            if (account) {
                setLoading(true);
                let addressApprove = MARKET_ADDRESS;
                if(typeOfSale === "AUCTION"){
                    addressApprove = AUCTION_ADDRESS;
                }
                await approveContract(addressApprove, HUP_ADDRESS, account)
                    .then((res) => {
                        if (res) {
                            Store.addNotification(addNotify('Approved successfully', 'success'));
                            checkAllowance();
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        console.error("Error Approve Token", error);
                        setLoading(false);
                        if (error) {
                            if (error.code == 4001 && error.message) {
                                Store.addNotification(addNotify(error.message, 'danger'));
                            } else if (error.reason) {
                                Store.addNotification(addNotify(error.reason, 'danger'));
                            } else {
                                if (error.data && error.data.message) {
                                    Store.addNotification(addNotify(error.data.message, 'danger'));
                                }
                            }
                        }
                    });
            }
        } catch (error) {
            setLoading(false);
            console.error("Error Handle Approve", error);
        }
    };

    const handleShowBuyModal = ()=>{
        setShowBuyModal(true);
    }
    const handleShowOfferModal = ()=>{
        setShowOfferModal(true);
    }
    const confirmBuy = async (price)=>{
        setLoadingBuy(true);
        console.log('data', data);
        
        if (Number(price) <= 0 || hupBalance < Number(price)) {
            Store.addNotification(addNotify('Invalid balance.', 'danger'));
            return;
        }
        if (!marketContract) {
            Store.addNotification(addNotify('Market contract invalid.', 'danger'));
            return;
        }
        if (Number(data?.chainId) !== chainId) {
            Store.addNotification(addNotify('Invalid chain.', 'danger'));
            return;
        }
        if (!data?.nftId) {
            Store.addNotification(addNotify('Nft not found.', 'danger'));
            return;
        }

        await _buyNft(marketContract , NFT_ADDRESS, Number(data?.nftId))
        .then((res) => {
            let resWait = res.wait();
            resWait.then(async (resTransaction) => {

                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                    
                    let payload = {
                        "buyHash": res.hash,
                        "buyerAddress": account,
                        "id": data?.id,
                        "marketplaceId": data?.marketplaceDetails?.id,
                        "ownerAddress": data?.ownerAddress
                    };
                    let resAp = await NftApi.buyNft(payload);
                    if (resAp.status === 201) {
                        Store.addNotification(addNotify('Buy successfully', 'success'));
                        setLoadingBuy(false);

                        setTimeout(() => {
                            navigate(`/genesis-nft/profile`)
                        }, 2000);
                    }else{
                        Store.addNotification(addNotify('Server Error', 'danger'));
                        setLoadingBuy(false);
                    }
                    
                }
                else {
                    setLoadingBuy(false);
                    Store.addNotification(addNotify('Transaction failed', 'warning'));
                    return;
                }

            })
            .catch((error) => {
                setLoadingBuy(false);
                Store.addNotification(addNotify(error?.message ? error?.message : 'Server Error !!!', 'danger'));
            });
        })
        .catch((error) => {
            setLoadingBuy(false);
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        });
    }

    const onConfirmMakeOffer = async (value)=>{
        console.log('value', value);
        setLoadingBuy(true);

        if (Number(value?.price_offer) <= 0 || hupBalance < Number(value?.price_offer)) {
            Store.addNotification(addNotify('Invalid balance.', 'danger'));
            setLoadingBuy(false);
            return;
        }
        if (!contractBid) {
            Store.addNotification(addNotify('Contract invalid.', 'danger'));
            setLoadingBuy(false);
            return;
        }
        if (Number(data?.chainId) !== chainId) {
            Store.addNotification(addNotify('Invalid chain.', 'danger'));
            setLoadingBuy(false);
            return;
        }
        if (!data?.nftId) {
            Store.addNotification(addNotify('Nft not found.', 'danger'));
            setLoadingBuy(false);
            return;
        }

        await _makeOfferAuction(contractBid , Number(data?.nftId), Number(value?.price_offer))
        .then((res) => {
            let resWait = res.wait();
            resWait.then(async (resTransaction) => {

                if (resTransaction && resTransaction.status && resTransaction.blockNumber) {
                        
                    let payload = {
                        "buyHash": res.hash,
                        "buyerAddress": account,
                        "id": data?.id,
                        "marketplaceId": data?.marketplaceDetails?.id,
                        "ownerAddress": data?.ownerAddress,
                        "price": Number(value?.price_offer),
                    };
                    let resAp = await NftApi.buyNft(payload);

                    if (resAp.status === 201) {
                        Store.addNotification(addNotify('Make offer successfully', 'success'));
                        setLoadingBuy(false);

                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }else{
                        Store.addNotification(addNotify('Server Error', 'danger'));
                        setLoadingBuy(false);
                    }
                    
                }
                else {
                    setLoadingBuy(false);
                    Store.addNotification(addNotify('Transaction failed', 'warning'));
                    return;
                }

            })
            .catch((error) => {
                setLoadingBuy(false);
                Store.addNotification(addNotify(error?.message ? error?.message : 'Server Error !!!', 'danger'));
            });
        })
        .catch((error) => {
            setLoadingBuy(false);
            if (error) {
                if (error.code === 4001 && error.message) {
                    Store.addNotification(addNotify(error.message, 'danger'));
                } else if (error.reason) {
                    Store.addNotification(addNotify(error.reason, 'danger'));
                } else {
                    if (error.data && error.data.message) {
                        Store.addNotification(addNotify(error.data.message, 'danger'));
                    }
                }
            }
        });
    }

    const Item = ({item}) => {
        const handleRedirect = (id) => {
            navigate(`/nft-details/${id}`)
        }

        return (
            <>
                <Col className="gutter-row" xs={24} xl={6} md={6} sm={6} key={item.id}>
                    <div className="nft-item" onClick={() => handleRedirect(item?.merlionNft.id)}>
                        <div className="nft-img">
                            <img src={item?.merlionNft.nftImageURL} alt="" />
                        </div>
                        <div className="nft-info">
                            <span>NFT Collection</span>
                            <span>{item?.merlionNft.name}</span>
                        </div>
                        <div className="nft-info">
                            <span>Curent price:</span>
                            <span>{item?.price ? Number(item?.price).toLocaleString() : '___'} HLUP</span>
                        </div>
                    </div>
                </Col>
            </>
        )
    }

    const getProgressTime = useCallback((startTime) => {
        if (!startTime) {
            return `TBA`
        }
        const now = new Date()
        const utcDate = Date.UTC(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            now.getHours(),
            now.getMinutes(),
            now.getSeconds(),
            0
        )
        const startDate = new Date(startTime)
        const startTS = Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate(),
            startDate.getHours(),
            startDate.getMinutes(),
            startDate.getSeconds(),
            0
        )
        let delta = Math.abs(startTS.valueOf() - utcDate.valueOf()) / 1000;
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        const hours = Math.floor(delta / 3600);
        delta -= hours * 3600;
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        const seconds = Math.floor(delta % 60);  // in theory the modulus is not required
        if (days > 0) {
            return Date.now() + days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000
    
        } else {
            return Date.now() + hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000
        }
    }, []);

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            <span>Ended</span>
        }
        return (<span>{days}d : {hours}h : {minutes}m : {seconds}s</span>);
    };

    let finishDate = new Date(data?.marketplaceDetails?.finishTime);
    let nowDate = new Date(Date.now());
    let mintedDate = new Date(data?.createdDate);

    if(MAPPING_CHAINID_DECIMAL.helatestnet !== chainId){
        return(
            <><ChainNotSupport /></>
        )
    }
    
    return (
        <>
            <div className="main-nft-section">
                <div className="container">
                    <div className="box-details-nft">
                        <div className="breadcrum">
                            <ul>
                                <li>
                                    Marketplace
                                </li>
                                <li>
                                    {"->"}
                                </li>
                                <li className="active">
                                    Detail NFT's name
                                </li>
                            </ul>
                        </div>
                        <div className="mint-after-box">
                            <div className="box-left">
                                <img src={data?.nftImageURL} alt="" />
                            </div>
                            <div className="box-right">
                                <div className="bid-details">
                                    <div className="row-user">
                                        <img src="./images/user-nft.png" alt="" /> <span>Funny Merlion NFT</span> <img src="./images/checked.png" alt="" />
                                    </div>
                                    <div className="bid-name">
                                        {data?.name}
                                    </div>
                                    <div className="bid-owner-address">
                                        Owned by: <span>{data?.ownerAddress.substring(0, 4)}...{data?.ownerAddress.substring(data?.ownerAddress.length - 4)}<img src="./images/copy.png" alt="" /></span>
                                    </div>
                                    
                                        <div className="mint-release">
                                            <div className="open-minting">
                                                Current price:
                                            </div>
                                            <div className="mint-balance">
                                                <img src="../../images/icon-mint.png" alt="" /> {data?.marketplaceDetails?.price ? Number(data?.marketplaceDetails?.price).toLocaleString() : '0'} <span className="text-16 text-weight-600">HLUP</span>
                                            </div>
                                            
                                            {!isSelling && account && account.toLowerCase() !== data?.ownerAddress.toLowerCase() && (
                                                <>
                                                    <p className="mt-10 text-16 text-darkblue">Your HLUP: <span className="text-weight-600">{Number(hupBalance.toFixed(2)).toLocaleString()}</span></p>
                                                    
                                                        {hupBalance < Number(data?.marketplaceDetails?.price) ? (
                                                            <div className="mint-button">
                                                                <Alert message="No Enought HLUP Balance" type="error" />
                                                            </div>
                                                        ):(
                                                            <div className="mint-button">
                                                                {allowanceToken > 0 ? (
                                                                    <>
                                                                        {typeOfSale === "FIXED" ? (<>
                                                                            <button type="button" className="btn-basic" onClick={handleShowBuyModal}>
                                                                                Buy now
                                                                            </button>
                                                                        </>):(<>
                                                                            {data?.marketplaceDetails?.finishTime && finishDate > nowDate && (
                                                                                <button type="button" className="btn-basic" onClick={handleShowOfferModal}>
                                                                                    Place Offer
                                                                                </button>
                                                                            )}
                                                                        </>)}
                                                                    </>
                                                                ):(
                                                                    <>
                                                                        <button type="button" className="btn-basic" disabled={loading || !account} onClick={handleApproveToken}>
                                                                            Approve for Buy {loading && <Spin size="small" />}
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        )}
                                                    
                                                    
                                                </>
                                            )}
                                            {typeOfSale === "AUCTION" && data?.marketplaceDetails?.finishTime && finishDate > nowDate && (
                                                <>
                                                    <div className="auction-time">
                                                        <div className="auction-time-label">End in:</div>
                                                        <div className="auction-time-count">
                                                            <Countdown date={getProgressTime(finishDate)} renderer={renderer} />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    
                                    
                                    <div className="group-detail-bid">
                                        <div className="title" onClick={handleShow}>
                                            <span className="left">
                                                Details
                                            </span>
                                            {!showInfo ?
                                                <>
                                                    <span className="right active">
                                                        <img src="../../images/arrow-circle-down-1.png" alt="" />
                                                    </span>
                                                </>
                                                :
                                                <>
                                                    <span className="right">
                                                        <img src="../../images/arrow-circle-down.png" alt="" />
                                                    </span>
                                                </>
                                            }
                                        </div>
                                        <div className={!showInfo ? "box-content" : "box-content dis-none"}>
                                            <ul>
                                                <li>
                                                    <div className="txt-left">
                                                        Contract address:
                                                    </div>
                                                    <div className="txt-right">
                                                        {data?.marketplaceDetails?.marketAddress.substring(0, 4)}...{data?.marketplaceDetails?.marketAddress.substring(data?.ownerAddress.length - 4)}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="txt-left">
                                                        Token ID:
                                                    </div>
                                                    <div className="txt-right">
                                                        {data?.nftId ? data?.nftId : "---"}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="txt-left">
                                                        Token Standard:
                                                    </div>
                                                    <div className="txt-right">
                                                        {data?.tokenStandard ? data?.tokenStandard : "---"}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="txt-left">
                                                        Chain:
                                                    </div>
                                                    <div className="txt-right">
                                                        {data?.networkEnum}
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="txt-left">
                                                        Creator fees:
                                                    </div>
                                                    <div className="txt-right">
                                                        {data?.creatorFee ? data?.creatorFee : "---"} %
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="txt-left">
                                                        Minted Date:
                                                    </div>
                                                    <div className="txt-right">
                                                        <TimeAgo timestamp={(mintedDate.getTime() / 1000)} />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <div className="group-detail-bid">
                                        <div className="title" onClick={handleAtt}>
                                            <span className="left">
                                                Traits
                                            </span>
                                            {!showAtt ?
                                                <>
                                                    <span className="right active">
                                                        <img src="../../images/arrow-circle-down-1.png" alt="" />
                                                    </span>
                                                </>
                                                :
                                                <>
                                                    <span className="right">
                                                        <img src="../../images/arrow-circle-down.png" alt="" />
                                                    </span>
                                                </>
                                            }
                                        </div>
                                        <div className={!showAtt ? "box-content res" : "box-content res dis-none"}>
                                            <ul className="list-modal">
                                                <li>
                                                    <div className="content">
                                                        <span className="att-1">
                                                            {data?.attributes?.background?.attributeTypeEnum}
                                                        </span>
                                                        <span className="att-2">
                                                            {data?.attributes?.background?.name} {data?.attributes?.background?.rate}%
                                                        </span>
                                                        <span className="att-3">
                                                            Floor: {data?.attributes?.background?.floor} HLUP
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="content">
                                                        <span className="att-1">
                                                            {data?.attributes?.face?.attributeTypeEnum}
                                                        </span>
                                                        <span className="att-2">
                                                            {data?.attributes?.face?.name} {data?.attributes?.face?.rate}%
                                                        </span>
                                                        <span className="att-3">
                                                            Floor: {data?.attributes?.face?.floor} HLUP
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="content">
                                                        <span className="att-1">
                                                            {data?.attributes?.clothes?.attributeTypeEnum}
                                                        </span>
                                                        <span className="att-2">
                                                            {data?.attributes?.clothes?.name} {data?.attributes?.clothes?.rate}%
                                                        </span>
                                                        <span className="att-3">
                                                            Floor: {data?.attributes?.clothes?.floor} HLUP
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="content">
                                                        <span className="att-1">
                                                            {data?.attributes?.body?.attributeTypeEnum}
                                                        </span>
                                                        <span className="att-2">
                                                            {data?.attributes?.body?.name} {data?.attributes?.body?.rate}%
                                                        </span>
                                                        <span className="att-3">
                                                            Floor: {data?.attributes?.body?.floor} HLUP
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="content">
                                                        <span className="att-1">
                                                            {data?.attributes?.hat?.attributeTypeEnum}
                                                        </span>
                                                        <span className="att-2">
                                                            {data?.attributes?.hat?.name} {data?.attributes?.hat?.rate}%
                                                        </span>
                                                        <span className="att-3">
                                                            Floor: {data?.attributes?.hat?.floor} HLUP
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="content">
                                                        <span className="att-1">
                                                            {data?.attributes?.item?.attributeTypeEnum}
                                                        </span>
                                                        <span className="att-2">
                                                            {data?.attributes?.item?.name} {data?.attributes?.item?.rate}%
                                                        </span>
                                                        <span className="att-3">
                                                            Floor: {data?.attributes?.item?.floor} HLUP
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {typeOfSale === "AUCTION" && (
                            <>
                                <div className="group-detail-bid mb-15">
                                    <div className="title" onClick={handleActiveOffer}>
                                        <span className="left">
                                            Offer history
                                        </span>
                                        {!showActive ?
                                            <>
                                                <span className="right active">
                                                    <img src="../../images/arrow-circle-down-1.png" alt="" />
                                                </span>
                                            </>
                                            :
                                            <>
                                                <span className="right">
                                                    <img src="../../images/arrow-circle-down.png" alt="" />
                                                </span>
                                            </>
                                        }
                                    </div>
                                    <div className={!showActiveOffer ? "box-content" : "box-content dis-none"}>
                                        <div className="main-table">
                                            <OfferHistory data={data} endAuction={endAuction} currentBidOwner={currentBidOwner} currentBidPrice={currentBidPrice} auctionIndex={auctionIndex} auctionStatus={auctionStatus}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        
                        <div className="group-detail-bid">
                            <div className="title" onClick={handleActive}>
                                <span className="left">
                                    Activities
                                </span>
                                {!showActive ?
                                    <>
                                        <span className="right active">
                                            <img src="../../images/arrow-circle-down-1.png" alt="" />
                                        </span>
                                    </>
                                    :
                                    <>
                                        <span className="right">
                                            <img src="../../images/arrow-circle-down.png" alt="" />
                                        </span>
                                    </>
                                }
                            </div>
                            <div className={!showActive ? "box-content" : "box-content dis-none"}>
                                <div className="main-table">
                                    <NftLogs data={data} />
                                </div>
                            </div>
                        </div>
                        <div className="group-detail-bid">
                            <div className="title" onClick={handleItem}>
                                <span className="left">
                                    You may also like
                                </span>
                                {!showItem ?
                                    <>
                                        <span className="right active">
                                            <img src="../../images/arrow-circle-down-1.png" alt="" />
                                        </span>
                                    </>
                                    :
                                    <>
                                        <span className="right">
                                            <img src="../../images/arrow-circle-down.png" alt="" />
                                        </span>
                                    </>
                                }
                            </div>
                            <div className={!showItem ? "box-content res" : "box-content res dis-none"}>
                                <div className="box-tasb-content">
                                    <Row gutter={16}>
                                        {dataLike.length === 0 ?
                                            <>
                                                <div className="no-data">
                                                    <Empty description={false} />
                                                </div>
                                            </>
                                            :
                                            <>
                                                {dataLike.length > 0 && dataLike.map((item,key) => (
                                                    <Item item={item}/>
                                                ))}
                                            </>
                                        }
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-mint modal-list-done" open={showBuyModal} onOk={()=> setShowBuyModal(false)} onCancel={()=> setShowBuyModal(false)}>
                <div className="modal-content-mint">
                    <div className="title text-green">
                        Complete checkout!
                    </div>
                    <div className="mt-15 mb-15 modal-nft">
                        <Row gutter={32}>
                            <Col className="gutter-row" xs={24} xl={7} md={7} sm={7}>
                                <div className="modal-nft-img">
                                    <img src={data?.nftImageURL} alt="" />
                                </div>
                            </Col>
                            <Col className="gutter-row" xs={24} xl={17} md={17} sm={17}>
                                <div className="modal-nft-info">
                                    <div className="nft-info-title">Funny Merlion NFT</div>
                                    <div className="nft-info-name">{data?.name}</div>
                                    <div className="nft-info-label">Listing price:</div>
                                    <div className="nft-info-price">
                                        <img width={25} src="../../images/up-favi.svg" alt="" /> 
                                        {Number(data?.marketplaceDetails?.price).toLocaleString()} HLUP
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <p className="mt-10 text-16 text-darkblue">Your HLUP: <span className="text-weight-600">{Number(hupBalance.toFixed(2)).toLocaleString()}</span></p>
                    <div className="modal-list-info">
                        <Row gutter={16}>
                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                <div className="label-info text-weight-500">Total:</div>
                            </Col>
                            <Col className="gutter-row" xs={12} xl={12} md={12} sm={12}>
                                <div className="label-value text-right text-weight-600">{Number(data?.marketplaceDetails?.price).toLocaleString()} HLUP</div>
                            </Col>
                        </Row>
                    </div>
                    
                    <div className="text-center mt-15">
                        {hupBalance < Number(data?.marketplaceDetails?.price) ? (
                            <button disabled className="btn-basic w-200">Confirm checkout!</button>
                        ):(
                            <button disabled={loadingBuy || !account} className="btn-basic w-200" onClick={()=>confirmBuy(data?.marketplaceDetails?.price)}>Confirm checkout! {loadingBuy && <Spin size="small" />}</button>
                        )}
                        
                    </div>
                </div>
            </Modal>
            <Modal className="modal-mint modal-list-done" open={showOfferModal} onOk={()=> setShowOfferModal(false)} onCancel={()=> setShowOfferModal(false)}>
                <Form form={form} layout="vertical" autoComplete="off" className="basic-form"
                    onFinish={onConfirmMakeOffer}
                >
                    <div className="modal-content-mint">
                        <div className="title text-green">
                            Make an offer
                        </div>
                        <div className="modal-make-offer">
                            <div className="make-offer-head">
                                <span className="text-weight-600 text-16">Offer price</span>
                                <span className="text-weight-500 text-16 text-darkblue">Balance: {hupBalance.toLocaleString()} HLUP</span>
                            </div>
                            <div className="make-offer-input">
                                <Form.Item
                                    label=""
                                    name="price_offer"
                                    className="mb-0"
                                    rules={[
                                        { required: true, message: 'Please input price offer!' },
                                        {
                                            validator: (rule, value, cb) => {
                                                if(!value || parseFloat(value) <= 0){
                                                    cb("Price offer must be > 0")
                                                }else if(parseFloat(value) > 0 && parseFloat(value) < Number(data?.marketplaceDetails?.price)){
                                                    cb("Price offer must be >= Nft price")
                                                }else{
                                                    cb()
                                                }
                                            }
                                        }
                                    ]}
                                >
                                    <Input type="number" placeholder="Ex: 5000"/>
                                </Form.Item>
                            </div>
                        </div>
                        <div className="mt-10 mb-15 modal-nft">
                            <Row gutter={32} className="align-center">
                                <Col className="gutter-row" xs={24} xl={5} md={5} sm={5}>
                                    <div className="modal-nft-img">
                                        <img src={data?.nftImageURL} alt="" />
                                    </div>
                                </Col>
                                <Col className="gutter-row" xs={24} xl={19} md={19} sm={19}>
                                    <div className="modal-nft-info">
                                        <Row gutter={32} className="align-center">
                                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                <div className="nft-info-title">Funny Merlion NFT</div>
                                                <div className="nft-info-name mb-0">{data?.name}</div>
                                            </Col>
                                            <Col className="gutter-row" xs={24} xl={12} md={12} sm={12}>
                                                <div className="nft-info-label">Start price:</div>
                                                <div className="nft-info-price">
                                                    <img width={25} src="../../images/up-favi.svg" alt="" /> 
                                                    {Number(data?.marketplaceDetails?.price).toLocaleString()} HLUP
                                                </div>
                                            </Col>
                                        </Row>
                                    
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        
                        
                        <div className="text-center mt-15">
                            {hupBalance < Number(data?.marketplaceDetails?.price) ? (
                                <button disabled className="btn-basic w-200">Make offer</button>
                            ):(
                                <button type="submit" disabled={loadingBuy || !account} className="btn-basic w-200">Make offer {loadingBuy && <Spin size="small" />}</button>
                            )}
                            
                        </div>
                    </div>
                </Form>
                
            </Modal>
            <FooterHome />
            {loading && (<Loader />)}
            {loadingBuy && (<Loader />)}
        </>
    )
}
export default MintDetails
