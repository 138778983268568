import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FooterHome from "../common/footer/footerHome";
import "./style.css"

const WalletGenerator = () => {
    const navigate = useNavigate();
    const handleRidirect = () => {
        navigate("/wallet-generator/details");
    };
    const handleRidirectPremium = () => {
        navigate("/wallet-generator/details-premium");
    };

    return (
        <>
            <div className="main-section">
                <div className="container">
                    <div className="section-generator">
                        <div className="row-generator">
                            <div className="title">Wallet Generator</div>
                            <div className="title-row">
                                Our <span>wallet generator</span> is simple and secure <br />
                                with no hidden fees
                            </div>
                            <div className="row-warning mar-top-20">
                                <div className="box-img">
                                    <img src="../images/bot/danger.png" alt="" />
                                </div>
                                <div className="text">
                                    Tip: The wallet generation process is all done in the local
                                    environment, we don't have access to any information about the
                                    wallet! This Dapp is run locally, for security, please
                                    disconnect from the internet and run this Dapp on an unmarked
                                    browser!
                                </div>
                            </div>
                            <div className="row-cash-fee mar-top-30">
                                <div className="columns">
                                    <div className="colum w-6">
                                        <div className="box-cash active">
                                            <div className="content-l">
                                                <div className="fee-round">Free</div>
                                                {/* <div className="balance">$0</div> */}
                                                <div className="desc">
                                                    Generate multiple wallet addresses in 1-click.
                                                    {/* This website is a <br /> convenient blockchain tool */}
                                                </div>
                                            </div>
                                            <div className="content-r">
                                                <ul className="list-checked">
                                                    <li>
                                                        <img src="../images/bot/tick-circle.png" alt="" />{" "}
                                                        Free of Charge
                                                    </li>
                                                    <li>
                                                        <img src="../images/bot/tick-circle.png" alt="" />{" "}
                                                        Fast Respond
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="group-button-fee">
                                                <button className="btn-fee" onClick={handleRidirect}>
                                                    Start for free
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterHome />
        </>
    );
};
export default WalletGenerator;
