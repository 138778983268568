import { message } from "antd";

const IconSuccess = () => {
  return (
    <div>
      <img src="../../../images/bot/IconS.png" alt="" />
    </div>
  );
};
const IconError = () => {
  return (
    <div>
      <img src="../../../images/bot/IconE.png" alt="" />
    </div>
  );
};
const IconWarning = () => {
  return (
    <div>
      <img src="../../../images/bot/IconW.png" alt="" />
    </div>
  );
};
const handleClose = () => {
  message.destroy();
};
const Content = (data) => {
  return (
    <div className="content-cus">
      <div>{data}</div>
      <img src="../../../images/bot/closeM.png" alt="" onClick={handleClose} style={{padding: '3px'}} />
    </div>
  );
};

export const notiSuccess = (content, duration) => {
  return new Promise((resolve) => {
    message.success({
      type: "success",
      content: Content(content),
      icon: IconSuccess(),
      className: "custom-class",
      duration: duration,
    });
  });
};
export const notiWarning = (content, duration) => {
  return new Promise((resolve) => {
    message.warning({
      type: "warning",
      content: Content(content),
      icon: IconWarning(),
      className: "custom-class",
      duration: duration,
    });
  });
};
export const notiError = (content, duration) => {
  return new Promise((resolve) => {
    message.error({
      type: "error",
      content: Content(content),
      icon: IconError(),
      className: "custom-class",
      duration: duration,
    });
  });
};