import React, {useState, useEffect} from "react";
import { ethers } from "ethers";
import { useActiveWeb3React, useConnectWallet } from "../../../hooks";
import {useSelector, useDispatch} from 'react-redux';
import allActions from '../../../actions';
import web3 from "web3";
import { ArrowRightOutlined } from "@ant-design/icons";
//Image
import IconClose from '../asset/images/icon-close.svg';
import IconCopy from '../asset/images/icon-copy.svg';
import { ADDRESS_HLUP_ADDRESS } from "../../../constants";

const provider = "https://testnet-rpc.helachain.com";
const InfoWallet = ({onCancel}) => {
    const [myBalance, setMyBalance] = useState();
    const { walletLogout } = useConnectWallet();
    const { account } = useActiveWeb3React();
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.currentUser);
    const address = currentUser.address;
    const accountEllipsis = address ? `${address.substring(0, 4)}...${address.substring(address.length - 4)}` : '';

    const w3 = window.ethereum ? new web3(window.ethereum) : new web3(provider);
    const infoStake = useSelector(state => state.currentUser.infoStake);

    useEffect(() => {
        const loadBalance = async () => {
            const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
            const hupToken = {
                address: ADDRESS_HLUP_ADDRESS,
                abi: [
                    "function name() view returns (string)",
                    "function symbol() view returns (string)",
                    "function gimmeSome() external",
                    "function balanceOf(address _owner) public view returns (uint256 balance)",
                    "function transfer(address _to, uint256 _value) public returns (bool success)",
                ],
            };
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();        
            const kdgContract = new ethers.Contract(hupToken.address, hupToken.abi, signer);        
            let kdgBalance = await kdgContract.balanceOf(address);
            setMyBalance(Number(ethers.utils.formatEther(kdgBalance, 6)).toFixed(0));
        }
    
        loadBalance();
    }, [address]);

    useEffect(() => {
        dispatch(allActions.userActions.getStakingUser(address));
    }, [address]);

    let copyWallet = null;
    const copyToClipboard = () => {
        copyWallet.select();
        document.execCommand("copy");
    };
    // useEffect(() => {
    //     if (account) {
    //         w3.eth.getBalance(account, (err, balance) => {
    //             if (balance) {
    //                 let _balance = web3.utils.fromWei(
    //                     web3.utils.toBN(balance),
    //                     "ether"
    //                 );
    //                 let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
    //                 setNativeBalance(bnbBl);
    //             }
    //         });
    //     }
    // }, [account]);
    const handleDisconnect = () => {
		walletLogout();
		onCancel(false);
        dispatch(allActions.userActions.setUser(null));
        dispatch(allActions.userActions.setChainId(null));
        window.location.reload();
	};

    return (
        <div className="c-popup">
            <div className="c-popup__box">
                <div className="c-popup__head">
                    Wallet
                    <button className="c-popup__close" onClick={() => onCancel(false)}><img src={IconClose} alt="Close" /></button>
                </div>
                <div className="c-popup__body">
                    <div className="c-infoWallet bg-darkblue p-15">
                        
                        <div className="c-infoWallet__row row-bottom">
                            <span>Wallet Address:</span>
                            <p>
                                {accountEllipsis}
                                <input readOnly value={address} ref={(ref) => (copyWallet = ref)}/>
                                <button onClick={() => copyToClipboard()}><img src={IconCopy} alt="Copy" /></button>
                            </p>
                        </div>
                        <div className="c-infoWallet__row row-bottom">
                            <span>Staking:</span>
                            <p>
                                {infoStake?.staking ? new Intl.NumberFormat("ja-JP").format(infoStake?.staking) : '0'} {' '}
                                <img src="../images/up-favi.svg" width={25}/>
                            </p>
                        </div>
                        <div className="c-infoWallet__row">
                            <span>Your balance:</span>
                            <p>
                                {new Intl.NumberFormat("ja-JP").format(myBalance)} {' '}
                                <img src="../images/up-favi.svg" width={25}/>
                            </p>
                        </div>
                    </div>
                    
                    <div className="c-infoWallet__row row-bottom">
                        <span>Tier:</span>
                        <p>{infoStake?.tierName ? infoStake?.tierName : '--'}</p>
                    </div>
                    <div className="c-infoWallet__row mt-15">
                        <span onClick={()=> window.location.replace('/genesis-nft/profile')} className="text-weight-500 text-16 text-darkblue">NFT Profile</span>
                        <ArrowRightOutlined />
                    </div>
                 
                    <div className="text-center">
                        <button className="btn-basic w-200 mt-15" onClick={() => handleDisconnect()}>Sign out</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoWallet;