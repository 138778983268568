import React, { useEffect, useState } from "react";
import {Link , useNavigate} from "react-router-dom";
import Marquee from "react-fast-marquee";
import FooterHome from '../../common/footer/footerHome';
import { useActiveWeb3React } from "../../../hooks";
import { Tabs , Empty, Row, Col, Pagination } from "antd";
import { FormOutlined } from '@ant-design/icons';
import "../style.css"
import { NftApi } from "../../../config/api/apiNFT";
import ProfileOfferHistory from "./profile-offer-history";
import { MAPPING_CHAINID_DECIMAL } from "../../../constants";
import ChainNotSupport from "../../chain-not-support";

const NftProfile = () => {
    const { account, chainId } = useActiveWeb3React()
    
    const navigate = useNavigate();
    const [nftList, setNftList] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [totalRow, setTotalRow] = useState(0);
    const [profileDetail, setProfileDetail] = useState(null);
    const [param, setParam] = useState(
        {
            page: 0,
            size: 12,
            sort: 'id,desc',
            ownerAddress: ''
        }
    );

    const getNftByOwner = async (status) => {
        setLoadingData(true);
       const dataFilter = {
            ...param,
            ownerAddress: account
        };
        try {
            let res = await NftApi.getNftByOwner(dataFilter);
            setTotalRow(parseInt(res.headers['x-total-count']));
            if (res.status === 200) {
                if (res.data.length > 0) {
                    setLoadingData(false);
                    setNftList(res.data);
                } else {
                    setLoadingData(false);
                    setNftList([]);
                }
            }
        } catch (error) { console.log('Error: ', error); }
        finally {
            setLoadingData(false);
        }
    }

    const getProfileDetail = async (account) => {
        let rs = await NftApi.getProfileByAddress(account);
        if(rs.status == 200){
            setProfileDetail(rs.data);
        }
    }

    useEffect(() => {
        if(account){
            getNftByOwner()
        }
    }, [account, param]);

    useEffect(() => {
        if(account){
            getProfileDetail(account)
        }
    }, [account]);

    const handleRedirect = (id) => {
        navigate(`/nft-owner-details/${id}`)
    }
    const onChange_Pagi = (pageNumber) => {
        setParam({
            ...param,
            page: pageNumber - 1
        });
    };

    const Item = ({item}) => {
        
        return (
            <>
                <Col className="gutter-row" xs={24} xl={6} md={6} sm={6} key={item.id}>
                    <div className="nft-item" onClick={() => handleRedirect(item?.id)}>
                        <div className="nft-img">
                            <img src={item?.nftImageURL} alt="" />
                        </div>
                        <div className="nft-info">
                            <span>NFT Collection</span>
                            <span>{item?.name}</span>
                        </div>
                        <div className="nft-info">
                            <span>Curent price:</span>
                            <span>{item?.price ? item?.price : '___'} HLUP</span>
                        </div>
                    </div>
                </Col>
            </>
        )
    }

    const changeNftTabs = (key)=>{
        if(key === 'all'){
            navigate('/genesis-nft')
        }
    }
    const handleChangeProfile = ()=>{
        navigate('/profile')
    }

    if(chainId && MAPPING_CHAINID_DECIMAL.hela !== chainId){
        return(
            <><ChainNotSupport /></>
        )
    }

    return (
        <>
            <div className="main-nft-profile">
                <div className="group-images">
                    <div className="images-info">
                        <Marquee pauseOnHover={true} speed={80}>            
                            <div className="item">
                                <img src="../../images/img-row.png" alt="" />
                            </div>
                            <div className="item">
                                <img src="../../images/pic-1.png" alt="" />
                            </div>
                            <div className="item">
                                <img src="../../images/pic-2.png" alt="" />
                            </div>
                            <div className="item">
                                <img src="../../images/pic-3.png" alt="" />
                            </div>
                            <div className="item">
                                <img src="../../images/pic.png" alt="" />
                            </div>
                            <div className="item">
                                <img src="../../images/pic-1.png" alt="" />
                            </div>
                            <div className="item">
                                <img src="../../images/pic-3.png" alt="" />
                            </div>
                        </Marquee>
                    </div>
                </div>
                <div className="container">
                    <div className="group-row">
                        <div className="nft-profile-user">
                            <div className="profile-avatar">
                                {profileDetail && profileDetail?.avatarLink ? (
                                    <img src={profileDetail?.avatarLink} />
                                ):(
                                    <img src="../../../images/default_nft.png" />
                                )}
                                
                            </div>
                            <div className="profile-info">
                                <div className="profile-name">{profileDetail != null ? profileDetail?.username : 'Unname'} <span onClick={handleChangeProfile} style={{marginLeft: '5px', cursor:'pointer'}}><FormOutlined /></span></div>
                                <div className="profile-wallet">Wallet : {account && account.length > 0 && (<span>{account.substring(0, 5)}...{account.substring(account.length - 5)}</span>)}</div>
                            </div>
                        </div>
                        <div className="main-tabs-nft">
                            <Tabs defaultActiveKey="your-nft" onChange={changeNftTabs}>
                                <Tabs.TabPane tab="All" key="all">
                                    <></>
                                </Tabs.TabPane> 
                                <Tabs.TabPane tab="Your NFT" key="your-nft">
                                    <div className="box-tasb-content">
                                        <Row gutter={16}>
                                            {nftList.length === 0 ?
                                                <>
                                                    <Col className="text-center" xs={24}>
                                                        <div className="no-data">
                                                            <img width={200} src="../images/nodata.svg" />
                                                            <p className="text-black-green text-weight-500">You don't have any NFT</p>
                                                            <button type="button" className="btn-basic w-fitcontent" onClick={()=> window.location.replace('/mint-nft')}>
                                                                Mint Now
                                                            </button>
                                                        </div>
                                                    </Col>
                                                    
                                                </>
                                                :
                                                <>
                                                    {nftList.length > 0 && nftList.map((item,key) => (
                                                        <Item item={item}/>
                                                    ))}
                                                </>
                                            }
                                        </Row>
                                        {totalRow > 12 && (
                                            <Pagination
                                                current={param.page + 1}
                                                defaultCurrent={1}
                                                total={totalRow}
                                                pageSize={param.size}
                                                onChange={onChange_Pagi}
                                                showSizeChanger={false}
                                                className="pagiation-custom mt-20"
                                            />
                                        )}
                                    </div>
                                </Tabs.TabPane>   
                                <Tabs.TabPane tab="Offer history" key="offer-history">
                                    <ProfileOfferHistory />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Your offer" key="your-offer">
                                    <ProfileOfferHistory />
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            <FooterHome />
        </>
    )
}
export default NftProfile
