import React , {useState} from 'react';
import { Tabs } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import LiquidityV2 from './AddLiqV2';
import LiquidityV3 from './AddLiqV3';
import "./style.css"
import bgMainheaderRow from "./images/bg-radi.png";
import FooterHome from '../common/footer/footerHome';
import { useActiveWeb3React } from '../../hooks';
import ChainNotSupport from '../chain-not-support';

const Liquidity = () => {
    const { account , chainId } = useActiveWeb3React();
    let navigate = useNavigate();
    let location = useLocation();

    function callback(key) {
        navigate(`${"/add-liquidity"}?${key}`);
    }

    const pathTabsLive = location.search.split("?");
    const tabBox = pathTabsLive[1] === undefined ? "V2" : pathTabsLive[1];

    return (
        <>
            <div className="main-section" style={{ background: `url(${bgMainheaderRow}) no-repeat`,}}>
                <div className="group-title pt-0"> 
                    <div className="img-title-left">
                        <img src="../images/bot/left-title.svg" alt="" />
                    </div>
                    <div className="img-title-right">
                        <img src="../images/bot/right-title.svg" alt="" />
                    </div>
                    <div className="main-title res-title">
                        Smart Liquidity
                    </div>
                </div>
                {chainId === 56 || chainId === 1 || chainId === 42161 || chainId === 97 ? (
                    <>
                        <div className="section-market-volum">
                            <div className="container">
                                <div className="round-tabs round-tabs-volum res">
                                    <Tabs activeKey={tabBox} onChange={callback}>
                                        <Tabs.TabPane tab="Add LP V2" key="V2">
                                            <LiquidityV2 />
                                        </Tabs.TabPane>
                                        <Tabs.TabPane tab="Add LP V3" key="V3">
                                            <LiquidityV3 />
                                        </Tabs.TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </>
                ):(
                    <>
                        <ChainNotSupport />
                    </>
                )}
                
            </div>
            <FooterHome />
        </>
    )
}
export default Liquidity